import produce from "immer";
import { ILoanModel, ILoanSummaryModel, EntitySummaryModel, IGetLoanModel, IGetLoansModel, SecuritySummaryModel, IIndexSummaryModel, ISubFundSummaryModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IGetEntitySummary, IGetSecuritySummary } from "./Reference";
export interface IState {
    loanAllLoading: boolean;
    loans: ILoanSummaryModel[];
    loanLoading: boolean;
    loanCurrent?: ILoanModel;
    loanSaving: boolean;
    loanDeleting: boolean;
    dictionaries: {
        entities: Record<string | number, EntitySummaryModel>;
        indexes: Record<string | number, IIndexSummaryModel>;
        securities: Record<string | number, SecuritySummaryModel>;
        subFunds: Record<string | number, ISubFundSummaryModel>;
    }
}
export const ActionFactories = produceActionFactories({
    loanLoad: (payload: number) => payload,
    loanLoaded: (payload: IGetLoanModel) => payload,
    loanSave: (payload: ILoanModel) => payload,
    loanSaved: (payload: ILoanModel) => payload,
    loanDelete: (payload: number) => payload,
    loanDeleted: (payload: number) => payload,
    loanLoadAll: () => undefined,
    loanLoadedAll: (payload: IGetLoansModel) => payload,
    loanAddEntityInDictionary: (payload: IGetEntitySummary) => payload,
    loanAddSecurityInDictionary: (payload: IGetSecuritySummary) => payload,
    loanAddIndexInDictionary: (payload: IIndexSummaryModel) => payload,
});

export function reducer(
    state: IState = {
        loanLoading: false,
        loanAllLoading: false,
        loanSaving: false,
        loanDeleting: false,
        loans: [],
        dictionaries: {
            entities: {},
            indexes: {},
            securities: {},
            subFunds: {}
        }
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "loanAddEntityInDictionary":
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.entities[action.payload.entity.id] = action.payload.entity;
                break;
            case "loanAddSecurityInDictionary":
                draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds };
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.securities[action.payload.security.id] = action.payload.security;
                break;
            case "loanAddIndexInDictionary":
                draft.dictionaries.indexes[action.payload.id] = action.payload;
                break;
            case "loanLoadAll":
                draft.loanAllLoading = true;
                break;
            case "loanLoadedAll": {
                draft.loanAllLoading = false;
                const { loans, ...dictionaries } = action.payload;
                draft.loans = loans;
                draft.dictionaries = { ...dictionaries, indexes: {}, securities: {}, subFunds: {} };
                break;
            }
            case "loanLoad":
                draft.loanLoading = true;
                draft.loanCurrent = undefined;
                break;
            case "loanLoaded": {
                draft.loanLoading = false;
                const { loan, entities, indexes, securities, subFunds } = action.payload;
                draft.loanCurrent = loan;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...entities };
                draft.dictionaries.indexes = { ...draft.dictionaries.indexes, ...indexes };
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...securities };
                draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...subFunds };
                break;
            }
            case "loanSave":
                draft.loanSaving = true;
                break;
            case "loanSaved":
                draft.loanSaving = false;
                const saved = action.payload;
                draft.loanCurrent = saved;
                const existing = draft.loans.find(i => i.id === saved.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.loans.push({ ...saved, type: "LoanSummaryModel" });
                }
                break;
            case "loanDelete":
                draft.loanDeleting = true;
                break;
            case "loanDeleted":
                draft.loanDeleting = false;
                const deletedId = action.payload;
                if (draft.loanCurrent?.id === deletedId) {
                    delete draft.loanCurrent;
                }
                const idx = draft.loans.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.loans.splice(idx, 1);
                }
                break;
        }
    });
}
