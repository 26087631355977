import produce from "immer";
import { ISubFundModel, IHistoricalValuesModel, ISubFundSummaryModel, IGetSubFundModel, SecuritySummaryModel, IGetSubFundsModel, EntitySummaryModel, CustomScreenSummaryModel, CustomScreenModel, ICustomScreenDataModel, IGetCustomScreensDataModel, ISubFundsSubmitCustomScreenDataParameters, IMonitoringResultsetModel, IMacroScriptsGetMonitoringResultForTargetParameters, IPositionModel, IGetPositionsModel, ProcessExecutionSummaryModel, IGetProcessExecutionsModel, RelationshipSummaryModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { ISubFundsGetAllParameters } from "proxy/apiProxy";
import { IGetEntitySummary, IGetSecuritySummary } from "../../reducers/Reference";
export type VisualizationType = "GRID" | "CHART";
export interface IState {
    allLoading: boolean;
    loading: boolean;
    all: (ISubFundModel | ISubFundSummaryModel)[];
    current?: ISubFundModel;
    historicalValues?: IHistoricalValuesModel[];
    historicalValuesLoading: boolean;
    saving: boolean;
    deleting: boolean;
    subfundHistoricalValuesVisualizationType: VisualizationType;
    dictionaries: {
        entities: Record<number, EntitySummaryModel>;
        subFunds: Record<number, ISubFundSummaryModel>;
        securities: Record<number, SecuritySummaryModel>;
        customScreens: Record<string | number, CustomScreenSummaryModel>;
        relationships: Record<string | number, RelationshipSummaryModel>;
    };
    customScreens?: CustomScreenModel[];
    customScreenDatas?: ICustomScreenDataModel[];
    customScreenDataSubmitting: boolean;
    customScreenDataLoading: boolean;

    monitoringResults: Record<number, IMonitoringResultsetModel | null>;

    composition?: IPositionModel[];
    compositionVisualizationType: VisualizationType;
    pricingDates: Date[];
    pricingDatesLoading: boolean;
    pricingDateLoading: boolean;
    currentPricingDate?: Date;
    processExecutions?: ProcessExecutionSummaryModel[];
    processExecutionsLoading: boolean;
}

export const ActionFactories = produceActionFactories({
    subfundLoad: (payload: number) => payload,
    subfundLoaded: (payload: IGetSubFundModel) => payload,
    subfundSave: (payload: ISubFundModel) => payload,
    subfundSaved: (payload: ISubFundModel) => payload,
    subfundDelete: (payload: number) => payload,
    subfundDeleted: (payload: number) => payload,
    subfundLoadAll: (payload: ISubFundsGetAllParameters) => payload,
    subfundLoadedAll: (payload: IGetSubFundsModel) => payload,
    subfundHistoricalValuesLoad: (payload: number) => payload,
    subfundHistoricalValuesLoaded: (payload: IHistoricalValuesModel[]) => payload,
    subfundHistoricalValuesVisualizationTypeChange: (payload: VisualizationType) => payload,
    subfundAddSecurityInDictionary: (payload: IGetSecuritySummary) => payload,
    subfundAddEntityInDictionary: (payload: IGetEntitySummary) => payload,
    subfundCustomScreensLoaded: (payload: CustomScreenModel[]) => payload,
    subfundCustomScreenDatasLoad: (payload: number) => payload,
    subfundCustomScreenDatasLoaded: (payload: IGetCustomScreensDataModel) => payload,
    subfundCustomScreenDataSubmit: (payload: ISubFundsSubmitCustomScreenDataParameters) => payload,
    subfundCustomScreenDataSubmitted: (payload: ICustomScreenDataModel) => payload,

    subfundMonitoringResultLoad: (payload: IMacroScriptsGetMonitoringResultForTargetParameters) => payload,
    subfundMonitoringResultLoaded: (payload: IMonitoringResultsetModel) => payload,

    subfundCompositionVisualizationTypeChange: (payload: VisualizationType) => payload,
    subfundCompositionLoaded: (payload: IGetPositionsModel) => payload,
    subfundPricingDatesLoad: (payload: number) => payload,
    subfundPricingDatesLoaded: (payload: Date[]) => payload,
    subfundPricingDateLoad: (payload: Date) => payload,
    subfundPricingDateLoaded: (payload: Date) => payload,
    subfundProcessExecutionsLoad: (payload: number) => payload,
    subfundProcessExecutionsLoaded: (payload: IGetProcessExecutionsModel) => payload,
});

export function reducer(
    state: IState = {
        loading: false,
        allLoading: false,
        saving: false,
        deleting: false,
        historicalValuesLoading: false,
        subfundHistoricalValuesVisualizationType: "GRID",
        all: [],
        dictionaries: {
            entities: {},
            securities: {},
            subFunds: {},
            customScreens: {},
            relationships: {},
        },
        customScreenDataLoading: false,
        customScreenDataSubmitting: false,
        monitoringResults: {},
        compositionVisualizationType: "GRID",
        pricingDates: [],
        pricingDatesLoading: false,
        pricingDateLoading: false,
        processExecutionsLoading: false
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "subfundProcessExecutionsLoad":
                draft.processExecutionsLoading = true;
                break;
            case "subfundProcessExecutionsLoaded":
                draft.processExecutionsLoading = false;
                draft.processExecutions = action.payload.processExecutions;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                break;
            case "subfundMonitoringResultLoad":
                draft.monitoringResults[action.payload.id] = null;
                break;
            case "subfundMonitoringResultLoaded":
                draft.monitoringResults[action.payload.monitoringMacroId] = action.payload;
                break;

            case "subfundCustomScreensLoaded":
                draft.customScreens = action.payload;
                break;
            case "subfundCustomScreenDatasLoad":
                draft.customScreenDataLoading = true;
                break;
            case "subfundCustomScreenDatasLoaded":
                draft.customScreenDataLoading = false;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.customScreens = { ...draft.dictionaries.customScreens, ...action.payload.customScreens };
                draft.customScreenDatas = action.payload.customScreenDatas;
                break;
            case "subfundCustomScreenDataSubmit":
                draft.customScreenDataSubmitting = true;
                break;
            case "subfundCustomScreenDataSubmitted":
                if (!draft.customScreenDatas) {
                    draft.customScreenDatas = [];
                }
                const customScreenData = draft.customScreenDatas.find(i => i.customScreenId === action.payload.customScreenId);
                if (!!customScreenData) {
                    Object.assign(customScreenData, action.payload);
                }
                else {
                    draft.customScreenDatas.push(action.payload);
                }
                draft.customScreenDataSubmitting = false;
                break;
            case "subfundLoadAll":
                draft.allLoading = true;
                break;
            case "subfundLoadedAll":
                draft.allLoading = false;
                draft.all = action.payload.subFunds;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                break;
            case "subfundLoad":
                draft.loading = true;
                draft.current = undefined;
                draft.customScreens = undefined;
                draft.customScreenDatas = undefined;
                draft.dictionaries = {
                    entities: {},
                    subFunds: {},
                    securities: {},
                    customScreens: {},
                    relationships: {}
                };
                draft.monitoringResults = {};
                draft.composition = [];
                break;
            case "subfundLoaded":
                draft.loading = false;
                draft.current = action.payload.subFund;
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                break;
            case "subfundSave":
                draft.saving = true;
                break;
            case "subfundSaved":
                draft.saving = false;
                if (draft.current) {
                    draft.current = action.payload;
                }
                const existing = draft.all.find(i => i.id === action.payload.id);
                if (existing) {
                    Object.assign(existing, action.payload);
                }
                else {
                    draft.all.push(action.payload);
                }
                break;
            case "subfundDelete":
                draft.deleting = true;
                break;
            case "subfundDeleted":
                draft.deleting = false;
                const deletedId = action.payload;
                if (draft.current && draft.current.id === deletedId) {
                    delete draft.current;
                }
                const idx = draft.all.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.all.splice(idx, 1);
                }
                break;
            case "subfundHistoricalValuesVisualizationTypeChange":
                draft.subfundHistoricalValuesVisualizationType = action.payload;
                break;
            case "subfundHistoricalValuesLoaded":
                draft.historicalValues = action.payload;
                draft.historicalValuesLoading = false;
                break;
            case "subfundHistoricalValuesLoad":
                draft.historicalValues = undefined;
                draft.historicalValuesLoading = true;
                break;
            case "subfundAddEntityInDictionary":
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.entities[action.payload.entity.id] = action.payload.entity;
                break;
            case "subfundAddSecurityInDictionary":
                draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds };
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.securities[action.payload.security.id] = action.payload.security;
                break;
            case "subfundCompositionLoaded":
                draft.composition = action.payload.positions;
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities }
                draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds }
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities }
                break;
            case "subfundCompositionVisualizationTypeChange":
                draft.compositionVisualizationType = action.payload;
                break;
            case "subfundPricingDatesLoad":
                draft.composition = undefined;
                draft.pricingDatesLoading = true;
                break;
            case "subfundPricingDatesLoaded":
                draft.pricingDatesLoading = false;
                draft.pricingDates = action.payload.sort((a, b) => (a ? a.getTime() : 0) - (b ? b.getTime() : 0));
                break;
            case "subfundPricingDateLoaded":
                draft.currentPricingDate = action.payload;
                draft.pricingDateLoading = false;
                break;
            case "subfundPricingDateLoad":
                draft.pricingDateLoading = true;
                break;
        }
    });
}
