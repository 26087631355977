import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, share, mergeMap } from "rxjs/operators";
import { reportTemplateCategoriesApi, IReportTemplateCategoryModel } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, changedNavigation } from "lib/rxJsUtility";
import { tryParseNumber } from "tools/lib/utility";
import { filterRoute } from "tools/lib/UrlDictionary";

export const loadReportTemplateCategories: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplateCategory", "reportTemplateCategoryLoadAll"),
        mergeMap(() => reportTemplateCategoriesApi.getAllAsync({})),
        map(ActionFactories.reportTemplateCategory.reportTemplateCategoryLoadedAll));
export const onOpenScreenReportTemplateCategory: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("TemplateCategories", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? 0),
        map(ActionFactories.reportTemplateCategory.reportTemplateCategoryLoad));

export const onOpenScreenReportTemplateCategories: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("TemplateCategories"),
        map(() => ActionFactories.reportTemplateCategory.reportTemplateCategoryLoadAll()));
export const loadReportTemplateCategory: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("reportTemplateCategory", "reportTemplateCategoryLoad"),
            share()
        );
        return merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => reportTemplateCategoriesApi.getAsync({ id })),
                map(ActionFactories.reportTemplateCategory.reportTemplateCategoryLoaded)),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({ id: 0, name: "" } as IReportTemplateCategoryModel)),
                map(ActionFactories.reportTemplateCategory.reportTemplateCategoryLoaded)));
    };
export const saveReportTemplateCategory: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplateCategory", "reportTemplateCategorySave"),
        mergeMap(model => reportTemplateCategoriesApi.saveAsync({ model })),
        map(ActionFactories.reportTemplateCategory.reportTemplateCategorySaved));
export const deleteReportTemplateCategory: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("reportTemplateCategory", "reportTemplateCategoryDelete"),
            mergeMap(id => reportTemplateCategoriesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.reportTemplateCategory.reportTemplateCategoryDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };
