import * as React from "react";
import { oProps } from "tools/lib/utility";
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import StickyContainer from "tools/components/StickyContainer";
import { Box, Fab, IconButton, Paper, Tooltip } from "@material-ui/core";
import ClassificationDialog from "./ClassificationDialog";
import { useField } from "formik";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import SortableTree, { ExtendedNodeData, isDescendant, NodeRendererProps, TreeNode, removeNodeAtPath, changeNodeAtPath } from "react-sortable-tree";
import classnames from "classnames";
import styled from '@emotion/styled';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { IEditableClassificationType, ITreeItem, ITreeItemModel } from "./treeTypes";
type NumberOrStringArray = Array<string | number>;

export default function ClassificationsData() {
    const [{ value: classifications }, , { setValue: setClassifications }] = useField<IEditableClassificationType["classifications"]>(oProps<IEditableClassificationType>().path("classifications"));
    const [editingClassification, setEditingClassification] = React.useState<{ model: ITreeItemModel, path: NumberOrStringArray } | undefined>();
    const [addingClassification, setAddingClassification] = React.useState<ITreeItemModel | undefined>();

    const hideEdit = () => {
        setEditingClassification(undefined);
        setAddingClassification(undefined);
    }

    const handleEditClassificationChanged = React.useCallback((row: ITreeItemModel) => {
        if (editingClassification) {
            const newClassifications = changeNodeAtPath({ path: editingClassification.path, treeData: structuredClone(classifications), getNodeKey: t => t.node.model.code, newNode: ({ model, ...node }: ITreeItem) => ({ ...node, model: row }) })
            setClassifications(newClassifications);
            hideEdit();
        }
        else if (addingClassification) {
            const newItem: ITreeItem = { model: row, children: [] };
            const newClassifications = [...classifications, newItem];
            setClassifications(newClassifications);
            hideEdit();
        }
    }, [addingClassification, classifications, editingClassification, setClassifications])
    const handleCancelEditClassification = hideEdit;

    const handleNodeClick = React.useCallback((path: NumberOrStringArray, row: ITreeItem) => setEditingClassification({ model: row.model, path }), []);
    const handleNodeDelete = React.useCallback((path: NumberOrStringArray, row: ITreeItem, parentRow?: ITreeItem) => {
        const newClassifications = removeNodeAtPath({ treeData: structuredClone(classifications), getNodeKey: (t => t.node.model.code), path });
        setClassifications(newClassifications);
    }, [classifications, setClassifications])

    const handleAddNodeClick = React.useCallback(() => setAddingClassification({ name: { en: "" }, code: "", id: 0 }), []);
    const readOnly = React.useContext(ReadOnlyContext);
    const handleChange = React.useCallback((items: IEditableClassificationType["classifications"]) => setClassifications(items), [setClassifications]);
    const handleCanDrop = React.useCallback(() => !readOnly, [readOnly]);
    const handleGenerateNodeProps = React.useCallback((nodeData: ExtendedNodeData<ITreeItem>) => {
        if (readOnly) {
            return {};
        }

        return {
            buttons: [<Tooltip title="Delete"><IconButton
                size="small"
                style={{ verticalAlign: "middle" }}
                onClick={handleNodeDelete.bind(null, nodeData.path, nodeData.node, nodeData.parentNode)} >
                <DeleteIcon />
            </IconButton></Tooltip>,
            <Tooltip title="Edit"><IconButton
                size="small"
                style={{ verticalAlign: "middle" }}
                onClick={handleNodeClick.bind(null, nodeData.path, nodeData.node)} >
                <MoreVertIcon />
            </IconButton></Tooltip>
            ]
        };

    }, [handleNodeClick, handleNodeDelete, readOnly]);
    const handleGetNodeKey = React.useCallback((item: TreeNode<ITreeItem>) => item.node.model.code, []);
    return <><ClassificationDialog
        classification={editingClassification?.model ?? addingClassification}
        onCancel={handleCancelEditClassification}
        onClassificationChanged={handleEditClassificationChanged} />
        <SortableTree<ITreeItem>
            onChange={handleChange}
            treeData={classifications}
            getNodeKey={handleGetNodeKey}
            canDrag={!readOnly}
            canDrop={handleCanDrop}
            isVirtualized={false}
            nodeContentRenderer={NoteNodeRenderer}
            generateNodeProps={handleGenerateNodeProps} />
        {!readOnly && <StickyContainer>
            <Fab color="primary" aria-label="Add" onClick={handleAddNodeClick}>
                <AddIcon />
            </Fab>
        </StickyContainer>}</>
}

const NodeContainer = styled(Paper)({
    padding: 10,
});

function NoteNodeRenderer(props: NodeRendererProps<ITreeItem>) {
    const {
        scaffoldBlockPxWidth,
        toggleChildrenVisibility,
        connectDragPreview,
        connectDragSource,
        isDragging,
        canDrop,
        canDrag,
        node,
        title,
        subtitle,
        draggedNode,
        path,
        treeIndex,
        isSearchMatch,
        isSearchFocus,
        buttons = [],
        className,
        style,
        didDrop,
        treeId,
        isOver, // Not needed, but preserved for other renderers
        parentNode, // Needed for dndManager
        rowDirection,
        ...otherProps
    } = props;
    const nodeTitle = title || node.model.code;
    const nodeSubtitle = subtitle || node.subtitle;
    // const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : null;
    let handle = null;
    if (canDrag) {
        if (typeof node.children === 'function' && node.expanded) {
            // Show a loading symbol on the handle when the children are expanded
            //  and yet still defined by a function (a callback to fetch the children)
            handle = <div className="rst__loadingHandle">
                <div className="rst__loadingCircle">
                    {[...new Array(12)].map((_, index) => <div key={index} className={classnames('rst__loadingCirclePoint')} />)}
                </div>
            </div>
        } else {
            // Show the handle used to initiate a drag-and-drop
            handle = connectDragSource(<div style={{ cursor: "move", color: "lightgray", zIndex: 9999 }}><DragIndicatorIcon /></div>, { dropEffect: 'copy' });
        }
    }

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
    const isLandingPadActive = !didDrop && isDragging;

    return <div style={{ height: '100%', paddingTop: 10 }} {...otherProps}>
        {toggleChildrenVisibility && node.children && (node.children.length > 0 || typeof node.children === 'function') && (
            <div>
                <button
                    type="button"
                    aria-label={node.expanded ? 'Collapse' : 'Expand'}
                    className={classnames(node.expanded ? 'rst__collapseButton' : 'rst__expandButton')}
                    style={{ left: -0.5 * scaffoldBlockPxWidth }}
                    onClick={() => toggleChildrenVisibility({ node, path, treeIndex, })} />
                {node.expanded && !isDragging && <div style={{ width: scaffoldBlockPxWidth }} className={classnames('rst__lineChildren')} />}
            </div>)}
        <NodeContainer elevation={2}>
            {connectDragPreview(<div
                className={classnames(
                    'rst__row',
                    isLandingPadActive && 'rst__rowLandingPad',
                    isLandingPadActive && !canDrop && 'rst__rowCancelPad',
                    isSearchMatch && 'rst__rowSearchMatch',
                    isSearchFocus && 'rst__rowSearchFocus',
                    className
                )}
                style={{ opacity: isDraggedDescendant ? 0.5 : 1, alignItems: node.subtitle ? "center" : "baseline", gap: 5, ...style, minWidth: 250 }}>
                {handle}
                <Box display="flex" flexDirection="column" width="100%">
                    <span className={classnames('rst__rowTitle', node.subtitle && 'rst__rowTitleWithSubtitle')}>
                        {typeof nodeTitle === 'function' ? nodeTitle({ node, path, treeIndex, }) : nodeTitle}
                    </span>

                    {nodeSubtitle && (
                        <span className="rst__rowSubtitle">
                            {typeof nodeSubtitle === 'function' ? nodeSubtitle({ node, path, treeIndex, }) : nodeSubtitle}
                        </span>
                    )}
                </Box>
                <div className="rst__rowToolbar">
                    {buttons.map((btn, index) => (
                        <div key={index} className="rst__toolbarButton">
                            {btn}
                        </div>
                    ))}
                </div>
            </div>)}
        </NodeContainer>
    </div>
}
