import DetailPanel, {ISubMenu} from "tools/components/DetailPanel";
import {IBatchModel, IFeatureModel} from "proxy/apiProxy";
import {Formik, FormikHelpers} from 'formik';
import BatchData from "./BatchData";
import BatchScript from "./BatchScript";
import BatchScriptVisualization from "./BatchScriptVisualization";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import {useGrants, useReduxActions, useReduxSelections} from "tools/lib/reduxStoreAccess";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import BatchConditionalScriptEditor from "./BatchConditionalScriptEditor";
import {oProps} from "tools/lib/utility";
import {getConfig} from "lib/userManager";
import {IActionButton, IMenuActionButton, ISimpleActionButton} from "../../tools/components/FabContainer";
import {Button, Snackbar} from "@material-ui/core";
import BatchConfigureRunModal from "./BatchConfigureRunModal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {getSiteScreenUrl} from "../../tools/lib/UrlDictionary";
import {MouseEvent, useState} from "react";

const {general: {disableSudo}} = getConfig();
export default function Batch() {
    const {
        batchScriptSchema,
        batchCurrent,
        batchLoading,
        dictionaries,
        batchConditionalScriptMetadata,
        batchConditionalScriptCheckResult = {errors: []},
        batchExecutionRequested
    } = useReduxSelections("batch");
    const {
        batchSave,
        batchRequestExecution,
        batchStop,
        batchDelete,
        batchAddEntityInDictionary,
        batchConditionalScriptValidate,
        batchCloseExecutionRequested
    } = useReduxActions("batch");
    const {navigationNavigate} = useReduxActions("navigation");
    const {referenceMacroScriptCategories = {}} = useReduxSelections("reference");
    const {navigationActiveScreen: navigationActiveUrlNode} = useReduxSelections("navigation");
    const isGranted = useGrants();
    const [configurationOpen, setConfigurationOpen] = useState<boolean>(false)

    const handleBack = () => navigationNavigate(undefined);
    if (!batchCurrent) {
        return <DetailPanelLoading onBackClick={handleBack} tabNumber={3}/>;
    }


    const handleExecute = (asOfDate?: Date) => {
        console.log("Executing batch", batchCurrent?.id, asOfDate)
        if (batchCurrent?.id) {
            setConfigurationOpen(false)
            batchRequestExecution({id: batchCurrent?.id, asOfDate});
        }
    }


    const handleConfigureExecution = () => {
        if (batchCurrent?.id) {
            setConfigurationOpen(true);
        }
    }

    const handleCloseConfigureExecution = () => {
        if (batchCurrent?.id) {
            setConfigurationOpen(false)
        }
    }

    function handleCloseExecutingNotification() {
        if (batchCurrent?.id)
            batchCloseExecutionRequested(batchCurrent?.id)
    }

    const handleOnStop = () => {
        if (batchCurrent?.id) {
            batchStop(batchCurrent.id);
        }
    }
    const handleTabValueChanged = (tabValue: any) => navigationNavigate({sectionKey: tabValue as string});
    const handleDelete = () => {
        if (batchCurrent?.id) {
            batchDelete(batchCurrent.id);
        }
    }

    const handleSubmit = (values: IBatchModel, {setSubmitting}: FormikHelpers<IBatchModel>) => {
        batchSave(values)
        setSubmitting(false);
    }

    const title = !!batchCurrent.id ? batchCurrent.name : 'New batch';
    const tabs = [{
        value: "detail",
        label: "Data"
    }, {
        value: "conditionalScript",
        label: "Condition"
    }, {
        value: "structure",
        label: "Definition"
    }, {
        value: "visualRepresentation",
        label: "Visualization"
    }] as ISubMenu[];
    const tabValue = navigationActiveUrlNode?.activeSectionKey ?? "detail";
    const extraActionButtons: IActionButton[] = (!!batchCurrent.id && isGranted(IFeatureModel.BatchExecute))
        ? [
            {
                label: "Execute",
                icon: PlayArrowIcon,
                actions: [
                    {
                        label: "Run",
                        onClick: handleExecute
                    },
                    {
                        label: "Configure run",
                        onClick: handleConfigureExecution
                    }
                ]
            } as IMenuActionButton, {
                label: "Stop all executing jobs",
                onClick: handleOnStop,
                icon: StopIcon
            } as ISimpleActionButton
        ]
        : [];

    function handleOpenExecution(event: MouseEvent) {
        event.preventDefault()
        navigationNavigate({screenKey: "Executions"})
    }

    return <Formik onSubmit={handleSubmit}
                   initialValues={batchCurrent}
                   enableReinitialize={true}
                   validateOnMount={true}>
        {({
              dirty,
              isValid,
              submitForm,
              values
          }) => (
            <WriteAccess value={IFeatureModel.BatchWrite}>
                <DetailPanel
                    isQuerying={batchLoading}
                    title={title}
                    tabs={tabs}
                    tabValue={tabValue}
                    noPadding={tabValue === "structure" || tabValue === "conditionalScript"}
                    onTabValueChanged={handleTabValueChanged}
                    canSave={dirty && isValid}
                    onSaveClick={submitForm}
                    canDelete={!!values.id}
                    onDeleteClick={handleDelete}
                    actions={extraActionButtons}
                    saveAllowed={IFeatureModel.BatchWrite}
                    deleteAllowed={IFeatureModel.BatchWrite}
                    saveMustBeConfirmed={!disableSudo}
                    onBackClick={handleBack}
                    badge={!values.id ? "new" : undefined}>
                    {tabValue === "visualRepresentation" && <BatchScriptVisualization script={values.script}/>}
                    {tabValue === "structure" &&
                        <BatchScript jsonSchema={batchScriptSchema} onSaveRequest={submitForm}/>}
                    {tabValue === "detail" &&
                        <BatchData batch={values} onEntityLoaded={batchAddEntityInDictionary}
                                   dictionaries={dictionaries}
                                   referenceMacroScriptCategories={referenceMacroScriptCategories}/>}
                    {tabValue === "conditionalScript" && <BatchConditionalScriptEditor
                        fieldName={oProps<IBatchModel>().path("conditionalScript")}
                        metadata={batchConditionalScriptMetadata}
                        onSaveRequest={submitForm}
                        onScriptChanged={batchConditionalScriptValidate}
                        scriptErrors={batchConditionalScriptCheckResult.errors}/>}
                    <Snackbar
                        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                        open={batchExecutionRequested}
                        message="Execution started..."
                        action={
                            <>
                                <Button
                                    color="secondary" size="small"
                                    href={getSiteScreenUrl("Executions")}
                                    onClick={handleOpenExecution}>
                                    OPEN
                                </Button>
                                <IconButton size="small" aria-label="close" color="inherit"
                                            onClick={handleCloseExecutingNotification}>
                                    <CloseIcon fontSize="small"/>
                                </IconButton>
                            </>
                        }
                    />
                    <BatchConfigureRunModal
                        handleCancel={handleCloseConfigureExecution}
                        handleRun={handleExecute}
                        open={configurationOpen}
                        name={batchCurrent.code}/>
                </DetailPanel>
            </WriteAccess>
        )}
    </Formik>;

}
