import { Epic } from "redux-observable";
import { filter, map, share, mergeMap } from "rxjs/operators";
import { batchInstancesApi, batchesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, changedNavigation } from "lib/rxJsUtility";
import { tryParseNumber, today } from "tools/lib/utility";
import { filterRoute } from "tools/lib/UrlDictionary";
import { merge } from "rxjs";

export const onOpenScreenBatchInstances: Epic<IAnyAction>
    = action$ => {
        const batchInstanceScreenOpen$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey),
            filterRoute("Executions"),
            share()
        );
        return merge(
            batchInstanceScreenOpen$.pipe(
                map(() => {
                    const from = today();
                    from.setMonth(from.getMonth() - 1);
                    return ActionFactories.batchInstance.batchInstanceSearch({ from });
                })),
            batchInstanceScreenOpen$.pipe(
                mergeMap(() => batchesApi.getAllAsync({})),
                map(ActionFactories.batchInstance.batchInstanceSetBatches)
            )
        );
    }
export const onGetBatchInstanceTaskTraces: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("batchInstance", "batchInstanceTaskNodeTracesLoad"),
        mergeMap(batchInstancesApi.getTracedRowsAsync),
        map(i => ActionFactories.batchInstance.batchInstanceTaskNodeTracesLoaded())
    );
export const onOpenScreenBatchInstanceTask: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.task?.taskId),
        filterRoute("Executions", "task"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.task?.taskId)),
        filter(i => typeof (i) !== "undefined"),
        map(taskId => ActionFactories.batchInstance.batchInstanceSetCurrentTask(taskId as number)));
export const onOpenScreenBatchInstance: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("Executions", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? 0),
        filter(i => !!i),
        map(ActionFactories.batchInstance.batchInstanceLoad));
export const searchBatchInstances: Epic<IAnyAction>
    = (action$) =>
        action$.pipe(
            mapToPayload("batchInstance", "batchInstanceSearch"),
            mergeMap(batchInstancesApi.searchAsync),
            map(ActionFactories.batchInstance.batchInstancesFound));
export const loadBatchInstance: Epic<IAnyAction>
    = (action$) => action$.pipe(
        mapToPayload("batchInstance", "batchInstanceLoad"),
        mergeMap(id => batchInstancesApi.getAsync({ id })),
        map(ActionFactories.batchInstance.batchInstanceLoaded));
