import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import Summary from "./Summary";
import { getSiteScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class StressTestSummaryScreen extends React.PureComponent {
  public render() {
    return <>
      <NavigationRoute
        gridSize={12}
        exact={true}
        slideDirection={"right"}
        component={Summary}
        path={getSiteScreenUrl("StressTestingScreen")} />
    </>
  }
}

export default StressTestSummaryScreen;
