import ClassificationScreen from "features/Classification/ClassificationTypeScreen";
import PositionScreen from "./components/position/PositionScreen";
import SecurityScreen from "features/Security/SecurityScreen";
import PortfolioHistoricalValueScreen from "./components/portfolioHistoricalValue/PortfolioHistoricalValueScreen";
import ReportTemplateScreen from "components/reportTemplate/ReportTemplateScreen";
import ReportTemplateCategoryScreen from "components/reportTemplateCategory/ReportTemplateCategoryScreen";
import MacroScriptCategoryScreen from "components/macroScriptCategory/MacroScriptCategoryScreen";
import MacroScreen from "features/MacroScript/MacroScriptScreen";
import BatchScreen from "components/batch/BatchScreen";
import BankAccountScreen from "components/bankAccount/BankAccountScreen";
import EntityScreen from "features/Entity/EntityScreen";
import CashMovementScreen from "components/cashMovement/CashMovementScreen";
import AccountBalanceScreen from "components/accountBalance/AccountBalanceScreen";
import StressTestSummaryScreen from "components/stressTest/StressTestSummaryScreen";
import BatchInstanceScreen from "components/batchInstance/BatchInstanceScreen";
import ValueAtRiskScreen from "components/valueAtRisk/ValueAtRiskScreen";
import ProcessDefinitionScreen from "features/ProcessDefinition/ProcessDefinitionScreen";
import RelationshipScreen from "features/Relationship/RelationshipScreen";
import ReportScreen from "components/report/ReportScreen";
import MonitoringMacroCallScreen from "components/monitoringMacroCall/MonitoringMacroCallScreen";
import DashboardIcon from '@material-ui/icons/Dashboard';
import DashboardScreen from "components/dashboard/DashboardScreen";
import ConnectorsScreen from "components/connectors/ConnectorsScreen";
import PortfolioTransactionScreen from "components/portfolioTransaction/PortfolioTransactionScreen";
import ChangesHistoryScreen from "components/changesHistory/ChangesHistoryScreen";
import NoteScreen from "components/note/NoteScreen";
import ParametersScreen from "components/parameters/ParametersScreen";
import StatisticDefinitionSetScreen from "components/statisticDefinitionSet/StatisticDefinitionSetScreen";
import GlobalPortfolioComplianceScreen from "components/globalPortfolioCompliance/GlobalPortfolioComplianceScreen";
import TradeDateScreen from "components/tradeDate/TradeDateScreen";
import IndexScreen from "components/indexes/IndexScreen";
import FxRateScreen from "components/fxRates/FxRateScreen";
import TradesScreen from "components/portfolioTrade/PortfolioTradeScreen";
import CustomScreenScreen from "features/CustomScreen/CustomScreenScreen";

import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import DomainIcon from '@material-ui/icons/Domain';
import WebIcon from '@material-ui/icons/Web';
import NoteIcon from '@material-ui/icons/Note';
import FileChartIcon from "mdi-material-ui/FileChart";
import NotebookCheckOutlineIcon from "mdi-material-ui/NotebookCheckOutline";
import BankIcon from "mdi-material-ui/Bank";
import HandshakeIcon from "mdi-material-ui/Handshake";
import AlertOctagonOutlineIcon from "mdi-material-ui/AlertOctagonOutline";
import PublicIcon from '@material-ui/icons/Public';
import RocketLaunchIcon from "mdi-material-ui/RocketLaunch";
import SourceBranchIcon from "mdi-material-ui/SourceBranch";
import FinanceIcon from "mdi-material-ui/Finance";
import MicrosoftVisualStudioCode from "mdi-material-ui/MicrosoftVisualStudioCode";
import BankTransferIcon from "mdi-material-ui/BankTransfer";
import ToyBrickIcon from "mdi-material-ui/ToyBrickOutline";
import ConnectionIcon from "mdi-material-ui/Connection";
import ShieldCheckOutlineIcon from "mdi-material-ui/ShieldCheckOutline";
import CloudPrintIcon from "mdi-material-ui/CloudPrint";
import ChartPpfIcon from "mdi-material-ui/ChartPpf";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import BookMultipleIcon from "mdi-material-ui/BookMultiple";
import FactoryIcon from "mdi-material-ui/Factory";
import CashMultipleIcon from "mdi-material-ui/CashMultiple";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import SettingsIcon from '@material-ui/icons/Settings';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TimelineIcon from '@material-ui/icons/Timeline';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PortfoliosComplianceScreen from "components/portfoliosCompliance/PortfoliosComplianceScreen";
import { ISiteMap } from "tools/lib/SiteMapTypes";
import ShareClassScreen from "features/ManagedShareClass/ShareClassScreen";
import ManagedSicavScreen from "features/ManagedSicav/SicavScreen";
import SicavScreen from "features/Sicav/SicavScreen";
import TraceScreen from "components/trace/TraceScreen";
import RegisterTransactionScreen from "components/registerTransaction/RegisterTransactionScreen";
import ExternalTemplateScreen from "components/externalTemplate/ExternalTemplateScreen";
import GlobalPortfolioDealingScreen from "components/globalPortfolioDealing/GlobalPortfolioDealingScreen";
import DocumentDefinitionScreen from "components/document/DocumentDefinitionScreen";
import TenancyScreen from "components/tenant/TenancyScreen";
import LoanScreen from "components/loan/LoanScreen";
import TenantsAdministrationScreen from "features/TenantsAdministration/TenantsAdministrationScreen";
import TenantManagerScreen from "features/TenantManager/TenantManagerScreen";
import PortfolioScreen from "features/ManagedPortfolio/PortfolioScreen";
import DevelopmentItemScreen from "features/DevelopmentItem/DevelopmentItemScreen";
import ProcessExecutionScreen from "features/ProcessExecution/ProcessExecutionScreen";
import SubFundScreen from "features/Subfund/SubFundScreen";

const siteMap: ISiteMap = [{
    label: "Dashboards",
    modules: [{
        label: "Dashboards",
        icon: <DashboardIcon />,
        screens: [{
            label: "Dashboards",
            screenKey: "Dashboards",
            keywords: "view dashboard",
            component: DashboardScreen
        }, {
            label: "Get Files",
            screenKey: "DashboardsGetFiles",
            keywords: "view dashboard report get file export",
            component: DashboardScreen
        }, {
            label: "Execute",
            screenKey: "DashboardsExecute",
            keywords: "view dashboard batch execute",
            component: DashboardScreen
        }, {
            label: "Drop Files",
            screenKey: "DashboardsDropFiles",
            keywords: "view dashboard import drop files",
            component: DashboardScreen
        }]
    }, {
        label: "Processes",
        icon: <NotebookCheckOutlineIcon />,
        screens: [{
            label: "Process executions",
            screenKey: "ProcessExecutions",
            component: ProcessExecutionScreen
        }]
    }]
}, {
    label: "PMS",
    modules: [{
        label: "Portfolios",
        icon: <BookMultipleIcon />,
        screens: [{
            label: "Portfolios",
            screenKey: "MyPortfolios",
            keywords: "management",
            component: PortfolioScreen
        }, {
            label: "Funds/Sicavs",
            screenKey: "MySicavs",
            keywords: "management fund sicav",
            component: ManagedSicavScreen
        }, {
            label: "ShareClasses",
            screenKey: "MyShareClasses",
            keywords: "management",
            component: ShareClassScreen
        }, {
            label: "Loans",
            screenKey: "Loans",
            component: LoanScreen
        }]
    }, {
        label: "Positions/Transactions",
        icon: <AccountBalanceWalletIcon />,
        screens: [{
            label: "Positions",
            component: PositionScreen,
            screenKey: "Positions"
        }, {
            label: "Portfolio Transactions",
            component: PortfolioTransactionScreen,
            screenKey: "PortfolioTransactions"
        }, {
            label: "Cash Movements",
            component: CashMovementScreen,
            screenKey: "CashMovements"
        }, {
            label: "Cash Accounts",
            screenKey: "BankAccounts",
            component: BankAccountScreen
        }]
    }, {
        label: "Register",
        icon: <BankTransferIcon />,
        screens: [{
            label: "Register Transactions",
            keywords: "sub red",
            component: RegisterTransactionScreen,
            screenKey: "RegisterTransactions"
        }]
    }, {
        label: "Fund Accounting",
        icon: <FinanceIcon />,
        screens: [{
            label: "Account Balances",
            component: AccountBalanceScreen,
            screenKey: "AccountBalances"
        }, {
            label: "NAVs/TNAs",
            component: PortfolioHistoricalValueScreen,
            screenKey: "HistoricalValues"
        }]
    }, {
        label: "CashMov Classifications",
        icon: <LocalOfferIcon />,
        screens: [{
            label: "CashMov Classifications",
            screenKey: "MovementClassificationTypes",
            component: ClassificationScreen
        }]
    }]
}, {
    label: "Universe",
    modules: [{
        label: "Securities",
        icon: <PublicIcon />,
        screens: [{
            label: "Securities",
            keywords: "Universe",
            screenKey: "Securities",
            component: SecurityScreen
        }, {
            label: "Sub Funds",
            screenKey: "SubFunds",
            keywords: "subfund",
            component: SubFundScreen
        }, {
            label: "Funds/Sicavs",
            screenKey: "Sicavs",
            component: SicavScreen
        }, {
            label: "Tenancies",
            screenKey: "Tenancies",
            component: TenancyScreen
        }]
    }, {
        label: "Indexes",
        icon: <TimelineIcon />,
        screens: [{
            label: "Indexes",
            keywords: "Index MSCI",
            screenKey: "Indexes",
            component: IndexScreen
        }]
    }, {
        label: "Forex",
        icon: <CashMultipleIcon />,
        screens: [{
            label: "Fx Rates",
            keywords: "Fx Forex Rates",
            screenKey: "FxRates",
            component: FxRateScreen
        }]
    }, {
        label: "Security Classifications",
        icon: <LocalOfferIcon />,
        screens: [{
            label: "Security Classifications",
            screenKey: "SecurityClassificationTypes",
            component: ClassificationScreen
        }]
    }]
}, {
    label: "CRM",
    modules: [{
        label: "Entities",
        icon: <BankIcon />,
        screens: [{
            label: "People",
            keywords: "Person User Client Prospect",
            screenKey: "People",
            component: EntityScreen
        }, {
            label: "Companies",
            screenKey: "Companies",
            component: EntityScreen
        }, {
            label: "Entity Groups",
            screenKey: "EntityGroups",
            component: EntityScreen
        }]
    }, {
        label: "Relationships",
        icon: <HandshakeIcon />,
        screens: [{
            label: "Investors",
            screenKey: "Investors",
            component: RelationshipScreen
        }, {
            label: "Counterparties",
            screenKey: "Counterparties",
            component: RelationshipScreen
        }, {
            label: "Roles",
            screenKey: "ServiceProvidersCollaborators",
            component: RelationshipScreen
        }]
    }, {
        label: "Classifications",
        icon: <LocalOfferIcon />,
        screens: [{
            label: "Investor Classifications",
            screenKey: "InvestorClassificationTypes",
            component: ClassificationScreen
        }, {
            label: "Role Classifications",
            screenKey: "RoleRelationshipClassificationTypes",
            component: ClassificationScreen
        }, {
            label: "Entity Classifications",
            screenKey: "EntityClassificationTypes",
            component: ClassificationScreen
        }]
    }]
}, {
    label: "Dealing",
    modules: [{
        label: "Trade Blotter",
        icon: <ReceiptIcon />,
        screens: [{
            component: TradeDateScreen,
            screenKey: "TradeDatesScreen",
            label: "Trade Books",
        }, {
            component: GlobalPortfolioDealingScreen,
            screenKey: "GlobalPortfolioDealingPolicy",
            studioRelated: true,
            label: "Order Calculation Macro",
        }]
    }, {
        label: "Trades",
        icon: <ShoppingCartIcon />,
        screens: [{
            component: TradesScreen,
            screenKey: "TradesScreen",
            label: "Trades",
        }]
    }]
}, {
    label: "Risk Management",
    modules: [{
        label: "Statistics",
        icon: <ChartPpfIcon />,
        screens: [{
            component: ValueAtRiskScreen,
            screenKey: "ValueAtRiskScreen",
            keywords: "Statistics",
            label: "Statistics",
        }, {
            component: StatisticDefinitionSetScreen,
            screenKey: "StatisticDefinitionSets",
            label: "Definitions",
        }]
    }, {
        label: "Stress-testing",
        icon: <AlertOctagonOutlineIcon />,
        screens: [{
            component: StressTestSummaryScreen,
            screenKey: "StressTestingScreen",
            label: "Stress-testing",
        }]
    },
    {
        label: "Investment Compliance",
        icon: <ShieldCheckOutlineIcon />,
        screens: [{
            component: PortfoliosComplianceScreen,
            screenKey: "PortfolioComplianceScreen",
            label: "Compliance Checks",
        }, {
            label: "Global Investment Rule",
            studioRelated: true,
            screenKey: "GlobalPortfolioCompliancePolicy",
            component: GlobalPortfolioComplianceScreen
        }]
    }]
}, {
    label: "Macros",
    modules: [{
        label: "Macro Execution",
        icon: <RocketLaunchIcon />,
        screens: [{
            label: "Macro Executions",
            screenKey: "MacroMonitoringCalls",
            // keywords: "history run launch",
            component: MonitoringMacroCallScreen
        }]
    }, {
        label: "Macro Code",
        icon: <MicrosoftVisualStudioCode />,
        screens: [{
            label: "Macros",
            screenKey: "Macros",
            studioRelated: true,
            keywords: "code c# cs csharp dotnet import upload etl",
            component: MacroScreen
        }, {
            label: "Categories",
            screenKey: "MacroScriptCategories",
            component: MacroScriptCategoryScreen
        }]
    }, {
        label: "Studio",
        icon: <ToyBrickIcon />,
        screens: [{
            label: "Development Items",
            screenKey: "DevelopmentItems",
            studioRelated: true,
            keywords: "code c# cs csharp dotnet import upload etl custom screen",
            component: DevelopmentItemScreen
        }, {
            label: "Classifications",
            screenKey: "DevelopmentItemClassificationTypes",
            component: ClassificationScreen
        }]
    }, {
        label: "Custom Screens",
        icon: <WebIcon />,
        studioRelated: true,
        screens: [{
            label: "Screens",
            screenKey: "CustomScreen",
            keywords: "screen macro questionnaire profiling aml",
            component: CustomScreenScreen
        }]
    }]
}, {
    label: "Reporting",
    modules: [{
        label: "Report Generation",
        icon: <CloudPrintIcon />,
        screens: [{
            label: "Report Generation Setup",
            screenKey: "Reports",
            component: ReportScreen
        }]
    }, {
        label: "Report Design",
        icon: <FileChartIcon />,
        screens: [{
            label: "Templates",
            screenKey: "Templates",
            keywords: "Designer Stimulsoft",
            component: ReportTemplateScreen
        }, {
            label: "External Template",
            screenKey: "ExternalTemplateEdition",
            keywords: "Export Empty Report External Template",
            component: ExternalTemplateScreen
        }, {
            label: "Categories",
            screenKey: "TemplateCategories",
            component: ReportTemplateCategoryScreen
        }]
    }, {
        label: "Notes & Documents",
        icon: <NoteIcon />,
        screens: [{
            label: "Notes",
            keywords: "note memo",
            screenKey: "Notes",
            component: NoteScreen
        }, {
            label: "Documents",
            keywords: "document",
            screenKey: "DocumentDefinitions",
            component: DocumentDefinitionScreen
        }, {
            label: "Classifications",
            screenKey: "NoteClassificationTypes",
            component: ClassificationScreen
        }]
    }]
}, {
    label: "Settings",
    modules: [{
        label: "Batches",
        icon: <FactoryIcon />,
        screens: [{
            label: "Sequences",
            screenKey: "BatchDefinitions",
            component: BatchScreen
        }, {
            label: "Executions",
            screenKey: "Executions",
            keywords: "history run launch",
            component: BatchInstanceScreen
        }]
    }, {
        label: "Processes",
        icon: <NotebookCheckOutlineIcon />,
        screens: [{
            label: "Process Definitions",
            screenKey: "ProcessDefinitions",
            component: ProcessDefinitionScreen
        }, {
            label: "Classifications",
            screenKey: "ProcessDefinitionClassificationTypes",
            keywords: "process definition classification",
            component: ClassificationScreen
        }]
    }, {
        label: "Connectors",
        icon: <ConnectionIcon />,
        screens: [{
            label: "Definitions",
            screenKey: "Connectors",
            keywords: "ftp mail sftp ftps folders files",
            component: ConnectorsScreen
        }]
    }, {
        label: "Versions & Audit",
        icon: <SourceBranchIcon />,
        screens: [{
            label: "Versions History",
            keywords: "system changes zip upload",
            screenKey: "History",
            studioRelated: true,
            component: ChangesHistoryScreen
        }, {
            label: "Traces",
            keywords: "log errors track activity",
            screenKey: "Traces",
            component: TraceScreen
        }]
    }, {
        label: "Parameters",
        icon: <SettingsIcon />,
        screens: [{
            label: "Parameters",
            keywords: "global setting market data",
            screenKey: "GeneralSettings",
            component: ParametersScreen
        }]
    }]
}, {
    label: "Tenant Management",
    onlyForTenantAdministrators: true,
    modules: [{
        label: "Tenants",
        icon: <DomainIcon />,
        screens: [{
            label: "Tenants",
            screenKey: "TenantsAdministration",
            component: TenantsAdministrationScreen
        }]
    }, {
        label: "Tenant Managers",
        icon: <SupervisedUserCircleIcon />,
        screens: [{
            label: "Tenant Managers",
            screenKey: "TenantManagers",
            component: TenantManagerScreen
        }]
    }]
}];

export default siteMap;
