import { Epic } from 'redux-observable'
import { map, withLatestFrom, share, distinctUntilChanged } from 'rxjs/operators';
import { ActionFactories, IAnyAction, IState } from "reducers";
import { generatePath } from 'react-router';
import { IGoToScreenSectionPayload } from 'reducers/Navigation';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { IActiveScreen, UnsafeGetSiteMapUrl as UnsafeGetSiteMapUrls, getActiveScreen } from 'tools/lib/UrlDictionary';
import { mapToPayload, changedNavigation, onlyNotNull } from 'lib/rxJsUtility';
import { merge } from 'rxjs';
import { getSiteMapScreen } from 'tools/lib/SiteMap';

export const navigationNavigate: Epic<IAnyAction, IAnyAction, IState> = (action$, state$) =>
    action$.pipe(
        mapToPayload("navigation", "navigationNavigate"),
        withLatestFrom(state$),
        map(([payload, state]) => {
            const { navigationActiveScreen: navigationActiveNode } = state.navigation;

            if (!navigationActiveNode) {
                return;
            }

            const outputUrls = getOutputUrls(payload, navigationActiveNode);
            if (!outputUrls) {
                return;
            }
            let parameters: any = {};
            if (!Object.keys(payload?.parameters ?? {}).length) {
                const activeSections = navigationActiveNode.matchingSections ?? {};
                if ((!!payload?.sectionKey) && (!payload?.screenKey || payload.screenKey === navigationActiveNode.screenKey)) {
                    for (const key in activeSections) {
                        if (activeSections.hasOwnProperty(key)) {
                            parameters = { ...parameters, ...activeSections[key] };
                        }
                    }
                }
            }
            if (payload?.parameters) {
                parameters = { ...parameters, ...payload.parameters };
            }
            const outputUrl = outputUrls.filter(i => urlMatch(i, parameters));
            const newPath = generatePath(outputUrl[0], parameters);
            return newPath;
        }),
        onlyNotNull(),
        map(url => ActionFactories.router.push(url))
    );
function stringCompare(a: string, b: string): number {
    if (a === b) {
        return 0;
    }
    else if (a > b) {
        return 1;
    }
    else {
        return -1;
    }
}
function urlMatch(url: string, parameters: any) {
    const parameterKeys = Object.keys(parameters ?? {}).sort(stringCompare);
    const urlKeys = extractSegments(url).sort(stringCompare);
    return parameterKeys.join("_") === urlKeys.join("_"); //not the best and most reliable, but that should do it
}
function extractSegments(path: string): string[] {
    const pattern = /:([a-zA-Z0-9]+)/g;
    let match: RegExpExecArray | null;
    const segments: string[] = [];

    while ((match = pattern.exec(path)) !== null) {
        segments.push(match[1]);
    }

    return segments;
}
export const handleNavigation: Epic<IAnyAction> = action$ => {
    const activeUrlNode$ = action$.ofType(LOCATION_CHANGE).pipe(
        map(action => (action as LocationChangeAction).payload.location.pathname),
        distinctUntilChanged(),
        map(getActiveScreen),
        onlyNotNull(),
        map(activeScreen => ({
            activeScreen,
            activeSiteMapSelection: getSiteMapScreen(activeScreen.screenKey)
        })),
        share());
    return merge(
        activeUrlNode$.pipe(map(ActionFactories.navigation.navigationChanged)),
        activeUrlNode$.pipe(map(i => ActionFactories.navigation.navigationExpandModuleCategory(i?.activeSiteMapSelection?.moduleCategoryIndex ?? 0)))
    );
};

export const onOpenScreenPerson: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(),
        map(i => ActionFactories.navigation.navigationModuleChange()));


function getOutputUrls(
    payload: IGoToScreenSectionPayload | undefined,
    currentActiveUrl: IActiveScreen): string[] | undefined {
    const {
        activeSectionKey,
        screenKey
    } = currentActiveUrl;
    if (!payload) {
        if (activeSectionKey) {
            return UnsafeGetSiteMapUrls({ screenKey });
        }
        return;
    }

    const newScreenKey = payload.screenKey ?? screenKey;

    if (!newScreenKey) {
        return;
    }

    return UnsafeGetSiteMapUrls({
        screenKey: newScreenKey,
        activeSectionKey: payload.sectionKey
    });
}
