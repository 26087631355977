import { Epic } from "redux-observable";
import { forkJoin, from, merge, of } from "rxjs";
import { filter, map, share, distinctUntilChanged, mergeMap, toArray, tap } from "rxjs/operators";
import {
    securitiesApi, IShareClassModel, IBondModel, ICommodityModel, ICfdModel, IFutureModel, IOptionModel, ISwapModel, IEquityModel, IStructuredProductModel, IFxForwardModel, IEtfModel, SecurityModel,
    ICouponTypeModel, IFrequencyTypeModel, IOptionTypeModel, IPutCallModel, IGetSecurityProxyModel, IProxyPositionModel, customScreensApi, IMiscellaneousGoodModel, IRealEstateModel, macroScriptsApi, IRelatedFileModel
} from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { ISecurityTypeModel } from "proxy/apiProxy";
import { changedNavigation, mapToPayload, onlyNotNull } from "lib/rxJsUtility";
import { tryParseNumber } from "tools/lib/utility";
import { filterRoute } from "tools/lib/UrlDictionary";
import { Observable } from "rxjs";
import saveAs from "file-saver";
import { IRelatedFileWithContent } from "reducers/IRelatedFileWithContent";
import { ISecurityLoadedPayload } from "features/Security/slice";

export const loadSecurities: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securitySearch"),
        mergeMap(securitiesApi.searchAsync),
        map(ActionFactories.security.securityLoadedAll));
export const onOpenScreenSecurityHistoricalSeries: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.historicalValues?.id),
        filterRoute("Securities", "historicalValues"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.historicalValues?.id)),
        filter(id => typeof id !== "undefined"),
        distinctUntilChanged(),
        map(id => ActionFactories.security.securityHistoricalValuesLoad(id as number)));
export const onOpenScreenProcessExecutions: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.processes?.id),
        filterRoute("Securities", "processes"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.processes?.id)),
        filter(id => typeof id !== "undefined"),
        distinctUntilChanged(),
        map(id => ActionFactories.security.securityProcessExecutionsLoad(id as number)));
export const loadProcessExecutions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securityProcessExecutionsLoad"),
        mergeMap(id => securitiesApi.getProcessesAsync({ id })),
        map(ActionFactories.security.securityProcessExecutionsLoaded));

export const saveHistoricalValue: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securitySaveHistoricalValueSave"),
        mergeMap(historicalValuePayload =>
            securitiesApi.saveHistoricalValueAsync({ id: historicalValuePayload.securityId, historicalValueSet: historicalValuePayload.historicalValue }).then(() => historicalValuePayload)),
        map(ActionFactories.security.securitySaveHistoricalValueSaved));
export const onOpenScreenSecurityRatings: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.ratings?.id),
        filterRoute("Securities", "ratings"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.ratings?.id)),
        filter(id => typeof id !== "undefined"),
        distinctUntilChanged(),
        map(id => ActionFactories.security.securityRatingsLoad(id as number)));
export const onOpenScreenSecurityDatedScreen: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("Securities", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id)),
        filter(id => typeof id !== "undefined" && id > 0),
        distinctUntilChanged(),
        map(id => ActionFactories.security.securityPricingDatesLoad(id as number)));
export const loadPricingDates: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securityPricingDatesLoad"),
        mergeMap(id => securitiesApi.getPricingDatesAsync({ id })),
        map(ActionFactories.security.securityPricingDatesLoaded));
export const loadSecurityHistoricalValues: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securityHistoricalValuesLoad"),
        mergeMap(id => securitiesApi.getHistoricalValuesAsync({ id })),
        map(ActionFactories.security.securityHistoricalValuesLoaded));
export const loadSecurityRatings: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securityRatingsLoad"),
        mergeMap(id => securitiesApi.getBondRatingsAsync({ id })),
        map(ActionFactories.security.securityRatingsLoaded));
export const onOpenScreenSecurity: Epic<IAnyAction>
    = action$ => {
        const securityToLoad$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
            filterRoute("Securities"),
            map(({ matchingSections }) => {
                if (!matchingSections?.detail) {
                    return;
                }
                const id = tryParseNumber(matchingSections?.detail?.id);
                if (id) {
                    return id;
                }
                else {
                    return matchingSections?.detail?.id as ISecurityTypeModel;
                }
            }),
            onlyNotNull(),
            share());

        return merge(
            securityToLoad$.pipe(map(ActionFactories.security.securityLoad)),
            securityToLoad$.pipe(map(() => ActionFactories.macroScript.macroScriptLoadAll())));
    }





export const submitCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securityCustomScreenDataSubmit"),
        mergeMap(securitiesApi.submitCustomScreenDataAsync),
        map(ActionFactories.security.securityCustomScreenDataSubmitted));
export const loadCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securityCustomScreenDatasLoad"),
        mergeMap(id => securitiesApi.getLastCustomScreenDatasAsync({ id })),
        map(ActionFactories.security.securityCustomScreenDatasLoaded));


async function getSecurityAsync(id: number | ISecurityTypeModel) {
    if (typeof id === "number") {
        const [loadedEntity, files] = await Promise.all([
            securitiesApi.getAsync({ id: id as number }),
            securitiesApi.getFilesAsync({ id: id as number })]);
        return {
            ...loadedEntity,
            files
        };
    }
    else {
        return { security: createEmptySecurity(id), entities: {}, relationships: {}, subFunds: {}, files: [], processDefinitions: {}, indexes: {} } as ISecurityLoadedPayload;
    }
}



export const loadSecurity: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("security", "securityLoad"),
            share());

        const security$ = requestedId$.pipe(
            mergeMap(getSecurityAsync),
            share());

        const customScreens$ = requestedId$.pipe(
            mergeMap(() => customScreensApi.getAllAsync({})),
            map(customScreens => customScreens.filter(customScreen => customScreen.type === "SecurityInvestmentCustomScreenSummaryModel")),
            mergeMap(async customScreens => Promise.all(customScreens.map(({ id }) => customScreensApi.getAsync({ id })))),
            map(ActionFactories.security.securityCustomScreensLoaded));


        const securityProxy$ = merge(
            requestedId$.pipe(
                filter(id => typeof id === "number"),
                mergeMap(id => securitiesApi.getProxyAsync({ id: id as number }))),
            requestedId$.pipe(
                filter(id => typeof id === "string"),
                map(type => ({ proxyPositions: [], securities: {}, indexes: {} } as IGetSecurityProxyModel)))
        ).pipe(share());

        return merge(
            requestedId$.pipe(
                filter(id => typeof id === "number" && !!id),
                map(id => ActionFactories.security.securityCustomScreenDatasLoad(typeof id === "number" ? id : 0))),
            customScreens$,

            security$.pipe(map(ActionFactories.security.securityLoaded)),
            securityProxy$.pipe(map(ActionFactories.security.securityProxyLoaded)),
            requestedId$.pipe(map(() => ActionFactories.parameters.parametersLoad())),
            requestedId$.pipe(filter(i => typeof i === "number"), map(id => ActionFactories.security.securityRatingsLoad(id as number))),
            requestedId$.pipe(map(() => ActionFactories.parameters.parametersLoad())),
        );
    };
export const getMonitoringResultLoad: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securityMonitoringResultLoad"),
        mergeMap(macroScriptsApi.getMonitoringResultForTargetAsync),
        map(ActionFactories.security.securityMonitoringResultLoaded));

function createEmptySecurity(type: ISecurityTypeModel): SecurityModel {
    switch (type) {
        case ISecurityTypeModel.ShareClass: return { id: 0, type: "ShareClassModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", isUnderManagement: false } as IShareClassModel;
        case ISecurityTypeModel.Bond: return { id: 0, type: "BondModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", couponType: ICouponTypeModel.CommercialPaper, isCallable: false, isPerpetual: false, pricingFrequency: IFrequencyTypeModel.Daily, isConvertible: false } as IBondModel;
        case ISecurityTypeModel.Commodity: return { id: 0, type: "CommodityModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", pricingFrequency: IFrequencyTypeModel.Daily } as ICommodityModel;
        case ISecurityTypeModel.Cfd: return { id: 0, type: "CfdModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", pricingFrequency: IFrequencyTypeModel.Daily, isOtc: false } as ICfdModel;
        case ISecurityTypeModel.Future: return { id: 0, type: "FutureModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", isOtc: false, pricingFrequency: IFrequencyTypeModel.Daily } as IFutureModel;
        case ISecurityTypeModel.Option: return { id: 0, type: "OptionModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", isOtc: false, optionType: IOptionTypeModel.European, pricingFrequency: IFrequencyTypeModel.Daily, putCall: IPutCallModel.Put } as IOptionModel;
        case ISecurityTypeModel.Swap: return { id: 0, type: "SwapModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", isOtc: false, pricingFrequency: IFrequencyTypeModel.Daily } as ISwapModel;
        case ISecurityTypeModel.Equity: return { id: 0, type: "EquityModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "" } as IEquityModel;
        case ISecurityTypeModel.StructuredProduct: return { id: 0, type: "StructuredProductModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", pricingFrequency: IFrequencyTypeModel.Daily } as IStructuredProductModel;
        case ISecurityTypeModel.FxForward: return { id: 0, type: "FxForwardModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", pricingFrequency: IFrequencyTypeModel.Daily, buyAmount: 0, sellAmount: 0, sellCurrencyId: 0, isOtc: false } as IFxForwardModel;
        case ISecurityTypeModel.Etf: return { id: 0, type: "EtfModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", pricingFrequency: IFrequencyTypeModel.Daily } as IEtfModel;
        case ISecurityTypeModel.MiscellaneousGood: return { id: 0, type: "MiscellaneousGoodModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", pricingFrequency: IFrequencyTypeModel.Daily, isOtc: false } as IMiscellaneousGoodModel;
        case ISecurityTypeModel.RealEstate: return { id: 0, type: "RealEstateModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", pricingFrequency: IFrequencyTypeModel.Daily } as IRealEstateModel;
        case ISecurityTypeModel.Instrument: throw new Error(`extensionFieldsValues:${type}, can't create an instrument; create an underlying type instead`);
        case ISecurityTypeModel.All: throw new Error(`extensionFieldsValues:${type}, can't create an instrument; create an underlying type instead`);
    }
}

export const getFileContent: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securityFileContentLoad"),
        mergeMap(payload => securitiesApi.getFileContentAsync(payload)
            .then(file => ({ file, payload }))
            .catch(() => undefined)),
        onlyNotNull(),
        tap(({ file: { blob, fileName } }) => saveAs(blob, fileName)),
        map(({ payload }) => ActionFactories.security.securityFileContentLoaded(payload)));


function saveSecurity(
    savedSecurity: SecurityModel,
    proxy: IProxyPositionModel[] | undefined,
    files: IRelatedFileWithContent[] | undefined) {
    const { id: securityId } = savedSecurity;

    const merges$: Observable<IAnyAction>[] = []; // [of(savedEntity).pipe(map(ActionFactories.entity.entitySaved))];

    const savedFiles$ = (function () {
        if (files) {
            var file$ = from(files).pipe(share());
            var deletedFile$ = file$.pipe(
                filter(i => !!i.toDelete),
                mergeMap(i => securitiesApi.deleteFileAsync(({ id: securityId, fileId: i.id }))),
                map(ActionFactories.app.dummy)
            );
            merges$.push(deletedFile$);

            return file$.pipe(
                filter(i => !i.toDelete),
                mergeMap(async ({ content, toDelete, fileMimeType, fileName, contentToDelete, ...file }) => {
                    const savedFile = await securitiesApi.saveFileAsync({ id: securityId, model: file });
                    if (content && fileName) {
                        await securitiesApi.saveFileContentAsync({ fileId: savedFile.id, fileModel: { data: content.content, mimeType: content.mimeType, name: fileName } });
                        return { ...savedFile, fileName: content.fileName, fileMimeType: content.mimeType };
                    }
                    if (contentToDelete) {
                        await securitiesApi.deleteFileContentAsync({ fileId: savedFile.id });
                        return { ...savedFile, fileName: undefined, fileMimeType: undefined };
                    }
                    return savedFile;
                }),
                toArray(),
            );
        }
        else {
            return of([] as IRelatedFileModel[]);
        }
    })();


    merges$.push(forkJoin([of(savedSecurity), savedFiles$]).pipe(
        map(([security, files]) => ({ security, files })),
        map(ActionFactories.security.securitySaved)))

    const toWait$: Observable<any>[] = [];

    merges$.push(of(proxy).pipe(
        onlyNotNull(),
        mergeMap(model => securitiesApi.saveProxyAsync({ id: securityId, model })),
        map(() => ActionFactories.security.securityProxyLoaded({ proxyPositions: proxy ?? [], indexes: {}, securities: {} }))
    ));
    if (toWait$.length) {
        return forkJoin(toWait$).pipe(mergeMap(() => merge(...merges$)));
    }
    else {
        return merge(...merges$);
    }
}

export const saveSecurityEpic: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securitySave"),
        mergeMap(({ security, proxy, files }) =>
            from(securitiesApi.saveAsync({ model: security })).pipe(
                mergeMap(savedSecurity => saveSecurity(savedSecurity, proxy, files)))));

export const deleteSecurity: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("security", "securityDelete"),
            mergeMap(id => securitiesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.security.securityDeleted),
            share()
        );
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }
