import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, share, mergeMap } from "rxjs/operators";
import { studioDevelopmentItemClassificationTypesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { changedNavigation, mapToPayload, onlyNotNull } from "lib/rxJsUtility";
import { tryParseNumber } from "tools/lib/utility";
import { filterRoute } from "tools/lib/UrlDictionary";
import { getConfig } from "lib/userManager";
const { general: { enableStudio } } = getConfig();

export const classificationsOpenScreen: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("DevelopmentItemClassificationTypes"),
        map(ActionFactories.developmentItemClassificationType.developmentItemClassificationTypeLoadAll));
export const loadClassifications: Epic<IAnyAction>
    = action$ =>
        merge(
            action$.ofType("applicationLoaded").pipe(map(i => null)),
            action$.ofType("developmentItemClassificationTypeLoadAll").pipe(map(i => null)),
            action$.ofType("developmentItemClassificationTypeSaved").pipe(map(i => null)),
            action$.ofType("referenceRefreshAll").pipe(map(i => null))
        ).pipe(
            mergeMap(() => enableStudio ? studioDevelopmentItemClassificationTypesApi.getAllAsync({}) : Promise.resolve([])),
            map(ActionFactories.developmentItemClassificationType.developmentItemClassificationTypeAllLoaded));
export const onOpenScreenClassification: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("DevelopmentItemClassificationTypes", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id)),
        onlyNotNull(),
        map(ActionFactories.developmentItemClassificationType.developmentItemClassificationTypeLoad));
export const loadClassification: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("developmentItemClassificationType", "developmentItemClassificationTypeLoad"),
            share()
        );
        return merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => studioDevelopmentItemClassificationTypesApi.getAsync({ id }))),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({ id: 0, name: { en: "" }, code: "", classifications: [] })))
        ).pipe(
            map(ActionFactories.developmentItemClassificationType.developmentItemClassificationTypeLoaded));
    };
export const saveClassification: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItemClassificationType", "developmentItemClassificationTypeSave"),
        mergeMap(model => studioDevelopmentItemClassificationTypesApi.saveAsync({ model })),
        map(ActionFactories.developmentItemClassificationType.developmentItemClassificationTypeSaved));
export const deleteClassification: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("developmentItemClassificationType", "developmentItemClassificationTypeDelete"),
            mergeMap(id => studioDevelopmentItemClassificationTypesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.developmentItemClassificationType.developmentItemClassificationTypeDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }
