import { IPositionModel, isDerivativeSummaryModel, isRegularSecuritySummaryModel, isSecurityInstrumentSummaryModel } from "proxy/apiProxy";
import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { FormikProps, Formik, FormikHelpers } from "formik";
import { IDailyDataSearchPositionsParameters } from "proxy/apiProxy";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { IErrors, oProps } from "tools/lib/utility";
import FieldBox from "tools/components/FieldBox";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import ManagedPortfolioMultiSelectField from "components/searchers/ManagedPortfolioMultiSelectField";
import { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";
import React from "react";
import { getPortfolioTypeLabel } from "features/ManagedPortfolio/getPortfolioTypeLabel";
import { getSecurityTypeLabel } from "features/Security/getSecurityTypeLabel";

export default function Positions() {
    const { positionSearch, positionAddPortfoliosToDictionary } = useReduxActions("position");
    const { positionsLoading, positions = [], dictionaries } = useReduxSelections("position");
    const { portfolios, securities, subFunds } = dictionaries;
    const { navigationNavigate } = useReduxActions("navigation");
    const { referenceCurrencies = {}, referenceCountries = {} } = useReduxSelections("reference");
    const {
        classificationTypes: movementClassificationTypes,
        classificationTypesFlat: movementClassificationTypesFlat
    } = useReduxSelections("securityClassificationType");

    const { classificationTypesColumns, classificationTypesState } = React.useMemo(() => getClassificationTypesColumnDefinitions(movementClassificationTypes, movementClassificationTypesFlat, ({ securityId }: IPositionModel) => {
        if (!securityId) {
            return;
        }
        const security = dictionaries.securities[securityId];
        if (!security) {
            return;
        }
        if (isSecurityInstrumentSummaryModel(security)) {
            return security.classifications;
        }
        return;
    }), [dictionaries.securities, movementClassificationTypes, movementClassificationTypesFlat]);
    const columns: IColumnDefinition[] = React.useMemo(() => [
        { name: "PortfolioName", title: "Prtf Name", getCellValue: ({ portfolioId }: IPositionModel) => portfolios[portfolioId].name, aggregationType: "count", filteringEnabled: true },
        { name: "PortfolioCode", title: "Prtf Code", getCellValue: ({ portfolioId }: IPositionModel) => portfolios[portfolioId].internalCode, filteringEnabled: true },
        { name: "PortfolioType", title: "Prtf Type", getCellValue: ({ portfolioId }: IPositionModel) => getPortfolioTypeLabel(portfolios[portfolioId].type), filteringEnabled: true },
        { name: "Date", title: "Date", getCellValue: ({ date }: IPositionModel) => date, columnType: "date" },
        { name: "TargetSecurityCode", title: "Sec Code", getCellValue: ({ securityId }: IPositionModel) => securities[securityId].internalCode, filteringEnabled: true },
        { name: "TargetSecurityName", title: "Sec Name", getCellValue: ({ securityId }: IPositionModel) => securities[securityId].name, filteringEnabled: true },
        { name: "TargetSecurityType", title: "Sec Type", getCellValue: ({ securityId }: IPositionModel) => getSecurityTypeLabel(securities[securityId].type), filteringEnabled: true },
        {
            name: "TargetSecurityMat", title: "Sec Mat", getCellValue: ({ securityId }: IPositionModel) => {
                const secu = securities[securityId];
                return (secu.type === "BondSummaryModel" || isDerivativeSummaryModel(secu)) ? secu.maturityDate : undefined;
            }, columnType: "date", filteringEnabled: true
        },
        {
            name: "TargetSecurityCurrency", title: "Sec Ccy", getCellValue: ({ securityId }: IPositionModel) => {
                const currencyId = securities[securityId].currencyId;
                if (!currencyId) {
                    return
                }
                return referenceCurrencies[currencyId]?.isoCode;
            }, filteringEnabled: true
        },
        {
            name: "Country", title: "Country", getCellValue: ({ securityId }: IPositionModel) => {
                const secu = securities[securityId];
                let countryId: number | undefined;
                if (secu.type === "ShareClassSummaryModel" && secu.subFundId) {
                    if (subFunds[secu.subFundId].countryId) {
                        countryId = subFunds[secu.subFundId].countryId
                    }
                }
                else if (isRegularSecuritySummaryModel(secu)) {
                    countryId = secu.countryId;
                }
                if (!countryId) {
                    return undefined;
                }
                const country = referenceCountries[countryId];
                if (!country) {
                    return "???";
                }
                return country.name?.en;
            }, filteringEnabled: true
        },
        {
            name: "TargetSecurityIsin", title: "Isin", getCellValue: ({ securityId }: IPositionModel) => {
                const secu = securities[securityId];
                return isSecurityInstrumentSummaryModel(secu) ? secu.isin : undefined
            }, filteringEnabled: true
        },
        { name: "Quantity", title: "Qty", getCellValue: ({ quantity: value }: IPositionModel) => value, columnType: "decimal", filteringEnabled: true },
        { name: "MarketValueInPortfolioCurrency", title: "Mark Val Prtf Cur", getCellValue: ({ marketValueInPortfolioCcy }: IPositionModel) => marketValueInPortfolioCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "MarketValueInSecurityCurrency", title: "Mark Val Sec Cur", getCellValue: ({ marketValueInSecurityCcy }: IPositionModel) => marketValueInSecurityCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "Weight", title: "Weight", getCellValue: ({ weight }: IPositionModel) => weight, columnType: "precisePercentage2", aggregationType: "sum", filteringEnabled: true },
        { name: "AccruedInterestInPortfolioCcy", title: "Accr Int Portf Cur", getCellValue: ({ accruedInterestInPortfolioCcy }: IPositionModel) => accruedInterestInPortfolioCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "BookCostInPortfolioCcy", title: "Book Cost Portf Cur", getCellValue: ({ bookCostInPortfolioCcy }: IPositionModel) => bookCostInPortfolioCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "ProfitLossOnMarketPortfolioCcy", title: "P & L On Market Portf Cur", getCellValue: ({ profitLossOnMarketPortfolioCcy }: IPositionModel) => profitLossOnMarketPortfolioCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "ProfitLossOnFxPortfolioCcy", title: "P & L On Fx Portf Cur", getCellValue: ({ profitLossOnFxPortfolioCcy }: IPositionModel) => profitLossOnFxPortfolioCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "CostPrice", title: "Cost Price", getCellValue: ({ costPrice }: IPositionModel) => costPrice, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "OwnershipPercentage", title: "Ownership Percentage", getCellValue: ({ ownershipPercentage }: IPositionModel) => ownershipPercentage, columnType: "precisePercentage2", aggregationType: "sum", filteringEnabled: true },
        { name: "ValuationPrice", title: "Valuation Price", getCellValue: ({ valuationPrice }: IPositionModel) => valuationPrice, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "NbAccruedDays", title: "Nb Accr Days", getCellValue: ({ nbAccruedDays }: IPositionModel) => nbAccruedDays, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        ...classificationTypesColumns
    ], [classificationTypesColumns, portfolios, subFunds, referenceCountries, referenceCurrencies, securities]);
    const state: IGridState = {
        "PortfolioName": { width: 280 },
        "PortfolioCode": { width: 150, hidden: true },
        "PortfolioType": { width: 120, hidden: true },
        "Date": { width: 120 },
        "Country": { width: 120, hidden: true },
        "TargetSecurityCode": { width: 150, hidden: true },
        "TargetSecurityName": { width: 280 },
        "TargetSecurityType": { width: 120 },
        "TargetSecurityIssuer": { width: 200, hidden: true },
        "TargetSecurityMat": { width: 100, hidden: true },
        "TargetSecurityCurrency": { width: 130 },
        "TargetSecurityIsin": { width: 150 },
        "Quantity": { width: 180 },
        "MarketValueInPortfolioCurrency": { width: 180 },
        "Weight": { width: 110 },
        "OwnershipPercentage": { width: 110 },
        "AccruedInterestInPortfolioCcy": { width: 180, hidden: true },
        "BookCostInPortfolioCcy": { width: 180, hidden: true },
        "ProfitLossOnMarketPortfolioCcy": { width: 180, hidden: true },
        "MarketValueInSecurityCurrency": { width: 180, hidden: true },
        "ProfitLossOnFxPortfolioCcy": { width: 180, hidden: true },
        "CostPrice": { width: 180, hidden: true },
        "ValuationPrice": { width: 180, hidden: true },
        "NbAccruedDays": { width: 180, hidden: true },
        ...classificationTypesState
    };

    const getRowKey = (row: IPositionModel) => row.id;

    const handleSubmit = (model: IDailyDataSearchPositionsParameters, { setSubmitting }: FormikHelpers<IDailyDataSearchPositionsParameters>) => {
        positionSearch(model);
        setSubmitting(false);
    }
    const handleValidate = (values: IDailyDataSearchPositionsParameters) => {
        const errors: IErrors<IDailyDataSearchPositionsParameters> = {};
        if (!values.lastOnly) {
            if (!values.from) {
                errors.from = "From date is required";
            }
            if (values.to && values.from && values.from > values.to) {
                errors.to = "Must be greater than the start of period";
            }
        }
        return errors;
    }
    const handleRowSelect = ({ securityId }: IPositionModel) => {
        const { type } = securities[securityId];
        switch (type) {
            case "LoanSummaryModel":
                navigationNavigate({ screenKey: "Loans", sectionKey: "detail", parameters: { id: securityId } });
                break;
            case "CashSummaryModel":
                navigationNavigate({ screenKey: "BankAccounts", sectionKey: "detail", parameters: { id: securityId } });
                break;
            default:
                navigationNavigate({ screenKey: "Securities", sectionKey: "detail", parameters: { id: securityId } });
                break;
        }
    }
    const monthToDate = (function () {
        var date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1);
    })();
    return <Formik onSubmit={handleSubmit} validate={handleValidate} initialValues={{ lastOnly: true, onlyActivePortfolios: true, from: monthToDate } as IDailyDataSearchPositionsParameters} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;

    function renderForm({ isValid, submitForm, values: { lastOnly } }: FormikProps<IDailyDataSearchPositionsParameters>) {
        return (
            <SearchPanel
                isQuerying={positionsLoading}
                onSearchClick={submitForm}
                searchDisabled={!isValid}
                title="Positions"
                subTitle="Search by portfolio and time period"
                renderSearch={(<FieldBox display="flex" flexDirection="column">
                    <FieldBox display="flex" flexGrow="1">
                        <ManagedPortfolioMultiSelectField
                            name={oProps<IDailyDataSearchPositionsParameters>().path("portfolioIds")}
                            label="Portfolios"
                            dictionaries={dictionaries}
                            onSelected={positionAddPortfoliosToDictionary} />
                    </FieldBox>
                    <FieldBox display="flex" flexDirection="row">
                        <FormCheckBoxField name={oProps<IDailyDataSearchPositionsParameters>().path("onlyActivePortfolios")} label="Only active portfolios" />
                        <FormCheckBoxField name={oProps<IDailyDataSearchPositionsParameters>().path("lastOnly")} label="Only Last positions" />
                        {(!lastOnly) && <FormDatePickerField name={oProps<IDailyDataSearchPositionsParameters>().path("from")} label="From" />}
                        {(!lastOnly) && <FormDatePickerField name={oProps<IDailyDataSearchPositionsParameters>().path("to")} label="To" />}
                    </FieldBox>
                </FieldBox>
                )}>
                <ExtendedGrid
                    getRowId={getRowKey}
                    columns={columns}
                    rows={positions}
                    onRowClick={handleRowSelect}
                    initialState={state}
                    userCanGroup={true}

                    defaultExportFileName="positions.xlsx" />
            </SearchPanel>
        );
    }
}
// https://react-pivottable.js.org/