import * as React from "react";
import { useField } from "formik";
import { IMacroTypeModel, IEntityMetadataModel, IQuestionnaireDevelopmentItemModel, IMacroModel, DevelopmentItemModel } from "proxy/apiProxy";
import { oProps } from "tools/lib/utility";
import SurveyDesigner from "../Designers/SurveyDesigner";
import { DevelopmentItemDiagnostics, ITypedMacro } from "../slice";
import FormMacroEditor from "../Designers/FormMacroEditor";
import { EditorReferencePayload } from "../Designers/INotControllableEditorProps";

export interface IQuestionnaireDevelopmentItemRefs {
    questionnaireTemplateRef: React.MutableRefObject<EditorReferencePayload<string>>;
    questionnaireLoadMacroRef: React.MutableRefObject<EditorReferencePayload<IMacroModel>>;
    questionnaireCompleteMacroRef: React.MutableRefObject<EditorReferencePayload<IMacroModel>>;
}
export interface IQuestionnaireDevelopmentItemEditorProps extends IQuestionnaireDevelopmentItemRefs {
    type: DevelopmentItemModel["type"];
    tabValue: string | undefined;
    metadata: Record<string | number, IEntityMetadataModel>;
    diagnostics?: DevelopmentItemDiagnostics;
    onCodeChanged: (macro: ITypedMacro) => void;
}
export default function QuestionnaireDevelopmentItemEditor({ tabValue, metadata, diagnostics, questionnaireTemplateRef, questionnaireCompleteMacroRef, questionnaireLoadMacroRef, onCodeChanged }: IQuestionnaireDevelopmentItemEditorProps) {
    const [{ value: id }] = useField(oProps<IQuestionnaireDevelopmentItemModel>().path("id"));
    switch (tabValue) {
        case "loadMacro": return <FormMacroEditor key={`loadMacro${id}`}
            type={IMacroTypeModel.QuestionnaireOnLoad}
            onCodeChanged={onCodeChanged}
            // field={oProps<IQuestionnaireDevelopmentItemModel>().path("onLoadMacro")}
            refToGetValue={questionnaireLoadMacroRef}
            executing={false}
            diagnostics={diagnostics && diagnostics[IMacroTypeModel.QuestionnaireOnLoad]}
            metadata={metadata}
            error={undefined} />;
        case "completeMacro": return <FormMacroEditor key={`completeMacro${id}`}
            type={IMacroTypeModel.QuestionnaireOnComplete}
            onCodeChanged={onCodeChanged}
            // field={oProps<IQuestionnaireDevelopmentItemModel>().path("onCompleteMacro")}
            refToGetValue={questionnaireCompleteMacroRef}
            executing={false}
            diagnostics={diagnostics && diagnostics[IMacroTypeModel.QuestionnaireOnComplete]}
            metadata={metadata}
            error={undefined} />;
        case "questionnaire": return <SurveyDesigner key={`questionnaire${id}`}
            refToGetValue={questionnaireTemplateRef} />;
    }
    return null;
}
