import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import produce from "immer";
import { IActiveScreen, defaultActiveNode, IUrlKey } from "tools/lib/UrlDictionary";
import { ISiteMapSelection } from "tools/lib/SiteMap";
// import { navigationTree, defaultActiveNode } from "siteMap";
// import { INavigationTreeNodeModuleCategory, INavigationTree, INavigationTreeNodeFull, INavigationTreeNode } from "tools/lib/SiteMap";

// export const createIncrementCounter1Action = createPayloadActionFactory<ActionType, IChangeCounter1Payload>("INCREMENT_COUNTER1");
// export const createDecrementCounter1Action = createPayloadActionFactory<ActionType, IChangeCounter1Payload>("DECREMENT_COUNTER1");

export interface IGoToScreenSectionPayload {
    screenKey?: IUrlKey;
    sectionKey?: string;
    parameters?: {
        [parameterName: string]: any;
    }
}

export interface INavigationChanged {
    activeScreen: IActiveScreen;
    activeSiteMapSelection?: ISiteMapSelection;
}

export const ActionFactories = produceActionFactories({

    /**
     * @deprecated The goal is to get rid of this so that the screen it self triggers the loading of values
     */
    navigationNavigate: (payload?: IGoToScreenSectionPayload) => payload,
    /**
     * @deprecated The goal is to get rid of this so that the screen it self triggers the loading of values
     */
    navigationExpandModuleCategory: (payload: number) => payload,
    /**
     * @deprecated The goal is to get rid of this so that the screen it self triggers the loading of values
     */
    navigationChanged: (payload: INavigationChanged) => payload,
    /**
     * @deprecated The goal is to get rid of this so that the screen it self triggers the loading of values
     */
    navigationModuleChange: () => undefined,
});

export interface IState {
    /**
     * @deprecated The goal is to get rid of this so that the screen it self triggers the loading of values
     */
    navigationOpenedModuleCategoryIndex: number;
    /**
     * @deprecated The goal is to get rid of this so that the screen it self triggers the loading of values
     */
    navigationActiveScreen?: IActiveScreen;
    /**
     * @deprecated The goal is to get rid of this so that the screen it self triggers the loading of values
     */
    navigationActiveSiteMapNode?: ISiteMapSelection;
}

export function reducer(
    state: IState = {
        navigationOpenedModuleCategoryIndex: 0,
        navigationActiveScreen: defaultActiveNode,
        // navigationTree
    },
    action: (AnyActionOf<typeof ActionFactories>)
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "navigationExpandModuleCategory":
                draft.navigationOpenedModuleCategoryIndex = action.payload;
                break;
            case "navigationChanged":
                draft.navigationActiveScreen = action.payload.activeScreen;
                draft.navigationActiveSiteMapNode = action.payload.activeSiteMapSelection;
                break;
        }
    });
}
