import { IExtensionFieldModel, IParametersModel, IExtensionFieldTypeModel } from "proxy/apiProxy";
import { Table, TableRow, TableCell, TableBody, IconButton, Typography, TableHead } from "@material-ui/core";
import { getEnumLabels, oProps } from "tools/lib/utility";
import DeleteIcon from "@material-ui/icons/Delete";
import { FieldArray, FieldArrayRenderProps, useField } from "formik";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import FormTextField from "tools/fieldComponents/FormTextField";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import StickyFab from "tools/components/StickyFab";
import AddIcon from '@material-ui/icons/Add';
import { useCallback } from "react";

const extensionFieldTypeLabel = getEnumLabels(IExtensionFieldTypeModel);
export interface IExtensionFieldsProps {
    extensionFieldsField: "portfolioExtensionFields" | "securityExtensionFields" | "shareClassExtensionFields" | "subFundExtensionFields" | "personExtensionFields" | "companyExtensionFields" | "sicavExtensionFields";
};
export default function ExtensionFields({
    extensionFieldsField }: IExtensionFieldsProps) {
    const [, { value: extensionFields = [] }] = useField<IExtensionFieldModel[]>(extensionFieldsField);

    return <FieldArray name={oProps<IParametersModel>().path(extensionFieldsField)}>{renderExtensionFields}</FieldArray>;
    function renderExtensionFields({ move, push, remove, form: { validateForm } }: FieldArrayRenderProps) {
        const handleAdd = () => {
            push({} as IExtensionFieldModel);
            setTimeout(validateForm);
        }
        const handleRemove = (idx: number) => {
            remove(idx);
            setTimeout(validateForm);
        }
        const handleMove = (from: number, to: number) => {
            move(from, to);
            setTimeout(validateForm);
        }
        return <ReadOnlyContext.Consumer>
            {readOnly => <>
                {!extensionFields.length && <Typography>No extension field</Typography>}
                {!!extensionFields.length && <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Required</TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Label</TableCell>
                            <TableCell>Type</TableCell>
                            {!readOnly && <TableCell />}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {extensionFields.map((_, index) => <ExtensionField
                            key={index}
                            index={index}
                            maxIndex={extensionFields.length - 1}
                            readOnly={readOnly}
                            onDelete={handleRemove}
                            pathToValue={oProps<IParametersModel>().path(extensionFieldsField, index)}
                            onMove={handleMove} />)}
                    </TableBody>
                </Table>}
                {!readOnly && <StickyFab onClick={handleAdd}>
                    <AddIcon />
                </StickyFab>}
            </>}
        </ReadOnlyContext.Consumer>
    }
}

interface IExtensionField {
    index: number;
    maxIndex: number;
    onMove: (from: number, to: number) => void;
    readOnly: boolean;
    onDelete: (index: number) => void;
    pathToValue: string;
}
function ExtensionField({ index, onMove, maxIndex, readOnly, pathToValue, onDelete }: IExtensionField) {
    const handleMoveUp = useCallback(() => onMove(index, index - 1), [index, onMove]);
    const handleMoveDown = useCallback(() => onMove(index, index + 1), [index, onMove]);
    const handleDelete = useCallback(() => onDelete(index), [index, onDelete]);
    return <TableRow>
        <TableCell><FormCheckBoxField name={oProps<IExtensionFieldModel>(pathToValue).path("isMandatory")} /></TableCell>
        <TableCell><FormTextField name={oProps<IExtensionFieldModel>(pathToValue).path("code")} required={true} /></TableCell>
        <TableCell><FormTextField name={oProps<IExtensionFieldModel>(pathToValue).path("label")} required={true} /></TableCell>
        <TableCell><FormSimpleSelectField name={oProps<IExtensionFieldModel>(pathToValue).path("type")} options={extensionFieldTypeLabel} required={true} /></TableCell>
        {!readOnly && <TableCell align="right">
            <IconButton
                size="small"
                aria-label="Unlink"
                style={{ verticalAlign: "middle" }}
                onClick={handleDelete} >
                <DeleteIcon />
            </IconButton>
            <IconButton disabled={index >= maxIndex} onClick={handleMoveDown} >
                <ArrowDownwardIcon />
            </IconButton>
            <IconButton disabled={index <= 0} onClick={handleMoveUp}>
                <ArrowUpwardIcon />
            </IconButton>
        </TableCell>}
    </TableRow>;
}