import { SecuritySummaryModel, IIndexSummaryModel, PeerModel, isSecurityInstrumentSummaryModel } from "proxy/apiProxy";
import { Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton, Typography } from "@material-ui/core";
import { oProps, today } from "tools/lib/utility";
import SecuritySearch from "components/searchers/SecuritySearch";
import CloseIcon from '@material-ui/icons/Close';
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import { IGetSecuritySummary } from "reducers/Reference";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { FieldArray, FieldArrayRenderProps, useField } from "formik";
import FieldBox from "tools/components/FieldBox";
import { IndexSearch } from "components/searchers/IndexSearch";
import { getSecurityTypeLabel } from "features/Security/getSecurityTypeLabel";

export interface IPortfolioPeersProps {
    peersField: string;
    dictionaries: {
        securities: Record<number, SecuritySummaryModel>;
        indexes: Record<number, IIndexSummaryModel>;
    }
    onSecurityLoaded: (security: IGetSecuritySummary) => void;
    onIndexLoaded: (security: IIndexSummaryModel) => void;
    onSecurityClick?: (securityId: number) => void;
    onIndexClick?: (indexId: number) => void;
};
export default function PortfolioPeers({
    peersField, dictionaries, onSecurityLoaded, onSecurityClick, onIndexLoaded, onIndexClick }: IPortfolioPeersProps) {
    const [{ value: peers }] = useField<PeerModel[]>(peersField);

    const handlePeerSelected = (peer: PeerModel) => {
        switch (peer.type) {
            case "SecurityPeerModel":
                if (onSecurityClick) {
                    onSecurityClick(peer.securityId);
                }
                break;
            case "IndexPeerModel":
                if (onIndexClick) {
                    onIndexClick(peer.indexId);
                }
                break;
        }
    }
    return <FieldArray name={peersField} render={renderPeers} validateOnChange={true} />
    function renderPeers({ push, remove, form: { validateForm } }: FieldArrayRenderProps): React.ReactNode {
        const handleSecuritySelected = (found: IGetSecuritySummary) => {
            const { security } = found;
            // setShareClassesIdValue([...shareClassesId, security.id]);
            const peer: PeerModel = {
                type: "SecurityPeerModel",
                fromDate: today(),
                securityId: security.id
            }
            onSecurityLoaded(found);
            push(peer);
        }
        const handleIndexSelected = (found: IIndexSummaryModel) => {
            const peer: PeerModel = {
                type: "IndexPeerModel",
                fromDate: today(),
                indexId: found.id
            }
            onIndexLoaded(found);
            push(peer);
        }
        const handleRemove = (positionIndex: number) => {
            remove(positionIndex);
            setTimeout(() => validateForm(), 0);
        }
        return (<ReadOnlyContext.Consumer>
            {readOnly => <>
                {!peers.length && <Typography>No peer</Typography>}
                {!!peers.length && <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Internal Code</TableCell>
                            <TableCell>ISIN</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>From</TableCell>
                            <TableCell>To</TableCell>
                            {!readOnly && <TableCell />}
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {peers.map((peer, idx) => <PeerLine
                            key={idx}
                            peer={peer}
                            peersField={peersField}
                            dictionaries={dictionaries}
                            onClick={handlePeerSelected}
                            onDelete={handleRemove}
                            positionIndex={idx}
                            readOnly={readOnly} />)}
                    </TableBody>
                </Table>}
                {!readOnly && <FieldBox display="flex" flexDirection="row">
                    <IndexSearch label="Add an index..." onSelected={handleIndexSelected} />
                    <SecuritySearch label="Add a security..." onSelected={handleSecuritySelected} />
                </FieldBox>}
            </>}
        </ReadOnlyContext.Consumer>);
    }
}
// function getKey(peer: PeerModel) {
//     switch (peer.type) {
//         case "SecurityPeerModel": return `${peer.type}${peer.securityId}`;
//         case "IndexPeerModel": return `${peer.type}${peer.indexId}`;
//     }
// }
interface IPeerLineProps {
    positionIndex: number;
    peersField: string;
    peer: PeerModel;
    onDelete: (positionIndex: number) => void;
    onClick: (peer: PeerModel) => void;
    readOnly: boolean;
    dictionaries: {
        securities: Record<number, SecuritySummaryModel>;
        indexes: Record<number, IIndexSummaryModel>;
    }
}
function PeerLine({ peer, peersField, onDelete, onClick, positionIndex, readOnly, dictionaries: { securities, indexes } }: IPeerLineProps) {
    const handleClick = () => onClick(peer);
    const handleDelete = () => onDelete(positionIndex);
    const fromDatePath = oProps<PeerModel[]>(peersField).path(positionIndex, "fromDate")
    const toDatePath = oProps<PeerModel[]>(peersField).path(positionIndex, "toDate")
    return <TableRow>
        <TableCell>{peer.type === "IndexPeerModel" ? "Index" : getSecurityTypeLabel(securities[peer.securityId]?.type)}</TableCell>
        <TableCell>{peer.type === "IndexPeerModel" ? indexes[peer.indexId]?.internalCode : securities[peer.securityId]?.internalCode}</TableCell>
        <TableCell>{peer.type === "SecurityPeerModel" ? getSecurityIsin(securities[peer.securityId]) : null}</TableCell>
        <TableCell>{peer.type === "IndexPeerModel" ? indexes[peer.indexId]?.name : securities[peer.securityId]?.name}</TableCell>
        <TableCell>
            <FormDatePickerField name={fromDatePath} required={true} />
        </TableCell>
        <TableCell>
            <FormDatePickerField name={toDatePath} />
        </TableCell>
        {!readOnly && <TableCell align="right">
            <Tooltip title="Unlink">
                <IconButton
                    size="small"
                    aria-label="Unlink"
                    style={{ verticalAlign: "middle" }}
                    onClick={handleDelete} >
                    <CloseIcon />
                </IconButton>
            </Tooltip>
        </TableCell>}
        <TableCell align="right">
            <Tooltip title="Open">
                <IconButton
                    size="small"
                    aria-label="Open"
                    style={{ verticalAlign: "middle" }}
                    onClick={handleClick} >
                    <OpenInBrowser />
                </IconButton>
            </Tooltip>
        </TableCell>
    </TableRow>
}
function getSecurityIsin(security: SecuritySummaryModel) {
    if (isSecurityInstrumentSummaryModel(security)) {
        return security.isin;
    }
    return;
}