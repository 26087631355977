import * as React from "react";
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Paper, LinearProgress } from "@material-ui/core";
import HeaderTitleLoading from "./HeaderTitleLoading";
import Skeleton from '@material-ui/lab/Skeleton';
import { useReduxActions } from "tools/lib/reduxStoreAccess";

const useStyles = makeStyles(theme => createStyles({
    detailContent: {
        padding: theme.spacing(2)
    },
    detailContentNoPadding: {
        padding: 0
    },
}));

const StyledPaper = withStyles(theme =>
    createStyles({
        root: {
            height: "100%",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column"
        }
    })
)(Paper);

export interface IDetailPanelLoadingProps {
    tabNumber?: number;
    hasSubTitle?: boolean;
    onBackClick?: (() => void) | boolean;
}
function range(count: number) {
    const elements: number[] = [];
    for (let index = 0; index < count; index++) {
        elements.push(index + 1);
    }
    return elements;
}
function DetailPanelLoading({ tabNumber, hasSubTitle = true, onBackClick }: React.PropsWithChildren<IDetailPanelLoadingProps>) {
    const { navigationNavigate } = useReduxActions("navigation");
    const handleBackClick = React.useCallback(() => {

        if (typeof onBackClick === "boolean" && onBackClick) {
            return () => navigationNavigate(undefined);
        }
        if (typeof onBackClick === "function") {
            return onBackClick;
        }
        return undefined;
    }, [navigationNavigate, onBackClick])
    const classes = useStyles();
    const virtualElements = React.useMemo(() => range(Math.floor(Math.random() * 10) + 1).map(i =>
        `${(Math.floor(Math.random() * 9) + 1) * 10}%`
    ), []);
    return (
        <StyledPaper>
            <LinearProgress variant="query" />
            <HeaderTitleLoading
                hasSubTitle={hasSubTitle}
                onBackClick={handleBackClick}
                tabNumber={tabNumber} />
            <Box className={classes.detailContent} flexGrow="1" overflow="auto" height="100%" position="relative">
                {virtualElements.map((i, idx) => <Skeleton key={idx} width={i} animation="wave" height="50px" />)}
            </Box>
        </StyledPaper>
    );
}
export default React.memo(DetailPanelLoading);