import produce from "immer";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IMacroScriptTypeModel } from "proxy/apiProxy";
import { IGitFile } from "tools/lib/octokitHelper";

export const ActionFactories = produceActionFactories({
    communityMacroLoadAll: (payload: IMacroScriptTypeModel | "customScreen") => payload,
    communityMacroLoadedAll: (payload: IGitFile[]) => payload,
    communityMacroLicenseLoaded: (payload: string) => payload,
    communityMacroLoad: (payload: string) => payload,
    communityMacroLoaded: (payload: string) => payload
});
export interface IState {
    communityMacroAllLoading: boolean;
    communityMacroLoading: boolean;
    communityMacroSelected?: string;
    communityMacros: IGitFile[];
    communityMacroLicense?: string;
}
export const reducer = (
    state: IState = {
        communityMacroAllLoading: false,
        communityMacroLoading: false,
        communityMacros: []
    },
    action: AnyActionOf<typeof ActionFactories>
) => produce(state, draft => {
    switch (action.type) {
        case "communityMacroLoadAll":
            draft.communityMacroAllLoading = true;
            break;
        case "communityMacroLoadedAll":
            draft.communityMacroAllLoading = false;
            draft.communityMacros = action.payload;
            break;
        case "communityMacroLoad":
            draft.communityMacroLoading = true;
            draft.communityMacroSelected = undefined;
            break;
        case "communityMacroLoaded":
            draft.communityMacroLoading = false;
            draft.communityMacroSelected = action.payload;
            break;
        case "communityMacroLicenseLoaded":
            draft.communityMacroLicense = action.payload;
            break;
    }
});
