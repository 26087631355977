import NavigationRoute from "components/global/NavigationRoute";
import Relationships from "./Relationships";
import Relationship from "./Relationship";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";
import { RelationshipModel } from "proxy/apiProxy";

export default function RoleRelationshipScreen() {
    return <>
        <NavigationRoute
            gridSize={12}
            exact={true}
            slideDirection={"right"}
            component={Relationships}
            type={"RoleRelationshipModel" as RelationshipModel["type"]}
            path={getSiteScreenUrl("ServiceProvidersCollaborators")} />
        <NavigationRoute
            gridSize={12}
            exact={true}
            slideDirection={"right"}
            component={Relationships}
            type={"CounterpartyRelationshipModel" as RelationshipModel["type"]}
            path={getSiteScreenUrl("Counterparties")} />
        <NavigationRoute
            gridSize={12}
            exact={true}
            slideDirection={"right"}
            component={Relationships}
            type={"InvestorRelationshipModel" as RelationshipModel["type"]}
            path={getSiteScreenUrl("Investors")} />

        <NavigationRoute
            gridSize={12}
            slideDirection={"left"}
            component={Relationship}
            path={getSiteDetailScreenUrl("ServiceProvidersCollaborators")} />
        <NavigationRoute
            gridSize={12}
            slideDirection={"left"}
            component={Relationship}
            path={getSiteDetailScreenUrl("Counterparties")} />
        <NavigationRoute
            gridSize={12}
            slideDirection={"left"}
            component={Relationship}
            path={getSiteDetailScreenUrl("Investors")} />
    </>
}