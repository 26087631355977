import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import { IFeatureModel, IDocumentDefinitionModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import DocumentDefinitionData from "./DocumentDefinitionData";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import DocumentStructure from "./DocumentStructure";
export default function Security() {
    const { navigationActiveScreen: navigationActiveUrlNode } = useReduxSelections("navigation");
    const { classificationTypes } = useReduxSelections("noteClassificationType");
    const { referenceReportTemplateCategories = {} } = useReduxSelections("reference");
    const { navigationNavigate } = useReduxActions("navigation");
    const {
        documentDefinition,
        documentDefinitionLoading,
        documentDefinitionReportTemplates = []
    } = useReduxSelections("documentDefinition");
    const {
        all: notes
    } = useReduxSelections("note");
    const {
        documentDefinitionDelete,
        documentDefinitionSave
    } = useReduxActions("documentDefinition");
    const handleBack = () => navigationNavigate(undefined);
    if (!documentDefinition) {
        return <DetailPanelLoading onBackClick={handleBack} tabNumber={2} hasSubTitle={false} />;
    }
    const title = !!documentDefinition.id ? (documentDefinition.name || "") : `New Definition`;
    const tabs: ISubMenu[] = [{
        label: "Data",
        value: "detail"
    }, {
        label: "Definition",
        value: "definition"
    }];
    const tabValue = navigationActiveUrlNode?.activeSectionKey;

    const handleTabValueChanged = (value: any) => navigationNavigate({ sectionKey: value as string });
    const handleDelete = () => {
        if (documentDefinition?.id) {
            documentDefinitionDelete(documentDefinition.id);
        }
    }
    const handleSubmit = (values: IDocumentDefinitionModel, { setSubmitting }: FormikHelpers<IDocumentDefinitionModel>) => {
        documentDefinitionSave(values);
        setSubmitting(false);
    }

    return <Formik onSubmit={handleSubmit} initialValues={documentDefinition} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm, values }: FormikProps<IDocumentDefinitionModel>) {
        return <WriteAccess value={IFeatureModel.MarketDataWrite}><DetailPanel
            isQuerying={documentDefinitionLoading}
            title={title}
            canSave={dirty && isValid}
            noPadding={tabValue === "definition"}
            onSaveClick={submitForm}
            canDelete={!!values.id}
            onDeleteClick={handleDelete}
            onBackClick={handleBack}
            saveAllowed={IFeatureModel.MarketDataWrite}
            deleteAllowed={IFeatureModel.MarketDataWrite}
            badge={!values.id ? "new" : undefined}
            tabs={tabs}
            tabValue={tabValue}
            onTabValueChanged={handleTabValueChanged}>
            {(tabValue === "detail") && <DocumentDefinitionData
                reportTemplateCategories={referenceReportTemplateCategories}
                classificationTypes={classificationTypes}
                reportTemplates={documentDefinitionReportTemplates} />}
            {(tabValue === "definition") && <DocumentStructure notes={notes} />}
        </DetailPanel></WriteAccess>
    }
}
