import { MacroScriptSummaryModel, IFeatureModel, ReportSummaryModel, isMacroScriptSummaryModel } from "proxy/apiProxy";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanel from "tools/components/DetailPanel";
import { Grid, TextField, Card, CardContent, Typography } from "@material-ui/core";
import ExplorerElement from './ExplorerElement';
import FileTableOutlineIcon from 'mdi-material-ui/FileTableOutline'
import FileChartOutlineIcon from 'mdi-material-ui/FilePdfBox'
import React from "react";
import { groupElements } from "./groupElements";
// import Divider from '@material-ui/core/Divider';
function isMacro(item: MacroScriptSummaryModel | ReportSummaryModel): item is MacroScriptSummaryModel {
    return isMacroScriptSummaryModel(item);
}
export default function DashboardsExport() {
    const { macroScripts = [], macroScriptRunning, reports = [], reportIssuing, macroScriptsLoadingAll, reportsLoadingAll, dictionaries } = useReduxSelections("dashboard");
    const { referenceMacroScriptCategories = {} } = useReduxSelections("reference");

    const { dashboardMacroScriptExecute, dashboardReportGenerate } = useReduxActions("dashboard");
    const isGranted = useGrants();

    const [filter, setFilter] = React.useState("");
    const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => setFilter(event.target.value);

    const elements = React.useMemo(() => {
        const rets: (MacroScriptSummaryModel | ReportSummaryModel)[] = [
            ...isGranted(IFeatureModel.ImportExportProcessMacroExecute) ? macroScripts.filter(i => i.type === "FileRetrieverMacroScriptSummaryModel") : [],
            ...isGranted(IFeatureModel.ReportExecute) ? reports : []]
            .filter(i => i.name.toLowerCase().includes(filter.toLowerCase()))
            .sort((a, b) => a.name.toLowerCase() === b.name.toLowerCase() ? 0 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        return rets;
    }, [filter, isGranted, macroScripts, reports]);


    const { groups, notGrouped } = React.useMemo(() => groupElements(elements, v => isMacro(v)
        ? v.categoryId
        : v.type === "TemplateReportSummaryModel"
            ? dictionaries.reportTemplates[v.reportTemplateId]?.categoryId
            : dictionaries.documentDefinitions[v.documentDefinitionId]?.categoryId), [dictionaries.reportTemplates, dictionaries.documentDefinitions, elements]);

    const handleClick = (elt: MacroScriptSummaryModel | ReportSummaryModel) => {
        if (isMacro(elt)) {
            dashboardMacroScriptExecute({ type: "FileRetrieverMacroScriptModel", id: elt.id });
        }
        else {
            dashboardReportGenerate(elt.id);
        }
    }

    return <DetailPanel title="Exports" isQuerying={macroScriptsLoadingAll || reportsLoadingAll}>
        <TextField value={filter} onChange={handleChangeFilter} label="Filter" />

        {groups.map(({ categoryId, elements }) => <Card key={categoryId} style={{ marginTop: 8, marginBottom: 8 }} >
            <CardContent>
                <Typography gutterBottom={true} variant="h5" component="h2">
                    {referenceMacroScriptCategories[categoryId].name}
                </Typography>
                <Grid container={true} spacing={1} style={{ marginTop: 16 }}>
                    {elements.map(elt => <Panel
                        key={`${isMacro(elt) ? "m" : "b"}${elt.id}`}
                        onClick={handleClick}
                        elt={elt}
                        reportIssuing={reportIssuing}
                        macroScriptRunning={macroScriptRunning} />)}
                </Grid>
            </CardContent>
        </Card>)}


        <Grid container={true} spacing={1} style={{ marginTop: 16 }}>
            {notGrouped.map(elt => <Panel
                key={`${isMacro(elt) ? "m" : "b"}${elt.id}`}
                onClick={handleClick}
                elt={elt}
                reportIssuing={reportIssuing}
                macroScriptRunning={macroScriptRunning} />)}
        </Grid>
    </DetailPanel>
}

interface IPanelProps {
    elt: ReportSummaryModel | MacroScriptSummaryModel;
    onClick: (elt: ReportSummaryModel | MacroScriptSummaryModel) => void;
    macroScriptRunning: {
        [id: number]: boolean;
    }
    reportIssuing: {
        [id: number]: boolean;
    }
}
function Panel({ reportIssuing, macroScriptRunning, elt, onClick }: IPanelProps) {
    const issuing = isMacro(elt) ? macroScriptRunning[elt.id] : reportIssuing[elt.id];
    const handleClick = () => onClick(elt);
    const icon = isMacro(elt) ? FileTableOutlineIcon : FileChartOutlineIcon;
    return <Grid item={true} lg={4} md={6} sm={12} alignItems="stretch" style={{ display: "flex" }}>
        <ExplorerElement icon={icon} name={elt.name} onClick={handleClick} processing={issuing} />
    </Grid>
}
