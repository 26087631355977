import * as React from "react";
import { SecuritySummaryModel, ICurrencyModel, IBenchmarkTargetExposureModel, IIndexSummaryModel, IPortfolioModel, IFrequencyTypeModel, IBenchmarkTargetExposurePositionModel, ICountryModel } from "proxy/apiProxy";
import { Accordion, AccordionSummary, AccordionDetails, Tooltip, IconButton, AccordionActions, Button, Typography, Grid, Divider } from "@material-ui/core";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import { useField } from "formik";
import { IGetSecuritySummary } from "reducers/Reference";
import { FieldArray, FieldArrayRenderProps } from "formik";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatDate, getEnumLabels, oProps, today } from "tools/lib/utility";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import FieldBox from "tools/components/FieldBox";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FormTextField from "tools/fieldComponents/FormTextField";
import StickyFab from "tools/components/StickyFab";
import AddIcon from '@material-ui/icons/Add';
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import { IndexSearch } from "components/searchers/IndexSearch";
import SecuritySearch from "components/searchers/SecuritySearch";
import { IndexSummaryReadOnly } from "components/summaries/IndexSummary";
import {SecuritySummaryReadOnly} from "components/summaries/SecuritySummary";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
const useBenchmarkExposureStyles = makeStyles(theme =>
    createStyles({
        heading: {
            // fontSize: theme.typography.pxToRem(15),
            // flexBasis: '33.33%',
            // flexShrink: 0,
        },
        secondaryHeading: {
            // fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
            marginLeft: theme.spacing(1)
        },
    }),
);
const frequencies = getEnumLabels(IFrequencyTypeModel);
interface ITargetPositionProps {
    targetPosition: IBenchmarkTargetExposurePositionModel,
    referenceCountries: Record<number, ICountryModel>;
    readOnly?: boolean;
    referenceCurrencies: Record<number, ICurrencyModel>;
    indexes: Record<number, IIndexSummaryModel>;
    securities: Record<number, SecuritySummaryModel>;
    onSecurityClick: (securityId: number) => void;
    onIndexClick: (indexId: number) => void;
    index: number;
    onDelete: (index: number) => void;
    fieldPath: string;
}
function TargetPosition({
    index,
    onDelete,
    targetPosition: {
        indexId,
        securityId
    },
    referenceCountries,
    referenceCurrencies,
    indexes,
    securities,
    onSecurityClick,
    onIndexClick,
    readOnly,
    fieldPath
}: ITargetPositionProps) {
    const handleDeleteClick = () => onDelete(index);
    const handleOnView = () => {
        if (indexId) {
            onIndexClick(indexId);
        }
        else if (securityId) {
            onSecurityClick(securityId);
        }
    }
    return <Grid container={true} spacing={2}>
        <Grid item={true} xs={4}>
            {!!indexId && <IndexSummaryReadOnly label="Index" index={indexes[indexId]} countries={referenceCountries} currencies={referenceCurrencies} />}
            {!!securityId && <SecuritySummaryReadOnly security={securities[securityId]} currencies={referenceCurrencies} />}
        </Grid>
        <Grid item={true} xs={2}>
            <FormTextField name={oProps<IBenchmarkTargetExposurePositionModel>(fieldPath).path("weight")} label="Weight" isNumber={true} required={true} />
        </Grid>
        <Grid item={true} xs={2}>
            <FormCheckBoxField name={oProps<IBenchmarkTargetExposurePositionModel>(fieldPath).path("applyFxRate")} label="Apply Fx rate" />
        </Grid>
        <Grid item={true} xs={3}>
            {!!indexId && <FormCheckBoxField name={oProps<IBenchmarkTargetExposurePositionModel>(fieldPath).path("replicateIndexExposureInBenchmarkComposition")} label="Replicate exposure in composition" />}
        </Grid>
        {!readOnly && <Grid item={true} xs={1} alignItems="flex-end">
            <Tooltip title="Delete">
                <IconButton
                    size="small"
                    style={{ verticalAlign: "middle" }}
                    onClick={handleDeleteClick} >
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Open">
                <IconButton
                    size="small"
                    style={{ verticalAlign: "middle" }}
                    onClick={handleOnView} >
                    <OpenInBrowser />
                </IconButton>
            </Tooltip>
        </Grid>}
    </Grid>;
}
interface IBenchmarkExposureIndexProps {
    referenceCountries: Record<number, ICountryModel>;
    referenceCurrencies: Record<number, ICurrencyModel>;
    indexes: Record<number, IIndexSummaryModel>;
    securities: Record<number, SecuritySummaryModel>;
    onShareClassLoaded: (security: IGetSecuritySummary) => void;
    onIndexLoaded: (index: IIndexSummaryModel) => void;
    onSecurityClick: (securityId: number) => void;
    onIndexClick: (indexId: number) => void;
    targetPositions: IBenchmarkTargetExposurePositionModel[];
    readOnly?: boolean;
    fieldPath: string
}
function BenchmarkExposureIndex({
    referenceCountries,
    referenceCurrencies,
    indexes,
    securities,
    readOnly = false,
    onShareClassLoaded,
    onIndexLoaded,
    onSecurityClick,
    onIndexClick,
    targetPositions,
    fieldPath, }: IBenchmarkExposureIndexProps) {
    return <FieldArray name={fieldPath} render={renderBenchmarkExposures} validateOnChange={true} />;
    function renderBenchmarkExposures({ remove, push }: FieldArrayRenderProps): React.ReactNode {
        const handleSelectedIndex = (selected: IIndexSummaryModel) => {
            push({
                indexId: selected.id,
                weight: 0,
                applyFxRate: false,
                replicateIndexExposureInBenchmarkComposition: false
            } as IBenchmarkTargetExposurePositionModel);
            onIndexLoaded(selected);
        }
        const handleSelectedSecurity = (selected: IGetSecuritySummary) => {
            push({
                securityId: selected.security.id,
                weight: 0,
                applyFxRate: false,
                replicateIndexExposureInBenchmarkComposition: false
            } as IBenchmarkTargetExposurePositionModel);
            onShareClassLoaded(selected);
        }
        return <>
            <Divider />
            <Typography gutterBottom={true} variant="h6" component="h2">Composition</Typography>
            <FieldBox display="flex" flexDirection="column">
                {targetPositions.length ? <>
                    {targetPositions.map((targetPosition, index) => <TargetPosition key={index}
                        readOnly={readOnly}
                        referenceCountries={referenceCountries}
                        referenceCurrencies={referenceCurrencies}
                        indexes={indexes}
                        securities={securities}
                        onSecurityClick={onSecurityClick}
                        onIndexClick={onIndexClick}
                        fieldPath={`${fieldPath}[${index}]`}
                        targetPosition={targetPosition}
                        onDelete={remove}
                        index={index} />)}
                </> : <Typography>No position is defined in this composition</Typography>}
                {!readOnly && <FieldBox display="flex" flexDirection="row">
                    <IndexSearch label="Add an index..." onSelected={handleSelectedIndex} />
                    <SecuritySearch label="Add a security..." onSelected={handleSelectedSecurity} />
                </FieldBox>}
            </FieldBox>
        </>
    }
}
interface IBenchmarkExposureProps {
    referenceCountries: Record<number, ICountryModel>;
    referenceCurrencies: Record<number, ICurrencyModel>;
    indexes: Record<number, IIndexSummaryModel>;
    securities: Record<number, SecuritySummaryModel>;
    onShareClassLoaded: (security: IGetSecuritySummary) => void;
    onIndexLoaded: (index: IIndexSummaryModel) => void;
    onSecurityClick: (securityId: number) => void;
    onIndexClick: (indexId: number) => void;
    targetExposureField: string;
    benchmarkExposure: IBenchmarkTargetExposureModel;
    index: number;
    readOnly?: boolean,
    onDelete: (index: number) => void;
}
function BenchmarkExposure({
    referenceCountries,
    referenceCurrencies,
    indexes,
    securities,
    onShareClassLoaded,
    onIndexLoaded,
    onSecurityClick,
    onIndexClick,
    targetExposureField,
    readOnly = false,
    benchmarkExposure: { name, fromDate, targetPositions },
    onDelete,
    index }: IBenchmarkExposureProps) {
    const classes = useBenchmarkExposureStyles();
    const handleDelete = () => onDelete(index);
    const [isExpanded, setIsExpanded] = React.useState(false);
    const handleChangeExpanded = (event: React.ChangeEvent<{}>, exp: boolean) => setIsExpanded(exp);
    return <Accordion expanded={index === 0 || isExpanded} onChange={handleChangeExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>{name}</Typography>
            <Typography className={classes.secondaryHeading}>From {formatDate(fromDate)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <FieldBox display="flex" flexDirection="column">
                <FieldBox display="flex" flexDirection="row">
                    <FormTextField name={oProps<IBenchmarkTargetExposureModel[]>(targetExposureField).path(index, "name")} label="Name" required={true} />
                    <FormDatePickerField name={oProps<IBenchmarkTargetExposureModel[]>(targetExposureField).path(index, "fromDate")} label="Start date" required={true} />
                    <FormSimpleSelectField name={oProps<IBenchmarkTargetExposureModel[]>(targetExposureField).path(index, "restrikingFrequency")} label="Restriking frequency" options={frequencies} required={true} />
                </FieldBox>
                <BenchmarkExposureIndex
                    readOnly={readOnly}
                    targetPositions={targetPositions}
                    referenceCountries={referenceCountries}
                    referenceCurrencies={referenceCurrencies}
                    indexes={indexes}
                    securities={securities}
                    onShareClassLoaded={onShareClassLoaded}
                    onIndexLoaded={onIndexLoaded}
                    onSecurityClick={onSecurityClick}
                    onIndexClick={onIndexClick}
                    fieldPath={oProps<IBenchmarkTargetExposureModel[]>(targetExposureField).path(index, "targetPositions")} />
            </FieldBox>
        </AccordionDetails>
        {!readOnly && <AccordionActions>
            <Button size="small" onClick={handleDelete} startIcon={<DeleteIcon />}>Delete</Button>
        </AccordionActions>}
    </Accordion>
}
export interface IPortfolioBenchmarkExposureProps {
    referenceCountries: Record<number, ICountryModel>;
    referenceCurrencies: Record<number, ICurrencyModel>;
    indexes: Record<number, IIndexSummaryModel>;
    securities: Record<number, SecuritySummaryModel>;
    onShareClassLoaded: (security: IGetSecuritySummary) => void;
    onIndexLoaded: (index: IIndexSummaryModel) => void;
    onSecurityClick: (securityId: number) => void;
    onIndexClick: (indexId: number) => void;
    targetExposureField: string;
};
export default function PortfolioBenchmarkExposure({
    targetExposureField,
    referenceCountries,
    referenceCurrencies,
    indexes,
    securities,
    onShareClassLoaded,
    onIndexLoaded,
    onSecurityClick,
    onIndexClick
}: IPortfolioBenchmarkExposureProps) {
    const [, { value: benchmarkExposures },] = useField<IBenchmarkTargetExposureModel[]>(targetExposureField);
    return <FieldArray name={targetExposureField} render={renderBenchmarkExposures} validateOnChange={true} />;

    function renderBenchmarkExposures({ remove, unshift }: FieldArrayRenderProps): React.ReactNode {
        const handleAddBenchmarkExposure = () => {
            unshift({
                fromDate: today(),
                name: "",
                restrikingFrequency: IFrequencyTypeModel.Daily,
                targetPositions: []
            } as IBenchmarkTargetExposureModel);
        }
        return <ReadOnlyContext.Consumer>{readOnly => <>{!!benchmarkExposures.length ? <>{benchmarkExposures.map((benchmarkExposure, idx) => <BenchmarkExposure
            key={idx}
            index={idx}
            readOnly={readOnly}
            onDelete={remove}
            benchmarkExposure={benchmarkExposure}
            targetExposureField={oProps<IPortfolioModel>().path("benchmarkExposures")}
            referenceCountries={referenceCountries}
            referenceCurrencies={referenceCurrencies}
            indexes={indexes}
            securities={securities}
            onShareClassLoaded={onShareClassLoaded}
            onIndexLoaded={onIndexLoaded}
            onSecurityClick={onSecurityClick}
            onIndexClick={onIndexClick}
        />)}</> : <Typography>No target exposure is defined for this portfolio</Typography>}
            {!readOnly && <StickyFab onClick={handleAddBenchmarkExposure} ><AddIcon /></StickyFab>}
        </>}</ReadOnlyContext.Consumer>;
    }
}
