import DetailPanel from "tools/components/DetailPanel";
import { IFeatureModel, IMonitoringMacroCallModel } from "proxy/apiProxy";
import { FormikProps, Formik, FormikHelpers } from 'formik';
import MonitoringMacroCallData from "./MonitoringMacroCallData";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";

export default function MonitoringMacroCall() {
    const { monitoringMacroCallSave, monitoringMacroCallDelete,
        monitoringMacroCallSelectMonitoringMacro
    } = useReduxActions("monitoringMacroCall");
    const { navigationNavigate } = useReduxActions("navigation");
    const { monitoringMacroCallCurrent, monitoringMacroCallLoading, monitoringMacroCallMetadataSearchLoading,
        monitoringMacroCallParameterMetadata = [], monitoringMacroCallMonitoringMacros = []
    } = useReduxSelections("monitoringMacroCall");

    const handleBack = () => navigationNavigate(undefined);
    if (!monitoringMacroCallCurrent) {
        return <DetailPanelLoading onBackClick={handleBack} hasSubTitle={false} />;
    }

    const handleDelete = () => {
        if (monitoringMacroCallCurrent?.id) {
            monitoringMacroCallDelete(monitoringMacroCallCurrent?.id);
        }
    }

    const handleSubmit = (values: IMonitoringMacroCallModel, { setSubmitting }: FormikHelpers<IMonitoringMacroCallModel>) => {
        monitoringMacroCallSave(values);
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={monitoringMacroCallCurrent} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;

    function renderForm(formikProps: FormikProps<IMonitoringMacroCallModel>) {
        if (!monitoringMacroCallCurrent) {
            return;
        }
        const { dirty, isValid, submitForm, values } = formikProps;
        const { id, name, macroScriptId } = values;
        const title = !!id ? (name) : 'New Monitoring Macro Call';
        const handleOpenMonitoringMacro = () => navigationNavigate({ screenKey: "Macros", sectionKey: "detail", parameters: { id: macroScriptId } })

        return <WriteAccess value={IFeatureModel.MacroExecutionSetupAndDashboardWrite}>
            <DetailPanel
                isQuerying={monitoringMacroCallLoading || monitoringMacroCallMetadataSearchLoading}
                title={title}
                canSave={dirty && isValid}
                onSaveClick={submitForm}
                canDelete={!!id}
                onDeleteClick={handleDelete}
                onBackClick={handleBack}
                saveAllowed={IFeatureModel.MacroExecutionSetupAndDashboardWrite}
                deleteAllowed={IFeatureModel.MacroExecutionSetupAndDashboardDelete}
                badge={!id ? "new" : undefined}>
                <MonitoringMacroCallData
                    parameterMetadata={monitoringMacroCallParameterMetadata}
                    macroScripts={monitoringMacroCallMonitoringMacros}
                    onOpenMonitoringMacro={handleOpenMonitoringMacro}
                    onSelectMonitoringMacro={monitoringMacroCallSelectMonitoringMacro} />
            </DetailPanel></WriteAccess>
    }
}
