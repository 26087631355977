import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import ClassificationTypes from "./ClassificationTypes";
import ClassificationType from "./ClassificationType";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";
import { ClassificationTypeDomain } from "./ClassificationTypeDomain";

@autobind
class SecurityClassificationScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                gridSize={9}
                gridSizeIfNotExact={5}
                slideDirection={"right"}
                component={ClassificationTypes}
                domain={ClassificationTypeDomain.Process}
                path={getSiteScreenUrl("ProcessDefinitionClassificationTypes")} />
            <NavigationRoute
                gridSize={7}
                slideDirection={"left"}
                component={ClassificationType}
                domain={ClassificationTypeDomain.Process}
                path={getSiteDetailScreenUrl("ProcessDefinitionClassificationTypes")} />

            <NavigationRoute
                gridSize={9}
                gridSizeIfNotExact={5}
                slideDirection={"right"}
                component={ClassificationTypes}
                domain={ClassificationTypeDomain.Security}
                path={getSiteScreenUrl("SecurityClassificationTypes")} />
            <NavigationRoute
                gridSize={7}
                slideDirection={"left"}
                component={ClassificationType}
                domain={ClassificationTypeDomain.Security}
                path={getSiteDetailScreenUrl("SecurityClassificationTypes")} />


            <NavigationRoute
                gridSize={9}
                gridSizeIfNotExact={5}
                slideDirection={"right"}
                component={ClassificationTypes}
                domain={ClassificationTypeDomain.Investor}
                path={getSiteScreenUrl("InvestorClassificationTypes")} />
            <NavigationRoute
                gridSize={7}
                slideDirection={"left"}
                component={ClassificationType}
                domain={ClassificationTypeDomain.Investor}
                path={getSiteDetailScreenUrl("InvestorClassificationTypes")} />

            <NavigationRoute
                gridSize={9}
                gridSizeIfNotExact={5}
                slideDirection={"right"}
                component={ClassificationTypes}
                domain={ClassificationTypeDomain.RoleRelationship}
                path={getSiteScreenUrl("RoleRelationshipClassificationTypes")} />
            <NavigationRoute
                gridSize={7}
                slideDirection={"left"}
                component={ClassificationType}
                domain={ClassificationTypeDomain.RoleRelationship}
                path={getSiteDetailScreenUrl("RoleRelationshipClassificationTypes")} />


            <NavigationRoute
                gridSize={9}
                gridSizeIfNotExact={5}
                slideDirection={"right"}
                component={ClassificationTypes}
                domain={ClassificationTypeDomain.Movement}
                path={getSiteScreenUrl("MovementClassificationTypes")} />
            <NavigationRoute
                gridSize={7}
                slideDirection={"left"}
                component={ClassificationType}
                domain={ClassificationTypeDomain.Movement}
                path={getSiteDetailScreenUrl("MovementClassificationTypes")} />


            <NavigationRoute
                gridSize={9}
                gridSizeIfNotExact={5}
                slideDirection={"right"}
                component={ClassificationTypes}
                domain={ClassificationTypeDomain.Entity}
                path={getSiteScreenUrl("EntityClassificationTypes")} />
            <NavigationRoute
                gridSize={7}
                slideDirection={"left"}
                component={ClassificationType}
                domain={ClassificationTypeDomain.Entity}
                path={getSiteDetailScreenUrl("EntityClassificationTypes")} />


            <NavigationRoute
                gridSize={9}
                gridSizeIfNotExact={5}
                slideDirection={"right"}
                component={ClassificationTypes}
                domain={ClassificationTypeDomain.Note}
                path={getSiteScreenUrl("NoteClassificationTypes")} />
            <NavigationRoute
                gridSize={7}
                slideDirection={"left"}
                component={ClassificationType}
                domain={ClassificationTypeDomain.Note}
                path={getSiteDetailScreenUrl("NoteClassificationTypes")} />


            <NavigationRoute
                gridSize={9}
                gridSizeIfNotExact={5}
                slideDirection={"right"}
                component={ClassificationTypes}
                domain={ClassificationTypeDomain.DevelopmentItem}
                path={getSiteScreenUrl("DevelopmentItemClassificationTypes")} />
            <NavigationRoute
                gridSize={7}
                slideDirection={"left"}
                component={ClassificationType}
                domain={ClassificationTypeDomain.DevelopmentItem}
                path={getSiteDetailScreenUrl("DevelopmentItemClassificationTypes")} />

            <NavigationRoute
                gridSize={9}
                gridSizeIfNotExact={5}
                slideDirection={"right"}
                component={ClassificationTypes}
                domain={ClassificationTypeDomain.Process}
                path={getSiteScreenUrl("ProcessDefinitionClassificationTypes")} />
            <NavigationRoute
                gridSize={7}
                slideDirection={"left"}
                component={ClassificationType}
                domain={ClassificationTypeDomain.Process}
                path={getSiteDetailScreenUrl("ProcessDefinitionClassificationTypes")} />
        </>
    }
}

export default SecurityClassificationScreen;
