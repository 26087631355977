import { Switch } from "@material-ui/core";
import { EntityModel, EntitySummaryModel, IEntityLoginModel } from "proxy/apiProxy";
import { useCallback } from "react";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import SearchPanel from "tools/components/SearchPanel";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";

function getEntityLabel(entity: EntityModel | EntitySummaryModel) {
    switch (entity.type) {
        case "PersonModel":
        case "PersonSummaryModel": return `${entity.firstName} ${entity.lastName}`;
        default: return entity.name;
    }
}
export default function TenantManager() {
    const { logins, loginsLoading, loginsSwitching } = useReduxSelections("tenantManager");
    const { tenantManagerLoginSwitch } = useReduxActions("tenantManager");
    const handleSwitch = useCallback((entityId: number, isAdmin: boolean) => tenantManagerLoginSwitch({ entityId, isAdmin }), [tenantManagerLoginSwitch]);
    const columns: IColumnDefinition[] = [
        { name: "Name", title: "Name", getCellValue: ({ entity }: IEntityLoginModel) => getEntityLabel(entity) },
        {
            name: "Switch", title: "Is Tenant Manager", getCellValue: ({ entity, isTenantAdministrator }: IEntityLoginModel) => <Switch
                checked={isTenantAdministrator}
                disabled={loginsSwitching[entity.id]}
                onChange={handleSwitch.bind(null, entity.id, !isTenantAdministrator)}
            />, align: "center"
        }
    ];
    const state: IGridState = {
        "Name": { width: 200 },
        "Switch": { width: 200 },
    };

    const getRowKey = useCallback(({ entity: { id } }: IEntityLoginModel) => id, []);
    return <SearchPanel title="Tenant Managers" isQuerying={loginsLoading} >
        <ExtendedGrid
            rows={logins}
            columns={columns}
            getRowId={getRowKey}
            initialState={state}
            userCanGroup={true}
            defaultExportFileName="tenancies.xlsx" />
    </SearchPanel>
}