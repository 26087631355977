import * as React from "react";
import {Typography, Badge, Box} from "@material-ui/core";
import {Theme, withStyles, createStyles} from "@material-ui/core/styles";
import {DataType} from 'csstype';

const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            right: -8,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: "0 4px"
        }
    })
)(Badge);

const ValueContainer = withStyles(theme =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "&>:first-child": {
                marginRight: theme.spacing(1)
            },
        }
    })
)(Box);

const StyledTypography = withStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.text.disabled,
            fontWeight: "normal"
        }
    })
)(Typography);

const StyledBox = withStyles((theme: Theme) =>
    createStyles({
        root: {
            borderBottom: 1,
            borderBottomColor: "rgba(0, 0, 0, 0.42)",
            borderBottomStyle: "solid"
        }
    })
)(Box);

export interface ISummaryFieldProps {
    label: React.ReactNode;
    value: React.ReactNode | undefined | null;
    error?: boolean;
    badge?: React.ReactNode;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    noUnderline?: boolean;
    rightAlign?: boolean;
    color?: DataType.Color;
    breakAll?: boolean;
}

export function SummaryField(props: ISummaryFieldProps) {
    const {
        label,
        value,
        error,
        noUnderline = false,
    } = props;
    if (typeof value === "undefined" || value === null) {
        return <></>;
    }
    return <StyledBox style={{borderBottomStyle: noUnderline ? "none" : "solid"}}>
        <Typography variant="caption" color="textSecondary"
                    style={{color: error ? "red" : undefined,}}
                    gutterBottom={true}>
            {label}
        </Typography>
        <InnerSummaryField {...props} />
    </StyledBox>
}

export interface IInnerSummaryFieldProps {
    value: React.ReactNode | undefined | null;
    error?: boolean;
    badge?: React.ReactNode;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    rightAlign?: boolean;
    color?: DataType.Color;
    noPadding?: boolean;
    breakAll?: boolean;
}

export function InnerSummaryField({
                                      value,
                                      error,
                                      badge,
                                      endAdornment,
                                      startAdornment,
                                      rightAlign = false,
                                      color,
                                      noPadding = false,
                                      breakAll = false,
                                  }: IInnerSummaryFieldProps) {
    const textStyle: React.CSSProperties = {
        color: error ? "red" : color,
        wordBreak: breakAll ? "break-all" : undefined,
    }
    const noPaddingStyle: React.CSSProperties = {
        marginBottom: 0,
        marginTop: 0,
    }
    const combinedTextStyle = noPadding
        ? {...textStyle, ...noPaddingStyle}
        : {...textStyle}

    return <ValueContainer>
        {!!startAdornment && <StyledTypography>{startAdornment}</StyledTypography>}
        {!!rightAlign && <Box flexGrow={1}>&nbsp;</Box>}
        {!!badge ? (
            <StyledBadge badgeContent={badge} color="primary" max={9999999999}>
                <Typography variant="body1" style={combinedTextStyle} gutterBottom={true}>
                    {value}
                </Typography>
            </StyledBadge>
        ) : (
            <Typography variant="body1" style={combinedTextStyle} gutterBottom={true}>
                {value}
            </Typography>
        )}
        {!rightAlign && <Box flexGrow={1}>&nbsp;</Box>}
        {!!endAdornment && <StyledTypography>{endAdornment}</StyledTypography>}
    </ValueContainer>
}