import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import { IEntityPositionModel, IFeatureModel, IUniverseScopeTypeModel, isNonPersonEntityModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import EntityData from "./EntityData";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { getEntityName } from "tools/lib/modelUtils";
import * as React from "react";
import { IEntityData } from "features/Entity/slice";
import { setFilesToDelete } from "reducers/IRelatedFileWithContent";
import { oProps } from "tools/lib/utility";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import Ratings from "components/global/Ratings";
import CustomViewDatas from "features/Relationship/CustomViewDatas";
import EntityList from "./EntityList";
import MonitoringResultGroups from "components/global/MonitoringResults";
import EntityComposition from "./EntityComposition";
import AttachedDocuments from "components/global/AttachedDocuments";
import { ProcessExecutionList } from "../ProcessExecution/ProcessExecutionList";
import useScopeButtonsMenu from "components/global/useScopeButtonsMenu";
import { IActionButton } from "tools/components/FabContainer";
import NotebookCheckOutlineIcon from "mdi-material-ui/NotebookCheckOutline";

export default function Entity() {
    const {
        entityCurrent,
        files,
        entityPicture,
        entityLoading,
        dictionaries,
        entitySaving,
        ratings,
        compositionDates,
        composition,
        customScreenDataLoading,
        customScreenDatas,
        customScreens,
        customScreenDataSubmitting,
        monitoringResults,
        currentCompositionDate,
        compositionDateLoading,
        compositionVisualizationType,
        compositionDatesLoading,
        processExecutionsLoading,
        processExecutions = []
    } = useReduxSelections("entity");
    const { macroScriptList } = useReduxSelections("macroScript");
    const monitoringMacros = React.useMemo(() => macroScriptList.filter(i => (i.type === "MonitoringMacroScriptModel" || i.type === "MonitoringMacroScriptSummaryModel") && i.singleScope === IUniverseScopeTypeModel.Entity), [macroScriptList]);
    const { parameters: { personExtensionFields = [], companyExtensionFields = [], sicavExtensionFields = [] } } = useReduxSelections("parameters");
    const {
        referenceCountries = {},
        referenceCurrencies = {},
        ratingTypes = {}
    } = useReduxSelections("reference");
    const { navigationNavigate } = useReduxActions("navigation");
    const {
        classificationTypes: securityClassificationTypes,
        classificationTypesFlat: securityClassificationTypesFlat
    } = useReduxSelections("securityClassificationType");
    const {
        entitySave,
        entityDelete,
        entityAddEntityInDictionary,
        entityFileContentLoad,
        entityCompositionVisualizationTypeChange,
        entityCustomScreenDataSubmit,
        entityMonitoringResultLoad,
        entityDateLoad,
        entityPositionDelete,
        entityPositionSave,
        entityAddSecurityInDictionary,
    } = useReduxActions("entity");
    const { navigationActiveScreen: navigationActiveUrlNode } = useReduxSelections("navigation");
    const { classificationTypes } = useReduxSelections("entityClassificationType");
    const handleDeletePosition = ({ id }: IEntityPositionModel) => entityPositionDelete(id);
    const formValues: IEntityData | undefined = React.useMemo(() => {
        if (!entityCurrent) {
            return undefined;
        }
        const ret: IEntityData = {
            entity: entityCurrent,
            imageFile: entityPicture,
            files: files ?? []
        };
        return ret;
    }, [entityCurrent, entityPicture, files]);
    const isGranted = useGrants();
    const extraReportActionButtons = useScopeButtonsMenu(IUniverseScopeTypeModel.Entity, entityCurrent?.id);

    const handleBack = React.useCallback(() => navigationNavigate(undefined), [navigationNavigate]);
    const title = !!entityCurrent?.id ? (getEntityName(entityCurrent) ?? "") : 'New entity';
    const handleDelete = React.useCallback(() => {
        if (entityCurrent?.id) {
            entityDelete(entityCurrent.id);
        }
    }, [entityCurrent?.id, entityDelete])

    const handleSubmit = React.useCallback((values: IEntityData, { setSubmitting }: FormikHelpers<IEntityData>) => {
        setFilesToDelete(values, formValues ?? { files: [] })
        entitySave(values);
        setSubmitting(false);
    }, [entitySave, formValues])
    const handleTabValueChanged = React.useCallback((value: any) => navigationNavigate({ sectionKey: value as string }), [navigationNavigate]);

    const handleLoadResult = React.useCallback((id: number) => entityMonitoringResultLoad({ id, targetId: entityCurrent?.id ?? 0 }), [entityCurrent?.id, entityMonitoringResultLoad]);

    const handleEntitySelected = React.useCallback((entityId: number) => {
        const entity = dictionaries.entities[entityId];
        if (!entity) {
            return;
        }
        switch (entity.type) {
            case "PersonSummaryModel":
                navigationNavigate({ screenKey: "People", sectionKey: "detail", parameters: { id: entityId } });
                break;
            case "EntityGroupSummaryModel":
                navigationNavigate({ screenKey: "EntityGroups", sectionKey: "detail", parameters: { id: entityId } });
                break;
            case "CompanySummaryModel":
                navigationNavigate({ screenKey: "Companies", sectionKey: "detail", parameters: { id: entityId } });
                break;
            case "SicavSummaryModel":
                navigationNavigate({ screenKey: "Sicavs", sectionKey: "detail", parameters: { id: entityId } });
                break;
        }
    }, [dictionaries.entities, navigationNavigate]);
    const handleNewExecutionProcess = React.useCallback(() => {
        navigationNavigate({ screenKey: "ProcessExecutions", sectionKey: "detail", parameters: { type: "EntityProcessExecutionModel", targetId: entityCurrent?.id ?? 0 } });
    }, [entityCurrent?.id, navigationNavigate])
    const handleDataSubmit = React.useCallback((customScreenId: number, values: any) => entityCustomScreenDataSubmit({ id: formValues?.entity?.id ?? 0, customScreenId, values }), [entityCustomScreenDataSubmit, formValues?.entity?.id]);
    const canEditCustomData = isGranted(IFeatureModel.UniverseCustomDataWrite);
    if (!entityCurrent || !formValues) {
        return <DetailPanelLoading onBackClick={handleBack} tabNumber={2} hasSubTitle={false} />;
    }

    return <Formik onSubmit={handleSubmit} initialValues={formValues} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;

    function renderForm({ dirty, isValid, submitForm, values }: FormikProps<IEntityData>) {

        const tabValue = navigationActiveUrlNode?.activeSectionKey;
        const tabs: ISubMenu[] = [{
            label: "Data",
            value: "detail"
        }];

        if (isNonPersonEntityModel(values?.entity)) {
            tabs.push({
                label: "Entities",
                value: "entities"
            });
        }
        if (!!monitoringMacros.length) {
            tabs.push({
                label: "Dashboard",
                value: "dashboard"
            });
        }
        if (values?.entity?.id) {
            tabs.push({
                label: "Positions",
                value: "composition"
            });
            tabs.push({
                label: "Processes",
                value: "processes"
            });
        }
        tabs.push({
            label: "Documents",
            value: "files"
        });
        if ((values?.entity?.type === "CompanyModel" || values?.entity?.type === "SicavModel") && !!ratings) {
            tabs.push({
                label: "Ratings",
                value: "ratings"
            });
        }

        if (values.entity?.id && !!customScreens && customScreens.length) {
            tabs.push({
                label: "Custom Data",
                value: "customViews"
            });
        }
        const handleSecuritySelected = (securityId: number) => {
            const security = dictionaries.securities[securityId];
            if (security.type === "CashSummaryModel") {
                navigationNavigate({ screenKey: "BankAccounts", sectionKey: "detail", parameters: { id: securityId } });
            }
            else if (security.type === "LoanSummaryModel") {
                navigationNavigate({ screenKey: "Loans", sectionKey: "detail", parameters: { id: securityId } });
            }
            else if (security.type === "ShareClassSummaryModel" && security.isUnderManagement) {
                navigationNavigate({ screenKey: "MyShareClasses", sectionKey: "detail", parameters: { id: securityId } });
            }
            else {
                navigationNavigate({ screenKey: "Securities", sectionKey: "detail", parameters: { id: securityId } });
            }
        }
        const extraActionButtons: IActionButton[] = [...extraReportActionButtons, {
            label: "Add Process Execution",
            icon: NotebookCheckOutlineIcon,
            onClick: handleNewExecutionProcess,
            feature: IFeatureModel.EntityDueDiligenceWrite//,
        }];
        const handleFileOpen = (fileId: number) => entityCurrent && entityFileContentLoad({ fileId });
        return <DetailPanel
            isQuerying={entityLoading || entitySaving || compositionDateLoading || customScreenDataLoading || customScreenDataSubmitting || processExecutionsLoading}
            title={title}
            subTitle={entityCurrent?.type?.replace("Model", "")}
            canSave={dirty && isValid}
            onSaveClick={submitForm}
            canDelete={!!entityCurrent?.id}
            onDeleteClick={handleDelete}
            onBackClick={handleBack}
            badge={!entityCurrent?.id ? "new" : undefined}
            tabs={tabs}
            tabValue={tabValue}
            saveAllowed={IFeatureModel.EntityWrite}
            deleteAllowed={IFeatureModel.EntityWrite}
            actions={extraActionButtons}
            onTabValueChanged={handleTabValueChanged}>
            {(tabValue === "detail") && <WriteAccess value={IFeatureModel.EntityWrite}>
                <EntityData
                    personExtensionFields={personExtensionFields}
                    companyExtensionFields={companyExtensionFields}
                    sicavExtensionFields={sicavExtensionFields}
                    formImageFieldName={oProps<IEntityData>().path("imageFile")}
                    formFieldName={oProps<IEntityData>().path("entity")}
                    classificationTypes={classificationTypes}
                    dictionaries={dictionaries}
                    onEntityLoaded={entityAddEntityInDictionary}
                    referenceCountries={referenceCountries} /></WriteAccess>}
            {(tabValue === "ratings" && !!ratings) && <Ratings
                ratingTypes={ratingTypes}
                ratings={ratings} />}
            {(tabValue === "processes") && <ProcessExecutionList
                processExecutions={processExecutions}
                dictionaries={dictionaries} />}
            {(tabValue === "composition" && values && values.entity) && <EntityComposition
                dictionaries={dictionaries}
                composition={composition}
                classificationTypes={securityClassificationTypes}
                classificationTypesFlat={securityClassificationTypesFlat}
                referenceCurrencies={referenceCurrencies}
                pricingDate={currentCompositionDate}
                pricingDates={compositionDates}
                referenceCountries={referenceCountries}
                onTargetSecuritySelected={handleSecuritySelected}
                pricingDatesLoading={compositionDatesLoading}
                onPricingDateChanged={entityDateLoad}
                onVisualizationTypeChanged={entityCompositionVisualizationTypeChange}
                visualizationType={compositionVisualizationType}
                entity={values.entity}
                onSavePosition={entityPositionSave}
                onDeletePosition={handleDeletePosition}
                onSecurityLoaded={entityAddSecurityInDictionary} />}
            {(tabValue === "dashboard" && !!monitoringMacros.length) && <MonitoringResultGroups
                monitoringMacros={monitoringMacros}
                onLoadResult={handleLoadResult}
                resultGroups={monitoringResults} />}
            {(tabValue === "files") && <AttachedDocuments
                formFieldName={oProps<IEntityData>().path("files")}
                onFileOpen={handleFileOpen} />}
            {(tabValue === "entities") && <EntityList
                dictionaries={dictionaries}
                formFieldName={oProps<IEntityData>().path("entity")}
                onEntityLoaded={entityAddEntityInDictionary}
                onEntitySelected={handleEntitySelected} />}
            {(tabValue === "customViews" && !!customScreenDatas && !!customScreens?.length) && <CustomViewDatas
                canEdit={canEditCustomData}
                dictionaries={dictionaries}
                customScreenLoading={customScreenDataLoading}
                dataSubmitting={customScreenDataSubmitting}
                customScreens={customScreens}
                onDataSubmit={handleDataSubmit}
                customScreenDatas={customScreenDatas} />}
        </DetailPanel>
    }
}
