import * as React from "react";
import { EntitySummaryModel, IEntityTypeModel } from "proxy/apiProxy";
import { useField } from 'formik';
import AsyncSelect from "tools/components/AsyncSelect";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IGetEntitySummary } from "reducers/Reference";
import { getEntityName } from "tools/lib/modelUtils";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import SearchIcon from '@material-ui/icons/Search';

export interface IEntitySelectFieldProps {
    name: string;
    label?: React.ReactNode;
    required?: boolean;
    onSelected?: (found: IGetEntitySummary) => void;
    type?: IEntityTypeModel;
}
export default function EntitySelectField({ name, label, required, onSelected, type: entityType }: IEntitySelectFieldProps) {
    const { referenceEntitySearch } = useReduxActions("reference");
    const { referenceEntitiesSearched, referenceEntitySearching } = useReduxSelections("reference");
    const validate = (entityId: number | undefined) => {
        if (!required) {
            return;
        }
        return (entityId ?? 0) === 0 ? "Required" : undefined;
    }
    const [, { value, error, initialValue }, { setValue }] = useField<number | undefined>({ name, validate });
    const getOptionLabel = (entity: EntitySummaryModel) => getEntityName(entity) ?? "";
    label = <><SearchIcon fontSize="small" /> {label ?? "Entity"}: Type criteria to find an entity {required ? "*" : ""}</>;
    if ((initialValue || null) !== (value || null)) {
        label = <b>{label}</b>;
    }
    const handleValueSelected = (entity: EntitySummaryModel) => {
        if (onSelected) {
            const entities = entity.mainContactId ? { [entity.mainContactId]: referenceEntitiesSearched.entities[entity.mainContactId] } : {};
            onSelected({ entity, entities });
        }
        setValue(entity.id);
    }
    const handleOnSearchOptions = (criterias: string) => referenceEntitySearch({ criterias, type: entityType });
    return <ReadOnlyContext.Consumer>{readOnly => readOnly ? null :
        <AsyncSelect onSearchOptions={handleOnSearchOptions} options={referenceEntitiesSearched.all}
            getOptionLabel={getOptionLabel} searching={referenceEntitySearching} label={label} onRequiredValueSelected={handleValueSelected} error={!!error} helperText={error} />}
    </ReadOnlyContext.Consumer>

}
