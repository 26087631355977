// import * as React from "react";
import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import TradeBooksTrades from "./TradeBooksTrades";
import PortfoliosComplianceChecks from "./PortfoliosComplianceChecks";
import TradeBooksTradesEdit from './TradeBooksTradesEdit';
import {
    FieldArray,
    FieldArrayRenderProps,
    Formik, FormikHelpers, FormikProps
} from "formik";
import { formatDate, oProps, toDictionary } from "tools/lib/utility";
import TradeDateCashFlowData from "./TradeDateCashFlowData";
import { IComplianceAlertLevelModel, IFeatureModel, ITradeBookFxTradeModel, ITradeBookSecurityTradeModel, ITradeDateModel, ITradeStatusModel, TradeBookTradeModel } from "proxy/apiProxy";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import AddIcon from '@material-ui/icons/Add';
import CashUsdOutlineIcon from 'mdi-material-ui/Cash';
import CheckbookIcon from 'mdi-material-ui/Checkbook';
import CogClockwiseIcon from 'mdi-material-ui/CogClockwise';
import { IActionButton } from "tools/components/FabContainer";
import React from "react";
import { IPosition } from "reducers/TradeDates";
import SendIcon from '@material-ui/icons/Send';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import { Box, CircularProgress, Typography } from "@material-ui/core";
import AlertDialog from "tools/components/AlertDialog";
import { TradeDetailEditDialog } from "./TradeDetailEditDialog";

enum TabCode { CashFlow = "detail", Trades = "trades", TradeChecks = "tradeChecks", TradesEdit = "tradesEdit" };
export default function TradeDate() {
    const { navigationActiveScreen } = useReduxSelections("navigation");
    const { checking, currentLoading, saving, current, dictionaries, checks, compositions, portfolios, tradesGenerated, tradesGenerating } = useReduxSelections("tradeDates");
    const { navigationNavigate } = useReduxActions("navigation");

    const { tradeDateLoad, tradeDateSave, tradeDateAddSecurityInDictionary, tradeDateSubmit, tradeDateConfirmationsGet, tradeDateGenerationLoad } = useReduxActions("tradeDates");
    const { referenceCurrencies = {}, referenceCountries = {} } = useReduxSelections("reference");
    const [editingTrade, setEditingTrade] = React.useState<{ trade: TradeBookTradeModel, existingIndex: number | undefined } | undefined>();
    const [expandedRowIds, setExpandedRowIds] = React.useState<(number | string)[]>([]);
    const [mustConfirmSubmitTrades, setMustConfirmSubmitTrades] = React.useState(false);
    const positions = React.useMemo(() => Object.values(compositions).reduce((a, v) => [...a, ...v.positions], [] as IPosition[]), [compositions]);
    const checksFailed = React.useMemo(() => {
        if (!checks) {
            return false;
        }
        if (Array.isArray(checks)) {
            return !!checks.find(i => !!i.issues.find(j => j.level === IComplianceAlertLevelModel.Breach || j.level === IComplianceAlertLevelModel.Error || j.level === IComplianceAlertLevelModel.Warning));
        }
        else {
            return true;
        }
    }, [checks]);

    const computedTradesChangedRef = React.useRef<(computedTrades: TradeBookTradeModel[] | undefined) => void>();

    const tabs: ISubMenu[] = [{
        label: "Cash Flow",
        value: TabCode.CashFlow
    }, {
        label: "Trades List",
        value: TabCode.Trades
    }, {
        label: "Trades From positions",
        value: TabCode.TradesEdit
    }, {
        label: <Box display="flex" alignItems="center">{checking && <><CircularProgress size={18} />&nbsp;</>}PreTrade Check</Box>,
        value: TabCode.TradeChecks
    }];
    React.useEffect(() => {
        if (!!tradesGenerated && !!computedTradesChangedRef.current) {
            computedTradesChangedRef.current(tradesGenerated);
        }
    }, [tradesGenerated])

    if (!current) {
        return <DetailPanelLoading tabNumber={4} hasSubTitle={true} />;
    }
    const handleTabValueChanged = (value: any) => navigationNavigate({ sectionKey: value as string });
    const handleSubmit = (tradeDate: ITradeDateModel, { setSubmitting }: FormikHelpers<ITradeDateModel>) => {
        tradeDateSave(tradeDate);
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={current} enableReinitialize={true} validateOnMount={true} >{
        formikProps => <FieldArray name={oProps<ITradeDateModel>().path("trades")}>
            {fieldArrayRenderProps => renderForm(formikProps, fieldArrayRenderProps, computedTradesChangedRef)}
        </FieldArray>}</Formik>;

    function renderForm({ dirty, isValid, submitForm, validateForm, resetForm, setFieldValue, values: { trades } }: FormikProps<ITradeDateModel>, { push, remove, replace }: FieldArrayRenderProps, ref: React.MutableRefObject<((computedTrades: TradeBookTradeModel[] | undefined) => void) | undefined>) {
        const tabValue = navigationActiveScreen?.activeSectionKey;
        const handleReset = () => {
            setTimeout(() => resetForm(), 200);
        }
        const handleRefresh = () => {
            if (current) {
                tradeDateLoad(current.date);
            }
        }
        const handleBack = () => navigationNavigate(undefined);
        const handleAddTrade = (trade: TradeBookTradeModel) => {
            setEditingTrade({ trade, existingIndex: undefined });
        }

        const handleAddNewCashTransaction = () => {
            const trade = { type: "TradeBookFxTradeModel" } as ITradeBookFxTradeModel;
            setEditingTrade({ trade, existingIndex: undefined });
        }
        const handleAddNewSecurityTransaction = () => {
            const trade = { type: "TradeBookSecurityTradeModel" } as ITradeBookSecurityTradeModel;
            setEditingTrade({ trade, existingIndex: undefined });
        }
        const handleEditTrade = (tradeIndex: number) => {
            const trade = trades[tradeIndex]
            setEditingTrade({ trade, existingIndex: tradeIndex });
        }
        const handleTradeEdited = (editedTrade: TradeBookTradeModel) => {
            if (typeof editingTrade?.existingIndex !== "undefined") {
                replace(editingTrade.existingIndex, editedTrade);
            }
            else {
                push(editedTrade);
            }
            setEditingTrade(undefined);
        }
        const handleSave = () => {
            submitForm();
        }
        const handleRequestSubmitTrades = () => {
            if (!current) {
                return;
            }
            if (checking || checksFailed) {
                setMustConfirmSubmitTrades(true);
            }
            else {
                tradeDateSubmit(current.date);
            }
        }
        const handleConfirmSubmitTrades = (response: boolean) => {
            setMustConfirmSubmitTrades(false);
            if (!response) {
                return;
            }
            current && tradeDateSubmit(current.date);
        }
        const handleReconcileTrades = () => current && tradeDateConfirmationsGet(current.date);
        const handleComputeTrades = () => current && tradeDateGenerationLoad({ tradeDate: current.date, inputs: trades });
        const customActions: IActionButton[] = [{
            icon: AddIcon,
            label: "New Trade",
            actions: [{
                label: "Add forex trade",
                onClick: handleAddNewCashTransaction,
                icon: CashUsdOutlineIcon,
                feature: IFeatureModel.TradeBookWrite
            }, {
                label: "Add security trade",
                onClick: handleAddNewSecurityTransaction,
                icon: CheckbookIcon,
                feature: IFeatureModel.TradeBookWrite
            }]
        }];
        if (current?.trades?.find(i => i.status === ITradeStatusModel.New)) {
            customActions.push({
                icon: SendIcon,
                label: "Submit trades",
                onClick: handleRequestSubmitTrades,
                feature: IFeatureModel.TradeBookSend,
                disabled: dirty
            });
        }
        customActions.push({
            icon: CogClockwiseIcon,
            label: "Compute trades",
            onClick: handleComputeTrades,
            feature: IFeatureModel.TradeBookSend
        });
        if (current?.trades?.find(i => i.status === ITradeStatusModel.CheckedAndSent)) {
            customActions.push({
                icon: MergeTypeIcon,
                label: "Reconcile trades",
                onClick: handleReconcileTrades,
                feature: IFeatureModel.TradeBookSend,
                disabled: dirty
            });
        }
        const handleCloseEdit = () => {
            if (editingTrade) {
                setEditingTrade(undefined);
            }
        }
        const handleDeleteTrade = (tradeIndex: number) => {
            remove(tradeIndex);
            setTimeout(() => validateForm(), 100);
        }
        const handleTradeGeneratedChanged = (computedTrades: TradeBookTradeModel[] | undefined) => {
            if (!computedTrades) {
                return;
            }
            const dicoT = toDictionary(trades, i => i.id);
            for (let index = 0; index < computedTrades.length; index++) {
                const { id, ...computedT } = computedTrades[index];
                if (id) {
                    const t = dicoT[id];
                    if (t) {
                        setFieldValue(oProps<ITradeDateModel>().path("trades", index), { ...t, ...computedT });
                    }
                }
                else {
                    const t = (function () {
                        if (computedT.type === "TradeBookSecurityTradeModel") {
                            return trades.find(i =>
                                i.type === "TradeBookSecurityTradeModel"
                                && i.portfolioId === computedT.portfolioId
                                && i.securityId === computedT.securityId);
                        }
                        else if (computedT.type === "TradeBookFxTradeModel") {
                            return trades.find(i =>
                                i.type === "TradeBookFxTradeModel"
                                && i.portfolioId === computedT.portfolioId
                                && i.purchasedCurrencyId === computedT.purchasedCurrencyId
                                && i.soldCurrencyId === computedT.soldCurrencyId);
                        }
                        return undefined;
                    })();
                    if (t) {
                        if (t.type === "TradeBookSecurityTradeModel") {
                            delete t.amountInPtfCcy;
                            delete t.quantity;
                        }
                        else {
                            delete t.soldAmount;
                            delete t.purchasedAmount;
                        }
                        setFieldValue(oProps<ITradeDateModel>().path("trades", index), { ...t, ...computedT });
                    }
                    else {
                        push({ ...computedT });
                    }
                }
            }
            setTimeout(() => validateForm(), 100);
        }

        ref.current = handleTradeGeneratedChanged;
        return <WriteAccess value={IFeatureModel.TradeBookWrite}>
            <AlertDialog open={mustConfirmSubmitTrades} onClose={handleConfirmSubmitTrades} title="Submit trades">
                {checksFailed && <Typography>Some portfolios wont be compliant.</Typography>}
                {checking && <Typography>Compliancy checks are not complete yet.</Typography>}
                <Typography>Do you really want to submit every trade that is not submitted?</Typography>
            </AlertDialog>
            <TradeDetailEditDialog
                tradeBookTrade={editingTrade?.trade}
                positions={positions}
                dictionaries={dictionaries}
                referenceCurrencies={referenceCurrencies}
                onSecurityLoaded={tradeDateAddSecurityInDictionary}
                onCancel={handleCloseEdit}
                onSubmit={handleTradeEdited} />
            <DetailPanel
                actions={customActions}
                isQuerying={currentLoading || saving || tradesGenerating}
                title="Trade Blotter"
                subTitle={formatDate(current?.date)}
                onBackClick={handleBack}
                noPadding={tabValue === TabCode.CashFlow || tabValue === TabCode.Trades || tabValue === TabCode.TradesEdit}
                onResetClick={handleReset}
                resetDisabled={!dirty}
                onRefreshClick={handleRefresh}
                onSaveClick={handleSave}
                canSave={dirty && isValid}
                saveAllowed={IFeatureModel.TradeBookWrite}
                tabs={tabs}
                tabValue={tabValue}
                onTabValueChanged={handleTabValueChanged}>
                {tabValue === TabCode.CashFlow && <TradeDateCashFlowData
                    compositions={compositions}
                    cashFlowsField={oProps<ITradeDateModel>().path("netCashFlows")}
                    portfolios={portfolios}
                    referenceCurrencies={referenceCurrencies} />}
                {tabValue === TabCode.Trades && <TradeBooksTrades
                    tradesField={oProps<ITradeDateModel>().path("trades")}
                    dictionaries={dictionaries}
                    onEditTrade={handleEditTrade}
                    referenceCurrencies={referenceCurrencies}
                    onDeleteTrade={handleDeleteTrade} />}
                {tabValue === TabCode.TradesEdit && <TradeBooksTradesEdit
                    positions={positions}
                    tradesField={oProps<ITradeDateModel>().path("trades")}
                    dictionaries={dictionaries}
                    referenceCurrencies={referenceCurrencies}
                    referenceCountries={referenceCountries}
                    onAddTrade={handleAddTrade}
                    onDeleteTrade={handleDeleteTrade}
                    onEditTrade={handleEditTrade}
                    expandedDetailRowIds={expandedRowIds}
                    onExpandedDetailRowIdsChange={setExpandedRowIds} />}
                {tabValue === TabCode.TradeChecks && <PortfoliosComplianceChecks
                    portfoliosChecks={checks}
                    processing={checking} />}
            </DetailPanel>
        </WriteAccess>
    }
}
