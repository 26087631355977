import * as React from "react";
import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { EntitySummaryModel, EntityModel, IEntityTypeModel, IPersonSummaryModel, IPersonModel, ICompanySummaryModel, ICompanyModel, ISicavSummaryModel, ISicavModel, IFeatureModel, IEntitiesSearchParameters } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getEntityName } from "tools/lib/modelUtils";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Grid } from "@material-ui/core";
import FormTextField from "tools/fieldComponents/FormTextField";
import { oProps } from "tools/lib/utility";
import { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";

export interface IEntitiesProps {
    type: IEntityTypeModel;
}
export default function Entities({ type }: IEntitiesProps) {
    const { entities: entityList, dictionaries: { entities }, entityAllLoading } = useReduxSelections("entity");
    const { navigationNavigate } = useReduxActions("navigation");
    const { entityLoadAll } = useReduxActions("entity");
    const { referenceCountries = {}, } = useReduxSelections("reference");
    const {
        classificationTypes,
        classificationTypesFlat
    } = useReduxSelections("entityClassificationType");

    const { classificationTypesColumns, classificationTypesState } = getClassificationTypesColumnDefinitions(classificationTypes, classificationTypesFlat, (secu: EntitySummaryModel) => secu.classifications);
    const handleRowClick = (row: (EntitySummaryModel | EntityModel)) => navigationNavigate({ sectionKey: "detail", parameters: { id: row.id } });
    const handleAddNewClick = () => navigationNavigate({ sectionKey: "detail", parameters: { id: type } });
    const { columns, state } = React.useMemo(() => {
        const cs = [
            { name: "InternalCode", title: "Internal Code", getCellValue: (row: (EntitySummaryModel | EntityModel)) => row.internalCode, filteringEnabled: true },
            { name: "Name", title: "Name", getCellValue: (row: (EntitySummaryModel | EntityModel)) => getEntityName(row) ?? "", filteringEnabled: true },
            { name: "Location", title: "Location", getCellValue: (row: (EntitySummaryModel | EntityModel)) => row.location, filteringEnabled: true },
            {
                name: "Country", title: "Country", getCellValue: ({ countryId }: EntitySummaryModel | EntityModel) => {
                    if (countryId) {
                        const country = referenceCountries[countryId];
                        if (!country) {
                            return "???";
                        }
                        return country.name?.en;
                    }
                    return undefined;
                }, filteringEnabled: true
            },
            { name: "EMail", title: "EMail", getCellValue: ({ email }: (EntitySummaryModel | EntityModel)) => email, filteringEnabled: true },
            ...classificationTypesColumns
        ] as IColumnDefinition[];
        const state = {
            "InternalCode": { width: 150 },
            "Name": { width: 280 },
            "Location": { width: 280 },
            "Country": { width: 280 },
            "EMail": { width: 280 },
            ...classificationTypesState
        } as IGridState;

        if (type === IEntityTypeModel.Person) {
            cs.push({ name: "FirstName", title: "First Name", getCellValue: (row: (IPersonSummaryModel | IPersonModel)) => row.firstName, filteringEnabled: true });
            cs.push({ name: "LastName", title: "Last Name", getCellValue: (row: (IPersonSummaryModel | IPersonModel)) => row.lastName, filteringEnabled: true });
            state.FirstName = { width: 280 };
            state.LastName = { width: 280 };
        }
        if (type === IEntityTypeModel.Company) {
            cs.push({ name: "Type", title: "Type", getCellValue: (row: (ICompanySummaryModel | ICompanyModel)) => (row.type === "CompanyModel" || row.type === "CompanySummaryModel") ? "Company" : "Sicav", filteringEnabled: true });
            cs.push({ name: "RegistrationNumber", title: "Registration Number", getCellValue: (row: (ICompanySummaryModel | ICompanyModel)) => row.registrationNumber, filteringEnabled: true });
            cs.push({ name: "VAT", title: "VAT", getCellValue: (row: (ICompanySummaryModel | ICompanyModel)) => row.vatNumber, filteringEnabled: true });
            cs.push({ name: "IBAN", title: "IBAN", getCellValue: (row: (ICompanySummaryModel | ICompanyModel)) => row.iban, filteringEnabled: true });
            cs.push({ name: "LEI", title: "LEI", getCellValue: (row: (ICompanySummaryModel | ICompanyModel)) => row.leiCode, filteringEnabled: true });
            state.Type = { width: 150 };
            state.RegistrationNumber = { width: 200 };
            state.VAT = { width: 200 };
            state.LEI = { width: 200, hidden: true };
            state.IBAN = { width: 200, hidden: true };
        }
        if (type === IEntityTypeModel.Sicav) {
            cs.push({ name: "Issuer", title: "Issuer", getCellValue: (row: (ISicavSummaryModel | ISicavModel)) => row.issuerId && getEntityName(entities[row.issuerId]), filteringEnabled: true });
            cs.push({ name: "RegistrationNumber", title: "Registration Number", getCellValue: (row: (ICompanySummaryModel | ICompanyModel)) => row.registrationNumber, filteringEnabled: true });
            cs.push({ name: "VAT", title: "VAT", getCellValue: (row: (ISicavSummaryModel | ISicavModel)) => row.vatNumber, filteringEnabled: true });
            cs.push({ name: "IBAN", title: "IBAN", getCellValue: (row: (ISicavSummaryModel | ISicavModel)) => row.iban, filteringEnabled: true });
            state.Issuer = { width: 280 };
            state.RegistrationNumber = { width: 280, hidden: true };
            state.VAT = { width: 280, hidden: true };
            state.IBAN = { width: 280, hidden: true };
        }
        return {
            columns: cs,
            state
        };
    }, [classificationTypesColumns, classificationTypesState, type, referenceCountries, entities]);

    const getRowKey = (row: EntitySummaryModel | EntityModel) => row.id || 0;
    const handleSubmit = (values: Omit<IEntitiesSearchParameters, "type">, { setSubmitting }: FormikHelpers<Omit<IEntitiesSearchParameters, "type">>) => {
        entityLoadAll({ ...values, type });
        setSubmitting(false);
    }

    return <Formik onSubmit={handleSubmit} initialValues={{} as Omit<IEntitiesSearchParameters, "type">} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;
    function renderForm({ isValid, submitForm }: FormikProps<Omit<IEntitiesSearchParameters, "type">>) {
        return <SearchPanel title={getScreenTitle(type)} isQuerying={entityAllLoading}
            onSearchClick={submitForm}
            searchDisabled={!isValid}
            addAllowed={IFeatureModel.EntityWrite}
            onAddClick={handleAddNewClick}
            renderSearch={<Grid container={true} spacing={1}>
                <Grid item={true} sm={12} >
                    <FormTextField name={oProps<Omit<IEntitiesSearchParameters, "type">>().path("criterias")} label="Keywords (name, first name, last name, internal code, vat number...)" />
                </Grid>
            </Grid>}>
            <ExtendedGrid
                getRowId={getRowKey}
                columns={columns}
                rows={entityList}
                onRowClick={handleRowClick}
                initialState={state}
                userCanGroup={true}
                defaultExportFileName={`${type}Entities.xlsx`} />
        </SearchPanel>
    }
}
function getScreenTitle(type: IEntityTypeModel) {
    switch (type) {
        case IEntityTypeModel.Company: return "Companies";
        case IEntityTypeModel.Person: return "People";
        case IEntityTypeModel.Sicav: return "Sicavs";
        case IEntityTypeModel.EntityGroup: return "Entity Groups"
    }
}