import produce from "immer";
import { IGetStressTestImpactsModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";

export interface IState {
  stressTestLastImpactsLoading: boolean;
  stressTestLastImpacts: IGetStressTestImpactsModel;
  stressTestBackTestingLoading: boolean;
  stressTestBackTesting: IGetStressTestImpactsModel;
}

export const ActionFactories = produceActionFactories({
  stressTestLastImpactsLoad: () => undefined,
  stressTestLastImpactsLoaded: (payload: IGetStressTestImpactsModel) => payload,
  stressTestBackTestingLoad: (payload: number) => payload,
  stressTestBackTestingLoaded: (payload: IGetStressTestImpactsModel) => payload
});

export function reducer(
  state: IState = {
    stressTestLastImpactsLoading: false,
    stressTestLastImpacts: {
      portfolios: {},
      entities: {},
      stressTestScenarios: {},
      impacts: []
    },
    stressTestBackTestingLoading: false,
    stressTestBackTesting: {
      portfolios: {},
      entities: {},
      stressTestScenarios: {},
      impacts: []
    }
  },
  action: AnyActionOf<typeof ActionFactories>
): IState {
  return produce(state, draft => {
    switch (action.type) {
      case "stressTestLastImpactsLoad":
        draft.stressTestLastImpactsLoading = true;
        break;
      case "stressTestLastImpactsLoaded":
        draft.stressTestLastImpactsLoading = false;
        draft.stressTestLastImpacts = action.payload;
        break;
      case "stressTestBackTestingLoad":
        draft.stressTestBackTestingLoading = true;
        break;
      case "stressTestBackTestingLoaded":
        draft.stressTestBackTestingLoading = false;
        draft.stressTestBackTesting = action.payload;
        break;
    }
  });
}
