import NavigationRoute from "components/global/NavigationRoute";
import Templates from "./ReportTemplates";
import ReportTemplate from "./ReportTemplate";
// import ShareClassDictionaryRequest from "./ShareClassDictionaryRequest.container";
// import { Route } from "react-router";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

export default function ReportingScreen() {
    return <>
        <NavigationRoute
            slideDirection={"right"}
            component={Templates}
            path={getSiteScreenUrl("Templates")}
            exact={true} />
        <NavigationRoute
            slideDirection={"left"}
            component={ReportTemplate}
            path={getSiteDetailScreenUrl("Templates")} />
        {/* <Route
                component={ShareClassDictionaryRequest}
                path={GetSiteMapUrl("Config", "Reporting", IReportTemplateTypeModel.ShareClass, "detail")} /> */}
    </>
}
