import { createStyles, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { useReduxSelections } from "tools/lib/reduxStoreAccess";
// import logo from "images/unify.png";
// import FundProcessIcon from "./FundProcessIcon";

const useStyles = makeStyles(theme => createStyles({
    titleHeader: {
        paddingTop: 0,
        paddingBottom: 0,
        fontWeight: "bold",
        width: "auto",
        color: theme.palette.primary.contrastText
        // backgroundColor: theme.palette.primary.light
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    }
}));

export default function ApplicationTitle() {
    const classes = useStyles();
    const { currentTenant, currentTenantImageUrl } = useReduxSelections("app");
    return <ListItem className={classes.titleHeader}>
        {/* <img src={logo} style={{ width: 40, height: 40 }}/> */}
        {/* {!currentTenantImageUrl && <FundProcessIcon className={classes.menuButton} style={{ width: 40, height: 40, backgroundColor: "white" }} />} */}
        {!!currentTenantImageUrl && <img className={classes.menuButton} style={{ height: 40, objectFit: "contain" }} alt="company logo" src={currentTenantImageUrl} />}
        {currentTenant && <ListItemText primary={currentTenant.name} />}
    </ListItem>
}
