import * as React from "react";

interface ISwitchChildProps<T> {
    type: T;
}
type ISwitchProps<T> = React.ReactElement<ISwitchChildProps<T>> | ReadonlyArray<React.ReactElement<ISwitchChildProps<T>>>;
export function Switch<T = string>({ type, children }: { type: T; children: ISwitchProps<T>; }) {
    return <>{React.Children.map(children, child => child).filter(i => i.props.type === type)}</>;
}

export function SwitchCase<T = string>({ type, children }: { type: T; children: ISwitchProps<T>; }) {
    return <>{children}</>
}