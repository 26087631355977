import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, mergeMap, share } from "rxjs/operators";
import { statisticDefinitionSetsApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, changedNavigation } from "lib/rxJsUtility";
import { IStatisticDefinitionSetModel } from "proxy/apiProxy";
import { tryParseNumber } from "tools/lib/utility";
import { filterRoute } from "tools/lib/UrlDictionary";
import { parse as jsonParse } from "jsonc-parser";

export const onOpenScreenStatisticDefinitionSets: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("StatisticDefinitionSets"),
        map(() => ActionFactories.statisticDefinitionSet.statisticDefinitionSetLoadAll()));
export const onOpenScreenStatisticDefinitionSet: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("StatisticDefinitionSets", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? 0),
        map(statisticDefinitionSetId => ActionFactories.statisticDefinitionSet.statisticDefinitionSetLoad(statisticDefinitionSetId as number)));
export const loadStatisticDefinitionSets: Epic<IAnyAction>
    = action$ =>
        merge(
            action$.ofType("applicationLoaded").pipe(map(i => null)),
            action$.ofType("statisticDefinitionSetLoadAll").pipe(map(i => null)),
            action$.ofType("statisticDefinitionSetSaved").pipe(map(i => null)),
            action$.ofType("referenceRefreshAll").pipe(map(i => null))
        ).pipe(
            mergeMap(() => statisticDefinitionSetsApi.getAllAsync({})),
            map(ActionFactories.statisticDefinitionSet.statisticDefinitionSetLoadedAll));
export const loadStatisticDefinitionSet: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("statisticDefinitionSet", "statisticDefinitionSetLoad"),
            share());

        return merge(
            requestedId$.pipe(
                mergeMap(() => statisticDefinitionSetsApi.getStatisticsDefinitionSetSchemaAsync()),
                map(i => ActionFactories.statisticDefinitionSet.statisticDefinitionSetSchemaLoaded(jsonParse(i)))),
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => statisticDefinitionSetsApi.getAsync({ id })),
                map(ActionFactories.statisticDefinitionSet.statisticDefinitionSetLoaded)),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({
                    id: 0,
                    name: "",
                    code: "",
                    definitions: "[]",
                    publishedVersion: 0
                } as IStatisticDefinitionSetModel)),
                map(ActionFactories.statisticDefinitionSet.statisticDefinitionSetLoaded)));
    }
export const saveStatisticDefinitionSet: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("statisticDefinitionSet", "statisticDefinitionSetSave"),
        mergeMap(model => statisticDefinitionSetsApi.saveAsync(({ model }))),
        map(ActionFactories.statisticDefinitionSet.statisticDefinitionSetSaved));

export const deleteStatisticDefinitionSet: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("statisticDefinitionSet", "statisticDefinitionSetDelete"),
            mergeMap(id => statisticDefinitionSetsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.statisticDefinitionSet.statisticDefinitionSetDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }
