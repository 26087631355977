import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import Reports from "./Reports";
import Report from "./Report";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class ReportScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                gridSize={12}
                exact={true}
                slideDirection={"right"}
                component={Reports}
                path={getSiteScreenUrl("Reports")} />
            <NavigationRoute
                gridSize={12}
                slideDirection={"left"}
                component={Report}
                path={getSiteDetailScreenUrl("Reports")} />
        </>
    }
}

export default ReportScreen;
