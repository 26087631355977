import NavigationRoute from "components/global/NavigationRoute";
import SubFund from "./SubFund";
import SubFunds from "./SubFunds";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

export default function SubFundScreen() {
    return <>
        <NavigationRoute
            gridSize={12}
            exact={true}
            slideDirection={"right"}
            component={SubFunds}
            path={getSiteScreenUrl("SubFunds")} />
        <NavigationRoute
            gridSize={12}
            slideDirection={"left"}
            component={SubFund}
            path={getSiteDetailScreenUrl("SubFunds")} />
    </>
}
