import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, mergeMap, share } from "rxjs/operators";
import { macroScriptCategoriesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, changedNavigation } from "lib/rxJsUtility";
import { tryParseNumber } from "tools/lib/utility";
import { filterRoute } from "tools/lib/UrlDictionary";
import { IMacroScriptCategoryModel } from "proxy/apiProxy";

export const loadMacroScriptCategories: Epic<IAnyAction>
    = (action$) =>
        action$.pipe(
            mapToPayload("macroScriptCategory", "macroScriptCategoryLoadAll"),
            mergeMap(() => macroScriptCategoriesApi.getAllAsync({})),
            map(ActionFactories.macroScriptCategory.macroScriptCategoryLoadedAll));
export const onOpenScreenMacroScriptCategory: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("MacroScriptCategories", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? 0),
        map(ActionFactories.macroScriptCategory.macroScriptCategoryLoad));

export const onOpenScreenMacroScriptCategories: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("MacroScriptCategories"),
        map(() => ActionFactories.macroScriptCategory.macroScriptCategoryLoadAll()));
export const loadMacroScriptCategory: Epic<IAnyAction>
    = (action$) => {
        const requestedId$ = action$.pipe(
            mapToPayload("macroScriptCategory", "macroScriptCategoryLoad"),
            share()
        );

        return merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => macroScriptCategoriesApi.getAsync({ id })),
                map(ActionFactories.macroScriptCategory.macroScriptCategoryLoaded)),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({ id: 0, name: "" } as IMacroScriptCategoryModel)),
                map(ActionFactories.macroScriptCategory.macroScriptCategoryLoaded)));
    };
export const saveMacroScriptCategory: Epic<IAnyAction>
    = (action$) => action$.pipe(
        mapToPayload("macroScriptCategory", "macroScriptCategorySave"),
        mergeMap(model => macroScriptCategoriesApi.saveAsync({ model })),
        map(ActionFactories.macroScriptCategory.macroScriptCategorySaved));
export const deleteMacroScriptCategory: Epic<IAnyAction>
    = (action$) => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("macroScriptCategory", "macroScriptCategoryDelete"),
            mergeMap(id => macroScriptCategoriesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.macroScriptCategory.macroScriptCategoryDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };
