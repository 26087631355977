import { Epic } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import { stressTestApi } from "proxy/apiProxy";
import { changedNavigation, mapToPayload } from "lib/rxJsUtility";
import { ActionFactories, IAnyAction } from "reducers";
import { filterRoute } from "tools/lib/UrlDictionary";

export const onOpenScreenSummary: Epic<IAnyAction>
  = action$ => action$.pipe(
    changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections),
    filterRoute("StressTestingScreen"),
    map(() => ActionFactories.stressTest.stressTestLastImpactsLoad()));

export const loadStressTestLastImpacts: Epic<IAnyAction>
  = action$ => action$.pipe(
    mapToPayload("stressTest", "stressTestLastImpactsLoad"),
    mergeMap(() => stressTestApi.getLastStressTestImpactsAsync()),
    map(ActionFactories.stressTest.stressTestLastImpactsLoaded));

export const loadStressTestBackTesting: Epic<IAnyAction>
  = action$ => action$.pipe(
    mapToPayload("stressTest", "stressTestBackTestingLoad"),
    mergeMap(portfolioId => stressTestApi.getStressTestBacktestingAsync({ portfolioId })),
    map(ActionFactories.stressTest.stressTestBackTestingLoaded));
