import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import MonitoringMacroCalls from "./MonitoringMacroCalls";
import MonitoringMacroCall from "./MonitoringMacroCall";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class MonitoringMacroCallScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                gridSize={12}
                exact={true}
                slideDirection={"right"}
                component={MonitoringMacroCalls}
                path={getSiteScreenUrl("MacroMonitoringCalls")} />
            <NavigationRoute
                gridSize={12}
                slideDirection={"left"}
                component={MonitoringMacroCall}
                path={getSiteDetailScreenUrl("MacroMonitoringCalls")} />
        </>
    }
}

export default MonitoringMacroCallScreen;
