import { Epic } from "redux-observable";
import { map, filter, share, tap, first, mergeMap } from "rxjs/operators";
import { IEntityMetadataModel, IMacroScriptCheckResultModel, ISubMacroScriptModel, studioMacroScriptsApi, macroScriptsApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { MacroScriptModel, IMacroScriptTypeModel } from "proxy/apiProxy";
import { changedNavigation, mapToPayload, onlyNotNull } from "lib/rxJsUtility";
import { base64toBlob, tryParseNumber } from "tools/lib/utility";
import { merge, combineLatest } from "rxjs";
import { filterRoute } from "tools/lib/UrlDictionary";
import { IMacroScriptLoadedPayload, mapType } from "./slice";
import saveAs from "file-saver";

function createNewMacroScriptInstance(type: IMacroScriptTypeModel): MacroScriptModel {
    switch (type) {
        case IMacroScriptTypeModel.DataProcessor:
            return {
                type: "DataProcessorMacroScriptModel",
                id: 0,
                code: "",
                name: "",
                description: "",
                script: "",
                publishedVersion: 0,
            };
        case IMacroScriptTypeModel.FileProcessor:
            return {
                type: "FileProcessorMacroScriptModel",
                id: 0,
                code: "",
                name: "",
                description: "",
                script: "",
                publishedVersion: 0,
            };
        case IMacroScriptTypeModel.FileRetriever:
            return {
                type: "FileRetrieverMacroScriptModel",
                id: 0,
                code: "",
                name: "",
                description: "",
                script: "",
                publishedVersion: 0,
            };
        case IMacroScriptTypeModel.MarketDataSelector:
            return {
                type: "MarketDataSelectorMacroScriptModel",
                id: 0,
                code: "",
                name: "",
                description: "",
                script: "",
                publishedVersion: 0,
            };
        case IMacroScriptTypeModel.SubMacro:
            return {
                type: "SubMacroScriptModel",
                id: 0,
                code: "",
                name: "",
                description: "",
                script: "",
                publishedVersion: 0,
            };
        case IMacroScriptTypeModel.Monitoring:
            return {
                type: "MonitoringMacroScriptModel",
                id: 0,
                code: "",
                name: "",
                description: "",
                script: "",
                publishedVersion: 0,
                publishOnPortal: false
            };
    }
}

function translateOpenRequest(screen: string | undefined, detail: any): IMacroScriptTypeModel | number | null {
    if (!detail || !screen) {
        return null;
    }
    const macroScriptId = tryParseNumber(detail?.id);
    if (typeof macroScriptId === "number") {
        return macroScriptId;
    }
    else {
        return detail?.id as IMacroScriptTypeModel;
    }
}
export const onOpenScreenTemplates: Epic<IAnyAction>
    = action$ => {
        const openRequest$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
            filterRoute("Macros", "detail"),
            // filter(({ screen, module, sections: { detail } }) => module === "Computations" && [IMacroScriptTypeModel.PortfolioMonitoring, IMacroScriptTypeModel.FileProcessor, IMacroScriptTypeModel.FileRetriever, IMacroScriptTypeModel.DataProcessor].includes((screen || "") as IMacroScriptTypeModel) && detail),
            map(({ screenKey, matchingSections }) => translateOpenRequest(screenKey, matchingSections?.detail)),
            filter(openRequest => !!openRequest),
            share()
        );
        const newTemplate$ = openRequest$.pipe(
            map(openRequest => {
                if (!openRequest) {
                    return;
                }
                if (typeof openRequest !== "number") {
                    return openRequest
                }
                return;
            }),
            filter(id => !!id),
            map(type => ActionFactories.macroScript.macroScriptNew(type as IMacroScriptTypeModel)));
        const loadTemplate$ = openRequest$.pipe(
            map(openRequest => {
                if (!openRequest) {
                    return;
                }
                if (typeof openRequest === "number") {
                    return openRequest
                }
                return;
            }),
            filter(id => !!id),
            map(id => ActionFactories.macroScript.macroScriptLoad(id as number)));

        return merge(
            newTemplate$,
            loadTemplate$,
        );
    }
export const onOpenScreen: Epic<IAnyAction>
    = action$ => {
        const userLoaded$ = action$.ofType("applicationLoaded").pipe(share(), first()); // Must be loaded from start for templates to be shown in related screens
        const changedScreen$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey),
            filterRoute("Macros"));
        return merge(userLoaded$, changedScreen$).pipe(
            map(() => ActionFactories.macroScript.macroScriptLoadAll()));
    }
export const loadMacroScripts: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("macroScript", "macroScriptLoadAll"),
        mergeMap(() => macroScriptsApi.getAllAsync({})),
        map(ActionFactories.macroScript.macroScriptLoadedAll));

export const deleteMacroScript: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("macroScript", "macroScriptDelete"),
            mergeMap(id => studioMacroScriptsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.macroScript.macroScriptDeleted));
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }
export const loadMacroScript: Epic<IAnyAction>
    = action$ => {
        const macroScript$ = action$.pipe(
            mapToPayload("macroScript", "macroScriptLoad"),
            mergeMap(id => studioMacroScriptsApi.getAsync({ id })),
            share());

        const ruleCheckResult$ = merge(
            macroScript$.pipe(
                filter(({ type }) => type !== "SubMacroScriptModel"),
                mergeMap(({ script, type }) => studioMacroScriptsApi.checkScriptAsync(({ type: mapType(type as Exclude<MacroScriptModel, ISubMacroScriptModel>["type"]), textModel: { text: script } })))),
            macroScript$.pipe(
                filter(({ type }) => type === "SubMacroScriptModel"),
                map(() => ({ errors: [] } as IMacroScriptCheckResultModel))
            )
        );
        const metadata$ = merge(
            macroScript$.pipe(
                filter(({ type }) => type !== "SubMacroScriptModel"),
                mergeMap(({ type }) => studioMacroScriptsApi.getUniverseStructureAsync({ type: mapType(type as Exclude<MacroScriptModel, ISubMacroScriptModel>["type"]) }))),
            macroScript$.pipe(
                filter(({ type }) => type === "SubMacroScriptModel"),
                map(() => ({} as Record<string | number, IEntityMetadataModel>))
            ),
        ).pipe(map(ActionFactories.macroScript.macroScriptMetadataLoaded));

        return merge(
            combineLatest([macroScript$, ruleCheckResult$]).pipe(
                map(([macroScript, macroScriptCheckResult]) => ({ macroScript, macroScriptCheckResult })),
                map(ActionFactories.macroScript.macroScriptLoaded)),
            metadata$);
    };
export const checkScript: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("macroScript", "macroScriptValidateScript"),
        mergeMap(({ script, type }) => studioMacroScriptsApi.checkScriptAsync({ type: mapType(type), textModel: { text: script } })),
        map(ActionFactories.macroScript.macroScriptValidatedScript));

export const executeScript: Epic<IAnyAction>
    = action$ => {
        const execution$ = action$.pipe(mapToPayload("macroScript", "macroScriptExecute"), share());
        const dataProcessorExecution$ = execution$.pipe(
            filter(i => i.type === "DataProcessorMacroScriptModel"),
            mergeMap(r => macroScriptsApi.executeDataProcessorMacroAsync({ id: r.id, scopeId: r.type === "DataProcessorMacroScriptModel" ? r.targetId : undefined })),
            map(ActionFactories.macroScript.macroScriptExecuted));

        const fileProviderExecution$ = execution$.pipe(
            filter(i => i.type === "FileRetrieverMacroScriptModel"),
            mergeMap(({ id }) => macroScriptsApi.executeFileProviderMacroAsync({ id })),
            tap(({ file }) => {
                if (file && file.data && file.mimeType && file.name) {
                    const blob = base64toBlob(file.data, file.mimeType);
                    saveAs(blob, file.name);
                }
            }),
            map(({ result }) => ActionFactories.macroScript.macroScriptFileProviderExecuted(result)));
        const fileProcessorExecution$ = execution$.pipe(
            map(i => {
                if (i.type === "FileProcessorMacroScriptModel") {
                    return i;
                }
                return undefined;
            }),
            onlyNotNull(),
            mergeMap(({ id, file }) => macroScriptsApi.executeFileProcessorMacroAsync({ id, fileModel: file })),
            map(ActionFactories.macroScript.macroScriptExecuted));
        return merge(
            dataProcessorExecution$,
            fileProviderExecution$,
            fileProcessorExecution$
        );
    }

export const newMacroScript: Epic<IAnyAction>
    = action$ => {
        const type$ = action$.pipe(
            mapToPayload("macroScript", "macroScriptNew"), share());

        const metadata$ = merge(
            type$.pipe(
                filter(type => type !== IMacroScriptTypeModel.SubMacro),
                mergeMap(type => studioMacroScriptsApi.getUniverseStructureAsync({ type }))),
            type$.pipe(
                filter(type => type === IMacroScriptTypeModel.SubMacro),
                map(() => ({} as Record<string | number, IEntityMetadataModel>))))
            .pipe(map(ActionFactories.macroScript.macroScriptMetadataLoaded));
        return merge(
            type$.pipe(
                map(type => ({ macroScript: createNewMacroScriptInstance(type), macroScriptCheckResult: { errors: [] } } as IMacroScriptLoadedPayload)),
                map(ActionFactories.macroScript.macroScriptLoaded)),
            metadata$);
    }
export const saveMacroScript: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("macroScript", "macroScriptSave"),
        mergeMap(model => studioMacroScriptsApi.saveAsync({ model })),
        map(ActionFactories.macroScript.macroScriptSaved));
