import { AxisProps } from "@nivo/axes";

export function deltaDate(date1: Date, date2: Date) {
    var diff = Math.floor(date1.getTime() - date2.getTime());
    var day = 1000 * 60 * 60 * 24;

    var dDays = Math.floor(diff / day);
    var dMonths = Math.floor(dDays / 31);
    var dYears = Math.floor(dMonths / 12);
    return { dDays, dMonths, dYears };
}
export default function getTimeAxisSpecs(min: Date, max: Date): AxisProps {
    const { dMonths, dYears } = deltaDate(max, min);
    if (dYears >= 10) {
        return { format: "%Y", tickValues: "every 1 year", tickRotation: 45 };
    }
    if (dMonths >= 12) {
        return { format: "%m %Y", tickValues: "every 1 month", tickRotation: 45 };
    }
    if (dMonths >= 1) {
        return { format: "%d %m %Y", tickValues: "every 1 week", tickRotation: 45 };
    }
    return { format: "%d %m %Y", tickValues: "every 1 day", tickRotation: 45 };
}
