import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import PortfoliosCompliance from "./PortfoliosCompliance";
import { getSiteScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class PortfoliosComplianceScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                gridSize={12}
                slideDirection={"right"}
                component={PortfoliosCompliance}
                path={getSiteScreenUrl("PortfolioComplianceScreen")} />
        </>
    }
}

export default PortfoliosComplianceScreen;
