import * as React from "react";
import ExtendedGrid from "tools/components/ExtendedGrid";
import { DashboardContext, useExtendedGrid, useFilter, useDetailFields, useWidgets, getSubWidgets, useUpdateSelection, UpdateSelectionCallback } from "./DashboardHelpers";
import { CollapsiblePanel } from "./DashboardComponents";
import { FieldDashboardRenderer } from "./FieldDashboardRenderer";
import { IDetailArrayFieldsDashboardFormatting, IFieldsDashboardFormatting, IGridArrayDashboardFormatting, ILayoutDashboardFormatting, IPieChartArrayDashboardFormatting, PathToValue, WidgetFormatting } from "./DashboardFormattingContracts";
import { Box, Divider, Typography } from "@material-ui/core";
import DashboardPieCharts from "./DashboardPieCharts";
// https://docs.npmjs.com/cli/v8/configuring-npm/package-json#local-paths
interface IDashBoardDataProps {
    data: any;
    children: React.ReactNode;
}
function DashBoardData({ data, children }: IDashBoardDataProps) {
    return <DashboardContext.Provider value={{ data, selections: [] }}>
        {children}
    </DashboardContext.Provider>
}
/**
 * DashboardRunner
 */
export interface IDashboardRunnerProps {
    value: any;
}
export function DashboardRunner({ value }: IDashboardRunnerProps) {
    // const property = "$";
    const field = React.useMemo(() => [], []);
    const widgets = React.useMemo(() => getSubWidgets(value, [], null, field), [field, value]);
    const [context, handleUpdateSelection] = useUpdateSelection(value);
    if (!widgets?.length) {
        return null;
    }
    if (widgets.length === 1) {
        return <DashboardContext.Provider value={context}>
            <WidgetDashboard value={value} format={widgets[0].formatting} field={field} onUpdateSelection={handleUpdateSelection} />
        </DashboardContext.Provider>
    }
    return <DashBoardData data={value}>
        <Box
            gridTemplateColumns="repeat(auto-fit, minmax(500px, 1fr))"
            gridTemplateRows="repeat(auto-fit, minmax(400px, 1fr))"
            gridGap={8}
            display={"grid"}>
            {widgets.map(({ field: fd, formatting: f, key, value }) => <CollapsiblePanel collapsedByDefault={f.collapsed ?? false} title={f.label}>
                <WidgetDashboard key={key} field={fd} format={f} value={value} onUpdateSelection={handleUpdateSelection} />
            </CollapsiblePanel>)}
        </Box>
    </DashBoardData>

}

interface IWidgetDashboardProps {
    value: any;
    format: WidgetFormatting;
    field: PathToValue;
    onUpdateSelection: UpdateSelectionCallback;
}
function WidgetDashboard({ format, value, field, onUpdateSelection }: IWidgetDashboardProps) {
    switch (format.type) {
        case "detail": return <DetailDashboardRenderer value={value} formatting={format} field={field} />
        case "grid": return <DataGridDashboardRenderer value={value} formatting={format} field={field} onUpdateSelection={onUpdateSelection} />
        case "pie": return <PieDashboardRenderer value={value} formatting={format} field={field} onUpdateSelection={onUpdateSelection} />
        case "fields": return <FieldsDashboardRenderer value={value} formatting={format} field={field} />
        case "layout": return <LayoutDashboardRenderer value={value} formatting={format} field={field} onUpdateSelection={onUpdateSelection} />
    }
}

interface IPieDashboardRendererProps {
    value: any[];
    formatting: IPieChartArrayDashboardFormatting;
    field: PathToValue;
    onUpdateSelection: UpdateSelectionCallback;
}

function PieDashboardRenderer({ value: arrayDefinition, formatting, field, onUpdateSelection }: IPieDashboardRendererProps) {
    const items = useFilter(arrayDefinition, formatting?.displayCondition);
    const handleRowsSelect = React.useCallback((rows: any[]) => onUpdateSelection(field, rows), [field, onUpdateSelection]);
    const tmp=formatting.fieldValueType;
    return <DashboardPieCharts
        onSelected={handleRowsSelect}
        rows={items}
        fieldValueType={tmp}
        measureColumnCodes={formatting.measures}
        labelField={formatting.labelField}
        pivotColumnCode={formatting.pivots} />
}
interface IDataGridDashboardRendererProps {
    value: any[];
    formatting: IGridArrayDashboardFormatting | null;
    field: PathToValue;
    onUpdateSelection: UpdateSelectionCallback;
}

function DataGridDashboardRenderer({ value: arrayDefinition, formatting, field, onUpdateSelection }: IDataGridDashboardRendererProps) {
    const { columns, getRowKey, state } = useExtendedGrid(arrayDefinition, formatting);
    const items = useFilter(arrayDefinition, formatting?.displayCondition);
    const handleRowsSelect = React.useCallback((rows: any[]) => onUpdateSelection(field, rows), [field, onUpdateSelection]);
    return <ExtendedGrid
        getRowId={getRowKey}
        columns={columns}
        onSelectionChanged={handleRowsSelect}
        rows={items}
        initialState={state}
        multiSelect
        userCanGroup
        stateLess
        showSelectionCheckbox
        defaultExportFileName={`${formatting?.label ?? "export"}.xlsx`} />;
}
interface IDetailDashboardRendererProps {
    value: any[];
    formatting: IDetailArrayFieldsDashboardFormatting | null;
    field: PathToValue;
}
function DetailDashboardRenderer({ value, formatting, field }: IDetailDashboardRendererProps) {
    const items = useFilter(value, formatting?.displayCondition, true);
    if (!items.length) {
        return <p>No selection</p>
    }
    if (items.length === 1) {
        return <FieldsDashboardRenderer value={items[0]} formatting={formatting as IFieldsDashboardFormatting} field={field} />
    }
    return <Box display="flex" flexDirection="column" style={{ gap: 16 }}>
        {items.map((item, idx) => <Box style={{ padding: 8 }}>
            <Typography align="center">Selection {idx + 1}</Typography>
            <Divider style={{ marginTop: 8, marginBottom: 8, color: "black" }} />
            <FieldsDashboardRenderer key={idx} value={item} formatting={formatting as IFieldsDashboardFormatting} field={field} />
        </Box>
        )}
    </Box>
}
/**
 * FieldsObjectRenderer
 */
interface IFieldsDashboardRendererProps {
    value: any;
    formatting: IFieldsDashboardFormatting | null;
    field: PathToValue;
}
function FieldsDashboardRenderer({ value: objectDefinition, formatting, field }: IFieldsDashboardRendererProps) {
    const fields = useDetailFields(objectDefinition, formatting);
    return <Box display="grid" gridGap={8} gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))" padding={1}>
        {fields.map(fieldFormat => <FieldDashboardRenderer key={fieldFormat.key} formatting={fieldFormat} value={objectDefinition[fieldFormat.key]} />)}
    </Box>
}
interface ILayoutDashboardRendererProps {
    value: any;
    formatting: ILayoutDashboardFormatting | null;
    field: PathToValue;
    onUpdateSelection: UpdateSelectionCallback;
}
function LayoutDashboardRenderer(props: ILayoutDashboardRendererProps) {
    const { formatting, field, onUpdateSelection } = props;

    // https://css-tricks.com/snippets/css/complete-guide-grid/#aa-grid-template-areas
    // https://css-tricks.com/books/greatest-css-tricks/flexible-grids/
    const templateAreas = React.useMemo(() => {
        if (!formatting?.templateAreas) {
            return;
        }
        return formatting.templateAreas.map(areas => `'${areas.join(" ")}'`).join(" ");
    }, [formatting?.templateAreas]);

    const widgets = useWidgets(props.value, field, formatting);

    return <Box
        gridTemplateColumns={formatting?.templateColumns}
        gridTemplateRows={formatting?.templateRows}
        gridTemplateAreas={templateAreas}
        gridGap={8}
        display="grid">
        {widgets.map(({ field: fd, formatting: f, key, value }) =>
            <CollapsiblePanel gridArea={f.area} collapsedByDefault={f.collapsed ?? false} title={f.label}>
                <WidgetDashboard key={key} field={fd} format={f} value={value}
                                 onUpdateSelection={onUpdateSelection} />
            </CollapsiblePanel>
        )}
    </Box>
}