import { Formik, FormikHelpers, FormikProps } from "formik";
import { IFeatureModel, IParametersModel } from "proxy/apiProxy";
import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import ParametersData from "./ParametersData";
import ParametersMarketDataProviders from "./ParametersMarketDataProviders";
import MacroScriptVisualEtl from "features/MacroScript/MacroScriptVisualEtl";
import NotGranted from 'components/global/NotGranted';
import ExtensionFields from "./ExtensionFields";
import JsonScriptEditor from "./JsonScriptEditor";
import { oProps } from "tools/lib/utility";
export default function Parameters() {
    const { parameters, parametersSaving, parametersLoading, parametersMarketDataProviders, marketDataImportProcess, dictionaries } = useReduxSelections("parameters");
    const { parametersSave, parametersAddBatchToDictionary } = useReduxActions("parameters");
    const { classificationTypes: securityClassificationTypes } = useReduxSelections("securityClassificationType");
    const { navigationActiveScreen: navigationActiveUrlNode } = useReduxSelections("navigation");
    const { navigationNavigate } = useReduxActions("navigation")
    const isGranted = useGrants();
    const title = "Global Parameters";
    if (!isGranted(IFeatureModel.GeneralSettingsWrite)) {
        return <DetailPanel title={title}>
            <NotGranted />
        </DetailPanel>
    }
    const handleSubmit = (values: IParametersModel, { setSubmitting }: FormikHelpers<IParametersModel>) => {
        parametersSave(values);
        setSubmitting(false);
    }
    const handleTabValueChanged = (value: any) => {
        const tabValue = value as string;
        navigationNavigate({ sectionKey: tabValue });
    }
    return <Formik onSubmit={handleSubmit} initialValues={parameters} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;

    function renderForm({ dirty, isValid, submitForm }: FormikProps<IParametersModel>) {
        const tabValue = getActualTab(navigationActiveUrlNode?.activeSectionKey);
        const tabs: ISubMenu[] | undefined = [{
            label: "General",
            value: "main"
        }, {
            label: "Portfolio Ext. Fields",
            value: "portfolioExtendedFields"
        }, {
            label: "Security Ext. Fields",
            value: "securityExtendedFields"
        }, {
            label: "Targ. SubFund Ext. Fields",
            value: "subFundExtendedFields"
        }, {
            label: "Share Class Ext. Fields",
            value: "shareClassExtendedFields"
        }, {
            label: "Person Ext. Fields",
            value: "personExtendedFields"
        }, {
            label: "Company Ext. Fields",
            value: "companyExtendedFields"
        }, {
            label: "Data Providers",
            value: "dataproviders"
        }, {
            label: "Theme",
            value: "theme"
        }];
        if (marketDataImportProcess) {
            tabs.push({
                label: "Data Providers Process",
                value: "dataprovidersprocess"
            });
        }
        return <DetailPanel
            isQuerying={parametersSaving || parametersLoading}
            tabs={tabs}
            title={title}
            canSave={dirty && isValid}
            onSaveClick={submitForm}
            tabValue={tabValue}
            noPadding={tabValue === "theme"}
            onTabValueChanged={handleTabValueChanged}>
            {(tabValue === "main") && <ParametersData
                onBatchLoaded={parametersAddBatchToDictionary}
                dictionaries={dictionaries}
                securityClassificationTypes={securityClassificationTypes} />}
            {(tabValue === "portfolioExtendedFields") && <ExtensionFields
                extensionFieldsField="portfolioExtensionFields" />}
            {(tabValue === "securityExtendedFields") && <ExtensionFields
                extensionFieldsField="securityExtensionFields" />}
            {(tabValue === "shareClassExtendedFields") && <ExtensionFields
                extensionFieldsField="shareClassExtensionFields" />}
            {(tabValue === "subFundExtendedFields") && <ExtensionFields
                extensionFieldsField="subFundExtensionFields" />}
            {(tabValue === "personExtendedFields") && <ExtensionFields
                extensionFieldsField="personExtensionFields" />}
            {(tabValue === "companyExtendedFields") && <ExtensionFields
                extensionFieldsField="companyExtensionFields" />}
            {(tabValue === "sicavExtendedFields") && <ExtensionFields
                extensionFieldsField="sicavExtensionFields" />}
            {(tabValue === "dataproviders") && <ParametersMarketDataProviders
                parametersMarketDataProviders={parametersMarketDataProviders} />}
            {(tabValue === "dataprovidersprocess" && !!marketDataImportProcess) && <MacroScriptVisualEtl definitionStructure={marketDataImportProcess} />}
            {(tabValue === "theme") && <JsonScriptEditor fieldName={oProps<IParametersModel>().path("themeConfig")} language="json" />}
        </DetailPanel>
    }
}
function getActualTab(tabValue: string | undefined) {
    if (typeof (tabValue) === "undefined" || tabValue.trim() === "") {
        return "main";
    }
    return tabValue;
}