import * as React from "react";
import { GridSize } from "@material-ui/core/Grid";
import { Slide, Grid } from "@material-ui/core";
import autobind from "autobind-decorator";
import { Route, RouteProps, RouteComponentProps } from "react-router";

interface IProps {
    gridSize?: GridSize;
    gridSizeIfNotExact?: GridSize;
    slideDirection?: 'left' | 'right' | 'up' | 'down';
    // moduleCategory?: string;
    // module?: string;
    // screen?: string;
    // section?: string;

}
export interface INavigationRouteRenderProps extends RouteProps<string, { [K: string]: string | undefined }>, IProps {
    // render?: ((props: RouteComponentProps<Params>) => React.ReactNode) | undefined;
    // render: (props: RouteComponentProps<TRouteParams>) => React.ReactNode;
}
// export interface INavigationRouteRenderProps<TRouteParams = any> extends RouteProps, IProps {
//     // render?: ((props: RouteComponentProps<Params>) => React.ReactNode) | undefined;
//     render: (props: RouteComponentProps<TRouteParams>) => React.ReactNode;
// }
export type INavigationRouteComponentProps<TRouteParams  extends { [K in keyof TRouteParams]?: string } = {}, TComponentProps = any> = {
    component: React.ComponentType<RouteComponentProps<TRouteParams> & TComponentProps> | React.ComponentType;
} & RouteProps & IProps & TComponentProps;
export type INavigationRouteProps = INavigationRouteRenderProps | INavigationRouteComponentProps<{ [K: string]: string | undefined }>;

function isRenderProps(props: INavigationRouteProps): props is INavigationRouteRenderProps {
    return !!(props as INavigationRouteRenderProps).render;
}
// function isComponentProps<TRouteParams = any>(props: INavigationRouteProps<TRouteParams>): props is INavigationRouteComponentProps<TRouteParams> {
//     return !!(props as INavigationRouteComponentProps<TRouteParams>).component;
// }

@autobind
class NavigationRoute extends React.Component<INavigationRouteProps> {
    public render() {
        const { gridSize = 12, gridSizeIfNotExact, component: Component, slideDirection, render, ...routeProps } = this.props;
        const renderContent = (props: RouteComponentProps<{ [K: string]: string | undefined }>) => {
            const size = (props.match.isExact || !gridSizeIfNotExact) ? gridSize : gridSizeIfNotExact;
            // const inner=: React.ReactNode = render ? render(props) : Component ? (<Component {...props} />) : null;
            if (slideDirection) {
                return <Slide direction={slideDirection} in={!!props.match} mountOnEnter={true} unmountOnExit={true}>
                    <Grid item={true} xs={size} style={{ height: "100%" }} >
                        {this.renderInner(props)}
                    </Grid>
                </Slide>
            }
            else {
                return <Grid item={true} xs={size} style={{ height: "100%" }} >
                    {this.renderInner(props)}
                </Grid>
            }
        };

        return <Route {...routeProps} render={renderContent} />;
    }
    private renderInner(props: RouteComponentProps<{ [K: string]: string | undefined }>) {
        if (isRenderProps(this.props) && this.props.render) {
            return this.props.render(props);
        }
        else {

            const { location, component, render, children, path, exact, sensitive, strict, gridSize, gridSizeIfNotExact, slideDirection, component: Component, ...innerProps } = this.props;

            return <Component {...props} {...innerProps} />;
        }
    }
}

export default NavigationRoute;
