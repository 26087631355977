import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import CashMovements from "./CashMovements";
import { getSiteDetailScreenUrl, getSiteScreenUrl } from "tools/lib/UrlDictionary";
import CashMovement from "./CashMovement";

@autobind
class CashMovementScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                gridSize={12}
                exact={true}
                slideDirection={"right"}
                component={CashMovements}
                path={getSiteScreenUrl("CashMovements")} />
            <NavigationRoute
                gridSize={12}
                slideDirection={"left"}
                component={CashMovement}
                path={getSiteDetailScreenUrl("CashMovements")} />
        </>
    }
}

export default CashMovementScreen;
