import * as React from "react";
import { IBankAccountSummaryModel } from "proxy/apiProxy";
import { useField } from 'formik';
import AsyncSelect from "tools/components/AsyncSelect";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IGetBankAccountSummary } from "reducers/Reference";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import SearchIcon from '@material-ui/icons/Search';
export interface IBankAccountSelectFieldProps {
    name: string;
    label?: React.ReactNode;
    required?: boolean;
    onSelected?: (found: IGetBankAccountSummary) => void;
}
export function BankAccountSelectField({ name, label, required, onSelected }: IBankAccountSelectFieldProps) {
    const { referenceBankAccountSearch } = useReduxActions("reference");
    const { referenceBankAccountsSearched, referenceBankAccountSearching } = useReduxSelections("reference");
    const validate = (bankAccountId: number | undefined) => {
        if (!required) {
            return;
        }
        return (bankAccountId ?? 0) === 0 ? "Required" : undefined;
    }
    const [, { value, error, initialValue }, { setValue }] = useField<number | undefined>({ name, validate });
    const getOptionLabel = (bankAccount: IBankAccountSummaryModel) => bankAccount.name;
    label = <><SearchIcon fontSize="small"/> {label ?? "Bank Account"}: Type criteria to find a bank account {required ? "*" : ""}</>;
    if ((initialValue || null) !== (value || null)) {
        label = <b>{label}</b>;
    }
    const handleValueSelected = (bankAccount: IBankAccountSummaryModel) => {
        if (onSelected) {
            onSelected({
                bankAccount,
                entities: referenceBankAccountsSearched.entities,
                portfolios: referenceBankAccountsSearched.portfolios
            });
        }
        setValue(bankAccount.id);
    }
    const handleOnSearchOptions = (criterias: string) => referenceBankAccountSearch({ criterias });
    return <ReadOnlyContext.Consumer>
        {readOnly => readOnly ? null : <AsyncSelect onSearchOptions={handleOnSearchOptions} options={referenceBankAccountsSearched.bankAccounts}
            getOptionLabel={getOptionLabel} searching={referenceBankAccountSearching} label={label} onRequiredValueSelected={handleValueSelected} error={!!error} helperText={error} />}
    </ReadOnlyContext.Consumer>
}
