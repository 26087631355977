import { PortfolioTransactionModel, PortfolioTransactionSummaryModel } from "proxy/apiProxy";

export function getPortfolioTransactionTypeLabel(type: (PortfolioTransactionSummaryModel | PortfolioTransactionModel)["type"]): string;
export function getPortfolioTransactionTypeLabel(type: (PortfolioTransactionSummaryModel | PortfolioTransactionModel)["type"] | null): string | null;
export function getPortfolioTransactionTypeLabel(type: (PortfolioTransactionSummaryModel | PortfolioTransactionModel)["type"] | undefined): string | undefined;
export function getPortfolioTransactionTypeLabel(type: (PortfolioTransactionSummaryModel | PortfolioTransactionModel)["type"] | undefined | null): string | null | undefined;
export function getPortfolioTransactionTypeLabel(type: (PortfolioTransactionSummaryModel | PortfolioTransactionModel)["type"] | undefined | null): string | null | undefined {
    if (typeof (type) === "undefined") {
        return undefined;
    }
    if (!type) {
        return null;
    }
    switch (type) {
        case "PortfolioFxTransactionSummaryModel":
        case "PortfolioFxTransactionModel": return "Cash";
        case "PortfolioSecurityTransactionSummaryModel":
        case "PortfolioSecurityTransactionModel": return "Security";
    }
}
