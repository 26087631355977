import * as React from "react";
import { Card, Button, CardActions, CardHeader, Avatar, Collapse, IconButton } from "@material-ui/core";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
// import { roleDomains, roleCollaborationTypes } from "components/processDefinition/FormInterface";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { useReduxActions } from "tools/lib/reduxStoreAccess";
import { EntitySummaryModel, ICounterpartyTypeModel, RelationshipSummaryModel } from "proxy/apiProxy";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { getEnumLabels } from "tools/lib/utility";
import { getEntityName } from "tools/lib/modelUtils";
import { useField } from "formik";
import CloseIcon from '@material-ui/icons/Close';
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { roleCollaborationTypes, roleDomains } from "features/Relationship/FormInterface";

const counterpartyTypes = getEnumLabels(ICounterpartyTypeModel);

export interface IRelationshipSummaryProps {
    entities: Record<string | number, EntitySummaryModel>;
    relationships: Record<string | number, RelationshipSummaryModel>;
    name: string;
}

export default function RelationshipSummary({
    relationships,
    entities,
    name: fieldName
}: IRelationshipSummaryProps) {
    const { navigationNavigate } = useReduxActions("navigation");
    const [expanded, setExpanded] = React.useState(false);
    const [{ value: relationshipId }, , { setValue }] = useField<number | undefined>(fieldName);
    if (!relationshipId) {
        return null;
    }
    const relationship = relationships[relationshipId];
    if (!relationship) {
        return null;
    }
    const { entityId, } = relationship;
    const entity = entities[entityId];

    const avatar = (() => {
        switch (relationship.type) {
            case "RoleRelationshipSummaryModel":
                return <Avatar><LocalOfferIcon /></Avatar>;
            case "CounterpartyRelationshipSummaryModel":
                if (relationship.isAuthorized) {
                    return <Avatar><VerifiedUserIcon /></Avatar>;
                }
                break;
        }
        return;
    })();

    const title = (() => {
        const entity = entities[relationship.entityId] ?? {};
        const entityName = getEntityName(entity) ?? "";
        return entityName;
    })();

    const subTitle = (() => {
        switch (relationship.type) {
            case "RoleRelationshipSummaryModel":
                return `${roleDomains[relationship.domain]}-${roleCollaborationTypes[relationship.collaborationType]}`;
            case "CounterpartyRelationshipSummaryModel":
                if (relationship.counterpartyType) {
                    return counterpartyTypes[relationship.counterpartyType];
                }
                return "Counterparty";
            default:
                return "Investor";
        }
    })();

    const handleSwitchExpand = () => setExpanded(!expanded);
    const handleOpenClick = () => {
        switch (relationship.type) {
            case "RoleRelationshipSummaryModel":
                navigationNavigate({ screenKey: "ServiceProvidersCollaborators", sectionKey: "detail", parameters: { id: relationshipId } });
                break;
            case "CounterpartyRelationshipSummaryModel":
                navigationNavigate({ screenKey: "Counterparties", sectionKey: "detail", parameters: { id: relationshipId } });
                break;
            case "InvestorRelationshipSummaryModel":
                navigationNavigate({ screenKey: "Investors", sectionKey: "detail", parameters: { id: relationshipId } });
                break;
        }
    }
    const handleOpenEntityClick = () => {
        switch (entity.type) {
            case "PersonSummaryModel":
                navigationNavigate({ screenKey: "People", sectionKey: "detail", parameters: { id: entityId } });
                break;
            case "EntityGroupSummaryModel":
                navigationNavigate({ screenKey: "EntityGroups", sectionKey: "detail", parameters: { id: entityId } });
                break;
            case "CompanySummaryModel":
                navigationNavigate({ screenKey: "Companies", sectionKey: "detail", parameters: { id: entityId } });
                break;
            case "SicavSummaryModel":
                navigationNavigate({ screenKey: "Sicavs", sectionKey: "detail", parameters: { id: entityId } });
                break;
        }
    }

    const handleSetUndefined = () => setValue(undefined);
    return <ReadOnlyContext.Consumer>{readOnly => <Card >
        <CardHeader
            avatar={avatar}
            action={<>
                {!readOnly && <IconButton onClick={handleSetUndefined}><CloseIcon /></IconButton>}
                <IconButton onClick={handleSwitchExpand}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </>}
            title={title}
            subheader={subTitle} />
        <Collapse in={expanded} timeout="auto">
            <CardActions>
                <Button size="small" onClick={handleOpenClick}><OpenInBrowser />Open Relationship</Button>
                <Button size="small" onClick={handleOpenEntityClick}><OpenInBrowser />Open Entity</Button>
            </CardActions>
        </Collapse>
    </Card>}
    </ReadOnlyContext.Consumer>
}







export interface IRelationshipSummaryReadOnlyProps {
    entities: Record<string | number, EntitySummaryModel>;
    relationships: Record<string | number, RelationshipSummaryModel>;
    relationshipId: number;
    label: string;
}

export function RelationshipSummaryReadOnly({
    relationships,
    entities,
    relationshipId
}: IRelationshipSummaryReadOnlyProps) {
    const { navigationNavigate } = useReduxActions("navigation");
    const [expanded, setExpanded] = React.useState(false);
    if (!relationshipId) {
        return null;
    }
    const relationship = relationships[relationshipId];
    if (!relationship) {
        return null;
    }
    const { entityId, } = relationship;
    const entity = entities[entityId];

    const avatar = (() => {
        switch (relationship.type) {
            case "RoleRelationshipSummaryModel":
                return <Avatar><LocalOfferIcon /></Avatar>;
            case "CounterpartyRelationshipSummaryModel":
                if (relationship.isAuthorized) {
                    return <Avatar><VerifiedUserIcon /></Avatar>;
                }
                break;
        }
        return;
    })();

    const title = (() => {
        const entity = entities[relationship.entityId] ?? {};
        const entityName = getEntityName(entity) ?? "";
        return entityName;
    })();

    const subTitle = (() => {
        switch (relationship.type) {
            case "RoleRelationshipSummaryModel":
                return `${roleDomains[relationship.domain]}-${roleCollaborationTypes[relationship.collaborationType]}`;
            case "CounterpartyRelationshipSummaryModel":
                if (relationship.counterpartyType) {
                    return counterpartyTypes[relationship.counterpartyType];
                }
                return "Counterparty";
            default:
                return "Investor";
        }
    })();

    const handleSwitchExpand = () => setExpanded(!expanded);
    const handleOpenClick = () => {
        switch (relationship.type) {
            case "RoleRelationshipSummaryModel":
                navigationNavigate({ screenKey: "ServiceProvidersCollaborators", sectionKey: "detail", parameters: { id: relationshipId } });
                break;
            case "CounterpartyRelationshipSummaryModel":
                navigationNavigate({ screenKey: "Counterparties", sectionKey: "detail", parameters: { id: relationshipId } });
                break;
            case "InvestorRelationshipSummaryModel":
                navigationNavigate({ screenKey: "Investors", sectionKey: "detail", parameters: { id: relationshipId } });
                break;
        }
    }
    const handleOpenEntityClick = () => {
        switch (entity.type) {
            case "PersonSummaryModel":
                navigationNavigate({ screenKey: "People", sectionKey: "detail", parameters: { id: entityId } });
                break;
            case "EntityGroupSummaryModel":
                navigationNavigate({ screenKey: "EntityGroups", sectionKey: "detail", parameters: { id: entityId } });
                break;
            case "CompanySummaryModel":
                navigationNavigate({ screenKey: "Companies", sectionKey: "detail", parameters: { id: entityId } });
                break;
            case "SicavSummaryModel":
                navigationNavigate({ screenKey: "Sicavs", sectionKey: "detail", parameters: { id: entityId } });
                break;
        }
    }

    return <Card >
        <CardHeader
            avatar={avatar}
            action={<>
                <IconButton onClick={handleSwitchExpand}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </>}
            title={title}
            subheader={subTitle} />
        <Collapse in={expanded} timeout="auto">
            <CardActions>
                <Button size="small" onClick={handleOpenClick}><OpenInBrowser />Open Relationship</Button>
                <Button size="small" onClick={handleOpenEntityClick}><OpenInBrowser />Open Entity</Button>
            </CardActions>
        </Collapse>
    </Card>
}
