import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import Parameters from "./Parameters";
import { getSiteScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class ParametersScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                gridSize={12}
                slideDirection={"right"}
                component={Parameters}
                path={getSiteScreenUrl("GeneralSettings")} />
        </>
    }
}

export default ParametersScreen;
