import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import Entities from "./Entities";
import Entity from "./Entity";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";
import { IEntityTypeModel } from "proxy/apiProxy";

@autobind
class EntityScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                gridSize={12}
                exact={true}
                slideDirection={"right"}
                component={Entities}
                type={IEntityTypeModel.Company}
                path={getSiteScreenUrl("Companies")} />
            <NavigationRoute
                gridSize={12}
                exact={true}
                slideDirection={"right"}
                component={Entities}
                type={IEntityTypeModel.Person}
                path={getSiteScreenUrl("People")} />
            <NavigationRoute
                gridSize={12}
                exact={true}
                slideDirection={"right"}
                component={Entities}
                type={IEntityTypeModel.EntityGroup}
                path={getSiteScreenUrl("EntityGroups")} />

            <NavigationRoute
                gridSize={12}
                slideDirection={"left"}
                component={Entity}
                path={getSiteDetailScreenUrl("Companies")} />
            <NavigationRoute
                gridSize={12}
                slideDirection={"left"}
                component={Entity}
                path={getSiteDetailScreenUrl("People")} />
            <NavigationRoute
                gridSize={12}
                slideDirection={"left"}
                component={Entity}
                path={getSiteDetailScreenUrl("EntityGroups")} />
        </>
    }
}

export default EntityScreen;
