import { IPortfolioHistoricalValueModel, isSecurityInstrumentSummaryModel } from "proxy/apiProxy";
import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { IDailyDataSearchHistoricalValuesParameters } from "proxy/apiProxy";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { IErrors, oProps } from "tools/lib/utility";
import FieldBox from "tools/components/FieldBox";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import ManagedPortfolioMultiSelectField from "components/searchers/ManagedPortfolioMultiSelectField";
export default function HistoricalValues() {
    const { portfolioHistoricalValueSearch, portfolioHistoricalValueAddPortfoliosToDictionary } = useReduxActions("portfolioHistoricalValue");
    const { portfolioHistoricalValuesLoading, dictionaries, portfolioHistoricalValues } = useReduxSelections("portfolioHistoricalValue");
    const { portfolios, securities } = dictionaries;
    const { referenceCurrencies = {} } = useReduxSelections("reference");

    const columns: IColumnDefinition[] = [
        { name: "PortfolioName", title: "Prtf Name", getCellValue: (row: IPortfolioHistoricalValueModel) => portfolios[row.portfolioId].name, positionToKeep: "left", filteringEnabled: true },
        { name: "PortfolioCode", title: "Prtf Code", getCellValue: (row: IPortfolioHistoricalValueModel) => portfolios[row.portfolioId].internalCode, filteringEnabled: true },
        { name: "Date", title: "Date", getCellValue: (row: IPortfolioHistoricalValueModel) => row.date, columnType: "date" },
        { name: "ShareClassName", title: "Share class Name", getCellValue: (row: IPortfolioHistoricalValueModel) => row.shareClassId && securities[row.shareClassId].name, filteringEnabled: true },
        { name: "ShareClassCode", title: "Share class Code", getCellValue: (row: IPortfolioHistoricalValueModel) => row.shareClassId && securities[row.shareClassId].internalCode, filteringEnabled: true },
        {
            name: "ShareClassIsin", title: "Share class ISIN", getCellValue: (row: IPortfolioHistoricalValueModel) => {
                if (!row.shareClassId) {
                    return null;
                }
                const secu = securities[row.shareClassId];
                if (!secu) {
                    return null;
                }
                return isSecurityInstrumentSummaryModel(secu) ? secu.isin : undefined;
            }, filteringEnabled: true
        },
        { name: "ShareClassCcy", title: "Share class Ccy", getCellValue: (row: IPortfolioHistoricalValueModel) => row.shareClassId && referenceCurrencies[securities[row.shareClassId].currencyId || 0]?.isoCode },
        { name: "NAV", title: "NAV", getCellValue: (row: IPortfolioHistoricalValueModel) => row.shareClassId ? row.shareClassValues && row.shareClassValues.MKT : row.portfolioValues && row.portfolioValues.MKT, columnType: "decimal" },
        { name: "TNA", title: "TNA", getCellValue: (row: IPortfolioHistoricalValueModel) => row.shareClassId ? row.shareClassValues && row.shareClassValues.TNA : row.portfolioValues && row.portfolioValues.TNA, columnType: "decimal" },
        { name: "FundTNA", title: "Fund TNA", getCellValue: (row: IPortfolioHistoricalValueModel) => row.portfolioValues && row.portfolioValues.TNA, columnType: "decimal" },
    ];
    const state: IGridState = {
        "PortfolioName": { width: 280 },
        "PortfolioCode": { width: 150 },
        "Date": { width: 120 },
        "ShareClassName": { width: 280 },
        "ShareClassCode": { width: 150 },
        "ShareClassIsin": { width: 150 },
        "ShareClassCcy": { width: 130 },
        "NAV": { width: 110 },
        "TNA": { width: 180 },
        "FundTNA": { width: 180 },
    };

    const getRowKey = (row: IPortfolioHistoricalValueModel) => `${row.date}-${row.portfolioId}-${row.shareClassId}`;
    const handleSubmit = (model: IDailyDataSearchHistoricalValuesParameters, { setSubmitting }: FormikHelpers<IDailyDataSearchHistoricalValuesParameters>) => {
        portfolioHistoricalValueSearch(model);
        setSubmitting(false);
    }
    const handleValidate = (values: IDailyDataSearchHistoricalValuesParameters) => {
        const errors: IErrors<IDailyDataSearchHistoricalValuesParameters> = {};
        // if (!values.lastOnly) {
        if (!values.from) {
            errors.from = "From date is required";
        }
        if (values.to && values.from && values.from > values.to) {
            errors.to = "Must be greater than the start of period";
        }
        // }
        return errors;
    }
    return <Formik onSubmit={handleSubmit} validate={handleValidate} initialValues={{} as IDailyDataSearchHistoricalValuesParameters} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;
    function renderForm({ isValid, submitForm }: FormikProps<IDailyDataSearchHistoricalValuesParameters>) {

        return <SearchPanel
            isQuerying={portfolioHistoricalValuesLoading}
            onSearchClick={submitForm}
            searchDisabled={!isValid}
            title="Historical Values"
            subTitle="Search portfolio historical values"
            renderSearch={(<FieldBox display="flex" flexDirection="column">
                <FieldBox display="flex" flexGrow="1">
                    <ManagedPortfolioMultiSelectField
                        name={oProps<IDailyDataSearchHistoricalValuesParameters>().path("portfolioIds")}
                        label="Portfolios"
                        dictionaries={dictionaries}
                        onSelected={portfolioHistoricalValueAddPortfoliosToDictionary} />
                </FieldBox>
                <FieldBox display="flex" flexDirection="row">
                    <FormDatePickerField name={oProps<IDailyDataSearchHistoricalValuesParameters>().path("from")} label="From" required={true} />
                    <FormDatePickerField name={oProps<IDailyDataSearchHistoricalValuesParameters>().path("to")} label="To" />
                </FieldBox>
            </FieldBox>
            )}>
            <ExtendedGrid
                defaultExportFileName="PortfolioHistoricalValues.xlsx"
                getRowId={getRowKey}
                columns={columns}
                rows={portfolioHistoricalValues}
                initialState={state}
                userCanGroup={true} />
        </SearchPanel>;
    }
}
