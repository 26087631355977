import { Epic } from 'redux-observable'
import { mergeMap, map, share } from 'rxjs/operators';
import { changedNavigation, mapToPayload } from "lib/rxJsUtility";
import { tenantsApi } from "proxy/apiProxy";
import { ActionFactories, Action } from "./slice";
import { base64toBlob } from 'tools/lib/utility';
import { from, merge } from 'rxjs';
import { IAnyAction, ActionFactories as AnyActionFactories } from 'reducers';
import { filterRoute } from 'tools/lib/UrlDictionary';

export const onOpenScreenTenantManagers: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("TenantsAdministration"),
        map(() => ActionFactories.tenantAdministrationLoad()));
// tenantAdded
export const createTenant: Epic<Action> = action$ => action$.pipe(
    mapToPayload("tenantsAdministration", "tenantAdministrationCreate"),
    mergeMap(async tenant => tenantsApi.createTenantAsync({ tenant })),
    map(ActionFactories.tenantAdministrationCreated));
export const tenantCreated: Epic<IAnyAction> = action$ => action$.pipe(
    mapToPayload("tenantsAdministration", "tenantAdministrationCreated"),
    map(AnyActionFactories.app.tenantAdded));
export const loadTenants: Epic<Action> = action$ => {
    const tenant$ = action$.pipe(
        mapToPayload("tenantsAdministration", "tenantAdministrationLoad"),
        mergeMap(async () => tenantsApi.getTenantsAsync()),
        share());
    return merge(
        tenant$.pipe(
            mergeMap(tenants => from(tenants).pipe(mergeMap(({ id }) => tenantsApi.getImageAsync({ id })
                .then(({ data, mimeType }) => ({ tenantId: id, imageUrl: window.URL.createObjectURL(base64toBlob(data, mimeType)) }))
                .catch(() => ({ tenantId: id }))))),
            map(ActionFactories.tenantAdministrationImageLoaded)),
        tenant$.pipe(map(ActionFactories.tenantAdministrationLoaded)));
}
