import * as React from "react";
import { ISubFundSummaryModel, ISecurityTypeModel, SecuritySummaryModel } from "proxy/apiProxy";
import { useField } from 'formik';
import AsyncSelect from "tools/components/AsyncSelect";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IGetSecuritySummary } from "reducers/Reference";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import SearchIcon from '@material-ui/icons/Search';
export interface ISecuritySelectFieldProps {
    name: string;
    label?: React.ReactNode;
    type?: ISecurityTypeModel;
    required?: boolean;
    onlyUnderManagement?: boolean;
    onSelected?: (found: IGetSecuritySummary) => void;
}
export default function SecuritySelectField({ name, label, required, type, onlyUnderManagement, onSelected }: ISecuritySelectFieldProps) {
    const { referenceSecuritySearch } = useReduxActions("reference");
    const { referenceSecuritiesSearched, referenceSecuritySearching } = useReduxSelections("reference");
    const validate = (securityId: number | undefined) => {
        if (!required) {
            return;
        }
        return (securityId ?? 0) === 0 ? "Required" : undefined;
    }
    const [, { value, error, initialValue }, { setValue }] = useField<number | undefined>({ name, validate });
    const getOptionLabel = (role: SecuritySummaryModel) => role.name;
    label = <><SearchIcon fontSize="small" /> {label ?? "Security"}: Type criteria to find a security {required ? "*" : ""}</>;
    if ((initialValue || null) !== (value || null)) {
        label = <b>{label}</b>;
    }
    const handleValueSelected = (security: SecuritySummaryModel) => {
        if (onSelected) {
            const subFunds: Record<string | number, ISubFundSummaryModel> = {};
            if (security.type === "ShareClassSummaryModel" && security.subFundId) {
                const subfund = referenceSecuritiesSearched.subFunds[security.subFundId];
                subFunds[security.subFundId] = subfund;
            }
            onSelected({ security, entities: {}, subFunds });
            setValue(security.id);
        }
    }
    const handleOnSearchOptions = (criterias: string) => referenceSecuritySearch({ criterias, type, onlyUnderManagement });
    return <ReadOnlyContext.Consumer>{readOnly => readOnly ? null :
        <AsyncSelect onSearchOptions={handleOnSearchOptions} options={referenceSecuritiesSearched.securities}
            getOptionLabel={getOptionLabel} searching={referenceSecuritySearching} label={label} onRequiredValueSelected={handleValueSelected} error={!!error} helperText={error} />}
    </ReadOnlyContext.Consumer>
}
