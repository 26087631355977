import { ISubMenu } from "tools/components/DetailPanel";
import { DevelopmentItemModel } from "proxy/apiProxy";

export const developmentItemMenus: Record<DevelopmentItemModel["type"], ISubMenu[]> = {
    "DashboardMacroDevelopmentItemModel": [
        {
            value: "detail",
            label: "Properties"
        }, {
            value: "macro",
            label: "Macro"
        }
    ], "EtlMacroDevelopmentItemModel": [
        {
            value: "detail",
            label: "Properties"
        }, {
            value: "macro",
            label: "Macro"
        }, {
            value: "visualization",
            label: "Visualization"
        }
    ], "QuestionnaireDevelopmentItemModel": [
        {
            value: "detail",
            label: "Properties"
        }, {
            value: "loadMacro",
            label: "Load Macro"
        }, {
            value: "questionnaire",
            label: "Questionnaire"
        }, {
            value: "completeMacro",
            label: "Complete Macro"
        }
    ], "FileConnectorDevelopmentItemModel": [
        {
            value: "detail",
            label: "Properties"
        }
    ], "FileConsumerMacroDevelopmentItemModel": [
        {
            value: "detail",
            label: "Properties"
        }, {
            value: "macro",
            label: "Macro"
        }, {
            value: "visualization",
            label: "Visualization"
        }
    ], "FileProducerMacroDevelopmentItemModel": [
        {
            value: "detail",
            label: "Properties"
        }, {
            value: "macro",
            label: "Macro"
        }, {
            value: "visualization",
            label: "Visualization"
        }
    ], "PipelineTaskDevelopmentItemModel": [
        {
            value: "detail",
            label: "Properties"
        }, {
            value: "pipeline",
            label: "Pipeline"
        }, {
            value: "visualization",
            label: "Visualization"
        }
    ], "ReportTemplateDevelopmentItemModel": [
        {
            value: "detail",
            label: "Properties"
        }, {
            value: "loadMacro",
            label: "Load Macro"
        }, {
            value: "template",
            label: "Template"
        }
    ]
};
