const screenDictionary = {
    AccountBalances: {
        url: "/portfoliodata/accountBalances"
    },
    BankAccounts: {
        sections: {
            detail: "/:id",
            customViews: "/:id/customScreens"
        },
        url: "/bankaccounts"
    },
    BatchDefinitions: {
        sections: {
            detail: "/:id",
            structure: "/:id/structure",
            visualRepresentation: "/:id/visual",
            conditionalScript: "/:id/conditionalScript",
        },
        url: "/config/batches/definitions"
    },
    CashMovements: {
        sections: {
            detail: "/:id"
        },
        url: "/portfoliodata/cashMovements"
    },
    Companies: {
        sections: {
            detail: "/:id",
            // checkDueDiligence: "/:id/checkduediligence",
            // complianceDueDiligence: "/:id/complianceduediligence",
            entities: "/:id/entities",
            ratings: "/:id/ratings",
            customViews: "/:id/customScreens",
            dashboard: "/:id/dashboard",
            composition: "/:id/composition",
            files: "/:id/files",
            theming: "/:id/theming",
            processes: "/:id/processes"
        },
        url: "/companies"
    },
    EntityGroups: {
        sections: {
            detail: "/:id",
            entities: "/:id/entities",
            // checkDueDiligence: "/:id/checkduediligence",
            // complianceDueDiligence: "/:id/complianceduediligence",
            customViews: "/:id/customScreens",
            dashboard: "/:id/dashboard",
            files: "/:id/files",
            theming: "/:id/theming",
            processes: "/:id/processes"

        },
        url: "/entitygroups"
    },
    Connectors: {
        sections: {
            detail: "/definition",
            visualRepresentation: "/definition/visual",
        },
        url: "/config/connectors"
    },
    Counterparties: {
        sections: {
            detail: "/:id",
            // dueDiligence: "/:id/duediligence",
            customViews: "/:id/customScreens",
            dashboard: "/:id/dashboard",
            processes: "/:id/processes"
        },
        url: "/counterparties"
    },
    CustomScreen: {
        sections: {
            detail: "/:id",
            macro: "/:id/macro",
            template: "/:id/template"
        },
        url: "/config/macros/customscreens"
    },
    DashboardsGetFiles: {
        url: "/dashboardsGetFiles"
    },
    Dashboards: {
        sections: {
            detail: "/:id"
        },
        url: "/dashboardsResult"
    },
    DevelopmentItems: {
        sections: {
            detail: "/:id",
            macro: "/:id/macro",
            visualization: "/:id/visual",
            questionnaire: "/:id/questionnaire",
            template: "/:id/template",
            loadMacro: "/:id/loadmacro",
            completeMacro: "/:id/completemacro",
            pipeline: "/:id/pipeline",
        },
        url: "/developmentItems"
    },
    DocumentDefinitions: {
        sections: {
            detail: "/:id",
            definition: "/:id/definition"
        },
        url: "/documentDefinitions"
    },
    DashboardsExecute: {
        url: "/dashboardsExecute"
    },
    DashboardsDropFiles: {
        url: "/dashboardsDropFiles"
    },
    EntityClassificationTypes: {
        sections: {
            detail: "/:id",
            classifications: "/:id/classifications",
        },
        url: "/config/lists/entityclassificationtypes"
    },
    ProcessDefinitionClassificationTypes: {
        sections: {
            detail: "/:id",
            classifications: "/:id/classifications",
        },
        url: "/config/processes/classificationtypes"
    },
    Executions: {
        sections: {
            detail: "/:id",
            task: "/:id/tasks/:taskId",
            tasks: "/:id/tasks"
        },
        url: "/config/batches/history"
    },
    ProcessDefinitions: {
        sections: {
            detail: "/:id",
            tasks: "/:id/tasks"
        },
        url: "/config/processes/definitions"
    },
    FxRates: {
        sections: {
            detail: "/:id"
        },
        url: "/marketdata/fxrates"
    },
    GeneralSettings: {
        sections: {
            main: "/main",
            portfolioExtendedFields: "/portfolioExtendedFields",
            securityExtendedFields: "/securityExtendedFields",
            shareClassExtendedFields: "/shareClassExtendedFields",
            subFundExtendedFields: "/subFundExtendedFields",
            personExtendedFields: "/personExtendedFields",
            companyExtendedFields: "/companyExtendedFields",
            dataproviders: "/dataproviders",
            dataprovidersprocess: "/dataprovidersprocess",
            theme: "/theme",
        },
        url: "/config/general"
    },
    GlobalPortfolioCompliancePolicy: {
        url: "/config/compliance/globalPortfolioCompliancePolicy"
    },
    GlobalPortfolioDealingPolicy: {
        url: "/config/compliance/globalPortfolioDealingPolicy"
    },
    HistoricalValues: {
        url: "/portfoliodata/historicalvalues"
    },
    History: {
        url: "/config/changes/history"
    },
    Indexes: {
        sections: {
            detail: "/:id",
            historicalValues: "/:id/historicalvalues"
        },
        url: "/marketdata/indexes"
    },
    InvestorClassificationTypes: {
        sections: {
            detail: "/:id",
            classifications: "/:id/classifications",
        },
        url: "/config/lists/investorclassificationtypes"
    },
    RoleRelationshipClassificationTypes: {
        sections: {
            detail: "/:id",
            classifications: "/:id/classifications",
        },
        url: "/config/lists/roleclassificationtypes"
    },
    NoteClassificationTypes: {
        sections: {
            detail: "/:id",
            classifications: "/:id/classifications",
        },
        url: "/config/lists/noteclassificationtypes"
    },
    DevelopmentItemClassificationTypes: {
        sections: {
            detail: "/:id",
            classifications: "/:id/classifications",
        },
        url: "/studio/developmentitems/classificationtypes"
    },
    Investors: {
        sections: {
            detail: "/:id",
            // dueDiligence: "/:id/duediligence",
            profile: "/:id/profile",
            customViews: "/:id/customScreens",
            dashboard: "/:id/dashboard",
            commitments: "/:id/commitments",
            processes: "/:id/processes"
        },
        url: "/investors"
    },
    Loans: {
        sections: {
            detail: "/:id",
            collaterals: "/:id/collaterals",
            covenants: "/:id/covenants",
        },
        url: "/loans"
    },
    MacroMonitoringCalls: {
        sections: {
            detail: "/:id"
        },
        url: "/config/computations/calls"
    },
    MacroScriptCategories: {
        sections: {
            detail: "/:id"
        },
        url: "/config/macros/categories"
    },
    Macros: {
        sections: {
            detail: "/:id",
            macro: "/:id/macro",
            visualetl: "/:id/visual"
        },
        url: "/config/macros/scripts"
    },
    MovementClassificationTypes: {
        sections: {
            detail: "/:id",
            classifications: "/:id/classifications",
        },
        url: "/config/lists/movementclassificationtypes"
    },
    MyPortfolios: {
        sections: {
            detail: "/:id",
            complianceMacro: "/:id/complianceMacro",
            dealingMacro: "/:id/dealingMacro",
            composition: "/:id/composition",
            benchmarkComposition: "/:id/benchmarkcomposition",
            closings: "/:id/closings",
            peers: "/:id/peers",
            computations: "/:id/computations",
            historicalValues: "/:id/historicalvalues",
            customViews: "/:id/customScreens",
            targetExposures: "/:id/targetExposures",
            dashboard: "/:id/dashboard",
            files: "/:id/files",
            processes: "/:id/processes"
        },
        url: "/portfolios/myportfolios"
    },
    MyShareClasses: {
        sections: {
            detail: "/:id",
            historicalValues: "/:id/historicalvalues",
            customViews: "/:id/customScreens",
            dashboard: "/:id/dashboard",
            processes: "/:id/processes"
        },
        url: "/portfolios/myshareclasses"
    },
    MySicavs: {
        sections: {
            detail: "/:id",
            customViews: "/:id/customScreens",
            entities: "/:id/entities",
            dashboard: "/:id/dashboard",
            processes: "/:id/processes"
        },
        url: "/mysicavs"
    },
    NoteCategories: {
        sections: {
            detail: "/:id"
        },
        url: "/notes/categories"
    },
    Notes: {
        sections: {
            detail: "/:id",
            versions: "/:id/versions"
        },
        url: "/notes/notes"
    },
    People: {
        sections: {
            detail: "/:id",
            // checkDueDiligence: "/:id/checkduediligence",
            // complianceDueDiligence: "/:id/complianceduediligence",
            customViews: "/:id/customScreens",
            dashboard: "/:id/dashboard",
            files: "/:id/files",
            composition: "/:id/composition",
            theming: "/:id/theming",
            processes: "/:id/processes"
        },
        url: "/people"
    },
    PortfolioComplianceScreen: {
        url: "/portfoliocompliance"
    },
    PortfolioTransactions: {
        sections: {
            detail: "/:id"
        },
        url: "/portfoliodata/portfoliotransactions"
    },
    Positions: {
        url: "/portfoliodata/positions"
    },
    RegisterTransactions: {
        sections: {
            detail: "/:id"
        },
        url: "/portfoliodata/registertransactions"
    },
    Reports: {
        sections: {
            detail: "/:id"
        },
        url: "/config/reporting/report"
    },
    Securities: {
        sections: {
            detail: "/:id",
            // checkDueDiligence: "/:id/checkduediligence",
            // complianceDueDiligence: "/:id/complianceduediligence",
            historicalValues: "/:id/historicalvalues",
            proxy: "/:id/proxy",
            ratings: "/:id/ratings",
            customViews: "/:id/customScreens",
            dashboard: "/:id/dashboard",
            files: "/:id/files",
            processes: "/:id/processes"
        },
        url: "/securitymanagement/securities"
    },
    SecurityClassificationTypes: {
        sections: {
            detail: "/:id",
            classifications: "/:id/classifications",
        },
        url: "/config/lists/securityclassificationtypes"
    },
    ServiceProvidersCollaborators: {
        sections: {
            detail: "/:id",
            // dueDiligence: "/:id/duediligence",
            permissions: "/:id/permissions",
            customViews: "/:id/customScreens",
            dashboard: "/:id/dashboard",
            processes: "/:id/processes"
        },
        url: "/collaborators"
    },
    Sicavs: {
        sections: {
            detail: "/:id",
            // checkDueDiligence: "/:id/checkduediligence",
            // complianceDueDiligence: "/:id/complianceduediligence",
            customViews: "/:id/customScreens",
            entities: "/:id/entities",
            dashboard: "/:id/dashboard",
            theming: "/:id/theming",
            processes: "/:id/processes"
        },
        url: "/sicavs"
    },
    StatisticDefinitionSets: {
        sections: {
            detail: "/:id",
            definition: "/:id/definition",
        },
        url: "/StatisticDefinitionSets"
    },
    StressTestingScreen: {
        url: "/stresstesting"
    },
    SubFunds: {
        sections: {
            detail: "/:id",
            historicalValues: "/:id/historicalvalues",
            customViews: "/:id/customScreens",
            dashboard: "/:id/dashboard",
            composition: "/:id/composition",
            processes: "/:id/processes"
        },
        url: "/securitymanagement/subfunds"
    },
    ProcessExecutions: {
        sections: {
            detail: ["/:id", "/:type/:targetId"],
        },
        url: "/processexecutions"
    },
    TemplateCategories: {
        sections: {
            detail: "/:id"
        },
        url: "/config/reporting/templatecategories"
    },
    Templates: {
        sections: {
            detail: "/:id",
            designer: "/:id/content",
        },
        url: "/config/reporting/templates"
    },
    Tenancies: {
        sections: {
            detail: "/:id",
        },
        url: "/tenancies"
    },
    ExternalTemplateEdition: {
        url: "/config/reporting/externaltemplate"
    },
    Traces: {
        url: "/config/traces"
    },
    TradeDatesScreen: {
        sections: {
            detail: "/:tradedate", //cashflow
            trades: "/:tradedate/trades",
            tradesEdit: "/:tradedate/tradesedit",
            tradeChecks: "/:tradedate/tradeChecks",
        },
        url: "/tradeblotter/tradebooks"
    },
    TradesScreen: {
        url: "/tradeblotter/trades"
    },
    ValueAtRiskScreen: {
        sections: {
            detail: "/:id"
        },
        url: "/valueatrisk"
    },
    TenantManagers: {
        url: "/tenantManagers"
    },
    TenantsAdministration: {
        url: "/tenantsAdministration"
    },
};

export default screenDictionary;