import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import RegisterTransactions from "./RegisterTransactions";
import RegisterTransaction from "./RegisterTransaction";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class PortfolioTransactionScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute slideDirection={"right"}
                gridSize={12}
                exact={true}
                component={RegisterTransactions} path={getSiteScreenUrl("RegisterTransactions")} />
            <NavigationRoute gridSize={12} slideDirection={"left"} component={RegisterTransaction} path={getSiteDetailScreenUrl("RegisterTransactions")} />
        </>;
    }
}

export default PortfolioTransactionScreen;
