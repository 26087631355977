import DetailPanel from "tools/components/DetailPanel";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { IFeatureModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import CashMovementData from "./CashMovementData";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { GrantRequest } from "tools/lib/utility";
import { ICashMovementData } from "reducers/CashMovement";
import { useMemo } from "react";

export default function CashMovement() {
    const { cashMovementCurrent, cashMovementLoading, dictionaries, cashMovementSaving } = useReduxSelections("cashMovement");
    const { classificationTypes } = useReduxSelections("movementClassificationType");
    const { referenceCurrencies = {} } = useReduxSelections("reference");
    const { cashMovementDelete, cashMovementSave,
        cashMovementEntitySelected,
        cashMovementPortfolioSelected,
        cashMovementSecuritySelected,
        cashMovementBankAccountSelected,
        cashMovementLoadFile
    } = useReduxActions("cashMovement");
    const { navigationNavigate } = useReduxActions("navigation");
    const handleBack = () => navigationNavigate(undefined);
    const handleRequestFileLoad = () => {
        if (!cashMovementCurrent) return;
        cashMovementLoadFile(cashMovementCurrent.id);
    }
    const formValues: ICashMovementData | undefined = useMemo(() => {
        if (!cashMovementCurrent) {
            return undefined;
        }
        const ret: ICashMovementData = {
            cashMovement: cashMovementCurrent
        };
        return ret;
    }, [cashMovementCurrent]);

    if (!formValues) {
        return <DetailPanelLoading onBackClick={handleBack} hasSubTitle={false} />;
    }
    const handleDelete = () => {
        if (!!cashMovementCurrent?.id) {
            cashMovementDelete(cashMovementCurrent.id);
        }
    }

    const title = !!cashMovementCurrent?.id ? cashMovementCurrent.description : 'Cash Movement';
    const handleSubmit = (values: ICashMovementData, { setSubmitting }: FormikHelpers<ICashMovementData>) => {
        cashMovementSave(values);
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={formValues} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm }: FormikProps<ICashMovementData>) {
        const grantRequest: GrantRequest[] = (!!cashMovementCurrent?.id && !!cashMovementCurrent.portfolioId)
            ? [{ feature: IFeatureModel.ManagedPortfolioWrite, portfolioId: cashMovementCurrent.portfolioId }] // if update: edition & save granted only if rights on the initial related portfolio (the target portfolio should be granted as only granted ones are supposed to be selectable)
            : [IFeatureModel.ManagedPortfolioWrite]; //if create: edition and save only if rights on an portfolio (as only granted target target portfolios are selectable)
        return <WriteAccess value={grantRequest}><DetailPanel
            isQuerying={cashMovementLoading || cashMovementSaving}
            title={title}
            canSave={dirty && isValid}
            onSaveClick={submitForm}
            canDelete={!!cashMovementCurrent?.id}
            onDeleteClick={handleDelete}
            onBackClick={handleBack}
            saveAllowed={grantRequest}
            deleteAllowed={grantRequest}
            badge={!cashMovementCurrent?.id ? "new" : undefined}>
            <CashMovementData
                onRequestFileLoad={handleRequestFileLoad}
                classificationTypes={classificationTypes}
                referenceCurrencies={referenceCurrencies}
                onEntitySelected={cashMovementEntitySelected}
                onPortfolioSelected={cashMovementPortfolioSelected}
                onSecuritySelected={cashMovementSecuritySelected}
                onBankAccountSelected={cashMovementBankAccountSelected}
                dictionaries={dictionaries} />
        </DetailPanel></WriteAccess>
    }
}
