import { Box } from "@material-ui/core";
import { ITaskDefinitionStructureModel, IResultErrorModel } from "proxy/apiProxy";
import React from "react";
import StreamsGraph, { IGraphNode } from "tools/diagram/StreamsGraph";

interface IStreamViewerProps {
    definitionStructure: Omit<ITaskDefinitionStructureModel, "name" | "noTraces"> | undefined;
    counters?: Record<string | number, number>;
    error?: IResultErrorModel;
}
export function StreamViewer({ definitionStructure: { links, nodes } = { nodes: {}, links: [] }, counters, error }: IStreamViewerProps) {
    const graphNodes = React.useMemo(() => {
        return Object.keys(nodes).map(key => ({
            id: nodes[key].name,
            name: nodes[key].name,
            type: nodes[key].type,
            memoryFootPrint: nodes[key].memoryFootPrint as IGraphNode["memoryFootPrint"],
            performanceImpact: nodes[key].performanceImpact as IGraphNode["performanceImpact"]
        }));
    }, [nodes]);
    const graphLinks = React.useMemo(() => links.map(({ sourceNode, destinationNode }) => ({ fromId: sourceNode, toId: destinationNode })), [links]);
    const graphErrors = React.useMemo(() => {
        if (!error || !error.nodeName || !error.text) {
            return {};
        }
        return { [error.nodeName]: error.text };
    }, [error]);
    return <Box display="flex" flexDirection="row" style={{ height: "100%", width: "100%" }}>
        <StreamsGraph nodes={graphNodes} links={graphLinks} errors={graphErrors} counters={counters} />
    </Box>;
}
