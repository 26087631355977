import { Epic } from "redux-observable";
import { map, switchMap } from "rxjs/operators";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, castNotNull } from "lib/rxJsUtility";
import {
    GetFilesAsync,
    GetContentAsync, IPmsRepository
} from "tools/lib/octokitHelper";

export const loadCommunityReports: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("communityReportTemplate", "communityReportTemplateLoadAll"),
        switchMap(i => GetFilesAsync(IPmsRepository.Report, "Global")),
        map(reportTemplates => ActionFactories.communityReportTemplate.communityReportTemplateLoadedAll(reportTemplates.filter(reportTemplate => reportTemplate.name.endsWith(".mrt")))));
export const loadLicense: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("communityReportTemplate", "communityReportTemplateLoadAll"),
        switchMap(i => GetContentAsync(IPmsRepository.Report, "LICENSE")),
        castNotNull(),
        map(ActionFactories.communityReportTemplate.communityReportTemplateLicenseLoaded));
export const loadCommunityMacro: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("communityReportTemplate", "communityReportTemplateLoad"),
        switchMap(i => GetContentAsync(IPmsRepository.Report, i)),
        castNotNull(),
        map(ActionFactories.communityReportTemplate.communityReportTemplateLoaded));
