import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import Indexes from "./Indexes";
import Indx from "./Indx";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class SecurityScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute gridSize={12} exact={true} slideDirection={"right"} component={Indexes} path={getSiteScreenUrl("Indexes")} />
            <NavigationRoute gridSize={12} slideDirection={"left"} component={Indx} path={getSiteDetailScreenUrl("Indexes")} />
        </>
    }
}

export default SecurityScreen;
