import { Box, Fab, IconButton, Tooltip } from "@material-ui/core";
import { useField } from "formik";
import {
    EntitySummaryModel, ICounterpartyNoteVersionModel, ICurrencyModel, IEntityNoteVersionModel, IEntityTypeModel,
    IInvestorNoteVersionModel, IPortfolioNoteVersionModel, IRoleNoteVersionModel, ISecurityNoteVersionModel, ISecurityTypeModel,
    IShareClassNoteVersionModel, ISicavNoteVersionModel, ISubFundNoteVersionModel, ISubFundSummaryModel, ITargetSicavNoteVersionModel,
    NoteSummaryVersionModel, NoteVersionModel, PortfolioSummaryModel, RelationshipSummaryModel, SecuritySummaryModel
} from "proxy/apiProxy";
import React, { useMemo } from "react";
import DialogPanel, { IActionButton } from "tools/components/DialogPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { oProps, today, toDictionary } from "tools/lib/utility";
import DeleteIcon from '@material-ui/icons/Delete';
import * as Uuid from 'uuid';
import { NoteSaveForm } from "./NoteSaveForm";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FormMultiCulturedTextField from "tools/fieldComponents/FormMultiCulturedTextField";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
// import StickyContainer from "tools/components/StickyContainer";
import AddIcon from '@material-ui/icons/Add';
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import RelationshipSelectField from "components/searchers/RelationshipSelectField";
import EntitySelectField from "components/searchers/EntitySelectField";
import ManagedPortfolioSelectField from "components/searchers/ManagedPortfolioSelectField";
import SecuritySelectField from "components/searchers/SecuritySelectField";
import SubFundSelectField from "components/searchers/SubFundSelectField";
import ManagedSicavSelectField from "components/searchers/ManagedSicavSelectField";
import { IGetEntitySummary, IGetPortfolioSummary, IGetRelationshipSummary, IGetSecuritySummary, IGetSicavSummary, IGetSubFundSummary } from "reducers/Reference";
import RelationshipSummary from "components/summaries/RelationshipSummary";
import EntitySummary from "components/summaries/EntitySummary";
import ManagedPortfolioSummary from "components/summaries/ManagedPortfolioSummary";
import SecuritySummary from "components/summaries/SecuritySummary";
import SubFundSummary from "components/summaries/SubFundSummary";
interface IDictionaries {
    entities: Record<string | number, EntitySummaryModel>;
    portfolios: Record<string | number, PortfolioSummaryModel>;
    securities: Record<string | number, SecuritySummaryModel>;
    relationships: Record<string | number, RelationshipSummaryModel>;
}
function getEntityLabel(entity: EntitySummaryModel | undefined): { name: string, internalCode: string } {
    if (!entity) {
        return { name: "", internalCode: "" };
    }
    switch (entity.type) {
        case "PersonSummaryModel": return { ...entity, name: `${entity.firstName} ${entity.lastName}` };
        default: return entity;
    }
}
function getRelationshipLabel(relationship: RelationshipSummaryModel, { entities }: IDictionaries): { name: string, internalCode: string } {
    return getEntityLabel(entities[relationship?.entityId ?? 0]);
}

function getNoteSummaryVersionLabel(noteSummaryVersionModel: NoteSummaryVersionModel | NoteVersionModel | undefined, dictionaries: IDictionaries): { name: string, internalCode?: string } {
    if (!noteSummaryVersionModel) {
        return { name: "" }
    }
    switch (noteSummaryVersionModel.type) {
        case "CounterpartyNoteSummaryVersionModel":
        case "CounterpartyNoteVersionModel":
            return getRelationshipLabel(dictionaries.relationships[noteSummaryVersionModel.counterpartyId], dictionaries);
        case "EntityNoteSummaryVersionModel":
        case "EntityNoteVersionModel":
            return getEntityLabel(dictionaries.entities[noteSummaryVersionModel.entityId]);
        case "GlobalNoteSummaryVersionModel":
        case "GlobalNoteVersionModel":
            return { name: "-", internalCode: "-" };
        case "InvestorNoteSummaryVersionModel":
        case "InvestorNoteVersionModel":
            return getRelationshipLabel(dictionaries.relationships[noteSummaryVersionModel.investorId], dictionaries);
        case "PortfolioNoteSummaryVersionModel":
        case "PortfolioNoteVersionModel":
            return dictionaries.portfolios[noteSummaryVersionModel.portfolioId];
        case "RoleNoteSummaryVersionModel":
        case "RoleNoteVersionModel":
            return getRelationshipLabel(dictionaries.relationships[noteSummaryVersionModel.roleId], dictionaries);
        case "SecurityNoteSummaryVersionModel":
        case "SecurityNoteVersionModel":
            return dictionaries.securities[noteSummaryVersionModel.securityId];
        case "ShareClassNoteSummaryVersionModel":
        case "ShareClassNoteVersionModel":
            return dictionaries.securities[noteSummaryVersionModel.shareClassId];
        case "SicavNoteSummaryVersionModel":
        case "SicavNoteVersionModel":
            return getEntityLabel(dictionaries.entities[noteSummaryVersionModel.sicavId]);
        case "SubFundNoteSummaryVersionModel":
        case "SubFundNoteVersionModel":
            return dictionaries.portfolios[noteSummaryVersionModel.subFundId];
        case "TargetSicavNoteSummaryVersionModel":
        case "TargetSicavNoteVersionModel":
            return getEntityLabel(dictionaries.entities[noteSummaryVersionModel.targetSicavId]);
    }
}
function getNoteSummaryTypeLabel(type: (NoteSummaryVersionModel | NoteVersionModel)["type"]): string {
    switch (type) {
        case "CounterpartyNoteSummaryVersionModel":
        case "CounterpartyNoteVersionModel":
            return "Counterparty";
        case "EntityNoteSummaryVersionModel":
        case "EntityNoteVersionModel":
            return "Entity";
        case "GlobalNoteSummaryVersionModel":
        case "GlobalNoteVersionModel":
            return "Global";
        case "InvestorNoteSummaryVersionModel":
        case "InvestorNoteVersionModel":
            return "Investor";
        case "PortfolioNoteSummaryVersionModel":
        case "PortfolioNoteVersionModel":
            return "Portfolio";
        case "RoleNoteSummaryVersionModel":
        case "RoleNoteVersionModel":
            return "Role";
        case "SecurityNoteSummaryVersionModel":
        case "SecurityNoteVersionModel":
            return "Security";
        case "ShareClassNoteSummaryVersionModel":
        case "ShareClassNoteVersionModel":
            return "ShareClass";
        case "SicavNoteSummaryVersionModel":
        case "SicavNoteVersionModel":
            return "Sicav";
        case "SubFundNoteSummaryVersionModel":
        case "SubFundNoteVersionModel":
            return "SubFund";
        case "TargetSicavNoteSummaryVersionModel":
        case "TargetSicavNoteVersionModel":
            return "Target Sicav";
    }
}
function getTypeLabel(version: NoteSummaryVersionModel | NoteVersionModel | undefined): string | undefined {
    if (!version) {
        return undefined;
    }
    return getNoteSummaryTypeLabel(version.type)

}

function DeleteVersion({ onClick, versionKey }: { versionKey: string | number, onClick: (key: string | number) => void }) {
    const handleClick = () => onClick(versionKey);
    return <Tooltip title="Delete">
        <IconButton
            size="small"
            aria-label="Unlink"
            style={{ verticalAlign: "middle" }}
            onClick={handleClick} >
            <DeleteIcon />
        </IconButton>
    </Tooltip>
}
interface IVersionReference {
    summary?: NoteSummaryVersionModel;
    model?: NoteVersionModel;
    key: string | number;
}
export interface INoteVersionsProps {
    dictionaries: IDictionaries;
    currentVersions: NoteSummaryVersionModel[];
    onNoteVersionRequestLoad: (noteSummaryVersionId: number) => void;
    versionContents: Record<number, NoteVersionModel>;
    onRelationshipSelected: (found: IGetRelationshipSummary) => void;
    onEntitySelected: (found: IGetEntitySummary) => void;
    onPortfolioSelected: (found: IGetPortfolioSummary) => void;
    onSecuritySelected: (found: IGetSecuritySummary) => void;
    currencies: Record<number, ICurrencyModel>;
}
export default function NoteVersions({
    currentVersions,
    dictionaries,
    onNoteVersionRequestLoad,
    versionContents,
    ...extraParams
}: INoteVersionsProps) {
    const [{ value: versionsToSave }, , { setValue: setVersionsToSave }] = useField<NoteSaveForm["versionsToSave"]>(oProps<NoteSaveForm>().path("versionsToSave"));
    const [{ value: versionsToDelete }, , { setValue: setVersionsToDelete }] = useField<NoteSaveForm["versionsToDelete"]>(oProps<NoteSaveForm>().path("versionsToDelete"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => void setVersionsToSave({ ...versionContents }), [versionContents]);
    const { summaries } = React.useMemo(() => {
        const versionsToDeleteDico = toDictionary(versionsToDelete, i => i);
        const versionsToUpdate = currentVersions.filter(i => !versionsToDeleteDico[i.id]).map(original => {
            const elt: IVersionReference = {
                summary: original,
                model: versionsToSave[original.id],
                key: original.id
            };
            return elt;
        });
        const versionsToAdd = Object.keys(versionsToSave).filter(i => isNaN(Number(i))).map(i => {
            const elt: IVersionReference = {
                model: versionsToSave[i],
                key: i
            };
            return elt;
        });
        return {
            summaries: [...versionsToUpdate, ...versionsToAdd],
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentVersions, versionsToSave, versionsToDelete, dictionaries]);
    const [selectedNoteVersionKey, setSelectedNoteVersionKey] = React.useState<number | string | undefined>();
    const [addingNoteVersionKey, setAddingNoteVersionKey] = React.useState<string | undefined>();
    const getRowKey = ({ key: id }: IVersionReference) => id;
    const handleBackClick = () => setSelectedNoteVersionKey(undefined);
    const handleRowSelect = ({ key: id }: IVersionReference) => {
        if (typeof id === "number" && !versionContents[id]) {
            onNoteVersionRequestLoad(id);
        }
        setSelectedNoteVersionKey(id);
    }
    const handleDelete = (key: string | number) => {
        if (typeof key === "number") {
            setVersionsToDelete([...versionsToDelete, key]);
        }
        else {
            const { [key]: toDelete, ...newVersionsToSave } = versionsToSave;
            setVersionsToSave(newVersionsToSave);
        }
    }
    const handleAddVersionClick = () => {
        const key = Uuid.v4();
        setAddingNoteVersionKey(key);
        setVersionsToSave({ ...versionsToSave, [key]: { validFrom: today() } as NoteVersionModel })
    }
    const handleCancelAdd = () => {
        const { [addingNoteVersionKey ?? ""]: toAdd, ...newVersionsToSave } = versionsToSave;
        setVersionsToSave(newVersionsToSave);
        setAddingNoteVersionKey(undefined);
    }
    const handleValidateAdd = () => {
        const key = addingNoteVersionKey;
        setAddingNoteVersionKey(undefined);
        setSelectedNoteVersionKey(key);
    }

    const columns: IColumnDefinition[] = [
        { name: "Type", title: "Target Type", getCellValue: ({ summary: original, model: toSave }: IVersionReference) => getTypeLabel(toSave ?? original), filteringEnabled: true },
        { name: "TargetName", title: "Target Name", getCellValue: ({ summary: original, model: toSave }: IVersionReference) => getNoteSummaryVersionLabel(toSave ?? original, dictionaries)?.name, filteringEnabled: true },
        { name: "TargetCode", title: "Target Code", getCellValue: ({ summary: original, model: toSave }: IVersionReference) => getNoteSummaryVersionLabel(toSave ?? original, dictionaries)?.internalCode ?? "", filteringEnabled: true },
        { name: "ValidFrom", title: "Valid From", getCellValue: ({ summary: original, model: toSave }: IVersionReference) => (toSave ?? original)?.validFrom, filteringEnabled: true, columnType: "date" },
        { name: "UpdatedOn", title: "Updated On", getCellValue: ({ summary: original, model: toSave }: IVersionReference) => (toSave ?? original)?.updatedOn, filteringEnabled: true, columnType: "dateTime" },
        { name: "UpdatedBy", title: "Updated By", getCellValue: ({ summary: original, model: toSave }: IVersionReference) => getEntityLabel(dictionaries.entities[(toSave ?? original)?.updatedById ?? 0]).name, filteringEnabled: true },
        { name: "Delete", title: " ", getCellValue: ({ key }: IVersionReference) => <DeleteVersion onClick={handleDelete} versionKey={key} /> },
    ];
    const state: IGridState = {
        "Type": { width: 150 },
        "TargetName": { width: 250, },
        "TargetCode": { width: 150, },
        "ValidFrom": { width: 150 },
        "UpdatedOn": { width: 180 },
        "UpdatedBy": { width: 250 },
    };
    return <ReadOnlyContext.Consumer>
        {readOnly => <>
            <VersionEdit selectedNoteVersionKey={selectedNoteVersionKey} onClose={handleBackClick} />
            <AddNew selectedNoteVersionKey={addingNoteVersionKey} onOk={handleValidateAdd} onCancel={handleCancelAdd} dictionaries={dictionaries} {...extraParams} />
            <ExtendedGrid
                getRowId={getRowKey}
                columns={columns}
                onRowClick={handleRowSelect}
                rows={summaries}
                initialState={state}
                userCanGroup={true}
                defaultExportFileName="NoteVersions.xlsx" />
            {!readOnly && <Box style={{ position: "absolute", bottom: 16, left: 16, zIndex: 999999 }}>
                <Fab color="primary" aria-label="Add" onClick={handleAddVersionClick}>
                    <AddIcon />
                </Fab>
            </Box>}
        </>}
    </ReadOnlyContext.Consumer>
}
interface IVersionEditProps {
    selectedNoteVersionKey?: number | string;
    onClose: () => void;
}
function VersionEdit({ selectedNoteVersionKey, onClose }: IVersionEditProps) {
    const [{ value }] = useField<NoteVersionModel>(oProps<NoteSaveForm>().path("versionsToSave", selectedNoteVersionKey ?? 0));
    return <DialogPanel isOpened={typeof selectedNoteVersionKey !== "undefined"} onBackClick={onClose} closeLabel="OK" title="Version Detail" maxWidth="lg" isQuerying={!value}>
        <Box height={400}>
            <FormDatePickerField label="Valid From" name={oProps<NoteSaveForm>().path("versionsToSave", selectedNoteVersionKey ?? 0, "validFrom")} />
            <FormMultiCulturedTextField label="Content" name={oProps<NoteSaveForm>().path("versionsToSave", selectedNoteVersionKey ?? 0, "content")} multiline={true} />
        </Box>
    </DialogPanel>
}
function getTypeLabels(): Record<NoteVersionModel["type"], string> {
    return {
        "CounterpartyNoteVersionModel": "Counterparty",
        "EntityNoteVersionModel": "Entity",
        "GlobalNoteVersionModel": "Global",
        "InvestorNoteVersionModel": "Investor",
        "PortfolioNoteVersionModel": "Portfolio",
        "RoleNoteVersionModel": "Role",
        "SecurityNoteVersionModel": "Security",
        "ShareClassNoteVersionModel": "ShareClass",
        "SicavNoteVersionModel": "Sicav",
        "SubFundNoteVersionModel": "SubFund",
        "TargetSicavNoteVersionModel": "Target Sicav",
    }
}





interface IAddNewProps {
    selectedNoteVersionKey?: number | string;
    onCancel: () => void;
    onOk: () => void;
    onRelationshipSelected: (found: IGetRelationshipSummary) => void;
    onEntitySelected: (found: IGetEntitySummary) => void;
    onPortfolioSelected: (found: IGetPortfolioSummary) => void;
    onSecuritySelected: (found: IGetSecuritySummary) => void;
    dictionaries: {
        entities: Record<string | number, EntitySummaryModel>;
        portfolios: Record<string | number, PortfolioSummaryModel>;
        securities: Record<string | number, SecuritySummaryModel>;
        relationships: Record<string | number, RelationshipSummaryModel>;
        // subFunds: Record<string | number, ISubFundSummaryModel>;
    }
    currencies: Record<number, ICurrencyModel>;
}
function AddNew({ selectedNoteVersionKey, onCancel, onOk, ...extraParams }: IAddNewProps) {
    const [{ value }] = useField<NoteVersionModel>(oProps<NoteSaveForm>().path("versionsToSave", selectedNoteVersionKey ?? 0));
    const targetTypes = useMemo(() => getTypeLabels(), [])
    const actions: IActionButton[] = [{ label: "OK", onClick: onOk }];
    return <DialogPanel isOpened={typeof selectedNoteVersionKey === "string"} actions={actions} onBackClick={onCancel} title="Version Detail" isQuerying={!value}>
        <FormSimpleSelectField name={oProps<NoteSaveForm>().path("versionsToSave", selectedNoteVersionKey ?? 0, "type")} label="Target Type" options={targetTypes} required={true} />
        <SelectTarget type={value?.type} selectedNoteVersionKey={selectedNoteVersionKey} {...extraParams} />
    </DialogPanel>
}
interface ISelectTargetProps {
    selectedNoteVersionKey?: number | string;
    type?: NoteVersionModel["type"];
    onRelationshipSelected: (found: IGetRelationshipSummary) => void;
    onEntitySelected: (found: IGetEntitySummary) => void;
    onPortfolioSelected: (found: IGetPortfolioSummary) => void;
    onSecuritySelected: (found: IGetSecuritySummary) => void;
    // onSubFundSelected: (found: IGetSubFundSummary) => void;
    currencies: Record<number, ICurrencyModel>;
    dictionaries: {
        entities: Record<string | number, EntitySummaryModel>;
        portfolios: Record<string | number, PortfolioSummaryModel>;
        securities: Record<string | number, SecuritySummaryModel>;
        relationships: Record<string | number, RelationshipSummaryModel>;
        // subFunds: Record<string | number, ISubFundSummaryModel>;
    }
}
function SelectTarget({ type, selectedNoteVersionKey, onRelationshipSelected, onEntitySelected, onPortfolioSelected, onSecuritySelected, dictionaries, currencies }: ISelectTargetProps) {
    const subFunds = React.useMemo(() => toDictionary(Object.values(dictionaries.portfolios).filter(portfolio => portfolio.type === "SubFundSummaryModel"), portfolio => portfolio.id, portfolio => portfolio as ISubFundSummaryModel), [dictionaries.portfolios])
    if (!type) {
        return null;
    }

    const handleSicavSelected = ({ entities, sicav }: IGetSicavSummary) => onEntitySelected({ entity: sicav, entities });
    const handleSubFundSelected = ({ subFund, entities }: IGetSubFundSummary) => onPortfolioSelected({ portfolio: subFund, entities });
    switch (type) {
        case "CounterpartyNoteVersionModel":
            const counterpartyIdPath = oProps<ICounterpartyNoteVersionModel>(oProps<NoteSaveForm>().path("versionsToSave", selectedNoteVersionKey ?? 0)).path("counterpartyId");
            return <>
                <RelationshipSelectField type="CounterpartyRelationshipModel" name={counterpartyIdPath} onSelected={onRelationshipSelected} required={true} />
                <RelationshipSummary {...dictionaries} name={counterpartyIdPath} />
            </>;
        case "EntityNoteVersionModel":
            const entityIdPath = oProps<IEntityNoteVersionModel>(oProps<NoteSaveForm>().path("versionsToSave", selectedNoteVersionKey ?? 0)).path("entityId");
            return <>
                <EntitySelectField name={entityIdPath} onSelected={onEntitySelected} required={true} />
                <EntitySummary {...dictionaries} name={entityIdPath} />
            </>;
        case "GlobalNoteVersionModel":
            return null;
        case "InvestorNoteVersionModel":
            const investorIdPath = oProps<IInvestorNoteVersionModel>(oProps<NoteSaveForm>().path("versionsToSave", selectedNoteVersionKey ?? 0)).path("investorId");
            return <>
                <RelationshipSelectField type="InvestorRelationshipModel" name={investorIdPath} onSelected={onRelationshipSelected} required={true} />
                <RelationshipSummary {...dictionaries} name={investorIdPath} />
            </>;
        case 'PortfolioNoteVersionModel':
            const managedPortfolioIdPath = oProps<IPortfolioNoteVersionModel>(oProps<NoteSaveForm>().path("versionsToSave", selectedNoteVersionKey ?? 0)).path("portfolioId");
            return <>
                <ManagedPortfolioSelectField name={managedPortfolioIdPath} required={true} onSelected={onPortfolioSelected} />
                <ManagedPortfolioSummary {...dictionaries} name={managedPortfolioIdPath} currencies={currencies} />
            </>;
        case "RoleNoteVersionModel":
            const roleIdPath = oProps<IRoleNoteVersionModel>(oProps<NoteSaveForm>().path("versionsToSave", selectedNoteVersionKey ?? 0)).path("roleId");
            return <>
                <RelationshipSelectField type="RoleRelationshipModel" name={roleIdPath} onSelected={onRelationshipSelected} required={true} />
                <RelationshipSummary {...dictionaries} name={roleIdPath} />
            </>;
        case "SecurityNoteVersionModel":
            const securityIdPath = oProps<ISecurityNoteVersionModel>(oProps<NoteSaveForm>().path("versionsToSave", selectedNoteVersionKey ?? 0)).path("securityId");
            return <>
                <SecuritySelectField name={securityIdPath} required={true} onSelected={onSecuritySelected} />
                <SecuritySummary {...dictionaries} name={securityIdPath} currencies={currencies} />
            </>;
        case "ShareClassNoteVersionModel":
            const shareClassIdPath = oProps<IShareClassNoteVersionModel>(oProps<NoteSaveForm>().path("versionsToSave", selectedNoteVersionKey ?? 0)).path("shareClassId");
            return <>
                <SecuritySelectField type={ISecurityTypeModel.ShareClass} onlyUnderManagement={true} name={shareClassIdPath} required={true} onSelected={onSecuritySelected} />
                <SecuritySummary {...dictionaries} name={shareClassIdPath} currencies={currencies} />
            </>;
        case "SicavNoteVersionModel":
            const sicavIdPath = oProps<ISicavNoteVersionModel>(oProps<NoteSaveForm>().path("versionsToSave", selectedNoteVersionKey ?? 0)).path("sicavId");
            return <>
                <ManagedSicavSelectField name={sicavIdPath} required={true} onSelected={handleSicavSelected} />
                <EntitySummary {...dictionaries} name={sicavIdPath} />
            </>;
        case "SubFundNoteVersionModel":
            const subFundIdPath = oProps<ISubFundNoteVersionModel>(oProps<NoteSaveForm>().path("versionsToSave", selectedNoteVersionKey ?? 0)).path("subFundId");
            return <>
                <SubFundSelectField name={subFundIdPath} required={true} onSelected={handleSubFundSelected} />
                <SubFundSummary subFunds={subFunds} name={subFundIdPath} currencies={currencies} />
            </>;
        case "TargetSicavNoteVersionModel":
            const targetSicavIdPath = oProps<ITargetSicavNoteVersionModel>(oProps<NoteSaveForm>().path("versionsToSave", selectedNoteVersionKey ?? 0)).path("targetSicavId");
            return <>
                <EntitySelectField type={IEntityTypeModel.Sicav} name={targetSicavIdPath} required={true} />
                <EntitySummary {...dictionaries} name={targetSicavIdPath} />
            </>;
    }
}