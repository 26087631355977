import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import ChangesHistory from "./ChangesHistory";
import { getSiteScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class ChangesHistoryScreen extends React.PureComponent {
    public render() {
        return <NavigationRoute slideDirection={"right"} component={ChangesHistory} path={getSiteScreenUrl("History")} />
    }
}
export default ChangesHistoryScreen;
