// @ts-nocheck
import DetailPanel from "tools/components/DetailPanel";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { ITenancyModel, IFeatureModel, ISecurityTypeModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import FormTextField from "tools/fieldComponents/FormTextField";
import { oProps } from "tools/lib/utility";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import EntitySummary from "components/summaries/EntitySummary";
import EntitySelectField from "components/searchers/EntitySelectField";
import { CurrencySelectField } from "components/searchers/CurrencySelectField";
import { useMemo } from "react";
import { getEntityName } from "tools/lib/modelUtils";
import SecuritySelectField from "components/searchers/SecuritySelectField";
import SecuritySummary from "components/summaries/SecuritySummary";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { Box, Grid } from "@material-ui/core";

export default function Tenancy() {
    const { tenancyCurrent, tenancyLoading, tenancySaving, dictionaries: { entities, securities } } = useReduxSelections("tenancy");
    const { tenancyDelete, tenancySave, tenancyAddEntityInDictionary, tenancyAddSecurityInDictionary } = useReduxActions("tenancy");
    const { navigationNavigate } = useReduxActions("navigation");
    const {
        referenceCountries = {},
        referenceCurrencies = {}
    } = useReduxSelections("reference");
    const handleBack = () => navigationNavigate(undefined);
    const name = useMemo(() => `${securities[tenancyCurrent?.realEstateId ?? 0]?.name} - ${getEntityName(entities[tenancyCurrent?.leaseHolderId ?? 0])}`, [entities, tenancyCurrent?.leaseHolderId, securities, tenancyCurrent?.realEstateId]);
    if (!tenancyCurrent) {
        return <DetailPanelLoading onBackClick={handleBack} hasSubTitle={false} />;
    }
    const handleDelete = () => {
        if (!!tenancyCurrent?.id) {
            tenancyDelete(tenancyCurrent.id);
        }
    }





    const title = !!tenancyCurrent?.id ? name : 'New tenancy';
    const handleSubmit = (values: ITenancyModel, { setSubmitting }: FormikHelpers<ITenancyModel>) => {
        tenancySave(values);
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={tenancyCurrent} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ values: formValues, dirty, isValid, submitForm }: FormikProps<ITenancyModel>) {
        return <WriteAccess value={IFeatureModel.ManagedPortfolioWrite}><DetailPanel
            isQuerying={tenancyLoading || tenancySaving}
            title={title}
            canSave={dirty && isValid}
            onSaveClick={submitForm}
            canDelete={!!tenancyCurrent?.id}
            onDeleteClick={handleDelete}
            onBackClick={handleBack}
            saveAllowed={IFeatureModel.ManagedPortfolioWrite}
            deleteAllowed={IFeatureModel.ManagedPortfolioWrite}
            badge={!tenancyCurrent?.id ? "new" : undefined}>
            <Grid container={true} spacing={2}>
                <Grid item={true} md={6}>
                    <Box display="flex" flexDirection="column">
                        <SecuritySelectField name={oProps<ITenancyModel>().path("realEstateId")} onSelected={tenancyAddSecurityInDictionary} type={ISecurityTypeModel.RealEstate} required={true} />
                        <SecuritySummary name={oProps<ITenancyModel>().path("realEstateId")} label="Issuer" securities={securities} currencies={referenceCurrencies} />
                    </Box>
                </Grid>
                <Grid item={true} md={6}>
                    <Box display="flex" flexDirection="column">
                        <EntitySelectField name={oProps<ITenancyModel>().path("leaseHolderId")} onSelected={tenancyAddEntityInDictionary} />
                        <EntitySummary name={oProps<ITenancyModel>().path("leaseHolderId")} label="Issuer" countries={referenceCountries} entities={entities} />
                    </Box>
                </Grid>
                <Grid item={true} md={3}>
                    <CurrencySelectField name={oProps<ITenancyModel>().path("leaseCurrencyId")} label="Currency" />
                </Grid>
                <Grid item={true} md={3}>
                    <FormTextField name={oProps<ITenancyModel>().path("estimatedRentalValue")} label="Estimated Rental Value" isNumber={true} adornment={referenceCurrencies[formValues?.leaseCurrencyId ?? 0]?.isoCode} />
                </Grid>
                <Grid item={true} md={3}>
                    <FormTextField name={oProps<ITenancyModel>().path("locatedAreaSqm")} label="located Area Sqm" isNumber={true} adornment="m2" />
                </Grid>
                <Grid item={true} md={3}>
                    <FormTextField name={oProps<ITenancyModel>().path("passingRent")} label="Passing Rent" isNumber={true} adornment={referenceCurrencies[formValues?.leaseCurrencyId ?? 0]?.isoCode} />
                </Grid>
                <Grid item={true} md={4}>
                    <FormDatePickerField name={oProps<ITenancyModel>().path("breakOptionDate")} label="Break Option Date" />
                </Grid>
                <Grid item={true} md={4}>
                    <FormDatePickerField name={oProps<ITenancyModel>().path("leaseStartDate")} label="Lease Start Date" />
                </Grid>
                <Grid item={true} md={4}>
                    <FormDatePickerField name={oProps<ITenancyModel>().path("leaseEndDate")} label="Lease End Date" />
                </Grid>
            </Grid>
        </DetailPanel>
        </WriteAccess>
    }
}
