import siteMap from "siteMap";

import { IUrlKey } from "./UrlDictionary";
import { ISiteMap, ISiteMapModuleCategory, ISiteMapModule, ISiteMapScreen } from "./SiteMapTypes";
import { getConfig } from "lib/userManager";

const { general: { enableStudio } } = getConfig();
export const typedSiteMap: ISiteMap = (siteMap as ISiteMap)
    .map(({ modules, ...moduleCategory }) => ({
        ...moduleCategory, modules: modules
            .filter(module => !module.studioRelated || enableStudio)
            .map(({ screens, ...module }) => ({ ...module, screens: screens.filter(screen => !screen.studioRelated || enableStudio) }))
    }));

export interface ISiteMapSelection {
    moduleCategoryIndex: number;
    moduleCategory: ISiteMapModuleCategory;
    moduleIndex: number;
    module: ISiteMapModule;
    screenIndex: number;
    screen: ISiteMapScreen;
}
export function getSiteMapScreen(screenKey: IUrlKey): ISiteMapSelection | undefined {
    for (let moduleCategoryIndex = 0; moduleCategoryIndex < typedSiteMap.length; moduleCategoryIndex++) {
        const moduleCategory = typedSiteMap[moduleCategoryIndex];
        for (let moduleIndex = 0; moduleIndex < moduleCategory.modules.length; moduleIndex++) {
            const module = moduleCategory.modules[moduleIndex];
            for (let screenIndex = 0; screenIndex < module.screens.length; screenIndex++) {
                const screen = module.screens[screenIndex];
                if (screen.screenKey === screenKey) {
                    return {
                        moduleCategoryIndex,
                        moduleCategory,
                        moduleIndex,
                        module,
                        screenIndex,
                        screen
                    };
                }
            }
        }
    }
    return;
}
