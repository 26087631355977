import { filter } from "rxjs/operators";
import { OperatorFunction, Observable } from "rxjs";
import { matchPath } from "react-router";
import screenDictionary from "screenDictionary";
import { IFeatureModel } from "proxy/apiProxy";
interface IScreenUrl {
    feature?: IFeatureModel | [IFeatureModel];
    url: string;
    sections?: {
        [sectionKey: string]: string | string[];
    }
}
type NavigationTreeType = typeof screenDictionary;

export type IUrlKey = Extract<keyof NavigationTreeType, string>;
export type ISectionKey<S extends IUrlKey> = Extract<keyof SectionList<NavigationTreeType[S]>, string>;

type SectionList<T> = T extends { sections: infer U, url: string } ? U : {};

export const getSiteScreenUrl = (screenKey: IUrlKey) => screenDictionary[screenKey].url;
export const getSiteDetailScreenUrl = (screenKey: IUrlKey) => {
    const { url, sections } = screenDictionary[screenKey] as IScreenUrl;
    if (sections?.detail) {
        const details = Array.isArray(sections.detail) ? sections.detail : [sections.detail];
        const ret= details.map(detail=> `${url}${detail}`);
        return ret;
    }
    if (sections && Object.values(sections).length > 0) {
        const section = Object.values(sections)[0];
        const details = Array.isArray(section) ? section : [section];
        const ret= details.map(detail=> `${url}${detail}`);
        return ret;
    }
    return url;
}
export const getSiteNecessaryFeature = (screenKey: IUrlKey) => {
    const { feature } = screenDictionary[screenKey] as IScreenUrl;
    return feature;
}

export function UnsafeGetSiteMapUrl({ screenKey, activeSectionKey: sectionKey }: IActiveScreen) {
    const screen = screenDictionary[screenKey] as IScreenUrl;
    if (!sectionKey || !screen.sections) {
        return [screen.url];
    }
    const sectionUrls = screen.sections[sectionKey];
    var paths = Array.isArray(sectionUrls) ? sectionUrls : [sectionUrls]

    return paths.map(path => `${screen.url}${path}`);
}

/**
 * @deprecated The goal is to get rid of this so that the screen it self triggers the loading of values
 */
export function filterRoute<
    S extends IUrlKey,
    SC extends ISectionKey<S>
>(screenKey: S, sectionKey?: SC): OperatorFunction<IActiveScreen, IActiveScreen> {
    return function mapOperation(action$: Observable<IActiveScreen>): Observable<IActiveScreen> {
        return action$.pipe(
            filter(i => routeMatches(i, screenKey, sectionKey))
        );
    }
}

export function routeMatches<
    S extends IUrlKey,
    SC extends ISectionKey<S>
>(i: IActiveScreen, screenKey: S, sectionKey?: SC) {
    if (i.screenKey !== screenKey) {
        return false;
    }
    if (sectionKey && (!i.matchingSections || !i.matchingSections[sectionKey])) {
        return false;
    }
    return true;
}
export function getActiveScreen(urlPath: string): IActiveScreen | undefined {
    for (const screenKey in screenDictionary) {
        if (screenDictionary.hasOwnProperty(screenKey)) {
            const screen = screenDictionary[screenKey as keyof (typeof screenDictionary)] as IScreenUrl;
            if (urlPath.startsWith(screen.url)) {
                return {
                    screenKey: screenKey as IUrlKey,
                    parameters: matchPath(urlPath, screen.url)?.params,
                    ...getMatchingSections(screen, urlPath),
                };
            }
            else {
                if (!screen.sections) {
                    const match = matchPath(urlPath, screen.url);
                    if (match) {
                        return {
                            screenKey: screenKey as IUrlKey,
                            parameters: matchPath(urlPath, screen.url)?.params
                        };
                    }
                }
            }
        }
    }
    return;
}
export interface IUrlParams {
    [parameterName: string]: string;
}
export interface IUrlMatchingSections {
    [key: string]: IUrlParams;
}
export interface IActiveScreen extends Partial<IMatchingScreenSections> {
    screenKey: IUrlKey;
    parameters?: IUrlParams;
}
interface IMatchingScreenSections {
    matchingSections: IUrlMatchingSections;
    activeSectionKey: string;
}

function getMatchingSections({ sections, url: screenBaseUrl }: IScreenUrl, pathName: string): IMatchingScreenSections | undefined {
    if (!sections) {
        return;
    }
    let maxUrlDepth = -1;
    let activeSectionKey: string = "";
    const matchingSections = Object
        .keys(sections)
        .flatMap(sectionKey => {
            const sectionUrls = sections[sectionKey];
            var paths = Array.isArray(sectionUrls) ? sectionUrls : [sectionUrls]
            return paths.map(path => {
                const sectionUrl = `${screenBaseUrl}${path}`;
                const match = matchPath(pathName, sectionUrl);
                if (match) {
                    const urlDepth = (sectionUrl.match(/\//g) || []).length;
                    const params = match?.params ?? {};
                    if (urlDepth > maxUrlDepth) {
                        activeSectionKey = sectionKey;
                        maxUrlDepth = urlDepth;
                    }
                    return {
                        sectionKey,
                        params
                    };
                }
                return null;
            });
        })
        .filter(i => !!i)
        .map(i => i as { sectionKey: string, params: {} })
        .reduce((a, { sectionKey, params }) => ({ ...a, [sectionKey]: params }), {} as IUrlMatchingSections);
    return {
        matchingSections,
        activeSectionKey
    };
}

const defaultScreenKey = Object.keys(screenDictionary)[0];
export const defaultActiveNode: IActiveScreen = {
    screenKey: defaultScreenKey as IUrlKey,
};
