import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import { IConnectorsModel, IFeatureModel } from "proxy/apiProxy";
import { FormikProps, Formik, FormikHelpers } from 'formik';
import ConnectorsScript from "./ConnectorsScript";
import ConnectorsVisualization from "./ConnectorsVisualization";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IActionButton } from "tools/components/FabContainer";
import NotGranted from 'components/global/NotGranted';
import { getConfig } from "lib/userManager";

const { general: { disableSudo } } = getConfig();

export default function Connectors() {
    const { connectorsSave, connectorsProcessorTest, connectorsProviderTest } = useReduxActions("connector");
    const { connectorsLoading, connectorsSaving, connectors, connectorsScriptSchema = {}, connectorsState } = useReduxSelections("connector");
    const { navigationActiveScreen: navigationActiveUrlNode } = useReduxSelections("navigation");
    const { navigationNavigate } = useReduxActions("navigation")
    const isGranted = useGrants();
    const title = "Connectors";
    if (!isGranted(IFeatureModel.ConnectorsWrite)) {
        return <DetailPanel title={title}>
            <NotGranted />
        </DetailPanel>
    }
    const handleSubmit = (model: IConnectorsModel, { setSubmitting }: FormikHelpers<IConnectorsModel>) => {
        connectorsSave(model);
        setSubmitting(false);
    }
    const handleTabValueChanged = (value: any) => {
        const tabValue = (value ?? "detail") as string;
        navigationNavigate({ sectionKey: tabValue });
    }
    return <Formik onSubmit={handleSubmit} initialValues={connectors} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;

    function renderForm({ dirty, isValid, submitForm, values: { jsonConfig } }: FormikProps<IConnectorsModel>) {
        const tabs = [{
            value: "detail",
            label: "Visualization"
        }, {
            value: "visualRepresentation",
            label: "Definition"
        }] as ISubMenu[];
        const tabValue = ((navigationActiveUrlNode?.activeSectionKey ?? "") === "") ? "detail" : navigationActiveUrlNode?.activeSectionKey;
        const extraActionButtons: IActionButton[] | undefined = undefined;
        const handleSaveClick = () => {
            if (isValid && dirty)
                submitForm();
        }
        return <DetailPanel
            isQuerying={connectorsLoading || connectorsSaving}
            title={title}
            tabs={tabs}
            tabValue={tabValue}
            noPadding={tabValue === "visualRepresentation"}
            onTabValueChanged={handleTabValueChanged}
            canSave={dirty && isValid}
            onSaveClick={handleSaveClick}
            saveMustBeConfirmed={!disableSudo}
            actions={extraActionButtons}>
            {tabValue === "visualRepresentation" && <ConnectorsScript jsonSchema={connectorsScriptSchema} onSaveRequest={handleSaveClick} />}
            {tabValue === "detail" && <ConnectorsVisualization
                testDisabled={dirty}
                script={jsonConfig}
                connectorStates={connectorsState}
                onProcessorTestRequest={connectorsProcessorTest}
                onProviderTestRequest={connectorsProviderTest} />}
        </DetailPanel>
    }
}
