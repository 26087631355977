import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import Batches from "./Batches";
import Batch from "./Batch";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class SicavScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                gridSize={12}
                exact={true}
                slideDirection={"right"}
                component={Batches}
                path={getSiteScreenUrl("BatchDefinitions")} />
            <NavigationRoute
                gridSize={12}
                slideDirection={"left"}
                component={Batch}
                path={getSiteDetailScreenUrl("BatchDefinitions")} />
        </>
    }
}

export default SicavScreen;
