import produce from "immer";
import { EntityModel, IGetEntityModel, IGetEntitiesModel, EntitySummaryModel, IEntityTypeModel, RelationshipSummaryModel, IProcessDefinitionSummaryModel, IEntitiesSearchParameters, IRatingModel, CustomScreenModel, CustomScreenSummaryModel, ICustomScreenDataModel, IGetCustomScreensDataModel, ISecuritiesSubmitCustomScreenDataParameters, IMonitoringResultsetModel, IMacroScriptsGetMonitoringResultForTargetParameters, IEntityPositionModel, ISubFundSummaryModel, SecuritySummaryModel, IGetEntityPositionsModel, IRelatedFileModel, IEntitiesGetFileContentParameters, ProcessExecutionSummaryModel, IGetProcessExecutionsModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IBase64File } from "tools/lib/utility";
// import { IDueDiligence } from "../Relationship/IDueDiligence";
import { IRelatedFileWithContent } from "../../reducers/IRelatedFileWithContent";
import { IGetEntitySummary, IGetRelationshipSummary, IGetSecuritySummary } from "../../reducers/Reference";
// import { IGetFilePayload } from "features/Relationship/IGetFilePayload";
// import { IDueDiligenceState } from "features/Relationship/IDueDiligenceState";
export type VisualizationType = "GRID" | "CHART";

export interface IEntityData {
    entity: EntityModel;
    imageFile?: IBase64File;
    files: IRelatedFileWithContent[];
}

export interface IState {
    ratings?: IRatingModel[];
    entityAllLoading: boolean;
    entityLoading: boolean;
    filesLoading: boolean;
    entities: (EntitySummaryModel | EntityModel)[];
    entityCurrent?: EntityModel;
    entitySaving: boolean;
    positionSaving: boolean;
    entityDeleting: boolean;
    dictionaries: {
        entities: Record<EntitySummaryModel["id"], EntitySummaryModel>;
        relationships: Record<string | number, RelationshipSummaryModel>;
        processDefinitions: Record<string | number, IProcessDefinitionSummaryModel>;
        customScreens: Record<string | number, CustomScreenSummaryModel>;
        subFunds: Record<number, ISubFundSummaryModel>;
        securities: Record<number, SecuritySummaryModel>;
    }
    entityPicture?: IBase64File;

    customScreens?: CustomScreenModel[];
    customScreenDatas?: ICustomScreenDataModel[];
    customScreenDataSubmitting: boolean;
    customScreenDataLoading: boolean;

    files?: IRelatedFileModel[];

    compositionDates: Date[];
    compositionDatesLoading: boolean;
    compositionDateLoading: boolean;
    currentCompositionDate?: Date;
    composition?: IEntityPositionModel[];


    compositionVisualizationType: VisualizationType;

    monitoringResults: Record<number, IMonitoringResultsetModel | null>;
    fileContentsLoading: Record<number, boolean>;
    processExecutions?: ProcessExecutionSummaryModel[];
    processExecutionsLoading: boolean;
}
export interface IEntityLoadedPayload extends IGetEntityModel {
    entityPicture?: IBase64File;
    files: IRelatedFileModel[];
}
export interface IEntitySavedPayload {
    entity: EntityModel;
    files: IRelatedFileModel[];
}
export const ActionFactories = produceActionFactories({
    entityLoad: (payload: EntityModel["id"] | IEntityTypeModel) => payload,
    entityLoaded: (payload: IEntityLoadedPayload) => payload,
    entitySave: (payload: IEntityData) => payload,
    entitySaved: (payload: IEntitySavedPayload) => payload,
    entityDelete: (payload: EntityModel["id"]) => payload,
    entityDeleted: (payload: EntityModel["id"]) => payload,
    entityLoadAll: (payload: IEntitiesSearchParameters) => payload,
    entityLoadedAll: (payload: IGetEntitiesModel) => payload,
    entityAddEntityInDictionary: (payload: IGetEntitySummary) => payload,
    entityLoadedRelationship: (payload: IGetRelationshipSummary) => payload,
    entityRatingsLoad: (payload: number) => payload,
    entityRatingsLoaded: (payload: IRatingModel[]) => payload,
    entityAddSecurityInDictionary: (payload: IGetSecuritySummary) => payload,

    entityDatesLoad: (payload: number) => payload,
    entityDatesLoaded: (payload: Date[]) => payload,
    entityDateLoad: (payload: Date) => payload,
    entityCompositionLoaded: (payload: IGetEntityPositionsModel) => payload,
    entityDateLoaded: (payload: Date) => payload,
    entityCompositionVisualizationTypeChange: (payload: VisualizationType) => payload,
    entityPositionSave: (payload: IEntityPositionModel) => payload,
    entityPositionSaved: (payload: IEntityPositionModel) => payload,
    entityPositionDelete: (payload: number) => payload,
    entityPositionDeleted: (payload: number) => payload,


    // entityFilesLoaded: (payload: IRelatedFileModel[]) => payload,
    entityFileContentLoad: (payload: IEntitiesGetFileContentParameters) => payload,
    entityFileContentLoaded: (payload: IEntitiesGetFileContentParameters) => payload,

    // // <DUE DIL MANAGEMENT>
    // entityComplianceDueDiligenceLoaded: (payload: IGetProcessExecutionModel | undefined) => payload,
    // entityChecksDueDiligenceLoaded: (payload: IGetProcessExecutionModel | undefined) => payload,

    // entityLoadProcesses: (payload: number[]) => payload,
    // entityLoadedProcesses: (payload: IProcessDefinitionModel[]) => payload,

    // entityLoadComplianceFile: (payload: IGetFilePayload) => payload,
    // entityLoadedComplianceFile: (payload: IGetFilePayload) => payload,
    // entitySaveComplianceFile: (payload: string) => payload,
    // entitySavedComplianceFile: (payload: string) => payload,

    // entityLoadChecksFile: (payload: IGetFilePayload) => payload,
    // entityLoadedChecksFile: (payload: IGetFilePayload) => payload,
    // entitySaveChecksFile: (payload: string) => payload,
    // entitySavedChecksFile: (payload: string) => payload,

    // // </DUE DIL MANAGEMENT>
    entityCustomScreensLoaded: (payload: CustomScreenModel[]) => payload,
    entityCustomScreenDatasLoad: (payload: number) => payload,
    entityCustomScreenDatasLoaded: (payload: IGetCustomScreensDataModel) => payload,
    entityCustomScreenDataSubmit: (payload: ISecuritiesSubmitCustomScreenDataParameters) => payload,
    entityCustomScreenDataSubmitted: (payload: ICustomScreenDataModel) => payload,

    entityMonitoringResultLoad: (payload: IMacroScriptsGetMonitoringResultForTargetParameters) => payload,
    entityMonitoringResultLoaded: (payload: IMonitoringResultsetModel) => payload,
    entityProcessExecutionsLoad: (payload: number) => payload,
    entityProcessExecutionsLoaded: (payload: IGetProcessExecutionsModel) => payload,
});

export function reducer(
    state: IState = {
        entityLoading: false,
        entityAllLoading: false,
        entitySaving: false,
        positionSaving: false,
        entityDeleting: false,
        filesLoading: false,
        entities: [],
        dictionaries: {
            entities: {},
            processDefinitions: {},
            relationships: {},
            customScreens: {},
            securities: {},
            subFunds: {}
        },
        compositionDateLoading: false,
        compositionDates: [],
        compositionDatesLoading: false,
        customScreenDataLoading: false,
        customScreenDataSubmitting: false,
        monitoringResults: {},
        compositionVisualizationType: "GRID",
        fileContentsLoading: {},
        processExecutionsLoading: false
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "entityProcessExecutionsLoad":
                draft.processExecutionsLoading = true;
                break;
            case "entityProcessExecutionsLoaded":
                draft.processExecutionsLoading = false;
                draft.processExecutions = action.payload.processExecutions;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                break;
            case "entityPositionSave":
                draft.positionSaving = true;
                break;
            case "entityPositionSaved":
                draft.positionSaving = false;
                if (!draft.composition)
                    draft.composition = [];
                var existingPositionIndex = draft.composition.findIndex(i => i.id === action.payload.id);
                if (existingPositionIndex >= 0) {
                    draft.composition.splice(existingPositionIndex, 1, action.payload);
                }
                else {
                    draft.composition.unshift(action.payload);
                }
                break;
            case "entityPositionDelete":
                draft.positionSaving = true;
                if (draft.composition) {
                    var positionToRemove = draft.composition.findIndex(i => i.id === action.payload);
                    if (positionToRemove >= 0) {
                        draft.composition.splice(positionToRemove, 1);
                    }
                }
                break;
            case "entityPositionDeleted":
                draft.positionSaving = false;
                break;
            case "entityDatesLoad":
                draft.composition = undefined;
                draft.compositionDatesLoading = true;
                delete draft.processExecutions;
                break;
            case "entityDatesLoaded":
                draft.compositionDatesLoading = false;
                draft.compositionDates = action.payload.sort((a, b) => (a ? a.getTime() : 0) - (b ? b.getTime() : 0));
                break;
            case "entityDateLoaded":
                draft.currentCompositionDate = action.payload;
                draft.compositionDateLoading = false;
                break;
            case "entityDateLoad":
                draft.compositionDateLoading = true;
                break;
            case "entityCompositionLoaded":
                draft.composition = action.payload.positions;
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities }
                draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds }
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities }
                break;
            case "entityAddSecurityInDictionary":
                draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds };
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.securities[action.payload.security.id] = action.payload.security;
                break;

            case "entityMonitoringResultLoad":
                draft.monitoringResults[action.payload.id] = null;
                break;
            case "entityMonitoringResultLoaded":
                draft.monitoringResults[action.payload.monitoringMacroId] = action.payload;
                break;


            case "entityCustomScreensLoaded":
                draft.customScreens = action.payload;
                break;
            case "entityCustomScreenDatasLoad":
                draft.customScreenDataLoading = true;
                break;
            case "entityCustomScreenDatasLoaded":
                draft.customScreenDataLoading = false;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.customScreens = { ...draft.dictionaries.customScreens, ...action.payload.customScreens };
                draft.customScreenDatas = action.payload.customScreenDatas;
                break;
            case "entityCustomScreenDataSubmit":
                draft.customScreenDataSubmitting = true;
                break;
            case "entityCustomScreenDataSubmitted":
                if (!draft.customScreenDatas) {
                    draft.customScreenDatas = [];
                }
                const customScreenData = draft.customScreenDatas.find(i => i.customScreenId === action.payload.customScreenId);
                if (!!customScreenData) {
                    Object.assign(customScreenData, action.payload);
                }
                else {
                    draft.customScreenDatas.push(action.payload);
                }
                draft.customScreenDataSubmitting = false;
                break;


            case "entityLoadedRelationship":
                draft.dictionaries.relationships[action.payload.relationship.id] = action.payload.relationship;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                break;
            case "entityAddEntityInDictionary":
                draft.dictionaries.entities[action.payload.entity.id] = action.payload.entity;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                break;
            case "entityRatingsLoaded":
                draft.ratings = action.payload;
                break;
            case "entityRatingsLoad":
                draft.ratings = undefined;
                break;
            case "entityLoadAll":
                draft.entityAllLoading = true;
                break;
            case "entityLoadedAll":
                {
                    draft.entityAllLoading = false;
                    const { all, ...dictionaries } = action.payload;
                    draft.entities = all;
                    draft.dictionaries = { ...draft.dictionaries, ...dictionaries };
                }
                break;
            case "entityLoad":
                draft.entityLoading = true;
                draft.entityCurrent = undefined;
                // draft.relatedProcesses = undefined;
                // draft.checksDueDiligence = undefined;
                // draft.complianceDueDiligence = undefined;
                draft.customScreens = undefined;
                draft.customScreenDatas = undefined;
                draft.entityPicture = undefined;
                draft.monitoringResults = {};
                draft.compositionDates = [];
                draft.composition = [];
                draft.fileContentsLoading = {};
                draft.files = undefined;
                break;
            case "entityLoaded":
                {
                    draft.entityLoading = false;
                    const { entity, entityPicture, ...dictionaries } = action.payload;
                    draft.entityCurrent = entity;
                    draft.entityPicture = entityPicture;
                    draft.dictionaries.entities = { ...draft.dictionaries.entities, ...dictionaries.entities };
                    draft.files = action.payload.files;
                }
                break;
            case "entitySave":
                draft.entitySaving = true;
                break;
            case "entitySaved":
                draft.entitySaving = false;
                const saved = action.payload;
                draft.entityCurrent = saved.entity;
                const existing = draft.entities.find(i => i.id === saved.entity.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.entities.push(saved.entity);
                }
                draft.files = saved.files;
                break;
            case "entityDelete":
                draft.entityDeleting = true;
                break;
            case "entityDeleted":
                draft.entityDeleting = false;
                const deletedId = action.payload;
                if (draft.entityCurrent?.id === deletedId) {
                    delete draft.entityCurrent;
                }
                const idx = draft.entities.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.entities.splice(idx, 1);
                }
                break;
            case "entityCompositionVisualizationTypeChange":
                draft.compositionVisualizationType = action.payload;
                break;

            // // <DUE DIL MANAGEMENT>
            // case "entityComplianceDueDiligenceLoaded":
            //     draft.complianceDueDiligence = {
            //         execution: action.payload?.dueDiligenceExecution,
            //         fileLoading: {},
            //         fileSaving: {}
            //     };
            //     if (action.payload?.dueDiligenceExecution) {
            //         draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            //         draft.dictionaries.processDefinitions = { ...draft.dictionaries.processDefinitions, ...action.payload.processDefinitions };
            //         draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
            //     }
            //     break;
            // case "entityChecksDueDiligenceLoaded":
            //     draft.checksDueDiligence = {
            //         execution: action.payload?.dueDiligenceExecution,
            //         fileLoading: {},
            //         fileSaving: {}
            //     };
            //     if (action.payload?.dueDiligenceExecution) {
            //         draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            //         draft.dictionaries.processDefinitions = { ...draft.dictionaries.processDefinitions, ...action.payload.processDefinitions };
            //         draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
            //     }
            //     break;
            // case "entityLoadChecksFile":
            //     if (draft.checksDueDiligence) {
            //         draft.checksDueDiligence.fileLoading[action.payload.taskCode] = true;
            //     }
            //     break;
            // case "entityLoadedChecksFile":
            //     if (draft.checksDueDiligence) {
            //         delete draft.checksDueDiligence.fileLoading[action.payload.taskCode];
            //     }
            //     break;
            // case "entitySaveChecksFile":
            //     if (draft.checksDueDiligence) {
            //         draft.checksDueDiligence.fileSaving[action.payload] = true;
            //     }
            //     break;
            // case "entitySavedChecksFile":
            //     if (draft.checksDueDiligence) {
            //         delete draft.checksDueDiligence.fileSaving[action.payload];
            //     }
            //     break;
            // case "entityFileContentLoad":
            //     draft.fileContentsLoading[action.payload.fileId] = true;
            //     break;
            // case "entityFileContentLoaded":
            //     delete draft.fileContentsLoading[action.payload.fileId];
            //     break;
            // case "entityLoadComplianceFile":
            //     if (draft.complianceDueDiligence) {
            //         draft.complianceDueDiligence.fileLoading[action.payload.taskCode] = true;
            //     }
            //     break;
            // case "entityLoadedComplianceFile":
            //     if (draft.complianceDueDiligence) {
            //         delete draft.complianceDueDiligence.fileLoading[action.payload.taskCode];
            //     }
            //     break;
            // case "entitySaveComplianceFile":
            //     if (draft.complianceDueDiligence) {
            //         draft.complianceDueDiligence.fileSaving[action.payload] = true;
            //     }
            //     break;
            // case "entitySavedComplianceFile":
            //     if (draft.complianceDueDiligence) {
            //         delete draft.complianceDueDiligence.fileSaving[action.payload];
            //     }
            //     break;
            // case "entityLoadedProcesses":
            //     draft.processesLoading = false;
            //     draft.relatedProcesses = [...draft.relatedProcesses ?? [], ...action.payload];
            //     break;
            // case "entityLoadProcesses":
            //     draft.processesLoading = true;
            //     break;
            // // </DUE DIL MANAGEMENT>
        }
    });
}
