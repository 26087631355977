import * as React from "react";
import { IBatchInstanceSummaryModel, ITasksStatusModel } from "proxy/apiProxy";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import SearchPanel from "tools/components/SearchPanel";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { IBatchInstancesSearchParameters } from "proxy/apiProxy";
import FieldBox from "tools/components/FieldBox";
import { oProps, IErrors, today } from "tools/lib/utility";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { BatchInstanceStatusBadge } from "components/batch/BatchInstanceStatusBadge";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";

export default function BatchInstances() {
    const { navigationNavigate } = useReduxActions("navigation");
    const {
        batchInstances,
        dictionaries: { batches: listedBatches },
        batchInstancesSearching 
    } = useReduxSelections("batchInstance");
    const initialParams = React.useMemo(() => {
        const from = today();
        from.setDate(from.getDate() - 1);
        return ({ from }) as IBatchInstancesSearchParameters;
    }, []);
    const { batchInstanceSearch } = useReduxActions("batchInstance");
    const { navigationActiveSiteMapNode } = useReduxSelections("navigation");
    // IBatchInstanceSummaryModel
    const columns: IColumnDefinition[] = [
        {
            name: "Batch", title: "Batch",
            getCellValue: ({ batchId = 0 }: IBatchInstanceSummaryModel) => {
                const batch = listedBatches[batchId];
                if (batch) {
                    return `${batch.code}-${batch.name}`;
                }
                return;
            }, filteringEnabled: true
        },
        {
            name: "Version", title: "Version",
            getCellValue: ({ executedVersionNumber, batchId = 0 }: IBatchInstanceSummaryModel) => {
                if (executedVersionNumber) {
                    return `${executedVersionNumber}/${listedBatches[batchId]?.publishedVersion}`;
                }
                else {
                    return "From script";
                }
            }
        },
        {
            name: "StartDateTime", title: "Start DateTime",
            getCellValue: ({ executionDateTime }: IBatchInstanceSummaryModel) => executionDateTime, columnType: "dateTime"
        },
        {
            name: "EndDateTime", title: "End DateTime",
            getCellValue: ({ endOfExecutionDateTime }: IBatchInstanceSummaryModel) => endOfExecutionDateTime, columnType: "dateTime"
        },
        {
            name: "Status", title: "Status",
            getCellValue: ({ status }: IBatchInstanceSummaryModel) => (!!status) ? <BatchInstanceStatusBadge status={status as ITasksStatusModel} /> : null,
            // contentRenderer: (v) => (!!v) ? <BatchInstanceStatusBadge status={v as ITasksStatusModel} /> : null,
            // getCellValue: (row) => row.status,
        }
    ];
    const state: IGridState = {
        "Batch": { width: 280 },
        "Version": { width: 120 },
        "StartDateTime": { width: 180, sortingPosition: 1 },
        "EndDateTime": { width: 180 },
        "Status": { width: 180 },
    };
    const handleRowEdit = ({ id }: IBatchInstanceSummaryModel) => navigationNavigate({ sectionKey: "detail", parameters: { id } });
    const getRowKey = (row: IBatchInstanceSummaryModel) => row.id || 0;
    const handleSubmit = (values: IBatchInstancesSearchParameters, { setSubmitting }: FormikHelpers<IBatchInstancesSearchParameters>) => {
        if (!values) {
            const from = today();
            from.setDate(from.getDate() - 1);
            values = { from };
        }
        batchInstanceSearch(values);
        setSubmitting(false);
    }
    const handleValidate = (values: IBatchInstancesSearchParameters) => {
        const errors: IErrors<IBatchInstancesSearchParameters> = {};
        if (values.to && values.from > values.to) {
            errors.to = "Must be greater than the start of period";
        }
        return errors;
    }
    return <Formik onSubmit={handleSubmit} initialValues={initialParams} enableReinitialize={true} validateOnMount={true} validate={handleValidate} >{renderForm}</Formik>;
    function renderForm({ isValid, submitForm }: FormikProps<IBatchInstancesSearchParameters>) {
        return (<SearchPanel
            title={navigationActiveSiteMapNode?.screen?.label ?? "Executions"}
            isQuerying={batchInstancesSearching}
            onSearchClick={submitForm}
            searchDisabled={!isValid}
            renderSearch={(<FieldBox display="flex" flexDirection="column">
                {/* <FieldBox display="flex" flexGrow="1">
                <FormSimpleSelectField name={nameof<IBatchInstancesSearchParameters>("batchId")} label="Batch" options={batchInstanceBatches} />
            </FieldBox> */}
                <FieldBox display="flex" flexDirection="row">
                    <FormDatePickerField name={oProps<IBatchInstancesSearchParameters>().path("from")} label="From" required={true} />
                    <FormDatePickerField name={oProps<IBatchInstancesSearchParameters>().path("to")} label="To" />
                </FieldBox>
            </FieldBox>)}>
            <ExtendedGrid
                rows={batchInstances}
                columns={columns}
                getRowId={getRowKey}
                initialState={state}
                onRowClick={handleRowEdit}
            // userCanGroup={true}
            />
        </SearchPanel>);
    }
}
