import produce from "immer";
import {
    EntitySummaryModel,
    IBatchesRunParameters,
    IBatchModel,
    IBatchSummaryModel,
    IGetBatchesModel,
    IGetBatchModel,
    IMacroScriptCheckResultModel
} from "proxy/apiProxy";
import {AnyActionOf, produceActionFactories} from "tools/lib/store";
import {IMacroScriptMetadata} from "../features/MacroScript/slice";
import {IGetEntitySummary} from "./Reference";

export interface IState {
    batchAllLoading: boolean;
    batchLoading: boolean;
    batches: (IBatchSummaryModel | IBatchModel)[];
    batchCurrent?: IBatchModel;
    batchSaving: boolean;
    batchDeleting: boolean;
    batchScriptSchema?: object;
    batchConditionalScriptCheckResult?: IMacroScriptCheckResultModel;
    batchConditionalScriptMetadata: IMacroScriptMetadata;
    dictionaries: {
        entities: Record<number, EntitySummaryModel>;
    },
    batchExecutionRequested:boolean,
}
export const ActionFactories = produceActionFactories({
    batchStop: (payload: number) => payload,
    batchStopped: (payload: number) => payload,
    batchLoad: (payload: number) => payload,
    batchRequestExecution: (payload: IBatchesRunParameters) => payload,
    batchRequestedExecution: (payload: number) => payload,
    batchCloseExecutionRequested: (payload: number) => payload,
    batchLoaded: (payload: IGetBatchModel) => payload,
    batchSave: (payload: IBatchModel) => payload,
    batchSaved: (payload: IBatchModel) => payload,
    batchDelete: (payload: number) => payload,
    batchDeleted: (payload: number) => payload,
    batchLoadAll: () => undefined,
    batchLoadedAll: (payload: IGetBatchesModel) => payload,
    batchAddEntityInDictionary: (payload: IGetEntitySummary) => payload,
    batchSchemaLoaded: (schema: object) => schema,
    batchConditionalScriptValidate: (payload: string) => payload,
    batchConditionalScriptValidated: (payload: IMacroScriptCheckResultModel) => payload,
    batchConditionalScriptMetadataLoaded: (payload: IMacroScriptMetadata) => payload,
});

export function reducer(
    state: IState = {
        batchLoading: false,
        batchAllLoading: false,
        batchSaving: false,
        batchDeleting: false,
        batches: [],
        dictionaries: {
            entities: {}
        },
        batchConditionalScriptMetadata: {},
        batchExecutionRequested:false,
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "batchConditionalScriptMetadataLoaded":
                draft.batchConditionalScriptMetadata = action.payload;
                break;
            case "batchConditionalScriptValidated":
                draft.batchConditionalScriptCheckResult = action.payload;
                break;
            case "batchLoadAll":
                draft.batchAllLoading = true;
                break;
            case "batchLoadedAll":
                draft.batchAllLoading = false;
                draft.batches = action.payload.batches;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                break;
            case "batchLoad":
                draft.batchLoading = true;
                draft.batchCurrent = undefined;
                draft.batchExecutionRequested = false;
                break;
            case "batchLoaded":
                draft.batchLoading = false;
                draft.batchCurrent = action.payload.batch;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                break;
            case "batchSchemaLoaded":
                draft.batchScriptSchema = action.payload;
                break;
            case "batchSave":
                draft.batchSaving = true;
                break;
            case "batchSaved":
                draft.batchSaving = false;
                const saved = action.payload;
                if (draft.batchCurrent) {
                    draft.batchCurrent = saved;
                }
                const existing = draft.batches.find(i => i.id === saved.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.batches.push(saved);
                }
                break;
            case "batchAddEntityInDictionary":
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.entities[action.payload.entity.id] = action.payload.entity;
                break;
            case "batchDelete":
                draft.batchDeleting = true;
                break;
            case "batchDeleted":
                draft.batchDeleting = false;
                const deletedId = action.payload;
                if (draft.batchCurrent && draft.batchCurrent.id === deletedId) {
                    delete draft.batchCurrent;
                }
                const idx = draft.batches.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.batches.splice(idx, 1);
                }
                break;
            case "batchRequestedExecution":
                draft.batchExecutionRequested = true;
                break;
            case "batchRequestExecution":
                draft.batchExecutionRequested = false;
                break;
            case "batchCloseExecutionRequested":
                draft.batchExecutionRequested = false;
                break;

        }
    });
}
