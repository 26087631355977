import * as React from "react";
import {
    PortfolioModel,
    ICurrencyModel,
    IClassificationTypeModel,
    IFrequencyTypeModel,
    IInvestmentProcessTypeModel, ICountryModel, IStatisticDefinitionSetSummaryModel, IEntityTypeModel, //, IIndexSummaryModel
    IPersonSummaryModel,
    IFeatureModel,
    ISicavSummaryModel,
    SecuritySummaryModel,
    IExtensionFieldModel,
    IWaterfallTypeModel,
    IIndexSummaryModel,
    IDiscretionaryPortfolioModel
} from "proxy/apiProxy";
import FormTextField from "tools/fieldComponents/FormTextField";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import { formatDate, getEnumLabels, IDictionary, oProps } from "tools/lib/utility";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import PortfolioShareClasses from "./PortfolioShareClasses";
import { CardContent, Typography, Card, Box, Chip, Grid } from "@material-ui/core";
import ClassificationsData from "components/global/ClassificationsData";
import FormTimePickerField from "tools/fieldComponents/FormTimePickerField";
import EntitySelectField from "components/searchers/EntitySelectField";
import { IGetEntitySummary, IGetSecuritySummary, IGetSicavSummary } from "reducers/Reference";
import EntitySummary from "components/summaries/EntitySummary";
import * as PortfolioReducer from "features/ManagedPortfolio/slice";
import FormMultiSelectField from "tools/fieldComponents/FormMultiSelectField";
import { CurrencySelectField } from "components/searchers/CurrencySelectField";
import { CountrySelectField } from "components/searchers/CountrySelectField";
import FaceIcon from '@material-ui/icons/Face';
import ManagedSicavSelectField from "components/searchers/ManagedSicavSelectField";
import { useGrants } from "tools/lib/reduxStoreAccess";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import ExtensionFields from "./ExtensionFields";
import { IndexSelectField } from "components/searchers/IndexSelectField";
import { IndexSummary } from "components/summaries/IndexSummary";
import { IFormPortfolioModel, IFormManagedSubFundModel } from "./FormPortfolioModel";
// import PortfolioPeers from "./PortfolioPeers";

export interface IProps {
    referenceCurrencies: IDictionary<ICurrencyModel>;
    referenceCountries: IDictionary<ICountryModel>;
    statisticDefinitionSets: IStatisticDefinitionSetSummaryModel[];
    dictionaries: PortfolioReducer.IState["dictionaries"];
    onSecurityLoaded: (security: IGetSecuritySummary) => void;
    onSecurityClick: (securityId: number) => void;
    onIndexLoaded: (index: IIndexSummaryModel) => void;
    // onIndexClick: (indexId: number) => void;
    onEntityLoaded: (found: IGetEntitySummary) => void;
    classificationTypes: IClassificationTypeModel[];
    currentValues: IFormPortfolioModel;
    canChangeSicav: boolean;
    portfolioExtensionFields: IExtensionFieldModel[];
    subFundExtensionFields: IExtensionFieldModel[];
};

const investmentProcesses = getEnumLabels(IInvestmentProcessTypeModel);

const pricingFrequencies = getEnumLabels(IFrequencyTypeModel);

const waterfallTypeModels = getEnumLabels(IWaterfallTypeModel);

function PortfolioData({ referenceCountries,
    referenceCurrencies,
    dictionaries,
    onSecurityLoaded,
    onEntityLoaded,
    classificationTypes,
    onSecurityClick,
    statisticDefinitionSets,
    currentValues,
    canChangeSicav,
    portfolioExtensionFields,
    subFundExtensionFields,
    onIndexLoaded,
    // onIndexClick
}: IProps) {

    const getStatisticDefinitionValue = (c: IStatisticDefinitionSetSummaryModel) => c.id;
    const getStatisticDefinitionPrimaryContent = (c: IStatisticDefinitionSetSummaryModel) => c.name;
    const fundOfFundModifier = (currentValues.type === "ManagedSubFundModel" && !!currentValues.isFundOfFundModifierId) ? (dictionaries.entities[currentValues.isFundOfFundModifierId] as IPersonSummaryModel) : undefined
    const isGranted = useGrants();
    const isSicavGranted = ({ id: sicavId }: ISicavSummaryModel) => isGranted({ feature: IFeatureModel.ManagedPortfolioWrite, sicavId });
    const isShareClassGranted = (s: SecuritySummaryModel) => s.type === "ShareClassSummaryModel" && (!s.subFundId || isGranted({ feature: IFeatureModel.ManagedPortfolioWrite, portfolioId: s.subFundId }));
    const handleSicavLoaded = ({ entities, sicav }: IGetSicavSummary) => onEntityLoaded({ entities, entity: sicav });
    // const handleSelectedIndex=(found: IIndexSummaryModel)=>onIndexLoaded(found)

    return <Grid container={true} spacing={1}>

        <Grid item={true} xs={2}>
            <FormTextField name={oProps<PortfolioModel>().path("internalCode")} label="Internal Code" required={true} maxLength={100} />
        </Grid>
        <Grid item={true} xs={4}>
            <FormTextField name={oProps<PortfolioModel>().path("name")} label="Name" required={true} maxLength={250} />
        </Grid>
        <Grid item={true} xs={3}>
            <FormTextField name={oProps<PortfolioModel>().path("shortName")} label="Short Name" maxLength={50} />
        </Grid>
        <Grid item={true} xs={2}>
            <CurrencySelectField name={oProps<PortfolioModel>().path("currencyId")} label="Currency" required={true} />
        </Grid>


        <Grid item={true} xs={2}>
            <FormDatePickerField name={oProps<PortfolioModel>().path("inceptionDate")} label="Inception Date" />
        </Grid>
        <Grid item={true} xs={2}>
            <FormSimpleSelectField name={oProps<PortfolioModel>().path("pricingFrequency")} label="Pricing Frequency" options={pricingFrequencies} required={true} />
        </Grid>


        {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={3}>
            <CountrySelectField name={oProps<IFormManagedSubFundModel>().path("domicileId")} label="Domicile" />
        </Grid>}
        {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={3}>
            <CountrySelectField name={oProps<IFormManagedSubFundModel>().path("countryId")} label="Country" />
        </Grid>}

        <Grid item={true} container={true} xs={12} spacing={1}>
            {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={6}>
                <Box>
                    <WriteAccess value={canChangeSicav}>
                        <ManagedSicavSelectField name={oProps<IFormManagedSubFundModel>().path("sicavId")} filter={isSicavGranted} label="Sicav" onSelected={handleSicavLoaded} required={true} />
                        <EntitySummary name={oProps<IFormManagedSubFundModel>().path("sicavId")} entities={dictionaries.entities} countries={referenceCountries} />
                    </WriteAccess>
                </Box>
            </Grid>}
            {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={6}>
                <Box>
                    <EntitySelectField type={IEntityTypeModel.Person} name={oProps<IFormManagedSubFundModel>().path("subscriptionContactId")} label="Subscription Contact" onSelected={onEntityLoaded} />
                    <EntitySummary name={oProps<IFormManagedSubFundModel>().path("subscriptionContactId")} entities={dictionaries.entities} countries={referenceCountries} />
                </Box>
            </Grid>}

        </Grid>


        {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={12}>
            <Card >
                <CardContent>
                    <Typography gutterBottom={true} variant="h5" component="h2">
                        ShareClasses
                    </Typography>
                    <PortfolioShareClasses
                        primaryShareClassIdField={oProps<IFormManagedSubFundModel>().path("primaryShareClassId")}
                        shareClassesIdField={oProps<IFormManagedSubFundModel>().path("shareClassesId")}
                        referenceCurrencies={referenceCurrencies}
                        filter={isShareClassGranted}
                        securities={dictionaries.securities}
                        onShareClassLoaded={onSecurityLoaded}
                        onShareClassClick={onSecurityClick} />
                </CardContent>
            </Card>
        </Grid>}




        <Grid item={true} xs={12}>
            <ClassificationsData
                panelTitle="Classification"
                fieldName={oProps<PortfolioModel>().path("classifications")}
                classificationTypes={classificationTypes} />
        </Grid>

        <ExtensionFields extensionFields={portfolioExtensionFields} pathToExtensionFields={oProps<PortfolioModel>().path("extensionFieldsValues")} xs={3} />
        {currentValues.type === "ManagedSubFundModel" && <ExtensionFields extensionFields={subFundExtensionFields} pathToExtensionFields={oProps<IFormManagedSubFundModel>().path("subFundExtensionFieldsValues")} />}

        <Grid item={true} xs={3}>
            <FormMultiSelectField name={oProps<PortfolioModel>().path("statisticDefinitionSets")} label="Statistics to compute backtesting" options={statisticDefinitionSets} getValue={getStatisticDefinitionValue} getPrimaryContent={getStatisticDefinitionPrimaryContent} />
        </Grid>
        <Grid item={true} xs={3}>
            <FormMultiSelectField name={oProps<PortfolioModel>().path("dailyStatisticDefinitionSets")} label="Statistics to compute daily" options={statisticDefinitionSets} getValue={getStatisticDefinitionValue} getPrimaryContent={getStatisticDefinitionPrimaryContent} />
        </Grid>

        {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={3}>
            <FormSimpleSelectField name={oProps<IFormManagedSubFundModel>().path("investmentProcess")} label="Investment Process" options={investmentProcesses} />
        </Grid>}
        {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={3}>
            <FormTextField name={oProps<IFormManagedSubFundModel>().path("recommendedTimeHorizon")} label="Recommended Time Horizon" isNumber={true} />
        </Grid>}
        {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={3}>
            <FormTextField name={oProps<IFormManagedSubFundModel>().path("settlementNbDays")} label="Settlement Nb Days" isNumber={true} />
        </Grid>}

        {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={3}><Box>
            {fundOfFundModifier && <Chip icon={<FaceIcon />} label={`"Is Fund Of Fund" modified by ${fundOfFundModifier.firstName} ${fundOfFundModifier.lastName} on ${formatDate(currentValues.type === "ManagedSubFundModel" ? currentValues.isFundOfFundModifiedDateTime : undefined)}`} />}
            <FormCheckBoxField name={oProps<IFormManagedSubFundModel>().path("isFundOfFunds")} label="Is Fund Of Funds" canBeUndefined={true} />
        </Box>
        </Grid>}


        {currentValues.type === "DiscretionaryPortfolioModel" && <Grid item={true} xs={3}>
            <FormTextField name={oProps<IDiscretionaryPortfolioModel>().path("managementFee")} label="Management Fee" isPercentage={true} />
        </Grid>}
        {currentValues.type === "DiscretionaryPortfolioModel" && <Grid item={true} xs={3}>
            <FormTextField name={oProps<IDiscretionaryPortfolioModel>().path("performanceFee")} label="Performance Fee" isPercentage={true} />
        </Grid>}


        {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={3}>
            <FormTimePickerField name={oProps<IFormManagedSubFundModel>().path("cutOffTime")} label="Cut Off Time" />
        </Grid>}
        {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={3}>
            <FormCheckBoxField name={oProps<IFormManagedSubFundModel>().path("shortExposure")} label="Short Exposure" />
        </Grid>}
        {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={3}>
            <FormCheckBoxField name={oProps<IFormManagedSubFundModel>().path("leverage")} label="Leverage" />
        </Grid>}
        {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={3}>
            <FormCheckBoxField name={oProps<IFormManagedSubFundModel>().path("closedEnded")} label="Closed Ended" />
        </Grid>}


        {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={3}>
            <FormCheckBoxField name={oProps<IFormManagedSubFundModel>().path("inLiquidation")} label="In liquidation" />
        </Grid>}
        {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={3}>
            <FormDatePickerField name={oProps<IFormManagedSubFundModel>().path("liquidationDate")} label="Liquidation Date" />
        </Grid>}
        {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={3}>
            <FormDatePickerField name={oProps<IFormManagedSubFundModel>().path("limitedDurationEndDate")} label="Limited Duration End Date" />
        </Grid>}
        {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={3}>
            <FormDatePickerField name={oProps<IFormManagedSubFundModel>().path("commitmentPeriodEndDate")} label="Commitment Period End Date" />
        </Grid>}
        {currentValues.type === "ManagedSubFundModel" && <Grid item={true} xs={6}>
            <FormTextField name={oProps<IFormManagedSubFundModel>().path("url")} label="Url" maxLength={500} />
        </Grid>}
        <Grid item={true} xs={3}>
            <FormTextField name={oProps<PortfolioModel>().path("minCommitmentPerInvestor")} label="Min Commitment Per Investor" isNumber={true} />
        </Grid>
        <Grid item={true} xs={3}>
            <FormTextField name={oProps<PortfolioModel>().path("maxTotalCommitmentWeightPerInvestor")} label="MaxTotal Commitment Weight Per Investor" isNumber={true} />
        </Grid>
        <Grid item={true} xs={3}>
            <FormTextField name={oProps<PortfolioModel>().path("minSize")} label="Min Size" isNumber={true} />
        </Grid>
        <Grid item={true} xs={3}>
            <FormTextField name={oProps<PortfolioModel>().path("maxSize")} label="Max Size" isNumber={true} />
        </Grid>
        <Grid item={true} xs={3}>
            <FormTextField name={oProps<PortfolioModel>().path("actualizationInterestRateOverRiskFree")} label="Actualization Interest Rate Over Risk Free" isNumber={true} />
        </Grid>
        <Grid item={true} container={true} xs={12} spacing={1}>
            <Grid item={true} xs={6}>
                <IndexSelectField name={oProps<PortfolioModel>().path("actualizationRiskFreeIndexId")} label="Actualization Risk Free Index" onSelected={onIndexLoaded} />
                {(!!currentValues.actualizationRiskFreeIndexId && !!dictionaries.indexes[currentValues.actualizationRiskFreeIndexId]) && <IndexSummary name={oProps<PortfolioModel>().path("actualizationRiskFreeIndexId")} label="Actualization Risk Free Index" indexes={dictionaries.indexes} countries={referenceCountries} currencies={referenceCurrencies} />}
            </Grid>
            <Grid item={true} xs={6}>
                <IndexSelectField name={oProps<PortfolioModel>().path("defaultInterestRiskFreeIndexId")} label="Default Interest Risk Free Index" onSelected={onIndexLoaded} />
                {(!!currentValues.defaultInterestRiskFreeIndexId && !!dictionaries.indexes[currentValues.defaultInterestRiskFreeIndexId]) && <IndexSummary name={oProps<PortfolioModel>().path("actualizationRiskFreeIndexId")} label="Actualization Risk Free Index" indexes={dictionaries.indexes} countries={referenceCountries} currencies={referenceCurrencies} />}
            </Grid>
        </Grid>
        <Grid item={true} xs={3}>
            <FormTextField name={oProps<PortfolioModel>().path("defaultInterestRateOverRiskFree")} label="Default Interest Rate Over Risk Free" isNumber={true} />
        </Grid>
        <Grid item={true} xs={3}>
            <FormSimpleSelectField name={oProps<PortfolioModel>().path("waterfallType")} options={waterfallTypeModels} label="Waterfall Type" />
        </Grid>
        <Grid item={true} xs={3}>
            <FormTextField name={oProps<PortfolioModel>().path("waterfallPreferredReturn")} label="Waterfall Preferred Return" isNumber={true} />
        </Grid>
        <Grid item={true} xs={3}>
            <FormCheckBoxField name={oProps<PortfolioModel>().path("clawbackClause")} label="Clawback Clause" />
        </Grid>
        <Grid item={true} xs={3}>
            <FormCheckBoxField name={oProps<PortfolioModel>().path("mayUseDerivativeInstruments")} label="May Use Derivative Instruments" />
        </Grid>
        <Grid item={true} xs={3}>
            <FormCheckBoxField name={oProps<PortfolioModel>().path("mayUseEfficientPortfolioManagementTechniques")} label="May Use Efficient Portfolio Management Techniques" />
        </Grid>
        <Grid item={true} xs={3}>
            <FormCheckBoxField name={oProps<PortfolioModel>().path("mayUseBorrowings")} label="May Use Borrowings" />
        </Grid>
        <Grid item={true} xs={3}>
            <FormTextField name={oProps<PortfolioModel>().path("leverageLevel")} label="Leverage Level" isNumber={true} />
        </Grid>
        <Grid item={true} xs={3}>
            <FormTextField name={oProps<PortfolioModel>().path("legalReserve")} label="Legal Reserve" isNumber={true} />
        </Grid>
        <Grid item={true} xs={3}>
            <FormTextField name={oProps<PortfolioModel>().path("initialOneOffManagementFee")} label="Initial One Off Management Fee" isNumber={true} />
        </Grid>
        <Grid item={true} xs={3}>
            <FormTextField name={oProps<PortfolioModel>().path("administrationFee")} label="Administration Fee" isPercentage={true} />
        </Grid>
        <Grid item={true} xs={3}>
            <FormTextField name={oProps<PortfolioModel>().path("depositaryFee")} label="Depositary Fee" isPercentage={true} />
        </Grid>
        <Grid item={true} xs={3}>
            <FormTextField name={oProps<PortfolioModel>().path("auditFee")} label="Audit Fee" isPercentage={true} />
        </Grid>
        <Grid item={true} xs={3}>
            <FormTextField name={oProps<PortfolioModel>().path("advisoryFee")} label="Advisory Fee" isPercentage={true} />
        </Grid>
        <Grid item={true} xs={3}>
            <FormTextField name={oProps<PortfolioModel>().path("maxCommitmentPerInvestor")} label="Max Commitment Per Investor" isNumber={true} />
        </Grid>
    </Grid>
}

export default React.memo(PortfolioData);



// List < CarriedInterestModel > WaterfallHurdles
