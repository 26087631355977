import * as React from "react";
import { MouseEventHandler, ResponsivePie } from '@nivo/pie'
import { ValueFormat } from "@nivo/core";

export interface ISimplePieChartArgs<T> {
    rows: T[];
    getValue: (row: T) => number;
    getArgument: (row: T) => string | undefined;
    getSliceLabel?: (value: number) => string;
    onRowsClick?: (rows: T[]) => void;
}
interface IGroupedValues<T> {
    value: number;
    group: string;
    items: T[];
}

function computeData<T>({ getValue, getArgument, rows }: ISimplePieChartArgs<T>) {
    const values = rows.reduce((acc, v) => {
        const argument = getArgument(v);
        if (!argument) {
            return acc;
        }
        if (!acc[argument]) {
            acc[argument] = {
                value: getValue(v),
                group: argument,
                items: [v]
            };
        }
        else {
            acc[argument].value += getValue(v);
            acc[argument].items.push(v);
        }
        return acc;
    }, {} as Record<string, IGroupedValues<T>>);
    return values;
}
export default function SimplePieChart<T>({ getValue, getArgument, rows, getSliceLabel, onRowsClick }: ISimplePieChartArgs<T>) {
    const valueFormat: ValueFormat<number> = React.useCallback(v => getSliceLabel ? getSliceLabel(v) : String(v), [getSliceLabel]);
    const chartData = React.useMemo(() => {
        const values = computeData({ getValue, getArgument, rows });
        return Object.values(values);
    }, [getArgument, getValue, rows]);
    const handleOnClick: MouseEventHandler<IGroupedValues<T>, SVGPathElement> = React.useCallback((i, j) => onRowsClick && onRowsClick(i.data.items), [onRowsClick]);
    const getRadialId = React.useCallback(({ group }: IGroupedValues<T>) => group, []);
    return <ResponsivePie<IGroupedValues<T>>
        data={chartData}
        onClick={handleOnClick}
        margin={{ top: 16, right: 60, bottom: 16, left: 60 }}
        fit={true}
        sortByValue={true}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={{ scheme: "nivo" }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsDiagonalLength={5}
        arcLinkLabelsStraightLength={5}
        arcLinkLabelsTextOffset={6}
        arcLinkLabelsTextColor="#333333"
        arcLabelsSkipAngle={10}
        valueFormat={valueFormat}
        id={getRadialId} />;
}
