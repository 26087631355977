import {IRelatedFileModel} from "proxy/apiProxy";
import {IBase64File, toDictionary} from "tools/lib/utility";

export interface IRelatedFileWithContent extends IRelatedFileModel {
    content?: IBase64File;
    toDelete?: true;
    contentToDelete?: true;
}


export function setFilesToDelete(
    values: { files: IRelatedFileWithContent[] },
    formValues: { files: IRelatedFileWithContent[] }) {
    const initialFiles = toDictionary(formValues.files, i => i.id);
    const newFiles = toDictionary(values.files.filter(i => !!i.id), i => i.id);
    for (const file of values.files.filter(i => !!i.id)) {
        if (initialFiles[file.id].fileName && !file.fileName) {
            file.contentToDelete = true;
        }
    }
    const files: IRelatedFileWithContent[] = [...values.files];
    for (const file of formValues.files.filter(i => !!i.id)) {
        if (!newFiles[file.id]) {
            files.push({...file, toDelete: true});
        }
    }
    values.files = files;
}