import { IBatchInstanceModel, IBatchSummaryModel, ITaskModel, ITasksStatusModel } from "proxy/apiProxy";
import { Card, CardContent, Typography, CardActions, Button, Grid } from "@material-ui/core";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import cronstrue from 'cronstrue';
import { formatDateTime } from "tools/lib/utility";
import React from "react";
import { SummaryField } from "components/global/SummaryField";
import { BatchInstanceStatusBadge } from "components/batch/BatchInstanceStatusBadge";

export interface IBatchInstanceDataProps {
    batchInstance: IBatchInstanceModel;
    onBatchClick?: () => void;
}
const statuses = {
    [ITasksStatusModel.Awaiting]: 1,
    [ITasksStatusModel.Processing]: 2,
    [ITasksStatusModel.Error]: 4,
    [ITasksStatusModel.Success]: 0,
    [ITasksStatusModel.InitializationError]: 5,
    [ITasksStatusModel.Cancelled]: 3,
}
export default function BatchInstanceData({ onBatchClick, batchInstance: { batch, executionDateTime, comment, asOfDate, executedVersionNumber, tasks } }: IBatchInstanceDataProps) {
    const task = React.useMemo(() => tasks.reduce((a, v) => (!a || statuses[v.status] > statuses[a.status]) ? v : a, null as ITaskModel | null), [tasks]);
    return <>
        <Grid container spacing={1}>
            <Grid item xs={4}><SummaryField label="Execution date" value={formatDateTime(executionDateTime)} /></Grid>
            <Grid item xs={4}><SummaryField label="As of Date" value={formatDateTime(asOfDate)} /></Grid>
            <Grid item xs={4}><SummaryField label="Comment" value={comment} /></Grid>
        </Grid>
        {task && <Grid item={true} xs={12} style={{ marginTop: 16 }}><TaskError task={task} /></Grid>}
        {batch && <Grid item={true} xs={12} style={{ marginTop: 16 }}><BatchCard batch={batch} executedVersion={executedVersionNumber} onBatchClick={onBatchClick} /></Grid>}
    </>
}
interface ITaskErrorProps {
    task: ITaskModel;
}
function TaskError({ task: { name, result, status } }: ITaskErrorProps) {
    if (!result) {
        return null;
    }
    if (status === ITasksStatusModel.Cancelled) {
        return <Typography style={{ color: "orange" }}>Job cancelled at task {name}.</Typography>
    }
    if (status !== ITasksStatusModel.Error && status !== ITasksStatusModel.InitializationError) {
        return null;
    }
    const { initializationError, error } = result;
    return <Card >
        <CardContent>
            <Grid container={true} spacing={1}>
                <Grid item={true} xs={4}>
                    <BatchInstanceStatusBadge status={status} />
                </Grid>
                <Grid item={true} xs={4}>
                    <SummaryField label="Task" value={name} />
                </Grid>
                {!!(error?.nodeName) && <Grid item={true} xs={4}>
                    <SummaryField label="Node" value={error.nodeName} />
                </Grid>}
            </Grid>
            {!!initializationError && <pre>{initializationError}</pre>}

            {!!(error?.text) && <pre>{error.text}</pre>}
        </CardContent>
    </Card >
}

interface IBatchCardProps {
    batch: IBatchSummaryModel;
    executedVersion: number | undefined;
    onBatchClick?: () => void;
}
function BatchCard({ batch: { code, name, updatedOn, cron }, executedVersion, onBatchClick }: IBatchCardProps) {
    return <Card >
        <CardContent>
            <Typography gutterBottom={true} variant="h5" component="h2">Batch</Typography>
            <Grid container={true} spacing={1}>
                <Grid item={true} xs={4}><SummaryField label="Code" value={code} /></Grid>
                <Grid item={true} xs={4}><SummaryField label="Name" value={name} /></Grid>
                {(!!executedVersion) && <Grid item={true} xs={4}><SummaryField label="Version" value={executedVersion} /></Grid>}
                {(!!cron) && <Grid item={true} xs={4}><SummaryField label="Scheduled at" value={cronstrue.toString(cron, { throwExceptionOnParseError: false, use24HourTimeFormat: true })} /></Grid>}
                {!!updatedOn && <Grid item={true} xs={4}><SummaryField label="Updated on" value={formatDateTime(updatedOn)} /></Grid>}
            </Grid>
        </CardContent>
        {
            onBatchClick && <CardActions>
                <Button size="small" onClick={onBatchClick}>
                    <OpenInBrowser />
                    Open
                </Button>
            </CardActions>
        }
    </Card >
}
