import produce from "immer";
import { IBatchInstanceModel, IGetBatchInstancesModel, IBatchSummaryModel, IBatchInstanceSummaryModel, IGetBatchesModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IBatchInstancesGetTracedRowsParameters, IBatchInstancesSearchParameters } from "proxy/apiProxy";
export interface IState {
    batchInstancesSearching: boolean;
    batchInstanceLoading: boolean;
    batchInstances: IBatchInstanceSummaryModel[];
    dictionaries: {
        batches: Record<string | number, IBatchSummaryModel>;
    }
    batchInstanceCurrent?: IBatchInstanceModel;
    batchInstanceCurrentTaskId?: number;
    batchInstanceTaskNodeTracesLoading?: string;
}
export const ActionFactories = produceActionFactories({
    batchInstanceLoad: (payload: number) => payload,
    batchInstanceLoaded: (payload: IBatchInstanceModel) => payload,
    batchInstanceSearch: (payload: IBatchInstancesSearchParameters) => payload,
    batchInstancesFound: (payload: IGetBatchInstancesModel) => payload,
    batchInstanceSetCurrentTask: (payload: number) => payload,
    batchInstanceTaskNodeTracesLoad: (payload: IBatchInstancesGetTracedRowsParameters) => payload,
    batchInstanceTaskNodeTracesLoaded: () => undefined,
    batchInstanceSetBatches: (payload: IGetBatchesModel) => payload
});

export function reducer(
    state: IState = {
        batchInstancesSearching: false,
        batchInstanceLoading: false,
        batchInstances: [],
        dictionaries: {
            batches: {}
        }
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "batchInstanceTaskNodeTracesLoad":
                draft.batchInstanceTaskNodeTracesLoading = action.payload.nodeName;
                break;
            case "batchInstanceTaskNodeTracesLoaded":
                delete draft.batchInstanceTaskNodeTracesLoading;
                break;
            case "batchInstanceSearch":
                draft.batchInstancesSearching = true;
                break;
            case "batchInstancesFound":
                draft.batchInstancesSearching = false;
                const { batchInstances, ...dictionaries } = action.payload;
                draft.batchInstances = batchInstances;
                draft.dictionaries = { ...draft.dictionaries, ...dictionaries };
                break;
            case "batchInstanceLoad":
                draft.batchInstanceLoading = true;
                draft.batchInstanceCurrent = undefined;
                break;
            case "batchInstanceLoaded":
                draft.batchInstanceCurrentTaskId = undefined;
                draft.batchInstanceLoading = false;
                draft.batchInstanceCurrent = action.payload;
                break;
            case "batchInstanceSetCurrentTask":
                draft.batchInstanceCurrentTaskId = action.payload;
                break;
        }
    });
}
