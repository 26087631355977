import * as React from "react";
import FieldBox from "tools/components/FieldBox";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import {
    IOversightLevelModel, IProcessTaskStatusModel,
    ProcessExecutionTaskModel, RelationshipSummaryModel,
    EntitySummaryModel, IDocumentProcessExecutionTaskModel, IClassificationTypeModel, //, ISimpleProcessExecutionTaskModel
    IQuestionnaireDevelopmentItemSummaryModel,
    IQuestionnaireProcessExecutionTaskModel,
    IQuestionnaireTaskStateModel,
    ProcessExecutionModel,
    IProcessExecutionsGetProcessExistingExecutionQuestionnaireParameters,
    IProcessDefinitionSummaryModel,
    ProcessDefinitionTaskModel,
    PortfolioSummaryModel,
    SecuritySummaryModel, IRiskLevelModel
} from "proxy/apiProxy";
import { oProps, base64toBlob, getEnumLabels, addYears, today, formatDateTime, LocaleDateFormat } from "tools/lib/utility";
import { format } from "date-fns"
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import {
    Accordion, AccordionSummary, AccordionDetails, Typography, Box, Tooltip,
    makeStyles, Chip, Avatar, ChipTypeMap, Badge, LinearProgress, IconButton, Grid, Button
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FormTextField from "tools/fieldComponents/FormTextField";
import FormDropZoneField from "tools/fieldComponents/FormDropZoneField";
import AttachmentIcon from '@material-ui/icons/Attachment';
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import { RelationshipSummaryReadOnly } from "components/summaries/RelationshipSummary";
import { IGetRelationshipSummary } from "reducers/Reference";
import CertificateOutlineIcon from "mdi-material-ui/CertificateOutline";
import FileDocumentEditOutlineIcon from "mdi-material-ui/FileDocumentEditOutline";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import saveAs from "file-saver";
import DialogPanel from "tools/components/DialogPanel";
import { FieldArray, FieldArrayRenderProps, Formik, FormikProps, useField, useFormikContext } from "formik";
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import FormMultiCulturedTextField from "tools/fieldComponents/FormMultiCulturedTextField";
import { MouseEventHandler, useCallback } from "react";
import {
    IProcessExecutionQuestionnaireLoadPayload,
    IProcessExecutionSavePayload,
    IProcessExecutionTaskFile,
    IQuestionnaireResponse
} from "./slice";
import ClassificationsData from "components/global/ClassificationsData";
import CircularProgress from '@material-ui/core/CircularProgress';
import { SummaryField } from "components/global/SummaryField";
import { getEntityName } from "tools/lib/modelUtils";
import DoneIcon from '@material-ui/icons/Done';
import { ProcessDefinitionLookups } from "features/ProcessDefinitionLookup/ProcessDefinitionLookups";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { getProcessExecutionTypeLabel } from "./getProcessExecutionTypeLabel";
import { EntitySummaryReadOnly } from "components/summaries/EntitySummary";
import { useReduxSelections } from "tools/lib/reduxStoreAccess";
import { ManagedPortfolioSummaryReadOnly } from "components/summaries/ManagedPortfolioSummary";
import { SecuritySummaryReadOnly } from "components/summaries/SecuritySummary";
import ProcessResponsibles from "features/ProcessExecution/ProcessResponsibles"
import SurveyRunnerDialog from "tools/components/SurveyRunnerComponent";
import { DashboardRunner } from "features/DevelopmentItem/Designers/Dashboard/DashboardRunner";
import { Visibility } from "@material-ui/icons"
import UpIcon from "@material-ui/icons/ArrowUpward"
import DownIcon from "@material-ui/icons/ArrowDownward"
import clsx from "clsx"

export function renewProcess(currentExecution: ProcessExecutionModel): ProcessExecutionModel | undefined {
    const tasks: ProcessExecutionModel["tasks"] = [];
    for (const key in currentExecution.tasks) {
        if (Object.prototype.hasOwnProperty.call(currentExecution.tasks, key)) {
            const currentTask = { ...currentExecution.tasks[key] };
            currentTask.comment = undefined;
            currentTask.status = IProcessTaskStatusModel.Open;
            tasks[key] = currentTask;
            switch (currentTask.type) {
                case "DocumentProcessExecutionTaskModel":
                    currentTask.fileSubmissionDate = undefined;
                    currentTask.endValidity = undefined;
                    currentTask.fileName = undefined;
                    currentTask.fileMimeType = undefined;
                    break;
                case "QuestionnaireProcessExecutionTaskModel":
                    currentTask.filledAt = undefined;
                    currentTask.filledById = undefined;
                    // currentTask.responses = undefined;
                    currentTask.result = undefined;
                    break;
            }
        }
    }
    return {
        ...currentExecution,
        id: 0,
        oversightLevel: IOversightLevelModel.Normal,
        startValidity: today(),
        endValidity: addYears(today(), 1),
        onSite: false,
        classifications: { ...currentExecution.classifications },
        tasks
    } as ProcessExecutionModel;
}

export const roleRelationshipOversightLevel = getEnumLabels(IOversightLevelModel);
export const roleRelationshipRiskLevel = getEnumLabels(IRiskLevelModel);
export const processTaskStatuses = getEnumLabels(IProcessTaskStatusModel);

type EditType = "Edit" | "Create";


function getLowerStatus(statuses: IProcessTaskStatusModel[]) {
    const processTaskStatusesLevel = {
        [IProcessTaskStatusModel.Refused]: 5,
        [IProcessTaskStatusModel.Open]: 4,
        [IProcessTaskStatusModel.InProgress]: 3,
        [IProcessTaskStatusModel.Waiting]: 2,
        [IProcessTaskStatusModel.Done]: 1,
        [IProcessTaskStatusModel.NotApplicable]: 0,
        [IProcessTaskStatusModel.NotAvailable]: 0,
    }
    let lowerStatus = IProcessTaskStatusModel.NotApplicable;
    for (const status of statuses) {
        if (processTaskStatusesLevel[status] > processTaskStatusesLevel[lowerStatus]) {
            lowerStatus = status;
        }
    }
    return lowerStatus;
}

function getProcessStatusColor(status: IProcessTaskStatusModel) {
    switch (status) {
        case IProcessTaskStatusModel.Refused: return "#cc0000";
        case IProcessTaskStatusModel.Open: return "#D85532";
        case IProcessTaskStatusModel.InProgress: return "#E88A12";
        case IProcessTaskStatusModel.Waiting: return "#8CBDFE";
        case IProcessTaskStatusModel.Done: return "#7CC488";
        case IProcessTaskStatusModel.NotApplicable: return;
        case IProcessTaskStatusModel.NotAvailable: return;
    }
}
function ProcessStatusBadge({ status, size }: { status: IProcessTaskStatusModel } & Pick<ChipTypeMap["props"], "size">) {
    const backgroundColor = getProcessStatusColor(status);
    const statusLabel = processTaskStatuses[status];
    if (!statusLabel) {
        return <div>Problem!!!</div>;
    }
    return <Chip size={size} variant={(!backgroundColor) ? "outlined" : undefined} style={{ backgroundColor }} avatar={<Avatar>{statusLabel[0]}</Avatar>} label={statusLabel} />;
}

const useStyles = makeStyles(() => ({
    statusHeading: {
        flexBasis: '20%',
        flexShrink: 0,
    },
    heading: {
        fontWeight: "bolder"
    },
    hiddenButton: {
        visibility: "hidden"
    },
}));

export interface IProcessExecutionDataProps {
    processExecution: ProcessExecutionModel;
    processFiles: {
        [processTaskCode: string]: IProcessExecutionTaskFile | null
    };
    dictionaries: {
        relationships: Record<number, RelationshipSummaryModel>;
        entities: Record<number, EntitySummaryModel>;
    };
    onRelationshipLoaded: (roleSummary: IGetRelationshipSummary) => void;
    onRequestFileLoad: (processTaskCode: string) => void;
    onLoadQuestionnaire: (payload: IProcessExecutionQuestionnaireLoadPayload) => void;
    taskSaving: Record<string, boolean | undefined>;
    taskLoading: Record<string, boolean | undefined>;
    classificationTypes: IClassificationTypeModel[];
    questionnaires: IQuestionnaireDevelopmentItemSummaryModel[];
    questionnaireContents: Record<string, IQuestionnaireTaskStateModel>;
    onReloadQuestionnaire: (payload: Omit<IProcessExecutionsGetProcessExistingExecutionQuestionnaireParameters, "getPreviousAnswers">) => void;
}

const typeMapping: Record<ProcessDefinitionTaskModel["type"], ProcessExecutionTaskModel["type"]> = {
    "DocumentProcessDefinitionTaskModel": "DocumentProcessExecutionTaskModel",
    "QuestionnaireProcessDefinitionTaskModel": "QuestionnaireProcessExecutionTaskModel",
    "SimpleProcessDefinitionTaskModel": "SimpleProcessExecutionTaskModel"
};
export default function ProcessExecutionData(props: IProcessExecutionDataProps) {
    const {
        processExecution,
        dictionaries,
        onRelationshipLoaded,
        classificationTypes,
        onLoadQuestionnaire
    } = props;
    const {approvedById, approvedAt, updatedById, updatedAt, createdAt} = processExecution;
    const getEntityName = React.useCallback((entityId: number) => {
        const entity = dictionaries.entities[entityId];
        if (!entity) {
            return;
        }
        if (entity.type === "PersonSummaryModel") {
            return `${entity.firstName} ${entity.lastName}`;
        }
        return entity.name;
    }, [dictionaries.entities]);
    const updatedByEntityName = updatedById ? getEntityName(updatedById) : undefined
    const entityId = processExecution.type === "EntityProcessExecutionModel" ? processExecution.entityId : undefined;
    const relationshipId = processExecution.type === "RelationshipProcessExecutionModel" ? processExecution.relationshipId : undefined;
    const portfolioId = processExecution.type === "PortfolioProcessExecutionModel" ? processExecution.portfolioId : undefined;
    const securityId = processExecution.type === "SecurityProcessExecutionModel" ? processExecution.securityId : undefined;
    const processExecutionId = processExecution.id;

    const handleLoadQuestionnaire = React.useCallback((questionnaireId: number, taskCode: string) => void onLoadQuestionnaire({
        questionnaireId,
        processExecutionTaskCode: taskCode,
        entityId,
        portfolioId,
        relationshipId,
        securityId,
        processExecutionId,
    }), [entityId, onLoadQuestionnaire, portfolioId, processExecutionId, relationshipId, securityId]);

    return <FieldBox display="flex" flexDirection="row">
        <FieldBox display="flex" flexDirection="column">
            {!!approvedById && <Box><Chip
                label={`Approved by ${getEntityName(approvedById)}${!!approvedAt ? format(approvedAt, " 'the' " + LocaleDateFormat + " 'at' HH:mm") : ""}`}
                icon={<CertificateOutlineIcon />} /></Box>}
            <RelatedItem dictionaries={dictionaries} processExecution={processExecution} />
            <ProcessResponsibles
                field={oProps<IProcessExecutionSavePayload>().path("execution", "responsibles")}
                dictionaries={dictionaries}
                onRelationshipLoaded={onRelationshipLoaded} />
            <ClassificationsData
                panelTitle="Classification"
                fieldName={oProps<IProcessExecutionSavePayload>().path("execution", "classifications")}
                classificationTypes={classificationTypes} />
            <FormCheckBoxField name={oProps<IProcessExecutionSavePayload>().path("execution", "initial")} label="Initial" />
            <FormDatePickerField name={oProps<IProcessExecutionSavePayload>().path("execution", "startValidity")} label="Validity start" required={true} />
            <FormDatePickerField name={oProps<IProcessExecutionSavePayload>().path("execution", "endValidity")} label="Expiration" required={true} />
            <FormSimpleSelectField name={oProps<IProcessExecutionSavePayload>().path("execution", "oversightLevel")} label="Oversight Level" options={roleRelationshipOversightLevel} required />
            <FormSimpleSelectField name={oProps<IProcessExecutionSavePayload>().path("execution", "riskAssessment")} label="Risk Assessment" options={roleRelationshipRiskLevel} />
            <FormCheckBoxField name={oProps<IProcessExecutionSavePayload>().path("execution", "onSite")} label="On Site" />
            <Grid container spacing={1}>
                {createdAt && <Grid item xs={12} md={6}>
                    <SummaryField label="Created at" value={formatDateTime(createdAt)}/>
                </Grid>}
            </Grid>
            <Grid container spacing={1}>
                {updatedAt && <Grid item xs={12} md={6}>
                    <SummaryField label="Updated at" value={formatDateTime(updatedAt)}/>
                </Grid>}
                {updatedByEntityName && <Grid item xs={12} md={6}>
                    <SummaryField label="Updated by" value={updatedByEntityName}/>
                </Grid>}
            </Grid>
        </FieldBox>
        <FieldArray name={oProps<IProcessExecutionSavePayload>().path("execution", "tasks")}>
            {fieldArrayRenderProps => <TasksEditor fieldArrayRenderProps={fieldArrayRenderProps} {...props} onLoadQuestionnaire={handleLoadQuestionnaire} />}
        </FieldArray>
    </FieldBox>
}

interface IRelatedItemProps {
    processExecution: ProcessExecutionModel;
    dictionaries: {
        entities: Record<EntitySummaryModel["id"], EntitySummaryModel>;
        relationships: Record<string | number, RelationshipSummaryModel>;
        portfolios?: Record<string | number, PortfolioSummaryModel>;
        securities?: Record<string | number, SecuritySummaryModel>;
    };

}

function RelatedItem({ processExecution, dictionaries }: IRelatedItemProps) {
    const typeLabel = React.useMemo(() => `Target type: ${getProcessExecutionTypeLabel(processExecution, dictionaries)}`, [dictionaries, processExecution]);
    const { referenceCountries, referenceCurrencies = {} } = useReduxSelections("reference");
    switch (processExecution.type) {
        case "EntityProcessExecutionModel":
            return <EntitySummaryReadOnly label={typeLabel} entityId={processExecution.entityId} entities={dictionaries.entities} countries={referenceCountries} />
        case "PortfolioProcessExecutionModel":
            return <ManagedPortfolioSummaryReadOnly label={typeLabel} portfolioId={processExecution.portfolioId} currencies={referenceCurrencies} portfolios={dictionaries.portfolios ?? {}} />
        case "RelationshipProcessExecutionModel":
            return <RelationshipSummaryReadOnly label={typeLabel} relationshipId={processExecution.relationshipId} entities={dictionaries.entities} relationships={dictionaries.relationships} />
        case "SecurityProcessExecutionModel":
            return <SecuritySummaryReadOnly label={typeLabel} securityId={processExecution.securityId} currencies={referenceCurrencies} securities={dictionaries.securities} />
    }
}

interface ITasksEditorProps extends Omit<IProcessExecutionDataProps, "onLoadQuestionnaire"> {
    fieldArrayRenderProps: FieldArrayRenderProps;
    onLoadQuestionnaire: (questionnaireId: number, taskCode: string) => void;
}

function TasksEditor({
                         processFiles,
                         onRequestFileLoad,
                         processExecution: { tasks, id: processExecutionId },
                         onLoadQuestionnaire,
                         onReloadQuestionnaire,
                         taskSaving,
                         taskLoading,
                         questionnaireContents,
                         questionnaires,
                         dictionaries,
                         fieldArrayRenderProps: { push, remove, replace, swap }
                     }: ITasksEditorProps) {
    const handleViewFile = React.useCallback((processTaskCode: string) => {
        const file = processFiles?.[processTaskCode];
        if (!file) {
            onRequestFileLoad(processTaskCode);
        } else {
            const blob = base64toBlob(file.content, file.mimeType);
            saveAs(blob, file.fileName);
        }
    }, [processFiles, onRequestFileLoad]);
    const handleCancelCreateFromProcessDefinition = React.useCallback(() => {
        setCreatingTasksFromProcessDefinition(false);
    }, []);
    const [creatingTask, setCreatingTask] = React.useState<ProcessExecutionTaskModel | undefined>();
    const [{ value: processClassifications }, , { setValue: setProcessClassifications }] = useField(oProps<IProcessExecutionSavePayload>().path("execution", "classifications"));// React.useState<ProcessExecutionTaskModel | undefined>();
    const [creatingTasksFromProcessDefinition, setCreatingTasksFromProcessDefinition] = React.useState<boolean>(false);
    const handleCreateCancel = React.useCallback(() => void setCreatingTask(undefined), []);
    const handleCreateFromProcessDefinitionValidate = React.useCallback(({
                                                                             tasks: taskDefinitions,
                                                                             classifications
                                                                         }: IProcessDefinitionSummaryModel) => {
        for (const { defaultValidityPeriod, type: taskDefinitionType, ...taskDefinition } of taskDefinitions) {
            if (tasks.findIndex(i => i.code === taskDefinition.code) < 0) {
                push({
                    ...taskDefinition,
                    status: IProcessTaskStatusModel.Open,
                    type: typeMapping[taskDefinitionType]
                } as ProcessExecutionTaskModel);
            }
        }
        setCreatingTasksFromProcessDefinition(false);
        setProcessClassifications({ ...classifications, ...processClassifications });
    }, [processClassifications, push, setProcessClassifications, tasks])
    const handleCreate: MouseEventHandler<HTMLButtonElement> = React.useCallback(e => {
        e.stopPropagation();
        setCreatingTask({ isMandatory: true } as ProcessExecutionTaskModel);
    }, []);
    const handleCreateFromProcessDefinition: MouseEventHandler<HTMLButtonElement> = React.useCallback(e => {
        e.stopPropagation();
        setCreatingTasksFromProcessDefinition(true);
    }, []);
    const handleCreateValidate = React.useCallback((newTask: ProcessExecutionTaskModel) => {
        const idx = tasks.findIndex(i => i.code === newTask.code);
        if (idx < 0) {
            push({ ...newTask, status: IProcessTaskStatusModel.Open } as ProcessExecutionTaskModel);
        } else {
            replace(idx, newTask);
        }
        setCreatingTask(undefined);
    }, [push, replace, tasks]);
    const handleDeleteTask = React.useCallback((processTaskCode: string) => {
        const idx = tasks.findIndex(i => i.code === processTaskCode);
        if (idx >= 0) {
            remove(idx);
        }
    }, [remove, tasks]);
    const lowerStatus = getLowerStatus(tasks.filter(({ isMandatory }) => isMandatory).map(({ status }) => status));
    const { validateForm, setFieldTouched } = useFormikContext();
    const handleLoadQuestionnaire = React.useCallback((taskCode: string, getPreviousAnswers: boolean) => {
        if (getPreviousAnswers) {
            onReloadQuestionnaire({
                id: processExecutionId,
                taskCode: taskCode,
                // getPreviousAnswers: true
            });
        } else {
            const task = tasks.filter(i => i.code === taskCode)[0];
            if (task.type === "QuestionnaireProcessExecutionTaskModel" && task.questionnaireId) {
                onLoadQuestionnaire(task.questionnaireId, taskCode);
            }
        }
        setTimeout(() => {
            validateForm();
            setFieldTouched(oProps<IProcessExecutionSavePayload>().path("execution", "tasks"));
        });
    }, [onLoadQuestionnaire, onReloadQuestionnaire, processExecutionId, setFieldTouched, tasks, validateForm]);

    return <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
        {creatingTasksFromProcessDefinition && <ProcessDefinitionLookups
            onCancel={handleCancelCreateFromProcessDefinition}
            onSelected={handleCreateFromProcessDefinitionValidate} />}
        <Typography gutterBottom={true} variant="h5" component="h2">
            <ProcessStatusBadge status={lowerStatus} />
            Tasks
            <IconButton size="small" onClick={handleCreate}><AddIcon /></IconButton>
            <IconButton size="small" onClick={handleCreateFromProcessDefinition}><PlaylistAddIcon /></IconButton>
        </Typography>
        <EditTask editOrCreate="Create" task={creatingTask} onCancel={handleCreateCancel}
                  onValidate={handleCreateValidate} questionnaires={questionnaires} />

        <div>
            {tasks.map((task, index) => <ProcessTaskInstance
                key={task.code}
                processExecutionQuestionnaireDetail={questionnaireContents}
                taskLoading={taskLoading}
                taskSaving={taskSaving}
                onFileClick={handleViewFile}
                task={task}
                onDeleteClick={handleDeleteTask}
                questionnaires={questionnaires}
                onLoadQuestionnaire={handleLoadQuestionnaire}
                dictionaries={dictionaries}
                index={index}
                enableUp={index > 0}
                enableDown={index < tasks.length - 1}
                swap={swap}
            />)}
        </div>
    </Box>
}

interface ITitleBadgeContentProps {
    task: ProcessExecutionTaskModel;
}

function taskIsDone(task: ProcessExecutionTaskModel) {
    switch (task.type) {
        case "DocumentProcessExecutionTaskModel":
            return !!task.fileName
        case "QuestionnaireProcessExecutionTaskModel":
            return !!task.filledById
    }
    return false
}

function TitleBadgeContent({ task }: ITitleBadgeContentProps) {
    return taskIsDone(task)
        ? <Badge color="primary" badgeContent={<DoneIcon fontSize="small" />}>
            <TitleIconsContent task={task} />
        </Badge>
        : <TitleIconsContent task={task} />;
}

function TitleIconsContent({ task }: ITitleBadgeContentProps) {
    return <Typography>
        {task.name?.en}
        &nbsp;{task.isMandatory && <Tooltip title="Required" arrow><ErrorOutlineIcon fontSize="small" /></Tooltip>}
        &nbsp;{task.isVisibleOnPortal && <Tooltip title="Visible by portal user" arrow><Visibility fontSize="small" /></Tooltip>}
    </Typography>
}

function TitleIcon({ task }: ITitleBadgeContentProps) {
    switch (task.type) {
        case "DocumentProcessExecutionTaskModel": return <AttachmentIcon />
        case "QuestionnaireProcessExecutionTaskModel": return <FileDocumentEditOutlineIcon />
        case "SimpleProcessExecutionTaskModel": return <AssignmentTurnedInIcon />
    }
}

interface IProcessTaskInstanceProps {
    taskSaving: Record<string, boolean | undefined>;
    taskLoading: Record<string, boolean | undefined>;
    processExecutionQuestionnaireDetail: Record<string, IQuestionnaireTaskStateModel>;
    task: ProcessExecutionTaskModel;
    onFileClick: (processTaskCode: string) => void;
    onDeleteClick: (processTaskCode: string) => void;
    questionnaires: IQuestionnaireDevelopmentItemSummaryModel[];
    onLoadQuestionnaire: (processTaskCode: string, getPreviousAnswers: boolean) => void;
    dictionaries: {
        entities: Record<number, EntitySummaryModel>;
    };
    index: number;
    enableUp: boolean;
    enableDown: boolean;
    swap: (indexA: number, indexB: number) => void;
}
function ProcessTaskInstance({ index, taskSaving, taskLoading, task, onFileClick, onDeleteClick, questionnaires,
                                 processExecutionQuestionnaireDetail, onLoadQuestionnaire, dictionaries,
                                 swap, enableUp, enableDown }: IProcessTaskInstanceProps) {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const classes = useStyles();
    const handleChange = React.useCallback(() => setExpanded(previous => !previous), []);
    const handleFileClick = React.useCallback(() => onFileClick(task.code), [task.code, onFileClick]);
    const { validateForm, setFieldTouched } = useFormikContext();
    const [editingTask, setEditingTask] = React.useState<ProcessExecutionTaskModel | undefined>();
    const [, , { setValue: setTaskValue }] = useField(oProps<IProcessExecutionSavePayload>().path("execution", "tasks", index));
    const handleEditCancel = React.useCallback(() => void setEditingTask(undefined), []);

    const handleEdit: MouseEventHandler<HTMLButtonElement> = React.useCallback(e => {
        e.stopPropagation();
        setEditingTask(task);
    }, [task]);
    const handleEditValidate = React.useCallback((newTask: ProcessExecutionTaskModel) => {
        setTaskValue({ ...task, ...newTask });
        setEditingTask(undefined);
        setTimeout(() => {
            validateForm();
            setFieldTouched(oProps<IProcessExecutionSavePayload>().path("execution", "tasks"));
        });
    }, [setFieldTouched, setTaskValue, task, validateForm]);
    const handleDelete: MouseEventHandler<HTMLButtonElement> = React.useCallback(e => {
        e.stopPropagation();
        onDeleteClick(task.code);
        setTimeout(() => {
            validateForm();
            setFieldTouched(oProps<IProcessExecutionSavePayload>().path("execution", "tasks"));
        });
    }, [onDeleteClick, setFieldTouched, task.code, validateForm]);

    const handleUp = useCallback(e => {
        e.stopPropagation();
        if (!enableUp) return
        swap(index, index - 1)
        setTimeout(() => {
            validateForm();
            setFieldTouched(oProps<IProcessExecutionSavePayload>().path("execution", "tasks"));
        });
    }, [enableUp, index, setFieldTouched, swap, validateForm])
    const handleDown = useCallback(e => {
        e.stopPropagation();
        if (!enableDown) return
        swap(index, index + 1)
        setTimeout(() => {
            validateForm();
            setFieldTouched(oProps<IProcessExecutionSavePayload>().path("execution", "tasks"));
        });
    }, [enableDown, index, setFieldTouched, swap, validateForm])

    const handleQuestionnaireLoad = React.useCallback((getPreviousAnswers: boolean) => onLoadQuestionnaire(task.code, getPreviousAnswers), [onLoadQuestionnaire, task.code]);
    const completeTitle = React.useMemo(() => `${task.description?.en ?? task.name?.en} ${task.isMandatory ? "(Required)" : null}`, [task.description?.en, task.isMandatory, task.name?.en]);
    return <Accordion key={task.code} expanded={expanded} onChange={handleChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: 'ButtonFace' }}>
            <Box display="flex" flexDirection="row" width="100%" alignItems="center" gridGap={16}>
                <TitleIcon task={task} />
                <Box>
                    <Typography className={classes.heading} gutterBottom={true}>
                        <Tooltip title={completeTitle}>
                            <TitleBadgeContent task={task} />
                        </Tooltip>
                    </Typography>
                </Box>
                <Box flexGrow={1} />
                <IconButton size="small" onClick={handleDelete}> <DeleteIcon /> </IconButton>
                <Box display="flex" flexDirection="row" alignItems="center" gridGap={0}>
                    <IconButton className={clsx(!enableUp && classes.hiddenButton)} size="small"
                                onClick={handleUp}><UpIcon /></IconButton>
                    <IconButton className={clsx(!enableDown && classes.hiddenButton)} size="small"
                                onClick={handleDown}><DownIcon /></IconButton>
                </Box>
                <IconButton size="small" onClick={handleEdit}> <EditIcon /> </IconButton>
                <Box>
                    <Typography className={classes.statusHeading}>
                        <ProcessStatusBadge size="small" status={task.status} />
                    </Typography>
                </Box>
            </Box>
        </AccordionSummary>
        <AccordionDetails>
            <EditTask editOrCreate="Edit" task={editingTask} onCancel={handleEditCancel} onValidate={handleEditValidate}
                      questionnaires={questionnaires} />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormSimpleSelectField
                        name={oProps<IProcessExecutionSavePayload>().path("execution", "tasks", index, "status")}
                        label="Status" options={processTaskStatuses} required={true} />
                </Grid>
                {task.type === "DocumentProcessExecutionTaskModel" && <DocumentTaskDetails
                    taskFieldName={oProps<IProcessExecutionSavePayload>().path("execution", "tasks", index)}
                    taskFileFieldName={oProps<IProcessExecutionSavePayload>().path("files", task.code)}
                    onFileClick={handleFileClick}
                    fileSaving={!!taskSaving[task.code]}
                    fileLoading={!!taskLoading[task.code]}
                    dictionaries={dictionaries} />}
                {task.type === "QuestionnaireProcessExecutionTaskModel" && <QuestionnaireTaskDetails
                    onQuestionnaireLoad={handleQuestionnaireLoad}
                    taskResponseFieldName={oProps<IProcessExecutionSavePayload>().path("questionnaires", task.code)}
                    processExecutionQuestionnaireDetail={processExecutionQuestionnaireDetail[task.code]}
                    taskFieldName={oProps<IProcessExecutionSavePayload>().path("execution", "tasks", index)}
                    questionnaireSaving={!!taskSaving[task.code]}
                    questionnaireLoading={!!taskLoading[task.code]}
                    dictionaries={dictionaries} />}
                <Grid item xs={12}>
                    <FormTextField
                        name={oProps<IProcessExecutionSavePayload>().path("execution", "tasks", index, "comment")}
                        label="Comment" multiline={true} />
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
}

interface IDocumentTaskDetailsProps {
    taskFieldName: string;
    taskFileFieldName: string;
    onFileClick: () => void;
    fileSaving: boolean;
    fileLoading: boolean;
    dictionaries: {
        entities: Record<number, EntitySummaryModel>;
    };
}
function DocumentTaskDetails({ taskFieldName, taskFileFieldName, onFileClick, fileSaving, fileLoading, dictionaries: { entities } }: IDocumentTaskDetailsProps) {
    const [{ value: task }] = useField<IDocumentProcessExecutionTaskModel>(taskFieldName);
    return <>
        <Grid item xs={12}>
            <FormDatePickerField name={oProps<IDocumentProcessExecutionTaskModel>(taskFieldName).path("endValidity")} label="Expiration" />
        </Grid>
        {(!!task.filledById) && <Grid item xs={12}>
            <SummaryField label="Submitted By" value={getEntityName(entities[task.filledById])} />
        </Grid>}
        <Grid item xs={12}>
            <div>
                {(fileSaving || fileLoading) && <LinearProgress variant="query" />}
                <FormDropZoneField
                    name={oProps<IDocumentProcessExecutionTaskModel>(taskFieldName).path("fileName")}
                    dateTime={oProps<IDocumentProcessExecutionTaskModel>(taskFieldName).path("fileSubmissionDate")}
                    contentName={taskFileFieldName}
                    onClick={onFileClick}
                    label="Drop the document here"
                    emptyValue={null} />
            </div>
        </Grid>
    </>
}

interface IQuestionnaireTaskDetailsProps {
    taskFieldName: string;
    taskResponseFieldName: string;
    questionnaireSaving: boolean;
    questionnaireLoading: boolean;
    processExecutionQuestionnaireDetail?: IQuestionnaireTaskStateModel;
    onQuestionnaireLoad: (getPreviousAnswers: boolean) => void;
    dictionaries: {
        entities: Record<number, EntitySummaryModel>;
    };
}
function QuestionnaireTaskDetails({ taskFieldName, questionnaireSaving, questionnaireLoading, processExecutionQuestionnaireDetail: { responses, template } = {}, onQuestionnaireLoad, taskResponseFieldName, dictionaries: { entities } }: IQuestionnaireTaskDetailsProps) {
    const [isSurveyOpened, setIsSurveyOpened] = React.useState<boolean>(false);
    const [, , { setValue: setResponseContentValue }] = useField<any | null | undefined>(oProps<IQuestionnaireResponse>(taskResponseFieldName).path("content"));
    const [, , { setValue: setResponseComplete }] = useField<any | null | undefined>(oProps<IQuestionnaireResponse>(taskResponseFieldName).path("isCompleted"));
    const [{ value: task }] = useField<IQuestionnaireProcessExecutionTaskModel>(taskFieldName);
    const [, , { setValue: setTaskState }] = useField<IQuestionnaireProcessExecutionTaskModel["status"]>(oProps<IQuestionnaireProcessExecutionTaskModel>(taskFieldName).path("status"));

    const handleOpen = React.useCallback(() => {
        onQuestionnaireLoad(true);
        setIsSurveyOpened(true);
    }, [onQuestionnaireLoad]);
    const handleReset = React.useCallback(() => {
        setIsSurveyOpened(false);
        onQuestionnaireLoad(false);
        setResponseComplete(false);
        setIsSurveyOpened(true);
    }, [onQuestionnaireLoad, setResponseComplete]);
    const handleCancelEdit = React.useCallback(() => {
        setIsSurveyOpened(false);
    }, []);
    const handleComplete = React.useCallback((values: any) => {
        setResponseContentValue(values);
        setTaskState(IProcessTaskStatusModel.InProgress);
        setResponseComplete(true);
        setIsSurveyOpened(false);
    }, [setResponseContentValue, setTaskState, setResponseComplete]);
    const handleOk = React.useCallback((values: any) => {
        setResponseContentValue(values);
        setResponseComplete(false);
        setIsSurveyOpened(false);
    }, [setResponseContentValue, setResponseComplete]);

    return <>
        {(!!template && !questionnaireLoading) &&
            <SurveyRunnerDialog
                isOpened={isSurveyOpened}
                onCancel={handleCancelEdit}
                onComplete={handleComplete}
                onReset={handleReset}
                onOk={handleOk}
                template={template}
                initialValues={responses} />}
        {(!!task.filledAt) && <Grid item xs={12}>
            <SummaryField label="Filled On" value={formatDateTime(task.filledAt)} />
        </Grid>}
        {(!!task.filledById) && <Grid item xs={12}>
            <SummaryField label="Filled By" value={getEntityName(entities[task.filledById])} />
        </Grid>}
        <Grid item xs={6}>
            <Button onClick={handleOpen} disabled={questionnaireSaving || questionnaireLoading} endIcon={(questionnaireSaving || questionnaireLoading) && <CircularProgress size={16} />}>
                {task.hasResponses
                    ? (!!task.filledById ? "View and edit answers" : "View and edit draft")
                    : "Answer questionnaire"}
            </Button>
        </Grid>
        {(!!task.result) && <Grid item xs={12}>
            <DashboardRunner value={task.result} />
            {/* <ReactJson
                style={{ width: 600 }}
                name={false}
                collapsed={1}
                displayDataTypes={false}
                displayObjectSize={false}
                src={task.result} /> */}
        </Grid>}
        {(!!task.error) && <Grid item xs={12}>
            <SummaryField label="Error" value={task.error} error />
        </Grid>}
    </>
}

const types: Record<ProcessExecutionTaskModel["type"], string> = {
    "SimpleProcessExecutionTaskModel": "Simple Check",
    "DocumentProcessExecutionTaskModel": "Document",
    "QuestionnaireProcessExecutionTaskModel": "Questionnaire"
}

interface IEditTaskProps {
    task?: ProcessExecutionTaskModel;
    onValidate: (task: ProcessExecutionTaskModel) => void;
    onCancel: () => void;
    editOrCreate: EditType;
    questionnaires: IQuestionnaireDevelopmentItemSummaryModel[];
}

function EditTask({ onValidate, task, onCancel, editOrCreate, questionnaires }: IEditTaskProps) {
    if (!task) {
        return null;
    }
    return <Formik onSubmit={onValidate} initialValues={task} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm, values }: FormikProps<ProcessExecutionTaskModel>) {
        return <DialogPanel
            onBackClick={onCancel}
            title={`${editOrCreate} Task`}
            actions={[{ label: "OK", onClick: submitForm, disabled: !dirty || !isValid }]}
            isOpened={!!task}>
            <FieldBox display="flex" flexDirection="column">
                <FormSimpleSelectField name={oProps<ProcessExecutionTaskModel>().path("type")} label="Type" options={types} required />
                <FormTextField name={oProps<ProcessExecutionTaskModel>().path("code")} label="Code" disabled={editOrCreate === "Edit"} required={editOrCreate === "Create"} />
                <FormMultiCulturedTextField name={oProps<ProcessExecutionTaskModel>().path("name")} label="Name" required={["en"]} />
                <FormMultiCulturedTextField name={oProps<ProcessExecutionTaskModel>().path("description")} label="Description" />
                <FormCheckBoxField name={oProps<ProcessExecutionTaskModel>().path("isPortalTask")} label="Portal Task" />
                <FormCheckBoxField name={oProps<ProcessExecutionTaskModel>().path("isMandatory")} label="Required" />
                {values?.type === "QuestionnaireProcessExecutionTaskModel" && <FormSimpleSelectField name={oProps<IQuestionnaireProcessExecutionTaskModel>().path("questionnaireId")} label="Questionnaire" options={questionnaires} required />}
            </FieldBox>
        </DialogPanel>;
    }
}