import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { IAccountTypeModel, IBankAccountModel, IFeatureModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { getEnumLabels } from "tools/lib/utility";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import BankAccountData from "./BankAccountData";
import CustomViewDatas from "features/Relationship/CustomViewDatas";

const accountTypes = getEnumLabels(IAccountTypeModel);

export default function BankAccount() {

    const { navigationActiveScreen: navigationActiveUrlNode } = useReduxSelections("navigation");
    const {
        bankAccountCurrent,
        bankAccountLoading,
        dictionaries,

        customScreenDataLoading,
        customScreenDatas,
        customScreens,
        customScreenDataSubmitting
    } = useReduxSelections("bankAccount");
    const {
        bankAccountDelete,
        bankAccountSave,
        bankAccountAddEntityInDictionary,
        bankAccountAddPortfolioInDictionary,
        securityCustomScreenDataSubmit
    } = useReduxActions("bankAccount");
    const { navigationNavigate } = useReduxActions("navigation");
    const {
        referenceCountries = {},
        referenceCurrencies = {},
    } = useReduxSelections("reference");

    const isGranted = useGrants();

    const handleBack = () => navigationNavigate(undefined);
    if (!bankAccountCurrent) {
        return <DetailPanelLoading onBackClick={handleBack} hasSubTitle={false} />;
    }
    const handleDelete = () => {
        if (!!bankAccountCurrent?.id) {
            bankAccountDelete(bankAccountCurrent.id);
        }
    }
    const handleTabValueChanged = (value: any) => navigationNavigate({ sectionKey: value as string });

    const handleDataSubmit = (customScreenId: number, values: any) =>
        securityCustomScreenDataSubmit({ id: bankAccountCurrent?.id, customScreenId, values });

    const canEditCustomData = isGranted(IFeatureModel.ManagedPortfolioWrite);

    const title = !!bankAccountCurrent?.id ? bankAccountCurrent.name : 'New bank account';
    const handleSubmit = (values: IBankAccountModel, { setSubmitting }: FormikHelpers<IBankAccountModel>) => {
        bankAccountSave(values);
        setSubmitting(false);
    }

    return <Formik onSubmit={handleSubmit} initialValues={bankAccountCurrent} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm, values, validateForm, setFieldValue }: FormikProps<IBankAccountModel>) {

        const tabs: ISubMenu[] = [{
            label: "Data",
            value: "detail"
        }];

        if (values?.id && !!customScreens) {
            tabs.push({
                label: "Custom Data",
                value: "customViews"
            });
        }

        const tabValue = navigationActiveUrlNode?.activeSectionKey;

        return <DetailPanel
            isQuerying={bankAccountLoading || customScreenDataLoading || customScreenDataSubmitting}
            title={title}
            canSave={dirty && isValid}
            onSaveClick={submitForm}
            canDelete={!!values.id}
            onDeleteClick={handleDelete}
            onBackClick={handleBack}
            saveAllowed={IFeatureModel.ManagedPortfolioWrite}
            deleteAllowed={IFeatureModel.ManagedPortfolioWrite}
            badge={!values.id ? "new" : undefined}
            tabs={tabs}
            tabValue={tabValue}
            onTabValueChanged={handleTabValueChanged}>
            {(tabValue === "detail") &&
                <WriteAccess value={IFeatureModel.ManagedPortfolioWrite}>
                    <BankAccountData
                        referenceCountries={referenceCountries}
                        referenceCurrencies={referenceCurrencies}
                        accountTypes={accountTypes}
                        dictionaries={dictionaries}
                        bankAccountAddEntityInDictionary={bankAccountAddEntityInDictionary}
                        bankAccountAddPortfolioInDictionary={bankAccountAddPortfolioInDictionary}
                    />
                </WriteAccess>
            }
            {(tabValue === "customViews" && !!customScreenDatas && !!customScreens?.length) &&
                <CustomViewDatas
                    canEdit={canEditCustomData}
                    dictionaries={dictionaries}
                    customScreenLoading={customScreenDataLoading}
                    dataSubmitting={customScreenDataSubmitting}
                    customScreens={customScreens}
                    onDataSubmit={handleDataSubmit}
                    customScreenDatas={customScreenDatas}
                />}
        </DetailPanel>;
    }
}
