import * as React from "react";
// import { Editor, ControlledEditorOnChange, EditorDidMount, monaco as reactMonaco } from "@monaco-editor/react";
import Editor, { OnChange, OnMount } from "@monaco-editor/react";
import { ICompletionDataModel, IMacroLanguageModel, IMacroModel, ITextModelWithPosition } from "proxy/apiProxy";
import { INotControllableEditorProps } from "./INotControllableEditorProps";
import { Box, SvgIconTypeMap, createStyles, withStyles } from "@material-ui/core";
import { FabDropdown } from "./FabDropdown";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import RateReviewIcon from '@material-ui/icons/RateReview';

const FloatingBoxRight = withStyles((theme) =>
    createStyles({
        root: {
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 999
        }
    })
)(Box);

// https://microsoft.github.io/monaco-editor/playground.html#interacting-with-the-editor-adding-an-action-to-an-editor-instance

export interface ICodeEditorError {
    start: number;
    length: number;
    startLineNumber: number;
    startColumnNumber: number;
    endLineNumber: number;
    endColumnNumber: number;
    sourcePart: string;
    message: string;
}

export interface ICodeEditorProps extends INotControllableEditorProps<IMacroModel> {
    codeErrors?: ICodeEditorError[];
    readOnly?: boolean;
    jsonSchema?: object;
    onCodeChanged: (v: IMacroModel) => void;
    onRequestAutoComplete?: (position: ITextModelWithPosition) => Promise<ICompletionDataModel[]>;
};
type Monaco = typeof monaco;
export default function CodeEditor(props: ICodeEditorProps) {
    const {
        codeErrors,
        readOnly = false,
        jsonSchema,
        onCodeChanged,
        refToGetValue
        // onRequestAutoComplete
    } = props;
    const monacoRef = React.useRef<Monaco>();
    const modelRef = React.useRef<monaco.editor.ITextModel | null | undefined>();
    const editorRef = React.useRef<monaco.editor.IStandaloneCodeEditor | null | undefined>();
    const languageRef = React.useRef<IMacroLanguageModel>(refToGetValue.current.initialValue?.language ?? IMacroLanguageModel.CSharp);
    const [language, setLanguage] = React.useState<IMacroLanguageModel>(languageRef.current);
    const handleChange: OnChange = React.useCallback((newValue, ev) => onCodeChanged({
        content: newValue || "",
        language
    }), [onCodeChanged, language]);
    // React.useEffect(() => {
    //     if (onRequestAutoComplete) {
    //         if(!monacoRef.current) return;
    //         monacoRef.current.languages.registerCompletionItemProvider(language, {
    //             provideCompletionItems: async function (model, position) {
    //                 onRequestAutoComplete({
    //                     text: model.getValue(),
    //                     lineNumber: position.lineNumber,
    //                     column: position.column
    //                 });
    //                 const result: monaco.languages.CompletionList = { suggestions: [] };
    //                 return result;
    //             }
    //         })
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [language, onRequestAutoComplete, monacoRef.current]);

    React.useEffect(() => setErrors(codeErrors, modelRef.current, monacoRef.current), [codeErrors]);
    React.useEffect(() => setJsonSchema(jsonSchema, modelRef.current, monacoRef.current), [jsonSchema]);

    // macroScriptsApi.getAutoCompleteScriptAsync({ textModel: { position: 1, text: "qzert" }, type: IMacroScriptTypeModel.Monitoring });
    const setter = React.useCallback((v?: IMacroModel) => {
        editorRef.current?.setValue(v?.content ?? "");
        // setLanguage(v?.language ?? IMacroLanguageModel.CSharp);
    }, []);
    const getter = React.useCallback(() => ({ content: editorRef.current?.getValue(), language: languageRef.current } as IMacroModel), []);
    // useNotControllableEditor(props, setter, getter);

    const handleEditorMounted: OnMount = React.useCallback((editor: monaco.editor.IStandaloneCodeEditor, mnco: typeof monaco) => {
        monacoRef.current = mnco;
        editorRef.current = editor;
        refToGetValue.current.setEditor(setter, getter);
        if (!monacoRef.current) return;
        modelRef.current = editor.getModel();
        if (modelRef.current) {
            // modelRef.current.onDidChangeLanguage()
            monacoRef.current.editor.setModelLanguage(modelRef.current, getLanguageCode(languageRef.current));
        }
        // modelRef.current.getLa
        if (codeErrors) {
            setErrors(codeErrors, modelRef.current, monacoRef.current);
        }
        if (jsonSchema) {
            setJsonSchema(jsonSchema, modelRef.current, monacoRef.current);
        }
    }, [refToGetValue, codeErrors, jsonSchema, getter, setter]);
    React.useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => refToGetValue.current.unsetEditor();
    }, [refToGetValue]);
    const handleLanguageChanged = React.useCallback((newLanguage: IMacroLanguageModel) => {
        setLanguage(newLanguage);
        languageRef.current = newLanguage;
        if (modelRef.current && monacoRef.current) {
            monacoRef.current.editor.setModelLanguage(modelRef.current, getLanguageCode(newLanguage));
        }
    }, []);
    return <div style={{ height: "100%", width: "100%", position: "relative" }}>
        <FloatingBoxRight><FormFabDropDown onValueChanged={handleLanguageChanged} value={language} options={IMacroLanguageModel} icon={RateReviewIcon} /></FloatingBoxRight>
        <Editor
            options={{ readOnly }}
            height="calc(100% - 30px)" // TODO: not great... possible to find much better
            // language={getLanguageCode(language)}
            onChange={handleChange}
            onMount={handleEditorMounted} />
    </div>
}
function getLanguageCode(language: IMacroLanguageModel) {
    switch (language) {
        case IMacroLanguageModel.CSharp: return "csharp";
        case IMacroLanguageModel.Python: return "python";
    }
}
function setJsonSchema(batchSchema: any, model: monaco.editor.ITextModel | null | undefined, mnco: Monaco | null | undefined) {
    if (!model || !batchSchema) {
        return;
    }
    if (!mnco) return;
    mnco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true,
        allowComments: true,
        schemas: [{
            uri: "http://myserver/foo-schema.json", // id of the first schema
            fileMatch: [model.uri.toString()], // associate with our model
            schema: batchSchema
        }]
    });
}
function setErrors(scriptErrors: ICodeEditorError[] | undefined = [], model: monaco.editor.ITextModel | null | undefined, mnco: Monaco | null | undefined) {
    if (!model) {
        return;
    }
    if (!mnco) return;
    mnco.editor.setModelMarkers(model, "MacroScriptEditor", scriptErrors.map(({ endColumnNumber, endLineNumber, startColumnNumber, startLineNumber, message }) => ({
        startLineNumber,
        startColumn: startColumnNumber,
        endLineNumber,
        endColumn: endColumnNumber,
        message,
        severity: mnco!.MarkerSeverity.Error,
    })));
}
interface IFormFabDropDownProps<T extends keyof any> {
    onValueChanged: (value: T) => void;
    value: T;
    options: Record<T, string>;
    icon: OverridableComponent<SvgIconTypeMap>;
}
function FormFabDropDown<T extends keyof any>({ options, icon, onValueChanged, value }: IFormFabDropDownProps<T>) {
    return <FabDropdown options={options} label={options[value]} onSelect={onValueChanged} icon={icon} />
}