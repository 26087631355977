import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import Trades from "./PortfolioTrades";
import { getSiteScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class TradeScreen extends React.PureComponent {
    public render() {
        return <NavigationRoute slideDirection={"right"} component={Trades} path={getSiteScreenUrl("TradesScreen")} />
    }
}

export default TradeScreen;
