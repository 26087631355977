import DetailPanel from "tools/components/DetailPanel";
import { IFeatureModel, ReportModel } from "proxy/apiProxy";
import { FormikProps, Formik, FormikHelpers } from 'formik';
import ReportData from "./ReportData";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import FileDownloadOutlineIcon from "mdi-material-ui/FileDownloadOutline";
import { IActionButton } from "tools/components/FabContainer";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";

export default function Report() {
    const { reportSave, reportDelete,
        reportSelectReportTemplate,
        reportSelectReportDocument,
        reportGenerate
    } = useReduxActions("report");
    const { navigationNavigate } = useReduxActions("navigation");
    const { reportCurrent, reportLoading, reportTemplateMetadataSearchLoading, reportGenerating,
        reportParameterMetadata = [], reportReportTemplates = [], reportDocumentDefinitions = []
    } = useReduxSelections("report");

    const handleBack = () => {
        navigationNavigate(undefined);
    }

    if (!reportCurrent) {
        return <DetailPanelLoading onBackClick={handleBack} hasSubTitle={false} />;
    }
    const { report } = reportCurrent;

    const handleDelete = () => {
        const { report: { id } } = reportCurrent;
        if (id) {
            reportDelete(id);
        }
    }
    const handleSubmit = (values: ReportModel, { setSubmitting }: FormikHelpers<ReportModel>) => {
        reportSave(values);
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={report} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;

    function renderForm(formikProps: FormikProps<ReportModel>) {
        if (!reportCurrent) {
            return;
        }
        const { dirty, isValid, submitForm, values } = formikProps;
        const { id, name } = values;
        const title = !!id ? (name) : 'New Report';
        const handleOpenReportTemplate = () => values.type === "TemplateReportModel" && navigationNavigate({ screenKey: "Templates", sectionKey: "detail", parameters: { id: values.reportTemplateId } });
        const handleOpenDocumentDefinition = () => values.type === "DocumentReportModel" && navigationNavigate({ screenKey: "BatchDefinitions", sectionKey: "detail", parameters: { id: values.documentDefinitionId } });
        const extraActionButtons = [
            //     {
            //     label: "Set preview data",
            //     onClick: this.handlePreviewDataRequest,
            //     icon: DatabaseExportIcon
            // }, 
            {
                label: "Generate Reports",
                onClick: () => reportGenerate(id),
                disabled: !id || reportGenerating,
                icon: FileDownloadOutlineIcon,
                feature: IFeatureModel.ReportExecute
            }] as IActionButton[];

        return <WriteAccess value={IFeatureModel.BatchWrite}>
            <DetailPanel
                isQuerying={reportLoading || reportTemplateMetadataSearchLoading || reportGenerating}
                title={title}
                canSave={dirty && isValid}
                onSaveClick={submitForm}
                canDelete={!!id}
                onDeleteClick={handleDelete}
                actions={extraActionButtons}
                onBackClick={handleBack}
                saveAllowed={IFeatureModel.BatchWrite}
                deleteAllowed={IFeatureModel.BatchWrite}
                badge={!id ? "new" : undefined}>
                <ReportData
                    parameterMetadata={reportParameterMetadata}
                    reportTemplates={reportReportTemplates}
                    onSelectReportTemplate={reportSelectReportTemplate}
                    onSelectDocumentDefinition={reportSelectReportDocument}
                    onOpenReportTemplate={handleOpenReportTemplate}
                    onOpenDocumentDefinition={handleOpenDocumentDefinition}
                    documentDefinitions={reportDocumentDefinitions} />
            </DetailPanel></WriteAccess>
    }
}
