import { Epic } from 'redux-observable'
import { mergeMap, map } from 'rxjs/operators';
import { changedNavigation, mapToPayload } from "lib/rxJsUtility";
import { tenantsApi } from "proxy/apiProxy";
import { ActionFactories, Action } from "./slice";
import { IAnyAction } from 'reducers';
import { filterRoute } from 'tools/lib/UrlDictionary';


export const onOpenScreenTenantManagers: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("TenantManagers"),
        map(() => ActionFactories.tenantManagerLoginsLoad()));

export const loadLogins: Epic<Action> = action$ => action$.pipe(
    mapToPayload("tenantManager", "tenantManagerLoginsLoad"),
    mergeMap(tenantsApi.getLoginsAsync),
    map(ActionFactories.tenantManagerLoginsLoaded));

export const adminSwitch: Epic<Action> = action$ => action$.pipe(
    mapToPayload("tenantManager", "tenantManagerLoginSwitch"),
    mergeMap(async i => tenantsApi.setTenantManagersAsync({ id: i.entityId, isTenantManager: i.isAdmin }).then(() => i)),
    map(ActionFactories.tenantManagerLoginSwitched));
