import { Epic } from "redux-observable";
import { filter, map, mergeMap, share, tap, delay } from "rxjs/operators";
import { batchesApi, developmentItemsRunApi, macroScriptsApi, monitoringMacroCallsApi, reportsApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, changedNavigation, onlyNotNull } from "lib/rxJsUtility";
import { filterRoute } from "tools/lib/UrlDictionary";
import { base64toBlob, tryParseNumber } from "tools/lib/utility";
import { from, merge, of } from "rxjs";
import saveAs from "file-saver";

export const onLoadDashboardList: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("dashboard", "dashboardMonitoringLoadAll"),
        mergeMap(async () => Promise.all([
            monitoringMacroCallsApi.getAllDashboardsAsync(),
            developmentItemsRunApi.getHomeDashboardsAsync()])),
        map(([oldOne, newOne]) => ({ old: oldOne, new: newOne })),
        map(ActionFactories.dashboard.dashboardMonitoringLoadedAll));
export const onLoadMacroList: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("dashboard", "dashboardMacroLoadAll"),
        mergeMap(() => macroScriptsApi.getAllAsync({})),
        map(ActionFactories.dashboard.dashboardMacroLoadedAll));

export const onLoadBatchList: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("dashboard", "dashboardBatchLoadAll"),
        mergeMap(() => batchesApi.getAllAsync({})),
        map(ActionFactories.dashboard.dashboardBatchLoadedAll));

export const onLoadReportList: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("dashboard", "dashboardReportLoadAll"),
        mergeMap(() => reportsApi.getAllAsync({})),
        map(ActionFactories.dashboard.dashboardReportLoadedAll));







export const onOpenModule: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("Dashboards"),
        map(() => ActionFactories.dashboard.dashboardMonitoringLoadAll()));

export const onOpenReport: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("DashboardsGetFiles"),
        mergeMap(() => from([
            ActionFactories.dashboard.dashboardReportLoadAll(),
            ActionFactories.dashboard.dashboardMacroLoadAll()])));
export const onOpenBatch: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("DashboardsExecute"),
        mergeMap(() => from([
            ActionFactories.dashboard.dashboardBatchLoadAll(),
            ActionFactories.dashboard.dashboardMacroLoadAll()])));
export const onOpenImport: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("DashboardsDropFiles"),
        map(() => ActionFactories.dashboard.dashboardMacroLoadAll()));

export const executeScript: Epic<IAnyAction>
    = action$ => {
        const execution$ = action$.pipe(mapToPayload("dashboard", "dashboardMacroScriptExecute"), share());
        const dataProcessorExecution$ = execution$.pipe(
            filter(i => i.type === "DataProcessorMacroScriptModel"),
            mergeMap(({ id }) => macroScriptsApi.executeDataProcessorMacroAsync({ id }).then(i => ({ ...i, id }))),
            map(ActionFactories.dashboard.dashboardMacroScriptExecuted));

        const fileProviderExecution$ = execution$.pipe(
            filter(i => i.type === "FileRetrieverMacroScriptModel"),
            mergeMap(({ id }) => macroScriptsApi.executeFileProviderMacroAsync({ id }).then(result => ({ ...result, id }))),
            tap(({ file }) => {
                if (file && file.data && file.mimeType && file.name) {
                    const blob = base64toBlob(file.data, file.mimeType);
                    saveAs(blob, file.name);
                }
            }),
            map(({ result, id }) => ActionFactories.dashboard.dashboardMacroScriptExecuted({ ...result, id })));

        const fileProcessorExecution$ = execution$.pipe(
            map(i => {
                if (i.type === "FileProcessorMacroScriptModel") {
                    return i;
                }
                return undefined;
            }),
            onlyNotNull(),
            mergeMap(({ id, file }) => of({ id, file }).pipe(
                mergeMap(({ id, file }) => macroScriptsApi.executeFileProcessorMacroAsync({ id, fileModel: file })),
                map(result => ActionFactories.dashboard.dashboardMacroScriptExecuted({ ...result, id }))
            )));

        return merge(
            dataProcessorExecution$,
            fileProviderExecution$,
            fileProcessorExecution$
        );
    }

export const runBatch: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("dashboard", "dashboardBatchRequestExecution"),
        mergeMap(id => batchesApi.runAsync({ id }).then(() => id)),
        delay(1000),
        map(ActionFactories.dashboard.dashboardBatchRequestedExecution));


export const generateReport: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("dashboard", "dashboardReportGenerate"),
        mergeMap(id => reportsApi.generateReportsAsync({ id }).then(result => ({ ...result, id }))),
        tap(({ blob, fileName }) => saveAs(blob, fileName ?? " ")),
        map(({ id }) => ActionFactories.dashboard.dashboardReportGenerated(id)));

export const onOpenDashboard: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("Dashboards", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? 0),
        onlyNotNull(),
        map(ActionFactories.dashboard.dashboardMonitoringResultLoad));
export const onLoadDashboard: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("dashboard", "dashboardMonitoringResultLoad"),
        mergeMap(id => {
            if (id < 0) {
                return developmentItemsRunApi.dashboardOnLoadAsync({ id: -id, body: { type: "SimpleExecuteModel" } })
            }
            else {
                return monitoringMacroCallsApi.getMonitoringResultsAsync({ id });
            }
        }),
        map(ActionFactories.dashboard.dashboardMonitoringResultLoaded));
