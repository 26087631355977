import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, share, mergeMap } from "rxjs/operators";
import { processClassificationTypesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { changedNavigation, mapToPayload, onlyNotNull } from "lib/rxJsUtility";
import { tryParseNumber } from "tools/lib/utility";
import { filterRoute } from "tools/lib/UrlDictionary";
export const classificationsOpenScreen: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("ProcessDefinitionClassificationTypes"),
        map(ActionFactories.processClassificationType.processClassificationTypeLoadAll));
export const loadClassifications: Epic<IAnyAction>
    = action$ =>
        merge(
            action$.ofType("applicationLoaded").pipe(map(i => null)),
            action$.ofType("processClassificationTypeLoadAll").pipe(map(i => null)),
            action$.ofType("processClassificationTypeSaved").pipe(map(i => null)),
            action$.ofType("referenceRefreshAll").pipe(map(i => null))
        ).pipe(
            mergeMap(() => processClassificationTypesApi.getAllAsync({})),
            map(ActionFactories.processClassificationType.processClassificationTypeAllLoaded));
export const onOpenScreenClassification: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("ProcessDefinitionClassificationTypes", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id)),
        onlyNotNull(),
        map(ActionFactories.processClassificationType.processClassificationTypeLoad));
export const loadClassification: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("processClassificationType", "processClassificationTypeLoad"),
            share()
        );
        return merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => processClassificationTypesApi.getAsync({ id }))),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({ id: 0, name: { en: "" }, code: "", classifications: [] })))
        ).pipe(
            map(ActionFactories.processClassificationType.processClassificationTypeLoaded));
    };
export const saveClassification: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("processClassificationType", "processClassificationTypeSave"),
        mergeMap(model => processClassificationTypesApi.saveAsync({ model })),
        map(ActionFactories.processClassificationType.processClassificationTypeSaved));
export const deleteClassification: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("processClassificationType", "processClassificationTypeDelete"),
            mergeMap(id => processClassificationTypesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.processClassificationType.processClassificationTypeDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }
