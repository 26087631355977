import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Form, Formik} from "formik";
import FormDatePickerField from "../../tools/fieldComponents/FormDatePickerField";
import {oProps} from "../../tools/lib/utility";

interface IBatchConfigureRunModalProps {
    open: boolean;
    handleCancel: () => void;
    handleRun: (asOfDate?: Date) => void,
    name: string;
}


export default function BatchConfigureRunModal({open, handleCancel, handleRun, name}: IBatchConfigureRunModalProps) {
    interface IFormsParams {
        asOfDate: Date | undefined;
    }

    function handleSubmit(values: IFormsParams) {
        console.log("Submit BatchConfigureRunModal with ", values)
        handleRun(values.asOfDate)
    }

    return <div>
        <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Configure run of {name}</DialogTitle>
            <Formik onSubmit={handleSubmit} initialValues={{asOfDate: undefined} as IFormsParams}>
                {({values, isSubmitting}) => (
                    <Form style={{margin: 0}}>
                        <DialogContent>
                            <FormDatePickerField
                                name={oProps<IFormsParams>().path("asOfDate")}
                                label="As of"/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel} color="primary">
                                Cancel
                            </Button>
                            <Button type="submit" disabled={isSubmitting} color="primary">
                                Run
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    </div>
        ;
}
