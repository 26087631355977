import * as React from "react";
import autobind from "autobind-decorator";
import DetailPanel from "tools/components/DetailPanel";
import {
    Chart,
    LineSeries,
    // ScatterSeries,
    ArgumentAxis,
    ValueAxis,
    Legend,

} from '@devexpress/dx-react-chart-material-ui';

import {
    ArgumentScale, FactoryFn,
    Animation
} from '@devexpress/dx-react-chart';
import { scaleTime } from 'd3-scale';
import * as ValueAtRiskReducer from "reducers/ValueAtRisk";
import * as NavigationReducer from "reducers/Navigation";
import { IBacktestingSerieModel, IStatisticScopeModel, IPeriodModel, ILevelOfConfidenceModel } from "proxy/apiProxy";
import { Typography, FormLabel, FormControl, FormGroup, FormControlLabel, Checkbox, Grid, Card, CardContent } from "@material-ui/core";

// test

const legendStyles = {
    display: 'flex',
    maxWidth: '100%',
    flexWrap: 'wrap'
};

const legendLabelStyles: React.CSSProperties = {
    whiteSpace: 'nowrap'
};

const legendItemStyles = {
    flexDirection: 'row',
    alignItem: 'center',
    justifyContent: 'center',
    width: 'auto'
};
const statisticTypeLabel = {
    PRF: "Performance",
    VOLA: "Volatility",
    VARP: "Percentile VaR",
    VARG: "Gaussian VaR",
    VARM: "Modified VaR",
    VVG: "Variance-Gamma VaR",
    MPV: "Marginal P-VaR",
    MGV: "Marginal G-VaR",
    MMV: "Marginal M-VaR",
    MVGV: "Marginal VG-VaR",
    IPV: "Incremental P-VaR",
    IGV: "Incremental G-VaR",
    IMV: "Incremental M-VaR",
    IVGV: "Incremental VG-VaR",
    ESFP: "Percentile ES",
    ESFG: "Gaussian ES",
    ESFM: "Modified ES",
    ESVG: "Variance-Gamma ES",
    SHPE: "Sharpe Ratio",
    INFO: "Information Ratio",
    MDD: "Maximum Drawdown",
    TERR: "Tracking Error",
    BETA: "Beta",
    CTP: "Contribution To Performance",
}
type IHistoricalValueTypeModel = keyof typeof statisticTypeLabel;

enum ScopeLabel {
    Exposure = "Exposure",
    Nav = "Nav"
}
enum PeriodLabel {
    OneMonth = "1M",
    OneYear = "1Y",
    TwoYears = "2Y",
    ThreeYears = "3Y"
}

enum LevelOfConfidenceLabel {
    L900 = "90%",
    L950 = "95%",
    L990 = "99%",
    L995 = "99.5%",
    L999 = "99.9%",
}
type IProps =
    ValueAtRiskReducer.IState &
    typeof NavigationReducer.ActionFactories;

// const tmp:IHistoricalValueTypeModel=""
interface IState {
    selectedStatisticTypes: IHistoricalValueTypeModel[],
    selectedScopes: IStatisticScopeModel[],
    selectedHoldingPeriods: IPeriodModel[],
    selectedCalculationPeriods: IPeriodModel[],
    selectedLevelOfConfidences: ILevelOfConfidenceModel[],
    benchmarkIsShow: boolean
}

@autobind
class ValueAtRiskDetail extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectedStatisticTypes: ["PRF", "VARG"],
            selectedScopes: [IStatisticScopeModel.Nav, IStatisticScopeModel.Exposure],
            selectedHoldingPeriods: [IPeriodModel.OneMonth, IPeriodModel.OneYear],
            selectedCalculationPeriods: [IPeriodModel.OneMonth, IPeriodModel.OneYear],
            selectedLevelOfConfidences: [ILevelOfConfidenceModel.L990],
            benchmarkIsShow: false
        };
    }
    public render() {
        const { valueAtRiskBacktesting, valueAtRiskBacktestingChartData } = this.props;
        if (!valueAtRiskBacktesting && !valueAtRiskBacktestingChartData) return <Typography variant="h6" color="inherit" noWrap={true}>
            Select a portfolio to view its details
        </Typography>;
        const chartKey = JSON.stringify(this.state);
        return (
            <DetailPanel
                isQuerying={false}
                title={valueAtRiskBacktesting?.portfolio.name + "-Backtesting"}
                subTitle={""}
                onBackClick={this.handleBack}>
                <Chart
                    key={chartKey}
                    data={valueAtRiskBacktestingChartData}>
                    <ArgumentScale factory={scaleTime as unknown as FactoryFn} />
                    <ArgumentAxis />
                    <ValueAxis />
                    {valueAtRiskBacktesting?.series.filter(this.isShowSerie).map((value: IBacktestingSerieModel, key) => {
                        const { portfolioValues, benchmarkValues, ...restSerie } = value;
                        const valueKey = ValueAtRiskReducer.createValueKey("portfolio", restSerie);
                        return <LineSeries
                            name={valueKey}
                            key={valueKey}
                            valueField={valueKey}
                            argumentField={"date"}
                        />

                    })}
                    <Legend position="bottom" rootComponent={this.legendRoot} itemComponent={this.legendItem} labelComponent={LegendLabel} />
                    <Animation />
                </Chart>
                <Card>
                    <CardContent >
                        <Grid container={true} direction="row" spacing={1}>
                            <Grid container={true} item={true} direction="column" xs={4} spacing={3}>
                                <FormControl component="fieldset" >
                                    <FormLabel component="legend">Statistic Type</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatisticTypes.indexOf("PRF") >= 0} onChange={this.handleChangeStatisticType} name={"PRF"} />}
                                            label="Performance"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatisticTypes.indexOf("VOLA") >= 0} onChange={this.handleChangeStatisticType} name={"VOLA"} />}
                                            label="Volatility"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatisticTypes.indexOf("MDD") >= 0} onChange={this.handleChangeStatisticType} name={"MDD"} />}
                                            label="Maximal Drawdown"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatisticTypes.indexOf("SHPE") >= 0} onChange={this.handleChangeStatisticType} name={"SHPE"} />}
                                            label="Sharpe Ratio"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatisticTypes.indexOf("INFO") >= 0} onChange={this.handleChangeStatisticType} name={"INFO"} />}
                                            label="Information Ratio"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatisticTypes.indexOf("TERR") >= 0} onChange={this.handleChangeStatisticType} name={"TERR"} />}
                                            label="Tracking Error"
                                        />
                                    </FormGroup>
                                </FormControl>

                                <FormControl component="fieldset" >
                                    <FormLabel component="legend">Value-at-Risk</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatisticTypes.indexOf("VARP") >= 0} onChange={this.handleChangeStatisticType} name={"VARP"} />}
                                            label="Percentile Value-at-Risk"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatisticTypes.indexOf("VARG") >= 0} onChange={this.handleChangeStatisticType} name={"VARG"} />}
                                            label="Gaussian Value-at-Risk"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatisticTypes.indexOf("VARM") >= 0} onChange={this.handleChangeStatisticType} name={"VARM"} />}
                                            label="Modified Value-at-Risk"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatisticTypes.indexOf("VVG") >= 0} onChange={this.handleChangeStatisticType} name={"VVG"} />}
                                            label="Variance-Gamma Value-at-Risk"
                                        />
                                    </FormGroup>
                                </FormControl>


                                <FormControl component="fieldset" >
                                    <FormLabel component="legend">Expected Shortfall</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatisticTypes.indexOf("ESFP") >= 0} onChange={this.handleChangeStatisticType} name={"ESFP"} />}
                                            label="Percentile Expected Shortfall"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatisticTypes.indexOf("ESFG") >= 0} onChange={this.handleChangeStatisticType} name={"ESFG"} />}
                                            label="Gaussian Expected Shortfall"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatisticTypes.indexOf("ESFM") >= 0} onChange={this.handleChangeStatisticType} name={"ESFM"} />}
                                            label="Modified Expected Shortfall"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatisticTypes.indexOf("ESVG") >= 0} onChange={this.handleChangeStatisticType} name={"ESVG"} />}
                                            label="Variance-Gamma Expected Shortfall"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>

                            <Grid container={true} item={true} direction="column" xs={4} spacing={3}>
                                <FormControl component="fieldset" >
                                    <FormLabel component="legend">Scope</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedScopes.indexOf(IStatisticScopeModel.Nav) >= 0} onChange={this.handleChangeScope} name={IStatisticScopeModel.Nav} />}
                                            label="NAV"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedScopes.indexOf(IStatisticScopeModel.Exposure) >= 0} onChange={this.handleChangeScope} name={IStatisticScopeModel.Exposure} />}
                                            label="Exposure"
                                        />
                                    </FormGroup>
                                </FormControl>

                                <FormControl component="fieldset" >
                                    <FormLabel component="legend">Holding Period</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedHoldingPeriods.indexOf(IPeriodModel.OneMonth) >= 0} onChange={this.handleChangeHoldingPeriod} name={IPeriodModel.OneMonth} />}
                                            label="1 Month"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedHoldingPeriods.indexOf(IPeriodModel.OneYear) >= 0} onChange={this.handleChangeHoldingPeriod} name={IPeriodModel.OneYear} />}
                                            label="1 Year (Annualized)"
                                        />
                                    </FormGroup>
                                </FormControl>

                                <FormControl component="fieldset" >
                                    <FormLabel component="legend">Calculation Window</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedCalculationPeriods.indexOf(IPeriodModel.OneMonth) >= 0} onChange={this.handleChangeCalculationPeriod} name={IPeriodModel.OneMonth} />}
                                            label="1 Month"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedCalculationPeriods.indexOf(IPeriodModel.OneYear) >= 0} onChange={this.handleChangeCalculationPeriod} name={IPeriodModel.OneYear} />}
                                            label="1 Year"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedCalculationPeriods.indexOf(IPeriodModel.TwoYears) >= 0} onChange={this.handleChangeCalculationPeriod} name={IPeriodModel.TwoYears} />}
                                            label="2 Year"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedCalculationPeriods.indexOf(IPeriodModel.ThreeYears) >= 0} onChange={this.handleChangeCalculationPeriod} name={IPeriodModel.ThreeYears} />}
                                            label="3 Year"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid container={true} item={true} direction="column" xs={4} spacing={3}>
                                <FormControl component="fieldset" >
                                    <FormLabel component="legend">Benchmark</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.benchmarkIsShow} onChange={this.handleChangeBenchmark} name={"benchmark"} />}
                                            label="Benchmark"
                                        />
                                    </FormGroup>
                                </FormControl>

                                <FormControl component="fieldset" >
                                    <FormLabel component="legend">Level of Confidence</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedLevelOfConfidences.indexOf(ILevelOfConfidenceModel.L900) >= 0} onChange={this.handleChangeLevelOfConfidence} name={ILevelOfConfidenceModel.L900} />}
                                            label="90%"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedLevelOfConfidences.indexOf(ILevelOfConfidenceModel.L950) >= 0} onChange={this.handleChangeLevelOfConfidence} name={ILevelOfConfidenceModel.L950} />}
                                            label="95%"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedLevelOfConfidences.indexOf(ILevelOfConfidenceModel.L990) >= 0} onChange={this.handleChangeLevelOfConfidence} name={ILevelOfConfidenceModel.L990} />}
                                            label="99%"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedLevelOfConfidences.indexOf(ILevelOfConfidenceModel.L995) >= 0} onChange={this.handleChangeLevelOfConfidence} name={ILevelOfConfidenceModel.L995} />}
                                            label="99.5%"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedLevelOfConfidences.indexOf(ILevelOfConfidenceModel.L999) >= 0} onChange={this.handleChangeLevelOfConfidence} name={ILevelOfConfidenceModel.L999} />}
                                            label="99.9%"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>


            </DetailPanel>
        );

    }

    private legendRoot(props: any) {
        const { style, ...restProps } = props;
        return <Legend.Root {...restProps} style={legendStyles} />
    };
    private legendItem(props: any) {
        const { style, ...restProps } = props;
        return <Legend.Item {...restProps} style={legendItemStyles} />
    };

    private isShowSerie(item: IBacktestingSerieModel) {
        const {
            selectedStatisticTypes,
            selectedScopes,
            selectedHoldingPeriods,
            selectedCalculationPeriods,
            selectedLevelOfConfidences
        } = this.state;
        return (selectedStatisticTypes as string[]).indexOf(item.type) >= 0
            && selectedScopes.indexOf(item.scope) >= 0
            && selectedHoldingPeriods.indexOf(item.holdingPeriod) >= 0
            && selectedCalculationPeriods.indexOf(item.calculationPeriod) >= 0
            && ((item.levelOfConfidence && selectedLevelOfConfidences.indexOf(item.levelOfConfidence) >= 0) || !item.levelOfConfidence)
    }

    private handleChangeStatisticType(event: any) {
        const selected: IHistoricalValueTypeModel[] = [...this.state.selectedStatisticTypes];
        if (event.target.checked) {
            selected.push(event.target.name as IHistoricalValueTypeModel);
        } else {
            selected.splice(selected.indexOf(event.target.name), 1);
        }
        const newState: Pick<IState, "selectedStatisticTypes"> = { selectedStatisticTypes: selected };
        this.setState(newState);
    };

    private handleChangeScope(event: any) {
        const selected: IStatisticScopeModel[] = [...this.state.selectedScopes];
        if (event.target.checked) {
            selected.push(event.target.name as IStatisticScopeModel);
        } else {
            selected.splice(selected.indexOf(event.target.name), 1);
        }
        const newState: Pick<IState, "selectedScopes"> = { selectedScopes: selected };
        this.setState(newState);
    }
    private handleChangeHoldingPeriod(event: any) {
        const selected: IPeriodModel[] = [...this.state.selectedHoldingPeriods];
        if (event.target.checked) {
            selected.push(event.target.name as IPeriodModel);
        } else {
            selected.splice(selected.indexOf(event.target.name), 1);
        }
        const newState: Pick<IState, "selectedHoldingPeriods"> = { selectedHoldingPeriods: selected };
        this.setState(newState);
    }
    private handleChangeCalculationPeriod(event: any) {
        const selected: IPeriodModel[] = [...this.state.selectedCalculationPeriods];
        if (event.target.checked) {
            selected.push(event.target.name as IPeriodModel);
        } else {
            selected.splice(selected.indexOf(event.target.name), 1);
        }
        const newState: Pick<IState, "selectedCalculationPeriods"> = { selectedCalculationPeriods: selected };
        this.setState(newState);
    }
    private handleChangeBenchmark(event: any) {
        const newState: Pick<IState, "benchmarkIsShow"> = { benchmarkIsShow: event.target.checked };
        this.setState(newState);
    }
    private handleChangeLevelOfConfidence(event: any) {
        const selected: ILevelOfConfidenceModel[] = [...this.state.selectedLevelOfConfidences];
        if (event.target.checked) {
            selected.push(event.target.name as ILevelOfConfidenceModel);
        } else {
            selected.splice(selected.indexOf(event.target.name), 1);
        }
        const newState: Pick<IState, "selectedLevelOfConfidences"> = { selectedLevelOfConfidences: selected };
        this.setState(newState);
    }
    private handleBack() {
        this.props.navigationNavigate(undefined);
    }
}
function LegendLabel(props: Legend.LabelProps & { style?: any }) {
    const { style, ...restProps } = props;
    if (typeof props.text === "string") {
        const serie = JSON.parse(props.text) as {
            type: keyof (typeof statisticTypeLabel),
            scope: keyof (typeof ScopeLabel),
            holdingPeriod: keyof (typeof PeriodLabel),
            calculationPeriod: keyof (typeof PeriodLabel),
            levelOfConfidence?: keyof (typeof LevelOfConfidenceLabel)
        };
        const label = `${statisticTypeLabel[serie.type]} | ${ScopeLabel[serie.scope]} | ${PeriodLabel[serie.holdingPeriod]} | ${PeriodLabel[serie.calculationPeriod]}${serie.levelOfConfidence ? ' | ' + LevelOfConfidenceLabel[serie.levelOfConfidence] : ''}`;
        return <Legend.Label {...restProps} style={legendLabelStyles} text={label} />
    }
    return <></>
};

export default ValueAtRiskDetail;

