import * as React from "react";
import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import { ICounterpartyTypeModel, IFeatureModel, IInvestorRelationshipModel, IRoleRelationshipModel, IUniverseScopeTypeModel, RelationshipModel } from "proxy/apiProxy";
import { FormikProps, Formik, FormikHelpers } from 'formik';
import RelationshipData from "./RelationshipData";
import AccessManagement from "./AccessManagement";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getEnumLabels, GrantRequest, oProps } from "tools/lib/utility";
import KeyRemoveIcon from "mdi-material-ui/KeyRemove";
import { IActionButton } from "tools/components/FabContainer";
import { IGetEntitySummary } from "reducers/Reference";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { getEntityName } from "tools/lib/modelUtils";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { getRelationshipTypeLabel } from "./getRelationshipTypeLabel";
import CustomViewDatas from "./CustomViewDatas";
import useScopeButtonsMenu from "components/global/useScopeButtonsMenu";
import MonitoringResultGroups from "components/global/MonitoringResults";
import InvestorOperations from "./InvestorOperations";
import { roleCollaborationTypes, roleDomains } from "./FormInterface";
import { ProcessExecutionList } from "features/ProcessExecution/ProcessExecutionList";
import NotebookCheckOutlineIcon from "mdi-material-ui/NotebookCheckOutline";

const counterpartyTypes = getEnumLabels(ICounterpartyTypeModel);

// const MyComp: React.FunctionComponent<{ test: string, me: number }> = ({ test, me }) => <div>{{ test }}:{{ me }}</div>
// function MyComp2({ test, me }: React.PropsWithChildren<{ test: string, me: number }>) {
//     return <div>{{ test }}:{{ me }}</div>;
// }

// type IRoleRelationshipData = Omit<IRelationshipData, "relationship"> & { relationship: IRoleRelationshipModel };
export default function Relationship() {
    const {
        relationshipSave,
        relationshipDeleteCurrent,
        relationshipLoadedEntity,
        relationshipLoadedPortfolio,

        relationshipLoadedRelationship,
        relationshipCustomScreenDataSubmit,
        relationshipMonitoringResultLoad,
        relationshipAddSecurityInDictionary
    } = useReduxActions("relationship");
    const { navigationNavigate } = useReduxActions("navigation");
    const { classificationTypes: investorClassificationTypes } = useReduxSelections("investorClassificationType");
    const { classificationTypes: roleRelationshipClassificationTypes } = useReduxSelections("roleRelationshipClassificationType");
    const {
        current,
        loading,
        saving,
        dictionaries,
        customScreenDataLoading,
        customScreenDatas,
        customScreens,
        customScreenDataSubmitting,
        monitoringResults,
        processExecutionsLoading,
        processExecutions = []
    } = useReduxSelections("relationship");
    const { macroScriptList } = useReduxSelections("macroScript");
    const monitoringMacros = React.useMemo(() => {
        if (!current) {
            return [];
        }
        const sco = (function () {
            switch (current?.type) {
                case "CounterpartyRelationshipModel": return IUniverseScopeTypeModel.Counterparty;
                case "RoleRelationshipModel": return IUniverseScopeTypeModel.Role;
                case "InvestorRelationshipModel": return IUniverseScopeTypeModel.Investor;
                default: return IUniverseScopeTypeModel.Role
            }
        })();
        return macroScriptList.filter(i =>
            (i.type === "MonitoringMacroScriptModel" || i.type === "MonitoringMacroScriptSummaryModel")
            && i.singleScope === sco);
    }, [current, macroScriptList]);
    const isGranted = useGrants();
    const handleNewExecutionProcess = React.useCallback(() => {
        navigationNavigate({ screenKey: "ProcessExecutions", sectionKey: "detail", parameters: { type: "RelationshipProcessExecutionModel", targetId: current?.id ?? 0 } });
    }, [current?.id, navigationNavigate])

    const { referenceCountries = {}, referenceCurrencies = {} } = useReduxSelections("reference");
    const { navigationActiveScreen: navigationActiveUrlNode } = useReduxSelections("navigation");

    // const formValues: IRelationshipData | undefined = React.useMemo(() => {
    //     if (!current) {
    //         return undefined;
    //     }
    //     const ret: IRelationshipData = {
    //         relationship: current,
    //         dueDiligence: { execution: dueDiligence?.execution, files: {} },
    //     };
    //     return ret;
    // }, [current, dueDiligence]);
    const extraReportActionButtons = useScopeButtonsMenu((function () {
        switch (current?.type) {
            case "CounterpartyRelationshipModel": return IUniverseScopeTypeModel.Counterparty;
            case "RoleRelationshipModel": return IUniverseScopeTypeModel.Role;
            case "InvestorRelationshipModel": return IUniverseScopeTypeModel.Investor;
            default: return IUniverseScopeTypeModel.Role
        }
    })(), current?.id);
    // const formValues = React.useMemo(() => current ? { relationship: current, dueDiligenceFiles: {} } as IRelationshipData : undefined, [current])

    const handleBack = () => navigationNavigate(undefined);
    if (!current) {
        return <DetailPanelLoading onBackClick={handleBack} tabNumber={3} hasSubTitle={false} />;
    }
    const handleLoadResult = (id: number) => relationshipMonitoringResultLoad({ id, targetId: current.id });
    const { entities } = dictionaries;
    const handleDelete = () => {
        const { id, type } = current;
        if (id) {
            relationshipDeleteCurrent({ id, type });
        }
    }
    const handleTabValueChanged = (value: any) => {
        const tabValue = value as string;
        navigationNavigate({ sectionKey: tabValue });
    }

    const handleSubmit = (values: RelationshipModel, { setSubmitting }: FormikHelpers<RelationshipModel>) => {
        relationshipSave(values);
        setSubmitting(false);
    }
    // const handleResponsibleSummaryChanged = (roleSummary: IGetRelationshipSummary | undefined) => {
    //     if (roleSummary) {
    //         relationshipLoadedRelationship(roleSummary);
    //     }
    // }
    const handleEntitySummaryChanged = (entitySummary: IGetEntitySummary | undefined) => {
        if (entitySummary) {
            relationshipLoadedEntity(entitySummary);
        }
    }
    const handleDataSubmit = (customScreenId: number, values: any) => relationshipCustomScreenDataSubmit({ id: current.id, type: current.type, customScreenId, values });
    const canEditCustomData = (function () {
        switch (current.type) {
            case "CounterpartyRelationshipModel": return isGranted(IFeatureModel.CounterpartyRelationshipCustomDataWrite);
            case "InvestorRelationshipModel": return isGranted({ feature: IFeatureModel.InvestorRelationshipCustomDataWrite, investorId: current.id });
            case "RoleRelationshipModel": return isGranted(IFeatureModel.RoleRelationshipCustomDataWrite);
        }
    })();

    return <Formik onSubmit={handleSubmit} initialValues={current} enableReinitialize={true} validateOnMount={true}>{renderForm}</Formik>;

    function renderForm({ dirty, isValid, submitForm, values, setFieldValue, validateForm }: FormikProps<RelationshipModel>) {

        if (!current) {
            return;
        }

        const { id, entityId } = values;
        const title = !!id ? ((getEntityName(entities[entityId])) ?? "") : `New ${getRelationshipTypeLabel(values.type)} relationship`;
        const tabValue = navigationActiveUrlNode?.activeSectionKey;
        const tabs: ISubMenu[] = [{
            label: "Data",
            value: "detail"
        }];
        if (!!monitoringMacros.length) {
            tabs.push({
                label: "Dashboard",
                value: "dashboard"
            });
        }
        if (values?.id) {
            tabs.push({
                label: "Processes",
                value: "processes"
            });
        }
        if (values.type === "RoleRelationshipModel") {
            tabs.push({
                label: "Permissions",
                value: "permissions"
            });
        }
        if (values.type === "InvestorRelationshipModel") {
            tabs.push({
                label: "Commitments",
                value: "commitments"
            });
        }
        if (values?.id && !!customScreens && customScreens.length) {
            tabs.push({
                label: "Custom Data",
                value: "customViews"
            });
        }

        const handleSave = () => submitForm();

        const [deleteFeature, writeFeature] = (function (): [GrantRequest, GrantRequest, GrantRequest, GrantRequest] {
            switch (values?.type) {
                case "CounterpartyRelationshipModel": return [IFeatureModel.CounterpartyRelationshipDelete, IFeatureModel.CounterpartyRelationshipWrite, IFeatureModel.CounterpartyRelationshipDueDiligenceWrite, IFeatureModel.CounterpartyRelationshipDueDiligenceApprove];
                case "InvestorRelationshipModel": return [
                    { feature: IFeatureModel.InvestorRelationshipDelete, investorId: values?.id ?? 0 },
                    { feature: IFeatureModel.InvestorRelationshipWrite, investorId: values?.id ?? 0 },
                    { feature: IFeatureModel.InvestorRelationshipDueDiligenceWrite, investorId: values?.id ?? 0 },
                    { feature: IFeatureModel.InvestorRelationshipDueDiligenceApprove, investorId: values?.id ?? 0 },
                ];
                case "RoleRelationshipModel": return [IFeatureModel.RoleRelationshipDelete, IFeatureModel.RoleRelationshipWrite, IFeatureModel.RoleRelationshipDueDiligenceWrite, IFeatureModel.RoleRelationshipDueDiligenceApprove];
            }
        })();

        // LibraryAddCheckIcon
        const extraActionButtons: IActionButton[] = [...extraReportActionButtons, {
            label: "Add Process Execution",
            icon: NotebookCheckOutlineIcon,
            onClick: handleNewExecutionProcess,
            feature: [IFeatureModel.RoleRelationshipDueDiligenceWrite, IFeatureModel.CounterpartyRelationshipDueDiligenceWrite, IFeatureModel.InvestorRelationshipDueDiligenceWrite]//,
            // actions: [{
            //     label: "New Process Execution",
            //     onClick: handleRenewCheckDueDiligence,
            //     icon: CheckCircleOutlineIcon,
            //     feature: IFeatureModel.EntityDueDiligenceWrite,
            //     disabled: !relatedProcesses.filter(i => i.type === "EntityChecksProcessModel").length
            // }]
        }];
        const handleClearRolePermissions = () => {
            setFieldValue(oProps<IRoleRelationshipModel>().path("permissions"), []);
        }

        if (current.type === "RoleRelationshipModel" && tabValue === "permissions") {
            extraActionButtons.unshift({
                label: "Remove All Permissions",
                onClick: handleClearRolePermissions,
                icon: KeyRemoveIcon,
                feature: IFeatureModel.RoleRelationshipWrite
            });
        }
        const subTitle = computeSubTitle();
        return <WriteAccess value={writeFeature}><DetailPanel
            isQuerying={loading || saving || customScreenDataLoading || customScreenDataSubmitting || processExecutionsLoading}
            title={title}
            subTitle={subTitle}
            canSave={dirty && isValid}
            onSaveClick={handleSave}
            canDelete={!!id}
            onDeleteClick={handleDelete}
            actions={extraActionButtons}
            onBackClick={handleBack}
            badge={!id ? "new" : undefined}
            saveAllowed={writeFeature}
            deleteAllowed={deleteFeature}
            tabs={tabs}
            tabValue={tabValue}
            onTabValueChanged={handleTabValueChanged}>
            {(tabValue === "detail") && <RelationshipData
                investorClassificationTypes={investorClassificationTypes}
                roleRelationshipClassificationTypes={roleRelationshipClassificationTypes}
                onRelationshipLoaded={relationshipLoadedRelationship}
                referenceCountries={referenceCountries}
                referenceCurrencies={referenceCurrencies}
                onEntityLoaded={handleEntitySummaryChanged}
                onPortfolioLoaded={relationshipLoadedPortfolio}
                dictionaries={dictionaries} />}
            {(tabValue === "processes") && <ProcessExecutionList
                processExecutions={processExecutions}
                dictionaries={dictionaries} />}
            {(tabValue === "dashboard" && !!monitoringMacros.length) && <MonitoringResultGroups
                monitoringMacros={monitoringMacros}
                onLoadResult={handleLoadResult}
                resultGroups={monitoringResults} />}
            {(tabValue === "commitments") && <InvestorOperations
                dictionaries={dictionaries}
                onSecurityLoaded={relationshipAddSecurityInDictionary}
                onPortfolioLoaded={relationshipLoadedPortfolio}
                referenceCurrencies={referenceCurrencies}
                formFieldName={oProps<IInvestorRelationshipModel>().path("investorOperations")} />}
            {(tabValue === "customViews" && !!customScreenDatas && !!customScreens?.length) && <CustomViewDatas
                canEdit={canEditCustomData}
                dictionaries={dictionaries}
                customScreenLoading={customScreenDataLoading}
                dataSubmitting={customScreenDataSubmitting}
                customScreens={customScreens}
                onDataSubmit={handleDataSubmit}
                customScreenDatas={customScreenDatas} />}
            {(tabValue === "permissions") && <AccessManagement formFieldName={oProps<IRoleRelationshipModel>().path("permissions")} />}
        </DetailPanel></WriteAccess>;
        function computeSubTitle() {
            switch (values.type) {
                case "RoleRelationshipModel":
                    return `${roleDomains[values.domain]}-${roleCollaborationTypes[values.collaborationType]}`;
                case "CounterpartyRelationshipModel":
                    if (values.counterpartyType) {
                        return counterpartyTypes[values.counterpartyType];
                    }
                    return "Counterparty";
                default:
                    return "Investor";
            }
        }
    }
}
