import FieldBox from "tools/components/FieldBox";
import { RelationshipSummaryModel, ICurrencyModel, EntitySummaryModel, IOperationTypeModel, PortfolioSummaryModel, SecuritySummaryModel, PortfolioTransactionModel, ITransactionTypeModel, IPortfolioFxTransactionModel, IPortfolioSecurityTransactionModel } from "proxy/apiProxy";
import { oProps, IDictionary, getEnumLabels } from "tools/lib/utility";
import ManagedPortfolioSummary from "components/summaries/ManagedPortfolioSummary";
import FormMultiCulturedTextField from "tools/fieldComponents/FormMultiCulturedTextField";
import SecuritySummary from "components/summaries/SecuritySummary";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FormTextField from "tools/fieldComponents/FormTextField";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import { IGetRelationshipSummary, IGetPortfolioSummary, IGetSecuritySummary } from "reducers/Reference";
import SecuritySelectField from "components/searchers/SecuritySelectField";
import RelationshipSelectField from "components/searchers/RelationshipSelectField";
import RelationshipSummary from "components/summaries/RelationshipSummary";
import ManagedPortfolioSelectField from "components/searchers/ManagedPortfolioSelectField";
import { CurrencySelectField } from "components/searchers/CurrencySelectField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";

export interface IPortfolioTransactionDataProps {
    transaction: PortfolioTransactionModel;
    dictionaries: {
        portfolios: Record<string | number, PortfolioSummaryModel>;
        securities: Record<string | number, SecuritySummaryModel>;
        entities: Record<string | number, EntitySummaryModel>;
        relationships: Record<string | number, RelationshipSummaryModel>;
    }
    currencies: IDictionary<ICurrencyModel>;
    onSecurityLoaded: (security: IGetSecuritySummary) => void;
    onCounterpartyRelationshipLoaded: (found: IGetRelationshipSummary) => void;
    onPortfolioLoaded: (found: IGetPortfolioSummary) => void;
}
const operationTypes = getEnumLabels(IOperationTypeModel);
const transactionTypes = getEnumLabels(ITransactionTypeModel);
export default function PortfolioTransactionData({ onSecurityLoaded, onCounterpartyRelationshipLoaded, onPortfolioLoaded, currencies, transaction, dictionaries }: IPortfolioTransactionDataProps) {
    const { portfolios, securities } = dictionaries;
    return <FieldBox display="flex" flexDirection="column">
        <ManagedPortfolioSelectField name={oProps<PortfolioTransactionModel>().path("portfolioId")} onSelected={onPortfolioLoaded} required />
        <ManagedPortfolioSummary name={oProps<PortfolioTransactionModel>().path("portfolioId")} portfolios={portfolios} currencies={currencies} />
        <FormMultiCulturedTextField name={oProps<PortfolioTransactionModel>().path("comment")} label="Comment" multiline={true} />
        <FormTextField name={oProps<PortfolioTransactionModel>().path("description")} label="Description" required={true} />
        <FormCheckBoxField name={oProps<PortfolioTransactionModel>().path("canceled")} label="Cancelled" />
        <FieldBox display="flex" flexDirection="row">
            <FieldBox display="flex" flexDirection="column">
                <FormDatePickerField label="Trade Date" name={oProps<PortfolioTransactionModel>().path("tradeDate")} required={true} />
                <FormDatePickerField label="Settlement Date" name={oProps<PortfolioTransactionModel>().path("settlementDate")} />
            </FieldBox>
            <FieldBox display="flex" flexDirection="column">
                <FormTextField label="Code" name={oProps<PortfolioTransactionModel>().path("transactionCode")} required={true} />
                <FormDatePickerField label="Nav Date" name={oProps<PortfolioTransactionModel>().path("navDate")} required={true} />
            </FieldBox>
        </FieldBox>
        <RelationshipSelectField type="CounterpartyRelationshipModel" label="Broker" name={oProps<PortfolioTransactionModel>().path("brokerId")} onSelected={onCounterpartyRelationshipLoaded} />
        <RelationshipSummary name={oProps<PortfolioTransactionModel>().path("brokerId")} {...dictionaries} />
        {(transaction.type === "PortfolioSecurityTransactionModel") && <SecurityTransactionData onSecurityLoaded={onSecurityLoaded} securityRelated={transaction} currencies={currencies} securities={securities} />}
        {(transaction.type === "PortfolioFxTransactionModel") && <FxTransactionData />}
    </FieldBox>;
}

function FxTransactionData() {
    return <FieldBox display="flex" flexDirection="row">
        <FieldBox display="flex" flexDirection="column">
            <FormTextField label="Purchase Amount" name={oProps<IPortfolioFxTransactionModel>().path("purchaseAmount")} isNumber={true} required={true} />
            <CurrencySelectField name={oProps<IPortfolioFxTransactionModel>().path("purchaseCurrencyId")} label="Purchase Currency Amount" required={true} />
        </FieldBox>
        <FieldBox display="flex" flexDirection="column">
            <FormTextField label="Sold Amount" name={oProps<IPortfolioFxTransactionModel>().path("soldAmount")} isNumber={true} required={true} />
            <CurrencySelectField name={oProps<IPortfolioFxTransactionModel>().path("soldCurrencyId")} label="Sold Currency Amount" required={true} />
            <FormTextField label="Fx Rate" name={oProps<IPortfolioFxTransactionModel>().path("fxRate")} isNumber={true} required={true} />
        </FieldBox>
    </FieldBox>
}

interface ISecurityTransactionDataProps {
    securityRelated: IPortfolioSecurityTransactionModel;
    currencies: IDictionary<ICurrencyModel>;
    securities: IDictionary<SecuritySummaryModel>;
    onSecurityLoaded: (security: IGetSecuritySummary) => void;
}
function SecurityTransactionData({ currencies, securities, securityRelated: { securityId }, onSecurityLoaded }: ISecurityTransactionDataProps) {
    return <>
        <SecuritySelectField required={true} name={oProps<IPortfolioSecurityTransactionModel>().path("securityId")} onSelected={onSecurityLoaded} />
        <SecuritySummary name={oProps<IPortfolioSecurityTransactionModel>().path("securityId")} currencies={currencies} securities={securities} />
        <FieldBox display="flex" flexDirection="row">
            <FieldBox display="flex" flexDirection="column">
                <FormSimpleSelectField label="Operation Type" options={operationTypes} name={oProps<IPortfolioSecurityTransactionModel>().path("operationType")} required={true} />
                <FormTextField label="Quantity" name={oProps<IPortfolioSecurityTransactionModel>().path("quantity")} required={true} />
                <FormTextField label="Gross Amount in Portfolio Currency" name={oProps<IPortfolioSecurityTransactionModel>().path("grossAmountInPortfolioCcy")} />
                <FormTextField label="Net Amount in Portfolio Currency" name={oProps<IPortfolioSecurityTransactionModel>().path("netAmountInPortfolioCcy")} />
                <FormDatePickerField label="Value Date" name={oProps<IPortfolioSecurityTransactionModel>().path("valueDate")} required={true} />
            </FieldBox>
            <FieldBox display="flex" flexDirection="column">
                <FormSimpleSelectField label="Transaction Type" options={transactionTypes} name={oProps<IPortfolioSecurityTransactionModel>().path("transactionType")} required={true} />
                <FormTextField label="Price in Security Currency" name={oProps<IPortfolioSecurityTransactionModel>().path("priceInSecurityCcy")} required={true} />
                <FormTextField label="Gross Amount in Security Currency" name={oProps<IPortfolioSecurityTransactionModel>().path("grossAmountInSecurityCcy")} />
                <FormTextField label="Net Amount in Security Currency" name={oProps<IPortfolioSecurityTransactionModel>().path("netAmountInSecurityCcy")} />
                <FormTextField label="Fees in Security Currency" name={oProps<IPortfolioSecurityTransactionModel>().path("feesInSecurityCcy")} />
            </FieldBox>
        </FieldBox>
    </>;
}