import * as React from "react";
import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IColumnState, IGridState } from "tools/components/ExtendedGrid";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { ITradeDateSummaryModel, ITradeStatusModel } from "proxy/apiProxy";
import { splitPascalCase, today } from "tools/lib/utility";
import TodayIcon from '@material-ui/icons/Today';
import { IActionButton } from "tools/components/FabContainer";

function formatDate(d: Date) {
    var month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [year, month, day].join("");
}
// const tradeStatus = getEnumLabels(ITradeStatusModel);
const tradeStatuses = Object.keys(ITradeStatusModel).map(k => ({ name: k as ITradeStatusModel, label: splitPascalCase(ITradeStatusModel[k as keyof (typeof ITradeStatusModel)]) }));
const statePosition: Record<ITradeStatusModel, number> = {
    [ITradeStatusModel.New]: 1,
    [ITradeStatusModel.CheckedAndSending]: 2,
    [ITradeStatusModel.CheckedAndSent]: 3,
    [ITradeStatusModel.Reconciling]: 4,
    [ITradeStatusModel.Reconciled]: 5,
    [ITradeStatusModel.Settled]: 6,
};
export default function TradeDates() {
    const { all: tradeDates = [], allLoading } = useReduxSelections("tradeDates");
    // const { navigationActiveSiteMapNode } = useReduxSelections("navigation");
    const { navigationNavigate } = useReduxActions("navigation");
    const { columns, state } = React.useMemo(() => {
        const cs = [
            { name: "Date", title: "Date", getCellValue: (row: ITradeDateSummaryModel) => row.date, columnType: "date" },
            ...tradeStatuses
                .sort(({ name: nameA }, { name: nameB }) => statePosition[nameA] - statePosition[nameB])
                .map(({ name, label }) => ({
                    name,
                    title: label,
                    getCellValue: (row: ITradeDateSummaryModel) => row.statuses[name],
                    columnType: "integer",
                    filteringEnabled: true
                }))
        ] as IColumnDefinition[];
        const state = {
            "Date": { width: 150, sortingPosition: -1 },
            ...tradeStatuses.reduce((a, v) => {
                a[v.name] = { width: 170 };
                return a;
            }, {} as Record<string, IColumnState>)
        } as IGridState;

        return {
            columns: cs,
            state
        };
    }, []);
    const getRowKey = (row: ITradeDateSummaryModel) => formatDate(row.date);
    const handleRowClick = (row: ITradeDateSummaryModel) => navigationNavigate({ sectionKey: "detail", parameters: { tradedate: formatDate(row.date) } });
    const handleTradeToday = () => navigationNavigate({ sectionKey: "detail", parameters: { tradedate: formatDate(today()) } });
    const extraActions: IActionButton[] = [{
        label: "Today",
        icon: TodayIcon,
        onClick: handleTradeToday
    }];

    return <SearchPanel title="Trading activity" isQuerying={allLoading} actions={extraActions}>
        <ExtendedGrid
            getRowId={getRowKey}
            columns={columns}
            rows={tradeDates}
            onRowClick={handleRowClick}
            initialState={state}
            userCanGroup={true}
            defaultExportFileName={`TradingActivity.xlsx`} />
    </SearchPanel>
}
