import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import BatchInstanceData from "./BatchInstanceData";
import BatchInstanceTaskDetail from "./BatchInstanceTaskDetail";
import BatchScriptVisualization from "../batch/BatchScriptVisualization";
import { formatDateTime } from "tools/lib/utility";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import React from "react";
import { parseScript } from "components/batch/batchParser";

export default function BatchInstance() {
    const { batchInstanceCurrent, batchInstanceLoading, batchInstanceCurrentTaskId } = useReduxSelections("batchInstance");
    // const { batchInstanceTaskNodeTracesLoad } = useReduxActions("batchInstance");
    const { batchInstanceLoad } = useReduxActions("batchInstance");
    const { navigationActiveScreen: navigationActiveUrlNode } = useReduxSelections("navigation");
    const { navigationNavigate } = useReduxActions("navigation");
    const handleBack = () => navigationNavigate(undefined);
    const parsedScript = React.useMemo(() => {
        if (!batchInstanceCurrent?.executedScript) {
            return undefined;
        }
        return parseScript(batchInstanceCurrent.executedScript);
    }, [batchInstanceCurrent])
    const selectedTask = React.useMemo(() => {
        if (!batchInstanceCurrent || !batchInstanceCurrentTaskId) {
            return undefined;
        }
        const { tasks } = batchInstanceCurrent;
        var task = tasks.find(i => i.id === batchInstanceCurrentTaskId);
        if (!task) {
            return undefined;
        }
        return task;
    }, [batchInstanceCurrentTaskId, batchInstanceCurrent]);
    if (!batchInstanceCurrent) {
        return <DetailPanelLoading onBackClick={handleBack} tabNumber={2} />;
    }
    const title = `${batchInstanceCurrent.batch?.name ?? batchInstanceCurrent.comment ?? ""} - ${formatDateTime(batchInstanceCurrent.executionDateTime)}`;
    const tabValue = navigationActiveUrlNode?.activeSectionKey ?? "detail";
    const tabs = [{
        value: "detail",
        label: "Data"
    }, {
        value: "tasks",
        label: "Tasks"
    }] as ISubMenu[];
    if (!!selectedTask) {
        tabs.push({
            value: "task",
            label: `${selectedTask.name} execution plan`
        });
    }
    const handleRefresh = () => {
        if (batchInstanceCurrent?.id) {
            batchInstanceLoad(batchInstanceCurrent.id);
        }
    }
    const handleTaskClick = (taskId: number) => navigationNavigate({ sectionKey: "task", parameters: { id: batchInstanceCurrent?.id ?? 0, taskId } });
    // const handleTaskNodeClick = (nodeName: string) => {
    //     if (typeof (batchInstanceCurrentTaskIndex) !== "undefined" && batchInstanceCurrent) {
    //         batchInstanceTaskNodeTracesLoad({ taskId: batchInstanceCurrent.tasks[batchInstanceCurrentTaskIndex].id, nodeName });
    //     }
    // }
    const handleBatchClick = () => {
        if (batchInstanceCurrent?.batch?.id) {
            navigationNavigate({ screenKey: "BatchDefinitions", sectionKey: "detail", parameters: { id: batchInstanceCurrent?.batch?.id } })
        }
    }
    const handleTabValueChanged = (tabValue: any) => {
        const sectionKey = tabValue as string;
        if (sectionKey === "task") {
            navigationNavigate({ sectionKey: "task", parameters: { id: batchInstanceCurrent?.id ?? 0, taskIndex: batchInstanceCurrentTaskId } });
        }
        else {
            navigationNavigate({ sectionKey });
        }
    }
    return (
        <DetailPanel
            isQuerying={batchInstanceLoading}
            onRefreshClick={handleRefresh}
            title={title}
            subTitle={batchInstanceCurrent.type}
            tabs={tabs}
            tabValue={tabValue}
            noPadding={tabValue === "task"}
            onTabValueChanged={handleTabValueChanged}
            onBackClick={handleBack}>
            {tabValue === "detail" && <BatchInstanceData batchInstance={batchInstanceCurrent} onBatchClick={handleBatchClick} />}
            {tabValue === "tasks" && <BatchScriptVisualization parsedScript={parsedScript} executedTasks={batchInstanceCurrent.tasks} onTaskClick={handleTaskClick} />}
            {(tabValue === "task" && !!selectedTask) && <BatchInstanceTaskDetail task={selectedTask} />}
            {/* {(tabValue === "task" && typeof (batchInstanceCurrentTaskIndex) !== "undefined") && <BatchInstanceTaskDetail task={batchInstanceCurrent.tasks[batchInstanceCurrentTaskIndex]} onNodeClick={handleTaskNodeClick} />} */}
        </DetailPanel>
    );
}
