import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { useMemo } from 'react'
import { ActionFactories, IState } from "reducers";
import { GrantRequest, isGranted } from './utility';
// import { ActionFactories, IAnyAction, IState } from "reducers";


// type PayloadActionFactory<AT, PL> = (payload: PL) => { type: AT, payload: PL };
// type NoPayloadActionFactory<AT> = () => { type: AT, payload?: undefined };

// export function useReduxAction<T extends { [AT in keyof T]: NoPayloadActionFactory<AT> | PayloadActionFactory<AT, ReturnType<T[AT]>["payload"]> }, K extends keyof T>(actions: T, actionType: K)
//     : T[K] {
//     const dispatch = useDispatch()
//     return useMemo(
//         () => bindActionCreators(actions[actionType], dispatch) as T[K],
//         [dispatch]
//     );
// }
type ActionFactoriesType = typeof ActionFactories;
// type IStateType = typeof ActionFactories;
export function useReduxActions<K extends keyof ActionFactoriesType>(subStore: K): ActionFactoriesType[K] {
    const dispatch = useDispatch()
    return useMemo(
        () => bindActionCreators(ActionFactories[subStore], dispatch),
        [dispatch, subStore]
    );
}
export function useReduxSelections<K extends keyof IState>(subState: K): IState[K] {
    return useSelector<IState, IState[K]>(state => state[subState]);
}
// export function useActions<A, M extends ActionCreatorsMapObject<A>>(actions: M): M {
//     const dispatch = useDispatch()
//     return useMemo(
//         () => bindActionCreators(actions, dispatch),
//         [dispatch]
//     );
// }


export function useGrants() {
    const profile = useReduxSelections("app").currentUser;
    // ?.featuresOnSomeScopes ?? [];
    return (grantRequests: GrantRequest | GrantRequest[] | Record<string, GrantRequest>) => isGranted(profile, grantRequests);
}
