import { useReduxSelections } from "tools/lib/reduxStoreAccess";
import { ProcessExecutionList } from "./ProcessExecutionList";
import SearchPanel from "tools/components/SearchPanel";

export default function ProcessExecutions() {
    const { processExecutions = [], processExecutionAllLoading, dictionaries } = useReduxSelections("processExecution");

    return <SearchPanel
        title="Process Executions"
        isQuerying={processExecutionAllLoading}>
        <ProcessExecutionList
            showContextualColumns
            processExecutions={processExecutions}
            dictionaries={dictionaries} />
    </SearchPanel>
}