import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import CustomScreens from "./CustomScreens";
import CustomScreen from "./CustomScreen";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class MacroScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                slideDirection={"right"}
                component={CustomScreens}
                path={getSiteScreenUrl("CustomScreen")}
                exact={true} />
            <NavigationRoute
                slideDirection={"left"}
                component={CustomScreen}
                path={getSiteDetailScreenUrl("CustomScreen")} />
        </>
    }
}

export default MacroScreen;
