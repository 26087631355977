import { Epic } from "redux-observable";
import { forkJoin, merge, of } from "rxjs";
import { filter, map, mergeMap, share } from "rxjs/operators";
import { IGetNoteVersionsModel, INoteModel, notesApi, noteVersionsApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, changedNavigation } from "lib/rxJsUtility";
import { tryParseNumber } from "tools/lib/utility";
import { filterRoute } from "tools/lib/UrlDictionary";

export const loadNotes: Epic<IAnyAction>
    = (action$) => action$.pipe(
        mapToPayload("note", "noteLoadAll"),
        mergeMap(() => notesApi.getAllAsync({})),
        map(ActionFactories.note.noteLoadedAll));

export const onOpenScreenNote: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("Notes", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? 0),
        map(ActionFactories.note.noteLoad));

export const onOpenScreenNotes: Epic<IAnyAction>
    = action$ => {
        const changedScreen$ = action$.pipe(changedNavigation(({ screenKey }) => screenKey), share());
        return merge(
            changedScreen$.pipe(filterRoute("Notes"))
        ).pipe(map(() => ActionFactories.note.noteLoadAll()));
    };

export const loadNote: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("note", "noteLoad"),
            share()
        );
        const newNote$ = requestedId$.pipe(
            filter(id => !id),
            map(() => ({
                id: 0,
                classifications: {},
                code: "",
                label: "",
                content: {}
            } as INoteModel)),
            share());
        const existingNote$ = requestedId$.pipe(
            filter(id => !!id),
            mergeMap(id => notesApi.getAsync({ id })),
            share());
        // const newVersions$ = requestedId$.pipe(
        //     filter(id => !id),
        //     map(() => ({
        //         noteVersions: [],
        //         entities: {},
        //         portfolios: {},
        //         relationships: {},
        //         securities: {}
        //     } as IGetNoteVersionsModel)),
        //     share());
        // const existingVersions$ = requestedId$.pipe(
        //     filter(id => !!id),
        //     noteVersionsApi.getNoteVersionsAsync(id => ({ noteId: id })),
        //     onlyStatusOk(),
        //     share());
        return merge(
            merge(newNote$, existingNote$).pipe(map(ActionFactories.note.noteLoaded)),
            requestedId$.pipe(map(noteId => ActionFactories.note.noteVersionsLoad(noteId)))
        );
    };
export const loadNoteVersions: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("note", "noteVersionsLoad"),
            share()
        );
        const newVersions$ = requestedId$.pipe(
            filter(id => !id),
            map(() => ({
                noteVersions: [],
                entities: {},
                portfolios: {},
                relationships: {},
                securities: {}
            } as IGetNoteVersionsModel)),
            share());
        const existingVersions$ = requestedId$.pipe(
            filter(id => !!id),
            mergeMap(id => noteVersionsApi.getNoteVersionsAsync({ noteId: id })),
            share());
        return merge(newVersions$, existingVersions$).pipe(map(ActionFactories.note.noteVersionsLoaded));
    }
export const saveNote: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("note", "noteSave"),
        mergeMap(({ note, versionsToDelete, versionsToSave }) => {
            const noteSaved$ = of(note).pipe(
                mergeMap(model => notesApi.saveAsync({ model })),
                share());
            const versionsSaved$ = noteSaved$.pipe(
                map(newNote => versionsToSave.map(({ ...values }) => ({ ...values, noteId: newNote.id }))),
                mergeMap(model => noteVersionsApi.saveAllAsync({ model })));
            const versionsDeleted$ = of(versionsToDelete).pipe(
                mergeMap(noteVersionIds => noteVersionsApi.deleteAllAsync({ noteVersionIds }).then(() => noteVersionIds)));
            return forkJoin([
                noteSaved$,
                versionsSaved$,
                versionsDeleted$
            ]).pipe(map(([note, savedVersions, deletedVersionIds]) => ActionFactories.note.noteSaved({
                deletedVersionIds,
                note,
                savedVersions
            })));
        }));
export const deleteNote: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("note", "noteDeleteCurrent"),
            mergeMap(id => notesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.note.noteDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };

export const loadNoteVersionContent: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("note", "noteVersionContentLoad"),
        mergeMap(id => noteVersionsApi.getAsync({ id })),
        map(ActionFactories.note.noteVersionContentLoaded));
