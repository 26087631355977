import { Epic } from "redux-observable";
import { map, filter, mergeMap, share, first, tap } from "rxjs/operators";
import { reportTemplatesApi, IReportTemplateModel } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { changedNavigation, mapToPayload } from "lib/rxJsUtility";
import { todayString, tryParseNumber } from "tools/lib/utility";
import { merge } from "rxjs";
import { filterRoute, routeMatches } from "tools/lib/UrlDictionary";
import saveAs from "file-saver";

interface INewRequest {
    openType: "new";
}
interface IDuplicateRequest {
    openType: "duplicate";
    templateId: number;
}
interface ILoadRequest {
    openType: "load";
    templateId: number;
}

type IOpenRequest = INewRequest | IDuplicateRequest | ILoadRequest;
function translateOpenRequest(screen: string | undefined, detail: any): IOpenRequest | undefined {
    if (!detail || !screen) {
        return;
    }
    const templateId = tryParseNumber(detail?.id);
    if (typeof templateId === "number" && templateId !== 0) {
        return {
            openType: "load",
            templateId
        } as IOpenRequest;
    };
    const prefix = "duplicate";
    const id = detail.id as string;
    if (id.substr(0, prefix.length) === prefix) {
        const idToDuplicate = Number(id.substr(prefix.length));
        if (isNaN(idToDuplicate)) {
            return;
        }
        return {
            openType: "duplicate",
            templateId: idToDuplicate
        } as IOpenRequest;
    }
    return {
        openType: "new",
    } as IOpenRequest;
}

export const onOpenScreenTemplates: Epic<IAnyAction>
    = action$ => {
        const openRequest$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
            filter(i => routeMatches(i, "Templates", "detail")),
            map(({ screenKey, matchingSections }) => translateOpenRequest(screenKey, matchingSections?.detail)),
            filter(openRequest => !!openRequest),
            share()
        );
        const duplicateTemplate$ = openRequest$.pipe(
            map(openRequest => {
                if (openRequest?.openType === "duplicate") {
                    return openRequest.templateId
                }
                return;
            }),
            filter(id => !!id),
            map(id => ActionFactories.reportTemplate.reportTemplateLoadForDuplicate(id as number)));
        const newTemplate$ = openRequest$.pipe(
            filter(openRequest => openRequest?.openType === "new"),
            map(id => ActionFactories.reportTemplate.reportTemplateLoad(0)));
        const loadTemplate$ = openRequest$.pipe(
            map(openRequest => {
                if (openRequest?.openType === "load") {
                    return openRequest.templateId
                }
                return;
            }),
            filter(id => !!id),
            map(id => ActionFactories.reportTemplate.reportTemplateLoad(id as number)));

        return merge(
            duplicateTemplate$,
            newTemplate$,
            loadTemplate$,
        );
    }

// var file = new Blob([data], {type: 'application/pdf'});
// var fileURL = window.URL.createObjectURL(file);
// window.open(fileURL, "_blank");

export const onRequestAnalysis: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplate", "reportTemplateAnalyse"),
        mergeMap(content => reportTemplatesApi.analyzeTemplatePOSTAsync({ content: { content } })),
        map(ActionFactories.reportTemplate.reportTemplateAnalysed));

export const onLoadDataPreview: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplate", "reportTemplateDataPreviewLoad"),
        mergeMap(dataPreviewRequest => reportTemplatesApi.generateReferentialAsync({ dataPreviewRequest })),
        map(ActionFactories.reportTemplate.reportTemplateDataPreviewLoaded));

export const onOpenScreenTemplate: Epic<IAnyAction>
    = action$ => {
        const userLoaded$ = action$.ofType("applicationLoaded").pipe(share(), first()); // Must be loaded from start for templates to be shown in related screens
        const changedScreen$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey),
            filterRoute("Templates"));
        return merge(userLoaded$, changedScreen$)
            .pipe(map(ActionFactories.reportTemplate.reportTemplateLoadAll));
    }
export const loadReportTemplates: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplate", "reportTemplateLoadAll"),
        mergeMap(() => reportTemplatesApi.getAllAsync({})),
        map(ActionFactories.reportTemplate.reportTemplateLoadedAll));
export const deleteTemplate: Epic<IAnyAction>
    = action$ => {
        const deleted$ = action$.pipe(
            mapToPayload("reportTemplate", "reportTemplateDelete"),
            mergeMap(id => reportTemplatesApi.deleteAsync({ id }).then(() => id)),
            share());
        return merge(
            deleted$.pipe(map(ActionFactories.reportTemplate.reportTemplateDeleted)),
            deleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined)))
        );
    }
export const duplicateTemplate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplate", "reportTemplateLoadForDuplicate"),
        mergeMap(async id => Promise.all([
            reportTemplatesApi.getAsync({ id }),
            reportTemplatesApi.getTemplateContentAsync({ id })])),
        map(([{ id, name, code, ...definition }, { content }]) => ({ content, definition: { ...definition, id: 0, code: `${code}${todayString()}`, name: `${name} (Duplicate)` } })),
        map(ActionFactories.reportTemplate.reportTemplateLoaded));
export const loadTemplate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplate", "reportTemplateLoad"),
        filter(i => !!i),
        mergeMap(async id => Promise.all([
            reportTemplatesApi.getAsync({ id }),
            reportTemplatesApi.getTemplateContentAsync({ id })])),
        map(([definition, { content }]) => ({ content, definition })),
        map(ActionFactories.reportTemplate.reportTemplateLoaded));
export const newTemplate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplate", "reportTemplateLoad"),
        filter(i => !i),
        mergeMap(() => reportTemplatesApi.getEmptyTemplateAsync()),
        map(({ content }) => ({ content, definition: { id: 0, name: "", publishOnPortal: false } as IReportTemplateModel })),
        map(ActionFactories.reportTemplate.reportTemplateLoaded));
export const generateReport: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplate", "reportTemplateGenerate"),
        mergeMap(({ scopeType, ...pars }) => reportTemplatesApi.generateReportAsync(pars).then(file => ({ scopeType, file }))),
        tap(({ file: { blob, fileName } }) => saveAs(blob, fileName)),
        map(({ scopeType }) => ActionFactories.reportTemplate.reportTemplateGenerated(scopeType)));
export const saveReportTemplate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplate", "reportTemplateSave"),
        mergeMap(async ({ content, definition }) => {
            const saved = await reportTemplatesApi.saveAsync({ model: definition });
            await reportTemplatesApi.saveTemplateContentAsync({ id: saved.id, content: { content } });
            return { definition: saved, content };
        }),
        map(ActionFactories.reportTemplate.reportTemplateSaved));
