import produce from "immer";
import { IDevelopmentItemTypeModel, DevelopmentItemSummaryModel, IMacroScriptErrorModel, IEntityMetadataModel, IExecutionStatusResultModel, IMacroModel, IMacroTypeModel, ITaskDefinitionStructureModel, IGetDevelopmentItemsModel, EntitySummaryModel, IDashboardMacroDevelopmentItemModel, IEtlMacroDevelopmentItemModel, IFileConnectorDevelopmentItemModel, IFileConsumerMacroDevelopmentItemModel, IFileProducerMacroDevelopmentItemModel, IPipelineTaskDevelopmentItemModel, IQuestionnaireDevelopmentItemModel, IReportTemplateDevelopmentItemModel, IGetDevelopmentItemModel, DevelopmentItemModel, MacroSymbolModel, IExecuteOnLoadModel, IDashboardResultModel, IExecuteQuestionnaireOnCompleteModel, IExecuteReportModel, IDevelopmentItemsRunReportGenerateParameters } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
// import { IGitFile } from "tools/lib/octokitHelper";
export interface ITypedMacro extends IMacroModel {
    type: IMacroTypeModel
};

export interface IMacroDiagnostic {
    errors?: IMacroScriptErrorModel[];
    description?: ITaskDefinitionStructureModel;
    returnType?: MacroSymbolModel;
}
type TypeReplace<T, T1, T2> = {
    [P in keyof T]: T[P] extends (T1 | undefined)
    ? (T2 | undefined)
    : (T[P] extends T1
        ? T2
        : T[P])
    // : TypeReplace<T[P], T1, T2>)
};
type MacroWithDiagnostic<T> = TypeReplace<T, IMacroModel, IMacroDiagnostic>;
// type MacroWithChecksAndSchema<T> = {
//     [P in keyof T]: T[P] extends (IMacroModel | undefined)
//     ? (Macro | undefined)
//     : (T[P] extends IMacroModel
//         ? Macro
//         : MacroWithChecksAndSchema<T[P]>)
// };

export type QuestionnaireDiagnostic = MacroWithDiagnostic<IQuestionnaireDevelopmentItemModel>;
export type ReportTemplateDiagnostic = MacroWithDiagnostic<IReportTemplateDevelopmentItemModel>;
export type EtlMacroDiagnostic = MacroWithDiagnostic<IEtlMacroDevelopmentItemModel>;
export type DashboardMacroDiagnostic = MacroWithDiagnostic<IDashboardMacroDevelopmentItemModel>;
export type FileConsumerMacroDiagnostic = MacroWithDiagnostic<IFileConsumerMacroDevelopmentItemModel>;
export type FileProducerMacroDiagnostic = MacroWithDiagnostic<IFileProducerMacroDevelopmentItemModel>;
export type DevelopmentItemDiagnostic = QuestionnaireDiagnostic
    | ReportTemplateDiagnostic
    | EtlMacroDiagnostic
    | DashboardMacroDiagnostic
    | FileConsumerMacroDiagnostic
    | FileProducerMacroDiagnostic
    | IFileConnectorDevelopmentItemModel
    | IPipelineTaskDevelopmentItemModel;


// export interface IDevelopmentItemValidateScript {
//     script: IMacroModel;
//     type: IMacroTypeModel;
// }
export interface IDevelopmentItemDiagnostic {
    errors: IMacroScriptErrorModel[];
    description?: ITaskDefinitionStructureModel;
    type: IMacroTypeModel;
    returnType?: MacroSymbolModel;
}
// export interface IDataProcessorDevelopmentItemExecuteScript {
//     id: number;
//     targetId?: number;
//     type: IDataProcessorDevelopmentItemModel["type"];
// }
// export interface IFileProcessorDevelopmentItemExecuteScript {
//     id: number;
//     type: IFileProcessorDevelopmentItemModel["type"];
//     file: IFileModel;
// }
// export interface IFileRetrieverDevelopmentItemExecuteScript {
//     id: number;
//     type: IFileRetrieverDevelopmentItemModel["type"];
// }
// export interface IDevelopmentItemMetadata {
//     [key: string]: IEntityMetadataModel;
//     [key: number]: IEntityMetadataModel;
// }

export function mapType(type: DevelopmentItemModel["type"] | DevelopmentItemSummaryModel["type"]): IDevelopmentItemTypeModel {
    switch (type) {
        case "QuestionnaireDevelopmentItemModel":
        case "QuestionnaireDevelopmentItemSummaryModel":
            return IDevelopmentItemTypeModel.Questionnaire;
        case "ReportTemplateDevelopmentItemModel":
        case "ReportTemplateDevelopmentItemSummaryModel":
            return IDevelopmentItemTypeModel.ReportTemplate;
        case "EtlMacroDevelopmentItemModel":
        case "EtlMacroDevelopmentItemSummaryModel":
            return IDevelopmentItemTypeModel.EtlMacro;
        case "DashboardMacroDevelopmentItemModel":
        case "DashboardMacroDevelopmentItemSummaryModel":
            return IDevelopmentItemTypeModel.DashboardMacro;
        case "FileConsumerMacroDevelopmentItemModel":
        case "FileConsumerMacroDevelopmentItemSummaryModel":
            return IDevelopmentItemTypeModel.FileConsumerMacro;
        case "FileConnectorDevelopmentItemModel":
        case "FileConnectorDevelopmentItemSummaryModel":
            return IDevelopmentItemTypeModel.FileConnector;
        case "FileProducerMacroDevelopmentItemModel":
        case "FileProducerMacroDevelopmentItemSummaryModel":
            return IDevelopmentItemTypeModel.FileProducerMacro;
        case "PipelineTaskDevelopmentItemModel":
        case "PipelineTaskDevelopmentItemSummaryModel":
            return IDevelopmentItemTypeModel.PipelineTask;
    }
}
export function mapTypeForNew(type: IDevelopmentItemTypeModel): DevelopmentItemModel["type"] {
    switch (type) {
        case IDevelopmentItemTypeModel.Questionnaire: return "QuestionnaireDevelopmentItemModel";
        case IDevelopmentItemTypeModel.ReportTemplate: return "ReportTemplateDevelopmentItemModel";
        case IDevelopmentItemTypeModel.EtlMacro: return "EtlMacroDevelopmentItemModel";
        case IDevelopmentItemTypeModel.DashboardMacro: return "DashboardMacroDevelopmentItemModel";
        case IDevelopmentItemTypeModel.FileConsumerMacro: return "FileConsumerMacroDevelopmentItemModel";
        case IDevelopmentItemTypeModel.FileConnector: return "FileConnectorDevelopmentItemModel";
        case IDevelopmentItemTypeModel.FileProducerMacro: return "FileProducerMacroDevelopmentItemModel";
        case IDevelopmentItemTypeModel.PipelineTask: return "PipelineTaskDevelopmentItemModel";
    }
}

// export type IDevelopmentItemExecuteScript = IDataProcessorDevelopmentItemExecuteScript | IFileProcessorDevelopmentItemExecuteScript | IFileRetrieverDevelopmentItemExecuteScript;
export const ActionFactories = produceActionFactories({
    developmentItemDelete: (payload: number) => payload,
    developmentItemDeleted: (payload: number) => payload,
    developmentItemLoad: (payload: number) => payload,
    developmentItemNew: (payload: IDevelopmentItemTypeModel) => payload,
    developmentItemLoaded: (payload: IGetDevelopmentItemModel) => payload,
    developmentItemSave: (payload: DevelopmentItemModel) => payload,
    // developmentItemExecute: (payload: IDevelopmentItemExecuteScript) => payload,
    // developmentItemExecuted: (payload: IExecutionStatusResultModel) => payload,
    // developmentItemFileProviderExecuted: (payload: IExecutionStatusResultModel) => payload,
    developmentItemSaved: (payload: DevelopmentItemModel) => payload,
    developmentItemLoadAll: () => undefined,
    developmentItemLoadedAll: (payload: IGetDevelopmentItemsModel) => payload,
    developmentItemValidateScript: (payload: ITypedMacro) => payload,
    developmentItemValidatedScript: (payload: IDevelopmentItemDiagnostic) => payload,
    developmentItemMetadataLoad: (payload: IDevelopmentItemTypeModel) => payload,
    developmentItemMetadataLoaded: (payload: Record<string | number, IEntityMetadataModel>) => payload,

    developmentItemReportTemplateGenerate: (payload: IDevelopmentItemsRunReportGenerateParameters) => payload,
    developmentItemReportTemplateGenerated: () => undefined,
    developmentItemReportGenerate: (payload: IExecuteReportModel) => payload,
    developmentItemReportGenerated: () => undefined,

    developmentItemReportExecuteOnLoad: (payload: IExecuteOnLoadModel) => payload,
    developmentItemReportExecuteOnLoaded: (payload: object) => payload,

    developmentItemQuestionnaireExecuteOnLoad: (payload: IExecuteOnLoadModel) => payload,
    developmentItemQuestionnaireExecuteOnLoaded: (payload: object) => payload,

    developmentItemQuestionnaireExecuteOnComplete: (payload: IExecuteQuestionnaireOnCompleteModel) => payload,
    developmentItemQuestionnaireExecuteOnCompleted: (payload: IDashboardResultModel) => payload,

    developmentItemDashboardExecuteOnLoad: (payload: IExecuteOnLoadModel) => payload,
    developmentItemDashboardExecuteOnLoaded: (payload: IDashboardResultModel) => payload,

    // developmentItemLoadedCommunityDevelopmentItems: (payload: IGitFile[]) => payload,
    // developmentItemLoadCommunityDevelopmentItem: (payload: string) => payload,
    // developmentItemLoadedCommunityDevelopmentItem: (payload: string) => payload,
    developmentItemValidate: (payload: DevelopmentItemModel) => payload
});
type AllOptional<T> = {
    [P in keyof T]?: T[P];
};
export type DevelopmentItemDiagnostics = AllOptional<Record<IMacroTypeModel, IMacroDiagnostic>>;
export interface IRealTimeProcessingStates {

    listLoading: boolean;
    loading: boolean;
    saving: boolean;
    deleting: boolean;
    questionnaireOnLoading: boolean;
    questionnaireOnCompleting: boolean;
    dashboardLoadedResult?: IDashboardResultModel;
    dashboardOnLoading: boolean;
    reportOnLoading: boolean;
    reportOnGenerating: boolean;
    executing: boolean;
    questionnaireLoadedAnswers?: any;
    questionnaireLoadedResult?: IDashboardResultModel;
    reportLoadedData?: any;
}
export interface IState {
    developmentItemStates: IRealTimeProcessingStates;
    developmentItemList: (DevelopmentItemSummaryModel | DevelopmentItemModel)[];
    developmentItemCurrent?: DevelopmentItemModel;
    // errors?: IMacroScriptErrorModel[],
    developmentItemDiagnosticCurrent?: DevelopmentItemDiagnostics;
    macroExecutionResult?: IExecutionStatusResultModel;
    developmentItemMetadata: Record<string | number, IEntityMetadataModel>;
    dictionaries: {
        entities: Record<number, EntitySummaryModel>;
    },
    // developmentItemCommunityDevelopmentItems: IGitFile[];
    // developmentItemLastCommunityScript?: string;
}
// IShareClassDevelopmentItemDictionary
export const reducer = (
    state: IState = {
        developmentItemStates: {
            listLoading: false,
            loading: false,
            deleting: false,
            saving: false,
            executing: false,
            questionnaireOnLoading: false,
            questionnaireOnCompleting: false,
            reportOnLoading: false,
            reportOnGenerating: false,
            dashboardOnLoading: false
        },
        developmentItemMetadata: {},
        developmentItemList: [],
        dictionaries: {
            entities: {}
        }
        // developmentItemCommunityDevelopmentItems: []
    },
    action: AnyActionOf<typeof ActionFactories>
) => produce(state, draft => {
    switch (action.type) {
        case "developmentItemQuestionnaireExecuteOnComplete":
            draft.developmentItemStates.questionnaireOnCompleting = true;
            break;
        case "developmentItemQuestionnaireExecuteOnCompleted":
            draft.developmentItemStates.questionnaireLoadedResult = action.payload;
            draft.developmentItemStates.questionnaireOnCompleting = false;
            break;
        case "developmentItemDashboardExecuteOnLoad":
            draft.developmentItemStates.dashboardOnLoading = true;
            break;
        case "developmentItemDashboardExecuteOnLoaded":
            draft.developmentItemStates.dashboardLoadedResult = action.payload;
            draft.developmentItemStates.dashboardOnLoading = false;
            break;
        case "developmentItemQuestionnaireExecuteOnLoad":
            draft.developmentItemStates.questionnaireOnLoading = true;
            break;
        case "developmentItemQuestionnaireExecuteOnLoaded":
            draft.developmentItemStates.questionnaireLoadedAnswers = action.payload;
            draft.developmentItemStates.questionnaireOnLoading = false;
            break;
        case "developmentItemReportExecuteOnLoad":
            draft.developmentItemStates.reportOnLoading = true;
            break;
        case "developmentItemReportExecuteOnLoaded":
            draft.developmentItemStates.reportLoadedData = action.payload;
            draft.developmentItemStates.reportOnLoading = false;
            break;
        case "developmentItemReportGenerate":
            draft.developmentItemStates.reportOnGenerating = true;
            break;
        case "developmentItemReportGenerated":
            draft.developmentItemStates.reportOnGenerating = false;
            break;
        case "developmentItemMetadataLoad":
            draft.developmentItemMetadata = {};
            draft.developmentItemDiagnosticCurrent = {};
            break;
        case "developmentItemMetadataLoaded":
            draft.developmentItemMetadata = action.payload;
            break;
        // case "developmentItemFileProviderExecuted":
        //     draft.macroExecutionResult = action.payload;
        //     draft.developmentItemExecuting = false;
        //     break;
        // case "developmentItemExecuted":
        //     draft.macroExecutionResult = action.payload;
        //     draft.developmentItemExecuting = false;
        //     break;
        // case "developmentItemExecute":
        //     draft.developmentItemExecuting = true;
        //     break;
        case "developmentItemValidate":
            draft.developmentItemDiagnosticCurrent = {};
            break;
        case "developmentItemValidateScript":
            draft.macroExecutionResult = undefined;
            break;
        case "developmentItemValidatedScript":
            if (!draft.developmentItemDiagnosticCurrent) {
                draft.developmentItemDiagnosticCurrent = {};
            }
            draft.developmentItemDiagnosticCurrent[action.payload.type] = {
                description: action.payload.description,
                errors: action.payload.errors,
                returnType: action.payload.returnType
            };
            break;
        case "developmentItemLoadAll":
            draft.developmentItemStates.listLoading = true;
            break;
        case "developmentItemLoadedAll":
            draft.developmentItemStates.listLoading = false;
            draft.developmentItemList = action.payload.developmentItems;
            draft.dictionaries = {
                entities: action.payload.entities
            };
            break;
        case "developmentItemNew":
        case "developmentItemLoad":
            draft.developmentItemStates.loading = true;
            draft.macroExecutionResult = undefined;
            break;
        case "developmentItemLoaded": {
            draft.developmentItemStates.loading = false;
            draft.developmentItemCurrent = action.payload.developmentItem;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            break;
        }
        case "developmentItemSave":
            draft.developmentItemStates.saving = true;
            break;
        case "developmentItemSaved": {
            draft.developmentItemStates.saving = false;
            if (draft.developmentItemCurrent) {
                draft.developmentItemCurrent = action.payload;
            }
            const existing = draft.developmentItemList.find(i => i.id === action.payload.id);
            if (existing) {
                Object.assign(existing, action.payload);
            }
            else {
                draft.developmentItemList.push(action.payload);
            }
            break;
        }
        case "developmentItemDelete":
            draft.developmentItemStates.deleting = true;
            break;
        case "developmentItemDeleted":
            draft.developmentItemStates.deleting = false;
            const deletedId = action.payload;
            const idx = draft.developmentItemList.findIndex(i => i.id === deletedId);
            if (idx >= 0) {
                draft.developmentItemList.splice(idx, 1);
            }
            break;
    }
});
