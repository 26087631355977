import { Epic } from "redux-observable";
import { of, merge } from "rxjs";
import { map, mergeMap, share, switchMap } from "rxjs/operators";
import { fxRatesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { changedNavigation, mapToPayload, onlyNotNull, withLatestFromBuffer } from "lib/rxJsUtility";
import { tryParseNumber } from "tools/lib/utility";
import { filterRoute } from "tools/lib/UrlDictionary";
export const onOpenScreenCurrencyHistoricalSeries: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("FxRates", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id)),
        // distinctUntilChanged(),
        onlyNotNull(),
        map(ActionFactories.fxRates.fxRatesHistoricalValuesLoad));
export const loadCurrencyHistoricalValues: Epic<IAnyAction>
    = action$ => {
        const valuesLoad$ = action$.pipe(
            mapToPayload("fxRates", "fxRatesHistoricalValuesLoad"),
            share());
        const historicalValues$ = valuesLoad$.pipe(
            withLatestFromBuffer(action$.pipe(mapToPayload("reference", "referenceCurrenciesLoaded"))),
            switchMap(([currencyToId, currencies]) => of(({ currencyToId })).pipe(
                mergeMap(i => fxRatesApi.getHistoricalValuesAsync(i)),
                map(historicalValues => ActionFactories.fxRates.fxRatesHistoricalValuesLoaded({ historicalValues, targetCurrency: currencies[currencyToId] })))));

        return merge(
            historicalValues$,
            valuesLoad$.pipe(map(() => ActionFactories.parameters.parametersLoad())));
    }

export const saveHistoricalValue: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("fxRates", "fxRatesHistoricalValueSave"),
        mergeMap(historicalValuePayload => of(historicalValuePayload).pipe(
            mergeMap(({ currencyToId, fxValue }) => fxRatesApi.saveHistoricalValueAsync({ currencyToId, historicalValueSet: fxValue })),
            map(() => ActionFactories.fxRates.fxRatesHistoricalValueSaved(historicalValuePayload)))));
