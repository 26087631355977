import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import Positions from "./AccountBalances";
import { getSiteScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class AccountBalanceScreen extends React.PureComponent {
    public render() {
        return <NavigationRoute slideDirection={"right"} component={Positions} path={getSiteScreenUrl("AccountBalances")} />
    }
}

export default AccountBalanceScreen;
