import { Epic } from "redux-observable";
import { forkJoin, from, merge, Observable, of } from "rxjs";
import { filter, map, share, mergeMap, toArray, distinctUntilChanged, switchMap, tap } from "rxjs/operators";
import { customScreensApi, entitiesApi, IEntityGroupModel, IRelatedFileModel, macroScriptsApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, changedNavigation, withLatestFromBuffer, onlyNotNull } from "lib/rxJsUtility";
import { EntityModel, ICompanyModel, IEntityTypeModel, IPersonModel } from "proxy/apiProxy";
import { IBase64File, today, tryParseNumber } from "tools/lib/utility";
import { filterRoute, routeMatches } from "tools/lib/UrlDictionary";
import { IEntityLoadedPayload } from "features/Entity/slice";
import { IRelatedFileWithContent } from "reducers/IRelatedFileWithContent";
import { saveAs } from "file-saver";
// import { IDueDiligence } from "features/Relationship/IDueDiligence";

// export const loadProcess: Epic<IAnyAction>
//     = action$ => action$.pipe(
//         mapToPayload("entity", "entityLoadProcesses"),
//         mergeMap(async ids => {
//             const entityComplianceProcess = await Promise.all((ids["EntityComplianceProcessModel"] ?? []).map(id => entityComplianceProcessDefinitionsApi.getAsync({ id })));
//             const entityCheckProcess = await Promise.all((ids["EntityChecksProcessModel"] ?? []).map(id => entityChecksProcessDefinitionsApi.getAsync({ id })));
//             return [...entityComplianceProcess, ...entityCheckProcess];
//         }),
//         map(ActionFactories.entity.entityLoadedProcesses));

export const onOpenScreenEntities: Epic<IAnyAction>
    = action$ => {
        const changedScreen$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey),
            share());
        const getCompaniesRequest$ = changedScreen$.pipe(
            filterRoute("Companies"),
            map(() => IEntityTypeModel.Company));
        const getEntityGroupsRequest$ = changedScreen$.pipe(
            filterRoute("EntityGroups"),
            map(() => IEntityTypeModel.EntityGroup));
        const getPeopleRequest$ = changedScreen$.pipe(
            filterRoute("People"),
            map(() => IEntityTypeModel.Person));

        return merge(
            getEntityGroupsRequest$,
            getCompaniesRequest$,
            getPeopleRequest$
        ).pipe(
            map(() => ActionFactories.entity.entityLoadedAll({ all: [], entities: {} })));
    }

export const onOpenScreenProcessExecutions: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.processes?.id),
        filter(i =>
            routeMatches(i, "Companies", "processes")
            || routeMatches(i, "EntityGroups", "processes")
            || routeMatches(i, "People", "processes")),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.processes?.id)),
        filter(id => typeof id !== "undefined"),
        distinctUntilChanged(),
        map(id => ActionFactories.entity.entityProcessExecutionsLoad(id as number)));
export const loadProcessExecutions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityProcessExecutionsLoad"),
        mergeMap(id => entitiesApi.getProcessesAsync({ id })),
        map(ActionFactories.entity.entityProcessExecutionsLoaded));


export const loadEntities: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityLoadAll"),
        mergeMap(entitiesApi.searchAsync),
        map(ActionFactories.entity.entityLoadedAll));
export const onOpenScreenEntity: Epic<IAnyAction>
    = action$ => {
        const route$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
            share());

        const getCompanyRequest$ = route$.pipe(
            filterRoute("Companies", "detail"),
            map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? IEntityTypeModel.Company));
        const getEntityGroupRequest$ = route$.pipe(
            filterRoute("EntityGroups", "detail"),
            map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? IEntityTypeModel.EntityGroup));
        const getPersonRequest$ = route$.pipe(
            filterRoute("People", "detail"),
            map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? IEntityTypeModel.Person));
        const entityToLoad$ = merge(
            getEntityGroupRequest$,
            getCompanyRequest$,
            getPersonRequest$
        ).pipe(share());
        return merge(
            entityToLoad$.pipe(map(ActionFactories.entity.entityLoad)),
            entityToLoad$.pipe(map(() => ActionFactories.macroScript.macroScriptLoadAll())));
    }
export const onOpenScreenSecurityRatings: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.ratings?.id),
        filterRoute("Companies", "ratings"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.ratings?.id)),
        filter(id => typeof id !== "undefined"),
        distinctUntilChanged(),
        map(id => ActionFactories.entity.entityRatingsLoad(id as number)));
// export const onOpenScreenFiles: Epic<IAnyAction>
//     = action$ => action$.pipe(
//         changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.ratings?.id),
//         filter(i => routeMatches(i, "Companies", "files") || routeMatches(i, "EntityGroups", "files") || routeMatches(i, "People", "files")),
//         map(({ matchingSections }) => tryParseNumber(matchingSections?.ratings?.id)),
//         filter(id => typeof id !== "undefined"),
//         distinctUntilChanged(),
//         map(id => ActionFactories.entity.entityFilesLoad(id as number)));

// export const entityFilesLoad: Epic<IAnyAction>
//     = action$ => action$.pipe(
//         mapToPayload("entity", "entityFilesLoad"),
//         entitiesApi.getFilesAsync(id => ({ id })),
//         onlyStatusOk(),
//         map(ActionFactories.entity.entityFilesLoaded));

export const loadSecurityRatings: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityRatingsLoad"),
        mergeMap(id => entitiesApi.getIssuerRatingsAsync({ id })),
        map(ActionFactories.entity.entityRatingsLoaded));

function createEmptyEntity(type: IEntityTypeModel): EntityModel | null {
    switch (type) {
        case IEntityTypeModel.Person: return {
            type: "PersonModel",
            id: 0,
            internalCode: "",
            processIds: [],
            firstName: "",
            lastName: "",
            classifications: {},
            entityExtensionFieldsValues: {},
            hasPersonalPortalTheme: false
        } as IPersonModel;
        case IEntityTypeModel.Company: return {
            type: "CompanyModel",
            id: 0,
            internalCode: "",
            processIds: [],
            name: "",
            classifications: {},
            entityExtensionFieldsValues: {},
            hasPersonalPortalTheme: false,
            entitiesId: []
        } as ICompanyModel;
        case IEntityTypeModel.EntityGroup: return {
            type: "EntityGroupModel",
            id: 0,
            internalCode: "",
            entitiesId: [],
            processIds: [],
            classifications: {},
            name: "",
            entityExtensionFieldsValues: {},
            hasPersonalPortalTheme: false
        } as IEntityGroupModel;
    }
    return null;
}

export const submitCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityCustomScreenDataSubmit"),
        mergeMap(entitiesApi.submitCustomScreenDataAsync),
        map(ActionFactories.entity.entityCustomScreenDataSubmitted));
export const loadCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityCustomScreenDatasLoad"),
        mergeMap(id => entitiesApi.getLastCustomScreenDatasAsync({ id })),
        map(ActionFactories.entity.entityCustomScreenDatasLoaded));

async function getEntityAsync(id: number | IEntityTypeModel) {
    if (typeof id === "number") {
        const [loadedEntity, loadedImage, files] = await Promise.all([
            entitiesApi.getAsync({ id: id as number }),
            entitiesApi.getImageAsync({ id: id as number }).catch(() => undefined),
            entitiesApi.getFilesAsync({ id: id as number })]);
        return {
            ...loadedEntity,
            entityPicture: loadedImage ? {
                content: loadedImage.data,
                mimeType: loadedImage.mimeType,
                fileName: loadedImage.name
            } : undefined,
            files
        };
    }
    else {
        return { entity: createEmptyEntity(id), entities: {} } as IEntityLoadedPayload;
    }
}
export const loadEntity: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("entity", "entityLoad"),
            share());

        const entity$ = requestedId$.pipe(
            mergeMap(getEntityAsync),
            share());





        // const checkDdResponse$ = requestedId$.pipe(
        //     filter(i => typeof i === "number" && i !== 0),
        //     mergeMap(id => entitiesApi.getChecksDueDiligenceAsync({ id: id as number }).catch(() => undefined)),
        //     share());

        // const checkDd$ = merge(
        //     checkDdResponse$.pipe(
        //         map(ActionFactories.entity.entityChecksDueDiligenceLoaded)),
        //     requestedId$.pipe(
        //         filter(i => typeof i !== "number"),
        //         map(() => ActionFactories.entity.entityChecksDueDiligenceLoaded(undefined))));





        // const complianceDdResponse$ = requestedId$.pipe(
        //     filter(i => typeof i === "number" && i !== 0),
        //     mergeMap(id => entitiesApi.getComplianceDueDiligenceAsync({ id: id as number }).catch(() => undefined)),
        //     share());

        // const complianceDd$ = merge(
        //     complianceDdResponse$.pipe(
        //         map(ActionFactories.entity.entityComplianceDueDiligenceLoaded)),
        //     requestedId$.pipe(
        //         filter(i => typeof i !== "number"),
        //         map(() => ActionFactories.entity.entityComplianceDueDiligenceLoaded(undefined))));





        const customScreens$ = requestedId$.pipe(
            mergeMap(() => customScreensApi.getAllAsync({})),
            map(customScreens => customScreens.filter(customScreen => customScreen.type === "EntityCustomScreenSummaryModel")),
            mergeMap(async customScreens => Promise.all(customScreens.map(({ id }) => customScreensApi.getAsync({ id })))),
            map(ActionFactories.entity.entityCustomScreensLoaded));

        return merge(
            requestedId$.pipe(
                filter(id => typeof id === "number" && !!id),
                map(id => ActionFactories.entity.entityCustomScreenDatasLoad(typeof id === "number" ? id : 0))),
            customScreens$,
            entity$.pipe(map(ActionFactories.entity.entityLoaded)),
            requestedId$.pipe(filter(i => typeof i === "number"), map(id => ActionFactories.entity.entityRatingsLoad(id as number))),
        );
    };

export const getMonitoringResultLoad: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityMonitoringResultLoad"),
        mergeMap(macroScriptsApi.getMonitoringResultForTargetAsync),
        map(ActionFactories.entity.entityMonitoringResultLoaded));

// export const getComplianceFile: Epic<IAnyAction>
//     = action$ => action$.pipe(
//         mapToPayload("entity", "entityLoadComplianceFile"),
//         mergeMap(payload => entitiesApi.getComplianceDueDiligenceFileAsync(payload)
//             .then(file => ({ file, payload }))
//             .catch(() => undefined)),
//         onlyNotNull(),
//         tap(({ file: { blob, fileName } }) => saveAs(blob, fileName)),
//         map(({ payload }) => ActionFactories.entity.entityLoadedComplianceFile(payload)));
export const getFileContent: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityFileContentLoad"),
        mergeMap(payload => entitiesApi.getFileContentAsync(payload)
            .then(file => ({ file, payload }))
            .catch(() => undefined)),
        onlyNotNull(),
        tap(({ file: { blob, fileName } }) => saveAs(blob, fileName)),
        map(({ payload }) => ActionFactories.entity.entityFileContentLoaded(payload)));
// export const getCheckFile: Epic<IAnyAction>
//     = action$ => action$.pipe(
//         mapToPayload("entity", "entityLoadChecksFile"),
//         mergeMap(payload => entitiesApi.getChecksDueDiligenceFileAsync(payload).then(file => ({ file, payload }))),
//         map(({ payload }) => ActionFactories.entity.entityLoadedChecksFile(payload)));

function saveEntity(
    savedEntity: EntityModel,
    // checksDueDiligence: IDueDiligence | undefined,
    // complianceDueDiligence: IDueDiligence | undefined,
    // previousChecksDueDiligence: IGetDueDiligenceExecutionModel | undefined,
    // previousComplianceDueDiligence: IGetDueDiligenceExecutionModel | undefined,
    imageFile: IBase64File | undefined,
    files: IRelatedFileWithContent[] | undefined) {
    const { id: entityId } = savedEntity;
    const merges$: Observable<IAnyAction>[] = []; // [of(savedEntity).pipe(map(ActionFactories.entity.entitySaved))];

    const toWait$: Observable<any>[] = [];

    const savedFiles$ = (function () {
        if (files) {
            var file$ = from(files).pipe(share());
            var deletedFile$ = file$.pipe(
                filter(i => !!i.toDelete),
                mergeMap(i => entitiesApi.deleteFileAsync(({ id: entityId, fileId: i.id }))),
                map(ActionFactories.app.dummy)
            );
            merges$.push(deletedFile$);

            return file$.pipe(
                filter(i => !i.toDelete),
                mergeMap(async ({ content, toDelete, fileMimeType, fileName, contentToDelete, ...file }) => {
                    const savedFile = await entitiesApi.saveFileAsync({ id: entityId, model: file });
                    if (content && fileName) {
                        await entitiesApi.saveFileContentAsync({ fileId: savedFile.id, fileModel: { data: content.content, mimeType: content.mimeType, name: fileName } });
                        return { ...savedFile, fileName: content.fileName, fileMimeType: content.mimeType };
                    }
                    if (contentToDelete) {
                        await entitiesApi.deleteFileContentAsync({ fileId: savedFile.id });
                        return { ...savedFile, fileName: undefined, fileMimeType: undefined };
                    }
                    return savedFile;
                }),
                toArray(),
            );
        }
        else {
            return of([] as IRelatedFileModel[]);
        }
    })();

    const savedEntity$ = (function () {
        if (imageFile) {
            const toSave = {
                id: entityId,
                fileModel: {
                    data: imageFile.content,
                    mimeType: imageFile.mimeType,
                    name: imageFile.fileName
                }
            };
            return from(entitiesApi.saveImageAsync(toSave)).pipe(map(() => savedEntity));
        }
        else {
            return from(entitiesApi.deleteImageAsync({ id: savedEntity.id })).pipe(map(() => savedEntity));
        }
    })();

    merges$.push(forkJoin([savedEntity$, savedFiles$]).pipe(
        map(([entity, files]) => ({ entity, files })),
        map(ActionFactories.entity.entitySaved)))

    // if (previousChecksDueDiligence && !checksDueDiligence?.execution) {
    //     merges$.push(of(entityId).pipe(
    //         mergeMap(id => entitiesApi.deleteChecksDueDiligenceAsync({ id })),
    //         map(() => ActionFactories.entity.entityChecksDueDiligenceLoaded(undefined))));
    // }
    // if (previousComplianceDueDiligence && !complianceDueDiligence?.execution) {
    //     toWait$.push(of(entityId).pipe(
    //         mergeMap(id => entitiesApi.deleteComplianceDueDiligenceAsync({ id })),
    //         map(() => ActionFactories.entity.entityComplianceDueDiligenceLoaded(undefined))
    //     ));
    // }
    // if (!!previousChecksDueDiligence?.dueDiligenceExecution?.approvedById !== !!checksDueDiligence?.execution?.approvedById) {
    //     if (!!checksDueDiligence?.execution?.approvedById) {
    //         toWait$.push(from(entitiesApi.checksDueDiligenceApproveAsync({ id: entityId })));
    //     }
    //     else {
    //         toWait$.push(from(entitiesApi.checksDueDiligenceDisapproveAsync({ id: entityId })));
    //     }
    // }
    // if (!!previousComplianceDueDiligence?.dueDiligenceExecution?.approvedById !== !!complianceDueDiligence?.execution?.approvedById) {
    //     if (!!complianceDueDiligence?.execution?.approvedById) {
    //         toWait$.push(from(entitiesApi.complianceDueDiligenceApproveAsync({ id: entityId })));
    //     }
    //     else {
    //         toWait$.push(from(entitiesApi.complianceDueDiligenceDisapproveAsync({ id: entityId })));
    //     }
    // }
    // if (checksDueDiligence?.execution) {
    //     const savedCheckDueDilExec$ = of(checksDueDiligence).pipe(
    //         mergeMap(i => entitiesApi.saveChecksDueDiligenceAsync({ id: entityId, model: i.execution })),
    //         map(i => ({
    //             dueDiligenceExecution: i,
    //             processDefinitions: {},
    //             entities: {},
    //             relationships: {}
    //         } as IGetDueDiligenceExecutionModel)),
    //         share());
    //     const savedCheckFile$ = savedCheckDueDilExec$.pipe(mergeMap(savedCheckDueDilExec => {
    //         return submitDueDiligenceFileChanges(
    //             ActionFactories.entity.entitySaveChecksFile,
    //             ActionFactories.entity.entitySavedChecksFile,
    //             entitiesApi.saveChecksDueDiligenceFileAsync,
    //             entitiesApi.deleteChecksDueDiligenceFileAsync,
    //             checksDueDiligence.files,
    //             entityId
    //         );
    //     }));
    //     merges$.push(savedCheckDueDilExec$.pipe(map(ActionFactories.entity.entityChecksDueDiligenceLoaded)));
    //     merges$.push(savedCheckFile$);
    // }
    // if (complianceDueDiligence?.execution) {
    //     const savedComplianceDueDilExec$ = of(complianceDueDiligence).pipe(
    //         mergeMap(i => entitiesApi.saveComplianceDueDiligenceAsync({ id: entityId, model: i.execution })),
    //         map(i => ({
    //             dueDiligenceExecution: i,
    //             processDefinitions: {},
    //             entities: {},
    //             relationships: {}
    //         } as IGetDueDiligenceExecutionModel)),
    //         share());
    //     const savedComplianceFile$ = savedComplianceDueDilExec$.pipe(mergeMap(savedComplianceDueDilExec => {
    //         return submitDueDiligenceFileChanges(
    //             ActionFactories.entity.entitySaveComplianceFile,
    //             ActionFactories.entity.entitySavedComplianceFile,
    //             entitiesApi.saveComplianceDueDiligenceFileAsync,
    //             entitiesApi.deleteComplianceDueDiligenceFileAsync,
    //             complianceDueDiligence.files,
    //             entityId
    //         );
    //     }));
    //     merges$.push(savedComplianceDueDilExec$.pipe(map(ActionFactories.entity.entityComplianceDueDiligenceLoaded)));
    //     merges$.push(savedComplianceFile$);
    // }
    if (toWait$.length) {
        return forkJoin(toWait$).pipe(mergeMap(() => merge(...merges$)));
    }
    else {
        return merge(...merges$);
    }
}

export const saveEntityEpic: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entitySave"),
        // withLatestFrom(action$.pipe(mapToPayload("entity", "entityChecksDueDiligenceLoaded")), (entityData, previousChecksDueDiligence) => ({ entityData, previousChecksDueDiligence })),
        // withLatestFrom(action$.pipe(mapToPayload("entity", "entityComplianceDueDiligenceLoaded")), ({ entityData, previousChecksDueDiligence }, previousComplianceDueDiligence) => ({ entityData, previousChecksDueDiligence, previousComplianceDueDiligence })),
        mergeMap(({ entity, imageFile, files }) =>
            of(entity).pipe(
                mergeMap(model => entitiesApi.saveAsync({ model })),
                mergeMap(savedEntity => saveEntity(savedEntity, imageFile, files)))));

export const deleteEntityEpic: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("entity", "entityDelete"),
            mergeMap(id => entitiesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.entity.entityDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }




export const onOpenScreenEntityDatedScreen: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("Companies", "detail"),
        // filterRoute("Pms", "Portfolios", "MyPortfolios", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id)),
        filter(id => typeof id !== "undefined"),
        // distinctUntilChanged(),
        map(id => ActionFactories.entity.entityDatesLoad(id as number)));

export const loadPricingDates: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityDatesLoad"),
        mergeMap(id => entitiesApi.getCompositionDatesAsync({ id })),
        map(i => {
            if (!i.length) {
                return [today()];
            }
            else {
                return i;
            }
        }),
        map(ActionFactories.entity.entityDatesLoaded));

export const loadPricingDateData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityDatesLoaded"),
        map(pricingDates => {
            if (pricingDates.length === 0) {
                return;
            }
            return pricingDates.reduce((agg, v) => {
                if ((agg ? agg.getTime() : 0) - (v ? v.getTime() : 0) > 0) {
                    return agg;
                }
                return v;
            }, undefined as Date | undefined);
        }),
        filter(i => !!i),
        map(date => ActionFactories.entity.entityDateLoad(date as Date)));
export const loadPricingDate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityDateLoad"),
        withLatestFromBuffer(action$.pipe(mapToPayload("entity", "entityLoad"), filter(id => typeof id === "number"))),
        switchMap(p => {
            const [pricingDate, key] = p;
            const id = key as number;
            const portfolioComposition$ = of({ id, date: pricingDate }).pipe(
                mergeMap(i => entitiesApi.getCompositionAsync(i)));
            return merge(
                portfolioComposition$.pipe(map(() => ActionFactories.entity.entityDateLoaded(pricingDate))),
                portfolioComposition$.pipe(map(ActionFactories.entity.entityCompositionLoaded)));
        }));

export const savePosition: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityPositionSave"),
        mergeMap(position => entitiesApi.savePositionAsync({ position })),
        map(ActionFactories.entity.entityPositionSaved));
export const deletePosition: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityPositionDelete"),
        mergeMap(positionId => entitiesApi.deletePositionAsync({ positionId }).then(() => positionId)),
        map(ActionFactories.entity.entityPositionDeleted));