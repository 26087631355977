import { Epic } from "redux-observable";
import {
    map, filter, share, first, mergeMap, mergeAll
    , debounceTime,
    tap
} from "rxjs/operators";
import { IGetDevelopmentItemModel, IEntityMetadataModel, IMacroLanguageModel, IMacroTypeModel, studioDevelopmentItemsApi, DevelopmentItemModel, IDevelopmentItemTypeModel, developmentItemsRunApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import {
    changedNavigation, mapToPayload    // , onlyNotNull 
} from "lib/rxJsUtility";
import {
    // base64toBlob, 
    tryParseNumber
} from "tools/lib/utility";
import { merge } from "rxjs";
import { filterRoute } from "tools/lib/UrlDictionary";
import { IDevelopmentItemDiagnostic, ITypedMacro } from "./slice";
import { getConfig } from "lib/userManager";
import { saveAs } from "file-saver";
import { Stimulsoft } from 'stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor';
// import saveAs from "file-saver";

function createNewDevelopmentItemInstance(type: IDevelopmentItemTypeModel): DevelopmentItemModel {
    switch (type) {
        case IDevelopmentItemTypeModel.Questionnaire: return {
            type: "QuestionnaireDevelopmentItemModel",
            name: "",
            id: 0,
            classifications: {},
            templateContent: "{}"
        }
        case IDevelopmentItemTypeModel.ReportTemplate: return {
            type: "ReportTemplateDevelopmentItemModel",
            name: "",
            id: 0,
            classifications: {},
            templateContent: new Stimulsoft.Report.StiReport().saveToJsonString(),
            onLoadMacro: { content: "return new {};", language: IMacroLanguageModel.CSharp }
        }
        case IDevelopmentItemTypeModel.EtlMacro: return {
            type: "EtlMacroDevelopmentItemModel",
            name: "",
            id: 0,
            classifications: {}
        }
        case IDevelopmentItemTypeModel.DashboardMacro: return {
            type: "DashboardMacroDevelopmentItemModel",
            name: "",
            id: 0,
            classifications: {}
        }
        case IDevelopmentItemTypeModel.FileConsumerMacro: return {
            type: "FileConsumerMacroDevelopmentItemModel",
            name: "",
            id: 0,
            classifications: {}
        }
        case IDevelopmentItemTypeModel.FileConnector: return {
            type: "FileConnectorDevelopmentItemModel",
            name: "",
            id: 0,
            classifications: {}
        }
        case IDevelopmentItemTypeModel.FileProducerMacro: return {
            type: "FileProducerMacroDevelopmentItemModel",
            name: "",
            id: 0,
            classifications: {}
        }
        case IDevelopmentItemTypeModel.PipelineTask: return {
            type: "PipelineTaskDevelopmentItemModel",
            name: "",
            id: 0,
            classifications: {}
        }
    }
}

function translateOpenRequest(screen: string | undefined, detail: any): IDevelopmentItemTypeModel | number | null {
    if (!detail || !screen) {
        return null;
    }
    const developmentItemId = tryParseNumber(detail?.id);
    if (typeof developmentItemId === "number") {
        return developmentItemId;
    }
    else {
        return detail?.id as IDevelopmentItemTypeModel;
    }
}
export const onOpenScreenTemplates: Epic<IAnyAction>
    = action$ => {
        const openRequest$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
            filterRoute("DevelopmentItems", "detail"),
            // filter(({ screen, module, sections: { detail } }) => module === "Computations" && [IDevelopmentItemTypeModel.PortfolioMonitoring, IDevelopmentItemTypeModel.FileProcessor, IDevelopmentItemTypeModel.FileRetriever, IDevelopmentItemTypeModel.DataProcessor].includes((screen || "") as IDevelopmentItemTypeModel) && detail),
            map(({ screenKey, matchingSections }) => translateOpenRequest(screenKey, matchingSections?.detail)),
            filter(openRequest => !!openRequest),
            share()
        );
        const newTemplate$ = openRequest$.pipe(
            map(openRequest => {
                if (!openRequest) {
                    return;
                }
                if (typeof openRequest !== "number") {
                    return openRequest
                }
                return;
            }),
            filter(id => !!id),
            map(type => ActionFactories.developmentItem.developmentItemNew(type as IDevelopmentItemTypeModel)));
        const loadTemplate$ = openRequest$.pipe(
            map(openRequest => {
                if (!openRequest) {
                    return;
                }
                if (typeof openRequest === "number") {
                    return openRequest
                }
                return;
            }),
            filter(id => !!id),
            map(id => ActionFactories.developmentItem.developmentItemLoad(id as number)));

        return merge(
            newTemplate$,
            loadTemplate$,
        );
    }
const { general: { enableStudio } } = getConfig();//developmentItemLoadAll

export const reportGenerate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemReportTemplateGenerate"),
        mergeMap(i => developmentItemsRunApi.reportGenerateAsync(i)),
        tap(({ blob, fileName }) => saveAs(blob, fileName)),
        map(ActionFactories.developmentItem.developmentItemReportTemplateGenerated));
export const onOpenScreen: Epic<IAnyAction>
    = action$ => {
        const userLoaded$ = action$.ofType("applicationLoaded").pipe(share(), first()); // Must be loaded from start for templates to be shown in related screens
        const changedScreen$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey),
            filterRoute("DevelopmentItems"));
        return merge(userLoaded$, changedScreen$).pipe(
            map(() => ActionFactories.developmentItem.developmentItemLoadAll()));
    }
export const developmentItemQuestionnaireExecuteOnLoad: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemQuestionnaireExecuteOnLoad"),
        mergeMap(body => studioDevelopmentItemsApi.questionnaireOnLoadAsync({ body })),
        map(ActionFactories.developmentItem.developmentItemQuestionnaireExecuteOnLoaded));

export const developmentItemReportExecuteOnLoad: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemReportExecuteOnLoad"),
        mergeMap(body => studioDevelopmentItemsApi.reportOnLoadAsync({ body })),
        map(ActionFactories.developmentItem.developmentItemReportExecuteOnLoaded));

export const developmentItemReportGenerate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemReportGenerate"),
        mergeMap(body => studioDevelopmentItemsApi.reportOnGenerateAsync({ body })),
        tap(({ blob, fileName }) => saveAs(blob, fileName)),
        map(ActionFactories.developmentItem.developmentItemReportGenerated));

export const developmentItemQuestionnaireExecuteOnComplete: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemQuestionnaireExecuteOnComplete"),
        mergeMap(body => studioDevelopmentItemsApi.questionnaireOnCompleteAsync({ body })),
        map(ActionFactories.developmentItem.developmentItemQuestionnaireExecuteOnCompleted));

export const developmentItemDashboardExecuteOnComplete: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemDashboardExecuteOnLoad"),
        mergeMap(body => studioDevelopmentItemsApi.dashboardOnLoadAsync({ body })),
        map(ActionFactories.developmentItem.developmentItemDashboardExecuteOnLoaded));

export const loadDevelopmentItems: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemLoadAll"),
        mergeMap(() => enableStudio ? studioDevelopmentItemsApi.getAllAsync({}) : Promise.resolve({ developmentItems: [], entities: {} })),
        map(ActionFactories.developmentItem.developmentItemLoadedAll));

export const deleteDevelopmentItem: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("developmentItem", "developmentItemDelete"),
            mergeMap(id => studioDevelopmentItemsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.developmentItem.developmentItemDeleted));
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }

async function validateMacroAsync(macro: ITypedMacro): Promise<IDevelopmentItemDiagnostic>
async function validateMacroAsync(macro: ITypedMacro | undefined): Promise<IDevelopmentItemDiagnostic | undefined>
async function validateMacroAsync(macro: ITypedMacro | undefined | null): Promise<IDevelopmentItemDiagnostic | undefined | null> {
    if (!macro) {
        return undefined;
    }
    if ((macro.content ?? "").trimEnd() === "") {
        return { errors: [], type: macro.type };
    }
    const { type, content, language } = macro;
    const macroModel = { content, language };
    const { errors, returnType } = await studioDevelopmentItemsApi.checkAsync({ type, macroModel });
    switch (type) {
        case IMacroTypeModel.QuestionnaireOnLoad:
        case IMacroTypeModel.QuestionnaireOnComplete:
        case IMacroTypeModel.ReportOnLoad:
        case IMacroTypeModel.Dashboard:
            return { errors, type, returnType };
        case IMacroTypeModel.Etl:
        case IMacroTypeModel.FileProducer:
        case IMacroTypeModel.FileConsumer:
            var description = await studioDevelopmentItemsApi.describeEtlAsync({ type, macroModel })
            return { errors, type, description, returnType };
    }
}
export const loadDevelopmentItem: Epic<IAnyAction>
    = action$ => {
        const loadDevelopmentItem$ = action$.pipe(
            mapToPayload("developmentItem", "developmentItemLoad"),
            mergeMap(id => studioDevelopmentItemsApi.getAsync({ id })),
            share());

        const loadedDevelopmentItem$ = loadDevelopmentItem$.pipe(
            map(ActionFactories.developmentItem.developmentItemLoaded));

        const metadata$ = loadDevelopmentItem$.pipe(
            mergeMap(studioDevelopmentItemsApi.getDatabaseStructureAsync),
            map(ActionFactories.developmentItem.developmentItemMetadataLoaded));

        const checks$ = loadDevelopmentItem$.pipe(
            map(i => i.developmentItem),
            map(ActionFactories.developmentItem.developmentItemValidate)
        );

        return merge(
            loadedDevelopmentItem$,
            checks$,
            metadata$);
    };
export const developmentItemValidate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemValidate"),
        map(i => {
            switch (i.type) {
                case "QuestionnaireDevelopmentItemModel": return [
                    { ...(i.onLoadMacro ?? { content: "", language: IMacroLanguageModel.CSharp }), type: IMacroTypeModel.QuestionnaireOnLoad } as ITypedMacro,
                    { ...(i.onCompleteMacro ?? { content: "", language: IMacroLanguageModel.CSharp }), type: IMacroTypeModel.QuestionnaireOnComplete } as ITypedMacro,
                ];
                case "ReportTemplateDevelopmentItemModel": return [{ ...(i.onLoadMacro ?? { content: "", language: IMacroLanguageModel.CSharp }), type: IMacroTypeModel.ReportOnLoad } as ITypedMacro];
                case "EtlMacroDevelopmentItemModel": return [{ ...(i.macro ?? { content: "", language: IMacroLanguageModel.CSharp }), type: IMacroTypeModel.Etl } as ITypedMacro];
                case "DashboardMacroDevelopmentItemModel": return [{ ...(i.macro ?? { content: "", language: IMacroLanguageModel.CSharp }), type: IMacroTypeModel.Dashboard } as ITypedMacro];
                case "FileConsumerMacroDevelopmentItemModel": return [{ ...(i.macro ?? { content: "", language: IMacroLanguageModel.CSharp }), type: IMacroTypeModel.FileConsumer } as ITypedMacro];
                case "FileProducerMacroDevelopmentItemModel": return [{ ...(i.macro ?? { content: "", language: IMacroLanguageModel.CSharp }), type: IMacroTypeModel.FileProducer } as ITypedMacro];
                default: return [];
            }
        }),
        mergeMap(async i => {
            const tmp = i.map(async m => {
                if ((m.content ?? "").trimEnd().length) {
                    return await validateMacroAsync(m)
                }
                return { errors: [], type: m.type } as IDevelopmentItemDiagnostic;
            });
            return Promise.all(tmp);
        }),
        mergeAll(),
        map(ActionFactories.developmentItem.developmentItemValidatedScript)
    )
export const checkScript: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemValidateScript"),
        debounceTime(2000),
        mergeMap(i => validateMacroAsync(i)),
        // map(ActionFactories.app.dummy));
        map(ActionFactories.developmentItem.developmentItemValidatedScript));

// export const executeScript: Epic<IAnyAction>
//     = action$ => {
//         const execution$ = action$.pipe(mapToPayload("developmentItem", "developmentItemExecute"), share());
//         const dataProcessorExecution$ = execution$.pipe(
//             filter(i => i.type === "DataProcessorDevelopmentItemModel"),
//             mergeMap(r => developmentItemsApi.executeDataProcessorMacroAsync({ id: r.id, scopeId: r.type === "DataProcessorDevelopmentItemModel" ? r.targetId : undefined })),
//             map(ActionFactories.developmentItem.developmentItemExecuted));

//         const fileProviderExecution$ = execution$.pipe(
//             filter(i => i.type === "FileRetrieverDevelopmentItemModel"),
//             mergeMap(({ id }) => developmentItemsApi.executeFileProviderMacroAsync({ id })),
//             tap(({ file }) => {
//                 if (file && file.data && file.mimeType && file.name) {
//                     const blob = base64toBlob(file.data, file.mimeType);
//                     saveAs(blob, file.name);
//                 }
//             }),
//             map(({ result }) => ActionFactories.developmentItem.developmentItemFileProviderExecuted(result)));
//         const fileProcessorExecution$ = execution$.pipe(
//             map(i => {
//                 if (i.type === "FileProcessorDevelopmentItemModel") {
//                     return i;
//                 }
//                 return undefined;
//             }),
//             onlyNotNull(),
//             mergeMap(({ id, file }) => developmentItemsApi.executeFileProcessorMacroAsync({ id, fileModel: file })),
//             map(ActionFactories.developmentItem.developmentItemExecuted));
//         return merge(
//             dataProcessorExecution$,
//             fileProviderExecution$,
//             fileProcessorExecution$
//         );
//     }

export const newDevelopmentItem: Epic<IAnyAction>
    = action$ => {
        const type$ = action$.pipe(
            mapToPayload("developmentItem", "developmentItemNew"), share());

        const metadata$ = merge(
            type$.pipe(
                // filter(type => type !== IDevelopmentItemTypeModel.SubMacro),
                mergeMap(type => studioDevelopmentItemsApi.getDatabaseStructureAsync())),
            type$.pipe(
                // filter(type => type === IDevelopmentItemTypeModel.SubMacro),
                map(() => ({} as Record<string | number, IEntityMetadataModel>))))
            .pipe(map(ActionFactories.developmentItem.developmentItemMetadataLoaded));
        return merge(
            type$.pipe(
                map(type => ({ developmentItem: createNewDevelopmentItemInstance(type), entities: {} } as IGetDevelopmentItemModel)),
                map(ActionFactories.developmentItem.developmentItemLoaded)),
            metadata$);
    }
export const saveDevelopmentItem: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemSave"),
        mergeMap(model => studioDevelopmentItemsApi.saveAsync({ model })),
        map(i => ActionFactories.developmentItem.developmentItemSaved(i)));
