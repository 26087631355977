import * as React from "react";
import {
    ICurrencyModel,
    IClassificationTypeModel,
    IFrequencyTypeModel,
    IInvestmentProcessTypeModel, ICountryModel, IEntityTypeModel, ISubFundModel, IPersonSummaryModel, IExtensionFieldModel
} from "proxy/apiProxy";
import FormTextField from "tools/fieldComponents/FormTextField";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import FieldBox from "tools/components/FieldBox";
import { formatDate, getEnumLabels, IDictionary, oProps } from "tools/lib/utility";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import { CardContent, Typography, Card, Box, Chip } from "@material-ui/core";
import ClassificationsData from "components/global/ClassificationsData";
import FormTimePickerField from "tools/fieldComponents/FormTimePickerField";
import EntitySelectField from "components/searchers/EntitySelectField";
import { IGetEntitySummary, IGetSecuritySummary } from "reducers/Reference";
import EntitySummary from "components/summaries/EntitySummary";
import * as SubFundReducer from "./slice";
import PortfolioShareClasses from "features/ManagedPortfolio/PortfolioShareClasses";
import { CurrencySelectField } from "components/searchers/CurrencySelectField";
import { CountrySelectField } from "components/searchers/CountrySelectField";
import FaceIcon from '@material-ui/icons/Face';
import ExtensionFields from "features/ManagedPortfolio/ExtensionFields";
export interface ISubFundDataProps {
    referenceCurrencies: IDictionary<ICurrencyModel>;
    referenceCountries: IDictionary<ICountryModel>;
    dictionaries: SubFundReducer.IState["dictionaries"];
    onShareClassLoaded: (security: IGetSecuritySummary) => void;
    onEntityLoaded: (found: IGetEntitySummary) => void;
    classificationTypes: IClassificationTypeModel[];
    onShareClassClick: (securityId: number) => void;
    currentValues: ISubFundModel;
    subFundExtensionFields: IExtensionFieldModel[];
};

const investmentProcesses = getEnumLabels(IInvestmentProcessTypeModel);

const pricingFrequencies = getEnumLabels(IFrequencyTypeModel);

function PortfolioData({ referenceCountries,
    referenceCurrencies,
    dictionaries,
    onShareClassLoaded,
    onEntityLoaded,
    classificationTypes,
    onShareClassClick,
    currentValues,
    subFundExtensionFields
}: ISubFundDataProps) {

    const fundOfFundModifier = (!!currentValues.isFundOfFundModifierId) ? (dictionaries.entities[currentValues.isFundOfFundModifierId] as IPersonSummaryModel) : undefined
    return <FieldBox display="flex" flexDirection="column">
        <FieldBox display="flex" flexDirection="row">
            <FormTextField name={oProps<ISubFundModel>().path("internalCode")} label="Internal Code" required={true} maxLength={100} />
            <CurrencySelectField name={oProps<ISubFundModel>().path("currencyId")} label="Currency" required={true} />
            <FormDatePickerField name={oProps<ISubFundModel>().path("inceptionDate")} label="Inception Date" />
        </FieldBox>
        <FieldBox display="flex" flexDirection="row">
            <FormSimpleSelectField name={oProps<ISubFundModel>().path("pricingFrequency")} label="Pricing Frequency" options={pricingFrequencies} required={true} />
        </FieldBox>
        <FieldBox display="flex" flexDirection="row">
            <FieldBox display="flex" flexDirection="column">
                <FormTextField name={oProps<ISubFundModel>().path("name")} label="Name" required={true} maxLength={250} />
                <Box>
                    <EntitySelectField type={IEntityTypeModel.Sicav} name={oProps<ISubFundModel>().path("sicavId")} label="Sicav" onSelected={onEntityLoaded} />
                    <EntitySummary name={oProps<ISubFundModel>().path("sicavId")} entities={dictionaries.entities} countries={referenceCountries} />
                </Box>
            </FieldBox>
            <FieldBox display="flex" flexDirection="column">
                <FormTextField name={oProps<ISubFundModel>().path("shortName")} label="Short Name" maxLength={50} />
                <CountrySelectField name={oProps<ISubFundModel>().path("domicileId")} label="Domicile" />
                <CountrySelectField name={oProps<ISubFundModel>().path("countryId")} label="Country" />
            </FieldBox>
            <FieldBox display="flex" flexDirection="column">
                <EntitySelectField type={IEntityTypeModel.Person} name={oProps<ISubFundModel>().path("subscriptionContactId")} label="Subscription Contact" onSelected={onEntityLoaded} />
                <EntitySummary name={oProps<ISubFundModel>().path("subscriptionContactId")} entities={dictionaries.entities} countries={referenceCountries} />
            </FieldBox>
        </FieldBox>
        <FieldBox display="flex" flexDirection="row">
            <ExtensionFields extensionFields={subFundExtensionFields} pathToExtensionFields={oProps<ISubFundModel>().path("subFundExtensionFieldsValues")} />
        </FieldBox>
        <Card >
            <CardContent>
                <Typography gutterBottom={true} variant="h5" component="h2">
                    ShareClasses
                </Typography>
                <PortfolioShareClasses
                    primaryShareClassIdField={oProps<ISubFundModel>().path("primaryShareClassId")}
                    shareClassesIdField={oProps<ISubFundModel>().path("shareClassesId")}
                    referenceCurrencies={referenceCurrencies}
                    securities={dictionaries.securities}
                    onShareClassLoaded={onShareClassLoaded}
                    onShareClassClick={onShareClassClick} />
            </CardContent>
        </Card>
        <ClassificationsData
            panelTitle="Classification"
            fieldName={oProps<ISubFundModel>().path("classifications")}
            classificationTypes={classificationTypes} />
        <FieldBox display="flex" flexDirection="row">
            <FieldBox display="flex" flexDirection="column">
                <FormSimpleSelectField name={oProps<ISubFundModel>().path("investmentProcess")} label="Investment Process" options={investmentProcesses} />
                <FormTextField name={oProps<ISubFundModel>().path("recommendedTimeHorizon")} label="Recommended Time Horizon" isNumber={true} />
                <FormTextField name={oProps<ISubFundModel>().path("settlementNbDays")} label="Settlement Nb Days" isNumber={true} />
                <FormTimePickerField name={oProps<ISubFundModel>().path("cutOffTime")} label="Cut Off Time" />
            </FieldBox>
            <FieldBox display="flex" flexDirection="column">
                {fundOfFundModifier && <Chip icon={<FaceIcon />} label={`"Is Fund Of Fund" modified by ${fundOfFundModifier.firstName} ${fundOfFundModifier.lastName} on ${formatDate(currentValues.isFundOfFundModifiedDateTime)}`} />}
                <FormCheckBoxField name={oProps<ISubFundModel>().path("isFundOfFunds")} label="Is Fund Of Funds" canBeUndefined={true} />
                <FormCheckBoxField name={oProps<ISubFundModel>().path("shortExposure")} label="Short Exposure" />
                <FormCheckBoxField name={oProps<ISubFundModel>().path("leverage")} label="Leverage" />
                <FormCheckBoxField name={oProps<ISubFundModel>().path("closedEnded")} label="Closed Ended" />
            </FieldBox>
            <FieldBox display="flex" flexDirection="column">
                <FormCheckBoxField name={oProps<ISubFundModel>().path("inLiquidation")} label="In liquidation" />
                <FormDatePickerField name={oProps<ISubFundModel>().path("liquidationDate")} label="Liquidation Date" />
                <FormTextField name={oProps<ISubFundModel>().path("url")} label="Url" maxLength={500} />
            </FieldBox>
        </FieldBox>
    </FieldBox>;
}

export default React.memo(PortfolioData);