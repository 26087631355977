import { Box } from "@material-ui/core";
import PricingDatesNavigator from "components/global/PricingDatesNavigator";
import DetailPanel from "tools/components/DetailPanel";
import FieldBox from "tools/components/FieldBox";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import PortfoliosComplianceChecks from "components/tradeDate/PortfoliosComplianceChecks";


export default function Trades() {
    const { portfoliosComplianceChecking, portfoliosComplianceDates, portfoliosComplianceDatesLoading, portfoliosComplianceChecks = [], portfoliosComplianceCurrentDate } = useReduxSelections("portfoliosCompliance");
    const { portfoliosComplianceCheck } = useReduxActions("portfoliosCompliance");
    const handleRefresh = () => {
        if (portfoliosComplianceCurrentDate) {
            portfoliosComplianceCheck(portfoliosComplianceCurrentDate);
        }
    }
    return <DetailPanel
        isQuerying={portfoliosComplianceChecking}
        onRefreshClick={handleRefresh}
        title="Portfolios Compliance">
        <Box display="flex" flexDirection="column" height="100%">
            <FieldBox display="flex" flexDirection="row" style={{ padding: 16 }}>
                <PricingDatesNavigator
                    onPricingDateChanged={portfoliosComplianceCheck}
                    pricingDatesLoading={portfoliosComplianceDatesLoading}
                    pricingDate={portfoliosComplianceCurrentDate}
                    pricingDates={portfoliosComplianceDates} />
                <div style={{ flexGrow: 1 }} />
            </FieldBox>
            <div style={{ flexGrow: 1, overflowY: "auto", height: "100%", padding: 16 }}>
                <PortfoliosComplianceChecks portfoliosChecks={portfoliosComplianceChecks} />
            </div>
        </Box>
    </DetailPanel>
}