import { Drawer, Accordion, AccordionDetails, AccordionSummary, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography, makeStyles, Box } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import ApplicationTitle from './ApplicationTitle';
import { ISiteMapSelection, typedSiteMap } from "tools/lib/SiteMap";
import { ISiteMapModule } from "tools/lib/SiteMapTypes";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getConfig } from 'lib/userManager';
// import menuBg from "images/menuBg.jpg";
// import { makeOpacity } from 'tools/lib/findColorBetween';

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
    drawerPaper: {
        position: 'relative',
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.sharp,
        }),
        whiteSpace: 'nowrap',
        width: drawerWidth,
        background: theme.palette.background.default,
        boxShadow: theme.shadows[5]
        // backgroundImage: `url("${menuBg}")`
        // backgroundImage: `linear-gradient(
        //     to right,
        //     ${theme.palette.background.paper},
        //     ${theme.palette.background.paper} calc(100% - 6px),
        //     ${theme.palette.grey[300]} calc(100% - 2px),
        //     ${theme.palette.grey[400]}
        //     )`
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        }),
        width: theme.spacing(5),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7),
        },
    },
    accordionContent: {
        padding: 0
    },
    accordionContentList: {
        width: '100%'
    },
    accordionHeading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: "normal",
        color: theme.palette.primary.contrastText
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.light,
    },
    toolbar: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0 8px',
        backgroundColor: theme.palette.primary.main,
        ...theme.mixins.toolbar,
    },
    menuList: {
        paddingBottom: 0,
        paddingTop: 0,
    }
}));

export default function ApplicationMenu() {
    const classes = useStyles();
    // const { menuDrawerOpened, theme, navigationOpenedModuleCategoryIndex, navigationActiveUrlNode: navigationActiveNode, navigationActiveSiteMapNode } = this.props;
    const { menuDrawerOpened, currentUser } = useReduxSelections("app");
    const { navigationOpenedModuleCategoryIndex, navigationActiveSiteMapNode } = useReduxSelections("navigation");
    // const navigationContext = navigationActiveNode ? getNavigationContext(navigationActiveNode) : undefined;
    const openedModuleCategory = typedSiteMap[navigationOpenedModuleCategoryIndex];
    // const activeModuleUrl = navigationContext?.module?.screens[0]?.screenKey;
    const { navigationExpandModuleCategory, navigationNavigate } = useReduxActions("navigation");
    const { closeMenuDrawer } = useReduxActions("app");
    const handleNavigate = (module: ISiteMapModule) => {
        navigationNavigate({ screenKey: module.screens[0].screenKey });
        // const screen = module.screens[Object.keys(module.screens)[0]];
        // this.props.push(screen.url);
    }

    const handleCategorySwitch = (moduleCategoryIndex: number) => {
        navigationExpandModuleCategory(moduleCategoryIndex);
    }
    const { general: { enableStudio } } = getConfig();
    const handleDrawerClose = () => {
        closeMenuDrawer();
    }
    return (
        <Drawer
            variant="permanent"
            anchor="left"
            classes={{
                paper: classNames(classes.drawerPaper, !menuDrawerOpened && classes.drawerPaperClose),
            }}
            open={menuDrawerOpened} >
            <div className={classes.toolbar}>
                {(menuDrawerOpened) && <ApplicationTitle />}
                <div style={{ flexGrow: 1 }} />
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Box style={{ overflowY: "auto" }}>
                {(!menuDrawerOpened) && <List component="ul" disablePadding={true}>
                    {openedModuleCategory.modules
                        .map((mod, idx) => <CollapsedListItem
                            key={idx}
                            moduleIndex={idx}
                            moduleCategoryIndex={navigationOpenedModuleCategoryIndex}
                            applicationModule={mod}
                            onClick={handleNavigate}
                            siteMapSelection={navigationActiveSiteMapNode} />)}
                </List>
                }
                {(menuDrawerOpened) && <List className={classes.menuList}>
                    {typedSiteMap.filter(i => (!i.onlyForTenantAdministrators || currentUser?.isTenanciesAdmin)).map((moduleCategory, moduleCategoryIdx) =>
                        <Accordion TransitionProps={{ unmountOnExit: true }} key={moduleCategoryIdx} expanded={(moduleCategoryIdx === navigationOpenedModuleCategoryIndex)} onChange={handleCategorySwitch.bind(null, moduleCategoryIdx)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classNames(classes.accordionSummary)}>
                                <Typography className={classes.accordionHeading}>{moduleCategory.label}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classNames(classes.accordionContent)}>
                                <List component="ul" disablePadding={true} className={classNames(classes.accordionContentList)}>
                                    {moduleCategory.modules
                                        .filter(mod => !mod.studioRelated || enableStudio)
                                        .map((mod, moduleIdx) => <ExpandedListItem
                                            key={moduleIdx}
                                            moduleIndex={moduleIdx}
                                            moduleCategoryIndex={moduleCategoryIdx}
                                            applicationModule={mod}
                                            onClick={handleNavigate}
                                            siteMapSelection={navigationActiveSiteMapNode} />)}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    )
                    }
                </List>}
            </Box>
        </Drawer>);
}

// rgba(255, 255, 255,0.7)
interface IMenuListItemProps {
    moduleCategoryIndex: number;
    moduleIndex: number;
    applicationModule: ISiteMapModule;
    onClick: (applicationModule: ISiteMapModule) => void;
    siteMapSelection?: ISiteMapSelection;
}
function CollapsedListItem({ applicationModule, onClick, moduleIndex, moduleCategoryIndex, siteMapSelection }: IMenuListItemProps) {
    const { label, icon } = applicationModule;
    const handleClick = () => onClick(applicationModule);
    const selectedModuleCategoryIndex = siteMapSelection?.moduleCategoryIndex;
    const selectedModuleIndex = siteMapSelection?.moduleIndex;
    return <Tooltip title={label} placement="right">
        <ListItem
            button={true}
            selected={selectedModuleIndex === moduleIndex && selectedModuleCategoryIndex === moduleCategoryIndex}
            onClick={handleClick}>
            <ListItemIcon>{icon}</ListItemIcon>
        </ListItem>
    </Tooltip>
}
function ExpandedListItem({ applicationModule, onClick, moduleIndex, moduleCategoryIndex, siteMapSelection }: IMenuListItemProps) {
    const { label, icon } = applicationModule;
    const handleClick = () => onClick(applicationModule);
    const selectedModuleCategoryIndex = siteMapSelection?.moduleCategoryIndex;
    const selectedModuleIndex = siteMapSelection?.moduleIndex;
    return <ListItem
        button={true}
        selected={selectedModuleIndex === moduleIndex && selectedModuleCategoryIndex === moduleCategoryIndex}
        onClick={handleClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
    </ListItem>;
}