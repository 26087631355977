import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IFeatureModel, IReportTemplateCategoryModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";

export default function ReportTemplateCategories() {
    const { reportTemplateCategories = [], reportTemplateCategoryAllLoading } = useReduxSelections("reportTemplateCategory");
    const { navigationNavigate } = useReduxActions("navigation");

    const columns: IColumnDefinition[] = [
        { name: "Name", title: "Name", getCellValue: ({ name }: IReportTemplateCategoryModel) => name, filteringEnabled: true }
    ];
    const state: IGridState = {
        "Name": { width: 280 }
    };

    const getRowKey = ({ id = 0 }: IReportTemplateCategoryModel) => id;

    const handleRowClick = ({ id }: IReportTemplateCategoryModel) => navigationNavigate({ sectionKey: "detail", parameters: { id } });

    const handleAddNewClick = () => navigationNavigate({ sectionKey: "detail", parameters: { id: 0 } });

    return (<SearchPanel title="Report Template Categories" isQuerying={reportTemplateCategoryAllLoading}
        addAllowed={IFeatureModel.ReportTemplateWrite}
        onAddClick={handleAddNewClick}>
        <ExtendedGrid
            rows={reportTemplateCategories}
            columns={columns}
            getRowId={getRowKey}
            onRowClick={handleRowClick}
            initialState={state} />
    </SearchPanel>);
}
