import { Avatar, Box, Card, CardContent, CardHeader, Grid, IconButton, Typography } from "@material-ui/core";
import { FieldArray, FieldArrayRenderProps, useField } from "formik";
import { PortfolioSummaryModel, ICommitmentModel, ICurrencyModel, EntitySummaryModel, IPortfolioTypeModel, SecuritySummaryModel, ISecurityTypeModel, InvestorOperationModel } from "proxy/apiProxy";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { formatDate, formatDecimal, oProps } from "tools/lib/utility";
import AddIcon from '@material-ui/icons/Add';
import FormTextField from "tools/fieldComponents/FormTextField";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from "@material-ui/icons/Delete";
import SecurityIcon from '@material-ui/icons/Security';
import QueueIcon from '@material-ui/icons/Queue';
import ManagedPortfolioSelectField from "components/searchers/ManagedPortfolioSelectField";
import { IGetPortfolioSummary, IGetSecuritySummary } from "reducers/Reference";
import ManagedPortfolioSummary from "components/summaries/ManagedPortfolioSummary";
import SecuritySelectField from "components/searchers/SecuritySelectField";
import SecuritySummary from "components/summaries/SecuritySummary";
import { getInvestorOperationTypeLabel } from "./getInvestorOperationTypeLabel";
import { ActionButton, IActionButton } from "tools/components/FabContainer";
import StickyContainer from "tools/components/StickyContainer";
export interface IInvestorOperationsProps {
    dictionaries: {
        entities: Record<string | number, EntitySummaryModel>;
        portfolios: Record<string | number, PortfolioSummaryModel>;
        securities: Record<number, SecuritySummaryModel>;
    };
    onPortfolioLoaded: (portfolioSummary: PortfolioSummaryModel) => void;
    onSecurityLoaded: (payload: IGetSecuritySummary) => void;
    referenceCurrencies: Record<number, ICurrencyModel>;
    formFieldName: string;
}

export default function InvestorOperations({ formFieldName, dictionaries, referenceCurrencies, onPortfolioLoaded, onSecurityLoaded }: IInvestorOperationsProps) {
    const [, { value: commitments = [] }] = useField<InvestorOperationModel[]>(formFieldName);

    return <FieldArray name={formFieldName}>{renderCommitments}</FieldArray>
    function renderCommitments({ push, remove, form: { validateForm } }: FieldArrayRenderProps) {
        const handleAddCommitment = () => {
            push({ type: "CommitmentModel" } as InvestorOperationModel);
            setTimeout(() => validateForm(), 100);
        }
        const handleAddDrawdown = () => {
            push({ type: "DrawdownModel" } as InvestorOperationModel);
            setTimeout(() => validateForm(), 100);
        }
        const extraActionButtons: IActionButton = {
            label: "Add",
            icon: AddIcon,
            actions: [{
                label: "Commitment",
                onClick: handleAddCommitment
            }, {
                label: "Drawdown",
                onClick: handleAddDrawdown
            }]
        };
        const handleRemove = (index: number) => {
            remove(index);
            setTimeout(() => validateForm(), 100);
        }
        return <ReadOnlyContext.Consumer>
            {readOnly => <>
                {!commitments.length && <Typography>No commitment</Typography>}
                {!!commitments.length && <Grid container={true} spacing={1}>
                    {commitments.map((commitment, index) => <Grid item={true} xs={12}><InvestorOperation
                        key={index}
                        index={index}
                        referenceCurrencies={referenceCurrencies}
                        readOnly={readOnly}
                        onSecurityLoaded={onSecurityLoaded}
                        onDelete={handleRemove}
                        dictionaries={dictionaries}
                        onPortfolioLoaded={onPortfolioLoaded}
                        investorOperation={commitment}
                        pathToValue={oProps<InvestorOperationModel[]>(formFieldName).path(index)} /></Grid>)}</Grid>}
                {!readOnly && <StickyContainer>
                    <ActionButton action={extraActionButtons} />
                </StickyContainer>}
            </>}
        </ReadOnlyContext.Consumer>
    }
}

interface IInvestorOperationProps {
    index: number;
    readOnly: boolean;
    onDelete: (index: number) => void;
    pathToValue: string;
    investorOperation: InvestorOperationModel;
    referenceCurrencies: Record<number, ICurrencyModel>;
    dictionaries: {
        entities: Record<string | number, EntitySummaryModel>;
        portfolios: Record<string | number, PortfolioSummaryModel>;
        securities: Record<number, SecuritySummaryModel>;
    };
    onPortfolioLoaded: (portfolioSummary: PortfolioSummaryModel) => void;
    onSecurityLoaded: (payload: IGetSecuritySummary) => void;
}
// SecurityIcon
function InvestorOperation({ onSecurityLoaded, dictionaries, onPortfolioLoaded, referenceCurrencies, readOnly, onDelete, pathToValue, index, investorOperation }: IInvestorOperationProps) {
    const [, , { setValue: setPortfolioId }] = useField<number | undefined>(oProps<InvestorOperationModel>(pathToValue).path("portfolioId"))
    const [, , { setValue: setShareClassId }] = useField<number | undefined>(oProps<InvestorOperationModel>(pathToValue).path("shareClassId"))
    const handleDelete = () => onDelete(index);
    const { amount, date, portfolioId = 0 } = investorOperation;
    const handlePortfolioSelected = ({ portfolio }: IGetPortfolioSummary) => {
        onPortfolioLoaded(portfolio);
        if (portfolioId !== portfolio.id) {
            setShareClassId(undefined);
        }
    }
    const handlePortfolioRemove = () => setShareClassId(undefined);
    const handleShareClassSelected = (found: IGetSecuritySummary) => {
        onSecurityLoaded(found);
        setPortfolioId(found.security.type === "ShareClassSummaryModel" ? found.security.subFundId : undefined);
    }

    const portfolio = dictionaries.portfolios[portfolioId];
    const currency = (function () {
        const currencyId = portfolio?.currencyId ?? 0;
        return referenceCurrencies[currencyId]
    })();
    const { isBackedByShares, hasBeenRefusedByBoard } = investorOperation.type === "CommitmentModel" ? investorOperation : { isBackedByShares: false, hasBeenRefusedByBoard: false };
    return <Card>
        <CardHeader
            avatar={hasBeenRefusedByBoard
                ? <Avatar><BlockIcon style={{ color: "red" }} /></Avatar>
                : isBackedByShares ? <Avatar><SecurityIcon /></Avatar>
                    : <Avatar><QueueIcon /></Avatar>}
            title={!!date ? formatDate(date) : "no date"}
            subheader={`${getInvestorOperationTypeLabel(investorOperation.type)}: ${typeof amount === "undefined" ? "<amount to define>" : formatDecimal(amount)} ${currency?.isoCode} in portfolio ${portfolio?.name}`}
            action={!readOnly ? <IconButton
                size="small"
                style={{ verticalAlign: "middle" }}
                onClick={handleDelete} >
                <DeleteIcon />
            </IconButton> : undefined} />
        <CardContent>
            <Grid container={true} spacing={1} >
                <Grid item={true} xs={12}>
                    <FormDatePickerField label="Date" name={oProps<InvestorOperationModel>(pathToValue).path("date")} fullWidth={false} />
                </Grid>
                <Grid item={true} xs={6}>
                    <Box>
                        <SecuritySelectField label="ShareClass" onSelected={handleShareClassSelected} name={oProps<InvestorOperationModel>(pathToValue).path("shareClassId")} type={ISecurityTypeModel.ShareClass} />
                        <SecuritySummary name={oProps<InvestorOperationModel>(pathToValue).path("shareClassId")} currencies={referenceCurrencies} securities={dictionaries.securities} />
                    </Box>
                </Grid>
                <Grid item={true} xs={6}>
                    <Box>
                        <ManagedPortfolioSelectField label="Portfolio" onSelected={handlePortfolioSelected} required={true} name={oProps<InvestorOperationModel>(pathToValue).path("portfolioId")} type={IPortfolioTypeModel.SubFund} />
                        <ManagedPortfolioSummary name={oProps<InvestorOperationModel>(pathToValue).path("portfolioId")} currencies={referenceCurrencies} portfolios={dictionaries.portfolios} onDelete={handlePortfolioRemove} />
                    </Box>
                </Grid>
                <Grid item={true} xs={3}>
                    <FormTextField label="Amount" name={oProps<InvestorOperationModel>(pathToValue).path("amount")} required={true} isNumber={true} minValue={0} adornment={currency?.isoCode} />
                </Grid>

                {investorOperation.type === "CommitmentModel" && < Grid item={true} xs={3}>
                    <FormTextField label="Issuing Commission" name={oProps<ICommitmentModel>(pathToValue).path("issuingCommission")} isPercentage={true} minValue={0} />
                </Grid>}
                {investorOperation.type === "CommitmentModel" && <Grid item={true} xs={3}>
                    <FormCheckBoxField label="Refused By Board" name={oProps<ICommitmentModel>(pathToValue).path("hasBeenRefusedByBoard")} />
                </Grid>}
                {investorOperation.type === "CommitmentModel" && <Grid item={true} xs={3}>
                    <FormCheckBoxField label="Backed By Shares" name={oProps<ICommitmentModel>(pathToValue).path("isBackedByShares")} />
                </Grid>}
            </Grid>
        </CardContent>
    </Card >
}