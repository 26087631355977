import { Epic } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import { tracesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
// import { USER_FOUND } from "redux-oidc";
import { mapToPayload } from "lib/rxJsUtility";

export const searchRelationship: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("traces", "tracesSearch"),
        mergeMap(tracesApi.searchAsync),
        map(ActionFactories.traces.tracesSearched));
