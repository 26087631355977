import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import { IFeatureModel, IUniverseScopeTypeModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import * as React from "react";
import { ISicavData } from "features/Sicav/slice";
import { oProps } from "tools/lib/utility";
import { Chip } from "@material-ui/core";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import CustomViewDatas from "features/Relationship/CustomViewDatas";
import MonitoringResultGroups from "components/global/MonitoringResults";
import BriefcaseAccountIcon from "mdi-material-ui/BriefcaseAccount";
import EntityData from "features/Entity/EntityData";
import { ProcessExecutionList } from "features/ProcessExecution/ProcessExecutionList";
import { IActionButton } from "tools/components/FabContainer";
import NotebookCheckOutlineIcon from "mdi-material-ui/NotebookCheckOutline";
import EntityList from "features/Entity/EntityList";

export default function Sicav() {
    const { current, entityPicture } = useReduxSelections("sicav");
    const { sicavSave } = useReduxActions("sicav");

    const formValues: ISicavData | undefined = React.useMemo(() => {
        if (!current) {
            return undefined;
        }
        const ret: ISicavData = {
            sicav: current,
            imageFile: entityPicture
        };
        return ret;
    }, [current, entityPicture]);

    const handleSubmit = React.useCallback((values: ISicavData, { setSubmitting }: FormikHelpers<ISicavData>) => {
        sicavSave(values);
        setSubmitting(false);
    }, [sicavSave])

    if (!current || !formValues) {
        return <DetailPanelLoading onBackClick tabNumber={2} hasSubTitle={false} />;
    }

    return <Formik onSubmit={handleSubmit} initialValues={formValues} enableReinitialize={true} validateOnMount={true} >
        {formikProps => <SicavForm formikProps={formikProps} />}
    </Formik >;
}

function SicavForm({ formikProps: { dirty, isValid, submitForm, values: formValues } }: { formikProps: FormikProps<ISicavData> }) {
    const {
        current,
        loading,
        dictionaries,
        saving,
        customScreenDataLoading,
        customScreenDatas,
        customScreens,
        customScreenDataSubmitting,
        monitoringResults,
        processExecutionsLoading,
        processExecutions = []
    } = useReduxSelections("sicav");
    const { macroScriptList } = useReduxSelections("macroScript");
    const monitoringMacros = React.useMemo(() => macroScriptList.filter(i => (i.type === "MonitoringMacroScriptModel" || i.type === "MonitoringMacroScriptSummaryModel") && i.singleScope === IUniverseScopeTypeModel.TargetSicav), [macroScriptList]);
    const { referenceCountries = {} } = useReduxSelections("reference");
    const { classificationTypes } = useReduxSelections("entityClassificationType");
    const { navigationNavigate } = useReduxActions("navigation");
    const {
        sicavDelete,
        sicavAddEntityInDictionary,
        sicavCustomScreenDataSubmit,
        sicavMonitoringResultLoad
    } = useReduxActions("sicav");
    const { navigationActiveScreen } = useReduxSelections("navigation");
    const { parameters: { sicavExtensionFields = [] } } = useReduxSelections("parameters");

    const isGranted = useGrants();

    const handleNewExecutionProcess = React.useCallback(() => {
        navigationNavigate({ screenKey: "ProcessExecutions", sectionKey: "detail", parameters: { type: "EntityProcessExecutionModel", targetId: current?.id ?? 0 } });
    }, [current?.id, navigationNavigate])

    const handleBack = React.useCallback(() => navigationNavigate(undefined), [navigationNavigate]);
    const handleLoadResult = React.useCallback((id: number) => current && sicavMonitoringResultLoad({ id, targetId: current.id }), [current, sicavMonitoringResultLoad]);
    const title = !!current?.id ? current.name : 'New sicav';
    const handleDelete = React.useCallback(() => {
        if (current?.id) {
            sicavDelete(current.id);
        }
    }, [current?.id, sicavDelete])

    const handleTabValueChanged = React.useCallback((value: any) => navigationNavigate({ sectionKey: value as string }), [navigationNavigate]);
    const handleEntitySelected = React.useCallback((entityId: number) => {
        const entity = dictionaries.entities[entityId];
        if (!entity) {
            return;
        }
        switch (entity.type) {
            case "PersonSummaryModel":
                navigationNavigate({ screenKey: "People", sectionKey: "detail", parameters: { id: entityId } });
                break;
            case "EntityGroupSummaryModel":
                navigationNavigate({ screenKey: "EntityGroups", sectionKey: "detail", parameters: { id: entityId } });
                break;
            case "CompanySummaryModel":
                navigationNavigate({ screenKey: "Companies", sectionKey: "detail", parameters: { id: entityId } });
                break;
            case "SicavSummaryModel":
                navigationNavigate({ screenKey: "Sicavs", sectionKey: "detail", parameters: { id: entityId } });
                break;
        }
    }, [dictionaries.entities, navigationNavigate]);
    const handleDataSubmit = (customScreenId: number, values: any) => current && sicavCustomScreenDataSubmit({ id: current.id, customScreenId, values });
    const canEditCustomData = isGranted(IFeatureModel.UniverseCustomDataWrite);
    if (!current || !formValues) {
        return <DetailPanelLoading onBackClick={handleBack} tabNumber={2} hasSubTitle={false} />;
    }
    const tabValue = navigationActiveScreen?.activeSectionKey;
    const tabs: ISubMenu[] = [{
        label: "Data",
        value: "detail"
    }, {
        label: "Entities",
        value: "entities"
    }];
    if (!!monitoringMacros.length) {
        tabs.push({
            label: "Dashboard",
            value: "dashboard"
        });
    }

    if (formValues.sicav?.id && !!customScreens?.length && !formValues.sicav.isUnderManagement) {
        tabs.push({
            label: "Custom Data",
            value: "customViews"
        });
    }
    if (formValues?.sicav?.id) {
        tabs.push({
            label: "Processes",
            value: "processes"
        });
    }
    const extraActionButtons: IActionButton[] = [{
        label: "Add Process Execution",
        icon: NotebookCheckOutlineIcon,
        onClick: handleNewExecutionProcess,
        feature: IFeatureModel.UniverseDueDiligenceWrite//,
    }];
    return <DetailPanel
        endAdornment={formValues.sicav.isUnderManagement ? <Chip icon={<BriefcaseAccountIcon />} size="small" label="Under management" /> : undefined}
        isQuerying={loading || saving || customScreenDataLoading || customScreenDataSubmitting || processExecutionsLoading}
        title={title}
        subTitle="Sicav"
        canSave={dirty && isValid}
        onSaveClick={formValues.sicav.isUnderManagement ? undefined : submitForm}
        canDelete={!!current?.id}
        onDeleteClick={formValues.sicav.isUnderManagement ? undefined : handleDelete}
        onBackClick={handleBack}
        badge={!current?.id ? "new" : undefined}
        tabs={tabs}
        tabValue={tabValue}
        actions={extraActionButtons}
        saveAllowed={IFeatureModel.UniverseWrite}
        deleteAllowed={IFeatureModel.UniverseDelete}
        onTabValueChanged={handleTabValueChanged}>
        {(tabValue === "detail") && <WriteAccess value={IFeatureModel.UniverseWrite}> <EntityData
            sicavExtensionFields={sicavExtensionFields}
            companyExtensionFields={[]}
            personExtensionFields={[]}
            formImageFieldName={oProps<ISicavData>().path("imageFile")}
            formFieldName={oProps<ISicavData>().path("sicav")}
            classificationTypes={classificationTypes}
            dictionaries={dictionaries}
            onEntityLoaded={sicavAddEntityInDictionary}
            referenceCountries={referenceCountries} /></WriteAccess>}
        {(tabValue === "dashboard" && !!monitoringMacros.length) && <MonitoringResultGroups
            monitoringMacros={monitoringMacros}
            onLoadResult={handleLoadResult}
            resultGroups={monitoringResults} />}
        {(tabValue === "entities") && <EntityList
            dictionaries={dictionaries}
            formFieldName={oProps<ISicavData>().path("sicav")}
            onEntityLoaded={sicavAddEntityInDictionary}
            onEntitySelected={handleEntitySelected} />}
        {(tabValue === "customViews" && !!customScreenDatas && !!customScreens?.length && !formValues.sicav.isUnderManagement) && <CustomViewDatas
            canEdit={canEditCustomData}
            dictionaries={dictionaries}
            customScreenLoading={customScreenDataLoading}
            dataSubmitting={customScreenDataSubmitting}
            customScreens={customScreens}
            onDataSubmit={handleDataSubmit}
            customScreenDatas={customScreenDatas} />}
        {(tabValue === "processes") && <ProcessExecutionList
            processExecutions={processExecutions}
            dictionaries={dictionaries} />}
    </DetailPanel>
}