import DetailPanel from "tools/components/DetailPanel";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import MonitoringResultGroups, { MonitoringResult } from "components/global/MonitoringResults";
import { IDashboardResultModel, IFeatureModel, IGetMonitoringResultGroupModel } from "proxy/apiProxy";
import { Typography } from "@material-ui/core";
import { DashboardRunner } from "features/DevelopmentItem/Designers/Dashboard/DashboardRunner";
function isNewResult(dashboardResult?: IGetMonitoringResultGroupModel | IDashboardResultModel): dashboardResult is IDashboardResultModel {
    return (dashboardResult as IDashboardResultModel)?.error || (dashboardResult as IDashboardResultModel)?.result;
}
export default function Dashboard() {
    const { dashboardResultLoading, dashboardResult, currentNewDashboard } = useReduxSelections("dashboard");
    const { dashboardMonitoringResultLoad } = useReduxActions("dashboard");
    const { navigationNavigate } = useReduxActions("navigation");
    const isGranted = useGrants();
    if (!isGranted(IFeatureModel.MacroExecutionSetupAndDashboardExecute)) {
        return <DetailPanel title="Monitoring">
            <Typography variant={"h6"} gutterBottom={true}>You are not granted to view dashboards</Typography>
        </DetailPanel>
    }
    const handleRefresh = () => {
        if (currentNewDashboard) {
            dashboardMonitoringResultLoad(-currentNewDashboard.id);
        }
        else
            if ((dashboardResult as IGetMonitoringResultGroupModel)?.dashboard?.id) {
                dashboardMonitoringResultLoad((dashboardResult as IGetMonitoringResultGroupModel).dashboard.id);
            }
    }
    const handleBack = () => navigationNavigate(undefined);
    if (!isNewResult(dashboardResult)) {
        if (dashboardResult && dashboardResult.monitoringResultGroups.length === 1) {
            const monitoringResult = dashboardResult.monitoringResultGroups[0];
            return <DetailPanel
                isQuerying={dashboardResultLoading || !dashboardResult}
                title={dashboardResult?.dashboard?.name ?? "Monitoring"}
                subTitle={monitoringResult.name}
                onBackClick={handleBack}
                onRefreshClick={handleRefresh}>
                <MonitoringResult result={monitoringResult.result} />
            </DetailPanel>
        }
        else
            return <DetailPanel
                isQuerying={dashboardResultLoading || !dashboardResult}
                title={dashboardResult?.dashboard?.name ?? "Monitoring"}
                onBackClick={handleBack}
                onRefreshClick={handleRefresh}>
                {dashboardResult && <MonitoringResultGroups resultGroups={dashboardResult.monitoringResultGroups} />}
            </DetailPanel>
    }
    else {
        return <DetailPanel
            isQuerying={dashboardResultLoading || !dashboardResult}
            title={currentNewDashboard?.name ?? "Monitoring"}
            onBackClick={handleBack}
            onRefreshClick={handleRefresh}>
            {dashboardResult && <DashboardRunner value={dashboardResult.result ?? {}} />}
        </DetailPanel>

    }
}
