import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IProcessDefinitionSummaryModel, IProcessDefinitionModel } from "proxy/apiProxy";
import AddIcon from '@material-ui/icons/Add';
import { IActionButton } from "tools/components/FabContainer";
import { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";

export default function ProcessDefinitions() {
    const { classificationTypes, classificationTypesFlat } = useReduxSelections("processClassificationType");
    const { classificationTypesColumns, classificationTypesState } = getClassificationTypesColumnDefinitions(classificationTypes, classificationTypesFlat, ({ classifications }: (IProcessDefinitionModel | IProcessDefinitionSummaryModel)) => classifications);
    const { processDefinitions = [], processDefinitionAllLoading } = useReduxSelections("processDefinition");
    const { navigationNavigate } = useReduxActions("navigation");

    const columns: IColumnDefinition[] = [
        { name: "Code", title: "Code", getCellValue: ({ code }: IProcessDefinitionSummaryModel) => code, filteringEnabled: true },
        { name: "Name", title: "Name", getCellValue: ({ name }: IProcessDefinitionSummaryModel) => name["en"] ?? name.en, filteringEnabled: true },
        ...classificationTypesColumns
    ];
    const state: IGridState = {
        "Code": { width: 280 },
        "Name": { width: 280 },
        ...classificationTypesState
    };
    const getRowKey = ({ id }: (IProcessDefinitionSummaryModel | IProcessDefinitionModel)) => id;

    const handleRowSelect = ({ id }: (IProcessDefinitionSummaryModel | IProcessDefinitionModel)) => navigationNavigate({ sectionKey: "detail", parameters: { id } });

    const extraActionButtons: IActionButton[] = [{
        label: "Add process",
        icon: AddIcon,
        onClick: () => navigationNavigate({ sectionKey: "detail", parameters: { id: 0 } }),
    }];

    return <SearchPanel
        title="Processes"
        isQuerying={processDefinitionAllLoading}
        actions={extraActionButtons}>
        <ExtendedGrid
            getRowId={getRowKey}
            columns={columns}
            rows={processDefinitions}
            onRowClick={handleRowSelect}
            initialState={state}
            userCanGroup={true}
            defaultExportFileName="Processes.xlsx" />
    </SearchPanel>
}
