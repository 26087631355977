import * as React from "react";
import { Survey } from "survey-react-ui";
import { Model, setLicenseKey } from "survey-core";
import 'survey-core/defaultV2.min.css';
import DialogPanel, { IActionButton } from "./DialogPanel";

setLicenseKey("YjRhNWFkNWItNDMyMi00MGM0LTg3OGItYTZkZjc3MmRjMmYwOzE9MjAyNS0wNC0wMiwyPTIwMjUtMDQtMDI=");

export interface ISurveyRunnerComponentProps {
    template: any;
    onComplete: (values: any) => void;
    initialValues?: any;
}


/**
 * @deprecated Use SurveyRunnerDialog
 */
export function SurveyRunnerComponent({ template, initialValues, onComplete }: ISurveyRunnerComponentProps) {
    const surveyModel = React.useMemo(() => new Model(typeof template === "string" ? template : JSON.stringify(template)), [template]);
    React.useEffect(() => {
        if (initialValues) {
            for (const valueName in initialValues) {
                if (Object.prototype.hasOwnProperty.call(initialValues, valueName)) {
                    const element = initialValues[valueName];
                    surveyModel.setValue(valueName, element);
                }
            }
        }
    }, [initialValues, surveyModel]);
    const handleComplete = (sender: Model) => onComplete(sender.getAllValues());
    return <Survey model={surveyModel} onComplete={handleComplete} />;
}


export interface ISurveyRunnerDialogProps {
    isOpened?: boolean;
    onCancel: () => void;
    cancelLabel?: string;
    onReset?: () => void;
    resetLabel?: string;
    onOk?: (values: any) => void;
    okLabel?: string;
    onComplete?: (values: any) => void;
    completeLabel?: string;
    template: any;
    initialValues?: any;
}
// Keep in sync with FrontEnd SurveyRunnerDialog
export default function SurveyRunnerDialog({ isOpened = true, initialValues, template, onOk, okLabel, onComplete, completeLabel, onCancel, cancelLabel, onReset, resetLabel}: ISurveyRunnerDialogProps) {
    const [canShowComplete, setCanShowComplete] = React.useState(false);

    const surveyModel = React.useMemo(() => {
        return new Model(typeof template === "string" ? template : JSON.stringify(template));
    }, [template]);

    React.useEffect(() => {
        const handleValueChanged = (model: Model) => {
            setCanShowComplete(() => model.calcIsCompleteButtonVisible());
        };
        surveyModel.onValueChanged.add(handleValueChanged);
        return () => surveyModel.onValueChanged.remove(handleValueChanged);
    }, [surveyModel.onValueChanged]);

    const actionButtons = React.useMemo(() => {
        const buttons: IActionButton[] = [];
        if (onReset) {
            buttons.push({
                label: resetLabel ?? "Reset",
                onClick: () => {
                    onReset();
                },
                disabled: false
            });
        }
        if (onOk) {
            buttons.push({
                label: okLabel ?? "Ok",
                onClick: () => {
                    onOk(surveyModel.getAllValues());
                },
                disabled: false
            });
        }
        if (onComplete) {
            buttons.push({
                label: completeLabel ?? "Complete",
                onClick: () => {
                    onComplete(surveyModel.getAllValues());
                },
                disabled: !canShowComplete
            });
        }
        return buttons;
    }, [onReset, onOk, onComplete, resetLabel, okLabel, surveyModel, completeLabel, canShowComplete]);

    const handleComplete = React.useCallback((sender: Model) => {
        if (onComplete) {
            onComplete(sender.getAllValues());
        }
    }, [onComplete]);

    React.useEffect(() => {
        if (initialValues) {
            for (const valueName in initialValues) {
                if (Object.prototype.hasOwnProperty.call(initialValues, valueName)) {
                    const element = initialValues[valueName];
                    surveyModel.setValue(valueName, element);
                }
            }
        }
    }, [initialValues, surveyModel]);

    return <DialogPanel isOpened={isOpened} onBackClick={onCancel} closeLabel={cancelLabel} maxWidth="lg"
                        actions={actionButtons}>
        <Survey model={surveyModel} onComplete={handleComplete} />
    </DialogPanel>
}