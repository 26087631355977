import * as React from "react";
import produce from "immer";
import { splitPascalCase, toDictionary } from "tools/lib/utility";
import { ColumnType, IColumnDefinition, IColumnState, IGridState } from "tools/components/ExtendedGrid";
import { IFieldsDashboardFormatting, FieldFormatting, PathToValue, FieldType, IArrayDashboardFormatting, IWidgetDashboardFormatting, IGridArrayDashboardFormatting, IWidget, OneOrMany, IObjectFieldsDashboardFormatting, ILayoutDashboardFormatting, WidgetFormatting, ArrayFormatting, MiscFieldTypes, TimeFieldType, NumericFieldType, ScreenLinkType, FileLinkType } from "./DashboardFormattingContracts";
import { FileLink, ReportLink, ScreenLink } from "./DashboardComponents";

export interface IDashboardContext {
    data: any;
    selections: any;
}

export const DashboardContext = React.createContext<IDashboardContext>({ data: {}, selections: {} });
function isValue(v: any): boolean {
    return !(typeof v === "object" && !(v instanceof Date)) && !Array.isArray(v);
}
function isFieldsContainer(definition: any) {
    // return Object.values(definition).findIndex(isValue) >= 0;
    return Object.keys(definition).map(key => ({ key, value: definition[key] })).filter(({ key }) => !key.endsWith("_format")).map(i => i.value).findIndex(isValue) >= 0;
}
export function isScreenLinkType(fieldType: FieldFormatting["type"]): fieldType is ScreenLinkType {
    return Object.values(ScreenLinkType).includes(fieldType as ScreenLinkType);
}
export function isFileLinkType(fieldType: FieldFormatting["type"]): fieldType is FileLinkType {
    return Object.values(FileLinkType).includes(fieldType as FileLinkType);
}
export function useFieldFormats(definition: any | any[], formatting: IFieldsDashboardFormatting | null) {
    return React.useMemo(() => {
        const formattings = getFieldFormats(definition, formatting);
        return Object.keys(formattings).map(key => ({ key, label: getLabelFromKey(key), hidden: false, type: MiscFieldTypes.String as FieldType, ...formattings[key] }));
    }, [definition, formatting]);
}
export function getFieldFormats(definition: any | any[], formatting: IFieldsDashboardFormatting | null) {
    return { ...assumeFormats(definition), ...(formatting?.formats ?? {}) };
}
function useRowKey(formatting: IArrayDashboardFormatting | null) {
    return React.useCallback((value: any) => value[formatting?.id ?? "Id"], [formatting?.id]);
}
export function useDetailFields(
    objectDefinition: any,
    formatting: IFieldsDashboardFormatting | null
) {
    const fieldFormats = useFieldFormats(objectDefinition, formatting);
    const fields = React.useMemo(() => sortDisplay(formatting, fieldFormats), [fieldFormats, formatting]);
    return fields;
}
function sortDisplay<T extends IWidgetDashboardFormatting, TC extends { hidden?: boolean, key: string | number }>(formatting: T | null, fieldFormats: TC[]) {
    const hiddens = toDictionary(formatting?.hiddens ?? [], i => i, () => true);
    const visibles = fieldFormats.filter(ff => !ff.hidden).filter(({ key }) => !Object.keys(hiddens).length || !hiddens[key]);

    if (!(formatting?.positions ?? []).length) {
        return visibles;
    }
    else {
        const positions = toDictionary((formatting?.positions ?? []).map((property, idx) => ({ property, position: idx })), i => i.property, i => i.position);
        return visibles.sort((a, b) => (positions[a.key] ?? 999999) - (positions[b.key] ?? 999999))
    }
}
export function useWidgets(value: any, field: PathToValue, formatting: ILayoutDashboardFormatting | null) {
    return React.useMemo(() => getWidgets(value, field, formatting), [value, field, formatting]);
}
function getWidgets(
    value: any,
    field: PathToValue,
    formatting: ILayoutDashboardFormatting | null
): (IWidget & { key: string | number })[] {
    const widgets = Object.keys(value).filter(i => !i.endsWith("_format")).flatMap(property => getSubWidgets(value[property], value[`${property}_format`] ?? [], formatting, [...field, property]));
    return sortDisplay(formatting, widgets);
}
export function isArrayFormatting(widgetFormat: WidgetFormatting): widgetFormat is ArrayFormatting {
    return !!(widgetFormat as IArrayDashboardFormatting).displayCondition || !!(widgetFormat as IArrayDashboardFormatting).id;
}
export function getSubWidgets(propertyValue: any, formatFromParent: OneOrMany<WidgetFormatting>, formatting: ILayoutDashboardFormatting | null, field: PathToValue) {
    if (!Array.isArray(formatFromParent)) {
        formatFromParent = [formatFromParent];
    }
    var format: OneOrMany<WidgetFormatting> = propertyValue._format ?? [];
    if (!Array.isArray(format)) {
        format = [format];
    }
    const property = field.at(-1);
    const labelFromKey = property === null || typeof (property) === "undefined" ? "" : getLabelFromKey(typeof (property) === "string" ? property : String(property));
    const widgetType = assumeWidgetType(propertyValue);
    var widgetFormattings: WidgetFormatting[] = [...formatFromParent, ...format].map(({ ...widgetFormat }) => {
        const { label } = widgetFormat;
        widgetFormat.type ??= widgetType ?? "fields";
        if (widgetFormat.type !== "fields" && widgetFormat.type !== "layout") {
            widgetFormat.id ??= "Id";
            if (widgetFormat.type === "detail") {
                widgetFormat.displayCondition ??= `$${field.map(f => "." + f).join()}.${widgetFormat.id}=@.${widgetFormat.id}`;
            }
        }
        return ({ ...widgetFormat, label: label ?? labelFromKey });
    });
    if (!widgetFormattings.length) {
        switch (widgetType) {
            case "grid":
                widgetFormattings.push({ type: "grid", label: labelFromKey, hiddens: ["Id"] } as IGridArrayDashboardFormatting);
                break;
            case "fields":
                widgetFormattings.push({ type: "fields", label: labelFromKey } as IObjectFieldsDashboardFormatting);
                break;
            case "layout":
                widgetFormattings.push({
                    type: "layout", label: labelFromKey,
                    templateColumns: "repeat(auto-fit, minmax(500px, 1fr))",
                    // templateRows: "repeat(auto-fit, minmax(400px, 1fr))"
                } as ILayoutDashboardFormatting);
                break;
        }
    }
    if (!!formatting?.templateAreas) {
        widgetFormattings = widgetFormattings.filter(f => !!f.area);
    }
    // const newField = property ? [...field, property] : [...field];
    return widgetFormattings.map(i => ({ key: property ?? "", value: propertyValue, field, formatting: i }));
}
function assumeWidgetType(value: any): WidgetFormatting["type"] | undefined {
    if (Array.isArray(value)) {
        return "grid";
    }
    else if (typeof (value) === "object") {
        if (isFieldsContainer(value)) {
            return "fields";
        }
        else {
            return "layout";
        }
    }
    return;
}
export function useExtendedGrid(definition: any | any[], formatting: IGridArrayDashboardFormatting | null) {
    const fieldFormats = useFieldFormats(definition, formatting);
    const getRowKey = useRowKey(formatting);

    const columns: { name: string, title?: string, columnType?: ColumnType }[] = React.useMemo(() => fieldFormats.map((fieldFormat) => {
        const { key, label, type, backgroundColor, foregroundColor } = fieldFormat;
        var newLabel = label;
        const getCellValue: IColumnDefinition["getCellValue"] = (function () {
            if (fieldFormat.type === "report") {
                return row => {
                    const v = row[key];
                    var asOfDate = fieldFormat.asOfDate;
                    if (typeof (asOfDate) === "string") {
                        if (asOfDate.startsWith("@.")) {
                            asOfDate = row[asOfDate.substring(2)] as Date;
                        } else {
                            asOfDate = undefined;
                        }
                    }
                    if (newLabel.startsWith("@.")) {
                        newLabel = row[newLabel.substring(2)] as string;
                    }
                    if (typeof v === "number") {
                        return <ReportLink
                            fileType={fieldFormat.fileType}
                            id={row[key]}
                            textValue={newLabel}
                            reportTemplate={fieldFormat.templateName}
                            targetType={fieldFormat.targetType}
                            taskCode={fieldFormat.taskCode}
                            culture={fieldFormat.culture}
                            asOfDate={asOfDate} />
                    }
                    return null;
                }

            }
            else if (isScreenLinkType(type)) {
                return row => {
                    const v = row[key];
                    if (newLabel.startsWith("@.")) {
                        newLabel = row[newLabel.substring(2)] as string;
                    }
                    if (typeof v === "number") {
                        return <ScreenLink id={row[key]} textValue={newLabel} referenceType={type} />
                    }
                    return null;
                }
            }
            else if (isFileLinkType(type)) {
                return row => {
                    const v = row[key];
                    if (newLabel.startsWith("@.")) {
                        newLabel = row[newLabel.substring(2)] as string;
                    }
                    if (typeof v === "number") {
                        return <FileLink id={row[key]} textValue={newLabel} referenceType={type} />
                    }
                    return null;
                }
            }
            return undefined;
        })();
        return ({
            name: key,
            title: newLabel,
            getCellValue,
            filteringEnabled: true,
            columnType: (function () {
                switch (type) {
                    case MiscFieldTypes.String: return "text";
                    case TimeFieldType.DateTime: return "dateTime";
                    case NumericFieldType.Percentage2: return "precisePercentage2";
                    case NumericFieldType.Percentage4: return "precisePercentage4";
                    case NumericFieldType.Decimal4: return "preciseDecimal";
                    case MiscFieldTypes.Markdown: return "text";
                    default: return type;
                }
            })(),
            backgroundColor: makeColorGetter(backgroundColor, "backgroundColor"),
            foregroundColor: makeColorGetter(foregroundColor, "foregroundColor")
        } as IColumnDefinition);
    }), [fieldFormats]);
    const hiddens = React.useMemo(() => new Set([...fieldFormats.filter(({ hidden }) => !!hidden).map(({ key }) => key), ...formatting?.hiddens ?? []]), [fieldFormats, formatting?.hiddens]);
    const sorts = React.useMemo(() => {
        const srts = formatting?.sorts ?? [];
        return (Array.isArray(srts) ? srts : [srts]).map((srt, idx) => {
            if (!(srt as { desc: string; }).desc) {
                return { key: srt as string, idx: idx + 1 };
            }
            else {
                return { key: (srt as { desc: string; }).desc, idx: -(idx + 1) };
            }
        });
    }, [formatting?.sorts]);
    const groups = React.useMemo(() => {
        const grps = formatting?.groups ?? [];
        return (Array.isArray(grps) ? grps : [grps]).map((grp, idx) => ({ key: grp, idx: -(idx + 1) }));
    }, [formatting?.groups]);
    const state: IGridState = React.useMemo(() => toDictionary(fieldFormats, ({ key }) => key, ({ key }) => {
        const columnPosition = (formatting?.positions ?? []).findIndex(p => p === key);
        return {
            hidden: hiddens.has(key),
            columnPosition: columnPosition < 0 ? 9999999 : columnPosition,
            sortingPosition: sorts.find(p => p.key === key)?.idx,
            groupingPosition: groups.find(p => p.key === key)?.idx,
            width: fieldFormats.find(p => p.key === key)?.width
        } as IColumnState;
    }), [fieldFormats, hiddens, formatting, sorts, groups]);
    return { columns, state, getRowKey };
}
function makeColorGetter<K extends keyof FieldFormatting>(color: string | { value: number, color: string }[] | null | undefined, property: K) {
    if (!color) {
        return undefined;
    }
    if (Array.isArray(color)) {
        return color;
    }
    return (row: any) => {
        if (color.startsWith("@.")) {
            return row[color.substring(2)] as string;
        }
        return color;
    };
}

export function assumeFormats(definition: any | any[]) {
    if (!Array.isArray(definition)) {
        definition = [definition];
    }

    return (definition as any[]).reduce<Record<string, FieldFormatting>>((a, values) => {
        for (const key in values) {
            if (Object.prototype.hasOwnProperty.call(values, key) && !key.endsWith("_format")) {
                const value = values[key];
                switch (typeof (value)) {
                    case "bigint":
                    case "number":
                        if (Number.isInteger(value)) {
                            if (!a[key]) {
                                a[key] = { type: NumericFieldType.Integer, label: getLabelFromKey(key), hidden: false };
                            }
                        }
                        else if (!a[key]) {
                            a[key] = { type: NumericFieldType.Decimal, label: getLabelFromKey(key), hidden: false };
                        }
                        else {
                            a[key].type = NumericFieldType.Decimal;
                        }

                        break;
                    case "string":
                        if (!a[key]) {
                            a[key] = { type: MiscFieldTypes.String, label: getLabelFromKey(key), hidden: false };
                        }
                        break;
                    case "boolean":
                        if (!a[key]) {
                            a[key] = { type: MiscFieldTypes.Boolean, label: getLabelFromKey(key), hidden: false };
                        }
                        break;
                    default:
                        if (value instanceof Date) {
                            if (isDate(value)) {
                                if (!a[key]) {
                                    a[key] = { type: TimeFieldType.Date, label: getLabelFromKey(key), hidden: false };
                                }
                            }
                            else if (!a[key]) {
                                a[key] = { type: TimeFieldType.DateTime, label: getLabelFromKey(key), hidden: false };
                            }
                            else {
                                a[key].type = TimeFieldType.DateTime;
                            }
                        }
                }
            }
        }
        return a;
    }, {});
}
function isDate(date: Date) {
    return !date.getHours() &&
        !date.getMinutes() &&
        !date.getSeconds() &&
        !date.getMilliseconds();
}
export function getLabelFromKey(key: string | PathToValue) {
    if (typeof key === "string") {
        return splitPascalCase(key);
    }
    if (!key) {
        return "";
    }
    key = String(key.at(-1));
    return splitPascalCase(key) ?? "";
}
export function useFilter(arrayDefinition: any[], displayCondition: string | undefined, forDetail?: boolean) {
    const globalDefinition = React.useContext(DashboardContext);
    const filter = React.useMemo(() => getFilter(globalDefinition, displayCondition, forDetail ?? false), [globalDefinition, displayCondition, forDetail]); // (i:any)=>boolean
    return React.useMemo(() => {
        if (filter) {
            return [...arrayDefinition].filter(filter);
        }
        else {
            return arrayDefinition;
            // const selectedValue = globalDefinition.selections[field.join(".")];
            // if (selectedValue) {
            //     return [selectedValue];
            // }
            // else {
            //     return arrayDefinition;
            // }
        }
    }, [arrayDefinition, filter]);
}
function getPathValue(data: any, pathToValue: PathToValue) {
    for (const pathSegment of pathToValue) {
        if (typeof (data) === "undefined" || data === null) {
            return undefined;
        }
        data = data[pathSegment];
    }
    return data;
}
function getSelectionPathValue(data: any, [...pathToValue]: PathToValue): any[] {
    const lastSegment = pathToValue.splice(-1)[0];
    const objs = getPathValue(data, pathToValue) as any[] | undefined;
    if (!objs) return [];
    return objs.map(o => getPathValue(o, [lastSegment]));
}
function setPathValue(data: any, pathToValue: PathToValue, value: any) {
    for (let index = 0; index < pathToValue.length - 1; index++) {
        const pathSegment = pathToValue[index];
        if (data[pathSegment] === null || typeof (data[pathSegment]) === "undefined") {
            data[pathSegment] = {};
        }
        data = data[pathSegment];
    }
    data[pathToValue.at(-1) ?? ""] = value;
}
function buildPath(absolutePath: string) {
    const [root, ...path] = absolutePath.split(".").map(e => { const p = Number.parseInt(e); return Number.isNaN(p) ? e : p; });
    return { rootType: (root === "$" ? "root" : "row") as ("root" | "row"), pathToValue: path };
}
function getFilter({ selections, data }: IDashboardContext, displayCondition: string | undefined, forDetail: boolean): ((items: any[]) => boolean) | null {
    if (!displayCondition) {
        return null;
    }

    var paths = toDictionary(displayCondition.split("=").map(buildPath), i => i.rootType);

    const comparisonValues: any[] | undefined = getSelectionPathValue(selections, paths.root.pathToValue);
    if (comparisonValues === null || typeof (comparisonValues) === "undefined") {
        return null;
    }
    if (!forDetail && !comparisonValues.length) {
        return null;
    }

    return (item: any) => {
        const pv = getPathValue(item, paths.row.pathToValue);
        return comparisonValues.findIndex(cv => cv === pv) >= 0;
    }
}
export type UpdateSelectionCallback = (path: PathToValue, value: any[]) => void;
export function useUpdateSelection(value: any) {
    const [context, setContext] = React.useState({ data: value, selections: {} });
    const handleUpdateSelection = React.useCallback((path: PathToValue, value: any) => {
        const { data, selections } = context;
        const newSelections = produce(selections, draft => void setPathValue(draft, path, value));
        setContext({ data, selections: newSelections });
    }, [context]);
    return [context, handleUpdateSelection] as [IDashboardContext, UpdateSelectionCallback];
}