import produce from "immer";
import { IGetMonitoringResultGroupModel, IMonitoringMacroCallSummaryModel, IDocumentDefinitionSummaryModel, IGetMonitoringMacroCallsModel, MacroScriptSummaryModel, IBatchSummaryModel, IGetReportsModel, IExecutionStatusResultModel, IReportTemplateSummaryModel, ReportSummaryModel, IGetBatchesModel, IGetDevelopmentItemsModel, IDashboardMacroDevelopmentItemSummaryModel, IDashboardResultModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IMacroScriptExecuteScript } from "../features/MacroScript/slice";
export interface IState {
    dashboardResultLoading: boolean;
    dashboardResultLoadingAll: boolean;
    currentNewDashboard?: IDashboardMacroDevelopmentItemSummaryModel;
    dashboardResult?: IGetMonitoringResultGroupModel | IDashboardResultModel;
    dashboards: (IMonitoringMacroCallSummaryModel | IDashboardMacroDevelopmentItemSummaryModel)[];
    // newDashboards?: IDashboardMacroDevelopmentItemSummaryModel[];
    dictionaries: {
        macroScripts: Record<string | number, MacroScriptSummaryModel>;
        reportTemplates: Record<string | number, IReportTemplateSummaryModel>;
        documentDefinitions: Record<string | number, IDocumentDefinitionSummaryModel>;
    }


    macroScripts?: MacroScriptSummaryModel[];
    macroScriptsLoadingAll: boolean;
    macroScriptRunning: {
        [id: number]: boolean;
    }


    batches?: IBatchSummaryModel[];
    batchesLoadingAll: boolean;
    batchLaunching: {
        [id: number]: boolean;
    }


    reports?: ReportSummaryModel[];
    reportsLoadingAll: boolean;
    reportIssuing: {
        [id: number]: boolean;
    }
}
export interface IMacroScriptExecuted extends IExecutionStatusResultModel {
    id: number;
}
export const ActionFactories = produceActionFactories({
    dashboardSetCurrentNew: (payload: IDashboardMacroDevelopmentItemSummaryModel) => undefined,
    dashboardMonitoringLoadAll: () => undefined,
    dashboardMonitoringLoadedAll: (payload: { old: IGetMonitoringMacroCallsModel, new: IGetDevelopmentItemsModel }) => payload,
    dashboardMonitoringResultLoad: (payload: number) => payload,
    dashboardMonitoringResultLoaded: (payload: IGetMonitoringResultGroupModel | IDashboardResultModel) => payload,

    dashboardMacroLoadAll: () => undefined,
    dashboardMacroLoadedAll: (payload: MacroScriptSummaryModel[]) => payload,
    dashboardMacroScriptExecute: (payload: IMacroScriptExecuteScript) => payload,
    dashboardMacroScriptExecuted: (payload: IMacroScriptExecuted) => payload,

    dashboardBatchLoadAll: () => undefined,
    dashboardBatchLoadedAll: (payload: IGetBatchesModel) => payload,
    dashboardBatchRequestExecution: (payload: number) => payload,
    dashboardBatchRequestedExecution: (payload: number) => payload,

    dashboardReportLoadAll: () => undefined,
    dashboardReportLoadedAll: (payload: IGetReportsModel) => payload,
    dashboardReportGenerate: (payload: number) => payload,
    dashboardReportGenerated: (payload: number) => payload,
});
function isNewResult(dashboardResult?: IGetMonitoringResultGroupModel | IDashboardResultModel): dashboardResult is IDashboardResultModel {
    return (dashboardResult as IDashboardResultModel).error || (dashboardResult as IDashboardResultModel).result;
}

export function reducer(
    state: IState = {
        dashboardResultLoading: false,
        dashboardResultLoadingAll: false,
        dashboards: [],
        dictionaries: {
            macroScripts: {},
            reportTemplates: {},
            documentDefinitions: {}
        },
        batchLaunching: {},
        reportIssuing: {},
        macroScriptRunning: {},
        macroScriptsLoadingAll: false,
        batchesLoadingAll: false,
        reportsLoadingAll: false
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "dashboardSetCurrentNew":
                draft.currentNewDashboard = action.payload;
                break;
            case "dashboardMonitoringResultLoad":
                draft.dashboardResultLoading = true;
                break;
            case "dashboardMonitoringResultLoaded":
                draft.dashboardResult = action.payload;
                if (!isNewResult(action.payload)) {
                    delete draft.currentNewDashboard;
                }
                draft.dashboardResultLoading = false;
                break;
            case "dashboardMonitoringLoadAll":
                draft.dashboardResultLoadingAll = true;
                break;
            case "dashboardMonitoringLoadedAll":
                draft.dashboardResultLoadingAll = false;
                const newDashboards = action.payload.new.developmentItems.filter(i => i.type === "DashboardMacroDevelopmentItemSummaryModel") as IDashboardMacroDevelopmentItemSummaryModel[];
                draft.dashboards = [...action.payload.old.macros, ...newDashboards];
                draft.dictionaries.macroScripts = { ...draft.dictionaries.macroScripts, ...action.payload.old.macroScripts };
                break;

            case "dashboardMacroLoadAll":
                draft.macroScriptsLoadingAll = true;
                break;
            case "dashboardMacroLoadedAll":
                draft.macroScriptsLoadingAll = false;
                draft.macroScripts = action.payload;
                break;
            case "dashboardMacroScriptExecute":
                draft.macroScriptRunning[action.payload.id] = true;
                break;
            case "dashboardMacroScriptExecuted":
                draft.macroScriptRunning[action.payload.id] = false;
                break;
            case "dashboardBatchLoadAll":
                draft.batchesLoadingAll = true;
                break;
            case "dashboardBatchLoadedAll":
                draft.batchesLoadingAll = false;
                draft.batches = action.payload.batches;
                break;
            case "dashboardBatchRequestExecution":
                draft.batchLaunching[action.payload] = true;
                break;
            case "dashboardBatchRequestedExecution":
                draft.batchLaunching[action.payload] = false;
                break;
            case "dashboardReportLoadAll":
                draft.reportsLoadingAll = true;
                break;
            case "dashboardReportLoadedAll":
                draft.reportsLoadingAll = false;
                draft.reports = action.payload.reports;
                draft.dictionaries.reportTemplates = { ...draft.dictionaries.reportTemplates, ...action.payload.reportTemplates };
                draft.dictionaries.documentDefinitions = { ...draft.dictionaries.documentDefinitions, ...action.payload.documentDefinitions };
                break;
            case "dashboardReportGenerate":
                draft.reportIssuing[action.payload] = true;
                break;
            case "dashboardReportGenerated":
                draft.reportIssuing[action.payload] = false;
                break;
        }
    });
}
