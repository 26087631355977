import { IFeatureModel, IBatchSummaryModel, MacroScriptSummaryModel } from "proxy/apiProxy";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanel from "tools/components/DetailPanel";
import { Grid, TextField, Card, CardContent, Typography } from "@material-ui/core";
import ExplorerElement from './ExplorerElement';
import FileClockOutlineIcon from 'mdi-material-ui/FileClockOutline'
import React from "react";
import FileCogOutlineIcon from 'mdi-material-ui/FileCogOutline'
import { groupElements } from "./groupElements";
function isMacro(item: MacroScriptSummaryModel | IBatchSummaryModel): item is MacroScriptSummaryModel {
    return !!(item as MacroScriptSummaryModel).type;
}
export default function DashboardsBatch() {
    const { batches = [], batchLaunching, macroScripts = [], macroScriptRunning, batchesLoadingAll, macroScriptsLoadingAll } = useReduxSelections("dashboard");
    const { referenceMacroScriptCategories = {} } = useReduxSelections("reference");

    const { dashboardBatchRequestExecution, dashboardMacroScriptExecute } = useReduxActions("dashboard");
    const isGranted = useGrants();

    const [criteria, setFilter] = React.useState("");
    const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => setFilter(event.target.value ?? "");

    const elements = React.useMemo(() => {
        const rets: (IBatchSummaryModel | MacroScriptSummaryModel)[] = [
            ...(isGranted(IFeatureModel.DataProcessorMacroExecute) ? macroScripts.filter(i => i.type === "DataProcessorMacroScriptSummaryModel" && !i.singleScope) : []),
            ...(isGranted(IFeatureModel.ReportExecute) ? batches : [])]
            .filter(i => i.name.toLowerCase().includes(criteria.toLowerCase()))
            .sort((a, b) => a.name.toLowerCase() === b.name.toLowerCase() ? 0 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        return rets;
    }, [criteria, isGranted, macroScripts, batches]);


    const { groups, notGrouped } = React.useMemo(() => groupElements(elements, v => v.categoryId), [elements]);

    const handleClick = (elt: MacroScriptSummaryModel | IBatchSummaryModel) => {
        if (isMacro(elt)) {
            dashboardMacroScriptExecute({ type: "DataProcessorMacroScriptModel", id: elt.id });
        }
        else {
            dashboardBatchRequestExecution(elt.id);
        }
    }
    return <DetailPanel title="Batches" isQuerying={batchesLoadingAll || macroScriptsLoadingAll}>
        <TextField value={criteria} onChange={handleChangeFilter} label="Filter" />
        {groups.map(({ categoryId, elements }) => <Card key={categoryId} style={{ marginTop: 8, marginBottom: 8 }}>
            <CardContent>
                <Typography gutterBottom={true} variant="h5" component="h2">
                    {referenceMacroScriptCategories[categoryId].name}
                </Typography>
                <Grid container={true} spacing={1} style={{ marginTop: 16 }}>
                    {elements.map(elt => <Panel
                        key={`${isMacro(elt) ? "m" : "b"}${elt.id}`}
                        onClick={handleClick}
                        elt={elt}
                        batchLaunching={batchLaunching}
                        macroScriptRunning={macroScriptRunning} />)}
                </Grid>
            </CardContent>
        </Card>)}
        <Grid container={true} spacing={1} style={{ marginTop: 16 }}>
            {notGrouped.map(elt => <Panel
                key={`${isMacro(elt) ? "m" : "b"}${elt.id}`}
                onClick={handleClick}
                elt={elt}
                batchLaunching={batchLaunching}
                macroScriptRunning={macroScriptRunning} />)}
        </Grid>
    </DetailPanel>
}

interface IPanelProps {
    elt: MacroScriptSummaryModel | IBatchSummaryModel;
    onClick: (elt: MacroScriptSummaryModel | IBatchSummaryModel) => void;
    batchLaunching: {
        [id: number]: boolean;
    }
    macroScriptRunning: {
        [id: number]: boolean;
    }
}
function Panel({ batchLaunching, macroScriptRunning, elt, onClick }: IPanelProps) {
    const issuing = isMacro(elt) ? macroScriptRunning[elt.id] : batchLaunching[elt.id];
    const handleClick = () => onClick(elt);
    const icon = isMacro(elt) ? FileCogOutlineIcon : FileClockOutlineIcon;
    return <Grid item={true} lg={4} md={6} sm={12} alignItems="stretch" style={{ display: "flex" }}>
        <ExplorerElement icon={icon} name={elt.name} onClick={handleClick} processing={issuing} />
    </Grid>
}
