import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IFeatureModel, IMonitoringMacroCallSummaryModel } from "proxy/apiProxy";
import { formatDateTime } from "tools/lib/utility";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";

export default function Roles() {
    const { monitoringMacroCallAllLoading, dictionaries: { macroScripts }, monitoringMacroCalls: macros } = useReduxSelections("monitoringMacroCall");
    const { referenceMacroScriptCategories = {} } = useReduxSelections("reference");

    const { navigationNavigate } = useReduxActions("navigation");

    const handleRowSelect = ({ id }: IMonitoringMacroCallSummaryModel) => navigationNavigate({ sectionKey: "detail", parameters: { id } });

    const handleAddNewClick = () => navigationNavigate({ sectionKey: "detail", parameters: { id: 0 } });

    const columns: IColumnDefinition[] = [
        { name: "Code", title: "Code", getCellValue: ({ code }: IMonitoringMacroCallSummaryModel) => code, filteringEnabled: true },
        { name: "Name", title: "Name", getCellValue: ({ name }: IMonitoringMacroCallSummaryModel) => name, filteringEnabled: true },
        { name: "Version", title: "Version", getCellValue: ({ publishedVersion }: IMonitoringMacroCallSummaryModel) => publishedVersion },
        { name: "Dashboard", title: "Dashboard", getCellValue: ({ isDashboard }: IMonitoringMacroCallSummaryModel) => isDashboard, columnType: "boolean" },
        { name: "MonitoringMacro", title: "Macro", getCellValue: ({ macroScriptId }: IMonitoringMacroCallSummaryModel) => macroScripts[macroScriptId]?.name, filteringEnabled: true },
        { name: "MacroCategory", title: "Category", getCellValue: ({ macroScriptId }: IMonitoringMacroCallSummaryModel) => referenceMacroScriptCategories[macroScripts[macroScriptId]?.categoryId ?? 0]?.name ?? "", filteringEnabled: true },
        { name: "LastUpdate", title: "Last Update", getCellValue: ({ updatedOn }: IMonitoringMacroCallSummaryModel) => formatDateTime(updatedOn) }
    ];

    const state: IGridState = {
        "TemplateCategory": { width: 280, groupingPosition: 1 },
        "Dashboard": { width: 150 },
        "Version": { width: 150 },
        "Code": { width: 180 },
        "Name": { width: 280 },
        "LastUpdate": { width: 280 },
        "TemplateName": { width: 280 },
    };
    const getRowKey = ({ id }: IMonitoringMacroCallSummaryModel) => id;

    return (<SearchPanel title="Monitoring macro setups"
        addAllowed={IFeatureModel.MacroExecutionSetupAndDashboardWrite}
        onAddClick={handleAddNewClick}
        isQuerying={monitoringMacroCallAllLoading}>
        <ExtendedGrid
            getRowId={getRowKey}
            columns={columns}
            rows={macros}
            onRowClick={handleRowSelect}
            initialState={state}
            userCanGroup={true} />
    </SearchPanel>);
}
