import {
    EntitySummaryModel, EntityModel, ICompanyModel,
    ICountryModel, ISicavModel, ISicavLegalFormModel, ISicavTypeModel, IEntityTypeModel, IPersonModel,
    RelationshipSummaryModel, IEntityGroupModel, IClassificationTypeModel, IExtensionFieldModel
} from "proxy/apiProxy";
import FieldBox from "tools/components/FieldBox";
import { IDictionary, cultures, toDictionary, oProps, Base64FileConverter } from "tools/lib/utility";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import EntitySelectField from "components/searchers/EntitySelectField";
import FormTextField from "tools/fieldComponents/FormTextField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { IGetEntitySummary } from "reducers/Reference";
import { useField } from "formik";
import EntitySummary from "components/summaries/EntitySummary";
import { Box, Card, CardContent, Typography, Grid } from "@material-ui/core";
import { CurrencySelectField } from "components/searchers/CurrencySelectField";
import { CountrySelectField } from "components/searchers/CountrySelectField";
import FormSelectImageField from "tools/fieldComponents/FormSelectImageField";
import ClassificationsData from "components/global/ClassificationsData";
import ExtensionFields from "features/ManagedPortfolio/ExtensionFields";

const dicoCultures = toDictionary(cultures, i => i.code, i => i.label);

const sicavLegalForm = ISicavLegalFormModel;
const sicavType = ISicavTypeModel;
const imageFileConverter = new Base64FileConverter();

export interface IEntityDataProps {
    formFieldName: string;
    formImageFieldName?: string;
    referenceCountries: IDictionary<ICountryModel>;
    classificationTypes: IClassificationTypeModel[];
    personExtensionFields: IExtensionFieldModel[];
    sicavExtensionFields: IExtensionFieldModel[];
    companyExtensionFields: IExtensionFieldModel[];

    onEntityLoaded: (found: IGetEntitySummary) => void;
    dictionaries: {
        entities: Record<string | number, EntitySummaryModel>;
        relationships: Record<string | number, RelationshipSummaryModel>;
    }
}
export default function EntityData({ personExtensionFields, sicavExtensionFields, companyExtensionFields, ...props }: IEntityDataProps) {
    const [{ value: type }] = useField<EntityModel["type"]>(oProps<EntityModel>(props.formFieldName).path("type"));
    switch (type) {
        case "CompanyModel": return <CompanyData {...props} entityExtensionFields={companyExtensionFields} />;
        case "PersonModel": return <PersonData {...props} entityExtensionFields={personExtensionFields} />;
        case "SicavModel": return <SicavData {...props} entityExtensionFields={sicavExtensionFields} />;
        case "EntityGroupModel": return <EntityGroupData {...props} entityExtensionFields={personExtensionFields} />;
    }
}
type EntityDataProps = Omit<IEntityDataProps, "personExtensionFields" | "sicavExtensionFields" | "companyExtensionFields"> & { entityExtensionFields: IExtensionFieldModel[] };
function SicavData({ formFieldName, referenceCountries, onEntityLoaded, formImageFieldName, classificationTypes, dictionaries: { entities }, entityExtensionFields }: EntityDataProps) {
    return <FieldBox display="flex" flexDirection="column">
        <Grid container spacing={1}>
            {formImageFieldName && <Grid item sm={12} lg={3}>
                <FormSelectImageField name={formImageFieldName} contentName="Picture" converter={imageFileConverter} />
            </Grid>}
            <Grid item container spacing={1} sm={12} lg={9} alignItems="center">
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<ISicavModel>(formFieldName).path("internalCode")} label="Internal Code" required={true} maxLength={100} />
                </Grid>
                <Grid item lg={6} xs={6}>
                    <FormTextField name={oProps<ISicavModel>(formFieldName).path("name")} label="Name" required={true} maxLength={250} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<IPersonModel>(formFieldName).path("subject")} label="Login" maxLength={250} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<ISicavModel>(formFieldName).path("vatNumber")} label="VAT Number" maxLength={10} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<ISicavModel>(formFieldName).path("registrationNumber")} label="Registration Number" maxLength={100} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <CurrencySelectField name={oProps<ISicavModel>(formFieldName).path("currencyId")} label="Currency" />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormSimpleSelectField name={oProps<ISicavModel>(formFieldName).path("culture")} label="Culture" options={dicoCultures} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<ISicavModel>(formFieldName).path("iban")} label="Iban" maxLength={34} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormSimpleSelectField name={oProps<ISicavModel>(formFieldName).path("legalForm")} label="Legal Form" options={sicavLegalForm} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormSimpleSelectField name={oProps<ISicavModel>(formFieldName).path("sicavType")} label="Type" options={sicavType} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormDatePickerField name={oProps<ISicavModel>(formFieldName).path("yearEnd")} label="Year End" disableToolbar={true} />
                </Grid>
                <Grid item lg={6} xs={6}>
                    <FormCheckBoxField name={oProps<ISicavModel>(formFieldName).path("regulated")} label="Regulated" />
                </Grid>
                <Grid item lg={4} xs={12}>
                    <FormCheckBoxField name={oProps<ISicavModel>(formFieldName).path("cssfEquivalentSupervision")} label="CSSF Equivalent Supervision" />
                </Grid>
                <Grid item lg={4} xs={12}>
                    <FormCheckBoxField name={oProps<ISicavModel>(formFieldName).path("discretionaryManagementLicensed")} label="Discretionary Management Licensed" />
                </Grid>
                <Grid item lg={4} xs={12}>
                    <FormCheckBoxField name={oProps<ISicavModel>(formFieldName).path("aifm")} label="Aifm" />
                </Grid>
                <Grid item lg={6} xs={6}>
                    <FormDatePickerField name={oProps<ISicavModel>(formFieldName).path("incorporationDate")} label="Incorporation Date" />
                </Grid>
                <Grid item lg={6} xs={6}>
                    <FormDatePickerField name={oProps<ISicavModel>(formFieldName).path("incorporationEndDate")} label="Incorporation End Date" />
                </Grid>
                <ExtensionFields extensionFields={entityExtensionFields} pathToExtensionFields={oProps<ISicavModel>(formFieldName).path("entityExtensionFieldsValues")} />
            </Grid>
            <Grid item spacing={1} sm={12}>
                <ClassificationsData
                    panelTitle="Classifications"
                    fieldName={oProps<ISicavModel>(formFieldName).path("classifications")}
                    classificationTypes={classificationTypes} />
            </Grid>
        </Grid>
        <Card >
            <CardContent>
                <Typography gutterBottom={true} variant="h5" component="h2">
                    Contact
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={6}>
                        <Box>
                            <EntitySelectField type={IEntityTypeModel.Company} name={oProps<ISicavModel>(formFieldName).path("issuerId")} label="Issuer" onSelected={onEntityLoaded} />
                            <EntitySummary name={oProps<ISicavModel>(formFieldName).path("issuerId")} countries={referenceCountries} entities={entities} label="Issuer" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <FormTextField name={oProps<ISicavModel>(formFieldName).path("url")} label="Url" />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <FormTextField name={oProps<ISicavModel>(formFieldName).path("phoneNumber")} label="Phone Number" maxLength={100} />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <FormTextField name={oProps<ISicavModel>(formFieldName).path("email")} label="Email" isEmail={true} />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <FormTextField name={oProps<ISicavModel>(formFieldName).path("streetAddress")} label="Street Address" />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <FormTextField name={oProps<ISicavModel>(formFieldName).path("zipCode")} label="Zip Code" maxLength={10} />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <FormTextField name={oProps<ISicavModel>(formFieldName).path("location")} label="Location" maxLength={250} />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <CountrySelectField name={oProps<ISicavModel>(formFieldName).path("countryId")} label="Country" />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </FieldBox>
}

function CompanyData({ formFieldName, referenceCountries, onEntityLoaded, formImageFieldName, classificationTypes, dictionaries: { entities }, entityExtensionFields }: EntityDataProps) {
    return <FieldBox display="flex" flexDirection="column">
        <Grid container spacing={1}>
            {formImageFieldName && <Grid item lg={3} xs={12}>
                <FormSelectImageField name={formImageFieldName} contentName="Picture" converter={imageFileConverter} />
            </Grid>}
            <Grid item container spacing={1} lg={9} xs={12} alignItems="center">
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<ICompanyModel>(formFieldName).path("internalCode")} label="Internal Code" required={true} maxLength={100} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<ICompanyModel>(formFieldName).path("leiCode")} label="Lei Code" />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<ICompanyModel>(formFieldName).path("name")} label="Name" required={true} maxLength={250} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<IPersonModel>(formFieldName).path("subject")} label="Login" maxLength={250} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<ICompanyModel>(formFieldName).path("vatNumber")} label="VAT Number" maxLength={10} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<ICompanyModel>(formFieldName).path("registrationNumber")} label="Registration Number" maxLength={100} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <CurrencySelectField name={oProps<ICompanyModel>(formFieldName).path("currencyId")} label="Currency" />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormSimpleSelectField name={oProps<ICompanyModel>(formFieldName).path("culture")} label="Culture" options={dicoCultures} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<ICompanyModel>(formFieldName).path("iban")} label="Iban" maxLength={34} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormDatePickerField name={oProps<ICompanyModel>(formFieldName).path("yearEnd")} label="Year End" disableToolbar={true} />
                </Grid>
                <Grid item lg={6} xs={6}>
                    <FormCheckBoxField name={oProps<ICompanyModel>(formFieldName).path("regulated")} label="Regulated" />
                </Grid>
                <Grid item lg={4} xs={12}>
                    <FormCheckBoxField name={oProps<ICompanyModel>(formFieldName).path("cssfEquivalentSupervision")} label="CSSF Equivalent Supervision" />
                </Grid>
                <Grid item lg={4} xs={12}>
                    <FormCheckBoxField name={oProps<ICompanyModel>(formFieldName).path("discretionaryManagementLicensed")} label="Discretionary Management Licensed" />
                </Grid>
                <Grid item lg={4} xs={12}>
                    <FormCheckBoxField name={oProps<ICompanyModel>(formFieldName).path("aifm")} label="Aifm" />
                </Grid>
                <Grid item container spacing={1}>
                    <Grid item lg={4} md={6} xs={12}>
                        <FormDatePickerField name={oProps<ICompanyModel>(formFieldName).path("incorporationDate")} label="Incorporation Date" />
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                        <FormDatePickerField name={oProps<ICompanyModel>(formFieldName).path("incorporationEndDate")} label="Incorporation End Date" />
                    </Grid>
                </Grid>

                <ExtensionFields extensionFields={entityExtensionFields} pathToExtensionFields={oProps<ICompanyModel>(formFieldName).path("entityExtensionFieldsValues")} />
            </Grid>
            <Grid item spacing={1} sm={12}>
                <ClassificationsData
                    panelTitle="Classification"
                    fieldName={oProps<ICompanyModel>(formFieldName).path("classifications")}
                    classificationTypes={classificationTypes} />
            </Grid>
        </Grid>
        <Card >
            <CardContent>
                <Typography gutterBottom={true} variant="h5" component="h2">
                    Contact
                </Typography>
                <Grid container spacing={1}>
                    <Grid item sm={6} lg={3}>
                        <FormTextField name={oProps<ICompanyModel>(formFieldName).path("url")} label="Url" />
                    </Grid>
                    <Grid item sm={6} lg={3}>
                        <FormTextField name={oProps<ICompanyModel>(formFieldName).path("phoneNumber")} label="Phone Number" maxLength={100} />
                    </Grid>
                    <Grid item sm={6} lg={3}>
                        <FormTextField name={oProps<ICompanyModel>(formFieldName).path("email")} label="Email" isEmail={true} />
                    </Grid>
                    <Grid item sm={6} lg={3}>
                        <CountrySelectField name={oProps<ICompanyModel>(formFieldName).path("countryId")} label="Country" />
                    </Grid>
                    <Grid item sm={6} lg={6}>
                        <FormTextField name={oProps<ICompanyModel>(formFieldName).path("streetAddress")} label="Street Address" />
                    </Grid>
                    <Grid item sm={6} lg={3}>
                        <FormTextField name={oProps<ICompanyModel>(formFieldName).path("zipCode")} label="Zip Code" maxLength={10} />
                    </Grid>
                    <Grid item sm={6} lg={3}>
                        <FormTextField name={oProps<ICompanyModel>(formFieldName).path("location")} label="Location" maxLength={250} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </FieldBox>
}

function EntityGroupData({ formFieldName, referenceCountries, onEntityLoaded, classificationTypes, formImageFieldName, dictionaries: { entities }, entityExtensionFields }: EntityDataProps) {
    return <FieldBox display="flex" flexDirection="column">
        <Grid container spacing={1}>
            {formImageFieldName && <Grid item lg={3} xs={12}>
                <FormSelectImageField name={formImageFieldName} contentName="Picture" converter={imageFileConverter} />
            </Grid>}
            <Grid item container spacing={1} lg={9} xs={12} alignItems="center" alignContent="flex-start">
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<IEntityGroupModel>(formFieldName).path("internalCode")} label="Internal Code" required={true} maxLength={100} />
                </Grid>
                <Grid item lg={6} xs={6}>
                    <FormTextField name={oProps<IEntityGroupModel>(formFieldName).path("name")} label="Name" required={true} maxLength={250} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<IPersonModel>(formFieldName).path("subject")} label="Login" maxLength={250} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <CurrencySelectField name={oProps<IEntityGroupModel>(formFieldName).path("currencyId")} label="Currency" />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormSimpleSelectField name={oProps<IEntityGroupModel>(formFieldName).path("culture")} label="Culture" options={dicoCultures} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<IEntityGroupModel>(formFieldName).path("iban")} label="Iban" maxLength={34} />
                </Grid>
                <ExtensionFields extensionFields={entityExtensionFields} pathToExtensionFields={oProps<IEntityGroupModel>(formFieldName).path("entityExtensionFieldsValues")} />
            </Grid>
            <Grid item spacing={1} sm={12}>
                <ClassificationsData
                    panelTitle="Classification"
                    fieldName={oProps<IEntityGroupModel>(formFieldName).path("classifications")}
                    classificationTypes={classificationTypes} />
            </Grid>
        </Grid>
        <Card >
            <CardContent>
                <Typography gutterBottom={true} variant="h5" component="h2">
                    Contact
                </Typography>
                <Grid container spacing={1}>
                    <Grid item sm={6} lg={3}>
                        <FormTextField name={oProps<IEntityGroupModel>(formFieldName).path("phoneNumber")} label="Phone Number" maxLength={100} />
                    </Grid>
                    <Grid item sm={6} lg={3}>
                        <FormTextField name={oProps<IEntityGroupModel>(formFieldName).path("email")} label="Email" isEmail={true} />
                    </Grid>
                    <Grid item sm={6} lg={3}>
                        <CountrySelectField name={oProps<IEntityGroupModel>(formFieldName).path("countryId")} label="Country" />
                    </Grid>
                    <Grid item sm={6} lg={6}>
                        <FormTextField name={oProps<IEntityGroupModel>(formFieldName).path("streetAddress")} label="Street Address" />
                    </Grid>
                    <Grid item sm={6} lg={3}>
                        <FormTextField name={oProps<IEntityGroupModel>(formFieldName).path("zipCode")} label="Zip Code" maxLength={10} />
                    </Grid>
                    <Grid item sm={6} lg={3}>
                        <FormTextField name={oProps<IEntityGroupModel>(formFieldName).path("location")} label="Location" maxLength={250} />
                    </Grid>
                    <Grid item lg={4} xs={4}>
                        <FormDatePickerField name={oProps<IEntityGroupModel>(formFieldName).path("incorporationDate")} label="Incorporation Date" />
                    </Grid>
                    <Grid item lg={4} xs={4}>
                        <FormDatePickerField name={oProps<IEntityGroupModel>(formFieldName).path("incorporationEndDate")} label="Incorporation End Date" />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </FieldBox>
}

function PersonData({ formFieldName, formImageFieldName, classificationTypes, entityExtensionFields }: EntityDataProps) {
    return <FieldBox display="flex" flexDirection="column">
        <Grid container spacing={1}>
            {formImageFieldName && <Grid item sm={3} xs={12}>
                <FormSelectImageField name={formImageFieldName} contentName="Picture" converter={imageFileConverter} />
            </Grid>}
            <Grid item container spacing={1} sm={9} xs={12} alignItems="center">
                <Grid item lg={3} xs={12}>
                    <FormTextField name={oProps<IPersonModel>(formFieldName).path("internalCode")} label="Internal Code" required={true} maxLength={100} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<IPersonModel>(formFieldName).path("firstName")} label="First Name" required={true} maxLength={100} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<IPersonModel>(formFieldName).path("lastName")} label="Last Name" required={true} maxLength={100} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<IPersonModel>(formFieldName).path("title")} label="Title (Mr, Mrs, Miss, Ms...)" maxLength={5} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<IPersonModel>(formFieldName).path("subject")} label="Login" maxLength={250} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <CurrencySelectField name={oProps<IPersonModel>(formFieldName).path("currencyId")} label="Preferred Currency" />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormSimpleSelectField name={oProps<IPersonModel>(formFieldName).path("culture")} label="Culture" options={dicoCultures} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<IPersonModel>(formFieldName).path("iban")} label="Iban" maxLength={34} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<IPersonModel>(formFieldName).path("idCardNumber")} label="Id Card Number" maxLength={100} />
                </Grid>
                <Grid item lg={3} xs={6}>
                    <FormTextField name={oProps<IPersonModel>(formFieldName).path("passportNumber")} label="Passport Number" maxLength={100} />
                </Grid>
                <ExtensionFields extensionFields={entityExtensionFields} pathToExtensionFields={oProps<IPersonModel>(formFieldName).path("entityExtensionFieldsValues")} />
            </Grid>
            <Grid item spacing={1} sm={12}>
                <ClassificationsData
                    panelTitle="Classification"
                    fieldName={oProps<IPersonModel>(formFieldName).path("classifications")}
                    classificationTypes={classificationTypes} />
            </Grid>
        </Grid>
        <Card >
            <CardContent>
                <Typography gutterBottom={true} variant="h5" component="h2">
                    Contact
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={3}>
                        <FormTextField name={oProps<IPersonModel>(formFieldName).path("mobileNumber")} label="Mobile Number" maxLength={100} />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <FormTextField name={oProps<IPersonModel>(formFieldName).path("phoneNumber")} label="Phone Number" maxLength={100} />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <FormTextField name={oProps<IPersonModel>(formFieldName).path("email")} label="Email" isEmail={true} />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <CountrySelectField name={oProps<IPersonModel>(formFieldName).path("countryId")} label="Country" />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormTextField name={oProps<IPersonModel>(formFieldName).path("streetAddress")} label="Street Address" />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <FormTextField name={oProps<IPersonModel>(formFieldName).path("zipCode")} label="Zip Code" maxLength={10} />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <FormTextField name={oProps<IPersonModel>(formFieldName).path("location")} label="Location" maxLength={250} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </FieldBox>
}
