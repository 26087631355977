import * as React from "react";
import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import { FormikProps, useField } from "formik";
import { IFeatureModel, DevelopmentItemModel } from "proxy/apiProxy";
import { useReduxSelections, useReduxActions } from "tools/lib/reduxStoreAccess";
import { oProps } from "tools/lib/utility";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { ITypedMacro } from "./slice";
import { IActionButton } from "tools/components/FabContainer";
import ReportTemplateDevelopmentItem, { IReportTemplateDevelopmentItemRefs } from "./DevelopmentItemEditor/ReportTemplateDevelopmentItemEditor";
import QuestionnaireDevelopmentItem, { IQuestionnaireDevelopmentItemRefs } from "./DevelopmentItemEditor/QuestionnaireDevelopmentItemEditor";
import EtlMacroDevelopmentItemEditor, { IEtlDevelopmentItemRefs } from "./DevelopmentItemEditor/EtlMacroDevelopmentItemEditor";
import { Switch } from "./Switch";
import { developmentItemMenus } from "./developmentItemMenus";
import { DevelopmentItemProperties } from "./DevelopmentItemProperties";
import { FormValues } from "./DevelopmentItem";
import { getConfig } from "lib/userManager";
import { DevelopmentItemExecution } from "./DevelopmentItemRunner/DevelopmentItemExecution";
import DashboardMacroDevelopmentItemEditor, { IDashboardDevelopmentItemRefs } from "./DevelopmentItemEditor/DashboardMacroDevelopmentItemEditor";

const { general: { disableSudo } } = getConfig();

export interface IDevelopmentItemRefs extends IQuestionnaireDevelopmentItemRefs, IReportTemplateDevelopmentItemRefs, IEtlDevelopmentItemRefs, IDashboardDevelopmentItemRefs { }
interface IDevelopmentItemFormProps extends IDevelopmentItemRefs {
    form: FormikProps<FormValues>;
    // onPreviewDataRequest: () => void;
    // previewData: any | undefined;
}
export function DevelopmentItemForm({ form: { submitForm, values, dirty, isValid }, ...refs }: IDevelopmentItemFormProps) {
    const { developmentItemCurrent, developmentItemMetadata, developmentItemDiagnosticCurrent, developmentItemStates } = useReduxSelections("developmentItem");
    const { classificationTypes } = useReduxSelections("developmentItemClassificationType");
    const { navigationActiveScreen } = useReduxSelections("navigation");
    const { developmentItemDelete, /*developmentItemExecute, */ developmentItemValidateScript, developmentItemValidate } = useReduxActions("developmentItem");
    const { navigationNavigate } = useReduxActions("navigation");
    const handleBack = () => navigationNavigate(undefined);

    const handleCodeChanged = React.useCallback((macro: ITypedMacro) => {
        developmentItemValidateScript(macro);
    }, [developmentItemValidateScript]);
    const handleTabValueChanged = React.useCallback((value: any) => {
        const tabValue = value as string;
        navigationNavigate({ sectionKey: tabValue });
    }, [navigationNavigate]);
    const handleDeleteClick = React.useCallback(() => {
        if (developmentItemCurrent?.id) {
            developmentItemDelete(developmentItemCurrent.id);
        };
    }, [developmentItemCurrent?.id, developmentItemDelete]);
    const tabValue = navigationActiveScreen?.activeSectionKey;

    const title = !!values.id ? values.name : "New Development Item";

    const tabs: ISubMenu[] | undefined = developmentItemMenus[values["type"]];

    const handleSaveClick = React.useCallback(() => {
        if (isValid) {
            submitForm();
        }
    }, [isValid, submitForm]);
    const handleTypeChanged = React.useCallback(() => {
        switch (values.type) {
            case "QuestionnaireDevelopmentItemModel":
                developmentItemValidate({
                    ...values,
                    templateContent: refs.questionnaireTemplateRef.current.getValue() ?? "{}",
                    onCompleteMacro: refs.questionnaireCompleteMacroRef.current.getValue(),
                    onLoadMacro: refs.questionnaireLoadMacroRef.current.getValue()
                });
                break;
            case "ReportTemplateDevelopmentItemModel":
                developmentItemValidate({
                    ...values,
                    templateContent: refs.reportTemplateRef.current.getValue() ?? "{}",
                    onLoadMacro: refs.reportLoadMacroRef.current.getValue()
                });
                break;
            case "DashboardMacroDevelopmentItemModel":
                developmentItemValidate({
                    ...values,
                    macro: refs.dashboardMacroRef.current.getValue()
                });
                break;
            case "EtlMacroDevelopmentItemModel":
                developmentItemValidate({
                    ...values,
                    macro: refs.etlMacroRef.current.getValue()
                });
                break;
            default:
                developmentItemValidate(values);
                break;
        }
    }, [developmentItemValidate, refs.etlMacroRef, refs.questionnaireCompleteMacroRef, refs.questionnaireLoadMacroRef, refs.questionnaireTemplateRef, refs.reportLoadMacroRef, refs.reportTemplateRef, refs.dashboardMacroRef, values]);
    const [{ value: type }] = useField<DevelopmentItemModel["type"]>(oProps<DevelopmentItemModel>().path("type"));
    const [onGoingExecution, setOnGoingExecution] = React.useState<DevelopmentItemModel["type"] | "ReportPreview">();
    const handleCloseExecution = React.useCallback(() => void setOnGoingExecution(undefined), [])

    const executeActionButtons = React.useMemo(() => {
        const newActions: IActionButton[] = [];
        switch (values.type) {
            case "QuestionnaireDevelopmentItemModel":
            case "ReportTemplateDevelopmentItemModel":
            case "EtlMacroDevelopmentItemModel":
            case "DashboardMacroDevelopmentItemModel":
                newActions.push({
                    label: "Execute",
                    onClick: () => setOnGoingExecution(values.type),
                    icon: PlayArrowIcon,
                    disabled: developmentItemStates.questionnaireOnLoading
                });
                break;
        }
        return newActions;
    }, [developmentItemStates.questionnaireOnLoading, values.type]);
    const handlePreviewDataRequest = React.useCallback(() => void setOnGoingExecution("ReportPreview"), []);
    return <WriteAccess value={IFeatureModel.DevelopmentItemsWrite}>
        <DevelopmentItemExecution refs={refs} execution={onGoingExecution} onCloseExecution={handleCloseExecution} />
        <DetailPanel
            tabs={tabs}
            tabValue={tabValue}
            onTabValueChanged={handleTabValueChanged}
            isQuerying={developmentItemStates.loading || developmentItemStates.saving}
            title={title}
            badge={!values.id ? "new" : undefined}
            onBackClick={handleBack}
            noPadding={tabValue === "detail" || tabValue === "visualetl"}
            onSaveClick={handleSaveClick}
            canSave={isValid}
            saveAllowed={IFeatureModel.DevelopmentItemsWrite}
            deleteAllowed={IFeatureModel.DevelopmentItemsWrite}
            onDeleteClick={handleDeleteClick}
            actions={executeActionButtons}
            canDelete={!!values.id}
            saveMustBeConfirmed={!disableSudo}>
            {(tabValue === "detail") && <DevelopmentItemProperties
                onTypeChanged={handleTypeChanged}
                classificationTypes={classificationTypes} />}
            <Switch type={type}>
                <QuestionnaireDevelopmentItem type="QuestionnaireDevelopmentItemModel"
                    onCodeChanged={handleCodeChanged}
                    questionnaireCompleteMacroRef={refs.questionnaireCompleteMacroRef}
                    questionnaireLoadMacroRef={refs.questionnaireLoadMacroRef}
                    questionnaireTemplateRef={refs.questionnaireTemplateRef}
                    tabValue={tabValue}
                    diagnostics={developmentItemDiagnosticCurrent}
                    metadata={developmentItemMetadata} />
                <EtlMacroDevelopmentItemEditor type="EtlMacroDevelopmentItemModel"
                    onCodeChanged={handleCodeChanged}
                    etlMacroRef={refs.etlMacroRef}
                    tabValue={tabValue}
                    metadata={developmentItemMetadata}
                    diagnostics={developmentItemDiagnosticCurrent} />
                <DashboardMacroDevelopmentItemEditor type="DashboardMacroDevelopmentItemModel"
                    onCodeChanged={handleCodeChanged}
                    dashboardMacroRef={refs.dashboardMacroRef}
                    tabValue={tabValue}
                    metadata={developmentItemMetadata}
                    diagnostics={developmentItemDiagnosticCurrent} />
                <ReportTemplateDevelopmentItem type="ReportTemplateDevelopmentItemModel"
                    onCodeChanged={handleCodeChanged}
                    reportLoadMacroRef={refs.reportLoadMacroRef}
                    reportTemplateRef={refs.reportTemplateRef}
                    onPreviewDataRequest={handlePreviewDataRequest}
                    previewData={developmentItemStates.reportLoadedData}
                    tabValue={tabValue}
                    metadata={developmentItemMetadata}
                    diagnostics={developmentItemDiagnosticCurrent} />
            </Switch>
        </DetailPanel>
    </WriteAccess>;
}
