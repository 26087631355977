import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IFeatureModel, IReportSummaryModel, ReportSummaryModel } from "proxy/apiProxy";
import { useReduxSelections, useReduxActions } from "tools/lib/reduxStoreAccess";

export default function Reports() {
    const { dictionaries: { reportTemplates, documentDefinitions }, reports, reportAllLoading } = useReduxSelections("report");
    const { referenceReportTemplateCategories = {} } = useReduxSelections("reference");
    const { navigationNavigate } = useReduxActions("navigation");
    const handleRowSelect = ({ id }: IReportSummaryModel) => navigationNavigate({ sectionKey: "detail", parameters: { id } });

    const handleAddNewClick = () => navigationNavigate({ sectionKey: "detail", parameters: { id: 0 } });

    const columns: IColumnDefinition[] = [
        { name: "Code", title: "Code", getCellValue: ({ code }: IReportSummaryModel) => code, filteringEnabled: true },
        { name: "Name", title: "Name", getCellValue: ({ name }: IReportSummaryModel) => name, filteringEnabled: true },
        {
            name: "TemplateName",
            title: "Template/Document",
            getCellValue: (report: ReportSummaryModel) => report.type === "TemplateReportSummaryModel"
                ? reportTemplates[report.reportTemplateId]?.name
                : documentDefinitions[report.documentDefinitionId]?.name,
            filteringEnabled: true
        },
        {
            name: "TemplateCategory",
            title: "Category",
            getCellValue: (report: ReportSummaryModel) => report.type === "TemplateReportSummaryModel"
                ? referenceReportTemplateCategories[reportTemplates[report.reportTemplateId]?.categoryId ?? 0]?.name ?? ""
                : referenceReportTemplateCategories[documentDefinitions[report.documentDefinitionId]?.categoryId ?? 0]?.name ?? "",
            filteringEnabled: true
        },
        { name: "FileName", title: "File Name", getCellValue: ({ fileName }: IReportSummaryModel) => fileName, filteringEnabled: true },
    ];

    const state: IGridState = {
        "TemplateCategory": { width: 280 },
        "Code": { width: 200 },
        "Name": { width: 280 },
        "FileName": { width: 280 },
        "TemplateName": { width: 280 },
    };
    const getRowKey = ({ id }: IReportSummaryModel) => id;

    return (<SearchPanel title="Reports" isQuerying={reportAllLoading}
        addAllowed={IFeatureModel.BatchWrite}
        onAddClick={handleAddNewClick}>
        <ExtendedGrid
            getRowId={getRowKey}
            columns={columns}
            rows={reports}
            onRowClick={handleRowSelect}
            initialState={state}
            userCanGroup={true} />
    </SearchPanel>);
}
