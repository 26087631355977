import {
    IManagedShareClassModel, ICurrencyModel, IFrequencyTypeModel, IInvestorTypeModel,
    IClassificationTypeModel, IDividendDistributionPolicyModel, EntitySummaryModel, IMarketDataProviderModel,
    ISubFundSummaryModel,
    PortfolioSummaryModel,
    IFeatureModel,
    IPortfolioTypeModel,
    ICountryModel,
    IIndexSummaryModel,
    IExtensionFieldModel
} from "proxy/apiProxy";
import FormTextField from "tools/fieldComponents/FormTextField";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FormSwitchField from "tools/fieldComponents/FormSwitchField";
import FieldBox from "tools/components/FieldBox";
import { getEnumLabels, oProps } from "tools/lib/utility";
import SubFundSummary from "components/summaries/SubFundSummary";
import ClassificationsData from "components/global/ClassificationsData";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { IGetPortfolioSummary } from "reducers/Reference";
import React from "react";
import { CurrencySelectField } from "components/searchers/CurrencySelectField";
import { useGrants } from "tools/lib/reduxStoreAccess";
import ManagedPortfolioSelectField from "components/searchers/ManagedPortfolioSelectField";
import { IndexSelectField } from "components/searchers/IndexSelectField";
import { IndexSummary } from "components/summaries/IndexSummary";
import DataProviderCodes from "features/Security/DataProviderCodes";
import ExtensionFields from "features/ManagedPortfolio/ExtensionFields";
// import { useField } from "formik";

export interface IShareClassDataProps {
    referenceCurrencies: Record<number, ICurrencyModel>;
    referenceCountries: Record<number, ICountryModel>;
    formValues: IManagedShareClassModel;
    previousSubFundId?: number;
    parametersMarketDataProviders: IMarketDataProviderModel[];
    classificationTypes: IClassificationTypeModel[];
    dictionaries: {
        subFunds: Record<string | number, ISubFundSummaryModel>;
        entities: Record<string | number, EntitySummaryModel>;
        indexes: Record<string | number, IIndexSummaryModel>;
    }
    shareClassExtensionFields: IExtensionFieldModel[];
    securityExtensionFields: IExtensionFieldModel[];
    onSubFundLoaded: (found: IGetPortfolioSummary) => void;
    onIndexLoaded: (index: IIndexSummaryModel) => void;
}

const frequencyTypes = getEnumLabels(IFrequencyTypeModel);

const investorTypes = getEnumLabels(IInvestorTypeModel);

const dividendDistributionPolicies = getEnumLabels(IDividendDistributionPolicyModel);

export default function ShareClassData({
    referenceCurrencies,
    referenceCountries,
    classificationTypes,
    parametersMarketDataProviders,
    formValues,
    previousSubFundId,
    dictionaries,
    onIndexLoaded,
    shareClassExtensionFields,
    securityExtensionFields,
    onSubFundLoaded: onPortfolioLoaded
}: IShareClassDataProps) {
    const isGranted = useGrants();
    const isPortfolioGranted = ({ id: portfolioId }: PortfolioSummaryModel) => isGranted({ feature: IFeatureModel.ManagedPortfolioWrite, portfolioId })
    const {
        subFunds,
    } = dictionaries;
    const currencyCode = (formValues.currencyId && referenceCurrencies) ? referenceCurrencies[formValues.currencyId]?.isoCode : undefined;
    // https://stackoverflow.com/questions/6913512/how-to-sort-an-array-of-objects-by-multiple-fields
    const subFundHelperText = React.useMemo(() => {
        const classifications = formValues.classifications ?? {};
        if (Object.keys(classifications).map(key => classifications[key]).filter(i => i).length && !previousSubFundId && formValues.subFundId) {
            return "Classifications will be removed";
        }
        return;
    }, [formValues.classifications, formValues.subFundId, previousSubFundId]);
    return <FieldBox display="flex" flexDirection="column">
        <Card >
            <CardContent>
                <Typography gutterBottom={true} variant="h5" component="h2">
                    Codes
                </Typography>
                <Grid container={true} spacing={1}>
                    <Grid item={true} xs={3}>
                        <FormTextField name={oProps<IManagedShareClassModel>().path("internalCode")} label="Internal Code" required={true} maxLength={100} />
                    </Grid>
                    <DataProviderCodes
                        formFieldName={oProps<IManagedShareClassModel>().path("dataProviderCodes")}
                        isinFieldName={oProps<IManagedShareClassModel>().path("isin")}
                        valorFieldName={oProps<IManagedShareClassModel>().path("valor")}
                        wknFieldName={oProps<IManagedShareClassModel>().path("wkn")}
                        cusipFieldName={oProps<IManagedShareClassModel>().path("cusip")}
                        parametersMarketDataProviders={parametersMarketDataProviders} />
                </Grid>
            </CardContent>
        </Card>
        <Grid container={true} spacing={1}>
            <Grid item={true} xs={3}>
                <FormTextField name={oProps<IManagedShareClassModel>().path("name")} label="Name" required={true} maxLength={250} />
            </Grid>
            <Grid item={true} xs={3}>
                <FormTextField name={oProps<IManagedShareClassModel>().path("shortName")} label="Short Name" maxLength={50} />
            </Grid>
            <Grid item={true} xs={3}>
                <CurrencySelectField name={oProps<IManagedShareClassModel>().path("currencyId")} label="Currency" />
            </Grid>
            <Grid item={true} xs={3}>
                <FormSwitchField name={oProps<IManagedShareClassModel>().path("isOpenForInvestment")} label="Is Open For Investment" />
            </Grid>
        </Grid>
        <ClassificationsData
            panelTitle="Classification"
            fieldName={oProps<IManagedShareClassModel>().path("classifications")}
            classificationTypes={classificationTypes} />
        <Grid container={true} spacing={1}>
            <Grid item={true} xs={3}>
                <ManagedPortfolioSelectField type={IPortfolioTypeModel.SubFund} name={oProps<IManagedShareClassModel>().path("subFundId")} label="Sub Fund" filter={isPortfolioGranted} onSelected={onPortfolioLoaded} />
                {!!subFundHelperText && <Typography style={{ color: "red" }} >{subFundHelperText}</Typography>}
                <SubFundSummary name={oProps<IManagedShareClassModel>().path("subFundId")} currencies={referenceCurrencies} subFunds={subFunds} />
            </Grid>
            <ExtensionFields extensionFields={securityExtensionFields} pathToExtensionFields={oProps<IManagedShareClassModel>().path("securityExtensionFieldsValues")} />
            <ExtensionFields extensionFields={shareClassExtensionFields} pathToExtensionFields={oProps<IManagedShareClassModel>().path("shareClassExtensionFieldsValues")} />
            <Grid item={true} xs={3}>
                <FormCheckBoxField name={oProps<IManagedShareClassModel>().path("isHedged")} label="Is Hedged" />
            </Grid>
            <Grid item={true} xs={3}>
                <FormSimpleSelectField name={oProps<IManagedShareClassModel>().path("dividendDistributionPolicy")} label="Dividend Type" options={dividendDistributionPolicies} />
            </Grid>
            <Grid item={true} xs={3}>
                <FormSimpleSelectField name={oProps<IManagedShareClassModel>().path("dividendPeriodicity")} label="Dividend Periodicity" options={frequencyTypes} disabled={formValues.dividendDistributionPolicy === IDividendDistributionPolicyModel.Accumulation} />
            </Grid>
            <Grid item={true} xs={3}>
                <FormSimpleSelectField name={oProps<IManagedShareClassModel>().path("investorType")} label="Investor Type" options={investorTypes} />
            </Grid>
            <Grid item={true} xs={3}>
                <FormDatePickerField name={oProps<IManagedShareClassModel>().path("inceptionDate")} label="Inception Date" />
            </Grid>
            <Grid item={true} xs={3}>
                <FormDatePickerField name={oProps<IManagedShareClassModel>().path("closingDate")} label="Closing Date" />
            </Grid>
            <Grid item={true} xs={3}>
                <FormTextField name={oProps<IManagedShareClassModel>().path("minimumInitialInvestment")} label="Minimum Initial Investment" isNumber={true} adornment={currencyCode} />
            </Grid>
            <Grid item={true} xs={3}>
                <FormTextField name={oProps<IManagedShareClassModel>().path("minimumSubscriptionAmount")} label="Minimum Subscription Amount" isNumber={true} adornment={currencyCode} />
            </Grid>
            <Grid item={true} xs={3}>
                <FormTextField name={oProps<IManagedShareClassModel>().path("entryFee")} label="Entry Fee" isPercentage={true} />
            </Grid>
            <Grid item={true} xs={3}>
                <FormTextField name={oProps<IManagedShareClassModel>().path("exitFee")} label="Exit Fee" isPercentage={true} />
            </Grid>
            <Grid item={true} xs={3}>
                <FormTextField name={oProps<IManagedShareClassModel>().path("maximumSubscriptionFee")} label="Maximum Subscription Fee" isPercentage={true} />
            </Grid>
            <Grid item={true} xs={3}>
                <FormTextField name={oProps<IManagedShareClassModel>().path("managementFee")} label="Management Fee" isPercentage={true} />
            </Grid>
            <Grid item={true} xs={3}>
                <FormTextField name={oProps<IManagedShareClassModel>().path("performanceFee")} label="Performance Fee" isPercentage={true} />
            </Grid>
            <Grid item={true} xs={3}>
                <FormTextField name={oProps<IManagedShareClassModel>().path("minimumHoldingAmount")} label="Minimum holding amount" isNumber={true} />
            </Grid>
            <Grid item={true} xs={4}>
                <IndexSelectField name={oProps<IManagedShareClassModel>().path("performanceFeeRelatedIndexId")} label="Performance Fee Related Index" onSelected={onIndexLoaded} />
                {(!!formValues.performanceFeeRelatedIndexId && !!dictionaries.indexes[formValues.performanceFeeRelatedIndexId]) && <IndexSummary name={oProps<IManagedShareClassModel>().path("performanceFeeRelatedIndexId")} label="ActualizationRiskFreeIndex" indexes={dictionaries.indexes} countries={referenceCountries} currencies={referenceCurrencies} />}
            </Grid>

            <Grid item={true} xs={3}>
                <FormTextField name={oProps<IManagedShareClassModel>().path("carriedInterest")} label="Carried Interest" isNumber={true} />
            </Grid>
            <Grid item={true} xs={3}>
                <FormTextField name={oProps<IManagedShareClassModel>().path("waterfallCatchUp")} label="Waterfall Catch Up" isNumber={true} />
            </Grid>
            <Grid item={true} xs={3}>
                <FormTextField name={oProps<IManagedShareClassModel>().path("waterfallSpecialReturn")} label="Waterfall Special Return" isNumber={true} />
            </Grid>
        </Grid>
    </FieldBox>
}
