import produce from "immer";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IGitFile } from "tools/lib/octokitHelper";

export const ActionFactories = produceActionFactories({
    communityReportTemplateLoadAll: () => undefined,
    communityReportTemplateLoadedAll: (payload: IGitFile[]) => payload,
    communityReportTemplateLicenseLoaded: (payload: string) => payload,
    communityReportTemplateLoad: (payload: string) => payload,
    communityReportTemplateLoaded: (payload: string) => payload
});
export interface IState {
    communityReportTemplateAllLoading: boolean;
    communityReportTemplateLoading: boolean;
    communityReportTemplateSelected?: string;
    communityReportTemplates: IGitFile[];
    communityReportTemplateLicense?: string;
}
export const reducer = (
    state: IState = {
        communityReportTemplateAllLoading: false,
        communityReportTemplateLoading: false,
        communityReportTemplates: []
    },
    action: AnyActionOf<typeof ActionFactories>
) => produce(state, draft => {
    switch (action.type) {
        case "communityReportTemplateLoadAll":
            draft.communityReportTemplateAllLoading = true;
            break;
        case "communityReportTemplateLoadedAll":
            draft.communityReportTemplateAllLoading = false;
            draft.communityReportTemplates = action.payload;
            break;
        case "communityReportTemplateLoad":
            draft.communityReportTemplateLoading = true;
            draft.communityReportTemplateSelected = undefined;
            break;
        case "communityReportTemplateLoaded":
            draft.communityReportTemplateLoading = false;
            draft.communityReportTemplateSelected = action.payload;
            break;
        case "communityReportTemplateLicenseLoaded":
            draft.communityReportTemplateLicense = action.payload;
            break;
    }
});
