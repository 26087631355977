import * as React from "react";
import { IPortfolioTypeModel } from "proxy/apiProxy";
import { useField } from 'formik';
import AsyncMultiSelect from "tools/components/AsyncMultiSelect";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { toDictionary } from "tools/lib/utility";
import { IGetPortfoliosSummary } from "reducers/Reference";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import SearchIcon from '@material-ui/icons/Search';

export interface IManagedPortfolioMultiSelectFieldProps {
    name: string;
    label?: React.ReactNode;
    required?: boolean;
    onSelected?: (found: IGetPortfoliosSummary) => void;
    dictionaries: IGetPortfoliosSummary;
    type?: IPortfolioTypeModel;
}
export default function ManagedPortfolioMultiSelectField({
    name,
    label,
    required,
    onSelected,
    type,
    dictionaries: {
        portfolios,
        entities
    } }: IManagedPortfolioMultiSelectFieldProps) {
    const { referenceManagedPortfolioSearch } = useReduxActions("reference");
    const { referenceManagedPortfoliosSearched: referencePortfoliosSearched, referenceManagedPortfolioSearching: referencePortfolioSearching } = useReduxSelections("reference");
    // const foundPortfolioIds = referencePortfoliosSearched.portfolios.map(i => i.id);
    const foundPortfolios = React.useMemo(() => toDictionary(referencePortfoliosSearched.portfolios, i => i.id), [referencePortfoliosSearched]);
    const foundPortfolioIds = React.useMemo(() => referencePortfoliosSearched.portfolios.map(i => i.id), [referencePortfoliosSearched]);
    const validate = (portfolioId: number[] | undefined) => {
        if (!required) {
            return;
        }
        return (portfolioId?.length ?? 0) === 0 ? "At least one required" : undefined;
    }
    const [, { value = [], error, initialValue }, { setValue }] = useField<number[] | undefined>({ name, validate });
    const handleGetOptionLabel = (fromSearchResults: boolean, option: number) =>
        (fromSearchResults ? foundPortfolios : portfolios)[option]?.name ?? "...";
    label = <><SearchIcon fontSize="small"/> {label ?? "Portfolios"}: Type criteria to find a portfolio {required ? "*" : ""}</>;
    if ((initialValue || null) !== (value || null)) {
        label = <b>{label}</b>;
    }
    const handleValueSelected = (portfolioIds: number[]) => {
        if (onSelected) {
            const selectedPortfolios = toDictionary(portfolioIds.map(portfolioId => foundPortfolios[portfolioId]).filter(i => !!i), i => i.id);
            onSelected({
                portfolios: selectedPortfolios,
                entities: referencePortfoliosSearched.entities
            });
        }
        setValue(portfolioIds);
    }
    const handleOnSearchOptions = (criterias: string) => {
        if (criterias.length) {
            referenceManagedPortfolioSearch({ criterias, type });
        }
    }
    return <ReadOnlyContext.Consumer>{readOnly => readOnly ? null :
        <AsyncMultiSelect
            fullWidth={true}
            onSearchOptions={handleOnSearchOptions}
            onValueSelected={handleValueSelected}
            options={foundPortfolioIds}
            values={value}
            getLabelValue={handleGetOptionLabel}
            searching={referencePortfolioSearching}
            label={label}
            error={!!error}
            helperText={error} />}
    </ReadOnlyContext.Consumer>
}
