export type PathToValue = (string | number)[];

export interface IDashboardFormatting {
    label?: string;
    hidden?: boolean;
}
export interface IWidgetDashboardFormatting extends IDashboardFormatting {
    collapsed?: boolean;
    area?: string;
    positions?: string[];
    hiddens?: string[];
}
export interface IFieldsDashboardFormatting extends IWidgetDashboardFormatting {
    formats?: Record<string, FieldFormatting>;
}
export interface IObjectFieldsDashboardFormatting extends IFieldsDashboardFormatting {
    type: "fields";
}

export interface IArrayDashboardFormatting {
    displayCondition?: string;
    id?: string;
}
export interface IGridArrayDashboardFormatting extends IFieldsDashboardFormatting, IArrayDashboardFormatting {
    type: "grid";
    sorts?: FieldSorting[] | FieldSorting;
    groups?: string[] | string;
}
export interface IPieChartArrayDashboardFormatting extends IArrayDashboardFormatting, IWidgetDashboardFormatting {
    type: "pie";
    pivots?: string;
    labelField?: string;
    fieldValueType?: NumericFieldType;
    measures: string | string[];
}
export interface IDetailArrayFieldsDashboardFormatting extends IFieldsDashboardFormatting, IArrayDashboardFormatting {
    type: "detail";
}

// https://css-tricks.com/snippets/css/complete-guide-grid/#aa-grid-template-areas
// https://css-tricks.com/books/greatest-css-tricks/flexible-grids/
export interface ILayoutDashboardFormatting extends IWidgetDashboardFormatting {
    type: "layout";
    templateColumns?: string;
    templateRows?: string;
    templateAreas?: string[][];
}

type FieldSorting = string | { desc: string; };
export interface IFieldFormattingBase extends IDashboardFormatting {
    backgroundColor?: string | { value: number, color: string }[];
    foregroundColor?: string | { value: number, color: string }[];
    width?: number;
}
export interface IFieldFormatting extends IFieldFormattingBase {
    type?: FieldType;
}
export interface IFieldReportFormatting extends IFieldFormattingBase {
    type: "report";
    templateName: string;
    targetType?: "processExecution" | "entity" | "relationship" | "portfolio" | "security";
    taskCode?: string;
    fileType?: "pdf" | "docx" | "xlsx" | "xls",
    culture?: string;
    asOfDate?: string | Date
}
export type FieldFormatting = IFieldFormatting | IFieldReportFormatting;
export enum TimeFieldType {
    Date = "date",
    DateTime = "datetime"
}
export enum NumericFieldType {
    Integer = "integer",
    Decimal = "decimal",
    Decimal4 = "decimal4",
    Percentage = "percentage",
    Percentage2 = "percentage2",
    Percentage4 = "percentage4",
    Currency = "currency"
}
export enum ScreenLinkType {
    Person = "person",
    Company = "company",
    EntityGroup = "entityGroup",
    ManagedSicav = "managedSicav",
    Portfolio = "portfolio",
    ShareClass = "shareClass",
    Sicav = "sicav",
    SubFund = "subFund",
    Security = "security",
    Investor = "investor",
    Role = "role",
    Counterparty = "counterparty",
    Index = "index",
    Movement = "movement",
    PortfolioTransaction = "portfolioTransaction",
    Cash = "cash",
    ProcessExecution = "processExecution"
}
export enum FileLinkType {
    CashMovementFile = "cashMovementFile",
    EntityDocumentFile = "entityDocumentFile",
    PortfolioDocumentFile = "portfolioDocumentFile",
    SecurityDocumentFile = "securityDocumentFile"
};
//  |
// "processExecutionTaskFile";
export type LinkType = ScreenLinkType | FileLinkType;
export enum MiscFieldTypes {
    String = "string",
    Boolean = "boolean",
    Markdown = "markdown"
}
export type FieldType =
    NumericFieldType |
    TimeFieldType |
    LinkType |
    MiscFieldTypes;

export type ObjectFormatting = ILayoutDashboardFormatting | IObjectFieldsDashboardFormatting;
export type ArrayFormatting = IGridArrayDashboardFormatting | IDetailArrayFieldsDashboardFormatting | IPieChartArrayDashboardFormatting;
export type WidgetFormatting = ObjectFormatting | ArrayFormatting;
export type OneOrMany<T> = T | T[];

export interface IWidget {
    value: any;
    formatting: WidgetFormatting;
    field: PathToValue;
}
