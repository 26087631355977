import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IFeatureModel, IMacroScriptCategoryModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";

export default function MacroScriptCategories() {

    const { macroScriptCategories = [], macroScriptCategoryAllLoading } = useReduxSelections("macroScriptCategory");
    const { navigationNavigate } = useReduxActions("navigation");
    // IMacroScriptCategoryModel
    const columns: IColumnDefinition[] = [
        { name: "Name", title: "Name", getCellValue: ({ name }: IMacroScriptCategoryModel) => name, filteringEnabled: true }
    ];
    const state: IGridState = {
        "Name": { width: 280 }
    };

    const getRowKey = (row: IMacroScriptCategoryModel) => row.id || 0;
    const handleRowClick = (row: IMacroScriptCategoryModel) => navigationNavigate({ sectionKey: "detail", parameters: { id: row.id } });

    const handleAddNewClick = () => navigationNavigate({ sectionKey: "detail", parameters: { id: 0 } });

    return (<SearchPanel title="Macro Script Categories"
        onAddClick={handleAddNewClick}
        addAllowed={IFeatureModel.MacroCategoryWrite}
        isQuerying={macroScriptCategoryAllLoading}>
        <ExtendedGrid
            rows={macroScriptCategories}
            columns={columns}
            getRowId={getRowKey}
            onRowClick={handleRowClick}
            initialState={state} />
    </SearchPanel>);
}
