import { useCallback, useEffect, useRef } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Stimulsoft } from 'stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor';
import 'stimulsoft-reports-js/Css/stimulsoft.designer.office2013.whiteblue.css';
import 'stimulsoft-reports-js/Css/stimulsoft.viewer.office2013.whiteblue.css';
import { IValueMacroTypeModel, MacroSymbolModel } from 'proxy/apiProxy';
import { INotControllableEditorProps, useNotControllableEditor } from "./INotControllableEditorProps";

export interface IReportDesignerProps extends INotControllableEditorProps<string> {
    onPreviewDataRequest?: () => void;
    previewData?: any;
    dictionary: MacroSymbolModel;
}
export default function ReportDesigner(props: IReportDesignerProps) {
    const { dictionary, onPreviewDataRequest, previewData } = props;
    const designerInstanceRef = useRef<Stimulsoft.Designer.StiDesigner | undefined>();
    const reportRef = useRef<Stimulsoft.Report.StiReport | undefined>();
    useEffect(() => {
        if (designerInstanceRef.current || reportRef.current) {
            return;
        }
        setupStimulsoft();
        const options = new Stimulsoft.Designer.StiDesignerOptions();
        options.height = "100%";
        options.appearance.showSaveDialog = false;
        options.toolbar.showFileMenu = true;
        options.appearance.allowChangeWindowTitle = false;

        const designer = new Stimulsoft.Designer.StiDesigner(options, 'StiDesigner', false);
        designer.renderHtml(STIMULSOFT_REPORT_DESIGNER_ID);
        const report = new Stimulsoft.Report.StiReport();
        reportRef.current = report;
        designer.report = report;

        designerInstanceRef.current = designer;
    }, []);
    useEffect(() => {
        const designer = designerInstanceRef.current;
        if (designer == null) {
            return;
        }
        designer.onOpenedReport = ({ report }) => {
            reportRef.current = report;
        };
        
        if (onPreviewDataRequest) {
            designer.onPreviewReport = (_, __) => onPreviewDataRequest();
        }
        else {
            designer.onPreviewReport = () => { };
        }
        return () => {
            designer.onPreviewReport = () => { };
        }
    }, [onPreviewDataRequest, reportRef]);
    useEffect(() => {
        const designer = designerInstanceRef.current;
        if (designer == null) {
            return;
        }
        if (previewData && designer) {
            const dataSet = new Stimulsoft.System.Data.DataSet('DataUniverse');
            dataSet.readJson(JSON.stringify(previewData));
            designer.report.dictionary.dataSources.clear();
            designer.report.regData(dataSet.dataSetName, dataSet.dataSetName, dataSet);
            designer.report.dictionary.synchronize();
            designer.report.renderAsync();
        }
    }, [previewData]);
    const setter = useCallback((v?: string) => {
        const report = new Stimulsoft.Report.StiReport();
        if (v !== "") {
            report.load(v);
        }
        reportRef.current = report;
        if (designerInstanceRef.current && designerInstanceRef.current.report.reportGuid !== report.reportGuid) {
            designerInstanceRef.current.report = report;
        }
    }, []);
    const getter = useCallback(() => reportRef.current?.saveToJsonString(), []);
    useNotControllableEditor(props, setter, getter);

    useEffect(() => {
        if (reportRef.current) {
            const dataSet = createDataSet(dictionary);
            reportRef.current.dictionary.dataSources.clear();
            for (const dataTable of dataSet) {
                reportRef.current.dictionary.dataSources.add(dataTable);
                reportRef.current.dictionary.synchronize();
            }
        }
    }, [dictionary]);
    return <div id={STIMULSOFT_REPORT_DESIGNER_ID} style={{ height: "100%" }}>
        <CircularProgress />Loading the designer...
    </div>;
}

const STIMULSOFT_REPORT_DESIGNER_ID = "stimulsoftReportDesigner";


function setupStimulsoft() {
    Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkmtwX8O7s2IxR3Jd6nKdY8HU4+qFUbAFKGJQAbZSl58AlVEG" +
        "aUttzMjZ0RXGJThFx94yZrifDCmSL/9/74l5oGzUHjuPEkqIY1pI2ZYn6+KPnec3ujt+wqioiEzVAMBouYjQQXXgfZ" +
        "koYZFmgy9Xy2AbheOKaH0DR6l8jTDbsoZCA2Y502FgJh4dkK9d5fTyHXFR9TTnBoFpLpJPLfEEtYBv2ZNq6OGh1v0e" +
        "lR3yzxiAyIOH2H+maVLiIYDUPX68qkJyt3HKcmXopFZbFTVHfyCndxw6nec+HYHOqzBIAws0R52HkTw5SdXKXaQS/u" +
        "jYakYYX4RDvnq1GMT8R3kVN3a1XiAAuckLZFTNc47NMkeZr5p96lCi8dqwYfbZLDOh7Oe0UX9bQ5uNmxVgCZ7YuOQa" +
        "WGZOY66aAB9Lksq2Lz8uXqbQWzkruQ9ExQBdYWuJrM5Qa1cwY6ssw5ZbPxDY8+0NBsvqfM32poBme2HMPQy0Cmeb8C" +
        "3Q77NYnBjLjkdqJ45dT0RzJJbZLlsWJ9Oiv3JykxfZ96d4KDo0FjkSSX2aO756DqY8Px06OwipGEtgpgtjeEUFijQ7" +
        "Q7usL5YfFUH5rrBu0gWpXoTpMOfffZsMlx2zavAWtdmvLfid6jH7p4lOJE12wQGVXbVS+pak9w";
    const stringCategory = "Strings";

    const getTranslationParamNames = ["culturedString"];
    const getTranslationParamTypes = [String];
    const getTranslationParamDescriptions = ["Fund process cultured string in JSON format"];

    Stimulsoft.Report.Dictionary.StiFunctions.removeFunction("GetTranslation");
    Stimulsoft.Report.Dictionary.StiFunctions.addFunction(stringCategory, "GetTranslation", "GetTranslation", "Get the translation from a FundProcess JSON cultured string.",
        "", String, "Translation", getTranslationParamTypes, getTranslationParamNames, getTranslationParamDescriptions, (culturedString: string) => {
            const language = "en";
            if (!culturedString) {
                return "";
            }
            try {
                const dico = JSON.parse(culturedString);
                if (!dico) {
                    return "";
                }
                return dico[language] ?? dico.en ?? (Object.values(dico).length ? Object.values(dico)[0] : "");
            }
            catch {
                return "";
            }
        });
    Stimulsoft.Report.Dictionary.StiFunctions.removeFunction("GetEnumValue");


    const getEnumValueParamNames = ["enumeration", "value", "translations"];
    const getEnumValueParamTypes = [String, Number, String];
    const getEnumValueParamDescriptions = ["Name of the enumeration to get the label from", "Value you want to get the label for", "The translations definitions that contains the expected label"];

    Stimulsoft.Report.Dictionary.StiFunctions.addFunction(stringCategory, "GetEnumValue", "GetEnumValue", "Get the text label of an enumeration.",
        "", String, "TextLabel", getEnumValueParamTypes, getEnumValueParamNames, getEnumValueParamDescriptions, (enumeration: string, value: number, translations: string) => {
            const language = "en";
            if (!translations) {
                return "";
            }
            try {
                const dico = JSON.parse(translations);
                if (!dico) {
                    return "";
                }
                const enumDefinition = dico[enumeration];
                if (!enumDefinition) {
                    return "";
                }
                const valueDefinition = enumDefinition[value];
                if (!valueDefinition) {
                    return "";
                }
                return valueDefinition[language] ?? valueDefinition.en ?? "";
            }
            catch {
                return "";
            }
        });
}
function createStiDataTable(dataTableName: string, enumeratedTypeProperties: Record<string | number, MacroSymbolModel>): Stimulsoft.Report.Dictionary.StiDataTableSource {
    const dataTable = new Stimulsoft.Report.Dictionary.StiDataTableSource(`DataUniverse.${dataTableName}`, dataTableName, dataTableName, dataTableName);
    for (const key in enumeratedTypeProperties) {
        if (Object.prototype.hasOwnProperty.call(enumeratedTypeProperties, key)) {
            const element = enumeratedTypeProperties[key];
            const type = (function () {
                if (element.type !== "ValueMacroSymbolModel") {
                    throw new Error("invalid type");
                }
                switch (element.valueType) {
                    case IValueMacroTypeModel.Boolean: return element.nullable ? Stimulsoft.System.NullableBoolean : Stimulsoft.System.StiBoolean;
                    case IValueMacroTypeModel.Byte: return element.nullable ? Stimulsoft.System.NullableByte : Stimulsoft.System.Byte;
                    case IValueMacroTypeModel.Char: return element.nullable ? Stimulsoft.System.NullableChar : Stimulsoft.System.Char;
                    case IValueMacroTypeModel.DateTime: return element.nullable ? Stimulsoft.System.NullableDateTime : Stimulsoft.System.DateTime;
                    case IValueMacroTypeModel.Decimal: return element.nullable ? Stimulsoft.System.NullableDecimal : Stimulsoft.System.Decimal;
                    case IValueMacroTypeModel.Double: return element.nullable ? Stimulsoft.System.NullableDouble : Stimulsoft.System.Double;
                    case IValueMacroTypeModel.Guid: return element.nullable ? Stimulsoft.System.NullableGuid : Stimulsoft.System.Guid;
                    case IValueMacroTypeModel.Int16: return element.nullable ? Stimulsoft.System.NullableInt16 : Stimulsoft.System.Int16;
                    case IValueMacroTypeModel.Int32: return element.nullable ? Stimulsoft.System.NullableInt32 : Stimulsoft.System.Int32;
                    case IValueMacroTypeModel.Int64: return element.nullable ? Stimulsoft.System.NullableInt64 : Stimulsoft.System.Int64;
                    case IValueMacroTypeModel.SByte: return element.nullable ? Stimulsoft.System.NullableSByte : Stimulsoft.System.SByte;
                    case IValueMacroTypeModel.Single: return element.nullable ? Stimulsoft.System.NullableSingle : Stimulsoft.System.Single;
                    case IValueMacroTypeModel.String: return element.nullable ? Stimulsoft.System.StiString : Stimulsoft.System.StiString;
                    case IValueMacroTypeModel.UInt16: return element.nullable ? Stimulsoft.System.NullableUInt16 : Stimulsoft.System.UInt16;
                    case IValueMacroTypeModel.UInt32: return element.nullable ? Stimulsoft.System.NullableUInt32 : Stimulsoft.System.UInt32;
                    case IValueMacroTypeModel.UInt64: return element.nullable ? Stimulsoft.System.NullableUInt64 : Stimulsoft.System.UInt64;
                    default: throw new Error("invalid type");
                }
            })();
            dataTable.columns.add(key, type);
        }
    }
    return dataTable;
}
function createDataSet(macroSymbolModel?: MacroSymbolModel): Stimulsoft.Report.Dictionary.StiDataTableSource[] {

    const dataSet: Stimulsoft.Report.Dictionary.StiDataTableSource[] = [];
    if (macroSymbolModel?.type === "ObjectMacroSymbolModel") {
        for (const key in macroSymbolModel.properties) {
            if (Object.prototype.hasOwnProperty.call(macroSymbolModel.properties, key)) {
                const element = macroSymbolModel.properties[key];
                if (element?.type === "EnumerableMacroSymbolModel" && element.enumeratedType?.type === "ObjectMacroSymbolModel") {
                    dataSet.push(createStiDataTable(key, element.enumeratedType.properties));
                }
            }
        }
    }
    return dataSet;
}
