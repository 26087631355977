import { FieldArray, FieldArrayRenderProps, useField } from "formik";
import { ICurrencyModel, ILoanModel, SecuritySummaryModel, ISubFundSummaryModel, ICountryModel, isRegularSecuritySummaryModel, isSecurityInstrumentSummaryModel } from "proxy/apiProxy";
import { IGetSecuritySummary } from "reducers/Reference";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { oProps } from "tools/lib/utility";
import SecuritySearch from "components/searchers/SecuritySearch";
import { Table, TableRow, TableCell, TableHead, TableBody, Typography, Tooltip, IconButton } from "@material-ui/core";
import { useReduxSelections } from "tools/lib/reduxStoreAccess";
import CloseIcon from '@material-ui/icons/Close';
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import { getSecurityTypeLabel } from "features/Security/getSecurityTypeLabel";

export interface ICollateralsProps {
    securities: Record<string | number, SecuritySummaryModel>;
    subFunds: Record<string | number, ISubFundSummaryModel>;
    addSecurityInDictionary: (payload: IGetSecuritySummary) => void;
    onSecuritySelected: (securityId: number) => void;
}
export default function Collaterals({ securities, addSecurityInDictionary, subFunds, onSecuritySelected }: ICollateralsProps) {
    const { referenceCountries = {}, referenceCurrencies = {} } = useReduxSelections("reference");

    const [{ value: collaterals }] = useField<ILoanModel["collaterals"]>(oProps<ILoanModel>().path("collaterals"));
    return <FieldArray name={oProps<ILoanModel>().path("collaterals")} render={renderCollaterals} validateOnChange={true} />;
    function renderCollaterals({ push, remove, form: { validateForm } }: FieldArrayRenderProps): React.ReactNode {
        const handleRemove = (id: number) => {
            const idx = collaterals.indexOf(id);
            if (idx < 0) {
                return;
            }
            remove(idx);
            // console.log(id);
            setTimeout(() => validateForm(), 100);
        }
        const handleAddSecurity = (payload: IGetSecuritySummary) => {
            if (collaterals.indexOf(payload.security.id) >= 0) {
                return;
            }
            addSecurityInDictionary(payload);
            push(payload.security.id);
        }
        return (<ReadOnlyContext.Consumer>{readOnly => <><CollateralsTable
            onSecuritySelected={onSecuritySelected}
            subFunds={subFunds}
            referenceCurrencies={referenceCurrencies}
            referenceCountries={referenceCountries}
            securities={securities}
            collateralIds={collaterals}
            readOnly={readOnly}
            onRemove={handleRemove} />
            {!readOnly && <SecuritySearch onSelected={handleAddSecurity} />}
        </>}</ReadOnlyContext.Consumer>);
    }
}
interface ICollateralsTableProps {
    securities: Record<string | number, SecuritySummaryModel>;
    subFunds: Record<string | number, ISubFundSummaryModel>;
    referenceCurrencies: Record<number | string, ICurrencyModel>;
    referenceCountries: Record<number | string, ICountryModel>;
    collateralIds: number[];
    readOnly: boolean;
    onRemove: (securityId: number) => void;
    onSecuritySelected: (securityId: number) => void;
}
function CollateralsTable({ securities, collateralIds, readOnly, onRemove, referenceCurrencies, referenceCountries, subFunds, onSecuritySelected }: ICollateralsTableProps) {
    if (!collateralIds?.length) {
        return <Typography>No collateral</Typography>
    }
    return <Table>
        <TableHead>
            <TableRow>
                <TableCell size="small">Type</TableCell>
                <TableCell size="small">Isin</TableCell>
                <TableCell size="small">Code</TableCell>
                <TableCell size="small">Name</TableCell>
                <TableCell size="small">Country</TableCell>
                {!readOnly && <TableCell size="small" />}
                <TableCell size="small" />
            </TableRow>
        </TableHead>
        <TableBody>
            {collateralIds.map((securityId) => <CollateralRow
                subFunds={subFunds}
                referenceCurrencies={referenceCurrencies}
                referenceCountries={referenceCountries}
                key={securityId}
                securityId={securityId}
                onRemove={onRemove}
                readOnly={readOnly}
                onSecuritySelected={onSecuritySelected}
                securities={securities} />)}
        </TableBody>
    </Table>
}

interface ICollateralRowProps {
    referenceCurrencies: Record<number | string, ICurrencyModel>;
    referenceCountries: Record<number | string, ICountryModel>;
    securityId: number;
    securities: Record<string | number, SecuritySummaryModel>;
    subFunds: Record<string | number, ISubFundSummaryModel>;
    onRemove: (securityId: number) => void;
    readOnly: boolean;
    onSecuritySelected: (securityId: number) => void;
}
function CollateralRow({ securityId, onRemove, securities, referenceCurrencies, referenceCountries, subFunds, readOnly, onSecuritySelected }: ICollateralRowProps) {
    const security = securities[securityId];

    if (!security) {
        return <TableRow>
            <TableCell size="small" />
            <TableCell size="small" />
            <TableCell size="small" />
            <TableCell size="small" />
            <TableCell size="small" />
            {!readOnly && <TableCell size="small" />}
            <TableCell size="small" />
        </TableRow>
    }
    const handleRemove = () => onRemove(securityId);
    const handleSelect = () => onSecuritySelected(securityId);
    const country = (function (secu: SecuritySummaryModel) {
        let countryId: number | undefined;
        if (secu.type === "ShareClassSummaryModel" && secu.subFundId) {
            if (subFunds[secu.subFundId].countryId) {
                countryId = subFunds[secu.subFundId].countryId
            }
        }
        else if (isRegularSecuritySummaryModel(secu) || secu.type === "RealEstateSummaryModel") {
            countryId = secu.countryId;
        }
        if (!countryId) {
            return undefined;
        }
        const country = referenceCountries[countryId];
        if (!country) {
            return "???";
        }
        return country.name?.en;
    })(security);
    return <TableRow>
        <TableCell size="small">{getSecurityTypeLabel(security.type)}</TableCell>
        <TableCell size="small">{isSecurityInstrumentSummaryModel(security) && security.isin}</TableCell>
        <TableCell size="small">{security.internalCode}</TableCell>
        <TableCell size="small">{security.name}</TableCell>
        <TableCell size="small">{country}</TableCell>
        {!readOnly && <TableCell align="right" size="small">
            <Tooltip title="Unlink">
                <IconButton
                    size="small"
                    aria-label="Unlink"
                    style={{ verticalAlign: "middle" }}
                    onClick={handleRemove} >
                    <CloseIcon />
                </IconButton>
            </Tooltip>
        </TableCell>}
        <TableCell align="right" size="small">
            <Tooltip title="Open">
                <IconButton
                    size="small"
                    aria-label="Open"
                    style={{ verticalAlign: "middle" }}
                    onClick={handleSelect} >
                    <OpenInBrowser />
                </IconButton>
            </Tooltip>
        </TableCell>
    </TableRow>
}
