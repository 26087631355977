import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import ProcessDefinitionData from "./ProcessDefinitionData";
import ProcessDefinitionTasksData from "./ProcessDefinitionTasksData";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IFeatureModel, IProcessDefinitionModel } from "proxy/apiProxy";
import WriteAccess from "tools/fieldComponents/WriteAccess";

// function getRelatedWriteFeature(type: IProcessDefinitionModel["type"]): IFeatureModel {
//     switch (type) {
//         case "InvestorKycProcessModel": return IFeatureModel.InvestorRelationshipDueDiligenceApprove;
//         case "CounterpartyKycProcessModel": return IFeatureModel.CounterpartyRelationshipDueDiligenceApprove;
//         case "RoleModel": return IFeatureModel.RoleRelationshipDueDiligenceApprove;
//         case "SecurityInvestmentChecksProcessModel": return IFeatureModel.UniverseDueDiligenceApprove;
//         case "SecurityComplianceProcessModel": return IFeatureModel.UniverseDueDiligenceApprove;
//         case "EntityChecksProcessModel": return IFeatureModel.EntityDueDiligenceApprove;
//         case "EntityComplianceProcessModel": return IFeatureModel.EntityDueDiligenceApprove;
//     }
// }

export default function ProcessDefinition() {
    const { navigationActiveScreen: navigationActiveUrlNode } = useReduxSelections("navigation");
    const { navigationNavigate } = useReduxActions("navigation");
    const { processDefinitionDelete, processDefinitionSave } = useReduxActions("processDefinition");
    const { processDefinitionCurrent, processDefinitionLoading, processDefinitionQuestionnaires } = useReduxSelections("processDefinition");
    const { classificationTypes } = useReduxSelections("processClassificationType");
    const handleBack = () => navigationNavigate(undefined);
    if (!processDefinitionCurrent) {
        return <DetailPanelLoading onBackClick={handleBack} tabNumber={2} hasSubTitle={false} />;
    }
    const title = !!processDefinitionCurrent.id ? (processDefinitionCurrent.name["en"] ?? processDefinitionCurrent.name.en) : `New Process Definition`;
    const tabValue = navigationActiveUrlNode?.activeSectionKey;

    const tabs: ISubMenu[] = [{
        label: "Data",
        value: "detail"
    }, {
        label: "Tasks",
        value: "tasks"
    }];
    const handleDelete = () => {
        if (processDefinitionCurrent.id) {
            processDefinitionDelete(processDefinitionCurrent.id);
        }
    }
    const handleTabValueChanged = (value: any) => navigationNavigate({ sectionKey: value as string });

    const handleSubmit = (values: IProcessDefinitionModel, { setSubmitting }: FormikHelpers<IProcessDefinitionModel>) => {
        processDefinitionSave(values);
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={processDefinitionCurrent} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;

    function renderForm({ dirty, isValid, submitForm }: FormikProps<IProcessDefinitionModel>) {
        if (!processDefinitionCurrent) {
            return null;
        }

        return <WriteAccess value={IFeatureModel.ProcessDefinitionWrite}><DetailPanel
            isQuerying={processDefinitionLoading}
            title={title}
            canSave={dirty && isValid}
            onSaveClick={submitForm}
            canDelete={!!processDefinitionCurrent.id}
            onDeleteClick={handleDelete}
            onBackClick={handleBack}
            badge={!processDefinitionCurrent.id ? "new" : undefined}
            saveAllowed={IFeatureModel.ProcessDefinitionWrite}
            deleteAllowed={IFeatureModel.ProcessDefinitionWrite}
            tabs={tabs}
            tabValue={tabValue}
            onTabValueChanged={handleTabValueChanged}>
            {(tabValue === "detail") && <ProcessDefinitionData classificationTypes={classificationTypes} />}
            {(tabValue === "tasks") && <ProcessDefinitionTasksData questionnaires={processDefinitionQuestionnaires} />}
        </DetailPanel></WriteAccess>
    }
}
