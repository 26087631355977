import { Formik, FormikHelpers, FormikProps } from 'formik';
import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import ClassificationTypeData from "./ClassificationTypeData";
import ClassificationsData from "./ClassificationsData";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { ClassificationTypeDomain, getClassificationTypeDomainLabel } from "./ClassificationTypeDomain";
import { getRelatedGrants } from "./getRelatedGrants";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { useMemo } from "react";
import { IEditableClassificationType, ITreeItem } from "./treeTypes";
import { IClassificationWithChildrenModel } from "proxy/apiProxy";

function useClassificationTypeSelections(domain: ClassificationTypeDomain) {
    const { classificationTypeLoading: securityClassificationTypeLoading, classificationTypeSaving: securityClassificationTypeSaving, classificationType: securityClassificationType } = useReduxSelections("securityClassificationType");
    const { classificationTypeLoading: investorClassificationTypeLoading, classificationTypeSaving: investorClassificationTypeSaving, classificationType: investorClassificationType } = useReduxSelections("investorClassificationType");
    const { classificationTypeLoading: roleRelationshipClassificationTypeLoading, classificationTypeSaving: roleRelationshipClassificationTypeSaving, classificationType: roleRelationshipClassificationType } = useReduxSelections("roleRelationshipClassificationType");
    const { classificationTypeLoading: movementClassificationTypeLoading, classificationTypeSaving: movementClassificationTypeSaving, classificationType: movementClassificationType } = useReduxSelections("movementClassificationType");
    const { classificationTypeLoading: entityClassificationTypeLoading, classificationTypeSaving: entityClassificationTypeSaving, classificationType: entityClassificationType } = useReduxSelections("entityClassificationType");
    const { classificationTypeLoading: noteClassificationTypeLoading, classificationTypeSaving: noteClassificationTypeSaving, classificationType: noteClassificationType } = useReduxSelections("noteClassificationType");
    const { classificationTypeLoading: processClassificationTypeLoading, classificationTypeSaving: processClassificationTypeSaving, classificationType: processClassificationType } = useReduxSelections("processClassificationType");
    const { classificationTypeLoading: developmentItemClassificationTypeLoading, classificationTypeSaving: developmentItemClassificationTypeSaving, classificationType: developmentItemClassificationType } = useReduxSelections("developmentItemClassificationType");
    const { securityClassificationTypeSave, securityClassificationTypeDelete } = useReduxActions("securityClassificationType");
    const { investorClassificationTypeSave, investorClassificationTypeDelete } = useReduxActions("investorClassificationType");
    const { roleRelationshipClassificationTypeSave, roleRelationshipClassificationTypeDelete } = useReduxActions("roleRelationshipClassificationType");
    const { movementClassificationTypeSave, movementClassificationTypeDelete } = useReduxActions("movementClassificationType");
    const { entityClassificationTypeSave, entityClassificationTypeDelete } = useReduxActions("entityClassificationType");
    const { noteClassificationTypeSave, noteClassificationTypeDelete } = useReduxActions("noteClassificationType");
    const { processClassificationTypeSave, processClassificationTypeDelete } = useReduxActions("processClassificationType");
    const { developmentItemClassificationTypeSave, developmentItemClassificationTypeDelete } = useReduxActions("developmentItemClassificationType");

    switch (domain) {
        case ClassificationTypeDomain.Security: return {
            classificationTypeLoading: securityClassificationTypeLoading,
            classificationTypeSaving: securityClassificationTypeSaving,
            classificationType: securityClassificationType,
            classificationTypeSave: securityClassificationTypeSave,
            classificationTypeDelete: securityClassificationTypeDelete,
        };
        case ClassificationTypeDomain.Process: return {
            classificationTypeLoading: processClassificationTypeLoading,
            classificationTypeSaving: processClassificationTypeSaving,
            classificationType: processClassificationType,
            classificationTypeSave: processClassificationTypeSave,
            classificationTypeDelete: processClassificationTypeDelete
        };
        case ClassificationTypeDomain.Investor: return {
            classificationTypeLoading: investorClassificationTypeLoading,
            classificationTypeSaving: investorClassificationTypeSaving,
            classificationType: investorClassificationType,
            classificationTypeSave: investorClassificationTypeSave,
            classificationTypeDelete: investorClassificationTypeDelete
        };
        case ClassificationTypeDomain.RoleRelationship: return {
            classificationTypeLoading:roleRelationshipClassificationTypeLoading,
            classificationTypeSaving: roleRelationshipClassificationTypeSaving,
            classificationType: roleRelationshipClassificationType,
            classificationTypeSave: roleRelationshipClassificationTypeSave,
            classificationTypeDelete: roleRelationshipClassificationTypeDelete
        };
        case ClassificationTypeDomain.Movement: return {
            classificationTypeLoading: movementClassificationTypeLoading,
            classificationTypeSaving: movementClassificationTypeSaving,
            classificationType: movementClassificationType,
            classificationTypeSave: movementClassificationTypeSave,
            classificationTypeDelete: movementClassificationTypeDelete
        };
        case ClassificationTypeDomain.Entity: return {
            classificationTypeLoading: entityClassificationTypeLoading,
            classificationTypeSaving: entityClassificationTypeSaving,
            classificationType: entityClassificationType,
            classificationTypeSave: entityClassificationTypeSave,
            classificationTypeDelete: entityClassificationTypeDelete
        };
        case ClassificationTypeDomain.Note: return {
            classificationTypeLoading: noteClassificationTypeLoading,
            classificationTypeSaving: noteClassificationTypeSaving,
            classificationType: noteClassificationType,
            classificationTypeSave: noteClassificationTypeSave,
            classificationTypeDelete: noteClassificationTypeDelete
        };
        case ClassificationTypeDomain.DevelopmentItem: return {
            classificationTypeLoading: developmentItemClassificationTypeLoading,
            classificationTypeSaving: developmentItemClassificationTypeSaving,
            classificationType: developmentItemClassificationType,
            classificationTypeSave: developmentItemClassificationTypeSave,
            classificationTypeDelete: developmentItemClassificationTypeDelete
        };
    }
}
export interface IClassificationTypeProps {
    domain: ClassificationTypeDomain
}

export default function ClassificationType({ domain }: IClassificationTypeProps) {
    const {
        classificationTypeLoading,
        classificationTypeSaving,
        classificationType,
        classificationTypeSave,
        classificationTypeDelete
    } = useClassificationTypeSelections(domain);

    const { navigationActiveScreen: navigationActiveUrlNode } = useReduxSelections("navigation");

    const { navigationNavigate } = useReduxActions("navigation");
    const handleBack = () => navigationNavigate(undefined);

    const editableClassificationType: IEditableClassificationType | undefined = useMemo(() => {
        if (!classificationType) {
            return;
        }
        const { classifications, ...data } = classificationType;
        return {
            ...data,
            classifications: classifications.map(mapFromModelToTreeItem)
        };
    }, [classificationType]);

    if (!editableClassificationType) {
        return <DetailPanelLoading onBackClick={handleBack} tabNumber={2} hasSubTitle={false} />;
    }
    const tabValue = navigationActiveUrlNode?.activeSectionKey;
    const relatedGrants = getRelatedGrants(domain);

    const tabs: ISubMenu[] | undefined = [{
        label: "Data",
        value: "detail"
    }, {
        label: "Classifications",
        value: "classifications"
    }];

    const handleTabValueChanged = (value: any) => navigationNavigate({ sectionKey: value as string });

    const handleDeleteType = () => {
        if (classificationType && classificationType.id) {
            classificationTypeDelete(classificationType.id);
        }
    }
    const handleSubmit = (values: IEditableClassificationType, { setSubmitting }: FormikHelpers<IEditableClassificationType>) => {
        const { classifications, ...data } = values;
        const newModel = {
            ...data,
            classifications: classifications.map(mapFromTreeItemToModel)
        };
        classificationTypeSave(newModel);
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={editableClassificationType} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>

    function renderForm({ dirty, isValid, submitForm, values }: FormikProps<IEditableClassificationType>) {
        return <WriteAccess value={relatedGrants}><DetailPanel
            isQuerying={classificationTypeLoading || classificationTypeSaving}
            tabs={tabs}
            tabValue={tabValue}
            onTabValueChanged={handleTabValueChanged}
            badge={!values.id ? "new" : undefined}
            title={`${getClassificationTypeDomainLabel(domain)} Classification Type`}
            onBackClick={handleBack}
            canSave={dirty && isValid}
            saveAllowed={relatedGrants}
            deleteAllowed={relatedGrants}
            onSaveClick={submitForm}
            canDelete={!!values.id}
            onDeleteClick={handleDeleteType}>
            {(tabValue === "detail") && <ClassificationTypeData />}
            {(tabValue === "classifications") && <ClassificationsData />}
        </DetailPanel></WriteAccess>
    }
}

function mapFromModelToTreeItem({ classifications, ...model }: IClassificationWithChildrenModel): ITreeItem {
    const { code, name } = model;
    return { title: code, subtitle: name ? name["en"] : "-", children: classifications.map(mapFromModelToTreeItem), model };
}
function mapFromTreeItemToModel({ children, expanded, ...item }: ITreeItem): IClassificationWithChildrenModel {
    return { ...item.model, classifications: ((children ?? []) as ITreeItem[]).map(mapFromTreeItemToModel) };
}
