import { MutableRefObject, useEffect, useRef } from "react";
export class EditorReferencePayload<T>{
    constructor(public initialValue: T | undefined) { }

    // actually sets the value on the editor component
    private _setter: ((value: T | undefined) => void) | undefined;

    // actually gets the value from the editor component
    private _getter: (() => T | undefined) | undefined;

    // the editor component just loaded and plugs its method
    public setEditor(setter: (value: T | undefined) => void, getter: () => T | undefined) {
        this._setter = setter;
        this._setter(this.initialValue);
        this._getter = getter;
    }
    // the editor just unloaded, its methods must be unplugged
    public unsetEditor() {
        if (this._getter) {
            this.initialValue = this._getter();
        }
        delete this._setter;
        delete this._getter;
    }
    public getValue(): T | undefined {
        if (this._getter) {
            return this._getter();
        }
        return this.initialValue;
    }
    // only from containers
    // sets value once loaded item
    // must ensure the editor contains this value;
    public setValue(value: T | undefined) {
        if (this._setter) {
            this._setter(value);
        }
        this.initialValue = value;
    }
}
// export interface IEditorReferencePayload<T> {
//     getValue?: () => T | undefined;
//     setValue?: (value: T | undefined) => void;
//     initialValue: T | undefined;
// }
export function useEditorRef<T>(initialValue: T | undefined): MutableRefObject<EditorReferencePayload<T>> {
    const ref = useRef<EditorReferencePayload<T>>(new EditorReferencePayload<T>(initialValue));
    useEffect(() => {
        ref.current.setValue(initialValue);
    }, [initialValue])
    return ref;
}
export interface INotControllableEditorProps<T> {
    refToGetValue: MutableRefObject<EditorReferencePayload<T>>;
}
export function useNotControllableEditor<T>({ refToGetValue }: INotControllableEditorProps<T>, setValue: (v: T | undefined) => void, getValue: () => T | undefined) {
    useEffect(() => {
        refToGetValue.current.setEditor(setValue, getValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => refToGetValue.current.unsetEditor();
    }, [getValue, setValue, refToGetValue]);
}
