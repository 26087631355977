import { IBatchModel, IBatchSummaryModel, IFeatureModel } from "proxy/apiProxy";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
// import StickyFab from "tools/components/StickyFab";
import cronstrue from 'cronstrue';
import SearchPanel from "tools/components/SearchPanel";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";

function isSummary(entity: IBatchSummaryModel | IBatchModel): entity is IBatchSummaryModel {
    return typeof (entity as IBatchSummaryModel).hasCondition !== "undefined";
}

export default function Batches() {
    const { navigationNavigate } = useReduxActions("navigation");
    const {
        batches = [],
        batchAllLoading
    } = useReduxSelections("batch");
    const { navigationActiveSiteMapNode } = useReduxSelections("navigation");
    const columns: IColumnDefinition[] = [
        { name: "Code", title: "Code", getCellValue: (row: IBatchSummaryModel | IBatchModel) => row.code, filteringEnabled: true },
        { name: "Name", title: "Name", getCellValue: (row: IBatchSummaryModel | IBatchModel) => row.name, filteringEnabled: true },
        { name: "Version", title: "Version", getCellValue: (row: IBatchSummaryModel | IBatchModel) => row.publishedVersion, },
        { name: "Schedule", title: "Schedule", getCellValue: (row: IBatchSummaryModel | IBatchModel) => row.cron ? cronstrue.toString(row.cron, { throwExceptionOnParseError: false, use24HourTimeFormat: true }) : undefined, },
        { name: "WithCondition", title: "With Condition", getCellValue: (row: IBatchSummaryModel | IBatchModel) => isSummary(row) ? row.hasCondition : (row.conditionalScript ?? "") === "" ? false : true, columnType: "boolean" },
        { name: "LastUpdate", title: "Last update", getCellValue: (row: IBatchSummaryModel | IBatchModel) => row.updatedOn, columnType: "dateTime" }
    ];
    const state: IGridState = {
        "Code": { width: 280 },
        "Name": { width: 280 },
        "Version": { width: 120 },
        "Schedule": { width: 280 },
        "LastUpdate": { width: 220 },
    };
    const handleRowEdit = ({ id }: IBatchSummaryModel | IBatchModel) => navigationNavigate({ sectionKey: "detail", parameters: { id } });
    const getRowKey = (row: IBatchSummaryModel | IBatchModel) => row.id || 0;

    const handleAddNewClick = () => navigationNavigate({ sectionKey: "detail", parameters: { id: 0 } });

    return <SearchPanel
        title={navigationActiveSiteMapNode?.screen?.label ?? "Batches"}
        addAllowed={IFeatureModel.BatchWrite}
        onAddClick={handleAddNewClick}
        isQuerying={batchAllLoading}>
        <ExtendedGrid
            rows={batches}
            columns={columns}
            getRowId={getRowKey}
            initialState={state}
            onRowClick={handleRowEdit} />
    </SearchPanel>
}
