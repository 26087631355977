import portfolioTransaction from "immer";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import {
	IGetRegisterTransactionsModel, IGetRegisterTransactionModel, RegisterTransactionModel, PortfolioSummaryModel, ISubFundSummaryModel,
	SecuritySummaryModel, EntitySummaryModel, RelationshipSummaryModel, RegisterTransactionSummaryModel
} from "proxy/apiProxy";
import { IRegisterTransactionsSearchParameters } from "proxy/apiProxy";
import { IGetRelationshipSummary, IGetPortfoliosSummary, IGetPortfolioSummary, IGetSecuritySummary } from "./Reference";

export const ActionFactories = produceActionFactories({
	registerTransactionLoad: (payload: (RegisterTransactionModel | RegisterTransactionSummaryModel)["type"] | number) => payload,
	registerTransactionLoaded: (payload: IGetRegisterTransactionModel) => payload,
	registerTransactionSave: (payload: RegisterTransactionModel) => payload,
	registerTransactionSaved: (payload: RegisterTransactionModel) => payload,
	registerTransactionDelete: (payload: number) => payload,
	registerTransactionDeleted: (payload: number) => payload,
	registerTransactionsSearch: (payload: IRegisterTransactionsSearchParameters) => payload,
	registerTransactionsLoaded: (payload: IGetRegisterTransactionsModel) => payload,
	registerTransactionAddSecurityInDictionary: (payload: IGetSecuritySummary) => payload,
	registerTransactionAddRelationshipInDictionary: (payload: IGetRelationshipSummary) => payload,
	registerTransactionAddPortfolioInDictionary: (payload: IGetPortfolioSummary) => payload,
	registerTransactionAddPortfoliosToDictionary: (payload: IGetPortfoliosSummary) => payload
});

export interface IState {
	transactionsLoading: boolean;
	transactionLoading: boolean;
	transactionDeleting: boolean;
	transactions: (RegisterTransactionSummaryModel | RegisterTransactionModel)[];
	transactionCurrent?: RegisterTransactionModel;
	transactionSaving: boolean;
	dictionaries: {
		portfolios: Record<string | number, PortfolioSummaryModel>;
		subFunds: Record<string | number, ISubFundSummaryModel>;
		securities: Record<string | number, SecuritySummaryModel>;
		entities: Record<string | number, EntitySummaryModel>;
		relationships: Record<string | number, RelationshipSummaryModel>;
	}
}

export function reducer(
	state: IState = {
		transactionsLoading: false,
		transactionLoading: false,
		transactionSaving: false,
		transactionDeleting: false,
		transactions: [],
		dictionaries: {
			portfolios: {},
			subFunds: {},
			securities: {},
			entities: {},
			relationships: {}
		}
	},
	action: AnyActionOf<typeof ActionFactories>
): IState {
	return portfolioTransaction(state, draft => {
		switch (action.type) {
			case "registerTransactionAddSecurityInDictionary":
				draft.dictionaries.securities[action.payload.security.id] = action.payload.security;
				draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds };
				draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
				break;
			case "registerTransactionAddRelationshipInDictionary":
				draft.dictionaries.relationships[action.payload.relationship.id] = action.payload.relationship;
				draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
				break;
			case "registerTransactionAddPortfolioInDictionary":
				draft.dictionaries.portfolios[action.payload.portfolio.id] = action.payload.portfolio;
				draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
				break;
			case "registerTransactionAddPortfoliosToDictionary":
				draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
				draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
				break;
			case "registerTransactionsSearch":
				draft.transactionsLoading = true;
				break;
			case "registerTransactionsLoaded":
				draft.transactionsLoading = false;
				const { transactions, ...dictionaries } = action.payload;
				draft.dictionaries = { ...draft.dictionaries, ...dictionaries };
				draft.transactions = action.payload.transactions;
				break;
			case "registerTransactionLoad":
				draft.transactionLoading = true;
				draft.transactionCurrent = undefined;
				break;
			case "registerTransactionLoaded":
				draft.transactionLoading = false;
				draft.transactionCurrent = action.payload.transaction;
				draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
				draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
				draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds };
				draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
				draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
				break;
			case "registerTransactionDelete":
				draft.transactionDeleting = true;
				break;
			case "registerTransactionDeleted":
				draft.transactionDeleting = false;
				const deletedId = action.payload;
				if (draft.transactionCurrent?.id === deletedId) {
					delete draft.transactionCurrent;
				}
				const idx = draft.transactions.findIndex(i => i.id === deletedId);
				if (idx >= 0) {
					draft.transactions.splice(idx, 1);
				}
				break;
			case "registerTransactionSave":
				draft.transactionSaving = true;
				break;
			case "registerTransactionSaved":
				draft.transactionSaving = false;
				const { comment, ...saved } = action.payload;
				const hasComment = (comment && Object.values(comment).filter(c => c && !!c && c.trim() !== "").length > 0) ?? false;
				const summarySaved = { ...saved, hasComment };
				if (draft.transactionCurrent) {
					draft.transactionCurrent = action.payload;
				}
				const existing = draft.transactions.find(i => i.id === saved.id);
				if (existing) {
					Object.assign(existing, summarySaved);
				}
				else {
					draft.transactions.push(summarySaved);
				}
				break;
		}
	});
}
