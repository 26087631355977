import { ICountryModel, ICurrencyModel, EntitySummaryModel, IBankAccountModel, PortfolioSummaryModel } from "proxy/apiProxy";
import FormTextField from "tools/fieldComponents/FormTextField";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FieldBox from "tools/components/FieldBox";
import { oProps } from "tools/lib/utility";
import { IGetEntitySummary, IGetPortfolioSummary } from "reducers/Reference";
import EntitySummary from "components/summaries/EntitySummary";
import EntitySelectField from "components/searchers/EntitySelectField";
import { CurrencySelectField } from "components/searchers/CurrencySelectField";
import ManagedPortfolioSelectField from "components/searchers/ManagedPortfolioSelectField";
import ManagedPortfolioSummary from "components/summaries/ManagedPortfolioSummary";

export interface IBankAccountDataProps {
    referenceCountries: Record<number, ICountryModel>;
    referenceCurrencies: Record<number, ICurrencyModel>;
    accountTypes: { [x: string]: string; };
    dictionaries: {
        portfolios: Record<string | number, PortfolioSummaryModel>;
        entities: Record<string | number, EntitySummaryModel>;
    }
    bankAccountAddEntityInDictionary: (payload: IGetEntitySummary) => {
        type: "bankAccountAddEntityInDictionary";
        payload: IGetEntitySummary;
    };

    bankAccountAddPortfolioInDictionary: (payload: IGetPortfolioSummary) => {
        type: "bankAccountAddPortfolioInDictionary";
        payload: IGetPortfolioSummary;
    }
}

export default function BankAccountData({
    referenceCurrencies,
    referenceCountries,
    accountTypes,
    dictionaries,
    bankAccountAddEntityInDictionary,
    bankAccountAddPortfolioInDictionary
}: IBankAccountDataProps) {
    const {
        portfolios,
        entities
    } = dictionaries;

    return <FieldBox display="flex" flexDirection="column">
        <FieldBox display="flex" flexDirection="row">
            <FormTextField name={oProps<IBankAccountModel>().path("internalCode")} label="Internal Code"
                required={true} maxLength={100} />
            <FormTextField name={oProps<IBankAccountModel>().path("name")} label="Name" required={true}
                maxLength={250} />
        </FieldBox>

        <FieldBox display="flex" flexDirection="row">
            <CurrencySelectField name={oProps<IBankAccountModel>().path("currencyId")} label="Currency"
                required={true} />
            <FormTextField name={oProps<IBankAccountModel>().path("iban")} label="Iban" maxLength={34} />
            <FormSimpleSelectField name={oProps<IBankAccountModel>().path("accountType")} label="Type"
                options={accountTypes} />
        </FieldBox>

        <FieldBox display="flex" flexDirection="row">
            <FormDatePickerField name={oProps<IBankAccountModel>().path("inceptionDate")} label="Inception Date" />
            <FormDatePickerField name={oProps<IBankAccountModel>().path("termDate")} label="Term Date" />
            <FormTextField name={oProps<IBankAccountModel>().path("rate")} label="Rate" />
        </FieldBox>

        <EntitySelectField name={oProps<IBankAccountModel>().path("issuerId")} label="Issuer"
            onSelected={bankAccountAddEntityInDictionary} />
        <EntitySummary name={oProps<IBankAccountModel>().path("issuerId")} label="Issuer"
            countries={referenceCountries} entities={entities} />

        <EntitySelectField name={oProps<IBankAccountModel>().path("bankId")} label="Bank"
            onSelected={bankAccountAddEntityInDictionary} />
        <EntitySummary name={oProps<IBankAccountModel>().path("bankId")} label="Bank"
            countries={referenceCountries} entities={entities} />

        <ManagedPortfolioSelectField name={oProps<IBankAccountModel>().path("portfolioId")}
            onSelected={bankAccountAddPortfolioInDictionary} />
        <ManagedPortfolioSummary name={oProps<IBankAccountModel>().path("portfolioId")} label="Portfolio"
            portfolios={portfolios} currencies={referenceCurrencies} />
    </FieldBox>
}
