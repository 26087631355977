import produce from "immer";
import {
    PortfolioModel, IHistoricalValuesModel, IPortfolioTypeModel, PortfolioSummaryModel, IGetPortfolioModel, SecuritySummaryModel, IGetPositionsModel,
    IGetPortfoliosModel, ISubFundSummaryModel, IMacroScriptCheckResultModel, IIndexSummaryModel, IPositionModel, EntitySummaryModel, IManagedPortfoliosSearchParameters,
    IPortfolioExtraDataModel, BenchmarkPositionModel, IGetBenchmarkPositionsModel, CustomScreenSummaryModel, CustomScreenModel, ICustomScreenDataModel,
    IGetCustomScreensDataModel, IManagedPortfoliosSubmitCustomScreenDataParameters, IMonitoringResultsetModel, IMacroScriptsGetMonitoringResultForTargetParameters, IRelatedFileModel, IManagedPortfoliosGetFileContentParameters, RelationshipSummaryModel, ProcessExecutionSummaryModel, IGetProcessExecutionsModel
} from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IGetEntitySummary, IGetSecuritySummary } from "../../reducers/Reference";
import { toDictionary } from "tools/lib/utility";
import { IRelatedFileWithContent } from "../../reducers/IRelatedFileWithContent";
import { IMacroScriptMetadata } from "features/MacroScript/slice";
export type VisualizationType = "GRID" | "CHART";
export interface IState {
    allLoading: boolean;
    loading: boolean;
    all: (PortfolioSummaryModel | PortfolioModel)[];
    current?: PortfolioModel;
    historicalValues?: IHistoricalValuesModel[];
    historicalValuesLoading: boolean;
    saving: boolean;
    deleting: boolean;
    pricingDates: Date[];
    pricingDatesLoading: boolean;
    pricingDateLoading: boolean;
    currentPricingDate?: Date;
    // portfolioPricingDate?: Date;
    composition?: IPositionModel[];
    benchmarkComposition?: BenchmarkPositionModel[];
    onlyActive: boolean;
    extraData?: Record<number, IPortfolioExtraDataModel>;
    // portfolioComposition?: IGetPositionsModel;
    // portfolioAnalyticsLoading: boolean;
    // portfolioCompositionLoading: boolean;
    analyticsVisualizationType: VisualizationType;
    historicalValuesVisualizationType: VisualizationType;
    compositionVisualizationType: VisualizationType;
    compliancePolicyMacroScriptCheckResult?: IMacroScriptCheckResultModel;
    compliancePolicyValidateScriptMetadata: IMacroScriptMetadata;
    dealingPolicyMacroScriptCheckResult?: IMacroScriptCheckResultModel;
    dealingPolicyValidateScriptMetadata: IMacroScriptMetadata;
    dictionaries: {
        // benchmarks: Record<string | number, string>;
        entities: Record<number, EntitySummaryModel>;
        subFunds: Record<number, ISubFundSummaryModel>;
        securities: Record<number, SecuritySummaryModel>;
        indexes: Record<number, IIndexSummaryModel>; // new
        customScreens: Record<string | number, CustomScreenSummaryModel>;
        relationships: Record<string | number, RelationshipSummaryModel>;
    };
    customScreens?: CustomScreenModel[];
    customScreenDatas?: ICustomScreenDataModel[];
    customScreenDataSubmitting: boolean;
    customScreenDataLoading: boolean;

    monitoringResults: Record<number, IMonitoringResultsetModel | null>;

    fileContentsLoading: Record<number, boolean>;

    files?: IRelatedFileModel[];
    processExecutions?: ProcessExecutionSummaryModel[];
    processExecutionsLoading: boolean;
}
// export interface IPayload {

// }

export interface IPortfolioLoadedPayload extends IGetPortfolioModel {
    files: IRelatedFileModel[];
    complianceMacro: string | undefined;
    dealingMacro: string | undefined;
}
export interface IPortfolioSavedPayload {
    portfolio: PortfolioModel;
    files: IRelatedFileModel[];
}
export interface IPortfolioData {
    portfolio: PortfolioModel;
    // dueDiligenceFiles: Record<SecurityFileType, { [dueDiligenceTaskCode: string]: IDueDiligenceTaskFile | null }>;
    files: IRelatedFileWithContent[];
    complianceMacro: string | undefined;
    dealingMacro: string | undefined;
}

export const ActionFactories = produceActionFactories({
    portfolioLoad: (payload: number | IPortfolioTypeModel) => payload,
    portfolioLoaded: (payload: IPortfolioLoadedPayload) => payload,
    portfolioSave: (payload: IPortfolioData) => payload,
    portfolioSaved: (payload: IPortfolioSavedPayload) => payload,
    portfolioDelete: (payload: number) => payload,
    portfolioDeleted: (payload: number) => payload,
    portfolioLoadAll: (payload: IManagedPortfoliosSearchParameters) => payload,
    portfolioLoadedAll: (payload: IGetPortfoliosModel) => payload,
    portfolioLastDataLoadedAll: (payload: IPortfolioExtraDataModel[]) => payload,
    portfolioHistoricalValuesLoad: (payload: number) => payload,
    portfolioHistoricalValuesLoaded: (payload: IHistoricalValuesModel[]) => payload,
    portfolioCompositionVisualizationTypeChange: (payload: VisualizationType) => payload,
    portfolioAnalyticsVisualizationTypeChange: (payload: VisualizationType) => payload,
    portfolioHistoricalValuesVisualizationTypeChange: (payload: VisualizationType) => payload,
    portfolioAddSecurityInDictionary: (payload: IGetSecuritySummary) => payload,
    portfolioAddIndexInDictionary: (payload: IIndexSummaryModel) => payload,
    portfolioAddEntityInDictionary: (payload: IGetEntitySummary) => payload,
    portfolioPricingDatesLoad: (payload: number) => payload,
    portfolioPricingDatesLoaded: (payload: Date[]) => payload,
    portfolioPricingDateLoad: (payload: Date) => payload,
    portfolioPricingDateLoaded: (payload: Date) => payload,
    portfolioBenchmarkCompositionLoaded: (payload: IGetBenchmarkPositionsModel) => payload,
    portfolioCompositionLoaded: (payload: IGetPositionsModel) => payload,
    portfolioCompliancePolicyValidateScript: (payload: string) => payload,
    portfolioCompliancePolicyValidatedScript: (payload: IMacroScriptCheckResultModel) => payload,
    portfolioCompliancePolicyValidateMetadataLoaded: (payload: IMacroScriptMetadata) => payload,
    portfolioDealingPolicyValidateScript: (payload: string) => payload,
    portfolioDealingPolicyValidatedScript: (payload: IMacroScriptCheckResultModel) => payload,
    portfolioDealingPolicyValidateMetadataLoaded: (payload: IMacroScriptMetadata) => payload,
    portfolioCustomScreensLoaded: (payload: CustomScreenModel[]) => payload,
    portfolioCustomScreenDatasLoad: (payload: number) => payload,
    portfolioCustomScreenDatasLoaded: (payload: IGetCustomScreensDataModel) => payload,
    portfolioCustomScreenDataSubmit: (payload: IManagedPortfoliosSubmitCustomScreenDataParameters) => payload,
    portfolioCustomScreenDataSubmitted: (payload: ICustomScreenDataModel) => payload,
    portfolioPositionSave: (payload: IPositionModel) => payload,
    portfolioPositionSaved: (payload: IPositionModel) => payload,
    portfolioPositionDelete: (payload: number) => payload,
    portfolioPositionDeleted: (payload: number) => payload,

    portfolioFileContentLoad: (payload: IManagedPortfoliosGetFileContentParameters) => payload,
    portfolioFileContentLoaded: (payload: IManagedPortfoliosGetFileContentParameters) => payload,

    portfolioMonitoringResultLoad: (payload: IMacroScriptsGetMonitoringResultForTargetParameters) => payload,
    portfolioMonitoringResultLoaded: (payload: IMonitoringResultsetModel) => payload,
    portfolioProcessExecutionsLoad: (payload: number) => payload,
    portfolioProcessExecutionsLoaded: (payload: IGetProcessExecutionsModel) => payload,
});

export function reducer(
    state: IState = {
        onlyActive: true,
        loading: false,
        allLoading: false,
        saving: false,
        deleting: false,
        historicalValuesLoading: false,
        pricingDatesLoading: false,
        pricingDates: [],
        pricingDateLoading: false,
        historicalValuesVisualizationType: "GRID",
        compositionVisualizationType: "GRID",
        analyticsVisualizationType: "GRID",
        compliancePolicyValidateScriptMetadata: {},
        dealingPolicyValidateScriptMetadata: {},
        all: [],
        dictionaries: {
            entities: {},
            subFunds: {},
            securities: {},
            indexes: {},
            customScreens: {},
            relationships: {}
        },
        customScreenDataLoading: false,
        customScreenDataSubmitting: false,
        monitoringResults: {},
        fileContentsLoading: {},
        processExecutionsLoading: false
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "portfolioProcessExecutionsLoad":
                draft.processExecutionsLoading = true;
                break;
            case "portfolioProcessExecutionsLoaded":
                draft.processExecutionsLoading = false;
                draft.processExecutions = action.payload.processExecutions;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                break;

            case "portfolioPositionSaved":
                if (draft.composition) {
                    const position = draft.composition.find(i => i.id === action.payload.id);
                    if (position) {
                        Object.assign(position, action.payload);
                    }
                    else {
                        draft.composition.push(action.payload);
                    }
                }
                break;
            case "portfolioPositionDeleted":
                if (draft.composition) {
                    const position = draft.composition.find(i => i.id === action.payload);
                    if (position) {
                        const idx = draft.composition.indexOf(position);
                        draft.composition.splice(idx, 1);
                    }
                }
                break;
            case "portfolioMonitoringResultLoad":
                draft.monitoringResults[action.payload.id] = null;
                break;
            case "portfolioMonitoringResultLoaded":
                draft.monitoringResults[action.payload.monitoringMacroId] = action.payload;
                break;
            case "portfolioCustomScreensLoaded":
                draft.customScreens = action.payload;
                break;
            case "portfolioCustomScreenDatasLoad":
                draft.customScreenDataLoading = true;
                break;
            case "portfolioCustomScreenDatasLoaded":
                draft.customScreenDataLoading = false;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.customScreens = { ...draft.dictionaries.customScreens, ...action.payload.customScreens };
                draft.customScreenDatas = action.payload.customScreenDatas;
                break;
            case "portfolioCustomScreenDataSubmit":
                draft.customScreenDataSubmitting = true;
                break;
            case "portfolioCustomScreenDataSubmitted":
                if (!draft.customScreenDatas) {
                    draft.customScreenDatas = [];
                }
                const customScreenData = draft.customScreenDatas.find(i => i.customScreenId === action.payload.customScreenId);
                if (!!customScreenData) {
                    Object.assign(customScreenData, action.payload);
                }
                else {
                    draft.customScreenDatas.push(action.payload);
                }
                draft.customScreenDataSubmitting = false;
                break;
            case "portfolioCompliancePolicyValidateMetadataLoaded":
                draft.compliancePolicyValidateScriptMetadata = action.payload;
                break;
            case "portfolioCompliancePolicyValidatedScript":
                draft.compliancePolicyMacroScriptCheckResult = action.payload;
                break;
            case "portfolioDealingPolicyValidateMetadataLoaded":
                draft.dealingPolicyValidateScriptMetadata = action.payload;
                break;
            case "portfolioDealingPolicyValidatedScript":
                draft.dealingPolicyMacroScriptCheckResult = action.payload;
                break;
            case "portfolioLastDataLoadedAll":
                draft.extraData = toDictionary(action.payload, i => i.portfolioId);
                break;
            case "portfolioCompositionLoaded":
                draft.composition = action.payload.positions;
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities }
                draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds }
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities }
                break;
            case "portfolioBenchmarkCompositionLoaded":
                draft.benchmarkComposition = action.payload.positions;
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities }
                draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds }
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities }
                draft.dictionaries.indexes = { ...draft.dictionaries.indexes, ...action.payload.indexes }
                break;
            case "portfolioPricingDatesLoad":
                draft.composition = undefined;
                draft.benchmarkComposition = undefined;
                draft.pricingDatesLoading = true;
                break;
            case "portfolioPricingDatesLoaded":
                draft.pricingDatesLoading = false;
                draft.pricingDates = action.payload.sort((a, b) => (a ? a.getTime() : 0) - (b ? b.getTime() : 0));
                break;
            case "portfolioPricingDateLoaded":
                draft.currentPricingDate = action.payload;
                draft.pricingDateLoading = false;
                break;
            case "portfolioFileContentLoad":
                draft.fileContentsLoading[action.payload.fileId] = true;
                break;
            case "portfolioFileContentLoaded":
                delete draft.fileContentsLoading[action.payload.fileId];
                break;
            case "portfolioPricingDateLoad":
                draft.pricingDateLoading = true;
                break;
            case "portfolioLoadAll":
                draft.allLoading = true;
                draft.onlyActive = action.payload.onlyActive ?? true;
                break;
            case "portfolioLoadedAll":
                draft.allLoading = false;
                draft.all = action.payload.portfolios;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                break;
            case "portfolioLoad":
                draft.loading = true;
                draft.current = undefined;
                draft.customScreens = undefined;
                draft.customScreenDatas = undefined;
                draft.compliancePolicyMacroScriptCheckResult = undefined;
                draft.dealingPolicyMacroScriptCheckResult = undefined;
                draft.pricingDates = [];
                draft.composition = [];
                draft.dictionaries = {
                    entities: {},
                    subFunds: {},
                    securities: {},
                    indexes: {},
                    customScreens: {},
                    relationships: {}
                };
                draft.monitoringResults = {};
                draft.files = undefined;
                break;
            case "portfolioLoaded":
                draft.loading = false;
                draft.current = action.payload.portfolio;
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
                draft.dictionaries.indexes = { ...draft.dictionaries.indexes, ...action.payload.indexes };
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.files = action.payload.files;
                break;
            case "portfolioSave":
                draft.saving = true;
                break;
            case "portfolioSaved":
                draft.saving = false;
                const saved = action.payload;
                draft.current = saved.portfolio;
                const existing = draft.all.find(i => i.id === saved.portfolio.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.all.push(saved.portfolio);
                }
                draft.files = saved.files;
                break;
            case "portfolioDelete":
                draft.deleting = true;
                break;
            case "portfolioDeleted":
                draft.deleting = false;
                const deletedId = action.payload;
                if (draft.current && draft.current.id === deletedId) {
                    delete draft.current;
                }
                const idx = draft.all.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.all.splice(idx, 1);
                }
                break;
            case "portfolioHistoricalValuesVisualizationTypeChange":
                draft.historicalValuesVisualizationType = action.payload;
                break;
            case "portfolioCompositionVisualizationTypeChange":
                draft.compositionVisualizationType = action.payload;
                break;
            case "portfolioAnalyticsVisualizationTypeChange":
                draft.analyticsVisualizationType = action.payload;
                break;
            case "portfolioHistoricalValuesLoaded":
                draft.historicalValues = action.payload;
                draft.historicalValuesLoading = false;
                break;
            case "portfolioHistoricalValuesLoad":
                draft.historicalValues = undefined;
                draft.historicalValuesLoading = true;
                break;
            case "portfolioAddEntityInDictionary":
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.entities[action.payload.entity.id] = action.payload.entity;
                break;
            case "portfolioAddSecurityInDictionary":
                draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds };
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.securities[action.payload.security.id] = action.payload.security;
                break;
            case "portfolioAddIndexInDictionary":
                draft.dictionaries.indexes[action.payload.id] = action.payload;
                break;
        }
    });
}
