import * as React from "react";
import { DevelopmentItemModel, IExecuteModel, IFileTypeModel } from "proxy/apiProxy";
import { useReduxSelections, useReduxActions } from "tools/lib/reduxStoreAccess";
import { Switch, SwitchCase } from "../Switch";
import { DevelopmentItemParametersDialog } from "./DevelopmentItemParametersDialog";
import DialogPanel from "tools/components/DialogPanel";
import SurveyRunnerDialog from "tools/components/SurveyRunnerComponent";
import { IDevelopmentItemRefs } from "../DevelopmentItemForm";
import { Grid } from "@material-ui/core";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import { oProps } from "tools/lib/utility";
import ReactJson from "react-json-view";
import { DashboardRunner } from "../Designers/Dashboard/DashboardRunner";

export interface IDevelopmentItemExecutionProps {
    execution: DevelopmentItemModel["type"] | "ReportPreview" | undefined;
    onCloseExecution: () => void;
    refs: IDevelopmentItemRefs;
}
enum ExecutionState {
    FillParameters,
    FillQuestionnaire,
    ShowQuestionnaireResult,
    ShowDashboardResult,
    ShowEtlResult
}
export function DevelopmentItemExecution({ execution, onCloseExecution, refs: { questionnaireLoadMacroRef, reportLoadMacroRef, questionnaireTemplateRef, questionnaireCompleteMacroRef, reportTemplateRef, dashboardMacroRef } }: IDevelopmentItemExecutionProps) {
    const { developmentItemQuestionnaireExecuteOnLoad, developmentItemReportExecuteOnLoad, developmentItemReportGenerate, developmentItemQuestionnaireExecuteOnComplete, developmentItemDashboardExecuteOnLoad } = useReduxActions("developmentItem");
    const { developmentItemStates } = useReduxSelections("developmentItem");
    const [executionState, setExecutionState] = React.useState(ExecutionState.FillParameters);
    const [questionnaireTemplate, setQuestionnaireTemplate] = React.useState<string>();
    React.useEffect(() => {
        if (!!execution) {
            setExecutionState(ExecutionState.FillParameters);
        }
    }, [execution]);
    const [executionParameters, setExecutionParameters] = React.useState<IExecuteModel>({});
    const handleQuestionnaireCompleted = React.useCallback((response: any) => {
        const v = questionnaireCompleteMacroRef.current.getValue();
        if (executionParameters && v) {
            developmentItemQuestionnaireExecuteOnComplete({ type: "ExecuteQuestionnaireOnCompleteModel", response, ...v, ...executionParameters });
            setExecutionState(ExecutionState.ShowQuestionnaireResult);
        }
    }, [developmentItemQuestionnaireExecuteOnComplete, executionParameters, questionnaireCompleteMacroRef]);
    const handleValidatedParameters = React.useCallback((executeModel: IExecuteModel) => {
        switch (execution) {
            case "QuestionnaireDevelopmentItemModel": {
                const loadMacro = questionnaireLoadMacroRef.current.getValue();
                if (loadMacro) {
                    developmentItemQuestionnaireExecuteOnLoad({ ...executeModel, type: "ExecuteOnLoadModel", ...loadMacro });
                    setQuestionnaireTemplate(questionnaireTemplateRef.current.getValue() ?? "{}");
                    setExecutionState(ExecutionState.FillQuestionnaire);
                }
                break;
            }
            case "DashboardMacroDevelopmentItemModel": {
                const loadMacro = dashboardMacroRef.current.getValue();
                if (loadMacro) {
                    developmentItemDashboardExecuteOnLoad({ ...executeModel, type: "ExecuteOnLoadModel", ...loadMacro });
                    setExecutionState(ExecutionState.ShowDashboardResult);
                }
                break;
            }
            case "ReportTemplateDevelopmentItemModel": {
                const loadMacro = reportLoadMacroRef.current.getValue();
                const template = reportTemplateRef.current.getValue() ?? "{}";
                const exportType = (executeModel as IReportGenerationParameters).exportType ?? IFileTypeModel.Pdf;
                if (loadMacro) {
                    developmentItemReportGenerate({ ...executeModel, template, exportType, type: "ExecuteReportModel", ...loadMacro });
                }
                onCloseExecution();
                break;
            }
            case "ReportPreview": {
                const loadMacro = reportLoadMacroRef.current.getValue();
                if (loadMacro) {
                    developmentItemReportExecuteOnLoad({ ...executeModel, type: "ExecuteOnLoadModel", ...loadMacro });
                }
                onCloseExecution();
                break;
            }
            case "EtlMacroDevelopmentItemModel":
                break;
        }
        setExecutionParameters(executeModel);
    }, [dashboardMacroRef, developmentItemDashboardExecuteOnLoad, developmentItemQuestionnaireExecuteOnLoad, developmentItemReportExecuteOnLoad, developmentItemReportGenerate, execution, onCloseExecution, questionnaireLoadMacroRef, questionnaireTemplateRef, reportLoadMacroRef, reportTemplateRef]);
    if (!execution) {
        return null;
    }
    return <Switch type={executionState}>
        <SwitchCase type={ExecutionState.FillParameters}>
            <DevelopmentItemParametersDialog initialParameters={executionParameters} onCancel={onCloseExecution} onValidated={handleValidatedParameters} >
                {execution === "ReportTemplateDevelopmentItemModel" && <Grid item xs={12}>
                    <FormSimpleSelectField name={oProps<IReportGenerationParameters>().path("exportType")} label="Export type" options={IFileTypeModel} required />
                </Grid>}
            </DevelopmentItemParametersDialog>
        </SwitchCase>
        <SwitchCase type={ExecutionState.FillQuestionnaire}>
            <SurveyRunnerDialog onCancel={onCloseExecution} template={questionnaireTemplate} initialValues={developmentItemStates.questionnaireLoadedAnswers} onComplete={handleQuestionnaireCompleted} />
        </SwitchCase>
        <SwitchCase type={ExecutionState.ShowQuestionnaireResult}>
            <DialogPanel onBackClick={onCloseExecution} maxWidth="lg">
                <ReactJson
                    style={{ width: 600 }}
                    name={false}
                    collapsed={1}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    src={developmentItemStates.questionnaireLoadedResult ?? {}} />
                <DashboardRunner value={developmentItemStates.questionnaireLoadedResult?.result ?? {}} />
            </DialogPanel>
        </SwitchCase>
        <SwitchCase type={ExecutionState.ShowDashboardResult}>
            <DialogPanel onBackClick={onCloseExecution} maxWidth="lg">
                <ReactJson
                    style={{ width: 600 }}
                    name={false}
                    collapsed={1}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    src={developmentItemStates.dashboardLoadedResult ?? {}} />
                <DashboardRunner value={developmentItemStates.dashboardLoadedResult?.result ?? {}} />
            </DialogPanel>
        </SwitchCase>
    </Switch>;
}
interface IReportGenerationParameters extends IExecuteModel {
    exportType?: IFileTypeModel;
}

