import { Epic } from "redux-observable";
/* import { merge } from "rxjs"; */
import { /* filter, share, */ map, mergeMap } from "rxjs/operators";
import { statisticsSetApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, changedNavigation } from "lib/rxJsUtility";
import { tryParseNumber } from "tools/lib/utility";
import { filterRoute } from "tools/lib/UrlDictionary";
export const loadValueAtRisks: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("valueAtRisk", "valueAtRiskLoadAll"),
        mergeMap(() => statisticsSetApi.getLastStatisticsPortfolioLevelAsync()),
        map(ActionFactories.valueAtRisk.valueAtRiskLoadedAll));
export const loadValueAtRiskChildren: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("valueAtRisk", "valueAtRiskLoadChildren"),
        mergeMap(portfolioId => statisticsSetApi.getLastStatisticsConstituentLevelAsync(({ portfolioId }))),
        map(ActionFactories.valueAtRisk.valueAtRiskLoadedChildren));
export const loadValueAtRiskBacktesting: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("valueAtRisk", "valueAtRiskLoadBacktesting"),
        mergeMap(portfolioId => statisticsSetApi.getStatisticsBacktestingAsync({ portfolioId })),
        map(ActionFactories.valueAtRisk.valueAtRiskLoadedBacktesting));

export const onOpenScreenValueAtRisk: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("ValueAtRiskScreen"),
        map(() => ActionFactories.valueAtRisk.valueAtRiskLoadAll()));

export const onOpenScreenValueAtRiskBacktesting: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("ValueAtRiskScreen", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? 0),
        map(portfolioId => ActionFactories.valueAtRisk.valueAtRiskLoadBacktesting(portfolioId as number)));