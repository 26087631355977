import { IMacroTypeModel, IEntityMetadataModel, IMacroModel, DevelopmentItemModel } from "proxy/apiProxy";
import { DevelopmentItemDiagnostics, ITypedMacro } from "../slice";
import FormMacroEditor from "../Designers/FormMacroEditor";
import { EditorReferencePayload } from "../Designers/INotControllableEditorProps";

export interface IDashboardDevelopmentItemRefs {
    dashboardMacroRef: React.MutableRefObject<EditorReferencePayload<IMacroModel>>;
}
interface IDashboardMacroDevelopmentItemEditorProps extends IDashboardDevelopmentItemRefs {
    type: DevelopmentItemModel["type"];
    tabValue: string | undefined;
    metadata: Record<string | number, IEntityMetadataModel>;
    diagnostics?: DevelopmentItemDiagnostics;
    onCodeChanged: (macro: ITypedMacro) => void;
}
export default function DashboardMacroDevelopmentItemEditor({ tabValue, onCodeChanged, dashboardMacroRef, metadata, diagnostics }: IDashboardMacroDevelopmentItemEditorProps) {
    switch (tabValue) {
        case "macro": return <FormMacroEditor
            type={IMacroTypeModel.Dashboard}
            onCodeChanged={onCodeChanged}
            executing={false}
            refToGetValue={dashboardMacroRef}
            diagnostics={diagnostics && diagnostics[IMacroTypeModel.Dashboard]}
            metadata={metadata}
            error={undefined} />;
    }
    return null;
}
