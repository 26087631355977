import * as React from "react";
import { useField } from "formik";
import { IMacroTypeModel, IEntityMetadataModel, MacroSymbolModel, IReportTemplateDevelopmentItemModel, IMacroModel, DevelopmentItemModel } from "proxy/apiProxy";
import { oProps } from "tools/lib/utility";
import { DevelopmentItemDiagnostics, ITypedMacro } from "../slice";
import FormMacroEditor from "../Designers/FormMacroEditor";
import ReportDesigner from "../Designers/ReportDesigner";
import { EditorReferencePayload } from "../Designers/INotControllableEditorProps";

export interface IReportTemplateDevelopmentItemRefs {
    reportTemplateRef: React.MutableRefObject<EditorReferencePayload<string>>;
    reportLoadMacroRef: React.MutableRefObject<EditorReferencePayload<IMacroModel>>;
}
export interface IReportTemplateDevelopmentItemEditorProps extends IReportTemplateDevelopmentItemRefs {
    type: DevelopmentItemModel["type"];
    tabValue: string | undefined;
    metadata: Record<string | number, IEntityMetadataModel>;
    diagnostics?: DevelopmentItemDiagnostics;
    onCodeChanged: (macro: ITypedMacro) => void;
    onPreviewDataRequest: () => void;
    previewData: any | undefined
}
export default function ReportTemplateDevelopmentItemEditor({ tabValue, onCodeChanged, reportLoadMacroRef, onPreviewDataRequest, reportTemplateRef, metadata, diagnostics, previewData }: IReportTemplateDevelopmentItemEditorProps) {
    const [{ value: id }] = useField(oProps<IReportTemplateDevelopmentItemModel>().path("id"));
    const dictionary: MacroSymbolModel = React.useMemo(() => (diagnostics?.ReportOnLoad?.returnType) ?? { type: "ObjectMacroSymbolModel", properties: {}, nullable: false }, [diagnostics?.ReportOnLoad?.returnType]);
    switch (tabValue) {
        case "loadMacro": return <FormMacroEditor key={`loadMacro${id}`}
            type={IMacroTypeModel.ReportOnLoad}
            onCodeChanged={onCodeChanged}
            // field={oProps<ReportTemplateDevelopmentItem>().path("onLoadMacro")}
            refToGetValue={reportLoadMacroRef}
            executing={false}
            diagnostics={diagnostics && diagnostics[IMacroTypeModel.ReportOnLoad]}
            metadata={metadata}
            error={undefined} />;
        case "template": return <ReportDesigner key={`template${id}`}
            onPreviewDataRequest={onPreviewDataRequest}
            previewData={previewData}
            dictionary={dictionary}
            refToGetValue={reportTemplateRef} />;
    }
    return null;
}
