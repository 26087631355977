import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, share, mergeMap, tap } from "rxjs/operators";
import { cashMovementsApi, IGetCashMovementModel } from "proxy/apiProxy";
import { ActionFactories, IAnyAction, IState } from "reducers";
import { changedNavigation, mapToPayload } from "lib/rxJsUtility";
import { filterRoute } from "tools/lib/UrlDictionary";
import { tryParseNumber } from "tools/lib/utility";
import saveAs from "file-saver";

export const loadCashMovements: Epic<IAnyAction, IAnyAction, IState>
    = action$ => action$.pipe(
        mapToPayload("cashMovement", "cashMovementsSearch"),
        mergeMap(cashMovementsApi.searchAsync),
        map(ActionFactories.cashMovement.cashMovementsLoadedAll));
export const onOpenScreenCashMovement: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("CashMovements", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? 0),
        map(cashMovementId => ActionFactories.cashMovement.cashMovementLoad(cashMovementId as number)));



// cashMovementLoadFile: (payload: number) => payload

export const getCheckFile: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("cashMovement", "cashMovementLoadFile"),
        mergeMap(id => cashMovementsApi.getFileAsync({ id })),
        tap(({ blob, fileName }) => saveAs(blob, fileName)),
        map(() => ActionFactories.app.dummy()));

export const loadCashMovement: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("cashMovement", "cashMovementLoad"),
            share()
        );
        return merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => cashMovementsApi.getAsync({ id })),
                map(ActionFactories.cashMovement.cashMovementLoaded)),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({
                    cashMovement: {
                        classifications: {}
                    },
                    entities: {},
                    portfolios: {},
                    securities: {}
                } as IGetCashMovementModel)),
                map(ActionFactories.cashMovement.cashMovementLoaded)));
    };
export const saveCashMovement: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("cashMovement", "cashMovementSave"),
        mergeMap(async ({ cashMovement, file: imageFile }) => {
            const savedCashMovement = await cashMovementsApi.saveAsync({ model: cashMovement });
            if (imageFile) {
                const { content, mimeType, fileName } = imageFile;
                if (content) {
                    await cashMovementsApi.saveFileAsync({
                        id: savedCashMovement.id,
                        fileModel: {data: content, mimeType, name: fileName}
                    });
                } else {
                    // FIXME Properly handle empty files with a warning and not brutally reject them
                    console.error(`File ${fileName} has no content. Not saving it.`);
                }
                return { ...savedCashMovement, fileName: imageFile.fileName };
            }
            else if (imageFile === null) {
                await cashMovementsApi.deleteFileAsync({ id: savedCashMovement.id });
                return { ...savedCashMovement, fileName: undefined };
            }
            else {
                return savedCashMovement;
            }
        }),
        map(ActionFactories.cashMovement.cashMovementSaved));
export const deleteCashMovement: Epic<IAnyAction>
    = (action$) => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("cashMovement", "cashMovementDelete"),
            mergeMap(id => cashMovementsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.cashMovement.cashMovementDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };
