import { Epic } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import { dailyDataApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload } from "lib/rxJsUtility";

export const loadPositions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("position", "positionSearch"),
        mergeMap(dailyDataApi.searchPositionsAsync),
        map(ActionFactories.position.positionLoaded));
