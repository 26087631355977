import DetailPanel
    // , { ISubMenu } 
    from "tools/components/DetailPanel";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import ProcessExecutionData from "./ProcessExecutionData";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { IProcessExecutionSavePayload } from "./slice";
import { useCallback, useMemo } from "react";
import { IActionButton } from "tools/components/FabContainer";
import { oProps } from "tools/lib/utility";
import CertificateOutlineIcon from "mdi-material-ui/CertificateOutline";
import AutorenewIcon from '@material-ui/icons/Autorenew';

export default function ProcessExecution() {
    // const { navigationActiveScreen: navigationActiveUrlNode } = useReduxSelections("navigation");
    const { currentUser } = useReduxSelections("app");
    const { navigationNavigate } = useReduxActions("navigation");
    const { processExecutionDelete, processExecutionSave, processExecutionLoadFile, processExecutionLoadedRelationship, processExecutionLoadedEntity, processExecutionLoadQuestionnaire, processExecutionReloadQuestionnaire } = useReduxActions("processExecution");
    const { processExecutionCurrent, processExecutionLoading, dictionaries, processTaskState, processExecutionSaving, processExecutionQuestionnaires, processExecutionQuestionnaireDetail } = useReduxSelections("processExecution");
    const { classificationTypes } = useReduxSelections("processClassificationType");
    const handleBack = () => navigationNavigate(undefined);
    const title = "Process execution";
    // const tabValue = navigationActiveUrlNode?.activeSectionKey;

    // const tabs: ISubMenu[] = [{
    //     label: "Data",
    //     value: "detail"
    // // }, {
    // //     label: "Tasks",
    // //     value: "tasks"
    // }];

    const handleDelete = useCallback(() => {
        if (processExecutionCurrent?.id) {
            processExecutionDelete(processExecutionCurrent?.id);
        }
    }, [processExecutionCurrent?.id, processExecutionDelete]);
    const handleTabValueChanged = useCallback((value: any) => navigationNavigate({ sectionKey: value as string }), [navigationNavigate]);

    const handleSubmit = useCallback((values: IProcessExecutionSavePayload, { setSubmitting }: FormikHelpers<IProcessExecutionSavePayload>) => {
        processExecutionSave(values);
        setSubmitting(false);
    }, [processExecutionSave])
    const formValues: IProcessExecutionSavePayload | undefined = useMemo(() => {
        if (!processExecutionCurrent) {
            return undefined;
        }
        const ret: IProcessExecutionSavePayload = {
            execution: processExecutionCurrent,
            files: {},
            questionnaires: {}
        };
        return ret;
    }, [processExecutionCurrent]);
    const handleRequestFileLoad = useCallback((taskCode: string) => {
        if (formValues?.execution) {
            processExecutionLoadFile({ id: formValues?.execution.id, taskCode, type: formValues?.execution.type });
        }
    }, [formValues?.execution, processExecutionLoadFile]);
    const handleNewExecutionProcess = useCallback(() => {
        navigationNavigate({ screenKey: "ProcessExecutions", sectionKey: "detail", parameters: { type: formValues?.execution.id, targetId: "renew" } });
    }, [formValues?.execution.id, navigationNavigate])

    if (!formValues) {
        return <DetailPanelLoading onBackClick={handleBack} tabNumber={2} hasSubTitle={false} />;
    }

    return <Formik onSubmit={handleSubmit} initialValues={formValues} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm, values, setFieldValue }: FormikProps<IProcessExecutionSavePayload>) {

        const handleApprove = () => {
            if (!currentUser) {
                return;
            }
            processExecutionLoadedEntity({ entities: {}, entity: { type: "PersonSummaryModel", internalCode: "", firstName: "", lastName: "", classifications: {}, entityExtensionFieldsValues: {}, ...currentUser } })
            setFieldValue(
                oProps<IProcessExecutionSavePayload>().path("execution", "approvedById"),
                values?.execution?.approvedById ? undefined : currentUser.id, true);
        }

        const extraActionButtons: IActionButton[] = [{
            label: "Change approval status",
            onClick: handleApprove,
            icon: CertificateOutlineIcon,
        }, {
            label: "Renew",
            onClick: handleNewExecutionProcess,
            icon: AutorenewIcon,
            disabled: !values?.execution.id || dirty
        }];

        if (!processExecutionCurrent) {
            return null;
        }

        return <WriteAccess value={true}><DetailPanel
            isQuerying={processExecutionLoading || processExecutionSaving}
            title={title}
            canSave={(dirty || !values.execution.id) && isValid}
            onSaveClick={submitForm}
            actions={extraActionButtons}
            canDelete={!!processExecutionCurrent.id}
            onDeleteClick={handleDelete}
            onBackClick={handleBack}
            badge={!processExecutionCurrent.id ? "new" : undefined}
            // saveAllowed={IFeatureModel.ProcessExecutionWrite}
            // deleteAllowed={IFeatureModel.ProcessExecutionWrite}
            // tabs={tabs}
            // tabValue={tabValue}
            onTabValueChanged={handleTabValueChanged}>
            <ProcessExecutionData
                classificationTypes={classificationTypes}
                dictionaries={dictionaries}
                taskLoading={processTaskState.loading}
                taskSaving={processTaskState.saving}
                processExecution={values.execution}
                processFiles={values.files}
                onRequestFileLoad={handleRequestFileLoad}
                onRelationshipLoaded={processExecutionLoadedRelationship}
                questionnaires={processExecutionQuestionnaires}
                onLoadQuestionnaire={processExecutionLoadQuestionnaire}
                onReloadQuestionnaire={processExecutionReloadQuestionnaire}
                questionnaireContents={processExecutionQuestionnaireDetail} />
            {/* {(tabValue === "detail") && <ProcessExecutionData
                classificationTypes={classificationTypes}
                dictionaries={dictionaries}
                fileLoading={processFileState.fileLoading}
                fileSaving={processFileState.fileSaving}
                processExecution={values.execution}
                processFiles={values.files}
                onRequestFileLoad={handleRequestFileLoad}
                onRelationshipLoaded={processExecutionLoadedRelationship} />} */}
            {/* {(tabValue === "tasks") && <ProcessExecutionTasksData questionnaires={processExecutionQuestionnaires} />} */}
        </DetailPanel></WriteAccess>
    }
}
