import { Epic } from "redux-observable";
import { merge, from, Observable } from "rxjs";
import { filter, map, share, mergeMap } from "rxjs/operators";
import { CustomScreenSummaryModel, customScreensApi, macroScriptsApi, counterpartyRelationshipsApi, investorRelationshipsApi, roleRelationshipsApi, ICollaborationTypeModel, IRoleDomainModel, IRolePositionModel } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, changedNavigation } from "lib/rxJsUtility";
import { tryParseNumber, today } from "tools/lib/utility";
import { IActiveScreen, ISectionKey, filterRoute } from "tools/lib/UrlDictionary";
import { IRoleRelationshipModel, RelationshipModel, ICounterpartyRelationshipModel, IInvestorRelationshipModel, IFrequencyTypeModel, IGetRelationshipModel } from "proxy/apiProxy";

import { getRelationshipApi } from "./getApi";

export const onOpenScreenRelationships: Epic<IAnyAction>
    = action$ => {
        const changedScreen$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey),
            share());
        const getRoleRelationshipsRequest$ = changedScreen$.pipe(
            filterRoute("ServiceProvidersCollaborators"),
            // ifGrantedAsync(IFeatureModel.RoleRelationshipRead),
            map(() => "RoleRelationshipModel" as RelationshipModel["type"]));
        const getCounterpartyRelationshipsRequest$ = changedScreen$.pipe(
            filterRoute("Counterparties"),
            // ifGrantedAsync(IFeatureModel.CounterpartyRelationshipRead),
            map(() => "CounterpartyRelationshipModel" as RelationshipModel["type"]));
        const getInvestorRelationshipsRequest$ = changedScreen$.pipe(
            filterRoute("Investors"),
            // ifGrantedAsync(IFeatureModel.InvestorRelationshipRead),
            map(() => "InvestorRelationshipModel" as RelationshipModel["type"]));
        return merge(
            getRoleRelationshipsRequest$,
            getCounterpartyRelationshipsRequest$,
            getInvestorRelationshipsRequest$
        ).pipe(
            map(ActionFactories.relationship.relationshipLoadAll));
    }
export const onOpenScreenProcessExecutions: Epic<IAnyAction>
    = action$ => {
        const route$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.processes?.id),
            share());

        return readRelationshipRoute(route$, "processes")
            .pipe(map(ActionFactories.relationship.relationshipProcessExecutionsLoad));
    }

export const loadProcessExecutions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipProcessExecutionsLoad"),
        mergeMap(({ id, type }) => getRelationshipApi(type).getProcessesAsync({ id })),
        map(ActionFactories.relationship.relationshipProcessExecutionsLoaded));


export const submitCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipCustomScreenDataSubmit"),
        mergeMap(({ type, ...payload }) => getRelationshipApi(type).submitCustomScreenDataAsync(payload)),
        map(ActionFactories.relationship.relationshipCustomScreenDataSubmitted));
export const loadRelationships: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipLoadAll"),
        mergeMap(type => getRelationshipApi(type).getAllAsync({})),
        map(ActionFactories.relationship.relationshipLoadedAll));
export const loadCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipCustomScreenDatasLoad"),
        mergeMap(({ type, id }) => getRelationshipApi(type).getLastCustomScreenDatasAsync({ id })),
        map(ActionFactories.relationship.relationshipCustomScreenDatasLoaded));
export const onOpenScreenRelationship: Epic<IAnyAction>
    = action$ => {
        const route$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
            share());

        const relationshipToLoad$ = readRelationshipRoute(route$, "detail");
        return merge(
            relationshipToLoad$.pipe(map(ActionFactories.relationship.relationshipLoad)),
            relationshipToLoad$.pipe(map(() => ActionFactories.macroScript.macroScriptLoadAll())));
    }

function readRelationshipRoute<SC extends (ISectionKey<"Counterparties"> & ISectionKey<"ServiceProvidersCollaborators"> & ISectionKey<"Investors">)>(route$: Observable<IActiveScreen>, subScreen: SC) {
    const roleRelationshipRoute$ = route$.pipe(
        filterRoute("ServiceProvidersCollaborators", subScreen),
        map(({ matchingSections }) => ({
            type: "RoleRelationshipModel" as RelationshipModel["type"],
            id: tryParseNumber(matchingSections?.detail?.id) ?? 0
        })));
    const counterpartyRelationshipRoute$ = route$.pipe(
        filterRoute("Counterparties", subScreen),
        map(({ matchingSections }) => ({
            type: "CounterpartyRelationshipModel" as RelationshipModel["type"],
            id: tryParseNumber(matchingSections?.detail?.id) ?? 0
        })));
    const investorRelationshipRoute$ = route$.pipe(
        filterRoute("Investors", subScreen),
        map(({ matchingSections }) => ({
            type: "InvestorRelationshipModel" as RelationshipModel["type"],
            id: tryParseNumber(matchingSections?.detail?.id) ?? 0
        })));
    return merge(
        roleRelationshipRoute$,
        counterpartyRelationshipRoute$,
        investorRelationshipRoute$
    ).pipe(share());
}

function createEmptyRelationship(type: RelationshipModel["type"]): RelationshipModel {
    switch (type) {
        case "RoleRelationshipModel": return {
            type: "RoleRelationshipModel",
            id: 0,
            permissions: [],
            portfolioIds: [],
            sicavIds: [],
            entityId: 0,
            startDate: today(),
            investorIds: [],
            fullScope: true,
            collaborationType: ICollaborationTypeModel.Collaborator,
            domain: IRoleDomainModel.Accounting,
            classifications: {},
            position: IRolePositionModel.Employee
        } as IRoleRelationshipModel;
        case "CounterpartyRelationshipModel": return {
            type: "CounterpartyRelationshipModel",
            id: 0,
            portfolioIds: [],
            sicavIds: [],
            entityId: 0,
            startDate: today(),
            processIds: [],
            investorIds: [],
            isAuthorized: false,
            fullScope: true
        } as ICounterpartyRelationshipModel;
        case "InvestorRelationshipModel": return {
            type: "InvestorRelationshipModel",
            id: 0,
            portfolioIds: [],
            sicavIds: [],
            entityId: 0,
            startDate: today(),
            processIds: [],
            classifications: {},
            investorIds: [],
            statementFrequency: IFrequencyTypeModel.Monthly,
            fullScope: true,
            investorOperations: [],
            inDefault: false
        } as IInvestorRelationshipModel;
    }
}
export const loadRelationship: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("relationship", "relationshipLoad"),
            share());

        const relationship$ = merge(
            requestedId$.pipe(
                filter(({ id }) => !!id),
                mergeMap(({ type, id }) => getRelationshipApi(type).getAsync({ id }))),
            requestedId$.pipe(
                filter(({ id }) => !id),
                map(({ type }) => ({ relationship: createEmptyRelationship(type), entities: {}, relationships: {}, portfolios: {} } as IGetRelationshipModel)))
        ).pipe(share());

        // const requestDueDilId$ = relationship$.pipe(
        //     map(({ relationship: { type, id } }) => ({ id, type })),
        //     share());

        const customScreens$ = relationship$.pipe(
            mergeMap(async loadedRelationship => {
                const screenType = (function (): CustomScreenSummaryModel["type"] {
                    switch (loadedRelationship.relationship.type) {
                        case "CounterpartyRelationshipModel": return "CounterpartyCustomScreenSummaryModel";
                        case "InvestorRelationshipModel": return "InvestorCustomScreenSummaryModel";
                        case "RoleRelationshipModel": return "RoleCustomScreenSummaryModel";
                    }
                })();
                const allCustomScreens = (await customScreensApi.getAllAsync({})).filter(customScreen => customScreen.type === screenType);
                return await Promise.all(allCustomScreens.map(({ id }) => customScreensApi.getAsync({ id })));
            }),
            map(ActionFactories.relationship.relationshipCustomScreensLoaded));

        // const ddResponse$ = requestDueDilId$.pipe(
        //     filter(({ id }) => !!id),
        //     mergeMap(({ type, id }) => getRelationshipApi(type).getDueDiligenceAsync({ id }).catch(() => undefined)));

        // const dd$ = merge(
        //     ddResponse$.pipe(
        //         map(ActionFactories.relationship.relationshipDueDiligenceLoaded)),
        //     requestedId$.pipe(
        //         filter(i => typeof i !== "number"),
        //         map(() => ActionFactories.relationship.relationshipDueDiligenceLoaded(undefined))));


        return merge(
            relationship$.pipe(map(ActionFactories.relationship.relationshipLoaded)),
            requestedId$.pipe(map(() => ActionFactories.parameters.parametersLoad())),
            requestedId$.pipe(
                filter(id => !!id),
                map(ActionFactories.relationship.relationshipCustomScreenDatasLoad)),
            customScreens$,
            // dd$,
            // relationship$.pipe(
            //     map(({ relationship: { processIds, type } }) => ({ processIds, type })),
            //     filter(i => !!i.processIds.length),
            //     map(({ type, processIds: ids }) => ActionFactories.relationship.relationshipLoadProcesses({ ids, type })))
        );
    };

export const getMonitoringResultLoad: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipMonitoringResultLoad"),
        mergeMap(macroScriptsApi.getMonitoringResultForTargetAsync),
        map(ActionFactories.relationship.relationshipMonitoringResultLoaded));
// export const getFile: Epic<IAnyAction>
//     = action$ => action$.pipe(
//         mapToPayload("relationship", "relationshipLoadFile"),
//         mergeMap(({ type, ...i }) => getRelationshipApi(type).getDueDiligenceFileAsync(i).then(file => ({ file, payload: i }))),
//         tap(({ file: { blob, fileName } }) => saveAs(blob, fileName)),
//         map(({ payload }) => ActionFactories.relationship.relationshipLoadedFile(payload)));

// function saveRelationship(
//     savedRelationship: RelationshipModel,
//     // dueDiligence: IDueDiligence | undefined,
//     // previousDueDiligence: IGetDueDiligenceExecutionModel | undefined
//     ) {
//     const { id: relationshipId, type } = savedRelationship;
//     const merges$: Observable<IAnyAction>[] = [
//         of(savedRelationship).pipe(map(ActionFactories.relationship.relationshipSaved)),
//         of({ id: relationshipId, type }).pipe(map(ActionFactories.relationship.relationshipCustomScreenDatasLoad))
//     ];
//     const relationshipsApi = getRelationshipApi(savedRelationship.type);
//     const toWait$: Observable<any>[] = [];

//     if (previousDueDiligence && !dueDiligence?.execution) {
//         merges$.push(of(relationshipId).pipe(
//             mergeMap(id => relationshipsApi.deleteDueDiligenceAsync({ id })),
//             map(() => ActionFactories.relationship.relationshipDueDiligenceLoaded(undefined))
//         ));
//     }
//     if (!!previousDueDiligence?.dueDiligenceExecution?.approvedById !== !!dueDiligence?.execution?.approvedById) {
//         if (!!dueDiligence?.execution?.approvedById) {
//             toWait$.push(from(relationshipsApi.dueDiligenceApproveAsync({ id: relationshipId })));
//         }
//         else {
//             toWait$.push(from(relationshipsApi.dueDiligenceDisapproveAsync({ id: relationshipId })));
//         }
//     }
//     if (dueDiligence?.execution) {
//         const savedDueDilExec$ = of(dueDiligence).pipe(
//             mergeMap(i => relationshipsApi.saveDueDiligenceAsync({ id: relationshipId, model: i.execution })),
//             map(i => ({
//                 dueDiligenceExecution: i,
//                 processDefinitions: {},
//                 entities: {},
//                 relationships: {}
//             } as IGetDueDiligenceExecutionModel)),
//             share());
//         const savedFile$ = savedDueDilExec$.pipe(mergeMap(() => {
//             return submitDueDiligenceFileChanges(
//                 ActionFactories.relationship.relationshipSaveFile,
//                 ActionFactories.relationship.relationshipSavedFile,
//                 relationshipsApi.saveDueDiligenceFileAsync,
//                 relationshipsApi.deleteDueDiligenceFileAsync,
//                 dueDiligence.files,
//                 relationshipId);
//         }));
//         merges$.push(savedDueDilExec$.pipe(map(ActionFactories.relationship.relationshipDueDiligenceLoaded)));
//         merges$.push(savedFile$);
//     }
//     if (toWait$.length) {
//         return forkJoin(toWait$).pipe(mergeMap(() => merge(...merges$)));
//     }
//     else {
//         return merge(...merges$);
//     }
// }

export const saveRelationshipEpic: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipSave"),
        mergeMap(relationship => {
            switch (relationship.type) {
                case "CounterpartyRelationshipModel":
                    return from(counterpartyRelationshipsApi.saveAsync({ model: relationship }));
                case "InvestorRelationshipModel":
                    return from(investorRelationshipsApi.saveAsync({ model: relationship }));
                case "RoleRelationshipModel":
                    return from(roleRelationshipsApi.saveAsync({ model: relationship }));
            }
        }),
        map(ActionFactories.relationship.relationshipSaved));

export const deleteRoleRelationship: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("relationship", "relationshipDeleteCurrent"),
            mergeMap(({ id, type }) => getRelationshipApi(type).deleteAsync({ id }).then(() => id)),
            map(ActionFactories.relationship.relationshipDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };
