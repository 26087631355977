import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, share, mergeMap } from "rxjs/operators";
import { tenanciesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, changedNavigation } from "lib/rxJsUtility";
import { IGetTenancyModel } from "proxy/apiProxy";
import { tryParseNumber } from "tools/lib/utility";
import { filterRoute } from "tools/lib/UrlDictionary";
export const loadTenancies: Epic<IAnyAction>
    = (action$) => action$.pipe(
        mapToPayload("tenancy", "tenancyLoadAll"),
        mergeMap(() => tenanciesApi.getAllAsync({})),
        map(ActionFactories.tenancy.tenancyLoadedAll));
export const onOpenScreenTenancy: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("Tenancies", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? 0),
        map(tenancyId => ActionFactories.tenancy.tenancyLoad(tenancyId as number)));

export const loadEntities: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("tenancy", "tenancyLoadAll"),
        mergeMap(tenanciesApi.searchAsync),
        map(ActionFactories.tenancy.tenancyLoadedAll));

// export const onOpenScreenTenancies: Epic<IAnyAction>
//     = action$ => action$.pipe(
//         changedNavigation(({ screenKey }) => screenKey),
//         filterRoute("Tenancies"),
//         map(() => ActionFactories.tenancy.tenancyLoadAll()));
export const loadTenancy: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("tenancy", "tenancyLoad"),
            share()
        );
        return merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => tenanciesApi.getAsync({ id })),
                map(ActionFactories.tenancy.tenancyLoaded)),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({
                    tenancy: { id: 0 },
                    entities: {}
                } as IGetTenancyModel)),
                map(ActionFactories.tenancy.tenancyLoaded)));
    };
export const saveTenancy: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("tenancy", "tenancySave"),
        mergeMap(model => tenanciesApi.saveAsync({ model })),
        map(ActionFactories.tenancy.tenancySaved));
export const deleteTenancy: Epic<IAnyAction>
    = (action$) => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("tenancy", "tenancyDelete"),
            mergeMap(id => tenanciesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.tenancy.tenancyDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };
