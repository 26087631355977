import * as React from "react";
import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import { FormikProps, FormikHelpers, Formik } from "formik";
import { CustomScreenModel, IFeatureModel } from "proxy/apiProxy";
import CustomScreenData, { CustomScreenFormValues } from "./CustomScreenData";
import GitHubIcon from '@material-ui/icons/GitHub';
import { useReduxSelections, useReduxActions } from "tools/lib/reduxStoreAccess";
import CommunityMacroSelect from "components/global/CommunityMacroSelect";
import { oProps } from "tools/lib/utility";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { IActionButton } from "tools/components/FabContainer";
import { getCustomScreenTypeLabel } from "./getCustomScreenTypeLabel";
import MacroScriptEditor from "features/MacroScript/MacroScriptEditor";
import { useRef } from "react";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { Box } from "@material-ui/core";
import { setLicenseKey } from "survey-core";
setLicenseKey("YjRhNWFkNWItNDMyMi00MGM0LTg3OGItYTZkZjc3MmRjMmYwOzE9MjAyNS0wNC0wMiwyPTIwMjUtMDQtMDI=");
export default function CustomScreenComponent() {
    const [showCommunityListDialog, setShowCommunityListDialog] = React.useState(false);
    const refToSurveyCreator = useRef(new SurveyCreator({
        showLogicTab: true,
        isAutoSave: true,
        showJSONEditorTab: true,
        showTranslationTab: true,
        showThemeTab: true
    }));
    const { customScreenCurrent, customScreenLoading, customScreenSaving, customScreenScriptCheckResult = { errors: [] }, customScreenScriptMetadata } = useReduxSelections("customScreen");
    const { referenceMacroScriptCategories = {} } = useReduxSelections("reference");
    const { navigationActiveScreen: navigationActiveNode } = useReduxSelections("navigation");
    const { customScreenSave, customScreenDelete, customScreenValidateScript } = useReduxActions("customScreen")
    const { navigationNavigate } = useReduxActions("navigation")
    const handleBack = () => navigationNavigate(undefined);
    const formValues = React.useMemo<CustomScreenFormValues | undefined>(() => {
        if (!customScreenCurrent) {
            return undefined;
        }
        const { templateContent, ...values } = customScreenCurrent;
        return {
            ...values,
            hasUserTemplate: !!templateContent,
        }

    }, [customScreenCurrent]);
    React.useEffect(() => {
        refToSurveyCreator.current.text = customScreenCurrent?.templateContent ?? "{}";
    }, [customScreenCurrent?.templateContent]);
    const handleShowCommunityListDialog = React.useCallback(() => setShowCommunityListDialog(true), []);
    const handleHideCommunityListDialog = React.useCallback(() => setShowCommunityListDialog(false), []);
    const handleTabValueChanged = React.useCallback((value: any) => {
        const tabValue = value as string;
        navigationNavigate({ sectionKey: tabValue });
    }, [navigationNavigate])
    const handleDeleteClick = React.useCallback(() => {
        if (customScreenCurrent?.id) {
            customScreenDelete(customScreenCurrent.id);
        };
    }, [customScreenCurrent?.id, customScreenDelete]);
    const handleSubmit = React.useCallback(({ templateContent, hasUserTemplate, ...values }: CustomScreenFormValues, { setSubmitting }: FormikHelpers<CustomScreenFormValues>) => {
        customScreenSave({
            templateContent: hasUserTemplate ? refToSurveyCreator.current.text : undefined,
            ...values
        });
        setSubmitting(false);
    }, [customScreenSave])
    const handleScriptChanged = React.useCallback((script: string) => customScreenValidateScript(script), [customScreenValidateScript]);
    if (!formValues) {
        return <DetailPanelLoading onBackClick={handleBack} tabNumber={3} hasSubTitle={false} />;
    }
    return <Formik onSubmit={handleSubmit} initialValues={formValues} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;
    function renderForm({ submitForm, setFieldValue, values, dirty, isValid }: FormikProps<CustomScreenFormValues>) {
        const tabValue = navigationActiveNode?.activeSectionKey;
        const title = !!values.id ? values.name : "New Custom Screen";
        const { errors } = customScreenScriptCheckResult;
        // values.hasUserTemplate
        const tabs: ISubMenu[] | undefined = [{
            value: "detail",
            label: "Data",
        }];
        if (values.hasUserTemplate) {
            tabs.push({
                value: "template",
                label: "Screen design",
            });
            tabs.push({
                value: "macro",
                label: "Code Behind",
            });
        }

        const extraActionButtons: IActionButton[] = [];
        if (tabValue === "macro") {
            extraActionButtons.push({
                label: "Get script from repository",
                onClick: handleShowCommunityListDialog,
                icon: GitHubIcon
            });
        };
        const handleCommunityMacroSelected = (communityMacro: string) => {
            handleHideCommunityListDialog();
            setFieldValue(oProps<CustomScreenModel>().path("afterCompleteMacro"), communityMacro);
            customScreenValidateScript(communityMacro);
        }
        const canSave = (dirty || refToSurveyCreator.current.text !== customScreenCurrent?.templateContent) && isValid
        const handleSaveClick = () => {
            if (canSave) {
                submitForm();
            }
        };
        return <WriteAccess value={IFeatureModel.CustomScreenWrite}>
            <CommunityMacroSelect
                isOpened={!!showCommunityListDialog}
                onClose={handleHideCommunityListDialog}
                onMacroLoaded={handleCommunityMacroSelected}
                macroType={"customScreen"} />
            <DetailPanel
                isQuerying={customScreenLoading || customScreenSaving}
                tabs={tabs}
                title={title}
                subTitle={`${getCustomScreenTypeLabel(values.type)} Custom Screen`}
                badge={!values.id ? "new" : undefined}
                onBackClick={handleBack}
                noPadding={tabValue === "macro" || tabValue === "template"}
                onSaveClick={handleSaveClick}
                canSave={canSave}
                saveAllowed={IFeatureModel.CustomScreenWrite}
                deleteAllowed={IFeatureModel.CustomScreenWrite}
                onDeleteClick={handleDeleteClick}
                canDelete={!!values.id}
                actions={extraActionButtons}
                tabValue={tabValue}
                onTabValueChanged={handleTabValueChanged}>
                {(tabValue === "detail") && <CustomScreenData referenceMacroScriptCategories={referenceMacroScriptCategories} />}
                {(tabValue === "macro" && values.hasUserTemplate) && <MacroScriptEditor field={oProps<CustomScreenModel>().path("afterCompleteMacro")} executing={false} onScriptChanged={handleScriptChanged} scriptErrors={errors} onSaveRequest={handleSaveClick} metadata={customScreenScriptMetadata} />}
                {(tabValue === "template" && values.hasUserTemplate) && <Box display="flex" flexDirection="row" style={{ height: "100%", width: "100%" }}>
                    <SurveyCreatorComponent creator={refToSurveyCreator.current} />
                </Box>}


                {/* <CustomScreenDesigner initialValue={formValues?.templateContent ?? "{}"} getterRef={templateGetterRef} /> */}
            </DetailPanel>
        </WriteAccess>
    }
}
