import DetailPanel from "tools/components/DetailPanel";
import { IFeatureModel, IMacroScriptCategoryModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import FieldBox from "tools/components/FieldBox";
import { oProps } from "tools/lib/utility";
import FormTextField from "tools/fieldComponents/FormTextField";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { useReduxSelections, useReduxActions } from "tools/lib/reduxStoreAccess";

export default function MacroScriptCategory() {
    const { macroScriptCategoryCurrent, macroScriptCategoryLoading, macroScriptCategorySaving } = useReduxSelections("macroScriptCategory");
    const { macroScriptCategoryDelete, macroScriptCategorySave } = useReduxActions("macroScriptCategory");
    const { navigationNavigate } = useReduxActions("navigation");
    const handleBack = () => navigationNavigate(undefined);

    if (!macroScriptCategoryCurrent) {
        return <DetailPanelLoading onBackClick={handleBack} hasSubTitle={false} />;
    }
    const handleDelete = () => {
        if (macroScriptCategoryCurrent?.id) {
            macroScriptCategoryDelete(macroScriptCategoryCurrent.id);
        }
    }
    const handleSubmit = (values: IMacroScriptCategoryModel, { setSubmitting }: FormikHelpers<IMacroScriptCategoryModel>) => {
        macroScriptCategorySave(values);
        setSubmitting(false);
    }
    
    return <Formik onSubmit={handleSubmit} initialValues={macroScriptCategoryCurrent} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    
    function renderForm(formikProps: FormikProps<IMacroScriptCategoryModel>) {
        if (!macroScriptCategoryCurrent) {
            return;
        }
        const { dirty, isValid, submitForm, values: { id } } = formikProps;

        const title = !!id ? "Macro Category" : 'New Macro Category';
        return <WriteAccess value={IFeatureModel.MacroCategoryWrite}>
            <DetailPanel
                isQuerying={macroScriptCategoryLoading || macroScriptCategorySaving}
                title={title}
                canSave={dirty && isValid}
                saveAllowed={IFeatureModel.MacroCategoryWrite}
                deleteAllowed={IFeatureModel.MacroCategoryWrite}
                onSaveClick={submitForm}
                canDelete={!!macroScriptCategoryCurrent.id}
                onDeleteClick={handleDelete}
                onBackClick={handleBack}
                badge={!macroScriptCategoryCurrent.id ? "new" : undefined}>
                <FieldBox display="flex" flexDirection="column">
                    <FormTextField name={oProps<IMacroScriptCategoryModel>().path("name")} label="Name" required={true} maxLength={250} />
                </FieldBox>
            </DetailPanel>
        </WriteAccess>
    }

} 
