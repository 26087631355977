import * as React from "react";
import { PortfolioSummaryModel, ICurrencyModel } from "proxy/apiProxy";
import { Card, CardContent, CardActions, Button, CardHeader, IconButton, Collapse } from "@material-ui/core";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import { useReduxActions } from "tools/lib/reduxStoreAccess";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { SummaryField } from "../global/SummaryField";
import { getPortfolioTypeLabel } from "features/ManagedPortfolio/getPortfolioTypeLabel";
import { useField } from "formik";
import CloseIcon from '@material-ui/icons/Close';
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";

export interface IManagedPortfolioSummaryProps {
  portfolios: Record<number, PortfolioSummaryModel>;
  currencies: Record<number, ICurrencyModel>;
  label?: string;
  name: string;
  onDelete?: () => void;
}

export default function ManagedPortfolioSummary({ onDelete, name: fieldName, portfolios, currencies, label }: IManagedPortfolioSummaryProps) {
  const [expanded, setExpanded] = React.useState(false);
  const { navigationNavigate } = useReduxActions("navigation");
  const [{ value: portfolioId }, , { setValue }] = useField<number | undefined>(fieldName);
  if (!portfolioId) {
    return null;
  }
  const portfolio = portfolios[portfolioId];
  if (!portfolio) {
    return null;
  }
  const { id, type, name, currencyId = 0, internalCode } = portfolio;
  const { [currencyId]: currency } = currencies;
  const handleSwitchExpand = () => setExpanded(!expanded);
  const typeLabel = getPortfolioTypeLabel(type);
  const handleOpenPortfolio = () => navigationNavigate({ screenKey: "MyPortfolios", sectionKey: "detail", parameters: { id } });

  const handleSetUndefined = () => {
    setValue(undefined);
    onDelete && onDelete();
  }
  return <ReadOnlyContext.Consumer>{readOnly => <Card >
    <CardHeader
      action={<>
        {!readOnly && <IconButton onClick={handleSetUndefined}><CloseIcon /></IconButton>}
        <IconButton onClick={handleSwitchExpand}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </>}
      subheader={label ?? typeLabel}
      title={name} />
    <Collapse in={expanded} timeout="auto">
      <CardContent>
        <SummaryField label="Name" value={name} />
        {!!currencyId && currency && <SummaryField label="Currency" value={`${currency.isoCode} - ${currency.name.en}`} />}
        <SummaryField label="Internal Code" value={internalCode} />
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleOpenPortfolio}>
          <OpenInBrowser />
          Open Portfolio
        </Button>
      </CardActions>
    </Collapse>
  </Card>}
  </ReadOnlyContext.Consumer>
}




export interface IManagedPortfolioSummaryReadOnlyProps {
  portfolios: Record<number, PortfolioSummaryModel>;
  currencies: Record<number, ICurrencyModel>;
  label?: string;
  portfolioId: number;
}

export function ManagedPortfolioSummaryReadOnly({ portfolioId, portfolios, currencies, label }: IManagedPortfolioSummaryReadOnlyProps) {
  const [expanded, setExpanded] = React.useState(false);
  const { navigationNavigate } = useReduxActions("navigation");
  if (!portfolioId) {
    return null;
  }
  const portfolio = portfolios[portfolioId];
  if (!portfolio) {
    return null;
  }
  const { id, type, name, currencyId = 0, internalCode } = portfolio;
  const { [currencyId]: currency } = currencies;
  const handleSwitchExpand = () => setExpanded(!expanded);
  const typeLabel = getPortfolioTypeLabel(type);
  const handleOpenPortfolio = () => navigationNavigate({ screenKey: "MyPortfolios", sectionKey: "detail", parameters: { id } });

  return <Card >
  <CardHeader
    action={<>
      <IconButton onClick={handleSwitchExpand}>
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    </>}
    subheader={label ?? typeLabel}
    title={name} />
  <Collapse in={expanded} timeout="auto">
    <CardContent>
      <SummaryField label="Name" value={name} />
      {!!currencyId && currency && <SummaryField label="Currency" value={`${currency.isoCode} - ${currency.name.en}`} />}
      <SummaryField label="Internal Code" value={internalCode} />
    </CardContent>
    <CardActions>
      <Button size="small" onClick={handleOpenPortfolio}>
        <OpenInBrowser />
        Open Portfolio
      </Button>
    </CardActions>
  </Collapse>
</Card>
}
