import NavigationRoute from "components/global/NavigationRoute";
import { getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";
import TenantsAdministration from "./TenantsAdministration";

export default function TenantsAdministrationScreen() {
    return <>
        <NavigationRoute
            gridSize={12}
            slideDirection={"left"}
            component={TenantsAdministration}
            path={getSiteDetailScreenUrl("TenantsAdministration")} />
    </>
}
