import { IMacroTypeModel, IEntityMetadataModel, IMacroModel, DevelopmentItemModel } from "proxy/apiProxy";
import { DevelopmentItemDiagnostics, ITypedMacro } from "../slice";
import FormMacroEditor from "../Designers/FormMacroEditor";
import { StreamViewer } from "../Designers/StreamViewer";
import { EditorReferencePayload } from "../Designers/INotControllableEditorProps";

export interface IEtlDevelopmentItemRefs {
    etlMacroRef: React.MutableRefObject<EditorReferencePayload<IMacroModel>>;
}
interface IEtlMacroDevelopmentItemEditorProps extends IEtlDevelopmentItemRefs {
    type: DevelopmentItemModel["type"];
    tabValue: string | undefined;
    metadata: Record<string | number, IEntityMetadataModel>;
    diagnostics?: DevelopmentItemDiagnostics;
    onCodeChanged: (macro: ITypedMacro) => void;
}
export default function EtlMacroDevelopmentItemEditor({ tabValue, onCodeChanged, etlMacroRef, metadata, diagnostics }: IEtlMacroDevelopmentItemEditorProps) {
    switch (tabValue) {
        case "macro": return <FormMacroEditor
            type={IMacroTypeModel.Etl}
            onCodeChanged={onCodeChanged}
            executing={false}
            refToGetValue={etlMacroRef}
            diagnostics={diagnostics && diagnostics[IMacroTypeModel.Etl]}
            metadata={metadata}
            error={undefined} />;
        case "visualetl": return <StreamViewer
            definitionStructure={diagnostics?.Etl?.description}
            counters={undefined}
            error={undefined} />;
    }
    return null;
}
