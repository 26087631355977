import * as React from "react";
import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import { IFeatureModel, IUniverseScopeTypeModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import SicavData from "./SicavData";
// import SicavNotes from "./SicavNotes";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { getEntityName } from "tools/lib/modelUtils";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import CustomViewDatas from "features/Relationship/CustomViewDatas";
import useScopeButtonsMenu from "components/global/useScopeButtonsMenu";
import MonitoringResultGroups from "components/global/MonitoringResults";
// import { ISicavData } from "reducers/ManagedSicav";
import { oProps } from "tools/lib/utility";
import { ISicavData } from "./slice";
import { ProcessExecutionList } from "features/ProcessExecution/ProcessExecutionList";
import NotebookCheckOutlineIcon from "mdi-material-ui/NotebookCheckOutline";
import { IActionButton } from "tools/components/FabContainer";
import EntityList from "features/Entity/EntityList";


export default function Sicav() {
    const { sicavCurrent, entityPicture } = useReduxSelections("managedSicav");
    const { managedSicavSave } = useReduxActions("managedSicav");
    const formValues: ISicavData | undefined = React.useMemo(() => {
        if (!sicavCurrent) {
            return undefined;
        }
        const ret: ISicavData = {
            sicav: sicavCurrent,
            imageFile: entityPicture,
        };
        return ret;
    }, [entityPicture, sicavCurrent]);

    const handleSubmit = React.useCallback((values: ISicavData, { setSubmitting }: FormikHelpers<ISicavData>) => {
        managedSicavSave(values);
        setSubmitting(false);
    }, [managedSicavSave])

    if (!formValues) {
        return <DetailPanelLoading onBackClick tabNumber={2} hasSubTitle={false} />;
    }

    return <Formik onSubmit={handleSubmit} initialValues={formValues} enableReinitialize={true} validateOnMount={true} >
        {formikProps => <SicavForm formikProps={formikProps} />}
    </Formik>;
}
function SicavForm({ formikProps: { dirty, isValid, submitForm, values: formValues } }: { formikProps: FormikProps<ISicavData> }) {
    const {
        sicavCurrent,
        sicavLoading,
        dictionaries,
        sicavSaving,
        customScreenDataLoading,
        customScreenDatas,
        customScreens,
        customScreenDataSubmitting,
        monitoringResults,
        processExecutionsLoading,
        processExecutions = []
    } = useReduxSelections("managedSicav");
    const { referenceCountries = {}, referenceCurrencies = {} } = useReduxSelections("reference");
    const {
        managedSicavDelete,
        managedSicavAddEntityInDictionary,
        managedSicavCustomScreenDataSubmit,
        managedSicavMonitoringResultLoad,
    } = useReduxActions("managedSicav");
    const isGranted = useGrants();
    const { navigationActiveScreen } = useReduxSelections("navigation");
    const extraReportActionButtons = useScopeButtonsMenu(IUniverseScopeTypeModel.Sicav, sicavCurrent?.id);
    const handleLoadResult = React.useCallback((id: number) => formValues && managedSicavMonitoringResultLoad({ id, targetId: formValues.sicav.id }), [formValues, managedSicavMonitoringResultLoad]);
    const { macroScriptList } = useReduxSelections("macroScript");
    const monitoringMacros = React.useMemo(() => macroScriptList.filter(i => (i.type === "MonitoringMacroScriptModel" || i.type === "MonitoringMacroScriptSummaryModel") && i.singleScope === IUniverseScopeTypeModel.Sicav), [macroScriptList]);
    const { classificationTypes } = useReduxSelections("entityClassificationType");
    const { navigationNavigate } = useReduxActions("navigation");
    const handleBack = React.useCallback(() => navigationNavigate(undefined), [navigationNavigate]);
    const title = !!formValues?.sicav.id ? (getEntityName(sicavCurrent) ?? "") : 'New entity';
    const handleDelete = React.useCallback(() => {
        if (sicavCurrent?.id) {
            managedSicavDelete(sicavCurrent.id);
        }
    }, [managedSicavDelete, sicavCurrent?.id])
    const handleNewExecutionProcess = React.useCallback(() => {
        navigationNavigate({ screenKey: "ProcessExecutions", sectionKey: "detail", parameters: { type: "EntityProcessExecutionModel", targetId: sicavCurrent?.id ?? 0 } });
    }, [sicavCurrent?.id, navigationNavigate])
    const handleTabValueChanged = React.useCallback((value: any) => navigationNavigate({ sectionKey: value as string }), [navigationNavigate]);
    // const handleNoteSelected = (id: number) => navigationNavigate({ screenKey: "Notes", sectionKey: "detail", parameters: { id } });

    const handleDataSubmit = React.useCallback((customScreenId: number, values: any) => formValues && managedSicavCustomScreenDataSubmit({ id: formValues.sicav.id, customScreenId, values }), [formValues, managedSicavCustomScreenDataSubmit]);
    const canEditCustomData = isGranted({ feature: IFeatureModel.ManagedPortfolioCustomDataWrite, sicavId: formValues?.sicav.id ?? 0 });
    const tabValue = navigationActiveScreen?.activeSectionKey;
    const tabs: ISubMenu[] = [{
        label: "Data",
        value: "detail"
    }, {
        label: "Entities",
        value: "entities"
    }];
    if (!!monitoringMacros.length) {
        tabs.push({
            label: "Dashboard",
            value: "dashboard"
        });
    }
    if (sicavCurrent?.id) {
        tabs.push({
            label: "Processes",
            value: "processes"
        });
    }
    if (sicavCurrent?.id && !!customScreens && customScreens.length) {
        tabs.push({
            label: "Custom Data",
            value: "customViews"
        });
    }
    const extraActionButtons: IActionButton[] = [...extraReportActionButtons, {
        label: "Add Process Execution",
        icon: NotebookCheckOutlineIcon,
        onClick: handleNewExecutionProcess,
        feature: IFeatureModel.ManagedPortfolioWrite//,
    }];
    const handleEntitySelected = React.useCallback((entityId: number) => {
        const entity = dictionaries.entities[entityId];
        if (!entity) {
            return;
        }
        switch (entity.type) {
            case "PersonSummaryModel":
                navigationNavigate({ screenKey: "People", sectionKey: "detail", parameters: { id: entityId } });
                break;
            case "EntityGroupSummaryModel":
                navigationNavigate({ screenKey: "EntityGroups", sectionKey: "detail", parameters: { id: entityId } });
                break;
            case "CompanySummaryModel":
                navigationNavigate({ screenKey: "Companies", sectionKey: "detail", parameters: { id: entityId } });
                break;
            case "SicavSummaryModel":
                navigationNavigate({ screenKey: "Sicavs", sectionKey: "detail", parameters: { id: entityId } });
                break;
        }
    }, [dictionaries.entities, navigationNavigate]);
    return <WriteAccess value={{ feature: IFeatureModel.ManagedPortfolioWrite, sicavId: formValues?.sicav?.id ?? 0 }}>
        <DetailPanel
            isQuerying={sicavLoading || sicavSaving || customScreenDataLoading || customScreenDataSubmitting || processExecutionsLoading}
            title={title}
            subTitle={sicavCurrent?.type?.replace("Model", "")}
            canSave={dirty && isValid}
            onSaveClick={submitForm}
            canDelete={!!sicavCurrent?.id}
            onDeleteClick={handleDelete}
            onBackClick={handleBack}
            saveAllowed={{ feature: IFeatureModel.ManagedPortfolioWrite, sicavId: formValues?.sicav?.id ?? 0 }}
            deleteAllowed={{ feature: IFeatureModel.ManagedPortfolioWrite, sicavId: formValues?.sicav?.id ?? 0 }}
            badge={!sicavCurrent?.id ? "new" : undefined}
            tabs={tabs}
            tabValue={tabValue}
            actions={extraActionButtons}
            onTabValueChanged={handleTabValueChanged}>
            {(tabValue === "detail") && <SicavData
                classificationTypes={classificationTypes}
                formFieldName={oProps<ISicavData>().path("sicav")}
                formImageFieldName={oProps<ISicavData>().path("imageFile")}
                dictionaries={dictionaries}
                onEntityLoaded={managedSicavAddEntityInDictionary}
                referenceCountries={referenceCountries}
                referenceCurrencies={referenceCurrencies} />}
            {(tabValue === "dashboard" && !!monitoringMacros.length) && <MonitoringResultGroups
                monitoringMacros={monitoringMacros}
                onLoadResult={handleLoadResult}
                resultGroups={monitoringResults} />}
            {(tabValue === "customViews" && !!customScreenDatas && !!customScreens?.length) && <CustomViewDatas
                canEdit={canEditCustomData}
                dictionaries={dictionaries}
                customScreenLoading={customScreenDataLoading}
                dataSubmitting={customScreenDataSubmitting}
                customScreens={customScreens}
                onDataSubmit={handleDataSubmit}
                customScreenDatas={customScreenDatas} />}
            {(tabValue === "entities") && <EntityList
                dictionaries={dictionaries}
                formFieldName={oProps<ISicavData>().path("sicav")}
                onEntityLoaded={managedSicavAddEntityInDictionary}
                onEntitySelected={handleEntitySelected} />}
            {(tabValue === "processes") && <ProcessExecutionList
                processExecutions={processExecutions}
                dictionaries={dictionaries} />}
        </DetailPanel>
    </WriteAccess>
}