import * as React from "react";
import { EntitySummaryModel, IEntityTypeModel } from "proxy/apiProxy";
import AsyncSelect from "tools/components/AsyncSelect";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IGetEntitySummary } from "reducers/Reference";
import { getEntityName } from "tools/lib/modelUtils";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import SearchIcon from '@material-ui/icons/Search';

export interface IEntitySelectFieldProps {
    label?: React.ReactNode;
    onSelected?: (found: IGetEntitySummary) => void;
    type?: IEntityTypeModel;
}
export default function EntitySearch({ label, onSelected, type: entityType }: IEntitySelectFieldProps) {
    const { referenceEntitySearch } = useReduxActions("reference");
    const { referenceEntitiesSearched, referenceEntitySearching } = useReduxSelections("reference");
    const getOptionLabel = (entity: EntitySummaryModel) => getEntityName(entity) ?? "";
    label = <><SearchIcon fontSize="small"/> {label ?? "Index"}: Type criteria to find an entity</>;
    const handleValueSelected = (entity: EntitySummaryModel) => {
        if (onSelected) {
            const entities = entity.mainContactId ? { [entity.mainContactId]: referenceEntitiesSearched.entities[entity.mainContactId] } : {};
            onSelected({ entity, entities });
        }
    }
    const handleOnSearchOptions = (criterias: string) => referenceEntitySearch({ criterias, type: entityType });
    return <ReadOnlyContext.Consumer>{readOnly => readOnly ? null :
        <AsyncSelect onSearchOptions={handleOnSearchOptions} fullWidth={true} options={referenceEntitiesSearched.all}
            getOptionLabel={getOptionLabel} searching={referenceEntitySearching} label={label} onRequiredValueSelected={handleValueSelected} />}
    </ReadOnlyContext.Consumer>

}
