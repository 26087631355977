import produce from "immer";
import { User } from "oidc-client-ts";
// import * as UserDataAccess from "dataAccess/User";
import { ICompanyModel, ICompanySummaryModel, IGetParametersModel, IParametersModel, IProfileModel, ITenantSummaryModel } from "proxy/apiProxy"
import { produceActionFactories, AnyActionOf } from "tools/lib/store";

export interface IStoreGridState {
    sorting: {
        columnName: string;
        direction: 'asc' | 'desc';
    }[];
    expandedRows: (number | string)[] | undefined;
    expandedDetails: (number | string)[] | undefined;
    filters: {
        columnName: string;
        operation?: string;
        value?: string;
    }[];
    grouping: {
        columnName: string;
    }[];
    columnWidths: {
        columnName: string;
        width: number | string;
    }[];
    columnOrder: string[];
    hiddenColumn: string[];
    expandedGroups?: string[];
}
export interface ISetGridStatePayload {
    screenKey: string;
    sectionKey: string;
    state: IStoreGridState;
}

export interface IApplicationLoadedPayload {
    profile?: IProfileModel;
    currentTenant?: ICompanyModel;
    currentTenantImage?: string;
    parameters?: IGetParametersModel;
    accessibleTenants: ICompanySummaryModel[] | undefined;
}
export interface ITenantImageLoadedPayload {
    tenantId: number;
    imageUrl?: string;
}
export const ActionFactories = produceActionFactories({
    setGridState: (payload: ISetGridStatePayload) => payload,
    requestLogout: () => undefined,
    onSigninCallback: (payload: User) => payload,
    searchShortcut: () => undefined,

    openMenuDrawer: () => undefined,
    closeMenuDrawer: () => undefined,
    dummy: () => undefined,
    firstTenantCreate: (payload: ITenantSummaryModel) => payload,
    firstTenantCreated: (payload: ICompanySummaryModel) => payload,

    switchTenant: (payload: number) => payload,
    // applicationLoad: (payload: number) => payload,
    applicationLoaded: (payload: IApplicationLoadedPayload) => payload,
    applicationLoadedWithNoTenant: () => undefined,
    tenantImageLoaded: (payload: ITenantImageLoadedPayload) => payload,
    tenantsImageLoad: () => undefined,
    tenantAdded: (payload: ICompanySummaryModel) => payload
});

export interface IState {
    gridStates: {
        [screenKey: string]: {
            [sectionKey: string]: IStoreGridState;
        }
    },
    currentUser?: IProfileModel;
    parameters?: IParametersModel;
    currentTenant?: ICompanyModel;
    currentTenantImageUrl?: string;
    searchShortcutGuid: number;
    applicationLoading: boolean;
    menuDrawerOpened: boolean;
    accessibleTenants: ICompanySummaryModel[];
    noTenant: boolean;
    tenantsImageUrls: Record<number, string>;
    tenantCreating: boolean;
}
export type Action = AnyActionOf<typeof ActionFactories>;

export function reducer(
    state: IState = {
        menuDrawerOpened: true,
        gridStates: {},
        searchShortcutGuid: 0,
        applicationLoading: false,
        tenantsImageUrls: {},
        tenantCreating: false,
        accessibleTenants: [],
        noTenant: false
    },
    action: Action
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "tenantAdded":
                draft.accessibleTenants.push(action.payload);
                break;
            case "closeMenuDrawer":
                draft.menuDrawerOpened = false;
                break;
            case "openMenuDrawer":
                draft.menuDrawerOpened = true;
                break;
            case "setGridState":
                if (!draft.gridStates[action.payload.screenKey]) {
                    draft.gridStates[action.payload.screenKey] = {};
                }
                draft.gridStates[action.payload.screenKey][action.payload.sectionKey] = action.payload.state;
                break;
            case "applicationLoaded":
                draft.currentTenant = action.payload.currentTenant;
                draft.currentTenantImageUrl = action.payload.currentTenantImage;
                draft.currentUser = action.payload.profile;
                draft.parameters = action.payload.parameters?.parameters;
                draft.accessibleTenants = action.payload.accessibleTenants ?? [];
                draft.noTenant = false;
                draft.applicationLoading = false;
                break;
            case "applicationLoadedWithNoTenant":
                delete draft.currentTenant;
                delete draft.currentTenantImageUrl;
                delete draft.currentUser;
                delete draft.parameters;
                draft.accessibleTenants = [];
                draft.noTenant = true;
                draft.applicationLoading = false;
                break;
            case "searchShortcut":
                draft.searchShortcutGuid = draft.searchShortcutGuid + 1;
                break;
            case "onSigninCallback":
                draft.applicationLoading = true;
                break;
            case "tenantImageLoaded":
                if (action.payload.imageUrl) draft.tenantsImageUrls[action.payload.tenantId] = action.payload.imageUrl;
                else delete draft.tenantsImageUrls[action.payload.tenantId];
                break;
            case "firstTenantCreate":
                draft.tenantCreating = true;
        }
    });
}
