import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import Tenancies from "./Tenancies";
import Tenancy from "./Tenancy";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class TenancyScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                gridSize={12}
                exact={true}
                slideDirection={"right"}
                component={Tenancies}
                path={getSiteScreenUrl("Tenancies")} />
            <NavigationRoute
                gridSize={12}
                slideDirection={"left"}
                component={Tenancy}
                path={getSiteDetailScreenUrl("Tenancies")} />
        </>
    }
}

export default TenancyScreen;
