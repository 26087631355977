import * as React from "react";
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Box, Paper, Typography, IconButton, Badge, LinearProgress } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { GlobalHotKeysProps, HotKeys } from "react-hotkeys";
import FabContainer, { IActionButton } from "./FabContainer";
import { IFeatureModel } from "proxy/apiProxy";

const ContainerBox = withStyles(theme =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            position: "relative"
        },
    })
)(Box);

const StyledAccordionSummary = withStyles(theme =>
    createStyles({
        root: {
            '&$expanded': {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
        },
    })
)(AccordionSummary);

const TopPaper = withStyles(theme =>
    createStyles({
        root: {
            height: "100%",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column"
        },
    })
)(Paper);

const TitleContainerBox = withStyles(theme =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            borderBottom: 1,
            borderBottomColor: theme.palette.divider,
            borderBottomStyle: "solid"
        },
    })
)(Box);

const StyledAccordion = withStyles(theme =>
    createStyles({
        root: {
            marginTop: "0 !important",
            marginBottom: "0 !important",
            // '&$expanded': {
            //     marginTop: "0 !important",
            //     marginBottom: "0 !important",
            // },
        },
    })
)(Accordion);

interface ISearchTitleProps {
    title: string;
    badge?: string;
}
function SearchTitle({ badge, title }: ISearchTitleProps) {
    if (!badge) {
        return (<Typography variant="h6" color="inherit" noWrap={true}>
            {title && <span style={{ verticalAlign: "middle" }}>
                {title}
            </span>}
        </Typography>);
    }
    return (<Badge badgeContent={badge} color="secondary">
        <Typography variant="h6" color="inherit" noWrap={true}>
            {title && <span style={{ verticalAlign: "middle" }}>
                {title}
            </span>}
        </Typography>
    </Badge>);
}

interface ITitleBarProps {
    onBackClick?: () => void;
    title: string;
    badge?: string;
    subTitle?: string;
}
function TitleBar({ subTitle, onBackClick, badge, title }: ITitleBarProps) {
    return <Box display="flex" alignItems="center">
        {(!!onBackClick) && <IconButton aria-label="Back" style={{ verticalAlign: "middle" }} onClick={onBackClick} >
            <ArrowBackIcon />
        </IconButton>}
        <Box>
            <SearchTitle badge={badge} title={title} />
            {(!!subTitle) && <Typography variant="subtitle1" color="inherit" noWrap={true}>
                {subTitle}
            </Typography>}
        </Box>
    </Box>;
}

interface ITopPanelProps {
    onBackClick?: () => void;
    renderSearch?: React.ReactNode;
    title: string;
    badge?: string;
    subTitle?: string;
    expanded: boolean;
    onExpandedChange: (expanded: boolean) => void;
}
function TopPanel({ expanded, renderSearch, subTitle, onBackClick, badge, title, onExpandedChange }: ITopPanelProps) {
    const handleExpandedChange = (event: React.ChangeEvent<{}>, e: boolean) => onExpandedChange(e);

    if (renderSearch) {
        return <StyledAccordion expanded={expanded} onChange={handleExpandedChange}>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <TitleBar title={title} badge={badge} onBackClick={onBackClick} subTitle={subTitle} />
            </StyledAccordionSummary>
            <AccordionDetails>
                {renderSearch}
            </AccordionDetails>
        </StyledAccordion>;
    }
    else {
        return <TitleContainerBox>
            <TitleBar title={title} badge={badge} onBackClick={onBackClick} subTitle={subTitle} />
        </TitleContainerBox>
    }
}

export interface ICollapsibleSearchPanelProps {
    onBackClick?: () => void;
    onSearchClick?: () => void;
    onRefreshClick?: () => void;
    onAddClick?: () => void;
    // renderExtraButtons?: React.ReactNode;
    addAllowed?: IFeatureModel | IFeatureModel[];
    refreshDisabled?: boolean;
    addDisabled?: boolean;
    searchDisabled?: boolean;
    renderSearch?: React.ReactNode;
    title: string;
    badge?: string;
    subTitle?: string;
    actions?: IActionButton[];
    isQuerying?: boolean;
    preventCollapse?: boolean;
}
export default function SearchPanel({ addAllowed, onSearchClick, preventCollapse, onRefreshClick, children, isQuerying, renderSearch, subTitle, onBackClick, badge, title, onAddClick, actions, addDisabled, refreshDisabled, searchDisabled }: React.PropsWithChildren<ICollapsibleSearchPanelProps>) {
    const [expanded, setExpanded] = React.useState(true);

    const handleSearchClick = React.useCallback(() => {
        if (onSearchClick) {
            if (!preventCollapse) {
                setExpanded(false);
            }
            onSearchClick();
        }
    }, [onSearchClick, preventCollapse]);
    const handleSubmitForm = (event: React.FormEvent) => {
        event.preventDefault();
        handleSearchClick();
    }
    // const handleSearchClick = React.useCallback((event:React.FormEventHandler<HTMLFormElement>) => {
    //     event.preventDefault();
    //     if (onSearchClick) {
    //         if (!preventCollapse) {
    //             setExpanded(false);
    //         }
    //         onSearchClick();
    //     }
    // }, [onSearchClick, preventCollapse]);

    const handleRefreshClick = React.useCallback(() => {
        if (onRefreshClick) {
            onRefreshClick();
        }
    }, [onRefreshClick]);

    // const keyMap: GlobalHotKeysProps["keyMap"] = {
    //     "SEARCH": "Enter"
    // };
    // const keyHandlers: GlobalHotKeysProps["handlers"] = {
    //     "SEARCH": () => handleSearchClick && handleSearchClick()
    // };
    const handleExpandedChange = (e: boolean) => setExpanded(e);
    return <form style={{ height: "100%" }} onSubmit={handleSubmitForm}>
    {/* return <form style={{ flexGrow: 1 }} onSubmit={handleSubmitForm}> */}
        <button type="submit" style={{ position: "fixed", visibility: "hidden" }} >Submit</button>

        <TopPaper>
            <LinearProgress hidden={!isQuerying} variant="query" />
            <TopPanel renderSearch={renderSearch} subTitle={subTitle} onBackClick={onBackClick} badge={badge} title={title} expanded={expanded} onExpandedChange={handleExpandedChange} />
            {/* <HotKeys handlers={keyHandlers} keyMap={keyMap}>
        </HotKeys> */}
            <FabContainer
                addDisabled={addDisabled}
                onAddClick={onAddClick}
                addAllowed={addAllowed}
                onRefreshClick={onRefreshClick ? handleRefreshClick : undefined}
                refreshDisabled={refreshDisabled}
                onSearchClick={(onSearchClick && renderSearch) ? handleSearchClick : undefined}
                searchDisabled={searchDisabled || isQuerying}
                actions={actions} />
            <ContainerBox flexGrow="1" display="flex" overflow="auto">
                {children}
            </ContainerBox>
        </TopPaper>
    </form>
}
