import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, share, mergeMap, tap } from "rxjs/operators";
import { ReportModel, reportsApi, reportTemplatesApi, IGetReportModel, IFileTypeModel, IScopeDescriptionModel, ITemplateReportModel, IDocumentReportModel, documentDefinitionsApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, changedNavigation } from "lib/rxJsUtility";
import { tryParseNumber } from "tools/lib/utility";
import { filterRoute } from "tools/lib/UrlDictionary";
import saveAs from "file-saver";


export const generateReport: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("report", "reportGenerate"),
        mergeMap(id => reportsApi.generateReportsAsync({ id })),
        tap(({ blob, fileName }) => saveAs(blob, fileName)),
        map(i => ActionFactories.report.reportGenerated()));
export const onSelectReportTemplate: Epic<IAnyAction>
    = action$ => {
        const request$ = action$.pipe(
            mapToPayload("report", "reportSelectReportTemplate"),
            share());
        return merge(
            request$.pipe(
                filter(i => !!i),
                map(i => ActionFactories.report.reportTemplateMetadataSearchLoad(i as number))),
            request$.pipe(
                filter(i => !i),
                map(() => ActionFactories.report.reportTemplateMetadataSearchLoaded([]))));
    }

export const onSelectReportDocument: Epic<IAnyAction>
    = action$ => {
        const request$ = action$.pipe(
            mapToPayload("report", "reportSelectReportDocument"),
            share());
        return merge(
            request$.pipe(
                filter(i => !!i),
                map(i => ActionFactories.report.reportDocumentMetadataSearchLoad(i as number))),
            request$.pipe(
                filter(i => !i),
                map(() => ActionFactories.report.reportDocumentMetadataSearchLoaded([]))));
    }

export const onReportTemplateGetMetadata: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("report", "reportTemplateMetadataSearchLoad"),
        mergeMap(id => reportTemplatesApi.getSearchMetadataAsync({ id })),
        map(ActionFactories.report.reportTemplateMetadataSearchLoaded));
export const onDocumentGetMetadata: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("report", "reportDocumentMetadataSearchLoad"),
        mergeMap(id => documentDefinitionsApi.getSearchMetadataAsync({ id })),
        map(ActionFactories.report.reportDocumentMetadataSearchLoaded));
export const onOpenScreenReports: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("Reports"),
        map(() => ActionFactories.report.reportLoadAll()));
export const onOpenScreenReport: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("Reports", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? 0),
        map(reportId => ActionFactories.report.reportLoad(reportId as number)));
export const loadReports: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("report", "reportLoadAll"),
        mergeMap(() => reportsApi.getAllAsync({})),
        map(ActionFactories.report.reportLoadedAll));
export const loadReport: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("report", "reportLoad"),
            share()
        );
        const reportLoaded$ = merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => reportsApi.getAsync({ id }))),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({
                    report: {
                        id: 0,
                        name: "",
                        code: "",
                        scopeDescription: {
                            subScopes: {},
                            extraParameters: {},
                            groupLabelExpression: "{group}-{date:yyyyMMdd}"
                        } as IScopeDescriptionModel,
                        reportTemplateId: 0,
                        exportType: IFileTypeModel.Pdf,
                        cultureInfos: [],
                        publishedVersion: 0,
                        parameterValues: {},
                        type: "TemplateReportModel"
                    } as ReportModel,
                    reportTemplates: {}
                } as IGetReportModel)))).pipe(share());

        const templateSearchMetadata$ = reportLoaded$.pipe(
            filter(r => r.report.type === "TemplateReportModel"),
            map(r => (r.report as ITemplateReportModel).reportTemplateId),
            filter(r => !!r),
            map(i => ActionFactories.report.reportTemplateMetadataSearchLoad(i as number)));
        const documentSearchMetadata$ = reportLoaded$.pipe(
            filter(r => r.report.type === "DocumentReportModel"),
            map(r => (r.report as IDocumentReportModel).documentDefinitionId),
            filter(r => !!r),
            map(i => ActionFactories.report.reportDocumentMetadataSearchLoad(i as number)));
        const reportTemplates$ = requestedId$.pipe(
            mergeMap(() => reportTemplatesApi.getAllAsync({})),
            map(ActionFactories.report.reportReportTemplateLoadedAll));
        const documentDefinitions$ = requestedId$.pipe(
            mergeMap(() => documentDefinitionsApi.getAllAsync({})),
            map(ActionFactories.report.reportDocumentDefinitionsLoadedAll));
        return merge(
            reportLoaded$.pipe(map(ActionFactories.report.reportLoaded)),
            reportTemplates$,
            documentDefinitions$,
            documentSearchMetadata$,
            templateSearchMetadata$);
    };
export const saveReport: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("report", "reportSave"),
        mergeMap(model => reportsApi.saveAsync({ model })),
        map(ActionFactories.report.reportSaved));
export const deleteReport: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("report", "reportDelete"),
            mergeMap(id => reportsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.report.reportDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };
