import * as React from "react";
import { SecuritySummaryModel, ICurrencyModel, IIndexSummaryModel, IProxyPositionModel, ICountryModel } from "proxy/apiProxy";
import { Tooltip, IconButton, Typography, Grid } from "@material-ui/core";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import { IGetSecuritySummary } from "reducers/Reference";
import { FieldArray, FieldArrayRenderProps, useField } from "formik";
import DeleteIcon from '@material-ui/icons/Delete';
import { oProps } from "tools/lib/utility";
import FieldBox from "tools/components/FieldBox";
import FormTextField from "tools/fieldComponents/FormTextField";
import { IndexSearch } from "components/searchers/IndexSearch";
import SecuritySearch from "components/searchers/SecuritySearch";
import { IndexSummaryReadOnly } from "components/summaries/IndexSummary";
import {SecuritySummaryReadOnly} from "components/summaries/SecuritySummary";
interface IProxyPositionProps {
    targetPosition: IProxyPositionModel,
    referenceCountries: Record<number, ICountryModel>;
    referenceCurrencies: Record<number, ICurrencyModel>;
    indexes: Record<number, IIndexSummaryModel>;
    securities: Record<number, SecuritySummaryModel>;
    onSecurityClick: (securityId: number) => void;
    onIndexClick: (indexId: number) => void;
    index: number;
    onDelete: (index: number) => void;
    fieldPath: string;
}
function ProxyPosition({
    index,
    onDelete,
    targetPosition: {
        indexId,
        securityId
    },
    referenceCountries,
    referenceCurrencies,
    indexes,
    securities,
    onSecurityClick,
    onIndexClick,
    fieldPath
}: IProxyPositionProps) {
    const handleDeleteClick = () => onDelete(index);
    const handleOnView = () => {
        if (indexId) {
            onIndexClick(indexId);
        }
        else if (securityId) {
            onSecurityClick(securityId);
        }
    }
    return <Grid container={true} spacing={2}>
        <Grid item={true} xs={4}>
            {!!indexId && <IndexSummaryReadOnly label="Index" index={indexes[indexId]} countries={referenceCountries} currencies={referenceCurrencies} />}
            {!!securityId && <SecuritySummaryReadOnly security={securities[securityId]} currencies={referenceCurrencies} />}
        </Grid>
        <Grid item={true} xs={2}>
            <FormTextField name={oProps<IProxyPositionModel[]>(fieldPath).path(index, "weight")} label="Weight" isNumber={true} required={true} />
        </Grid>
        <Grid item={true} xs={1} alignItems="flex-end">
            <Tooltip title="Delete">
                <IconButton
                    size="small"
                    style={{ verticalAlign: "middle" }}
                    onClick={handleDeleteClick} >
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Open">
                <IconButton
                    size="small"
                    style={{ verticalAlign: "middle" }}
                    onClick={handleOnView} >
                    <OpenInBrowser />
                </IconButton>
            </Tooltip>
        </Grid>
    </Grid>;
}
interface ISecurityProxyProps {
    referenceCountries: Record<number, ICountryModel>;
    referenceCurrencies: Record<number, ICurrencyModel>;
    dictionaries: {
        indexes: Record<number, IIndexSummaryModel>;
        securities: Record<number, SecuritySummaryModel>;
    };
    onShareClassLoaded: (security: IGetSecuritySummary) => void;
    onIndexLoaded: (index: IIndexSummaryModel) => void;
    onSecurityClick: (securityId: number) => void;
    onIndexClick: (indexId: number) => void;
    fieldPath: string
}
export default function SecurityProxy({
    referenceCountries,
    referenceCurrencies,
    dictionaries: {
        indexes,
        securities,
    },
    onShareClassLoaded,
    onIndexLoaded,
    onSecurityClick,
    onIndexClick,
    fieldPath, }: ISecurityProxyProps) {
    const [, { value: securityProxyPositions = [] }] = useField<IProxyPositionModel[] | undefined>(fieldPath);

    return <FieldArray name={fieldPath} render={renderBenchmarkExposures} validateOnChange={true} />;
    function renderBenchmarkExposures({ remove, push }: FieldArrayRenderProps): React.ReactNode {
        const handleSelectedIndex = (selected: IIndexSummaryModel) => {
            push({
                indexId: selected.id,
                weight: 0,
            } as IProxyPositionModel);
            onIndexLoaded(selected);
        }
        const handleSelectedSecurity = (selected: IGetSecuritySummary) => {
            push({
                securityId: selected.security.id,
                weight: 0,
            } as IProxyPositionModel);
            onShareClassLoaded(selected);
        }
        return <>
            <FieldBox display="flex" flexDirection="column">
                {((securityProxyPositions?.length ?? 0) === 0)
                    ? <Typography>No proxy composition is defined</Typography>
                    : <>{securityProxyPositions.map((securityProxyPosition, index) => <ProxyPosition key={index}
                        referenceCountries={referenceCountries}
                        referenceCurrencies={referenceCurrencies}
                        indexes={indexes}
                        securities={securities}
                        onSecurityClick={onSecurityClick}
                        onIndexClick={onIndexClick}
                        fieldPath={fieldPath}
                        targetPosition={securityProxyPosition}
                        onDelete={remove}
                        index={index} />)}</>}
                <FieldBox display="flex" flexDirection="row">
                    <IndexSearch label="Add an index..." onSelected={handleSelectedIndex} />
                    <SecuritySearch label="Add a security..." onSelected={handleSelectedSecurity} />
                </FieldBox>
            </FieldBox>
        </>
    }
}
