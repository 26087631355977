import {
    ObservableInput,
    concat,
    from,
    // forkJoin,
    merge, of
} from "rxjs";
import { map, share, mergeMap, switchMap, withLatestFrom, tap } from "rxjs/operators";
import { ActionFactories, IAnyAction } from "reducers";
import { ProcessExecutionModel, processExecutionsApi, IGetProcessExecutionModel, IOversightLevelModel, studioDevelopmentItemsApi, IDevelopmentItemTypeModel, IQuestionnaireDevelopmentItemSummaryModel, IProcessTaskStatusModel, referencesApi } from "proxy/apiProxy";
import { Epic } from "redux-observable";
import { changedNavigation, mapToPayload } from "lib/rxJsUtility";
import { IUrlParams, filterRoute } from "tools/lib/UrlDictionary";
import { IProcessExecutionSavePayload, NewProcessExecutionModelRef, ProcessExecutionLoadPayload, isNewProcessExecutionModelRef, ActionFactories as ProcessExecutionActionFactories, isRenewProcessExecutionModelRef } from "./slice";
import saveAs from "file-saver";

export const onOpenScreenProcessExecutions: Epic<IAnyAction>
    = action$ => {
        const changedScreen$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey),
            share());
        const getRoleProcessExecutionRequest$ = changedScreen$.pipe(
            filterRoute("ProcessExecutions"));
        return merge(
            getRoleProcessExecutionRequest$,
        ).pipe(
            map(ProcessExecutionActionFactories.processExecutionLoadAll));
    }
export const loadProcessExecutions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("processExecution", "processExecutionLoadAll"),
        mergeMap(() => processExecutionsApi.getAllAsync({})),
        map(ProcessExecutionActionFactories.processExecutionLoadedAll));

export const loadQuestionnaires: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("processExecution", "processExecutionLoad"),
        mergeMap(() => studioDevelopmentItemsApi.getByTypeAsync({ type: IDevelopmentItemTypeModel.Questionnaire })),
        map(i => ActionFactories.processExecution.processExecutionQuestionnairesLoaded(i.developmentItems as IQuestionnaireDevelopmentItemSummaryModel[])));

function buildProcessExecutionLoadPayload(detail: IUrlParams | undefined): ProcessExecutionLoadPayload {
    if (detail?.id) {
        return {
            id: Number(detail?.id)
        }
    }
    else if (detail?.targetId === "renew") {
        return {
            renewId: Number(detail?.type)
        }
    }
    else {
        return {
            targetId: Number(detail?.targetId),
            type: detail?.type as ProcessExecutionModel["type"]
        }
    }
}
export const processExecutionQuestionnaireLoad: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("processExecution", "processExecutionLoadQuestionnaire"),
        mergeMap(i => processExecutionsApi.getProcessExecutionQuestionnaireAsync(i).then(r => ({ taskCode: i.processExecutionTaskCode, ...r }))),
        map(ActionFactories.processExecution.processExecutionLoadedQuestionnaire));
export const processExecutionReloadQuestionnaire: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("processExecution", "processExecutionReloadQuestionnaire"),
        mergeMap(i => processExecutionsApi.getProcessExistingExecutionQuestionnaireAsync({ ...i, getPreviousAnswers: true }).then(r => ({ taskCode: i.taskCode, ...r }))),
        map(ActionFactories.processExecution.processExecutionLoadedQuestionnaire));
// export const processExecutionQuestionnaireSave: Epic<IAnyAction>
//     = action$ => action$.pipe(
//         mapToPayload("processExecution", "processExecutionQuestionnaireSave"),
//         mergeMap(i => processExecutionsApi.saveProcessExecutionQuestionnaireResponseAsync(i).then(r => ({ taskCode: i.taskCode, result: r }))),
//         map(ActionFactories.processExecution.processExecutionQuestionnaireSaved));
export const getFile: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("processExecution", "processExecutionLoadFile"),
        mergeMap(i => processExecutionsApi.getProcessExecutionFileAsync(i).then(file => ({ file, payload: i }))),
        tap(({ file: { blob, fileName } }) => saveAs(blob, fileName)),
        map(({ payload }) => ProcessExecutionActionFactories.processExecutionLoadedFile(payload)));

export const onOpenScreenProcessExecution: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => ({
            id: matchingSections?.detail?.id,
            targetId: matchingSections?.detail?.targetId,
            type: matchingSections?.detail?.type
        })),
        filterRoute("ProcessExecutions", "detail"),
        map(({ matchingSections }) => buildProcessExecutionLoadPayload(matchingSections?.detail)),
        map(ProcessExecutionActionFactories.processExecutionLoad));

async function createEmptyProcessExecutionAsync({ targetId, type }: NewProcessExecutionModelRef): Promise<IGetProcessExecutionModel> {
    const { dictionaries, ...target } = await (async function () {
        switch (type) {
            case "EntityProcessExecutionModel": {
                const { all: [entity], ...dictionaries } = await referencesApi.getEntitySummaryAsync({ id: targetId });
                dictionaries.entities[targetId] = entity;
                return { type, entityId: targetId, dictionaries };
            }
            case "RelationshipProcessExecutionModel": {
                const { all: [relationship], ...dictionaries } = await referencesApi.getRelationshipSummaryAsync({ id: targetId });
                dictionaries.relationships[targetId] = relationship;
                return { type, relationshipId: targetId, dictionaries };
            }
            case "PortfolioProcessExecutionModel": {
                const { portfolios: [portfolio], ...dico } = await referencesApi.getPortfolioSummaryAsync({ id: targetId });
                const dictionaries = { ...dico, portfolios: { [targetId]: portfolio } };
                return { type, portfolioId: targetId, dictionaries };
            }
            case "SecurityProcessExecutionModel": {
                const { securities: [security], ...dico } = await referencesApi.getSecuritySummaryAsync({ id: targetId });
                const dictionaries = { ...dico, securities: { [targetId]: security } };
                return { type, securityId: targetId, dictionaries };
            }
        }
    })();
    const processExecution = {
        ...target,
        id: 0,
        classifications: {},
        tasks: [],
        endValidity: function () {
            const dt = new Date();
            dt.setFullYear(dt.getFullYear() + 1)
            return dt;
        }(),
        onSite: false,
        initial: false,
        oversightLevel: IOversightLevelModel.Normal,
        startValidity: new Date(),
        responsibles: [{ title: "Reviewer", responsibleId: undefined }],
    } as ProcessExecutionModel;
    return {
        processExecution,
        relationships: {},
        portfolios: {},
        securities: {},
        ...dictionaries
    };
}
export const loadProcessExecution: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("processExecution", "processExecutionLoad"),
        mergeMap(async payload => {
            if (isNewProcessExecutionModelRef(payload)) return await createEmptyProcessExecutionAsync(payload);
            else if (isRenewProcessExecutionModelRef(payload)) return renewedProcessExecution(await processExecutionsApi.getAsync({ id: payload.renewId }));
            else return await processExecutionsApi.getAsync(payload);
        }),
        map(ProcessExecutionActionFactories.processExecutionLoaded));
function renewedProcessExecution(getProcessExecutionModel: IGetProcessExecutionModel): IGetProcessExecutionModel {
    // Keep in sync with the code from the service: ProcessExecutionService.RenewAsync
    delete getProcessExecutionModel.processExecution.approvedById;
    getProcessExecutionModel.processExecution.id = 0;
    getProcessExecutionModel.processExecution.startValidity = new Date(getProcessExecutionModel.processExecution.endValidity);
    getProcessExecutionModel.processExecution.endValidity.setFullYear(getProcessExecutionModel.processExecution.startValidity.getFullYear() + 1);
    getProcessExecutionModel.processExecution.initial = false
    for (const task of getProcessExecutionModel.processExecution.tasks) {
        task.status = IProcessTaskStatusModel.Open;
        switch (task.type) {
            case "DocumentProcessExecutionTaskModel":
                delete task.endValidity;
                delete task.fileMimeType;
                delete task.fileName;
                delete task.fileSubmissionDate;
                delete task.filledById;
                break;
            case "QuestionnaireProcessExecutionTaskModel":
                delete task.filledAt;
                delete task.error;
                delete task.filledById;
                delete task.result;
                break;
        }
    }
    return getProcessExecutionModel;
}
export const renewProcessExecution: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("processExecution", "processExecutionRenew"),
        mergeMap(payload => processExecutionsApi.processExecutionRenewAsync({ id: payload })),
        map(ProcessExecutionActionFactories.processExecutionLoaded));
export const saveProcessExecution: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("processExecution", "processExecutionSave"),
        withLatestFrom(action$.pipe(mapToPayload("processExecution", "processExecutionLoaded")), (processExecution, previousProcessExecution) => ({ processExecution, previousProcessExecution })),
        mergeMap(({ processExecution, previousProcessExecution }) => saveProcessAsync(processExecution, previousProcessExecution.processExecution)));

//     mergeMap(({ processExecution, previousProcessExecution }) => processExecutionsApi.saveAsync({ model: processExecution.execution })),
//     map(ProcessExecutionActionFactories.processExecutionSaved)
// );
// mergeMap(({ processExecution, previousProcessExecution }) => forkJoin(saveProcessAsync(processExecution, previousProcessExecution.processExecution))));
export const deleteProcessExecution: Epic<IAnyAction>
    = (action$) => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("processExecution", "processExecutionDelete"),
            switchMap(id => processExecutionsApi.deleteAsync({ id }).then(() => id)),
            map(ProcessExecutionActionFactories.processExecutionDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };


function saveProcessAsync({ execution: processExecution, files: processExecutionFiles, questionnaires: processExecutionQuestionnaire }: IProcessExecutionSavePayload, previousProcessExecution: ProcessExecutionModel | undefined): ObservableInput<IAnyAction> {
    return of(processExecution).pipe(
        mergeMap(pe => {
            // So that they are not validated on server
            let withoutTimestamps = { ...pe };
            withoutTimestamps.updatedById = undefined
            withoutTimestamps.updatedAt = undefined
            withoutTimestamps.createdAt = undefined
            return processExecutionsApi.saveAsync({ model: withoutTimestamps })
        }),
        mergeMap(pe => {
            if (!!previousProcessExecution?.approvedById !== !!processExecution.approvedById) {
                if (!!processExecution.approvedById) {
                    return processExecutionsApi.processExecutionApproveAsync({ id: pe.id }).then(() => pe);
                }
                else {
                    return processExecutionsApi.processExecutionDisapproveAsync({ id: pe.id }).then(() => pe);
                }
            }
            return of(pe);
        }),
        mergeMap(pe => {
            const file$ = from(Object.keys(processExecutionFiles)
                .map(taskCode => ({ taskCode, file: processExecutionFiles[taskCode] }))).pipe(share());
            const questionnaire$ = from(Object.keys(processExecutionQuestionnaire)
                .map(taskCode => ({ taskCode, questionnaire: processExecutionQuestionnaire[taskCode] }))).pipe(share());
            const savingFile$ = file$.pipe(
                map(({ taskCode }) => ProcessExecutionActionFactories.processExecutionSaveFile(taskCode)),
                share());
            const savedFile$ = file$.pipe(
                mergeMap(({ taskCode, file }) => file
                    ? processExecutionsApi.saveProcessExecutionFileAsync({ id: pe.id, taskCode, fileModel: { data: file.content, mimeType: file.mimeType, name: file.fileName } }).then(() => taskCode)
                    : processExecutionsApi.deleteProcessExecutionFileAsync({ id: pe.id, taskCode }).then(() => taskCode)),
                map(ProcessExecutionActionFactories.processExecutionSavedFile),
                share());
            const savingQuestionnaire$ = questionnaire$.pipe(
                map(({ taskCode }) => ProcessExecutionActionFactories.processExecutionSaveQuestionnaire(taskCode)),
                share());
            const savedQuestionnaire$ = questionnaire$.pipe(
                mergeMap(({ taskCode, questionnaire }) => questionnaire
                    ? processExecutionsApi.saveProcessExecutionQuestionnaireResponseAsync({ id: pe.id, taskCode, responses: questionnaire.content, complete: questionnaire.isCompleted }).then(() => taskCode)
                    : processExecutionsApi.deleteProcessExecutionQuestionnaireResponseAsync({ id: pe.id, taskCode }).then(() => taskCode)),
                map(ProcessExecutionActionFactories.processExecutionSavedQuestionnaire),
                share());
            return merge(
                savingFile$,
                savingQuestionnaire$,
                concat(
                    merge(savedFile$, savedQuestionnaire$),
                    of(pe.id).pipe(
                        mergeMap(id => processExecutionsApi.getAsync({ id })),
                        map(({ processExecution }) => ProcessExecutionActionFactories.processExecutionSaved(processExecution)))));
        })
    );
};
