import { getEnumLabels } from "tools/lib/utility";

export enum ClassificationTypeDomain {
    Investor = "Investor",
    RoleRelationship = "RoleRelationship",
    Security = "Security",
    Movement = "Movement",
    Entity = "Entity",
    Note = "Note",
    Process = "Process",
    DevelopmentItem = "DevelopmentItem",
}
export function getClassificationTypeDomainLabel(domain: ClassificationTypeDomain) {
    return getEnumLabels(ClassificationTypeDomain)[domain];
}
