import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, IconButton, Typography, Tabs, Tab } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Skeleton from '@material-ui/lab/Skeleton';

const useHeaderStyles = makeStyles(theme => createStyles({
    detailTitle: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(2),
    },
    tabContainer: {
        backgroundColor: theme.palette.primary.dark
    },
}));

export interface IHeaderTitleLoadingProps {
    tabNumber?: number;
    hasSubTitle?: boolean;
    onBackClick?: () => void;
}
function range(count: number) {
    const elements: number[] = [];
    for (let index = 0; index < count; index++) {
        elements.push(index + 1);
    }
    return elements;
}

export default function HeaderTitle({ tabNumber, hasSubTitle = true, onBackClick }: IHeaderTitleLoadingProps) {
    const classes = useHeaderStyles();
    const tabs = range(tabNumber ?? 0);
    return <Box display="flex" flexDirection="column">
        <Box className={classes.detailTitle} alignItems="center" display="flex">
            {(!!onBackClick) && <IconButton aria-label="Back" style={{ verticalAlign: "middle" }} onClick={onBackClick} >
                <ArrowBackIcon />
            </IconButton>}
            <Box>
                <Typography variant="h6" color="inherit" noWrap={true}>
                    <Skeleton width="200px" animation="wave" />
                </Typography>
                {hasSubTitle && <Typography variant="subtitle1" color="inherit" noWrap={true}>
                    <Skeleton width="150px" animation="wave" />
                </Typography>}
            </Box>
        </Box>
        {!!tabNumber && <Box display="flex"><Tabs style={{ flexGrow: 1 }}
            value="1"
            variant="scrollable"
            scrollButtons="auto"
            className={classes.tabContainer}>
            {tabs.map((idx) => (<Tab key={idx} value={idx.toString()} label={<Skeleton width="100px" animation="wave" />} />))}
        </Tabs><div style={{ width: 200 }} className={classes.tabContainer} /></Box>}
    </Box>;
}
