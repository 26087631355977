import * as React from "react";
import DialogPanel from "tools/components/DialogPanel";
import { IGitFile } from "tools/lib/octokitHelper";
import MuiDataGrid, { IColumnDefinitions, IRowAction } from "tools/components/MuiDataGrid";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IActionButton } from "tools/components/DialogPanel";
import ReactMarkdown from 'react-markdown';

export interface ICommunityReportTemplateSelectProps {
    onTemplateLoaded: (templateContent: string) => void;
    isOpened: boolean;
    onClose: () => void;
}
export default function CommunityReportTemplateSelect({ onClose,
    onTemplateLoaded,
    isOpened }: ICommunityReportTemplateSelectProps) {
    const { communityReportTemplates
        , communityReportTemplateSelected, communityReportTemplateAllLoading, communityReportTemplateLoading,
        communityReportTemplateLicense
    } = useReduxSelections("communityReportTemplate");
    const { communityReportTemplateLoad, communityReportTemplateLoadAll } = useReduxActions("communityReportTemplate")
    // const reportDictionary = reportDictionaries[templateType];
    const [showLicense, setShowLicense] = React.useState<IGitFile | undefined>();
    // eslint-disable-next-line
    React.useEffect(() => {
        if (onTemplateLoaded && communityReportTemplateSelected) {
            onTemplateLoaded(communityReportTemplateSelected);
        }
        // eslint-disable-next-line
    }, [communityReportTemplateSelected]); // Don't include onTemplateLoaded in dependencies... infinite render may occur
    // eslint-disable-next-line
    React.useEffect(() => {
        communityReportTemplateLoadAll();
        // eslint-disable-next-line
    }, [communityReportTemplateLoadAll]);
    const handleAcceptLicence = () => {
        if (showLicense) {
            setShowLicense(undefined);
            communityReportTemplateLoad(showLicense.path);
        }
    }
    const handleRefuseLicence = () => {
        if (showLicense) {
            setShowLicense(undefined);
        }
    }
    const columns: IColumnDefinitions<IGitFile> = {
        "Name": {
            sortable: true,
            getValue: (row) => row.name
        }
    };
    const actions: IRowAction<IGitFile>[] = [{
        content: "Load",
        onClick: setShowLicense
    }];
    const licenceActions: IActionButton[] = [{
        label: "Accept",
        onClick: handleAcceptLicence
    }];

    const getRowKey = (row: IGitFile) => row.path;
    return <DialogPanel
        isOpened={isOpened}
        isQuerying={communityReportTemplateAllLoading || communityReportTemplateLoading}
        title={`Get template from FundProcess repository`}
        onBackClick={onClose}>
        <DialogPanel isOpened={!!showLicense} actions={licenceActions} title="License of the report template" onBackClick={handleRefuseLicence}>
            <ReactMarkdown>{communityReportTemplateLicense ?? ""}</ReactMarkdown>
        </DialogPanel>
        <MuiDataGrid
            rows={communityReportTemplates}
            columns={columns}
            getRowKey={getRowKey}
            actions={actions}
            size="small" />
    </DialogPanel>;
}


// private handlePreviewDataRequest() {
//     const { reportDictionaryRequestDataLoad } = this.props;
//     reportDictionaryRequestDataLoad(true);
// }
