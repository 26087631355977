import DetailPanel from "tools/components/DetailPanel";
import HistoricalValue from "components/global/HistoricalValue";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { IFeatureModel } from "proxy/apiProxy";
export default function FxRate() {
    const {
        referenceCurrencies,
    } = useReduxSelections("reference");
    const { navigationNavigate } = useReduxActions("navigation");
    const {
        currentTargetCurrency,
        fxRatesHistoricalLoading,
        fxRatesHistoricalValuesVisualizationType
    } = useReduxSelections("fxRates");
    const {
        fxRatesHistoricalValuesVisualizationTypeChanged,
        fxRatesHistoricalValueSave
    } = useReduxActions("fxRates");
    const {
        parameters: { defaultCurrencyId }
    } = useReduxSelections("parameters");
    const isGranted = useGrants();
    const handleBack = () => navigationNavigate(undefined);
    const handleValueChanged = (date: Date, value: number | undefined) => {
        if (currentTargetCurrency) {
            fxRatesHistoricalValueSave({
                currencyToId: currentTargetCurrency.targetCurrency.id,
                fxValue: {
                    date,
                    rateFromReferenceCurrency: value
                }
            });
        }
    }
    if (!currentTargetCurrency || !referenceCurrencies) {
        return <DetailPanelLoading onBackClick={handleBack} hasSubTitle={true} />;
    }
    const { historicalValues, targetCurrency: { isoCode: targetIsoCode } } = currentTargetCurrency;
    return <DetailPanel
        isQuerying={fxRatesHistoricalLoading}
        title="Fx Rates"
        subTitle={`${referenceCurrencies[defaultCurrencyId ?? 0]?.isoCode} to ${targetIsoCode}`}
        onBackClick={handleBack}>
        <HistoricalValue
            historicalValues={historicalValues}
            onVisualizationTypeChanged={fxRatesHistoricalValuesVisualizationTypeChanged}
            visualizationType={fxRatesHistoricalValuesVisualizationType}
            editable={isGranted(IFeatureModel.MarketDataWrite)}
            onValueChanged={handleValueChanged}
        />
    </DetailPanel>;
}
