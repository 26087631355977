import DetailPanel from "tools/components/DetailPanel";
import { IFeatureModel, RegisterTransactionModel } from "proxy/apiProxy";
import { FormikProps, Formik, FormikHelpers } from 'formik';
import RegisterTransactionData from "./RegisterTransactionData";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { getRegisterTransactionTypeLabel } from "./getRegisterTransactionTypeLabel";

export default function PortfolioTransaction() {
    const {
        registerTransactionSave,
        registerTransactionAddSecurityInDictionary,
        registerTransactionAddRelationshipInDictionary,
        registerTransactionAddPortfolioInDictionary,
        registerTransactionDelete
    } = useReduxActions("registerTransaction");
    const { navigationNavigate } = useReduxActions("navigation");
    const { transactionCurrent, transactionLoading, transactionSaving, dictionaries } = useReduxSelections("registerTransaction");
    // : { counterpartyRelationships, entities, portfolios, securities }
    const { referenceCurrencies = {} } = useReduxSelections("reference");

    const handleBack = () => navigationNavigate(undefined);
    if (!transactionCurrent) {
        return <DetailPanelLoading onBackClick={handleBack} />;
    }

    const handleSubmit = (values: RegisterTransactionModel, { setSubmitting }: FormikHelpers<RegisterTransactionModel>) => {
        registerTransactionSave(values);
        setSubmitting(false);
    }
    const handleDelete = () => {
        if (transactionCurrent?.id) {
            registerTransactionDelete(transactionCurrent.id);
        }
    }
    return <Formik onSubmit={handleSubmit} initialValues={transactionCurrent} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;

    function renderForm(formikProps: FormikProps<RegisterTransactionModel>) {
        if (!transactionCurrent) {
            return;
        }
        const { dirty, isValid, submitForm, values } = formikProps;
        const { transactionCode } = values;

        return <WriteAccess value={{ feature: IFeatureModel.ManagedPortfolioWrite, portfolioId: transactionCurrent?.portfolioId ?? 0 }}>
            <DetailPanel
                isQuerying={transactionLoading || transactionSaving}
                title={`Register Transaction ${transactionCode}`}
                subTitle={getRegisterTransactionTypeLabel(values.type)}
                canSave={dirty && isValid}
                onSaveClick={submitForm}
                canDelete={!!values.id}
                saveAllowed={{ feature: IFeatureModel.ManagedPortfolioWrite, portfolioId: transactionCurrent?.portfolioId ?? 0 }}
                deleteAllowed={{ feature: IFeatureModel.ManagedPortfolioWrite, portfolioId: transactionCurrent?.portfolioId ?? 0 }}
                onDeleteClick={handleDelete}
                onBackClick={handleBack}>
                <RegisterTransactionData
                    currencies={referenceCurrencies}
                    transaction={values}
                    dictionaries={dictionaries}
                    onSecurityLoaded={registerTransactionAddSecurityInDictionary}
                    onRelationshipLoaded={registerTransactionAddRelationshipInDictionary}
                    onPortfolioLoaded={registerTransactionAddPortfolioInDictionary}
                />
            </DetailPanel></WriteAccess>
    }
}
