import { Epic } from "redux-observable";
import { map, switchMap } from "rxjs/operators";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, castNotNull } from "lib/rxJsUtility";
import {
    GetFilesAsync,
    GetContentAsync, IPmsRepository
} from "tools/lib/octokitHelper";
import { IMacroScriptTypeModel } from "proxy/apiProxy";

export const loadCommunityReports: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("communityMacro", "communityMacroLoadAll"),
        map(getMacroScriptRepoFolder),
        switchMap(i => GetFilesAsync(IPmsRepository.Macro, i)),
        map(macros => ActionFactories.communityMacro.communityMacroLoadedAll(macros.filter(macro => macro.name.endsWith(".csx")))));
export const loadLicense: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("communityMacro", "communityMacroLoadAll"),
        switchMap(i => GetContentAsync(IPmsRepository.Macro, "LICENSE")),
        castNotNull(),
        map(ActionFactories.communityMacro.communityMacroLicenseLoaded));

export const loadCommunityMacro: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("communityMacro", "communityMacroLoad"),
        switchMap(i => GetContentAsync(IPmsRepository.Macro, i)),
        castNotNull(),
        map(ActionFactories.communityMacro.communityMacroLoaded));


function getMacroScriptRepoFolder(macroScriptType: IMacroScriptTypeModel | "customScreen") {
    switch (macroScriptType) {
        case IMacroScriptTypeModel.DataProcessor: return "DataProcessor";
        case IMacroScriptTypeModel.FileProcessor: return "FileProcessor";
        case IMacroScriptTypeModel.FileRetriever: return "FileRetriever";
        case IMacroScriptTypeModel.Monitoring: return "Monitoring";
        case IMacroScriptTypeModel.MarketDataSelector: return "MarketDataSelector";
        case IMacroScriptTypeModel.SubMacro: return "SubMacro";
        case "customScreen": return "CustomScreen";
    }
}


// export const loadCommunityMacroScripts: Epic<IAnyAction>
//     = action$ => action$.pipe(
//         mapToPayload("macroScript", "macroScriptLoadForType"),
//         map(getMacroScriptRepoFolder),
//         switchMap(i => GetFilesAsync(IPmsRepository.Macro, i)),
//         castNotNull(),
//         map(ActionFactories.macroScript.macroScriptLoadedCommunityMacroScripts));
// export const loadCommunityMacroScript: Epic<IAnyAction>
//     = action$ => action$.pipe(
//         mapToPayload("macroScript", "macroScriptLoadCommunityMacroScript"),
//         switchMap(i => GetContentAsync(IPmsRepository.Macro, i)),
//         castNotNull(),
//         map(ActionFactories.macroScript.macroScriptLoadedCommunityMacroScript));
