import { Typography, Card, CardHeader, Chip, CardContent, Avatar, Grid, withStyles, createStyles, Tooltip, Box, IconButton, LinearProgress } from "@material-ui/core";
import FieldBox from "tools/components/FieldBox";
import SftpFolderIcon from "mdi-material-ui/FolderKeyNetworkOutline";
import FtpFolderIcon from "mdi-material-ui/FolderNetworkOutline";
import FileSystemFolderIcon from "mdi-material-ui/FolderOutline";
import DropboxIcon from "mdi-material-ui/Dropbox";
import ZipFolderIcon from "mdi-material-ui/FolderZipOutline";
import EmailIcon from '@material-ui/icons/Email';
import { IConnectorStates, IConnectorTestResult } from "reducers/Connector";
import PowerIcon from '@material-ui/icons/Power';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { green, red } from '@material-ui/core/colors';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import { parse as jsonParse } from "jsonc-parser";

interface IScript {
    [name: string]: IAdapter;
}
interface IAdapter {
    Type?: string;
    Connection?: any;
    Providers?: { [code: string]: IConnector };
    Processors?: { [code: string]: IConnector };
}
interface IConnector {
    Name?: string;
}
export interface IConnectorsVisualizationVisualizationProps {
    script: string;
    connectorStates: IConnectorStates;
    onProviderTestRequest: (code: string) => void;
    onProcessorTestRequest: (code: string) => void;
    testDisabled: boolean;
};

function parseScript(script: string): IScript | undefined {
    try {
        return jsonParse(script) as IScript;
    }
    catch {
        return;
    }
}

interface IAdapterVisualizationProps {
    adapter: IAdapter;
    connectorStates: IConnectorStates;
    onProviderTestRequest: (code: string) => void;
    onProcessorTestRequest: (code: string) => void;
    testDisabled: boolean;
}
function AdapterVisualization({ adapter, onProviderTestRequest, onProcessorTestRequest, connectorStates, testDisabled }: IAdapterVisualizationProps) {
    const processors = (!adapter.Processors) ? [] : Object.keys(adapter.Processors).map(code => ({ code, processor: (adapter.Processors ?? {})[code] }))
    const providers = (!adapter.Providers) ? [] : Object.keys(adapter.Providers).map(code => ({ code, provider: (adapter.Providers ?? {})[code] }))

    return <Grid container={true} spacing={1}>
        <Grid container={true} item={true} xs={6}>
            <Grid container={true} item={true} xs={12} direction="column" spacing={1}>
                {!!providers.length
                    ? providers.map(({ code, provider }) =>
                        <Grid key={code} item={true}>
                            <ConnectorVisualization testDisabled={testDisabled} connectorState={connectorStates.providers[code]} onTestRequested={onProviderTestRequest} code={code} connector={provider} type="provider" />
                        </Grid>)
                    : <Box display="flex" justifyContent="center"><Typography gutterBottom={true}>No file source defined</Typography></Box>}
            </Grid>
        </Grid>
        <Grid container={true} item={true} xs={6}>
            <Grid container={true} item={true} xs={12} direction="column" spacing={1}>
                {processors.length
                    ? processors.map(({ code, processor }) =>
                        <Grid key={code} item={true}>
                            <ConnectorVisualization testDisabled={testDisabled} connectorState={connectorStates.processors[code]} onTestRequested={onProcessorTestRequest} code={code} connector={processor} type="processor" />
                        </Grid>)
                    : <Box display="flex" justifyContent="center"><Typography gutterBottom={true}>No file destination defined</Typography></Box>}
            </Grid>
        </Grid>
    </Grid>;
}
interface IConnectorVisualizationProps {
    code: string;
    connector: IConnector;
    connectorState: IConnectorTestResult | undefined;
    onTestRequested: (code: string) => void;
    testDisabled: boolean;
    type: "provider" | "processor"
}

function ConnectorVisualization({ code, connector: { Name }, onTestRequested, testDisabled, connectorState: { message, success, testing } = {}, type }: IConnectorVisualizationProps) {
    const handleTestClick = () => onTestRequested(code);
    return <Card elevation={3}>
        {testing && <LinearProgress />}
        <CardHeader
            avatar={<Avatar>{type === "provider" ? <GetAppIcon /> : <PublishIcon />}</Avatar>}
            title={code}
            subheader={Name}
            action={<Tooltip title="Test connectivity"><IconButton disabled={testDisabled} onClick={handleTestClick} ><PowerIcon /> </IconButton></Tooltip >} />
        {(success === true || success === false) && <CardContent>
            {(success === true) && <Chip variant="outlined" icon={<ThumbUpIcon fontSize="small" style={{ color: green[500] }} />} label="Connector OK" />}
            {(success === false) && <><Chip variant="outlined" icon={<ThumbDownIcon fontSize="small" style={{ color: red[500] }} />} label="Connector Failed" /> <Typography paragraph={true} variant="caption" color="textSecondary" gutterBottom={true}>{message}</Typography></>}
        </CardContent>}
    </Card>;
}
function AdapterIcon({ adapterType }: { adapterType: string }) {
    switch (adapterType) {
        case "FileSystem":
            return <FileSystemFolderIcon />
        case "Ftp":
            return <FtpFolderIcon />
        case "Sftp":
            return <SftpFolderIcon />
        case "Mail":
            return <EmailIcon />
        case "Zip":
            return <ZipFolderIcon />
        case "Dropbox":
            return <DropboxIcon />
        default:
            return null;
    }
}
const StyledCardHeader = withStyles(theme =>
    createStyles({
        root: {
            backgroundColor: theme.palette.primary.light,
        }
    })
)(CardHeader);


export default function ConnectorsVisualizationVisualization({ script, onProviderTestRequest, onProcessorTestRequest, connectorStates, testDisabled }: IConnectorsVisualizationVisualizationProps) {
    const parsedScript = parseScript(script);
    if (!parsedScript) {
        return <Typography>Incorrect adapters</Typography>
    }
    return (<FieldBox display="flex" flexDirection="column">
        {Object.keys(parsedScript).filter(key => key !== "$schema").map(key => ({ name: key, adapter: parsedScript[key] })).map(({ name, adapter }, idx) =>
            <Card key={idx}>
                <StyledCardHeader title={name}
                    subheader={adapter?.Type}
                    // action={onTaskClick && <TaskAction idx={idx} onTaskClick={onTaskClick} />} 
                    avatar={<Avatar><AdapterIcon adapterType={adapter?.Type ?? ""} /></Avatar>} />
                <CardContent style={{ overflowX: "auto" }}>
                    <AdapterVisualization testDisabled={testDisabled} connectorStates={connectorStates} adapter={adapter} onProcessorTestRequest={onProcessorTestRequest} onProviderTestRequest={onProviderTestRequest} />
                </CardContent>
            </Card>
        )}
    </FieldBox>);
}
