import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import ReportTemplateCategories from "./ReportTemplateCategories";
import ReportTemplateCategory from "./ReportTemplateCategory";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class ReportTemplateCategoryScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                gridSize={9}
                gridSizeIfNotExact={6}
                exact={false}
                slideDirection={"right"}
                component={ReportTemplateCategories}
                path={getSiteScreenUrl("TemplateCategories")} />
            <NavigationRoute
                gridSize={6}
                slideDirection={"left"}
                component={ReportTemplateCategory}
                path={getSiteDetailScreenUrl("TemplateCategories")} />
        </>
    }
}

export default ReportTemplateCategoryScreen;
