import NavigationRoute from "components/global/NavigationRoute";
import Notes from "./Notes";
import Note from "./Note";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

export default function NoteScreen() {
    return <>
        <NavigationRoute
            gridSize={12}
            exact={true}
            slideDirection={"right"}
            component={Notes}
            path={getSiteScreenUrl("Notes")} />
        <NavigationRoute
            gridSize={12}
            slideDirection={"left"}
            component={Note}
            path={getSiteDetailScreenUrl("Notes")} />
    </>
}