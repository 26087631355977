import { parse as jsonParse } from "jsonc-parser";

export interface IScript {
    [name: string]: ITask;
}
export type ITask = ISimpleTask | IStreamTask;
export interface IStreamTask {
    Input?: ISteps;
    Pipeline?: ISteps;
}
export type ISteps = IStep | IStep[];
export interface ISimpleTask {
    Processor?: IStep;
}
export interface IStep {
    Name?: string,
    Type?: string
}
export function isSimpleTask(task: ITask): task is ISimpleTask {
    return !!(task as ISimpleTask).Processor;
}
export function isStreamTask(task: ITask): task is IStreamTask {
    return !!(task as IStreamTask).Input;
}
// https://developers.google.com/fonts/docs/getting_started

export function parseScript(script: string): IScript | undefined {
    try {
        return jsonParse(script) as IScript;
    }
    catch {
        return;
    }
}
