import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import ExternalTemplate from "./ExternalTemplate";
import { getSiteScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class ConnectorsScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                gridSize={12}
                slideDirection={"left"}
                component={ExternalTemplate}
                path={getSiteScreenUrl("ExternalTemplateEdition")} />
        </>;
    }
}

export default ConnectorsScreen;