import * as React from "react";
import { EntitySummaryModel, IEntityTypeModel } from "proxy/apiProxy";
import { useField } from 'formik';
import AsyncSelect from "tools/components/AsyncSelect";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IGetEntitySummary } from "reducers/Reference";
import { getEntityName } from "tools/lib/modelUtils";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { Card, CardContent, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';

export interface IEntitiesSelectFieldProps {
    name: string;
    entities: Record<EntitySummaryModel["id"], EntitySummaryModel>;
    label?: React.ReactNode;
    onEntityLoaded: (found: IGetEntitySummary) => void;
    type?: IEntityTypeModel;
}
export default function EntitiesSelectField({ name, label, onEntityLoaded, type: entityType, entities }: IEntitiesSelectFieldProps) {
    const [{ value: entityIds = [] }, , { setValue: setEntitiesId }] = useField<number[]>(name);
    const { referenceEntitySearch } = useReduxActions("reference");
    const { referenceEntitiesSearched, referenceEntitySearching } = useReduxSelections("reference");
    const getEntityOptionLabel = (entity: EntitySummaryModel) => getEntityName(entity) ?? "";
    const handleEntitySelected = (entity: EntitySummaryModel) => {
        if (entityIds.indexOf(entity.id) < 0) {
            const newEntityIds = [...entityIds, entity.id];
            setEntitiesId(newEntityIds);
            onEntityLoaded({ entity: entity, entities: referenceEntitiesSearched.entities });
        }
    }
    const handleSicavDelete = (idx: number) => {
        const newEntityIds = [...entityIds];
        newEntityIds.splice(idx, 1);
        setEntitiesId(newEntityIds);
    }
    const handleEntitySearch = (criteria: string) => referenceEntitySearch({ type: entityType, criterias: criteria });
    label = <><SearchIcon fontSize="small"/> {label ?? "Entity"}: Type criteria to find an entity</>;

    return <ReadOnlyContext.Consumer>{readOnly =>
        <Card>
            <CardContent>
                <List dense={true}>
                    {entityIds.map((id, idx) => <ListItem key={id}>
                        <ListItemText primary={getEntityOptionLabel(entities[id])} secondary="Entity" />
                        {!readOnly && <ListItemSecondaryAction>
                            <IconButton edge="end" onClick={handleSicavDelete.bind(null, idx)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>}
                    </ListItem>)}
                </List>
                {!readOnly && <AsyncSelect onSearchOptions={handleEntitySearch} options={referenceEntitiesSearched.all}
                    getOptionLabel={getEntityOptionLabel} searching={referenceEntitySearching} label={label ?? "Add Entity"}
                    onRequiredValueSelected={handleEntitySelected} helperText="Select an entity" />}
            </CardContent>
        </Card>}
    </ReadOnlyContext.Consumer>
}
