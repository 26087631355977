import * as React from "react";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, IconButton, Typography, Badge, Tabs, Tab } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ISubMenu } from "./DetailPanel";

const useStyles = makeStyles(theme => createStyles({
    detailTitle: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(1),
        gridColumnGap: theme.spacing(1),
        alignItems: "center",
        display: "flex"
    },
    subDetailTitle: {
        padding: 0,
        gridColumnGap: theme.spacing(2),
        alignItems: "baseline",
        display: "flex"
    },
    tabContainer: {
        backgroundColor: theme.palette.primary.dark,
        color:theme.palette.primary.contrastText
    },
}));

export interface IHeaderTitleProps {
    title: string;
    endAdornment?: React.ReactNode;
    badge?: string;
    subTitle?: string;
    onBackClick?: () => void;
    tabs?: ISubMenu[];
    tabValue?: any;
    spaceAfterTabs?: number;
    onTabValueChanged?: (value: any) => void;
    // actionButtons?: IFabContainerProps;
}

export default function HeaderTitle({ badge, title, subTitle, onBackClick, tabs, tabValue, onTabValueChanged, spaceAfterTabs, endAdornment }: IHeaderTitleProps) {
    const classes = useStyles();
    const handleTabChanged = (event: React.ChangeEvent<{}>, newValue: any) => {
        if (onTabValueChanged) {
            onTabValueChanged(newValue);
        }
    }
    let titleNode = !title ? null : <Typography variant="h6" color="inherit" noWrap={true}>{title}</Typography>;
    titleNode = !badge ? <>{titleNode}</> : <Badge badgeContent={badge} color="secondary">{titleNode}</Badge>;
    if (endAdornment)
        titleNode = <Box display="flex" alignItems="baseline" gridGap={8}>{titleNode}{endAdornment}</Box>
    return <Box display="flex" flexDirection="column">
        <Box className={classes.detailTitle} >
            {(!!onBackClick) && <IconButton onClick={onBackClick} >
                <ArrowBackIcon />
            </IconButton>}
            <Box className={classes.subDetailTitle}>
                {titleNode}
                {(!!subTitle) && <Typography variant="subtitle1" color="inherit" noWrap={true}>
                    {subTitle}
                </Typography>}
            </Box>
        </Box>
        {tabs && <Box display="flex"><Tabs style={{ flexGrow: 1, width: 1 }}
            value={tabValue}
            variant="scrollable"
            scrollButtons="auto"
            className={classes.tabContainer}
            onChange={handleTabChanged}>
            {tabs.map((tab, idx) => (<Tab key={idx} {...tab} />))}
        </Tabs>{!!spaceAfterTabs && <div style={{ width: spaceAfterTabs }} className={classes.tabContainer} />}</Box>}
    </Box>;
}
