import * as React from "react";
import { Box } from "@material-ui/core";
import { useCallback, useMemo } from "react";
import { DatumId, MouseEventHandler, ResponsivePie } from '@nivo/pie'
import { ValueFormat } from "@nivo/core";
import { formatDecimal, formatInteger, formatPercentage, formatPreciseDecimal, formatPrecisePercentage } from "tools/lib/utility";
import { NumericFieldType } from "./DashboardFormattingContracts";
// export type NumericFieldType =
//     "integer" |
//     "decimal" |
//     "decimal4" |
//     "percentage" |
//     "percentage2" |
//     "percentage4" |
//     "currency";
function getNumberTextValue(value: number | undefined, numberType: NumericFieldType): string {
    switch (numberType) {
        case NumericFieldType.Integer: return formatInteger(value);
        case NumericFieldType.Decimal: return formatDecimal(value);
        case NumericFieldType.Decimal4: return formatPreciseDecimal(value);
        case NumericFieldType.Percentage: return formatPercentage(value);
        case NumericFieldType.Percentage2: return formatPrecisePercentage(value);
        case NumericFieldType.Currency: return formatPrecisePercentage(value);
        case NumericFieldType.Percentage4: return formatPrecisePercentage(value, 4);
    }
}

// export type TimeFieldType =
//     "date" |
//     "datetime";
// function getDateTimeTextValue(value: Date | undefined, dateType: IMonitoringResultValueDateTypeModel): string {
//     switch (dateType) {
//         case IMonitoringResultValueDateTypeModel.Date: return formatDate(value);
//         case IMonitoringResultValueDateTypeModel.DateTime: return formatDateTime(value);
//     }
// }
interface IDashboardPieChartBaseProps {
    rows: any[];
    pivotColumnCode?: string;
    labelField?: string;
    fieldValueType?: NumericFieldType;
    onSelected: (rows: any[]) => void;
}
export interface IDashboardPieChartsProps extends IDashboardPieChartBaseProps {
    measureColumnCodes: string | string[];
}
export default function DashboardPieCharts({ measureColumnCodes, ...props }: IDashboardPieChartsProps) {
    const measures = useMemo(() => Array.isArray(measureColumnCodes) ? measureColumnCodes : [measureColumnCodes], [measureColumnCodes])
    return <Box id="coucou" display="flex" justifyContent="center" >
        {measures.map(measureColumnCode => <Box key={measureColumnCode} height={350} width={400} marginLeft={1} marginRight={1} overflow="visible" >
            <DashboardPieChart measureColumnCode={measureColumnCode} {...props} />
        </Box>)}
    </Box>
}

interface IDashboardPieChartProps extends IDashboardPieChartBaseProps {
    measureColumnCode: string;
}
function DashboardPieChart({ rows, pivotColumnCode, measureColumnCode, fieldValueType, labelField, onSelected }: IDashboardPieChartProps) {
    const getSliceLabel = useCallback((v: number) => getNumberTextValue(v, fieldValueType ?? NumericFieldType.Decimal), [fieldValueType]);
    const handleGetArgument = useCallback((row: any) => {
        if (!pivotColumnCode) {
            if (labelField)
                return row[labelField];
        }
        else {
            const pivotValue = row[pivotColumnCode];
            if (typeof (pivotValue) !== "undefined") {
                return String(pivotValue);
            }
        }
        return "";
    }, [pivotColumnCode, labelField]);
    const handleGetValue = useCallback((row: any) => {
        const v = row[measureColumnCode];
        if (typeof (v) === "number") {
            return v;
        }
        else if (typeof (v) !== "undefined") {
            return 1;
        }
        return 0;
    }, [measureColumnCode])
    return <SimplePieChart rows={rows} getArgument={handleGetArgument} getValue={handleGetValue} getSliceLabel={getSliceLabel} onRowsClick={onSelected} />
}



export interface ISimplePieChartArgs<T> {
    rows: T[];
    getValue: (row: T) => number;
    getArgument: (row: T) => string | undefined;
    getSliceLabel?: (value: number) => string;
    onRowsClick?: (rows: T[]) => void;
}
interface IGroupedValues<T> {
    value: number;
    group: string;
    items: T[];
}

function computeData<T>({ getValue, getArgument, rows }: ISimplePieChartArgs<T>) {
    const values = rows.reduce((acc, v) => {
        const argument = getArgument(v);
        if (!argument) {
            return acc;
        }
        if (!acc[argument]) {
            acc[argument] = {
                value: getValue(v),
                group: argument,
                items: [v]
            };
        }
        else {
            acc[argument].value += getValue(v);
            acc[argument].items.push(v);
        }
        return acc;
    }, {} as Record<string, IGroupedValues<T>>);
    return values;
}
function SimplePieChart<T>({ getValue, getArgument, rows, getSliceLabel, onRowsClick }: ISimplePieChartArgs<T>) {
    const valueFormat: ValueFormat<number> = React.useCallback(v => getSliceLabel ? getSliceLabel(v) : String(v), [getSliceLabel]);
    const chartData = React.useMemo(() => {
        const values = computeData({ getValue, getArgument, rows });
        return Object.values(values);
    }, [getArgument, getValue, rows]);
    const [activeId, setActiveId] = React.useState<DatumId | null>(null);
    const handleOnClick: MouseEventHandler<IGroupedValues<T>, SVGPathElement> = React.useCallback((i, j) => {
        if (activeId === i.data.group) {
            setActiveId(null);
            onRowsClick && onRowsClick([]);
        }
        else {
            setActiveId(i.data.group);
            const selection = rows.filter(r => getArgument(r) === i.data.group);
            onRowsClick && onRowsClick(selection);
        }
    }, [activeId, getArgument, onRowsClick, rows]);
    const handleActiveIdChanged = (id: DatumId | null) => { };
    const getRadialId = React.useCallback(({ group }: IGroupedValues<T>) => group, []);
    return <ResponsivePie<IGroupedValues<T>>
        data={chartData}
        activeId={activeId}
        onActiveIdChange={handleActiveIdChanged}
        activeOuterRadiusOffset={8}
        activeInnerRadiusOffset={8}
        onClick={handleOnClick}
        margin={{ top: 16, right: 60, bottom: 16, left: 60 }}
        fit={true}
        sortByValue={true}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={{ scheme: "nivo" }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsDiagonalLength={5}
        arcLinkLabelsStraightLength={5}
        arcLinkLabelsTextOffset={6}
        arcLinkLabelsTextColor="#333333"
        arcLabelsSkipAngle={10}
        valueFormat={valueFormat}
        id={getRadialId} />;
}
