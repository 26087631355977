import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import { IFeatureModel, IStatisticDefinitionSetModel } from "proxy/apiProxy";
import { FormikProps, Formik, FormikHelpers } from 'formik';
import StatisticDefinitionSetData from "./StatisticDefinitionSetData";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import StatisticDefinitionSetDefinition from "./StatisticDefinitionSetDefinition";
import WriteAccess from "tools/fieldComponents/WriteAccess";

export default function StatisticDefinitionSet() {
    const { statisticDefinitionSetSave, statisticDefinitionSetDelete } = useReduxActions("statisticDefinitionSet");
    const { navigationNavigate } = useReduxActions("navigation");
    const { statisticDefinitionSetCurrent, statisticDefinitionSetLoading, statisticDefinitionSetScriptSchema } = useReduxSelections("statisticDefinitionSet");
    const { navigationActiveScreen: navigationActiveUrlNode } = useReduxSelections("navigation");

    const handleBack = () => navigationNavigate(undefined);

    if (!statisticDefinitionSetCurrent) {
        return <DetailPanelLoading onBackClick={handleBack} hasSubTitle={false} />;
    }

    const handleDelete = () => {
        const { id } = statisticDefinitionSetCurrent;
        if (id) {
            statisticDefinitionSetDelete(id);
        }
    }
    const handleSubmit = (values: IStatisticDefinitionSetModel, { setSubmitting }: FormikHelpers<IStatisticDefinitionSetModel>) => {
        statisticDefinitionSetSave(values);
        setSubmitting(false);
    }

    const handleTabValueChanged = (tabValue: any) => navigationNavigate({ sectionKey: tabValue as string });

    return <Formik onSubmit={handleSubmit} initialValues={statisticDefinitionSetCurrent} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;

    function renderForm(formikProps: FormikProps<IStatisticDefinitionSetModel>) {
        if (!statisticDefinitionSetCurrent) {
            return;
        }
        const tabs = [{
            value: "detail",
            label: "Data"
        }, {
            value: "definition",
            label: "Definition"
        }] as ISubMenu[];
        const tabValue = navigationActiveUrlNode?.activeSectionKey ?? "detail";
        const { dirty, isValid, submitForm, values: { id, name } } = formikProps;

        const title = !!id ? (name) : 'New StatisticDefinitionSet';

        return <WriteAccess value={IFeatureModel.StatisticDefinitionSetWrite}>
            <DetailPanel
                isQuerying={statisticDefinitionSetLoading}
                title={title}
                tabs={tabs}
                tabValue={tabValue}
                noPadding={tabValue === "definition"}
                onTabValueChanged={handleTabValueChanged}
                canSave={dirty && isValid}
                onSaveClick={submitForm}
                canDelete={!!id}
                saveAllowed={IFeatureModel.StatisticDefinitionSetWrite}
                deleteAllowed={IFeatureModel.StatisticDefinitionSetWrite}
                onDeleteClick={handleDelete}
                onBackClick={handleBack}
                badge={!id ? "new" : undefined}>
                {tabValue === "detail" && <StatisticDefinitionSetData />}
                {tabValue === "definition" && <StatisticDefinitionSetDefinition jsonSchema={statisticDefinitionSetScriptSchema} onSaveRequest={submitForm} />}
            </DetailPanel></WriteAccess>
    }
}
