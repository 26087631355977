import DetailPanel from "tools/components/DetailPanel";
import { IFeatureModel, IReportTemplateCategoryModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import FieldBox from "tools/components/FieldBox";
import { oProps } from "tools/lib/utility";
import FormTextField from "tools/fieldComponents/FormTextField";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";

export default function ReportTemplateCategory() {
    const { navigationNavigate } = useReduxActions("navigation");

    const { reportTemplateCategoryCurrent, reportTemplateCategoryLoading, reportTemplateCategorySaving } = useReduxSelections("reportTemplateCategory");
    const { reportTemplateCategoryDelete, reportTemplateCategorySave } = useReduxActions("reportTemplateCategory");
    const handleBack = () => navigationNavigate(undefined);
    // const { reportTemplateCategoryCurrent, dirty, isValid, submitForm, reportTemplateCategoryLoading } = this.props;
    if (!reportTemplateCategoryCurrent) {
        return <DetailPanelLoading onBackClick={handleBack} hasSubTitle={false} />;
    }
    const handleDelete = () => {
        if (reportTemplateCategoryCurrent?.id) {
            reportTemplateCategoryDelete(reportTemplateCategoryCurrent.id);
        }
    }

    const handleSubmit = (values: IReportTemplateCategoryModel, { setSubmitting }: FormikHelpers<IReportTemplateCategoryModel>) => {
        reportTemplateCategorySave(values);
        setSubmitting(false);
    }

    return <Formik onSubmit={handleSubmit} initialValues={reportTemplateCategoryCurrent} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;

    function renderForm(formikProps: FormikProps<IReportTemplateCategoryModel>) {
        if (!reportTemplateCategoryCurrent) {
            return;
        }
        const { dirty, isValid, submitForm, values: { id, name } } = formikProps;

        const title = !!id ? name : 'New report Template Category';

        return <WriteAccess value={IFeatureModel.ReportTemplateWrite}>
            <DetailPanel
                isQuerying={reportTemplateCategoryLoading || reportTemplateCategorySaving}
                title={title}
                canSave={dirty && isValid}
                onSaveClick={submitForm}
                canDelete={!!id}
                onDeleteClick={handleDelete}
                onBackClick={handleBack}
                saveAllowed={IFeatureModel.ReportTemplateWrite}
                deleteAllowed={IFeatureModel.ReportTemplateWrite}
                badge={!id ? "new" : undefined}>
                <FieldBox display="flex" flexDirection="column">
                    <FormTextField name={oProps<IReportTemplateCategoryModel>().path("name")} label="Name" required={true} maxLength={250} />
                </FieldBox>
            </DetailPanel>
        </WriteAccess>
    }
}
