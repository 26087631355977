import { IconButton, Paper } from "@material-ui/core";
import { DocumentElementModel, INoteModel, IParagraphDocumentElementModel } from "proxy/apiProxy";
import { isDescendant, NodeRendererProps } from 'react-sortable-tree';// import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import classnames from "classnames";
import styled from '@emotion/styled';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
// import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
// import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import NoteText from "./NoteText";
import { ITreeItem } from "./ITreeItem";
import TextFieldsIcon from '@material-ui/icons/TextFields';

const NodeContainer = styled(Paper)({
    padding: 10,
});

export interface INoteNodeRendererProps extends NodeRendererProps {
    notes: Record<string, INoteModel>;
    onTitleChange: (paragraph: IParagraphDocumentElementModel) => void;
    onDelete: (documentElement: DocumentElementModel, parentElement: IParagraphDocumentElementModel) => void;
    readOnly: boolean;
}
export function NoteNodeRenderer(props: INoteNodeRendererProps) {
    const {
        scaffoldBlockPxWidth,
        toggleChildrenVisibility,
        connectDragPreview,
        connectDragSource,
        isDragging,
        canDrop,
        canDrag,
        node,
        title,
        subtitle,
        draggedNode,
        path,
        treeIndex,
        isSearchMatch,
        isSearchFocus,
        buttons,
        className,
        style,
        didDrop,
        treeId,
        isOver, // Not needed, but preserved for other renderers
        parentNode, // Needed for dndManager
        rowDirection,
        notes,
        onTitleChange,
        onDelete,
        readOnly,
        ...otherProps
    } = props;
    const documentNodeContent = (node as ITreeItem).row as DocumentElementModel;
    const documentParentNodeContent=(node as ITreeItem).parentNode?.row as IParagraphDocumentElementModel;
    if (!documentNodeContent) return null;
    let handle = null;
    if (canDrag) {
        if (typeof node.children === 'function' && node.expanded) {
            // Show a loading symbol on the handle when the children are expanded
            //  and yet still defined by a function (a callback to fetch the children)
            handle = <div className="rst__loadingHandle">
                <div className="rst__loadingCircle">
                    {[...new Array(12)].map((_, index) => <div key={index} className={classnames('rst__loadingCirclePoint')} />)}
                </div>
            </div>
        } else {
            // Show the handle used to initiate a drag-and-drop
            handle = connectDragSource(<div style={{ cursor: "move", color: "lightgray", zIndex: 99999 }}><DragIndicatorIcon /></div>, { dropEffect: 'copy' });
        }
    }

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
    const isLandingPadActive = !didDrop && isDragging;
    const handleTitleChanged = () => {
        if (documentNodeContent.type === "ParagraphDocumentElementModel") {
            onTitleChange(documentNodeContent);
        }
    }
    const handleDelete = () => {
        onDelete(documentNodeContent, documentParentNodeContent);
    }
    return <div style={{ height: '100%', paddingTop: 10 }} {...otherProps}>
        {toggleChildrenVisibility && node.children && (node.children.length > 0 || typeof node.children === 'function') && (
            <div>
                <button
                    type="button"
                    aria-label={node.expanded ? 'Collapse' : 'Expand'}
                    className={classnames(node.expanded ? 'rst__collapseButton' : 'rst__expandButton')}
                    style={{ left: -0.5 * scaffoldBlockPxWidth }}
                    onClick={() => toggleChildrenVisibility({ node, path, treeIndex, })} />
                {node.expanded && !isDragging && <div style={{ width: scaffoldBlockPxWidth }} className={classnames('rst__lineChildren')} />}
            </div>)}
        <NodeContainer elevation={2}>
            {connectDragPreview(<div
                className={classnames(
                    'rst__row',
                    isLandingPadActive && 'rst__rowLandingPad',
                    isLandingPadActive && !canDrop && 'rst__rowCancelPad',
                    isSearchMatch && 'rst__rowSearchMatch',
                    isSearchFocus && 'rst__rowSearchFocus',
                    className
                )}
                style={{ opacity: isDraggedDescendant ? 0.5 : 1, alignItems: "baseline", gap: 5, ...style }}>
                {handle}
                {documentNodeContent.type === "ParagraphDocumentElementModel" && <><TextFieldsIcon fontSize="small" />{documentNodeContent.title.en}</>}
                {documentNodeContent.type === "NoteDocumentElementModel" && <NoteText note={notes[documentNodeContent.noteCode]} />}
                {(!readOnly) && <>
                    {documentNodeContent.type === "ParagraphDocumentElementModel" && <IconButton size="small" onClick={handleTitleChanged}><EditIcon fontSize="small" /></IconButton>}
                    <IconButton size="small" onClick={handleDelete}><CloseIcon fontSize="small" /></IconButton>
                </>}
            </div>)}
        </NodeContainer>
    </div>
}
export function wrapNoteNodeRenderer(notes: Record<string, INoteModel>, onTitleChanging: (paragraph: IParagraphDocumentElementModel) => void, onDelete: (row: DocumentElementModel,parentRow:IParagraphDocumentElementModel) => void, readOnly: boolean) {
    return function (props: NodeRendererProps) {
        return <NoteNodeRenderer {...props} onTitleChange={onTitleChanging} notes={notes} onDelete={onDelete} readOnly={readOnly} />
    }
}

