import * as React from "react";
import DialogPanel from "tools/components/DialogPanel";
import { IGitFile } from "tools/lib/octokitHelper";
import MuiDataGrid, { IColumnDefinitions, IRowAction } from "tools/components/MuiDataGrid";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IMacroScriptTypeModel } from "proxy/apiProxy";
import { IActionButton } from "tools/components/DialogPanel";
import ReactMarkdown from 'react-markdown';
export interface ICommunityMacroSelectProps {
    onMacroLoaded: (macroContent: string) => void;
    isOpened: boolean;
    macroType: IMacroScriptTypeModel|"customScreen";
    onClose: () => void;
}
export default function CommunityMacroSelect({ onClose,
    onMacroLoaded,
    isOpened, macroType }: ICommunityMacroSelectProps) {
    const { communityMacros
        , communityMacroSelected, communityMacroAllLoading, communityMacroLoading,
        communityMacroLicense
    } = useReduxSelections("communityMacro");
    const { communityMacroLoad, communityMacroLoadAll } = useReduxActions("communityMacro");
    // const reportDictionary = reportDictionaries[macroType];
    const [showLicense, setShowLicense] = React.useState<IGitFile | undefined>();
    React.useEffect(() => {
        if (onMacroLoaded && communityMacroSelected) {
            onMacroLoaded(communityMacroSelected);
        }
        // eslint-disable-next-line
    }, [communityMacroSelected]); // Don't include onMacroLoaded in dependencies... infinite render may occur
    React.useEffect(() => {
        communityMacroLoadAll(macroType);
    }, [communityMacroLoadAll, macroType]);
    const handleAcceptLicence = () => {
        if (showLicense) {
            setShowLicense(undefined);
            communityMacroLoad(showLicense.path);
        }
    }
    const handleRefuseLicence = () => {
        if (showLicense) {
            setShowLicense(undefined);
        }
    }
    const columns: IColumnDefinitions<IGitFile> = {
        "Name": {
            sortable: true,
            getValue: (row) => row.name
        }
    };
    const actions: IRowAction<IGitFile>[] = [{
        content: "Load",
        onClick: setShowLicense
    }];
    const licenceActions: IActionButton[] = [{
        label: "Accept",
        onClick: handleAcceptLicence
    }];


    const getRowKey = (row: IGitFile) => row.path;
    return <DialogPanel
        isOpened={isOpened}
        isQuerying={communityMacroAllLoading || communityMacroLoading}
        title={`Get macro from FundProcess repository`}
        onBackClick={onClose}>
        <DialogPanel isOpened={!!showLicense} actions={licenceActions} title="License of the macro" onBackClick={handleRefuseLicence}>
            <ReactMarkdown>{communityMacroLicense ?? ""}</ReactMarkdown>
        </DialogPanel>
        <MuiDataGrid
            rows={communityMacros}
            columns={columns}
            getRowKey={getRowKey}
            actions={actions}
            size="small" />
    </DialogPanel>;
}
