import NavigationRoute from "components/global/NavigationRoute";
import { getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";
import TenantManager from "./TenantManager";

export default function TenantManagerScreen() {
    return <>
        <NavigationRoute
            gridSize={12}
            slideDirection={"left"}
            component={TenantManager}
            path={getSiteDetailScreenUrl("TenantManagers")} />
    </>
}
