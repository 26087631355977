import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, share, mergeMap } from "rxjs/operators";
import { macroScriptsApi, monitoringMacroCallsApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, changedNavigation } from "lib/rxJsUtility";
import { IGetMonitoringMacroCallModel, IScopeDescriptionModel, IMacroScriptTypeModel } from "proxy/apiProxy";
import { tryParseNumber } from "tools/lib/utility";
import { filterRoute } from "tools/lib/UrlDictionary";

export const onSelectMonitoringMacro: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("monitoringMacroCall", "monitoringMacroCallSelectMonitoringMacro"),
        map(ActionFactories.monitoringMacroCall.monitoringMacroCallMetadataSearchLoad));
export const onMonitoringMacroCallTemplateGetMetadata: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("monitoringMacroCall", "monitoringMacroCallMetadataSearchLoad"),
        mergeMap(id => macroScriptsApi.getSearchMetadataAsync({ id })),
        map(ActionFactories.monitoringMacroCall.monitoringMacroCallMetadataSearchLoaded));
export const onOpenScreenMonitoringMacroCalls: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("MacroMonitoringCalls"),
        map(() => ActionFactories.monitoringMacroCall.monitoringMacroCallLoadAll()));
export const onOpenScreenMonitoringMacroCall: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("MacroMonitoringCalls", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? 0),
        map(monitoringMacroCallId => ActionFactories.monitoringMacroCall.monitoringMacroCallLoad(monitoringMacroCallId as number)));
export const loadMonitoringMacroCalls: Epic<IAnyAction>
    = (action$) =>
        action$.pipe(
            mapToPayload("monitoringMacroCall", "monitoringMacroCallLoadAll"),
            mergeMap(() => monitoringMacroCallsApi.getAllAsync({})),
            map(ActionFactories.monitoringMacroCall.monitoringMacroCallLoadedAll));
export const loadMonitoringMacroCall: Epic<IAnyAction>
    = (action$) => {
        const requestedId$ = action$.pipe(
            mapToPayload("monitoringMacroCall", "monitoringMacroCallLoad"),
            share()
        );
        const monitoringMacroCallLoaded$ = merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => monitoringMacroCallsApi.getAsync({ id }))),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({
                    macro: {
                        id: 0,
                        code: "",
                        macroScriptId: 0,
                        name: "",
                        parameterValues: {},
                        scopeDescription: {
                            subScopes: {},
                            extraParameters: {},
                            groupLabelExpression: "{group}"
                        } as IScopeDescriptionModel,
                    },
                    macroScripts: {}
                } as IGetMonitoringMacroCallModel)))).pipe(share());

        const templateSearchMetadata$ = monitoringMacroCallLoaded$.pipe(
            map(r => r.macro.macroScriptId),
            filter(r => !!r),
            map(i => ActionFactories.monitoringMacroCall.monitoringMacroCallMetadataSearchLoad(i as number)));
        const monitoringMacroCallTemplates$ = requestedId$.pipe(
            mergeMap(() => macroScriptsApi.getAllByTypeAsync({ type: IMacroScriptTypeModel.Monitoring })),
            map(ActionFactories.monitoringMacroCall.monitoringMacroCallMonitoringMacroLoadedAll));
        return merge(
            monitoringMacroCallLoaded$.pipe(map(ActionFactories.monitoringMacroCall.monitoringMacroCallLoaded)),
            monitoringMacroCallTemplates$,
            templateSearchMetadata$);
    };
export const saveMonitoringMacroCall: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("monitoringMacroCall", "monitoringMacroCallSave"),
        mergeMap(model => monitoringMacroCallsApi.saveAsync({ model })),
        map(ActionFactories.monitoringMacroCall.monitoringMacroCallSaved));
export const deleteMonitoringMacroCall: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("monitoringMacroCall", "monitoringMacroCallDelete"),
            mergeMap(id => monitoringMacroCallsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.monitoringMacroCall.monitoringMacroCallDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };
