import * as React from "react";
import autobind from "autobind-decorator";
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, TableSortLabel, Tooltip } from "@material-ui/core";
import { Size } from "@material-ui/core/Table";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuDropdown, { IMenuItemDropdown } from "./MenuDropdown";

export interface IRowAction<TRow> {
    onClick: (row: TRow, index: number) => void;
    icon?: React.ReactElement<any>;
    content: React.ReactNode;
}
export interface IColumnDefinitions<TRow = any> {
    [name: string]: IColumnDefinition<TRow>;
}
export interface IColumnDefinition<TRow = any> {
    label?: string;
    getValue: (row: TRow, index: number) => any;
    contentRenderer?: (value: any, row: TRow, index: number) => React.ReactNode;
    headerRenderer?: (row: TRow) => React.ReactNode;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    sortable?: boolean;
    customSortComparer?: IComparer<TRow>;
}
export type GetMuiDataGridActionsFunction<TRow> = (row: TRow, idx: number) => IRowAction<TRow>[] | undefined;
export type IMuiDataGridActions<TRow> = IRowAction<TRow>[] | GetMuiDataGridActionsFunction<TRow>;
export type IconGetter<TRow> = (row: TRow, index: number) => React.ReactElement | undefined;
export type IconReference<TRow> = IconGetter<TRow> | React.ReactElement | undefined;
export interface IDataGridProps<TRow = any> {
    getRowKey?: (row: TRow, index: number) => React.Key;
    rows: TRow[];
    columns: IColumnDefinitions<TRow>;
    actions?: IMuiDataGridActions<TRow>;
    multipleActionsIcon?: IconReference<TRow>;
    size?: Size;
    actionsDisabled?: boolean;
}
interface INamedColumnDefinition<TRow = any> extends IColumnDefinition<TRow> {
    name: string;
}
export type IComparer<TRow = any> = (left: TRow, right: TRow) => number;
type IProps<TRow> = IDataGridProps<TRow>;
interface IState {
    sort: {
        [columnName: string]: number;
    }
}
@autobind
class DataGrid<TRow = any> extends React.Component<IProps<TRow>, IState> {
    constructor(props: IProps<TRow>) {
        super(props);
        this.state = { sort: {} };
    }
    public render() {
        const { columns, getRowKey = (row: TRow, idx: number) => idx, actions, size } = this.props;
        const { sort } = this.state;

        const columnsArray: INamedColumnDefinition<TRow>[] = Object.keys(columns).map(name => ({ ...columns[name], name }));
        return (<Table stickyHeader={true} size={size}>
            <TableHead>
                <TableRow>
                    {actions && <TableCell style={{ width: 60 }} />}
                    {columnsArray.map((columnDefinition, columnIndex) => {
                        const isSortActive = !!sort[columnDefinition.name] && Math.abs(sort[columnDefinition.name]) === 1;
                        const sortDirection = this.getSortDirection(columnDefinition.name, isSortActive);

                        return (<TableCell style={{ position: "sticky", top: 0, backgroundColor: "white" }} key={columnDefinition.name} align={columnDefinition.align} sortDirection={sortDirection}>
                            {(!!columnDefinition.sortable) && <TableSortLabel onClick={this.handleColumnSortClick.bind(null, columnDefinition)} active={isSortActive} direction={sortDirection}>{columnDefinition.label || columnDefinition.name}</TableSortLabel>}
                            {(!columnDefinition.sortable) && <>{columnDefinition.label || columnDefinition.name}</>}
                        </TableCell>);
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {this.getSortedRows().map((row, index) => (
                    <TableRow key={getRowKey(row, index)}>
                        {this.renderActionCell(row, index)}
                        {columnsArray.map((columnDefinition, columnIndex) =>
                            <TableCell key={columnDefinition.name} align={columnDefinition.align}>
                                {this.renderCellContent(columnDefinition, row, index)}
                            </TableCell>
                        )}
                    </TableRow>
                ))}
            </TableBody>
        </Table>);
    }
    private renderCellContent(columnDefinition: INamedColumnDefinition, row: TRow, index: number) {
        const value = columnDefinition.getValue(row, index);
        if (columnDefinition.contentRenderer) {
            return columnDefinition.contentRenderer(value, row, index);
        }
        else {
            return value;
        }
    }
    private renderActionCell(row: TRow, idx: number) {
        const actions = this.getRowActionsCell(row, idx);
        const { multipleActionsIcon, actionsDisabled } = this.props;

        if (!actions || actions.length === 0) {
            return;
        }
        if (actions.length === 1) {
            const action = actions[0];
            return <TableCell align="right">
                <Tooltip title={action.content || ""}>
                    <IconButton
                        size="small"
                        style={{ verticalAlign: "middle" }}
                        onClick={action.onClick.bind(null, row as TRow, 0)} >
                        {action.icon || <MoreVertIcon fontSize="small" />}
                    </IconButton>
                </Tooltip>
            </TableCell>
        }
        function isIconGetter(icon: IconReference<TRow>): icon is IconGetter<TRow> {
            return typeof icon === "function";
        }
        const mIcon = multipleActionsIcon ? isIconGetter(multipleActionsIcon) ? multipleActionsIcon(row, idx) : multipleActionsIcon : undefined;
        return <TableCell align="right">
            <MenuDropdown size="small" icon={mIcon || <MoreVertIcon fontSize="small" />} disabled={!!actionsDisabled} items={actions.map(action => ({
                content: action.content,
                icon: action.icon,
                onClick: () => action.onClick(row, idx)
            } as IMenuItemDropdown))} />
        </TableCell>;
    }
    private getRowActionsCell(row: TRow, idx: number) {
        const { actions } = this.props;
        if (!actions) {
            return;
        }
        if (typeof actions === "function") {
            return (actions as GetMuiDataGridActionsFunction<TRow>)(row, idx);
        }
        return actions;
    }
    private handleColumnSortClick(columnDefinition: INamedColumnDefinition<TRow>) {
        const { sort } = this.state;
        Object.keys(sort)
            .filter(key => sort[key] && key !== columnDefinition.name)
            .sort((l, r) => Math.abs(sort[l]) - Math.abs(sort[r]))
            .forEach(setSortOrder);

        if (Math.abs(sort[columnDefinition.name]) !== 1) {
            sort[columnDefinition.name] = 1;
        }
        else {
            sort[columnDefinition.name] = -sort[columnDefinition.name];
        }
        function setSortOrder(key: string, idx: number) {
            idx += 2;
            if (sort[key] > 0) {
                sort[key] = idx;
            }
            else {
                sort[key] = -idx;
            }
        }
        this.setState({ sort });
    }

    private getSortedRows(): TRow[] {
        const { rows } = this.props;
        const comparer = this.getComparer();
        if (!comparer) {
            return rows;
        }
        // TODO: consider managing this in the state for the sort not to be redone at any render!!!
        return rows.sort(comparer);
    }

    private getComparer(): IComparer<TRow> | undefined {
        const { sort } = this.state;
        const { columns } = this.props;

        const comparers = Object
            .keys(sort)
            .map(k => ({ direction: sort[k], column: columns[k] }))
            .filter(i => !!i.direction)
            .sort((l, r) => Math.abs(l.direction) - Math.abs(r.direction))
            .map(getSimpleComparer);

        if (!comparers.length) {
            return;
        }

        return (left: TRow, right: TRow): number => {
            for (const compare of comparers) {
                const ret = compare(left, right);
                if (ret) {
                    return ret;
                }
            }
            return 0;
        }
        function getSimpleComparer(def: { direction: number, column: IColumnDefinition<TRow> }): IComparer<TRow> {
            const customSortComparer = def.column.customSortComparer;
            const direction = def.direction > 0 ? 1 : -1;
            if (customSortComparer) {
                return (left: TRow, right: TRow) => customSortComparer(left, right) * direction;
            }
            else {
                const getValue = def.column.getValue;
                return (left: TRow, right: TRow) => {
                    const leftValue = getValue(left, 0);
                    const rightValue = getValue(right, 0);
                    if (!leftValue && !rightValue) {
                        return 0;
                    }
                    else if (leftValue && !rightValue) {
                        return direction;
                    }
                    else if (!leftValue && rightValue) {
                        return -direction;
                    }
                    else {
                        return leftValue === rightValue ? 0 : leftValue > rightValue ? direction : -direction;
                    }
                };
            }
        }
    }

    private getSortDirection(columnName: string, reverse: boolean): 'asc' | 'desc' | undefined {
        const { sort } = this.state;

        const direction = sort[columnName];
        if (!direction || Math.abs(direction) > 1) {
            return;
        }

        if (reverse) {
            return (direction > 0) ? 'desc' : 'asc';
        }
        else {
            return (direction > 0) ? 'asc' : 'desc';
        }
    }
}

export default DataGrid;
