import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import PortfolioHistoricalValues from "./PortfolioHistoricalValues";
import { getSiteScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class PortfolioHistoricalValueScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute slideDirection={"right"} component={PortfolioHistoricalValues} path={getSiteScreenUrl("HistoricalValues")} />
        </>
    }
}
export default PortfolioHistoricalValueScreen;
