import produce from "immer";
// import * as UserDataAccess from "dataAccess/User";
import { EntitySummaryModel, IGetUserTracesModel, ITracesSearchParameters, IUserTraceModel } from "proxy/apiProxy"
import { produceActionFactories, AnyActionOf } from "tools/lib/store";

export const ActionFactories = produceActionFactories({
    tracesSearch: (payload: ITracesSearchParameters) => payload,
    tracesSearched: (payload: IGetUserTracesModel) => payload,
});

export interface IState {
    searching: boolean;
    all: IUserTraceModel[];
    dictionaries: {
        entities: Record<number, EntitySummaryModel>;
    }
}

export function reducer(
    state: IState = {
        all: [],
        searching: false,
        dictionaries: {
            entities: {}
        }
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "tracesSearch":
                draft.searching = true;
                break;
            case "tracesSearched":
                draft.searching = false;
                const { traces, ...dictionaries } = action.payload;
                draft.all = traces;
                draft.dictionaries = dictionaries;
                break;
        }
    });
}
