import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { ICouponTypeModel, ILoanModel, IFeatureModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { getEnumLabels } from "tools/lib/utility";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import LoanData from "./LoanData";
import Collaterals from "./Collaterals";
import Covenants from "./Covenants";

export const couponTypes = getEnumLabels(ICouponTypeModel);
export default function Loan() {
    //@ts-ignore
    const { loanCurrent, loanLoading, loanSaving, dictionaries: { entities, securities, indexes, subFunds } } = useReduxSelections("loan");
    //@ts-ignore
    const { loanDelete, loanSave, loanAddEntityInDictionary, loanAddIndexInDictionary, loanAddSecurityInDictionary } = useReduxActions("loan");
    const { navigationNavigate } = useReduxActions("navigation");
    const { navigationActiveScreen } = useReduxSelections("navigation");
    const { parameters: { securityExtensionFields = [] } } = useReduxSelections("parameters");
    const handleBack = () => navigationNavigate(undefined);
    if (!loanCurrent) {
        return <DetailPanelLoading onBackClick={handleBack} hasSubTitle={false} />;
    }
    const handleDelete = () => {
        if (!!loanCurrent?.id) {
            loanDelete(loanCurrent.id);
        }
    }
    const handleTabValueChanged = (value: any) => navigationNavigate({ sectionKey: value as string });

    const title = !!loanCurrent?.id ? loanCurrent.name : 'New loan';
    const handleSubmit = (values: ILoanModel, { setSubmitting }: FormikHelpers<ILoanModel>) => {
        loanSave(values);
        setSubmitting(false);
    }

    const handleSecuritySelected = (securityId: number) => {
        const security = securities[securityId];
        if (security.type === "CashSummaryModel") {
            navigationNavigate({ screenKey: "BankAccounts", sectionKey: "detail", parameters: { id: securityId } });
        }
        else if (security.type === "ShareClassSummaryModel" && security.isUnderManagement) {
            navigationNavigate({ screenKey: "MyShareClasses", sectionKey: "detail", parameters: { id: securityId } });
        }
        else {
            navigationNavigate({ screenKey: "Securities", sectionKey: "detail", parameters: { id: securityId } });
        }
    }

    return <Formik onSubmit={handleSubmit} initialValues={loanCurrent} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ values: formValues, dirty, isValid, submitForm }: FormikProps<ILoanModel>) {
        const tabValue = navigationActiveScreen?.activeSectionKey;

        const tabs: ISubMenu[] | undefined = [{
            label: "Data",
            value: "detail"
        }, {
            label: "Collaterals",
            value: "collaterals"
        }, {
            label: "Covenants",
            value: "covenants"
        }];


        return <WriteAccess value={IFeatureModel.ManagedPortfolioWrite}><DetailPanel
            isQuerying={loanLoading || loanSaving}
            tabs={tabs}
            title={title}
            canSave={dirty && isValid}
            onSaveClick={submitForm}
            canDelete={!!loanCurrent?.id}
            onDeleteClick={handleDelete}
            onBackClick={handleBack}
            saveAllowed={IFeatureModel.ManagedPortfolioWrite}
            deleteAllowed={IFeatureModel.ManagedPortfolioWrite}
            badge={!loanCurrent?.id ? "new" : undefined}
            tabValue={tabValue}
            onTabValueChanged={handleTabValueChanged} >
            {(tabValue === "detail") && <LoanData
                securityExtensionFields={securityExtensionFields}
                currentValues={formValues}
                loanAddEntityInDictionary={loanAddEntityInDictionary}
                loanAddIndexInDictionary={loanAddIndexInDictionary}
                indexes={indexes}
                entities={entities} />}
            {(tabValue === "collaterals") && <Collaterals
                onSecuritySelected={handleSecuritySelected}
                subFunds={subFunds}
                securities={securities}
                addSecurityInDictionary={loanAddSecurityInDictionary} />}
            {(tabValue === "covenants") && <Covenants />}
        </DetailPanel>
        </WriteAccess>
    }
}
