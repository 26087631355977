import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IBankAccountSummaryModel, IFeatureModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getEntityName } from "tools/lib/modelUtils";

export default function BankAccounts() {
    const {
        bankAccounts,
        bankAccountAllLoading,
        dictionaries: { entities, portfolios }
    } = useReduxSelections("bankAccount");
    const { referenceCurrencies = {} } = useReduxSelections("reference");
    const { navigationNavigate } = useReduxActions("navigation");

    const handleRowClick = ({ id }: IBankAccountSummaryModel) => navigationNavigate({ sectionKey: "detail", parameters: { id } });
    const handleAddNewClick = () => navigationNavigate({ sectionKey: "detail", parameters: { id: 0 } });

    const columns: IColumnDefinition[] = [
        { name: "InternalCode", title: "Internal Code", getCellValue: (row) => row.internalCode, filteringEnabled: true },
        { name: "Name", title: "Name", getCellValue: ({ name }: IBankAccountSummaryModel) => name, filteringEnabled: true },
        { name: "Currency", title: "Currency", getCellValue: ({ currencyId = 0 }: IBankAccountSummaryModel) => referenceCurrencies[currencyId]?.isoCode },
        { name: "InceptionDate", title: "Inception Date", getCellValue: ({ inceptionDate }: IBankAccountSummaryModel) => inceptionDate, columnType: "date", filteringEnabled: true },
        { name: "TermDate", title: "Term Date", getCellValue: ({ termDate }: IBankAccountSummaryModel) => termDate, columnType: "date", filteringEnabled: true },
        { name: "Rate", title: "Rate", getCellValue: ({ rate }: IBankAccountSummaryModel) => rate, columnType: "decimal", filteringEnabled: true },
        { name: "Portfolio", title: "Portfolio", getCellValue: ({ portfolioId = 0 }: IBankAccountSummaryModel) => portfolios[portfolioId]?.name, filteringEnabled: true },
        { name: "Bank", title: "Bank", getCellValue: ({ bankId = 0 }: IBankAccountSummaryModel) => getEntityName(entities[bankId]), filteringEnabled: true },
        { name: "Issuer", title: "Issuer", getCellValue: ({ issuerId = 0 }: IBankAccountSummaryModel) => getEntityName(entities[issuerId]), filteringEnabled: true },
        { name: "Iban", title: "Iban", getCellValue: ({ iban }: IBankAccountSummaryModel) => iban, filteringEnabled: true },
    ];

    const state: IGridState = {
        "InternalCode": { width: 180 },
        "Name": { width: 200, hidden: true },
        "InceptionDate": { width: 180 },
        "TermDate": { width: 180 },
        "Rate": { width: 120 },
        "Portfolio": { width: 200 },
        "Bank": { width: 200 },
        "Issuer": { width: 200 },
        "Currency": { width: 120 },
        "Iban": { width: 180 }
    };

    const getRowKey = ({ id = 0 }: IBankAccountSummaryModel) => id;

    return <SearchPanel title="Cash Accounts" isQuerying={bankAccountAllLoading}
        addAllowed={IFeatureModel.ManagedPortfolioWrite}
        onAddClick={handleAddNewClick} >
        <ExtendedGrid
            rows={bankAccounts}
            columns={columns}
            getRowId={getRowKey}
            onRowClick={handleRowClick}
            initialState={state}
            userCanGroup={true}
            defaultExportFileName="bankAccounts.xlsx" />
    </SearchPanel>
}
