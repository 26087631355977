import { useField } from "formik";
import CodeEditor from "tools/components/CodeEditor";
import { Box } from "@material-ui/core";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";

// https://developers.google.com/fonts/docs/getting_started
export interface IJsonScriptEditorProps {
    fieldName: string;
    language: string;
};

export default function JsonScriptEditor({ fieldName, language }: IJsonScriptEditorProps) {
    const [, { value: script }, { setValue: setScript }] = useField<string | undefined>({ name: fieldName });
    const handleCodeChanged = (code: string) => setScript(code);
    return (<Box display="flex" flexDirection="row" style={{ height: "100%", width: "100%" }}>
        <div style={{ height: "100%", width: "100%" }}>
            <ReadOnlyContext.Consumer>
                {readOnly => <CodeEditor
                    readOnly={readOnly}
                    code={script}
                    onCodeChanged={handleCodeChanged}
                    language={language} />}
            </ReadOnlyContext.Consumer>
        </div>
    </Box>);
}
