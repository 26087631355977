import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import Securities from "./Securities";
import Security from "./Security";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class SecurityScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute gridSize={12} exact={true} slideDirection={"right"} component={Securities} path={getSiteScreenUrl("Securities")} />
            <NavigationRoute gridSize={12} slideDirection={"left"} component={Security} path={getSiteDetailScreenUrl("Securities")} />
        </>
    }
}

export default SecurityScreen;
