import * as React from "react";
import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import ReportDesigner from "tools/components/ReportDesigner";
import { FormikProps, Formik, FormikHelpers } from "formik";
import { IFeatureModel, IGetReportTemplateContentModel, IScopeDescriptionModel } from "proxy/apiProxy";
import ReportTemplateData from "./ReportTemplateData";
import GitHubIcon from '@material-ui/icons/GitHub';
import DatabaseArrowDownIcon from 'mdi-material-ui/DatabaseArrowDown';
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import CommunityReportTemplateSelect from "components/global/CommunityReportTemplateSelect";
import { oProps } from "tools/lib/utility";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import DialogPanel from "tools/components/DialogPanel";
import ScopeDescriptionData from "components/report/ScopeDescriptionData";
import { useLocalStorage } from "tools/lib/useLocalStorage";

export default function ReportTemplateComponent() {
    const [showCommunityListDialog, setShowCommunityListDialog] = React.useState(false);

    const { reportTemplateCurrent, reportTemplateLoading, reportTemplateSaving, referentialDictionary, reportTemplateAnalysis, dataPreviewLoading, templateAnalyzing } = useReduxSelections("reportTemplate");
    const { referenceReportTemplateCategories = {} } = useReduxSelections("reference");
    const { navigationActiveScreen } = useReduxSelections("navigation");
    const { reportTemplateSave, reportTemplateDelete, reportTemplateAnalyse, reportTemplateDataPreviewLoad } = useReduxActions("reportTemplate")
    const { navigationNavigate } = useReduxActions("navigation")
    const [isDataPreviewRequestOpened, setIsDataPreviewRequestOpened] = React.useState(false);
    const isGranted = useGrants();

    const [lastReportScopeDescription, setLastReportScopeDescription] = useLocalStorage<IScopeDescriptionModel>("lastReportScopeDescription");

    const initialScope = React.useMemo<IScopeDescriptionModel>(() => lastReportScopeDescription ?? {
        subScopes: {},
        extraParameters: {},
        groupLabelExpression: "{group}"
    }, [lastReportScopeDescription]);
    const handleBack = () => navigationNavigate(undefined);

    if (!reportTemplateCurrent) {
        return <DetailPanelLoading onBackClick={handleBack} tabNumber={2} hasSubTitle={false} />;
    }

    const handleShowCommunityListDialog = () => setShowCommunityListDialog(true);
    const handleHideCommunityListDialog = () => setShowCommunityListDialog(false);
    const handleDeleteClick = () => {
        if (reportTemplateCurrent?.definition?.id) {
            reportTemplateDelete(reportTemplateCurrent.definition.id);
        };
    }

    const handleSubmit = ({ content, definition }: IGetReportTemplateContentModel, { setSubmitting }: FormikHelpers<IGetReportTemplateContentModel>) => {
        // ReportDesigner.DesignerInstance?.report.isModified
        if (ReportDesigner.DesignerInstance) {
            reportTemplateSave({ definition, content: ReportDesigner.DesignerInstance.report.saveToJsonString() });
        }
        else {
            reportTemplateSave({ definition, content });
        }
        setSubmitting(false);
    }

    const handleRequestPreview = () => {
        reportTemplateAnalyse(ReportDesigner.DesignerInstance ? ReportDesigner.DesignerInstance.report.saveToJsonString() : reportTemplateCurrent.content);
        setIsDataPreviewRequestOpened(true)
    }
    const handleCloseRequestPreview = () => {
        setIsDataPreviewRequestOpened(false)
    }

    const handleSubmitGetDataForPreview = (scopeDescription: IScopeDescriptionModel) => {
        setLastReportScopeDescription(scopeDescription);
        scopeDescription.groupLabelExpression = "dummy";
        if (reportTemplateAnalysis) {
            reportTemplateDataPreviewLoad({
                scopeDescription,
                content: ReportDesigner.DesignerInstance ? ReportDesigner.DesignerInstance.report.saveToJsonString() : reportTemplateCurrent.content
            });
        }
        setIsDataPreviewRequestOpened(false);
    }

    return <>
        <Formik onSubmit={handleSubmitGetDataForPreview} initialValues={initialScope} enableReinitialize={true} validateOnMount={true} >{renderScopeForm}</Formik>
        <Formik onSubmit={handleSubmit} initialValues={reportTemplateCurrent} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>
    </>;
    function renderScopeForm({ submitForm, isValid }: FormikProps<IScopeDescriptionModel>) {
        return <DialogPanel
            maxWidth="md"
            isOpened={isDataPreviewRequestOpened}
            title="Request data preview"
            isQuerying={templateAnalyzing}
            onBackClick={handleCloseRequestPreview}
            actions={[{ label: "Load data", onClick: submitForm, disabled: !isValid }]}>
            <ScopeDescriptionData parameterMetadatas={reportTemplateAnalysis?.parameters} hideGroupNameExpression={true} />
        </DialogPanel>
    }
    function renderForm({ submitForm, setFieldValue, isValid, values: { content: formContent, definition: formDefinition } }: FormikProps<IGetReportTemplateContentModel>) {
        const tabValue = navigationActiveScreen?.activeSectionKey;

        const tabs: ISubMenu[] | undefined = [{
            label: "Data",
            value: "detail"
        }, {
            label: "Template",
            value: "designer"
        }];
        const extraActionButtons = (tabValue === "designer" && isGranted(IFeatureModel.ReportTemplateWrite)) ? [
            {
                label: "Get template from repository",
                onClick: handleShowCommunityListDialog,
                icon: GitHubIcon
            }] : [];

        const handleTabValueChanged = (value: any) => {
            const tabValue = value as string;
            setFieldValue(oProps<IGetReportTemplateContentModel>().path("content"), ReportDesigner.DesignerInstance ? ReportDesigner.DesignerInstance.report.saveToJsonString() : formContent);
            navigationNavigate({ sectionKey: tabValue });
        }

        extraActionButtons.push({
            label: "Get data for preview",
            onClick: handleRequestPreview,
            icon: DatabaseArrowDownIcon
        })
        const handleSaveClick = () => {
            if (isGranted(IFeatureModel.ReportTemplateWrite)) {
                setFieldValue(oProps<IGetReportTemplateContentModel>().path("content"), ReportDesigner.DesignerInstance ? ReportDesigner.DesignerInstance.report.saveToJsonString() : formContent);
                submitForm();
            }
        };
        const handleCommunityTemplateSelected = (communityTemplate: string) => {
            handleHideCommunityListDialog();
            setFieldValue(oProps<IGetReportTemplateContentModel>().path("content"), communityTemplate);
        }
        return <WriteAccess value={IFeatureModel.ReportTemplateWrite}>
            <CommunityReportTemplateSelect
                isOpened={!!showCommunityListDialog}
                onClose={handleHideCommunityListDialog}
                onTemplateLoaded={handleCommunityTemplateSelected} />
            <DetailPanel
                isQuerying={reportTemplateLoading || reportTemplateSaving || dataPreviewLoading}
                tabs={tabs}
                title={formDefinition.name}
                subTitle={`Version ${formDefinition.publishedVersion}`}
                onBackClick={handleBack}
                onSaveClick={handleSaveClick}
                canSave={isValid}
                onDeleteClick={handleDeleteClick}
                canDelete={formDefinition.id !== 0}
                saveAllowed={IFeatureModel.ReportTemplateWrite}
                deleteAllowed={IFeatureModel.ReportTemplateWrite}
                actions={extraActionButtons}
                noPadding={tabValue === "designer"}
                tabValue={tabValue}
                onTabValueChanged={handleTabValueChanged} >
                {(tabValue === "detail") && <ReportTemplateData fieldName={oProps<IGetReportTemplateContentModel>().path("definition")} referenceReportTemplateCategories={referenceReportTemplateCategories} />}
                {(tabValue === "designer") && <ReportDesigner template={formContent} data={referentialDictionary} />}
            </DetailPanel>
        </WriteAccess>
    }
}
