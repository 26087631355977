import { Epic } from "redux-observable";
import { map, share, mergeMap } from "rxjs/operators";
import { marketDataProvidersApi, parametersApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, changedNavigation } from "lib/rxJsUtility";
import { filterRoute } from "tools/lib/UrlDictionary";
import { merge } from "rxjs";

export const onOpenScreenParameter: Epic<IAnyAction>
    = action$ => {
        const openScreen$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey),
            filterRoute("GeneralSettings"),
            share());
        return merge(
            openScreen$.pipe(
                map(() => ActionFactories.parameters.parametersLoad())),
            openScreen$.pipe(
                map(() => ActionFactories.parameters.parametersMarketDataImportProcessLoad())),
        );
    }
export const loadParameter: Epic<IAnyAction>
    = action$ => {
        const parametersLoad$ = action$.pipe(
            mapToPayload("parameters", "parametersLoad"),
            share());

        return merge(
            parametersLoad$.pipe(
                mergeMap(parametersApi.getAsync),
                map(ActionFactories.parameters.parametersLoaded)),
            parametersLoad$.pipe(
                mergeMap(marketDataProvidersApi.getAllAsync),
                map(ActionFactories.parameters.parametersMarketProviderParametersLoaded)));
    }
export const saveParameter: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("parameters", "parametersSave"),
        mergeMap(model => parametersApi.saveAsync({ parametersModel: model }).then(() => model)),
        map(ActionFactories.parameters.parametersSaved));
export const loadMarketDataImportProcess: Epic<IAnyAction>
    = action$ => merge(
        action$.pipe(mapToPayload("parameters", "parametersSaved")),
        action$.pipe(mapToPayload("parameters", "parametersMarketDataImportProcessLoad")),
    ).pipe(
        mergeMap(() => parametersApi.getMarketDataImportStructureAsync()),
        map(ActionFactories.parameters.parametersMarketDataImportProcessLoaded));
