import * as React from "react";
import { RelationshipModel, RelationshipSummaryModel } from "proxy/apiProxy";
import { useField } from 'formik';
import AsyncSelect from "tools/components/AsyncSelect";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IGetRelationshipSummary } from "reducers/Reference";
import { getEntityName } from "tools/lib/modelUtils";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import SearchIcon from '@material-ui/icons/Search';

export interface IRelationshipSelectFieldProps {
    name: string;
    label?: React.ReactNode;
    type: RelationshipModel["type"];
    required?: boolean;
    onSelected?: (found: IGetRelationshipSummary) => void;
}

export default function RelationshipSelectField(
    { name, label, required, onSelected, type }: IRelationshipSelectFieldProps) {
    useReduxActions("reference");
    const validate = (relationshipId: number | undefined) => {
        if (!required) {
            return;
        }
        return (relationshipId ?? 0) === 0 ? "Required" : undefined;
    }
    const [, { value, error, initialValue }, { setValue }] = useField<number | undefined>({ name, validate });

    return <RelationshipSelect
        relationshipId={value}
        error={error}
        initialRelationshipId={initialValue}
        setRelationshipId={setValue}
        required={required}
        type={type}
        onSelected={onSelected}
        label={label} />
}

export function RelationshipSelect(
    { relationshipId, error, initialRelationshipId, setRelationshipId, label, required, onSelected, type }: {
        relationshipId?: number,
        error?: string,
        initialRelationshipId?: number,
        setRelationshipId: (id: number) => void,
        label?: React.ReactNode,
        type: RelationshipModel["type"],
        required?: boolean,
        onSelected?: (found: IGetRelationshipSummary) => void,
    }) {
    const { referenceRelationshipSearch } = useReduxActions("reference");
    const {
        referenceRelationshipsSearched: { all: relationships, ...dictionaries },
        referenceRelationshipSearching
    } = useReduxSelections("reference");
    const { entities } = dictionaries;

    const getOptionLabel = ({ entityId }: RelationshipSummaryModel) => getEntityName(entities[entityId]) ?? "--no entity--";
    label = <><SearchIcon fontSize="small" /> {label ?? "Relationship"}: Type criteria to find a
        relationship {required ? "*" : ""}</>;
    if ((initialRelationshipId || null) !== (relationshipId || null)) {
        label = <b>{label}</b>;
    }
    const handleValueSelected = (relationship: RelationshipSummaryModel) => {
        if (onSelected) {
            onSelected({ relationship, ...dictionaries });
        }
        setRelationshipId(relationship.id);
    }
    const handleOnSearchOptions = (criterias: string) => referenceRelationshipSearch({ criterias, type });
    return <ReadOnlyContext.Consumer>{readOnly => readOnly ? null :
        <AsyncSelect onSearchOptions={handleOnSearchOptions} options={relationships}
                     getOptionLabel={getOptionLabel} searching={referenceRelationshipSearching} label={label}
                     onRequiredValueSelected={handleValueSelected} error={!!error} helperText={error} />}
    </ReadOnlyContext.Consumer>
}