import produce from "immer";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IGetVersionsModel, IDeltaModel, IFileModel, IVersionModel, EntitySummaryModel } from "proxy/apiProxy";
import { IStudioVersionsGetAllParameters, IStudioVersionsGetDeltaParameters } from "proxy/apiProxy";

export type IVersionChangeLoaded = IStudioVersionsGetDeltaParameters & IDeltaModel;

export const ActionFactories = produceActionFactories({
  versionsSearch: (payload: IStudioVersionsGetAllParameters) => payload,
  versionsLoaded: (payload: IGetVersionsModel) => payload,
  versionsChangeLoad: (payload: IStudioVersionsGetDeltaParameters) => payload,
  versionsChangeLoaded: (payload: IVersionChangeLoaded) => payload,
  versionsAllLatestsLoad: () => undefined,
  versionsAllLatestsLoaded: () => undefined,
  versionsImport: (payload: IFileModel) => payload,
  versionsImported: () => undefined,
  versionRestore: (payload: IVersionModel) => payload,
  versionRestored: (payload: IVersionModel) => payload
});

export interface IState {
  versionsLoading: boolean;
  versionRestoring: boolean;
  versions: IVersionModel[];
  // versions?: IGetVersionsModel;
  versionCurrentDelta: {
    requested?: IStudioVersionsGetDeltaParameters;
    loaded?: IDeltaModel;
  };
  dictionaries: {
    entities: Record<string | number, EntitySummaryModel>;
  };
  versionsExporting: boolean;
  versionsImporting: boolean;
}

export function reducer(
  state: IState = {
    versionRestoring: false,
    versionsLoading: false,
    versionCurrentDelta: {},
    versionsExporting: false,
    versionsImporting: false,
    versions: [],
    dictionaries: {
      entities: {}
    }
  },
  action: AnyActionOf<typeof ActionFactories>
): IState {
  return produce(state, draft => {
    switch (action.type) {
      case "versionsSearch":
        draft.versionsLoading = true;
        break;
      case "versionRestore":
        draft.versionRestoring = true;
        break;
      case "versionRestored":
        draft.versions.unshift(action.payload);
        draft.versionRestoring = false;
        break;
      case "versionsLoaded":
        draft.versionsLoading = false;
        const { versions, ...dictionaries } = action.payload;
        draft.versions = versions;
        draft.dictionaries = { ...draft.dictionaries, ...dictionaries };
        break;
      case "versionsChangeLoad":
        draft.versionCurrentDelta.requested = action.payload;
        draft.versionCurrentDelta.loaded = undefined;
        break;
      case "versionsChangeLoaded":
        draft.versionCurrentDelta.loaded = action.payload;
        break;
      case "versionsAllLatestsLoad":
        draft.versionsExporting = true;
        break;
      case "versionsAllLatestsLoaded":
        draft.versionsExporting = false;
        break;
      case "versionsImport":
        draft.versionsImporting = true;
        break;
      case "versionsImported":
        draft.versionsImporting = false;
        break;
    }
  });
}
