import { Epic } from "redux-observable";
import { map, filter, share, mergeMap } from "rxjs/operators";
import { CustomScreenModel, customScreensApi, studioCustomScreensApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { changedNavigation, mapToPayload } from "lib/rxJsUtility";
import { tryParseNumber } from "tools/lib/utility";
import { combineLatest, merge } from "rxjs";
import { filterRoute, routeMatches } from "tools/lib/UrlDictionary";

function translateOpenRequest(screen: string | undefined, detail: any): CustomScreenModel["type"] | number | null {
    if (!detail || !screen) {
        return null;
    }
    const customScreenId = tryParseNumber(detail?.id);
    if (typeof customScreenId === "number") {
        return customScreenId;
    }
    else {
        return detail?.id;
    }
}
function createNewCustomScreenInstance(type: CustomScreenModel["type"]): CustomScreenModel {
    return {
        type,
        id: 0,
        code: "",
        name: "",
        publishedVersion: 0,
        templateContent: "{}",
        createOnly: true,
        publishOnPortal: false
    };
}
export const onOpenCustomScreen: Epic<IAnyAction>
    = action$ => {
        const openRequest$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
            filter(i => routeMatches(i, "CustomScreen", "detail")),
            map(({ screenKey, matchingSections }) => translateOpenRequest(screenKey, matchingSections?.detail)),
            filter(openRequest => !!openRequest),
            share()
        );
        const loadCustomScreen$ = openRequest$.pipe(
            filter(openRequest => typeof (openRequest) === "number"),
            map(id => ActionFactories.customScreen.customScreenLoad(id as number)));
        const newCustomScreen$ = openRequest$.pipe(
            filter(openRequest => typeof (openRequest) !== "number"),
            map(openRequest => ActionFactories.customScreen.customScreenNew(openRequest as CustomScreenModel["type"])));

        return merge(
            loadCustomScreen$,
            newCustomScreen$
        );
    }

export const newCustomScreen: Epic<IAnyAction>
    = action$ => {
        const customScreenRequest$ = action$.pipe(
            mapToPayload("customScreen", "customScreenNew"),
            share());
        const customScreenLoaded$ = customScreenRequest$.pipe(
            map(type => ({
                customScreenMacroCheckResult: { errors: [] },
                customScreen: createNewCustomScreenInstance(type)
            })),
            map(ActionFactories.customScreen.customScreenLoaded));
        const metadata$ = customScreenRequest$.pipe(
            mergeMap(studioCustomScreensApi.getUniverseStructureAsync),
            map(ActionFactories.customScreen.customScreenMetadataLoaded));
        return merge(
            customScreenLoaded$,
            metadata$
        )
    }
export const loadCustomScreen: Epic<IAnyAction>
    = action$ => {
        const customScreenRequest$ = action$.pipe(
            mapToPayload("customScreen", "customScreenLoad"),
            mergeMap(id => customScreensApi.getAsync({ id })),
            share());

        const ruleCheckResult$ = customScreenRequest$.pipe(
            mergeMap(({ afterCompleteMacro }) => studioCustomScreensApi.checkScriptAsync(({ textModel: { text: afterCompleteMacro } })))
        );
        const metadata$ = customScreenRequest$.pipe(
            mergeMap(studioCustomScreensApi.getUniverseStructureAsync),
            map(ActionFactories.customScreen.customScreenMetadataLoaded)
        );

        return merge(
            combineLatest([customScreenRequest$, ruleCheckResult$]).pipe(
                map(([customScreen, customScreenMacroCheckResult]) => ({ customScreen, customScreenMacroCheckResult })),
                map(ActionFactories.customScreen.customScreenLoaded)),
            metadata$);
    };
// = action$ => action$.pipe(
//     mapToPayload("customScreen", "customScreenLoad"),
//     customScreensApi.getAsync(id => ({ id })),
//     onlyStatusOk(),
//     map(ActionFactories.customScreen.customScreenLoaded));
export const checkScript: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("customScreen", "customScreenValidateScript"),
        mergeMap(script => studioCustomScreensApi.checkScriptAsync({ textModel: { text: script } })),
        map(ActionFactories.customScreen.customScreenValidatedScript));

export const onOpenScreen: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("CustomScreen"),
        map(ActionFactories.customScreen.customScreenLoadAll));
export const loadAll: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("customScreen", "customScreenLoadAll"),
        mergeMap(() => customScreensApi.getAllAsync({})),
        map(ActionFactories.customScreen.customScreenLoadedAll));
export const deleteCustomScreen: Epic<IAnyAction>
    = action$ => {
        const deleted$ = action$.pipe(
            mapToPayload("customScreen", "customScreenDelete"),
            mergeMap(id => studioCustomScreensApi.deleteAsync({ id }).then(() => id)),
            share());
        return merge(
            deleted$.pipe(map(ActionFactories.customScreen.customScreenDeleted)),
            deleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined)))
        );
    }
export const saveCustomScreen: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("customScreen", "customScreenSave"),
        mergeMap(model => studioCustomScreensApi.saveAsync({ model })),
        map(ActionFactories.customScreen.customScreenSaved));
