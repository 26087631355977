import { TextField } from "@material-ui/core";
import { Autocomplete, Value } from "@material-ui/lab";
import { useField } from "formik";
import * as React from "react";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { useReduxSelections } from "tools/lib/reduxStoreAccess";

export interface ICountrySelectFieldProps {
    fullWidth?: boolean;
    name: string;
    label?: React.ReactNode;
    disabled?: boolean;
    required?: boolean;
}

function normalizeIdValue(value: any) {
    if (typeof value !== "number")
        return;
    if (value === 0)
        return undefined;
    return value;
}
export function CountrySelectField({ name, label, disabled, fullWidth = true, required }: ICountrySelectFieldProps) {
    const validate = (value: any) => {
        if (!required) {
            return;
        }
        if (required && !normalizeIdValue(value)) {
            return "Required";
        }
        return;
    }

    const { referenceCountries = {} } = useReduxSelections("reference");
    const countryIds = React.useMemo(() => Object.keys(referenceCountries).map(i => referenceCountries[i].id), [referenceCountries]);
    const [{ value }, { error: errorText, initialValue }, { setValue }] = useField<number | undefined>({ name, validate });
    const [inputValue, setInputValue] = React.useState("");
    const isDirty = (initialValue ?? null) !== (value ?? null);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: Value<number, undefined, undefined, undefined>) => setValue(newValue ?? undefined);
    const handleNewInputValue = (event: React.ChangeEvent<{}>, newInputValue: string) => setInputValue(newInputValue);
    const handleGetLabel = (optionId: number) => referenceCountries[optionId ?? 0]?.name?.en ?? "???";
    return <ReadOnlyContext.Consumer>
        {readOnly => <Autocomplete
            value={value ?? null}
            onChange={handleChange}
            getOptionLabel={handleGetLabel}
            inputValue={inputValue}
            onInputChange={handleNewInputValue}
            options={countryIds}
            disabled={disabled||readOnly}
            fullWidth={fullWidth}
            renderInput={({ ...params }) => <TextField {...params}
                margin="dense"
                helperText={errorText}
                error={!!errorText}
                label={renderLabel(isDirty, required ?? false, label)}
            />}
        />}
    </ReadOnlyContext.Consumer>
}

function renderLabel(isDirty: boolean, isRequired: boolean, label: React.ReactNode | undefined): React.ReactNode {
    if (!label) {
        return null;
    }
    if (isRequired) {
        label = <>{label} *</>;
    }
    if (isDirty) {
        label = <b>{label}</b>
    }
    return label;
}
