import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {ConnectedRouter, connectRouter, routerMiddleware} from "connected-react-router";
import {createBrowserHistory} from "history";
import * as React from "react";
import * as ReactDOM from "react-dom";
import {Provider} from "react-redux";
import App from "./layout/App";
import epics from './epics';
import * as reducers from './reducers';
import reportWebVitals from './reportWebVitals';
import {combineReducers, configureStore} from "@reduxjs/toolkit";
import registerServiceWorker from './registerServiceWorker';
import "core-js";
import {combineEpics, createEpicMiddleware} from 'redux-observable';

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const history = createBrowserHistory({basename: baseUrl || undefined});


const initialState = {};

const appReducer = combineReducers({
    ...reducers.reducer,
    router: connectRouter(history)
});


const rootReducer: typeof appReducer = (state, action: reducers.IAnyAction) => {
    if (action.type === "switchTenant") {
        const emptyState = appReducer(undefined, {type: "DUMMY"});
        // const newState = appReducer(emptyState, { type: "applicationLoad", payload: action.payload });
        return emptyState;
    } else {
        return appReducer(state, action);
    }
}

const epicMiddleware = createEpicMiddleware();
const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [epicMiddleware, routerMiddleware(history)]
});

epicMiddleware.run(combineEpics(...epics));

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <App/>
                </MuiPickersUtilsProvider>
            </ConnectedRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root") as HTMLElement
);

registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

