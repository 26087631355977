import * as React from "react";
import autobind from "autobind-decorator";
import FieldBox from "tools/components/FieldBox";
import { oProps } from "tools/lib/utility";
import FormMultiCulturedTextField from "tools/fieldComponents/FormMultiCulturedTextField";
import FormTextField from "tools/fieldComponents/FormTextField";
import { IEditableClassificationType } from "./treeTypes";

@autobind
class ClassificationTypeData extends React.Component {
    public render() {
        return (
            <FieldBox display="flex" flexDirection="column">
                <FormTextField name={oProps<IEditableClassificationType>().path("code")} label="Code" required={true} />
                <FormMultiCulturedTextField name={oProps<IEditableClassificationType>().path("name")} label="Name" required={["en"]} />
                <FormMultiCulturedTextField name={oProps<IEditableClassificationType>().path("description")} label="Description" multiline={true} />
            </FieldBox>
        );
    }
}

export default ClassificationTypeData;
