import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import BatchInstances from "./BatchInstances";
import BatchInstance from "./BatchInstance";
// import BatchInstance from "./BatchInstance.container";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class BatchInstanceScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                gridSize={12}
                exact={true}
                slideDirection={"right"}
                component={BatchInstances}
                path={getSiteScreenUrl("Executions")} />
            <NavigationRoute
                gridSize={12}
                slideDirection={"left"}
                component={BatchInstance}
                path={getSiteDetailScreenUrl("Executions")} />
        </>
    }
}

export default BatchInstanceScreen;
