import * as React from "react";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Paper, LinearProgress, Tooltip, Fab } from "@material-ui/core";
import FabContainer, { IFabContainerProps, getButtonNumber } from "./FabContainer";
import HeaderTitle from "./HeaderTitle";
import styled from '@emotion/styled';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';

const DetailWrapper = styled.div(({ fullScreen = false }: { fullScreen?: boolean }) => {
    if (!fullScreen) {
        return {
            position: "relative",
            width: "100%",
            height: "100%",
            borderRadius: 5,
            // borderStyle: "solid",
            // borderWidth: 1,
            // borderColor: "lightgray"
        };
    }
    else {
        return {
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1250,
            right: 0,
            bottom: 0
        };
    }
})

const TopRightDiv = styled.div({
    position: "absolute",
    top: 8,
    right: 8,
    zIndex: 9999999
});

const useStyles = makeStyles(theme => createStyles({
    detailPaper: {
        height: "100%",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column"
    },
    detailContent: {
        padding: theme.spacing(2)
    },
    detailContentNoPadding: {
        padding: 0
    },
}));

export interface ISubMenu {
    icon?: string | React.ReactElement;
    label: React.ReactNode;
    // onChange?: (event: React.ChangeEvent<{ checked: boolean }>, value: any) => void;
    value: any;
}

export interface IDetailContainerProps extends IFabContainerProps {
    endAdornment?: React.ReactNode;
    title: string;
    badge?: string;
    subTitle?: string;
    onBackClick?: () => void;
    tabs?: ISubMenu[];
    tabValue?: any;
    noPadding?: boolean;
    onTabValueChanged?: (value: any) => void;
    // actionButtons?: IFabContainerProps;
    isQuerying?: boolean;
}

function InnerDetailPanel({ onTabValueChanged, badge, children, title, subTitle, onBackClick
    , tabs, tabValue, isQuerying, endAdornment, noPadding = false, ...actionButtons }: React.PropsWithChildren<IDetailContainerProps>) {
    const [fullScreen, setFullScreen] = React.useState(false);
    const handleTabChanged = (newValue: any) => {
        if (onTabValueChanged) {
            onTabValueChanged(newValue);
        }
    }
	const handleFullScreenSwitch = () => setFullScreen(v => !v);
    const actionButtonCount = React.useMemo(() => getButtonNumber(actionButtons), [actionButtons]);
    // actionButtonCount += actionButtons.actions?.length??0;
    // actionButtons.
    const classes = useStyles();
    return <DetailWrapper fullScreen={fullScreen}><Paper className={classes.detailPaper}>
        <TopRightDiv><Tooltip title="Switch to full screen"><Fab size="small" style={{width:25, height:25, minHeight:25}} onClick={handleFullScreenSwitch}><SettingsOverscanIcon fontSize="small" style={{fontSize:"1rem"}} /></Fab></Tooltip></TopRightDiv>
        <DetailProgress isQuerying={isQuerying} />
        <HeaderTitle
            title={title}
            badge={badge}
            subTitle={subTitle}
            onBackClick={onBackClick}
            tabs={tabs}
            tabValue={tabValue}
            endAdornment={endAdornment}
            onTabValueChanged={handleTabChanged}
            spaceAfterTabs={80 * actionButtonCount}
        />
        {(!!Object.values(actionButtons).filter(i => i).length) && <FabContainer {...actionButtons} />}
        <Box className={noPadding ? classes.detailContentNoPadding : classes.detailContent} flexGrow="1" overflow="auto" height="100%" position="relative">
            {children}
        </Box>
    </Paper>
    </DetailWrapper>
}
const DetailPanel = React.memo(InnerDetailPanel);
export default DetailPanel;
interface IDetailProgressProps {
    isQuerying: boolean | undefined;
}
function DetailProgress({ isQuerying }: IDetailProgressProps) {
    return <LinearProgress hidden={!isQuerying} variant="query" />;
}
