import { EntitySummaryModel, IBatchModel, IEntityTypeModel, IMacroScriptCategoryModel } from "proxy/apiProxy";
import FormTextField from "tools/fieldComponents/FormTextField";
import FieldBox from "tools/components/FieldBox";
import { oProps } from "tools/lib/utility";
import { Typography } from "@material-ui/core";
import cronstrue from 'cronstrue';
import { IGetEntitySummary } from "reducers/Reference";
import EntitiesSelectField from "components/searchers/EntitiesSelectField";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";

export interface IBatchDataProps {
    batch: IBatchModel;
    onEntityLoaded: (entity: IGetEntitySummary) => void;
    dictionaries: {
        entities: Record<EntitySummaryModel["id"], EntitySummaryModel>;
    };
    referenceMacroScriptCategories: Record<number, IMacroScriptCategoryModel>;
}

export default function BatchData({ batch: { cron }, dictionaries: { entities }, referenceMacroScriptCategories, onEntityLoaded }: IBatchDataProps) {
    return <FieldBox display="flex" flexDirection="column">
        <FormTextField name={oProps<IBatchModel>().path("code")} label="Code" required={true} maxLength={100} />
        <FormTextField name={oProps<IBatchModel>().path("name")} label="Name" required={true} maxLength={250} />
        <FormSimpleSelectField name={oProps<IBatchModel>().path("categoryId")} label="Category" options={referenceMacroScriptCategories} />
        <FormTextField name={oProps<IBatchModel>().path("description")} label="Description" multiline={true} />
        <FormTextField name={oProps<IBatchModel>().path("cron")} label="Cron scheduling" isCron={true} />
        <FormCheckBoxField name={oProps<IBatchModel>().path("emailOnlyOnError")} label="Email only on error" canBeUndefined={false} />
        {(!!cron) && <Typography>{cronstrue.toString(cron, { throwExceptionOnParseError: false, use24HourTimeFormat: true })}</Typography>}
        <EntitiesSelectField entities={entities} onEntityLoaded={onEntityLoaded} name={oProps<IBatchModel>().path("ownerIds")} type={IEntityTypeModel.Person} label="Owners" />
    </FieldBox>
}
