import * as React from "react";
import { FormikHelpers, Formik } from "formik";
import { DevelopmentItemModel, IReportTemplateDevelopmentItemModel, IQuestionnaireDevelopmentItemModel, IEtlMacroDevelopmentItemModel, IDashboardMacroDevelopmentItemModel } from "proxy/apiProxy";
import { useReduxSelections, useReduxActions } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
// import DialogPanel from "tools/components/DialogPanel";
// import SurveyRunnerComponent from "tools/components/SurveyRunnerComponent";
import { useEditorRef } from "./Designers/INotControllableEditorProps";
import { DevelopmentItemForm } from "./DevelopmentItemForm";
// import ReportTemplateDevelopmentItemEditor from "./ReportTemplateDevelopmentItemEditor";

function createEditableValues(developmentItemCurrent: DevelopmentItemModel | undefined) {
    switch (developmentItemCurrent?.type) {
        case "QuestionnaireDevelopmentItemModel": {
            const { templateContent, onCompleteMacro, onLoadMacro, ...values } = developmentItemCurrent;
            return values;
        }
        case "ReportTemplateDevelopmentItemModel": {
            const { templateContent, onLoadMacro, ...values } = developmentItemCurrent;
            return values;
        }
        case "DashboardMacroDevelopmentItemModel": {
            const { macro, ...values } = developmentItemCurrent;
            return values;
        }
        case "EtlMacroDevelopmentItemModel": {
            const { macro, ...values } = developmentItemCurrent;
            return values;
        }
        default:
            return developmentItemCurrent;
    }
}
export type FormValues = NonNullable<ReturnType<typeof createEditableValues>>;
export default function DevelopmentItemEdit() {
    const { developmentItemCurrent } = useReduxSelections("developmentItem");
    const { developmentItemSave } = useReduxActions("developmentItem");
    const { navigationNavigate } = useReduxActions("navigation");
    const handleBack = React.useCallback(() => navigationNavigate(undefined), [navigationNavigate]);

    const developmentItemValues = React.useMemo(() => createEditableValues(developmentItemCurrent), [developmentItemCurrent]);
    const reportTemplateRef = useEditorRef((developmentItemCurrent as IReportTemplateDevelopmentItemModel)?.templateContent);
    const questionnaireTemplateRef = useEditorRef((developmentItemCurrent as IQuestionnaireDevelopmentItemModel)?.templateContent);
    const etlMacroRef = useEditorRef((developmentItemCurrent as IEtlMacroDevelopmentItemModel)?.macro);
    const dashboardMacroRef = useEditorRef((developmentItemCurrent as IDashboardMacroDevelopmentItemModel)?.macro);
    const reportLoadMacroRef = useEditorRef((developmentItemCurrent as IReportTemplateDevelopmentItemModel)?.onLoadMacro);
    const questionnaireLoadMacroRef = useEditorRef((developmentItemCurrent as IQuestionnaireDevelopmentItemModel)?.onLoadMacro);
    const questionnaireCompleteMacroRef = useEditorRef((developmentItemCurrent as IQuestionnaireDevelopmentItemModel)?.onCompleteMacro);

    const handleSubmit = React.useCallback(async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        switch (values.type) {
            case "QuestionnaireDevelopmentItemModel":
                developmentItemSave({
                    ...values,
                    templateContent: questionnaireTemplateRef.current.getValue() ?? "{}",
                    onCompleteMacro: questionnaireCompleteMacroRef.current.getValue(),
                    onLoadMacro: questionnaireLoadMacroRef.current.getValue()
                });
                break;
            case "ReportTemplateDevelopmentItemModel":
                developmentItemSave({
                    ...values,
                    templateContent: reportTemplateRef.current.getValue() ?? "{}",
                    onLoadMacro: reportLoadMacroRef.current.getValue()
                });
                break;
            case "EtlMacroDevelopmentItemModel":
                developmentItemSave({
                    ...values,
                    macro: etlMacroRef.current.getValue()
                });
                break;
            case "DashboardMacroDevelopmentItemModel":
                developmentItemSave({
                    ...values,
                    macro: dashboardMacroRef.current.getValue()
                });
                break;
            default:
                developmentItemSave(values);
                break;
        }
        setSubmitting(false);
    }, [developmentItemSave, etlMacroRef, questionnaireCompleteMacroRef, questionnaireLoadMacroRef, questionnaireTemplateRef, reportLoadMacroRef, reportTemplateRef, dashboardMacroRef]);

    if (!developmentItemValues) {
        return <DetailPanelLoading onBackClick={handleBack} tabNumber={3} hasSubTitle={false} />;
    }
    return <Formik onSubmit={handleSubmit} initialValues={developmentItemValues} enableReinitialize={true} validateOnMount={true}>
        {(formikProps => <DevelopmentItemForm
            form={formikProps}
            questionnaireTemplateRef={questionnaireTemplateRef}
            reportTemplateRef={reportTemplateRef}
            reportLoadMacroRef={reportLoadMacroRef}
            dashboardMacroRef={dashboardMacroRef}
            etlMacroRef={etlMacroRef}
            questionnaireCompleteMacroRef={questionnaireCompleteMacroRef}
            questionnaireLoadMacroRef={questionnaireLoadMacroRef} />)}
    </Formik>;
}

