import { IOperationTypeModel, ICurrencyModel, PortfolioSummaryModel, SecuritySummaryModel, isSecurityInstrumentSummaryModel, ISubFundSummaryModel, IOrderTypeModel, TradeBookTradeModel, IFeatureModel } from "proxy/apiProxy";
import * as React from "react";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { getEnumLabels, IDictionary, splitPascalCase } from "tools/lib/utility";
import { useField } from "formik";
import { getSecurityTypeLabel } from "features/Security/getSecurityTypeLabel";
import { getPortfolioTypeLabel } from "features/ManagedPortfolio/getPortfolioTypeLabel";
import { getPortfolioTradeTypeLabel } from "components/portfolioTrade/getPortfolioTradeTypeLabel";
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from "@material-ui/core";
import { useGrants } from "tools/lib/reduxStoreAccess";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const orderTypes = getEnumLabels(IOrderTypeModel);

export interface ITradeBooksDataProps {
    dictionaries: {
        portfolios: Record<string | number, PortfolioSummaryModel>;
        subFunds: Record<string | number, ISubFundSummaryModel>;
        securities: Record<string | number, SecuritySummaryModel>;
    };
    referenceCurrencies: IDictionary<ICurrencyModel>;
    tradesField: string;
    onEditTrade: (tradeIndex: number) => void;
    onDeleteTrade: (tradeIndex: number) => void;
}
function TradeBooksTrades({ dictionaries, referenceCurrencies, tradesField, onEditTrade, onDeleteTrade }: ITradeBooksDataProps) {
    const { portfolios, securities } = dictionaries;
    const [, { value: tradesList }] = useField<TradeBookTradeModel[]>(tradesField);
    const trades = React.useMemo(() => tradesList.map((i, idx) => ({ ...i, rowIndex: idx })), [tradesList]);
    const getRowKey = (trade: TradeBookTradeModel & { rowIndex: number }) => {
        const { id } = trade;
        if (id) {
            return id;
        }
        const pos = trades.indexOf(trade);
        return `_${pos}`;
    }
    const isGranted = useGrants();
    const columns = React.useMemo(() => {
        const handleDeleteClick = ({ rowIndex }: { rowIndex: number }) => {
            onDeleteTrade && onDeleteTrade(rowIndex);
        }
        const ret: IColumnDefinition[] = [
            { name: "Type", title: "Type", getCellValue: ({ type }: TradeBookTradeModel) => getPortfolioTradeTypeLabel(type), aggregationType: "count" },
            { name: "PortfolioCode", title: "Prtf Code", getCellValue: ({ portfolioId }: TradeBookTradeModel) => portfolios[portfolioId ?? 0]?.name, aggregationType: "count", filteringEnabled: true },
            { name: "PortfolioName", title: "Prtf Name", getCellValue: ({ portfolioId }: TradeBookTradeModel) => portfolios[portfolioId ?? 0]?.name, aggregationType: "count", filteringEnabled: true },
            { name: "PortfolioType", title: "Prtf Type", getCellValue: ({ portfolioId }: TradeBookTradeModel) => portfolios[portfolioId ?? 0] ? getPortfolioTypeLabel(portfolios[portfolioId].type) : "", aggregationType: "count", filteringEnabled: true },
            
            {
                name: "TargetSecurityCode", title: "Sec Code", getCellValue: (trade: TradeBookTradeModel) => {
                    if (trade.type === "TradeBookSecurityTradeModel") {
                        return securities[trade.securityId ?? 0]?.internalCode;
                    }
                    else {
                        return <>{referenceCurrencies[trade.soldCurrencyId]?.isoCode}<KeyboardArrowRightIcon />{referenceCurrencies[trade.purchasedCurrencyId]?.isoCode}</>;
                    }
                }, filteringEnabled: true
            },
            {
                name: "TargetSecurityName", title: "Sec Name", getCellValue: (trade: TradeBookTradeModel) => {
                    if (trade.type === "TradeBookSecurityTradeModel") {
                        return securities[trade.securityId ?? 0]?.name;
                    }
                    else {
                        return <>{referenceCurrencies[trade.soldCurrencyId]?.name?.en}<KeyboardArrowRightIcon />{referenceCurrencies[trade.purchasedCurrencyId]?.name?.en}</>;
                    }
                }, filteringEnabled: true
            },
            { name: "TargetSecurityType", title: "Sec Type", getCellValue: (trade: TradeBookTradeModel) => trade.type === "TradeBookSecurityTradeModel" ? getSecurityTypeLabel(securities[trade.securityId ?? 0]?.type) : undefined },
            {
                name: "TargetSecurityIsin", title: "Isin", getCellValue: (trade: TradeBookTradeModel) => {
                    if (trade.type === "TradeBookFxTradeModel") {
                        return;
                    }
                    const secu = securities[trade.securityId];
                    return isSecurityInstrumentSummaryModel(secu) ? secu.isin : undefined
                }, filteringEnabled: true
            },
            {
                name: "BuySell", title: "Buy/Sell", getCellValue: (trade: TradeBookTradeModel) => {
                    if (trade.type === "TradeBookFxTradeModel") {
                        return;
                    }
                    const { buySell } = trade;
                    return buySell;
                }
            },
            { name: "Disabled", title: "Disabled", getCellValue: ({ disabled }: TradeBookTradeModel) => disabled, columnType: "boolean", filteringEnabled: true },
            {
                name: "Quantity", title: "Qty", getCellValue: (trade: TradeBookTradeModel) => {
                    if (trade.type === "TradeBookFxTradeModel") {
                        return;
                    }
                    const { quantity, buySell } = trade;
                    return typeof (quantity) === "number" ? (buySell === IOperationTypeModel.Buy ? quantity : -quantity) : undefined;
                },
                columnType: "decimal", filteringEnabled: true
            },
            {
                name: "MarketValueInPortfolioCurrency", title: "Amount Prtf Cur", getCellValue: (trade: TradeBookTradeModel) => {
                    if (trade.type === "TradeBookFxTradeModel") {
                        return;
                    }
                    const { amountInPtfCcy, buySell } = trade;
                    return typeof (amountInPtfCcy) === "number" ? (buySell === IOperationTypeModel.Buy ? amountInPtfCcy : -amountInPtfCcy) : undefined;
                },
                columnType: "decimal",
                aggregationType: "sum", filteringEnabled: true
            },
            {
                name: "TargetSecurityCurrency", title: "Sec Ccy", getCellValue: (trade: TradeBookTradeModel) => {
                    if (trade.type === "TradeBookFxTradeModel") {
                        return;
                    }
                    const currencyId = securities[trade.securityId ?? 0]?.currencyId;
                    if (!currencyId) {
                        return
                    }
                    return referenceCurrencies[currencyId]?.isoCode;
                }, filteringEnabled: true
            },
            {
                name: "SoldAmount", title: "Sold Amnt", getCellValue: (trade: TradeBookTradeModel) => {
                    if (trade.type === "TradeBookSecurityTradeModel") {
                        return;
                    }
                    return trade.soldAmount;
                },
                columnType: "decimal",
                aggregationType: "sum", filteringEnabled: true
            },
            {
                name: "SoldCurrency", title: "Sold Ccy", getCellValue: (trade: TradeBookTradeModel) => {
                    if (trade.type === "TradeBookSecurityTradeModel") {
                        return;
                    }
                    return referenceCurrencies[trade.soldCurrencyId ?? 0]?.isoCode;
                }, filteringEnabled: true
            },
            {
                name: "BuyAmount", title: "Buy Amnt", getCellValue: (trade: TradeBookTradeModel) => {
                    if (trade.type === "TradeBookSecurityTradeModel") {
                        return;
                    }
                    return trade.purchasedAmount;
                },
                columnType: "decimal",
                aggregationType: "sum", filteringEnabled: true
            },
            {
                name: "BuyCurrency", title: "Buy Ccy", getCellValue: (trade: TradeBookTradeModel) => {
                    if (trade.type === "TradeBookSecurityTradeModel") {
                        return;
                    }
                    return referenceCurrencies[trade.purchasedCurrencyId ?? 0]?.isoCode;
                }
            },
            { name: "Status", title: "Status", getCellValue: ({ status }: TradeBookTradeModel) => splitPascalCase(status), filteringEnabled: true },
            { name: "TargetWeight", title: "Target Weight", getCellValue: ({ targetWeight }: TradeBookTradeModel) => targetWeight, columnType: "precisePercentage2", filteringEnabled: true },
            { name: "Limit", title: "Limit", getCellValue: ({ limit }: TradeBookTradeModel) => limit, columnType: "decimal", filteringEnabled: true },
            { name: "OrderType", title: "Order Type", getCellValue: ({ orderType }: TradeBookTradeModel) => orderTypes[orderType] },
            { name: "ExpirationDate", title: "Expiration Date", getCellValue: ({ expirationDateTime }: TradeBookTradeModel) => expirationDateTime, columnType: "date" },
            { name: "Comment", title: "Comment", getCellValue: ({ comment }: TradeBookTradeModel) => comment?.en, filteringEnabled: true },

        ];
        if (isGranted(IFeatureModel.TradeBookWrite)) {
            ret.unshift({
                name: "Delete", title: "Delete", getCellValue: (row: TradeBookTradeModel & { rowIndex: number }) => <IconButton size="small" onClick={handleDeleteClick.bind(null, row)} color="inherit">
                    <DeleteIcon />
                </IconButton>
            });
        }
        return ret;
    }, [isGranted, onDeleteTrade, portfolios, referenceCurrencies, securities]);
    const state: IGridState = {
        "PortfolioName": { width: 280 },
        "PortfolioCode": { width: 150, hidden: true },
        "PortfolioType": { width: 120, hidden: true },
        "TargetSecurityCode": { width: 150, hidden: true },
        "TargetSecurityIsin": { width: 150, hidden: true },
        "TargetSecurityName": { width: 280 },
        "TargetSecurityCurrency": { width: 130 },
        "TargetSecurityType": { width: 120, hidden: true },
        "BuySell": { width: 100 },
        "Quantity": { width: 120 },
        "Amount": { width: 120 },
        "TargetWeight": { width: 120 },
        "Limit": { width: 120 },
        "OrderType": { width: 180 },
        "ExpirationDate": { width: 140 },
        "SoldAmount": { width: 120, hidden: false },
        "SoldCurrency": { width: 120, hidden: false },
        "BuyAmount": { width: 120, hidden: false },
        "BuyCurrency": { width: 120, hidden: false },
        "Disabled": { width: 100 },
        "Comment": { width: 280 },
        "Status": { width: 180 }
    };
    if (isGranted(IFeatureModel.TradeBookWrite)) {
        state.Delete = { width: 120 };
    }
    const handleRowSelect = ({ rowIndex }: TradeBookTradeModel & { rowIndex: number }) => onEditTrade(rowIndex);

    return <ExtendedGrid
        getRowId={getRowKey}
        onRowClick={isGranted(IFeatureModel.TradeBookWrite) ? handleRowSelect : undefined}
        columns={columns}
        rows={trades}
        initialState={state}
        userCanGroup={true}
        defaultExportFileName={`portfolioTrades.xlsx`} />
}
export default React.memo(TradeBooksTrades);