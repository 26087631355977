import { Epic } from "redux-observable";
import { from, merge, of } from "rxjs";
import { filter, map, share, distinctUntilChanged, switchMap, first, mergeMap, toArray, tap } from "rxjs/operators";
import { managedPortfoliosApi, IManagedPortfoliosSearchParameters, customScreensApi, studioTradeDatesApi, studioPortfolioComplianceApi, macroScriptsApi, dailyDataApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { IPortfolioTypeModel, PortfolioModel, IMacroScriptCheckResultModel, IFrequencyTypeModel } from "proxy/apiProxy";
import { tryParseNumber } from "tools/lib/utility";
import { mapToPayload, withLatestFromBuffer, changedNavigation, onlyNotNull } from "lib/rxJsUtility";
import { filterRoute } from "tools/lib/UrlDictionary";
import { IPortfolioLoadedPayload } from "features/ManagedPortfolio/slice";
import saveAs from "file-saver";
import { getConfig } from "lib/userManager";
export const loadEntities: Epic<IAnyAction>
    = action$ => {
        const portfoliosRequest$ = action$.pipe(
            mapToPayload("managedPortfolio", "portfolioLoadAll"),
            share());
        const portfolios$ = portfoliosRequest$.pipe(
            mergeMap(managedPortfoliosApi.searchAsync),
            share());
        const extraData$ = portfolios$.pipe(
            map(i => i.portfolios.map(j => j.id)),
            mergeMap(i => managedPortfoliosApi.getLastPortfolioDataAsync({ portfoliosIds: i })),
            share());
        return merge(
            portfolios$.pipe(map(ActionFactories.managedPortfolio.portfolioLoadedAll)),
            extraData$.pipe(map(ActionFactories.managedPortfolio.portfolioLastDataLoadedAll)));
    };
export const updatePosition: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedPortfolio", "portfolioPositionSave"),
        mergeMap(position => dailyDataApi.savePositionAsync({ position }).then(() => position)),
        map(ActionFactories.managedPortfolio.portfolioPositionSaved));

export const deletePosition: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedPortfolio", "portfolioPositionDelete"),
        mergeMap(positionId => dailyDataApi.deletePositionAsync({ id: positionId }).then(() => positionId)),
        map(ActionFactories.managedPortfolio.portfolioPositionDeleted));

export const loadEntityHistoricalValues: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedPortfolio", "portfolioHistoricalValuesLoad"),
        mergeMap(id => managedPortfoliosApi.getHistoricalValuesAsync({ id, mainShareClass: false })),
        map(ActionFactories.managedPortfolio.portfolioHistoricalValuesLoaded));
export const onOpenScreenPortfolios: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("MyPortfolios"),
        map(() => ({ onlyActive: true } as IManagedPortfoliosSearchParameters)),
        map(ActionFactories.managedPortfolio.portfolioLoadAll));
export const onOpenScreenPortfolio: Epic<IAnyAction>
    = action$ => {
        const portfolioToLoad$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
            filterRoute("MyPortfolios"),
            map(({ matchingSections }) => {
                if (!matchingSections?.detail) {
                    return;
                }
                const id = tryParseNumber(matchingSections?.detail?.id);
                if (id) {
                    return id;
                }
                else {
                    return matchingSections?.detail?.id as IPortfolioTypeModel;
                }
            }),
            onlyNotNull());
        return merge(
            portfolioToLoad$.pipe(map(ActionFactories.managedPortfolio.portfolioLoad)),
            portfolioToLoad$.pipe(map(() => ActionFactories.macroScript.macroScriptLoadAll())));
    }

export const onOpenScreenProcessExecutions: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.processes?.id),
        filterRoute("MyPortfolios", "processes"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.processes?.id)),
        filter(id => typeof id !== "undefined"),
        distinctUntilChanged(),
        map(id => ActionFactories.managedPortfolio.portfolioProcessExecutionsLoad(id as number)));
export const loadProcessExecutions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedPortfolio", "portfolioProcessExecutionsLoad"),
        mergeMap(id => managedPortfoliosApi.getProcessesAsync({ id })),
        map(ActionFactories.managedPortfolio.portfolioProcessExecutionsLoaded));


export const submitCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedPortfolio", "portfolioCustomScreenDataSubmit"),
        mergeMap(managedPortfoliosApi.submitCustomScreenDataAsync),
        map(ActionFactories.managedPortfolio.portfolioCustomScreenDataSubmitted));
export const loadCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedPortfolio", "portfolioCustomScreenDatasLoad"),
        mergeMap(id => managedPortfoliosApi.getLastCustomScreenDatasAsync({ id })),
        map(ActionFactories.managedPortfolio.portfolioCustomScreenDatasLoaded));

const { general: { enableStudio } } = getConfig();

async function getPortfolioAsync(id: number | IPortfolioTypeModel) {
    if (typeof id === "number") {
        const [loadedPortfolio, files, complianceMacro, dealingMacro] = await Promise.all([
            managedPortfoliosApi.getAsync({ id: id as number }),
            managedPortfoliosApi.getFilesAsync({ id: id as number }),
            enableStudio ? studioPortfolioComplianceApi.getPortfolioMacroAsync({ id: id as number }).then(i => i.script) : Promise.resolve(undefined),
            enableStudio ? studioTradeDatesApi.getPortfolioMacroAsync({ id: id as number }).then(i => i.script) : Promise.resolve(undefined),
        ]);
        return {
            ...loadedPortfolio,
            files,
            complianceMacro,
            dealingMacro
        };
    }
    else {
        return {
            portfolio: createEmptyPortfolio(id),
            entities: {},
            files: [],
            indexes: {},
            securities: {},
            complianceMacro: undefined,
            dealingMacro: undefined
        } as IPortfolioLoadedPayload;
    }
}

export const getFileContent: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedPortfolio", "portfolioFileContentLoad"),
        mergeMap(payload => managedPortfoliosApi.getFileContentAsync(payload)
            .then(file => ({ file, payload }))
            .catch(() => undefined)),
        onlyNotNull(),
        tap(({ file: { blob, fileName } }) => saveAs(blob, fileName)),
        map(({ payload }) => ActionFactories.managedPortfolio.portfolioFileContentLoaded(payload)));

export const loadPortfolio: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("managedPortfolio", "portfolioLoad"),
            share());

        const portfolio$ = requestedId$.pipe(
            mergeMap(getPortfolioAsync),
            share());

        const customScreens$ = requestedId$.pipe(
            mergeMap(() => customScreensApi.getAllAsync({})),
            map(customScreens => customScreens.filter(customScreen => customScreen.type === "PortfolioCustomScreenSummaryModel")),
            mergeMap(customScreens => from(customScreens).pipe(
                mergeMap(({ id }) => customScreensApi.getAsync({ id })),
                toArray(),
                map(ActionFactories.managedPortfolio.portfolioCustomScreensLoaded))));

        return merge(
            requestedId$.pipe(
                filter(id => typeof id === "number" && !!id),
                map(id => ActionFactories.managedPortfolio.portfolioCustomScreenDatasLoad(typeof id === "number" ? id : 0))),
            customScreens$,
            requestedId$.pipe(
                filter(() => enableStudio),
                first(),
                mergeMap(studioPortfolioComplianceApi.getUniverseStructureAsync),
                map(ActionFactories.managedPortfolio.portfolioCompliancePolicyValidateMetadataLoaded)),
            requestedId$.pipe(
                filter(() => enableStudio),
                first(),
                mergeMap(studioTradeDatesApi.getUniverseStructureAsync),
                map(ActionFactories.managedPortfolio.portfolioDealingPolicyValidateMetadataLoaded)),
            requestedId$.pipe(map(() => ActionFactories.parameters.parametersLoad())),
            portfolio$.pipe(map(ActionFactories.managedPortfolio.portfolioLoaded)),
            portfolio$.pipe(map(({ complianceMacro = "" }) => ActionFactories.managedPortfolio.portfolioCompliancePolicyValidateScript(complianceMacro))),
            portfolio$.pipe(map(({ dealingMacro = "" }) => ActionFactories.managedPortfolio.portfolioDealingPolicyValidateScript(dealingMacro)))
        );
    }

export const getMonitoringResultLoad: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedPortfolio", "portfolioMonitoringResultLoad"),
        mergeMap(macroScriptsApi.getMonitoringResultForTargetAsync),
        map(ActionFactories.managedPortfolio.portfolioMonitoringResultLoaded));

function createEmptyPortfolio(type: IPortfolioTypeModel): PortfolioModel {
    switch (type) {
        case IPortfolioTypeModel.Portfolio: return { type: "DiscretionaryPortfolioModel", extensionFieldsValues: {}, peers: [], id: 0, internalCode: "", name: "", shortName: "", classifications: {}, statisticDefinitionSets: [], dailyStatisticDefinitionSets: [], pricingFrequency: IFrequencyTypeModel.Daily, benchmarkExposures: [] };
        case IPortfolioTypeModel.SubFund: return { type: "ManagedSubFundModel", extensionFieldsValues: {}, subFundExtensionFieldsValues: {}, peers: [], id: 0, internalCode: "", name: "", shortName: "", classifications: {}, statisticDefinitionSets: [], dailyStatisticDefinitionSets: [], shareClassesId: [], pricingFrequency: IFrequencyTypeModel.Daily, isUnderManagement: true, benchmarkExposures: [], closings: [] };
    }
}
export const checkComplianceMacro: Epic<IAnyAction>
    = action$ => {
        const complianceMacro$ = action$.pipe(
            mapToPayload("managedPortfolio", "portfolioCompliancePolicyValidateScript"),
            filter(() => enableStudio),
            share());
        return merge(
            complianceMacro$.pipe(
                filter(m => m.trim() === ""),
                map(() => ({
                    errors: []
                } as IMacroScriptCheckResultModel))),
            complianceMacro$.pipe(
                filter(m => m.trim() !== ""),
                mergeMap(script => studioPortfolioComplianceApi.checkScriptAsync({ textModel: { text: script } })))
        ).pipe(map(ActionFactories.managedPortfolio.portfolioCompliancePolicyValidatedScript));
    }

export const checkDealingMacro: Epic<IAnyAction>
    = action$ => {
        const dealingMacro$ = action$.pipe(
            mapToPayload("managedPortfolio", "portfolioDealingPolicyValidateScript"),
            filter(() => enableStudio),
            share());
        return merge(
            dealingMacro$.pipe(
                filter(m => m.trim() === ""),
                map(() => ({
                    errors: []
                } as IMacroScriptCheckResultModel))),
            dealingMacro$.pipe(
                filter(m => m.trim() !== ""),
                mergeMap(script => studioTradeDatesApi.checkScriptAsync({ textModel: { text: script } })))
        ).pipe(map(ActionFactories.managedPortfolio.portfolioDealingPolicyValidatedScript));
    }

export const savePortfolio: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedPortfolio", "portfolioSave"),
        mergeMap(async ({ portfolio, files, complianceMacro, dealingMacro }) => {
            const savedPortfolio = await managedPortfoliosApi.saveAsync({ model: portfolio });
            if (enableStudio) {
                await studioPortfolioComplianceApi.savePortfolioMacroAsync({ id: savedPortfolio.id, complianceDefinition: { script: complianceMacro ?? "" } })
                await studioTradeDatesApi.savePortfolioMacroAsync({ id: savedPortfolio.id, dealingDefinition: { script: dealingMacro ?? "" } })
            }
            if (!files) {
                return { portfolio: savedPortfolio, files: [] };
            }
            await Promise.all(files.filter(i => !!i.toDelete).map(i => managedPortfoliosApi.deleteFileAsync(({ id: savedPortfolio.id, fileId: i.id }))))

            const savedFiles = await Promise.all(files.filter(i => !i.toDelete).map(async ({ content, toDelete, fileMimeType, fileName, contentToDelete, ...file }) => {
                const savedFile = await managedPortfoliosApi.saveFileAsync({ id: savedPortfolio.id, model: file });
                if (content && fileName) {
                    await managedPortfoliosApi.saveFileContentAsync({ fileId: savedFile.id, fileModel: { data: content.content, mimeType: content.mimeType, name: fileName } });
                    return { ...savedFile, fileName: content.fileName, fileMimeType: content.mimeType };
                }
                if (contentToDelete) {
                    await managedPortfoliosApi.deleteFileContentAsync({ fileId: savedFile.id });
                    return { ...savedFile, fileName: undefined, fileMimeType: undefined };
                }
                return savedFile;
            }));
            return { portfolio: savedPortfolio, files: savedFiles };
        }),
        map(ActionFactories.managedPortfolio.portfolioSaved));

export const deletePortfolio: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("managedPortfolio", "portfolioDelete"),
            mergeMap(id => managedPortfoliosApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.managedPortfolio.portfolioDeleted)
        );
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }

export const onOpenScreenPortfolioHistoricalSeries: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.historicalValues?.id),
        filterRoute("MyPortfolios", "historicalValues"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.historicalValues?.id)),
        filter(id => typeof id !== "undefined"),
        distinctUntilChanged(),
        map(id => ActionFactories.managedPortfolio.portfolioHistoricalValuesLoad(id as number)));

export const onOpenScreenPortfolioDatedScreen: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("MyPortfolios", "detail"),
        // filterRoute("Pms", "Portfolios", "MyPortfolios", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id)),
        filter(id => typeof id !== "undefined"),
        // distinctUntilChanged(),
        map(id => ActionFactories.managedPortfolio.portfolioPricingDatesLoad(id as number)));

export const loadPricingDates: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedPortfolio", "portfolioPricingDatesLoad"),
        mergeMap(id => managedPortfoliosApi.getPricingDatesAsync({ id })),
        map(ActionFactories.managedPortfolio.portfolioPricingDatesLoaded));

export const loadPricingDateData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedPortfolio", "portfolioPricingDatesLoaded"),
        map(pricingDates => {
            if (pricingDates.length === 0) {
                return;
            }
            return pricingDates.reduce((agg, v) => {
                if ((agg ? agg.getTime() : 0) - (v ? v.getTime() : 0) > 0) {
                    return agg;
                }
                return v;
            }, undefined as Date | undefined);
        }),
        filter(i => !!i),
        map(date => ActionFactories.managedPortfolio.portfolioPricingDateLoad(date as Date)));
export const loadPricingDate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedPortfolio", "portfolioPricingDateLoad"),
        withLatestFromBuffer(action$.pipe(mapToPayload("managedPortfolio", "portfolioLoad"), filter(id => typeof id === "number"))),
        switchMap(p => {
            const [pricingDate, key] = p;
            const id = key as number;
            const portfolioComposition$ = of({ pricingDate, id }).pipe(
                mergeMap(managedPortfoliosApi.getCompositionAsync));
            const portfolioBenchmarkComposition$ = of({ pricingDate, id }).pipe(
                mergeMap(managedPortfoliosApi.getBenchmarkCompositionAsync));
            return merge(
                portfolioComposition$.pipe(map(() => ActionFactories.managedPortfolio.portfolioPricingDateLoaded(pricingDate))),
                portfolioBenchmarkComposition$.pipe(map(ActionFactories.managedPortfolio.portfolioBenchmarkCompositionLoaded)),
                portfolioComposition$.pipe(map(ActionFactories.managedPortfolio.portfolioCompositionLoaded)));
        }));
