import DetailPanel from "tools/components/DetailPanel";
import { IFeatureModel, IPortfolioMacroModel } from "proxy/apiProxy";
import { FormikProps, Formik, FormikHelpers } from 'formik';
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import PortfolioScriptEditor from "features/ManagedPortfolio/PortfolioScriptEditor";
import { oProps } from "tools/lib/utility";
import WriteAccess from "tools/fieldComponents/WriteAccess";

export default function Connectors() {
    const { globalPortfolioComplianceSave, globalPortfolioComplianceValidateScript } = useReduxActions("globalPortfolioCompliance");
    const {
        globalPortfolioComplianceMacro,
        globalPortfolioComplianceMacroLoading,
        globalPortfolioComplianceMacroSaving,
        globalPortfolioComplianceMacroValidateScriptMetadata,
        globalPortfolioComplianceMacroCheckResult = { errors: [] } } = useReduxSelections("globalPortfolioCompliance");

    const handleSubmit = (model: IPortfolioMacroModel, { setSubmitting }: FormikHelpers<IPortfolioMacroModel>) => {
        globalPortfolioComplianceSave(model);
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={globalPortfolioComplianceMacro} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;

    function renderForm({ dirty, isValid, submitForm }: FormikProps<IPortfolioMacroModel>) {
        const handleSaveClick = () => {
            if (isValid && dirty)
                submitForm();
        }
        return <WriteAccess value={IFeatureModel.GlobalPortfolioCompliancePolicyWrite}><DetailPanel
            isQuerying={globalPortfolioComplianceMacroLoading || globalPortfolioComplianceMacroSaving}
            title="Global Portfolio Compliance Checks"
            noPadding={true}
            saveAllowed={IFeatureModel.GlobalPortfolioCompliancePolicyWrite}
            canSave={dirty && isValid}
            onSaveClick={handleSaveClick}>
            <PortfolioScriptEditor
                fieldName={oProps<IPortfolioMacroModel>().path("script")}
                metadata={globalPortfolioComplianceMacroValidateScriptMetadata}
                onSaveRequest={handleSaveClick}
                onScriptChanged={globalPortfolioComplianceValidateScript}
                scriptErrors={globalPortfolioComplianceMacroCheckResult.errors} />
        </DetailPanel>
        </WriteAccess>
    }
}
