import React from "react";
import Autocomplete, { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";
// import { makeStyles } from "@material-ui/core/styles";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { IUrlKey } from 'tools/lib/UrlDictionary';
import { ListItem, ListItemIcon, ListItemText, Popper, PopperProps } from "@material-ui/core";
import { FilterOptionsState } from "@material-ui/lab";
import { matchSorter, MatchSorterOptions } from "match-sorter";
import { useReduxSelections } from "tools/lib/reduxStoreAccess";

const SearchInput = withStyles((theme) =>
    createStyles({
        root: {
            marginLeft: theme.spacing(1),
            flex: 1
        }
    })
)(InputBase);
const StyledSearchIcon = withStyles((theme) =>
    createStyles({
        root: {
            // padding: 10,
            color: theme.palette.grey[500]
        }
    })
)(SearchIcon);
const SearchPaper = withStyles((theme) =>
    createStyles({
        root: {
            padding: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 250,
            // backgroundColor: theme.palette.primary.light
        }
    })
)(Paper);

// const useStyles = makeStyles({
//     option: {
//         fontSize: 15,
//         "& > span": {
//             marginRight: 10,
//             fontSize: 18
//         }
//     }
// });

export interface ISearchBarProps {
    options: ISearchOption[];
    onOptionSelected: (option: ISearchOption) => void;
}

export interface ISearchOption {
    screenKey: IUrlKey;
    keywords?: string;
    label: string;
    moduleLabel: string;
    moduleIcon: React.ReactElement<any>;
    moduleCategoryLabel: string;
}
const StyledPopper = function (props: PopperProps) {
    return <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />;
};

function filterOptions(
    options: ISearchOption[],
    { inputValue }: FilterOptionsState<ISearchOption>
) {
    if (!inputValue || !inputValue.length) {
        return [];
    }

    const terms = inputValue.split(" ");
    if (!terms) {
        return [];
    }

    // reduceRight will mean sorting is done by score for the _first_ entered word.
    return terms.reduceRight((results, term) => {
        const tmp = matchSorter<ISearchOption>(results, term, {
            keys: [
                ({ label }) => label,
                ({ moduleCategoryLabel }) => moduleCategoryLabel,
                ({ moduleLabel }) => moduleLabel,
                ({ keywords }) => keywords,
            ]
        } as MatchSorterOptions<ISearchOption>);
        return tmp;
    }, options);
}

export default function SearchBar({ options, onOptionSelected }: ISearchBarProps) {
    // const classes = useStyles();
    const getOptionLabel = ({ label, moduleLabel, moduleCategoryLabel }: ISearchOption) => "";//`${moduleCategoryLabel}/${moduleLabel}/${label}`
    const { searchShortcutGuid } = useReduxSelections("app");
    const inputRef = React.useRef<HTMLInputElement>();
    React.useEffect(() => {
        if (!searchShortcutGuid || !inputRef?.current) {
            return;
        }
        inputRef.current.focus();
    }, [searchShortcutGuid, inputRef])
    const renderOption = ({ label, moduleLabel, moduleCategoryLabel, moduleIcon }: ISearchOption) => <ListItem dense={true} button={false} style={{ padding: 0 }}>
        <ListItemIcon>{moduleIcon}</ListItemIcon>
        <ListItemText primary={label} secondary={`${moduleCategoryLabel}/${moduleLabel}`} />
    </ListItem>;
    const [currentValue, setCurrentValue] = React.useState({} as ISearchOption);
    const handleOnSelected = (j: React.ChangeEvent<{}>, searchOption: ISearchOption | null) => {
        setCurrentValue({} as ISearchOption);
        searchOption && onOptionSelected(searchOption);
    };

    const renderInput = (params: AutocompleteRenderInputParams) => {
        // inputRef = params.InputProps.ref as React.MutableRefObject<HTMLInputElement>;
        // params.InputProps.ref
        // const ref=(input)=>{

        // }
        return <SearchPaper>
            <SearchInput
                placeholder="Find screen"
                inputRef={inputRef}
                ref={params.InputProps.ref}
                inputProps={params.inputProps} />
            <StyledSearchIcon />
        </SearchPaper>;
    }
    return <Autocomplete
        PopperComponent={StyledPopper}
        filterOptions={filterOptions}
        options={options}
        value={currentValue}
        // classes={{
        //     option: classes.option
        // }}
        onChange={handleOnSelected}
        // onBlur={()=>}
        autoHighlight={true}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        renderInput={renderInput} />
}
