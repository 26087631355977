import DetailPanel, { ISubMenu } from "tools/components/DetailPanel";
import { SecurityModel, SecuritySummaryModel, IShareClassModel, IFeatureModel, IUniverseScopeTypeModel, IHistoricalValueSetModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import HistoricalValues, { IDataSeries } from "components/global/HistoricalValues";
import SecurityData from "./SecurityData";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import SecurityProxy from "./SecurityProxy";
import { oProps } from "tools/lib/utility";
import { getSecurityTypeLabel } from "./getSecurityTypeLabel";
import * as React from "react";
import { ISecurityData } from "features/Security/slice";
// import DueDiligenceExecutionData, { renewDueDiligence } from "features/Relationship/DueDiligenceExecutionData";
import { Chip } from "@material-ui/core";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import Ratings from "components/global/Ratings";
import CustomViewDatas from "features/Relationship/CustomViewDatas";
import useScopeButtonsMenu from "components/global/useScopeButtonsMenu";
import MonitoringResultGroups from "components/global/MonitoringResults";
import BriefcaseAccountIcon from "mdi-material-ui/BriefcaseAccount";
import AttachedDocuments from "components/global/AttachedDocuments";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { AddHistoricalValue } from "components/indexes/AddHistoricalValue";
import { ProcessExecutionList } from "features/ProcessExecution/ProcessExecutionList";
import { setFilesToDelete } from "../../reducers/IRelatedFileWithContent";
import NotebookCheckOutlineIcon from "mdi-material-ui/NotebookCheckOutline";

export default function Security() {
    const {
        referenceCountries = {},
        referenceCurrencies = {},
        ratingTypes = {}
    } = useReduxSelections("reference");
    const { navigationActiveScreen } = useReduxSelections("navigation");
    const { navigationNavigate } = useReduxActions("navigation");
    const { classificationTypes } = useReduxSelections("securityClassificationType");
    const { parameters: { securityExtensionFields = [] } } = useReduxSelections("parameters");
    const {
        current,
        proxy = [],
        dictionaries,
        historicalValues,
        historicalValuesVisualizationType,
        loading,
        saving,
        historicalLoading,
        // relatedProcesses = [],
        // complianceDueDiligence,
        // checksDueDiligence,
        ratings,
        securitySaveHistoricalValueSaving,

        customScreenDataLoading,
        customScreenDatas,
        customScreens,
        customScreenDataSubmitting,
        monitoringResults,
        files,
        processExecutionsLoading,
        processExecutions = []
    } = useReduxSelections("security");
    const { macroScriptList } = useReduxSelections("macroScript");
    const monitoringMacros = React.useMemo(() => macroScriptList.filter(i => (i.type === "MonitoringMacroScriptModel" || i.type === "MonitoringMacroScriptSummaryModel") && i.singleScope === IUniverseScopeTypeModel.Security), [macroScriptList]);

    const {
        securityDelete,
        securitySave,
        securityHistoricalValuesVisualizationTypeChanged,
        securityAddSecurityInDictionary,
        securityAddIndexInDictionary,
        securityAddSubFundInDictionary,

        // securityLoadComplianceFile,
        // securityLoadChecksFile,
        // securityLoadProcesses,
        securityLoadedRelationship,
        securityAddEntityInDictionary,
        securitySaveHistoricalValueSave,
        securityCustomScreenDataSubmit,
        securityMonitoringResultLoad,
        securityFileContentLoad
    } = useReduxActions("security");
    const {
        parametersMarketDataProviders,
        // parameters,
    } = useReduxSelections("parameters");
    const formValues: ISecurityData | undefined = React.useMemo(() => {
        if (!current) {
            return undefined;
        }
        const ret: ISecurityData = {
            security: current, proxy,
            files: files ?? []
        };
        return ret;
    }, [current, proxy, files]);
    const isGranted = useGrants();

    const [addingNewHistValue, setAddingNewHistValue] = React.useState(false);

    // if(isGranted(IFeatureModel.)){

    // }
    const handleBack = () => navigationNavigate(undefined);
    const extraReportActionButtons = useScopeButtonsMenu(IUniverseScopeTypeModel.Security, current?.id);
    const handleNewExecutionProcess = React.useCallback(() => {
        navigationNavigate({ screenKey: "ProcessExecutions", sectionKey: "detail", parameters: { type: "SecurityProcessExecutionModel", targetId: current?.id ?? 0 } });
    }, [current?.id, navigationNavigate])
    if (!formValues || !current) {
        return <DetailPanelLoading onBackClick={handleBack} tabNumber={2} hasSubTitle={true} />;
    }
    const title = !!current.id ? (current.name || "") : `New ${getSecurityTypeLabel(current.type)}`;
    const historicalValueProperties = getHistoricalValueTypes(current.type);
    const graphHistoricalValueProperties = getGraphHistoricalValueTypes(current.type);

    const parametersMarketDataProvidersInUse = parametersMarketDataProviders;
    // .filter(parametersMarketDataProvider => Object.keys(parameters.marketDataProviderConnectionSetups ?? {}).indexOf(parametersMarketDataProvider.marketDataProviderCode) >= 0);

    const handleTabValueChanged = (value: any) => navigationNavigate({ sectionKey: value as string });
    const handleDelete = () => {
        if (current?.id) {
            securityDelete(current.id);
        }
    }
    const handleSubmit = (values: ISecurityData, { setSubmitting }: FormikHelpers<ISecurityData>) => {
        setFilesToDelete(values, formValues)
        securitySave(values);
        setSubmitting(false);
    }
    const handleSecuritySelected = (securityId: number) => {
        const { type } = dictionaries.securities[securityId];
        switch (type) {
            case "LoanSummaryModel":
                navigationNavigate({ screenKey: "Loans", sectionKey: "detail", parameters: { id: securityId } });
                break;
            case "CashSummaryModel":
                navigationNavigate({ screenKey: "BankAccounts", sectionKey: "detail", parameters: { id: securityId } });
                break;
            default:
                navigationNavigate({ screenKey: "Securities", sectionKey: "detail", parameters: { id: securityId } });
                break;
        }
    }
    const handleLoadResult = (id: number) => securityMonitoringResultLoad({ id, targetId: current.id });
    const handleIndexSelected = (indexId: number) => {
        navigationNavigate({ screenKey: "Indexes", sectionKey: "detail", parameters: { id: indexId } });
    }
    const handleValueChanged = (date: Date, type: string, value: number | undefined) => {
        securitySaveHistoricalValueSave({
            historicalValue: {
                date,
                type,
                value: value
            },
            securityId: current.id
        })
    }

    const handleDataSubmit = (customScreenId: number, values: any) => securityCustomScreenDataSubmit({ id: formValues.security.id, customScreenId, values });
    const canEditCustomData = isGranted(IFeatureModel.UniverseCustomDataWrite);

    return <Formik onSubmit={handleSubmit} initialValues={formValues} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm, values, validateForm, setFieldValue }: FormikProps<ISecurityData>) {

        // const handleRenewCheckDueDiligence = () => {
        //     const newExecution = renewDueDiligence(values?.checksDueDiligence?.execution, relatedProcesses.filter(p => p.type === "SecurityInvestmentChecksProcessModel"));
        //     setFieldValue(oProps<ISecurityData>().path("checksDueDiligence", "files"), {}, true);
        //     setFieldValue(oProps<ISecurityData>().path("checksDueDiligence", "execution"), newExecution, true);
        //     setTimeout(() => validateForm().then(() =>
        //         navigationNavigate({ sectionKey: "checkDueDiligence" })
        //     ), 200);
        // }
        // const handleRemoveCheckDueDiligence = () => {
        //     setFieldValue(oProps<ISecurityData>().path("checksDueDiligence", "files"), undefined, true);
        //     setFieldValue(oProps<ISecurityData>().path("checksDueDiligence", "execution"), undefined, true);
        //     setTimeout(() => validateForm().then(() =>
        //         navigationNavigate({ sectionKey: "detail" })
        //     ), 200);
        // }
        // const handleRenewComplianceDueDiligence = () => {
        //     const newExecution = renewDueDiligence(values?.complianceDueDiligence?.execution, relatedProcesses.filter(p => p.type === "SecurityComplianceProcessModel"));
        //     setFieldValue(oProps<ISecurityData>().path("complianceDueDiligence", "files"), {}, true);
        //     setFieldValue(oProps<ISecurityData>().path("complianceDueDiligence", "execution"), newExecution, true);
        //     setTimeout(() => validateForm().then(() =>
        //         navigationNavigate({ sectionKey: "complianceDueDiligence" })
        //     ), 200);
        // }
        // const handleRemoveComplianceDueDiligence = () => {
        //     setFieldValue(oProps<ISecurityData>().path("complianceDueDiligence", "files"), undefined, true);
        //     setFieldValue(oProps<ISecurityData>().path("complianceDueDiligence", "execution"), undefined, true);
        //     setTimeout(() => validateForm().then(() =>
        //         navigationNavigate({ sectionKey: "detail" })
        //     ), 200);
        // }
        // const handleLoadProcesses = (processIds: number[], type: DueDiligenceProcessModel["type"]) => securityLoadProcesses({ [type]: processIds } as Record<DueDiligenceProcessModel["type"], number[]>);

        // const handleRequestCheckFileLoad = (taskCode: string) => securityLoadChecksFile({ id: values.security.id, taskCode });
        // const handleRequestComplianceFileLoad = (taskCode: string) => securityLoadComplianceFile({ id: values.security.id, taskCode });
        // const handleApproveChecksDueDiligence = () => {
        //     if (!values?.checksDueDiligence?.execution) {
        //         return;
        //     }
        //     setFieldValue(oProps<ISecurityData>().path("checksDueDiligence", "execution", "approvedById"), values?.checksDueDiligence?.execution?.approvedById ? undefined : 1, true);
        // }
        // const handleApproveComplianceDueDiligence = () => {
        //     if (!values?.complianceDueDiligence?.execution) {
        //         return;
        //     }
        //     setFieldValue(oProps<ISecurityData>().path("complianceDueDiligence", "execution", "approvedById"), values?.complianceDueDiligence?.execution?.approvedById ? undefined : 1, true);
        // }

        const tabs: ISubMenu[] = [{
            label: "Data",
            value: "detail"
        }, {
            label: "Proxy",
            value: "proxy"
        }];
        if (!!monitoringMacros.length) {
            tabs.push({
                label: "Dashboard",
                value: "dashboard"
            });
        }
        if (values.security.id) {
            tabs.push({
                label: "Historical series",
                value: "historicalValues"
            });
            tabs.push({
                label: "Processes",
                value: "processes"
            });
        }
        if (values.security.type === "BondModel" && !!ratings) {
            tabs.push({
                label: "Ratings",
                value: "ratings"
            });
        }
        tabs.push({
            label: "Documents",
            value: "files"
        });
        // if (values?.checksDueDiligence?.execution) {
        //     tabs.push({
        //         label: <Badge badgeContent={(!!values?.checksDueDiligence?.execution?.approvedById) ? <CertificateOutlineIcon fontSize="small" /> : undefined} >Investment Checks</Badge>,
        //         value: "checkDueDiligence"
        //     });
        // }
        // if (values?.complianceDueDiligence?.execution) {
        //     tabs.push({
        //         label: <Badge badgeContent={(!!values?.complianceDueDiligence?.execution?.approvedById) ? <CertificateOutlineIcon fontSize="small" /> : undefined} >Compliance Due Diligence</Badge>,
        //         value: "complianceDueDiligence"
        //     });
        // }
        if (values?.security?.id && !!customScreens && customScreens.length) {
            tabs.push({
                label: "Custom Data",
                value: "customViews"
            });
        }

        const tabValue = navigationActiveScreen?.activeSectionKey;
        // const extraActionButtons: IActionButton[] = [...extraReportActionButtons];
        const extraActionButtons = (() => {
            const ret = [...extraReportActionButtons];
            if (tabValue === "historicalValues") {
                ret.push({
                    label: "Add new Historical Value",
                    onClick: () => setAddingNewHistValue(true),
                    icon: PlaylistAddIcon
                });
            }
            ret.push({
                label: "Add Process Execution",
                icon: NotebookCheckOutlineIcon,
                onClick: handleNewExecutionProcess,
                feature: IFeatureModel.UniverseDueDiligenceWrite//,
            });
            return ret;
        })();
        const handleCloseNewHistValue = (hv: IHistoricalValueSetModel) => {
            setAddingNewHistValue(false);
            if (current?.id) {
                securitySaveHistoricalValueSave({
                    historicalValue: hv,
                    securityId: current.id
                });
            }
        };
        const handleCloseNewHistValueCancel = () => setAddingNewHistValue(false);
        const handleFileOpen = (fileId: number) => current && securityFileContentLoad({ fileId });
        return <DetailPanel
            endAdornment={(values.security.type === "ShareClassModel" && values.security.isUnderManagement) ? <Chip icon={<BriefcaseAccountIcon />} size="small" label="Under management" /> : undefined}
            isQuerying={loading || historicalLoading || saving || securitySaveHistoricalValueSaving || customScreenDataLoading || customScreenDataSubmitting || processExecutionsLoading}
            title={title}
            subTitle={getSecurityTypeLabel(values.security.type)}
            canSave={dirty && isValid}
            onSaveClick={values.security.type === "ShareClassModel" && values.security.isUnderManagement ? undefined : submitForm}
            canDelete={!!values.security.id}
            onDeleteClick={values.security.type === "ShareClassModel" && values.security.isUnderManagement ? undefined : handleDelete}
            onBackClick={handleBack}
            saveAllowed={IFeatureModel.UniverseWrite}
            deleteAllowed={IFeatureModel.UniverseDelete}
            badge={!values.security.id ? "new" : undefined}
            tabs={tabs}
            actions={extraActionButtons}
            tabValue={tabValue}
            onTabValueChanged={handleTabValueChanged}>
            <AddHistoricalValue
                isOpened={addingNewHistValue}
                onAdd={handleCloseNewHistValue}
                onCancel={handleCloseNewHistValueCancel}
                typeDescriptions={historicalValueProperties} />

            {(tabValue === "detail") && <WriteAccess value={IFeatureModel.UniverseWrite}><SecurityData
                securityExtensionFields={securityExtensionFields}
                formFieldName={oProps<ISecurityData>().path("security")}
                onRelationshipLoaded={securityLoadedRelationship}
                formValues={values.security}
                referenceCountries={referenceCountries}
                referenceCurrencies={referenceCurrencies}
                previousSubFundId={(values.security as IShareClassModel).subFundId}
                onSubFundLoaded={securityAddSubFundInDictionary}
                dictionaries={dictionaries}
                classificationTypes={classificationTypes}
                onEntityLoaded={securityAddEntityInDictionary}
                parametersMarketDataProviders={parametersMarketDataProvidersInUse} /></WriteAccess>}
            {(tabValue === "dashboard" && !!monitoringMacros.length) && <MonitoringResultGroups
                monitoringMacros={monitoringMacros}
                onLoadResult={handleLoadResult}
                resultGroups={monitoringResults} />}
            {(tabValue === "proxy") && <WriteAccess value={IFeatureModel.SecurityWriteProxy}><SecurityProxy
                fieldPath={oProps<ISecurityData>().path("proxy")}
                referenceCountries={referenceCountries}
                referenceCurrencies={referenceCurrencies}
                dictionaries={dictionaries}
                onShareClassLoaded={securityAddSecurityInDictionary}
                onIndexLoaded={securityAddIndexInDictionary}
                onSecurityClick={handleSecuritySelected}
                onIndexClick={handleIndexSelected} /></WriteAccess>}
            {(tabValue === "historicalValues") && <HistoricalValues
                historicalValues={historicalValues}
                properties={historicalValueProperties}
                graphProperties={graphHistoricalValueProperties}
                onVisualizationTypeChanged={securityHistoricalValuesVisualizationTypeChanged}
                visualizationType={historicalValuesVisualizationType}
                editable={isGranted(IFeatureModel.UniverseWrite)}
                onValueChanged={handleValueChanged}
            />}
            {(tabValue === "files") && <AttachedDocuments
                formFieldName={oProps<ISecurityData>().path("files")}
                onFileOpen={handleFileOpen} />}
            {(tabValue === "ratings" && !!ratings) && <Ratings
                ratingTypes={ratingTypes}
                ratings={ratings} />}
            {(tabValue === "customViews" && !!customScreenDatas && !!customScreens?.length) && <CustomViewDatas
                canEdit={canEditCustomData}
                dictionaries={dictionaries}
                customScreenLoading={customScreenDataLoading}
                dataSubmitting={customScreenDataSubmitting}
                customScreens={customScreens}
                onDataSubmit={handleDataSubmit}
                customScreenDatas={customScreenDatas} />}
            {(tabValue === "processes") && <ProcessExecutionList
                processExecutions={processExecutions}
                dictionaries={dictionaries} />}
        </DetailPanel>;
    }
}
function getGraphHistoricalValueTypes(securityType: (SecurityModel | SecuritySummaryModel)["type"]): IDataSeries {
    switch (securityType) {
        case "EquityModel":
        case "EquitySummaryModel":
            return {
                MKT: "Price",
                // "TRP": "Total return price",
                // "DIV": "Dividend",
                // "VOL": "Volatility",
                // "CAP": "Market capitalization",
                // "FFL": "Free float",
            };
        case "ShareClassModel":
        case "ShareClassSummaryModel":
            return {
                MKT: "NAV",
                // "TNA": "Total net asset",
                // "TRP": "Total return price",
                // "DIV": "Dividend",
                // "NBS": "Number of shares",
                // "SRRI": "SRRI",
                // "SUB": "Subscription",
                // "RED": "Redemption",
                // "TER": "Total expense ratio",
                // "OGC": "Ongoing charges",
            };
        case "BondModel":
        case "BondSummaryModel":
            return {
                MKT: "Clean price",
                BDP: "Dirty price",
                // "ACC": "Accrued interest",
            };
        case "CashSummaryModel":
            return {
                MKT: "Price",
            };
        case "FxForwardModel":
        case "FxForwardSummaryModel":
            return {
                MKT: "Price",
            };
        case "CommodityModel":
        case "CommoditySummaryModel":
            return {
                MKT: "Price",
            };
        // case "Option":
        //     return {
        //         "DTA": "Delta",
        //     };
        // case "Future":
        //     return {
        //         "DTA": "Delta",
        //     };
    }
    return {};
}
function getHistoricalValueTypes(securityType: (SecurityModel | SecuritySummaryModel)["type"]): IDataSeries {
    switch (securityType) {
        case "EquityModel":
        case "EquitySummaryModel":
            return {
                MKT: "Price",
                TRP: { title: "Adjusted price", columnType: "preciseDecimal" },
                VOLU: "Volume",
                DIV: "Dividend",
                //[IHistoricalValueTypeModel.VOLA]: "Volatility",
                CAP: "Market capitalization",
                FFL: "Free float",
                ESG: "ESG Rating",
                BID: "Bid",
                ASK: "Ask",
                DYLD: { title: "Div. Yield", columnType: "precisePercentage2" },
                PER: "P/E Ratio",
                FPER: "Forward P/E",
                BETA: "Beta",
                DTEQ: "Debt To Equity",
                DTMC: "Debt To MarketCap",
                DIVU: "Dividend unadjusted",
                SPLT: "Split ratio",
            };
        case "EtfModel":
        case "EtfSummaryModel":
            return {
                MKT: "Price",
                TRP: { title: "Adjusted price", columnType: "preciseDecimal" },
                DIV: "Dividend",
                //[IHistoricalValueTypeModel.VOLA]: "Volatility",
                CAP: "Market capitalization",
                FFL: "Free float",
                DIVU: "Dividend unadjusted",
            };
        case "ShareClassModel":
        case "ShareClassSummaryModel":
            return {
                MKT: "NAV",
                TNA: "Total net asset",
                TRP: { title: "Adjusted price", columnType: "preciseDecimal" },
                DIV: "Dividend",
                NBS: "Number of shares",
                SRRI: "SRRI",
                SUB: "Subscription",
                RED: "Redemption",
                TER: "Total expense ratio",
                OGC: "Ongoing charges",
                FSU: "Forecasted subscription",
                FRE: "Forecasted redemption",
                DIVU: "Dividend unadjusted",
            };
        case "BondModel":
        case "BondSummaryModel":
            return {
                MKT: "Clean price",
                BDP: "Dirty price",
                ACC: "Accrued interest",
                YTM: "Yield to maturity",
            };
        // case "Cash":
        //     return {};
        //     // return {
        //     //     [IHistoricalValueType.MKT]: "Price",
        //     // };
        case "FxForwardModel":
        case "FxForwardSummaryModel":
            return {
                MKT: "Price",
            };
        case "CommodityModel":
        case "CommoditySummaryModel":
            return {
                MKT: "Price",
            };
        // case ISecurityTypeModel.Option:
        //     return {};
        //     //  {
        //     //     [IHistoricalValueType.DTA]: "Delta",
        //     // };
        // case ISecurityTypeModel.Future:
        //     return {};
        //     // return {
        //     //     [IHistoricalValueType.DTA]: "Delta",
        //     // };
    }
    return {};
}
