import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import PortfolioTransactions from "./PortfolioTransactions";
import PortfolioTransaction from "./PortfolioTransaction";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class PortfolioTransactionScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute slideDirection={"right"}
                gridSize={12}
                exact={true}
                component={PortfolioTransactions} path={getSiteScreenUrl("PortfolioTransactions")} />
            <NavigationRoute gridSize={12} slideDirection={"left"} component={PortfolioTransaction} path={getSiteDetailScreenUrl("PortfolioTransactions")} />
        </>;
    }
}

export default PortfolioTransactionScreen;
