import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";
import Dashboard from "./Dashboard";
import Dashboards from "./Dashboards";
import DashboardsExecution from "./DashboardsExecution";
import DashboardsDropFiles from "./DashboardsDropFiles";
import DashboardsGetFiles from "./DashboardsGetFiles";

@autobind
class DashboardScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                gridSize={12}
                slideDirection={"right"}
                exact={true}
                component={Dashboards}
                path={getSiteScreenUrl("Dashboards")} />
            <NavigationRoute
                gridSize={12}
                slideDirection={"right"}
                exact={true}
                component={DashboardsGetFiles}
                path={getSiteScreenUrl("DashboardsGetFiles")} />
            <NavigationRoute
                gridSize={12}
                slideDirection={"right"}
                exact={true}
                component={DashboardsExecution}
                path={getSiteScreenUrl("DashboardsExecute")} />
            <NavigationRoute
                gridSize={12}
                slideDirection={"right"}
                exact={true}
                component={DashboardsDropFiles}
                path={getSiteScreenUrl("DashboardsDropFiles")} />

            <NavigationRoute
                gridSize={12}
                slideDirection={"left"}
                component={Dashboard}
                path={getSiteDetailScreenUrl("Dashboards")} />
        </>
    }
}

export default DashboardScreen;
