import * as React from "react";
import { IFeatureModel, CustomScreenModel, CustomScreenSummaryModel } from "proxy/apiProxy";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import SearchPanel from "tools/components/SearchPanel";
import { useReduxSelections, useReduxActions, useGrants } from "tools/lib/reduxStoreAccess";
import { IActionButton } from "tools/components/FabContainer";
import AddIcon from '@material-ui/icons/Add';
import { getCustomScreenTypeLabel } from "./getCustomScreenTypeLabel";
export default function MacroScripts() {

    const { customScreenAllLoading, customScreens } = useReduxSelections("customScreen");
    const { referenceMacroScriptCategories = {} } = useReduxSelections("reference");

    const { navigationNavigate } = useReduxActions("navigation");

    const handleRowSelect = ({ id }: (CustomScreenModel | CustomScreenSummaryModel)) => navigationNavigate({ sectionKey: "detail", parameters: { id } });
    const isGranted = useGrants();
    const columns: IColumnDefinition[] = [
        {
            name: "Name",
            title: "Name",
            getCellValue: (row: (CustomScreenModel | CustomScreenSummaryModel)) => row.name,
            positionToKeep: "left",
            filteringEnabled: true
        },
        {
            name: "Code",
            title: "Code",
            getCellValue: (row: (CustomScreenModel | CustomScreenSummaryModel)) => row.code,
            positionToKeep: "left",
            filteringEnabled: true
        },
        {
            name: "Category",
            title: "Category",
            getCellValue: (row: (CustomScreenModel | CustomScreenSummaryModel)) => referenceMacroScriptCategories[row.categoryId ?? 0]?.name
        },
        {
            name: "Type",
            title: "Type",
            getCellValue: (row: (CustomScreenModel | CustomScreenSummaryModel)) => getCustomScreenTypeLabel(row.type),
            positionToKeep: "left"
        },
        {
            name: "Version",
            title: "Version",
            getCellValue: (row: (CustomScreenModel | CustomScreenSummaryModel)) => row.publishedVersion,
        },
        {
            name: "PublishOnPortal",
            title: "Publish on portal",
            getCellValue: (row: (CustomScreenModel | CustomScreenSummaryModel)) => row.publishOnPortal,
            columnType: "boolean",
            positionToKeep: "left"
        },
        {
            name: "LastUpdate",
            title: "Last update",
            getCellValue: (row: (CustomScreenModel | CustomScreenSummaryModel)) => row.updatedOn,
            columnType: "dateTime"
        }
    ];

    const state: IGridState = {
        "Name": { width: 320 },
        "Code": { width: 280 },
        "Category": { width: 200 },
        "Type": { width: 120 },
        "Version": { width: 120 },
        "LastUpdate": { width: 140 }
    };

    const getRowKey = (row: (CustomScreenModel | CustomScreenSummaryModel)) => row.id || 0;
    // const computationMacroScriptTypeLabel = `${this.getMacroScriptTypeLabel() || ""} macroScript`;
    const extraActionButtons: IActionButton[] | undefined = React.useMemo(() => {
        if (!isGranted(IFeatureModel.CustomScreenWrite)) {
            return;
        }
        const types: CustomScreenModel["type"][] = [
            "InvestorCustomScreenModel",
            "CounterpartyCustomScreenModel",
            "RoleCustomScreenModel",
            "EntityCustomScreenModel",
            "PortfolioCustomScreenModel",
            "ManagedSicavCustomScreenModel",
            "ManagedShareClassCustomScreenModel",
            "SubFundCustomScreenModel",
            "SecurityInvestmentCustomScreenModel",
            "SicavCustomScreenModel",
        ];
        const actions = types.map(key => ({
            label: getCustomScreenTypeLabel(key),
            onClick: () => navigationNavigate({ sectionKey: "detail", parameters: { id: key } }),
        }));
        if (!actions.length) {
            return [];
        }
        return [{
            label: "Add macro",
            icon: AddIcon,
            actions: actions
        }];
    }, [isGranted, navigationNavigate]);

    return <>
        <SearchPanel
            title="Custom Screens"
            isQuerying={customScreenAllLoading}
            actions={extraActionButtons}>
            <ExtendedGrid
                rows={customScreens}
                columns={columns}
                getRowId={getRowKey}
                onRowClick={handleRowSelect}
                initialState={state}
                userCanGroup={true}
                defaultExportFileName="CustomScreens.xlsx" />
        </SearchPanel>
    </>
}

