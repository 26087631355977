import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IFeatureModel, INoteModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";

export default function Notes() {
    const { navigationNavigate } = useReduxActions("navigation");
    const { allLoading, all } = useReduxSelections("note");
    const {
        classificationTypes,
        classificationTypesFlat
    } = useReduxSelections("noteClassificationType");
    const { classificationTypesColumns, classificationTypesState } = getClassificationTypesColumnDefinitions(classificationTypes, classificationTypesFlat, ({ classifications }: INoteModel) => classifications);

    const columns: IColumnDefinition[] = [
        { name: "Code", title: "Code", getCellValue: ({ code }: INoteModel) => code, filteringEnabled: true },
        { name: "Label", title: "Label", getCellValue: ({ label }: INoteModel) => label, filteringEnabled: true },
        ...classificationTypesColumns
    ];

    const state: IGridState = {
        "Code": { width: 250 },
        "Label": { width: 400, },
        ...classificationTypesState
    };

    const getRowKey = ({ id }: INoteModel) => id;

    const handleRowSelect = ({ id }: INoteModel) => navigationNavigate({ sectionKey: "detail", parameters: { id } });

    const handleAddNewClick = () => navigationNavigate({ sectionKey: "detail", parameters: { id: 0 } });

    return <SearchPanel title="Notes" isQuerying={allLoading}
        addAllowed={[IFeatureModel.GeneralNoteWrite, IFeatureModel.ManagedPortfolioWrite]}
        onAddClick={handleAddNewClick}>
        <ExtendedGrid
            getRowId={getRowKey}
            columns={columns}
            rows={all}
            onRowClick={handleRowSelect}
            initialState={state}
            userCanGroup={true}
            defaultExportFileName="Notes.xlsx" />
    </SearchPanel>
}
