import {Epic} from "redux-observable";
import {merge} from "rxjs";
import {filter, first, map, mergeMap, share} from "rxjs/operators";
import {batchesApi, IBatchModel, IMacroScriptCheckResultModel} from "proxy/apiProxy";
import {ActionFactories, IAnyAction} from "reducers";
import {changedNavigation, mapToPayload} from "lib/rxJsUtility";
import {tryParseNumber} from "tools/lib/utility";
import {filterRoute} from "tools/lib/UrlDictionary";
import {parse as jsonParse} from "jsonc-parser";

export const onOpenScreenBatches: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("BatchDefinitions"),
        map(() => ActionFactories.batch.batchLoadAll()));
export const onOpenScreenBatch: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("BatchDefinitions", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? 0),
        map(batchId => ActionFactories.batch.batchLoad(batchId as number)));
export const loadBatches: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("batch", "batchLoadAll"),
        mergeMap(() => batchesApi.getAllAsync({})),
        map(ActionFactories.batch.batchLoadedAll));
export const loadBatch: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("batch", "batchLoad"),
            share()
        );
        const batch$ = merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => batchesApi.getAsync({ id }))),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({
                    entities: {},
                    batch: createEmptyBatch()
                }))),
        );
        return merge(
            requestedId$.pipe(
                mergeMap(batchesApi.getScriptSchemaAsync),
                map(i => ActionFactories.batch.batchSchemaLoaded(jsonParse(i)))),
            batch$.pipe(map(ActionFactories.batch.batchLoaded)),
            requestedId$.pipe(
                first(),
                mergeMap(batchesApi.getUniverseStructureAsync),
                map(ActionFactories.batch.batchConditionalScriptMetadataLoaded)),
            batch$.pipe(map(({ batch: { conditionalScript = "" } }) => ActionFactories.batch.batchConditionalScriptValidate(conditionalScript))),
        );
    };


export const checkDealingMacro: Epic<IAnyAction>
    = action$ => {
        const conditionalMacro$ = action$.pipe(
            mapToPayload("batch", "batchConditionalScriptValidate"),
            share());
        return merge(
            conditionalMacro$.pipe(
                filter(m => m.trim() === ""),
                map(() => ({
                    errors: []
                } as IMacroScriptCheckResultModel))),
            conditionalMacro$.pipe(
                filter(m => m.trim() !== ""),
                mergeMap(script => batchesApi.checkGlobalConditionScriptAsync({ textModel: { text: script } })))
        ).pipe(map(ActionFactories.batch.batchConditionalScriptValidated));
    }

function createEmptyBatch(): IBatchModel {
    return {
        id: 0,
        name: "",
        code: "",
        script: "{}",
        publishedVersion: 0,
        ownerIds: [],
        emailOnlyOnError: false
    };
}

export const runBatch: Epic<IAnyAction> = action$ =>
    action$.pipe(
        mapToPayload("batch", "batchRequestExecution"),
        mergeMap(payload => batchesApi.runAsync(payload).then(() => payload.id)),
        map(ActionFactories.batch.batchRequestedExecution)
    )

export const stopBatch: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("batch", "batchStop"),
        mergeMap(id => batchesApi.stopAsync({ id }).then(() => id)),
        map(ActionFactories.batch.batchStopped));
export const saveBatch: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("batch", "batchSave"),
        mergeMap(model => batchesApi.saveAsync({ model })),
        map(ActionFactories.batch.batchSaved));

export const deleteBatch: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("batch", "batchDelete"),
            mergeMap(id => batchesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.batch.batchDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };
