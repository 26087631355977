import * as React from "react";
import SearchPanel from "tools/components/SearchPanel";
import { FormikProps, Formik, FormikHelpers } from "formik";
import { IDeltaModel, IFeatureModel, IStudioVersionsGetAllParameters } from "proxy/apiProxy";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { IErrors, oProps, today, addMonths, formatDateTime, getEnumLabels } from "tools/lib/utility";
import FieldBox from "tools/components/FieldBox";
import { Typography, Card, CardHeader, Chip, Box, Breadcrumbs, IconButton, Tooltip, CardContent, withStyles, createStyles, LinearProgress } from "@material-ui/core";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from "@material-ui/lab";
import { IVersionTypeModel, IVersionModel, IFileModel, EntitySummaryModel } from "proxy/apiProxy";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import FaceIcon from '@material-ui/icons/Face';
import ViewDashboardOutlineIcon from 'mdi-material-ui/ViewDashboardOutline';
import ExportIcon from 'mdi-material-ui/HomeExportOutline';
import ImportIcon from 'mdi-material-ui/HomeImportOutline';
import { DiffEditor } from "@monaco-editor/react";
import FileSelectDialog from "components/global/FileSelectDialog";
import { getEntityName } from "tools/lib/modelUtils";
import { IActionButton } from "tools/components/FabContainer";
import DetailPanel from "tools/components/DetailPanel";
import NotGranted from 'components/global/NotGranted';
import FormTextField from "tools/fieldComponents/FormTextField";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import RestoreIcon from '@material-ui/icons/Restore';
import AlertDialog from "tools/components/AlertDialog";

const versionTypeModel = getEnumLabels(IVersionTypeModel);

const StyledCardContent = withStyles((theme) =>
    createStyles({
        root: {
            padding: "0 !important"
        }
    })
)(CardContent);
const StyledTimelineOppositeContent = withStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        }
    })
)(TimelineOppositeContent);

export default function Positions() {
    const { versionsSearch, versionsChangeLoad, versionsAllLatestsLoad, versionsImport, versionRestore } = useReduxActions("versioning");
    const { versionsImporting, versionRestoring, versionsLoading, versions, dictionaries: { entities }, versionCurrentDelta: { loaded: currentDeltaLoaded, requested: currentDeltaRequested }, versionsExporting } = useReduxSelections("versioning");
    const [showImportInputFileDialog, setShowImportInputFileDialog] = React.useState(false);
    const [importInputFileModel, setImportInputFileModel] = React.useState<IFileModel | undefined>();
    // const { navigationNavigate } = useReduxActions("navigation");
    const isGranted = useGrants();
    const title = "Changes history";
    const [toBeRestored, setToBeRestored] = React.useState<IVersionModel | undefined>();
    if (!isGranted(IFeatureModel.ChangesView)) {
        return <DetailPanel title={title}>
            <NotGranted />
        </DetailPanel>
    }

    const handleSubmit = (model: IStudioVersionsGetAllParameters, { setSubmitting }: FormikHelpers<IStudioVersionsGetAllParameters>) => {
        versionsSearch(model);
        setSubmitting(false);
    }
    const handleValidate = (values: IStudioVersionsGetAllParameters) => {
        const errors: IErrors<IStudioVersionsGetAllParameters> = {};
        if (values.to && values.from > values.to) {
            errors.to = "Must be greater than the start of period";
        }
        return errors;
    }
    // const handleVersionSelect = (version: IVersionModel) => {
    //     navigationNavigate({ moduleKey: "", screenKey: "", sectionKey: "", parameters: {} });
    // }
    const initialValues: IStudioVersionsGetAllParameters = { from: addMonths(today(), -1) }
    const handleLoadChange = (v: IVersionModel) => versionsChangeLoad({ versionId: v.versionId, versionType: v.type as string });
    const handleAskRestore = (v: IVersionModel) => setToBeRestored(v);
    const handleHideImportInputFileDialog = () => setShowImportInputFileDialog(false);
    const handleShowImportInputFileDialog = () => setShowImportInputFileDialog(true);


    const extraActionButtons: IActionButton[] = [{
        label: "Export backup from current system",
        onClick: versionsAllLatestsLoad,
        icon: ExportIcon,
    }, {
        label: "Import backup into current system",
        onClick: handleShowImportInputFileDialog,
        icon: ImportIcon,
        feature: IFeatureModel.SystemImport
    }];
    const handleImportFileSelected = () => {
        if (importInputFileModel) {
            versionsImport(importInputFileModel);
            setShowImportInputFileDialog(false);
        }
    }
    const handleCloseAskRestore = (response: boolean) => {
        if (response && toBeRestored) {
            versionRestore(toBeRestored);
        }
        setTimeout(() => setToBeRestored(undefined), 0);
    }
    return <Formik onSubmit={handleSubmit} validate={handleValidate} initialValues={initialValues} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    // https://codesandbox.io/s/hardcore-shadow-67xci?file=/demo.tsx:611-2287
    function renderForm({ isValid, submitForm }: FormikProps<IStudioVersionsGetAllParameters>) {
        return <>
            <AlertDialog open={!!toBeRestored} onClose={handleCloseAskRestore} title="Restore">
                Do you really want to restore this version?
            </AlertDialog>
            <FileSelectDialog
                title="Choose a file to be imported"
                label="Drop the file to import here"
                isOpened={showImportInputFileDialog}
                onClose={handleHideImportInputFileDialog}
                onFileSelected={setImportInputFileModel}
                selectedFile={importInputFileModel}
                onOk={handleImportFileSelected}
                okLabel="Import" />
            <SearchPanel
                isQuerying={versionsLoading || versionsExporting || versionsImporting}
                onSearchClick={submitForm}
                searchDisabled={!isValid}
                actions={extraActionButtons}
                title={title}
                subTitle="Search by time period"
                renderSearch={(<FieldBox display="flex" flexDirection="column">
                    <FieldBox display="flex" flexDirection="row">
                        <FormTextField name={oProps<IStudioVersionsGetAllParameters>().path("criterias")} label="Criteria" />
                        <FormSimpleSelectField name={oProps<IStudioVersionsGetAllParameters>().path("versionType")} options={versionTypeModel} label="Object type" />
                    </FieldBox>
                    <FieldBox display="flex" flexDirection="row">
                        <FormDatePickerField name={oProps<IStudioVersionsGetAllParameters>().path("from")} label="From" required={true} />
                        <FormDatePickerField name={oProps<IStudioVersionsGetAllParameters>().path("to")} label="To" />
                    </FieldBox>
                </FieldBox>
                )}>
                <Timeline>
                    {versions.map(version => <VersionItem
                        key={`${version.versionId}-${version.type}`}
                        deltaRequesting={!!currentDeltaRequested && currentDeltaRequested.versionId === version.versionId && currentDeltaRequested.versionType === version.type}
                        entities={entities}
                        onLoadChange={handleLoadChange}
                        onRestore={handleAskRestore}
                        restoring={versionRestoring}
                        version={version}
                        delta={(currentDeltaRequested && currentDeltaRequested.versionId === version.versionId && currentDeltaRequested.versionType === version.type) ? currentDeltaLoaded : undefined} />
                    )}
                </Timeline>
            </SearchPanel>
        </>;
    }
}
interface IVersionItemProps {
    version: IVersionModel;
    deltaRequesting: boolean;
    onRestore: (version: IVersionModel) => void;
    onLoadChange: (version: IVersionModel) => void;
    restoring: boolean;
    entities: Record<number, EntitySummaryModel>;
    delta?: IDeltaModel;
}
function InnerVersionItem({ version, deltaRequesting, onRestore, onLoadChange, restoring, entities, delta }: IVersionItemProps) {
    const handleAskRestore = () => onRestore(version);
    const handleLoadChange = () => onLoadChange(version);
    return <TimelineItem key={`${version.versionId}-${version.type}`} style={{ minHeight: "auto" }} >
        <StyledTimelineOppositeContent>
            <Typography color="textSecondary" noWrap={true} >{formatDateTime(version.updatedOn)}</Typography>
        </StyledTimelineOppositeContent>
        <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
            <Card elevation={3}>
                {(deltaRequesting && !delta) && <LinearProgress />}
                <CardHeader
                    title={<VersionTitle version={version} entity={entities[version.updatedById]} />}
                    action={<FieldBox>
                        <Tooltip title="Restore this version"><IconButton disabled={restoring} onClick={handleAskRestore}><RestoreIcon /></IconButton></Tooltip>
                        <Tooltip title="Show change details"><IconButton onClick={handleLoadChange}><ViewDashboardOutlineIcon /></IconButton></Tooltip>
                    </FieldBox>} />
                {delta && <StyledCardContent>
                    <DiffEditor
                        height="500px"
                        original={delta.original ?? ""}
                        modified={delta.new ?? ""} />
                </StyledCardContent>}
            </Card>
        </TimelineContent>
    </TimelineItem>
}

const VersionItem = React.memo(InnerVersionItem);

function VersionAvatar({ version }: { version: IVersionModel }) {
    return <Chip label={versionAvatarTitle(version)} />;
}
function versionAvatarTitle({ type }: IVersionModel): string {
    switch (type) {
        case IVersionTypeModel.Batch: return "Batch";
        case IVersionTypeModel.Connectors: return "Connectors";
        case IVersionTypeModel.MonitoringMacro: return "Monitoring";
        case IVersionTypeModel.MonitoringMacroCall: return "Monitoring Call";
        case IVersionTypeModel.ReportCall: return "Report Call";
        case IVersionTypeModel.ImportMacro: return "Import";
        case IVersionTypeModel.ExportMacro: return "Export";
        case IVersionTypeModel.ProcessMacro: return "Process";
        case IVersionTypeModel.ReportTemplate: return "Report";
        case IVersionTypeModel.GlobalPortfolioComplianceMacro: return "Global Portfolio Compliance";
        case IVersionTypeModel.SpecificPortfolioComplianceMacro: return "Specific Portfolio Compliance";
        case IVersionTypeModel.GlobalPortfolioDealingMacro: return "Global Portfolio Dealing";
        case IVersionTypeModel.SpecificPortfolioDealingMacro: return "Specific Portfolio Dealing";
        case IVersionTypeModel.StatisticDefinitionSet: return "Statistic Definition Set";
        case IVersionTypeModel.MarketDataSelectorMacro: return "Market Data Selector";
        case IVersionTypeModel.CustomScreen: return "Custom Screen";
        case IVersionTypeModel.DocumentDefinition: return "Document Definition";
    }
}
function VersionTitle({ version, entity }: { version: IVersionModel, entity: EntitySummaryModel }) {
    const versionChange = version.versionNumber === 1
        ? <Box display="flex" flexDirection="row" alignItems="center" ><DoubleArrowIcon />Creation</Box>
        : <Box display="flex" flexDirection="row" alignItems="center" >v{version.versionNumber - 1}
            <DoubleArrowIcon />
            v{version.versionNumber}</Box>;
    const label = version.type === IVersionTypeModel.Connectors
        ? <>Connectors </>
        : <>{version.code} - {version.name}</>;
    // 
    return <Breadcrumbs>
        <Chip variant="outlined" icon={<FaceIcon />} label={getEntityName(entity)} />
        <Box>{versionChange}</Box>
        <VersionAvatar version={version} />
        <Box>{label}</Box>
    </Breadcrumbs>;
}