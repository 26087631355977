import { IExecuteModel } from "proxy/apiProxy";
import DialogPanel from "tools/components/DialogPanel";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import { Formik, FormikProps } from "formik";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FormTextField from "tools/fieldComponents/FormTextField";
import { Grid, Button, TextField, Fab, Typography, Box } from "@material-ui/core";
import { useCallback, useState, useMemo } from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import { useField } from "formik";
import AddIcon from '@material-ui/icons/Add';
import { getEnumLabels, oProps } from "tools/lib/utility";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";

export interface IDevelopmentItemParametersDialogProps {
    onValidated: (parameters: IExecuteModel) => void;
    onCancel: () => void;
    initialParameters: IExecuteModel;
    children?: React.ReactNode;
}
export function DevelopmentItemParametersDialog({ onValidated, onCancel, initialParameters, children }: IDevelopmentItemParametersDialogProps) {
    return <Formik onSubmit={onValidated} initialValues={initialParameters} enableReinitialize={true} validateOnMount={true}>
        {(formikProps => <DevelopmentItemParametersForm form={formikProps} onCancel={onCancel} children={children} />)}
    </Formik>
}
interface IDevelopmentItemParametersFormProps {
    form: FormikProps<IExecuteModel>;
    onCancel: () => void;
    children?: React.ReactNode;
}
function DevelopmentItemParametersForm({ form: { submitForm, values, dirty, isValid }, onCancel, children }: IDevelopmentItemParametersFormProps) {
    const actions = useMemo(() => [{
        disabled: !isValid,
        label: "Ok",
        onClick: submitForm
    }], [isValid, submitForm]);
    return <DialogPanel
        onBackClick={onCancel}
        actions={actions}
        maxWidth="sm"
        title="Execution parameters"
        // isQuerying={processDefinitionLookupAllLoading}
        isOpened={true}><Grid container spacing={1}>
            <Grid item xs={12} >
                <FormDatePickerField name={oProps<IExecuteModel>().path("asOfDate")} label="As Of" />
            </Grid>
            <Grid item xs={12}>
                <FormTextField name={oProps<IExecuteModel>().path("culture")} label="Culture" />
            </Grid>
            <Grid item xs={6}>
                <FormTextField name={oProps<IExecuteModel>().path("related", "entityId")} label="Entity Id" isNumber />
            </Grid>
            <Grid item xs={6}>
                <FormTextField name={oProps<IExecuteModel>().path("related", "relationshipId")} label="Relationship Id" isNumber />
            </Grid>
            <Grid item xs={6}>
                <FormTextField name={oProps<IExecuteModel>().path("related", "portfolioId")} label="Portfolio Id" isNumber />
            </Grid>
            <Grid item xs={6}>
                <FormTextField name={oProps<IExecuteModel>().path("related", "securityId")} label="Security Id" isNumber />
            </Grid>
            <Grid item xs={6}>
                <FormTextField name={oProps<IExecuteModel>().path("related", "processExecutionId")} label="ProcessExecution Id" isNumber />
            </Grid>
            <Grid item xs={6}>
                <FormTextField name={oProps<IExecuteModel>().path("related", "processExecutionTaskCode")} label="ProcessExecutionTask Code" />
            </Grid>
            {children}
            <Grid item xs={12}>
                <StringToAnyDictionaryField label="Parameters" path={oProps<IExecuteModel>().path("parameters")} />
            </Grid>
        </Grid>
    </DialogPanel>
}



export interface IExecutionProperty {
    type: ExecutionPropertyType;
    value?: object;
}
export enum ExecutionPropertyType {
    String = "String",
    DateTime = "DateTime",
    Number = "Number",
    Boolean = "Boolean"
}
const executionPropertyTypeLabel = getEnumLabels(ExecutionPropertyType);

export interface IStringToAnyDictionaryFieldProps {
    path: string;
    label: string;
}

export function StringToAnyDictionaryField({ path, label }: IStringToAnyDictionaryFieldProps) {
    const [{ value: parameters = {} }, , { setValue }] = useField<Record<string, IExecutionProperty>>(path);
    const keys = useMemo(() => Object.keys(parameters), [parameters]);
    const handleChange = useCallback((key: string, newKey: string) => {
        const { [key]: value, ...newParameters } = parameters;
        setValue({ ...newParameters, [newKey]: value }, true);
    }, [parameters, setValue]);
    const handleAdd = useCallback(() => { setValue({ ...parameters, newParameter: { type: ExecutionPropertyType.String } as IExecutionProperty }, true) }, [parameters, setValue]);
    const handleDelete = useCallback((key: string) => {
        const { [key]: oldValue, ...newParameters } = parameters;
        delete newParameters[key];
        setValue(newParameters, true);
    }, [parameters, setValue]);
    return <>
        <Box display="flex" gridGap={8} alignItems="baseline"><Typography variant="h6" gutterBottom>{label}</Typography>
            <Fab color="primary" aria-label="Add" onClick={handleAdd} size="small">
                <AddIcon fontSize="small" />
            </Fab>
        </Box>
        {keys.map(key => <StringToStringDictionaryItem
            key={key}
            itemKey={key}
            path={oProps<Record<string, IExecutionProperty>>(path).path(key)}
            onKeyChange={handleChange}
            onDelete={handleDelete} />)}
    </>
}

interface IStringToAnyDictionaryItemProps {
    itemKey: string;
    onKeyChange: (key: string, newKey: string) => void;
    onDelete: (key: string) => void;
    path: string;
}
function StringToStringDictionaryItem({ itemKey: key, onKeyChange, path, onDelete }: IStringToAnyDictionaryItemProps) {
    const [currentKey, setCurrentKey] = useState(key);
    const handleKeyBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => onKeyChange(key, currentKey), [key, onKeyChange, currentKey]);
    const handleKeyChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setCurrentKey(e.target.value), [setCurrentKey]);
    const handleDelete = useCallback(() => onDelete(key), [onDelete, key]);
    const [{ value: type }] = useField<IExecutionProperty["type"]>(oProps<IExecutionProperty>(path).path("type"));
    return <Box display="flex" alignItems="baseline" style={{ gap: 8 }}>
        <TextField
            fullWidth={true}
            label="Name"
            margin="dense"
            value={currentKey}
            onChange={handleKeyChange}
            onBlur={handleKeyBlur} />
        <FormSimpleSelectField name={oProps<IExecutionProperty>(path).path("type")} options={executionPropertyTypeLabel} required={true} />
        {(type === ExecutionPropertyType.Number || type === ExecutionPropertyType.String) && <FormTextField name={oProps<IExecutionProperty>(path).path("value")} label="Value" isNumber={type === ExecutionPropertyType.Number} />}
        {type === ExecutionPropertyType.DateTime && <FormDatePickerField name={oProps<IExecutionProperty>(path).path("value")} label="Value" />}
        {type === ExecutionPropertyType.Boolean && <FormCheckBoxField name={oProps<IExecutionProperty>(path).path("value")} label="Value" />}
        <Button onClick={handleDelete} size="small">
            <DeleteIcon />
        </Button>
    </Box>
}

