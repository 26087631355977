import { Octokit } from "@octokit/rest";

export interface IGitFile {
    name: string;
    path: string;
    downloadUrl: string;
}
type ReposGetContentsResponseDataItem = {
    type: string;
    size: number;
    name: string;
    path: string;
    sha: string;
    url: string;
    git_url: string;
    html_url: string;
    download_url: string | null;
    _links: ReposGetContentsResponseDataItemLinks;
};
type ReposGetContentsResponseDataItemLinks = {
    self: string;
    git: string;
    html: string;
};
type ReposGetContentsResponseDataLinks = {
    git: string;
    self: string;
    html: string;
};
type ReposGetContentsResponseData =
    RepoSimpleContent
    | ReposGetContentsResponseDataItem[];
type RepoSimpleContent = {
    type: string;
    encoding?: string;
    size: number;
    name: string;
    path: string;
    content?: string;
    sha: string;
    url: string;
    git_url: string;
    html_url: string;
    download_url: string | null;
    _links: ReposGetContentsResponseDataLinks;
    target?: string;
    submodule_git_url?: string;
};

function isSimpleContent(data: ReposGetContentsResponseData): data is RepoSimpleContent {
    return !Array.isArray(data);
}
function isMultipleContent(data: ReposGetContentsResponseData): data is ReposGetContentsResponseDataItem[] {
    return Array.isArray(data);
}

export enum IPmsRepository {
    Macro = "PmsMacros",
    Report = "PmsReports"
}

// async function RepoGetContentAsync(repo: IPmsRepository, path: string) {
//     return (await new Octokit({}).repos.getContent({
//         owner: "fundprocess",
//         repo,
//         path
//     }));
// }
function RepoGetContentAsync(repo: IPmsRepository, path: string): any {
    // const prom=new Octokit({}).repos.getContent({
    //     owner: "fundprocess",
    //     repo,
    //     path
    // });
    return new Promise((resolve, reject) => {
        new Octokit({}).repos.getContent({
            owner: "fundprocess",
            repo,
            path
        })
            .catch(() => resolve(null))
            .then(res => resolve(res));
    })
    // return (await new Octokit({}).repos.getContent({
    //     owner: "fundprocess",
    //     repo,
    //     path
    // }));
}
export async function GetContentAsync(repo: IPmsRepository, filePath: string) {
    const res = await RepoGetContentAsync(repo, filePath);
    if (res == null) {
        return;
    }
    const { data } = res;
    const tmp = data as unknown as ReposGetContentsResponseData;
    if (isSimpleContent(tmp) && tmp.content) {
        return atob(tmp.content);
    }
    return;
}
export async function GetFilesAsync(repo: IPmsRepository, folderPath: string) {
    const res = await RepoGetContentAsync(repo, folderPath);
    if (res == null) {
        return [];
    }
    const { data } = res;
    const tmp = data as unknown as ReposGetContentsResponseData;
    if (isMultipleContent(tmp)) {
        return tmp
            .filter((item: any) => item.type === "file")
            .map(({ download_url, path, name }) => ({ downloadUrl: download_url, path, name })) as IGitFile[];
    }
    return [];
}
