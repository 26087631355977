import {Epic} from 'redux-observable'
import {map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {mapToPayload} from "lib/rxJsUtility";
import {parametersApi, profileAccountApi, tenantsApi} from "proxy/apiProxy";
import {Action, ActionFactories} from "./slice";
import {ActionFactories as RouterActionFactories, IAnyAction} from "reducers";
import {base64toBlob} from 'tools/lib/utility';
import {getTenantId, setTenantId} from 'lib/dataAccess';
import {from, merge} from 'rxjs';
import {getUserManager} from "../../lib/userManager";
import {RouterAction} from "connected-react-router";

export const onSigninCallback: Epic<Action | RouterAction> = action$ => action$.pipe(
    mapToPayload("app", "onSigninCallback"),
    mergeMap(async user => {
        const userManager = getUserManager();
        await userManager.storeUser(user);

        if (user.url_state)
            console.debug(`You will be redirected to your screen shortly ${user.url_state}`);

        const accessibleTenants = await tenantsApi.getAccessibleTenantsAsync();
        if (accessibleTenants.type === "NoTenantModel") {
            return null;
        }
        const tenants = accessibleTenants.tenants;
        let tenantId = getTenantId();
        if (!!tenantId) {
            const tenant = tenants.find(i => i.id === tenantId);
            if (!tenant) {
                setTenantId(undefined);
                tenantId = undefined;
            }
        }
        if (!tenantId) {
            if (tenants.length) {
                tenantId = tenants[0].id;
                setTenantId(tenantId);
            } else {
                return [];
            }
        }

        return await Promise.all([
            profileAccountApi.getCurrentAsync(),
            // tenantsApi.getAccessibleTenantsAsync(),
            parametersApi.getAsync(),
            profileAccountApi.getCurrentTenantAsync(),
            profileAccountApi.getCurrentTenantImageAsync()
                .then(({data, mimeType}) => window.URL.createObjectURL(base64toBlob(data, mimeType)))
                .catch(() => undefined),
            Promise.resolve(tenants),
            Promise.resolve(user)
        ]);
    }),
    mergeMap(payload => {
        if (payload) {
            const [profile, parameters, currentTenant, currentTenantImage, accessibleTenants, user] = payload;
            const applicationLoaded = ActionFactories.applicationLoaded({
                profile,
                parameters,
                currentTenant,
                currentTenantImage,
                accessibleTenants
            });
            return user?.url_state
                ? from([applicationLoaded, RouterActionFactories.router.replace(user.url_state)])
                : from([applicationLoaded]);
        } else {
            return from([ActionFactories.applicationLoadedWithNoTenant()]);
        }
    })
);
export const createFirstTenant: Epic<IAnyAction> = action$ => action$.pipe(
    mapToPayload("app", "firstTenantCreate"),
    mergeMap(async tenant => tenantsApi.createTenantAsync({ tenant })),
    map(({ id: tenantId }) => ActionFactories.switchTenant(tenantId)));

export const onApplicationLoad: Epic<Action> = action$ => merge(
    // action$.pipe(mapToPayload("app", "applicationLoad"), map(tenantId => ({ tenantId, navigate: false }))),
    action$.pipe(
        mapToPayload("app", "switchTenant"),
        map(tenantId => ({tenantId, navigate: true})))).pipe(
    mergeMap(async ({tenantId}) => {
        const accessibleTenants = await tenantsApi.getAccessibleTenantsAsync();
        if (accessibleTenants.type === "NoTenantModel") {
            return null;
        }
        const tenants = accessibleTenants.tenants;
        if (!!tenantId) {
            const tenant = tenants.find(i => i.id === tenantId);
            if (!tenant) {
                setTenantId(undefined);
                return [];
            }
        }
        if (!tenantId) {
            setTenantId(undefined);
            return [];
        }

                setTenantId(tenantId);
                // await timeout(1000);
                return await Promise.all([
                    profileAccountApi.getCurrentAsync(),
                    // tenantsApi.getAccessibleTenantsAsync(),
                    parametersApi.getAsync(),
                    profileAccountApi.getCurrentTenantAsync(),
                    profileAccountApi.getCurrentTenantImageAsync()
                        .then(({ data, mimeType }) => window.URL.createObjectURL(base64toBlob(data, mimeType)))
                        .catch(() => undefined),
                    Promise.resolve(tenants)
                ]);
            }),
            map(payload => {
                if (payload) {
                    const [profile, parameters, currentTenant, currentTenantImage, accessibleTenants] = payload;
                    return ActionFactories.applicationLoaded({ profile, parameters, currentTenant, currentTenantImage, accessibleTenants });
                }
                else {
                    return ActionFactories.applicationLoadedWithNoTenant();
                }
            }));
export const tenantImageLoad: Epic<Action> = action$ => action$.pipe(
    mapToPayload("app", "tenantsImageLoad"),
    withLatestFrom(action$.pipe(mapToPayload("app", "applicationLoaded"))),
    mergeMap(([, { accessibleTenants }]) => from(accessibleTenants ?? []).pipe(mergeMap(({ id }) => tenantsApi.getImageAsync({ id }).then(({ data, mimeType }) => ({ tenantId: id, imageUrl: window.URL.createObjectURL(base64toBlob(data, mimeType)) }))
        .catch(() => ({ tenantId: id }))
    ))),
    map(ActionFactories.tenantImageLoaded)
)
