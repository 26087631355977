import NavigationRoute from "components/global/NavigationRoute";
import Portfolio from "./Portfolio";
import Portfolios from "./Portfolios";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

export default function PortfolioScreen() {
    return <>
        <NavigationRoute
            gridSize={12}
            exact={true}
            slideDirection={"right"}
            component={Portfolios}
            path={getSiteScreenUrl("MyPortfolios")}
            underManagementOnly={false} />
        <NavigationRoute
            gridSize={12}
            slideDirection={"left"}
            component={Portfolio}
            path={getSiteDetailScreenUrl("MyPortfolios")} />
    </>
}
