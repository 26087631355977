import { Epic } from "redux-observable";
import { merge, concat, from } from "rxjs";
import { map, mergeMap, share, switchMap } from "rxjs/operators";
import { tradeDatesApi, dailyDataApi, portfolioComplianceApi, managedPortfoliosApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, changedNavigation } from "lib/rxJsUtility";
import { filterRoute } from "tools/lib/UrlDictionary";
// import { today } from "tools/lib/utility";
export const onOpenScreenTrade: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("TradeDatesScreen"),
        map(() => ActionFactories.tradeDates.tradeDateAllLoad()));


function parseDate(str: string) {
    if (!/^(\d){8}$/.test(str)) throw Error("invalid date");
    const y = Number(str.substr(0, 4)),
        m = Number(str.substr(4, 2)),
        d = Number(str.substr(6, 2));
    return new Date(y, m - 1, d);
}

export const loadTradeDates: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("tradeDates", "tradeDateAllLoad"),
        mergeMap(() => tradeDatesApi.getAllAsync()),
        map(ActionFactories.tradeDates.tradeDateAllLoaded));

export const onOpenScreenTradeDate: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.tradedate),
        filterRoute("TradeDatesScreen", "detail"),
        map(({ matchingSections }) => parseDate(matchingSections?.detail?.tradedate ?? "")),
        map(ActionFactories.tradeDates.tradeDateLoad));

export const loadTradeEpic: Epic<IAnyAction>
    = action$ => {
        const tradeDateLoad$ = action$.pipe(
            mapToPayload("tradeDates", "tradeDateLoad"),
            share());
        return tradeDateLoad$.pipe(
            switchMap(tradeDate => {
                const tradeDateLoaded$ = from(tradeDatesApi.getAsync({ tradeDate })).pipe(
                    map(ActionFactories.tradeDates.tradeDateLoaded));
                const lastCompositionsLoaded$ = from(dailyDataApi.searchPositionsAsync({ lastOnly: true, lastAsOfDate: tradeDate })).pipe(
                    map(ActionFactories.tradeDates.tradeDateCompositionsLoaded));
                const portfolios$ = from(managedPortfoliosApi.getAllAsync({})).pipe(
                    map(ActionFactories.tradeDates.tradePortfoliosLoaded));
                return concat(
                    tradeDateLoaded$,
                    lastCompositionsLoaded$,
                    portfolios$
                );
            })
        )
    }
export const saveTradeDate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("tradeDates", "tradeDateSave"),
        mergeMap(model => tradeDatesApi.saveAsync({ model })),
        map(ActionFactories.tradeDates.tradeDateSaved));

export const submitTradeDate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("tradeDates", "tradeDateSubmit"),
        mergeMap(tradeDate => tradeDatesApi.submitAsync({ tradeDate }).then(() => tradeDate)),
        map(ActionFactories.tradeDates.tradeDateSubmitted));

export const getConfirmationTradeDate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("tradeDates", "tradeDateConfirmationsGet"),
        mergeMap(tradeDate => tradeDatesApi.getTradesConfirmationAsync({ tradeDate }).then(() => tradeDate)),
        map(ActionFactories.tradeDates.tradeDateConfirmationsGet));
export const getGenerationTradeDate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("tradeDates", "tradeDateGenerationLoad"),
        mergeMap(tradeDatesApi.getGenerationAsync),
        map(ActionFactories.tradeDates.tradeDateGenerationLoaded));
export const mustCheckSimulation: Epic<IAnyAction>
    = action$ => merge(
        action$.pipe(mapToPayload("tradeDates", "tradeDateLoad")),
        action$.pipe(mapToPayload("tradeDates", "tradeDateSaved"), map(({ date }) => date)))
        .pipe(map(ActionFactories.tradeDates.tradeDateCheck));

export const checkSimulation: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("tradeDates", "tradeDateCheck"),
        mergeMap(tradeDate => portfolioComplianceApi.checkComplianceOnSimulationAsync({ tradeDate, tradeBooksOnly: true }).catch(() => ({ failureDetail: "macro failure during check" }))),
        map(ActionFactories.tradeDates.tradeDateChecked));
