import { RegisterTransactionModel, RegisterTransactionSummaryModel } from "proxy/apiProxy";

export function getRegisterTransactionTypeLabel(type: (RegisterTransactionSummaryModel | RegisterTransactionModel)["type"]): string;
export function getRegisterTransactionTypeLabel(type: (RegisterTransactionSummaryModel | RegisterTransactionModel)["type"] | null): string | null;
export function getRegisterTransactionTypeLabel(type: (RegisterTransactionSummaryModel | RegisterTransactionModel)["type"] | undefined): string | undefined;
export function getRegisterTransactionTypeLabel(type: (RegisterTransactionSummaryModel | RegisterTransactionModel)["type"] | undefined | null): string | null | undefined;
export function getRegisterTransactionTypeLabel(type: (RegisterTransactionSummaryModel | RegisterTransactionModel)["type"] | undefined | null): string | null | undefined {
    if (typeof (type) === "undefined") {
        return undefined;
    }
    if (!type) {
        return null;
    }
    switch (type) {
        case "RegisterShareClassTransactionSummaryModel":
        case "RegisterShareClassTransactionModel": return "Shareclass";
        case "RegisterPortfolioTransactionSummaryModel":
        case "RegisterPortfolioTransactionModel": return "Portfolio";
    }
}
