import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getEntityName } from "tools/lib/modelUtils";
import { IFeatureModel, ISubFundModel, ISubFundSummaryModel } from "proxy/apiProxy";
export default function Portfolios() {
    const { all: portfolios = [], dictionaries: { entities }, allLoading: portfolioAllLoading } = useReduxSelections("subFund");
    const { navigationActiveSiteMapNode } = useReduxSelections("navigation");
    const { navigationNavigate } = useReduxActions("navigation");
    const columns: IColumnDefinition[] = [
        { name: "Name", title: "Name", getCellValue: ({ name = "" }: ISubFundModel | ISubFundSummaryModel) => name, filteringEnabled: true },
        { name: "InternalCode", title: "Internal Code", getCellValue: ({ internalCode = "" }: ISubFundModel | ISubFundSummaryModel) => internalCode, filteringEnabled: true },
        { name: "Sicav", title: "Fund/Sicav", getCellValue: ({ sicavId }: ISubFundModel | ISubFundSummaryModel) => sicavId && getEntityName(entities[sicavId]), filteringEnabled: true },
        { name: "UnderManagement", title: "Under Management", getCellValue: (sf: ISubFundModel | ISubFundSummaryModel) => sf.isUnderManagement, columnType: "boolean", filteringEnabled: true }
    ];
    const state: IGridState = {
        "Name": { width: 240 },
        "InternalCode": { width: 180 },
        "Sicav": { width: 240 },
        "UnderManagement": { width: 180 }
    }
    const getRowKey = (row: ISubFundModel | ISubFundSummaryModel) => row.id || 0;
    const handleRowClick = (row: ISubFundModel | ISubFundSummaryModel) => {
        // if (row.isUnderManagement) {
        //     navigationNavigate({ screenKey: "MyPortfolios", sectionKey: "detail", parameters: { id: row.id } });
        // }
        // else {
            navigationNavigate({ sectionKey: "detail", parameters: { id: row.id } });
        // }
    }

    const handleAddClick = () => navigationNavigate({ sectionKey: "detail", parameters: { id: 0 } });

    const title = navigationActiveSiteMapNode?.screen?.label ?? "";
    return <SearchPanel title={title} isQuerying={portfolioAllLoading}
        addAllowed={IFeatureModel.UniverseWrite}
        onAddClick={handleAddClick}>
        <ExtendedGrid
            rows={portfolios}
            columns={columns}
            getRowId={getRowKey}
            onRowClick={handleRowClick}
            initialState={state}
            userCanGroup={true} />
    </SearchPanel>
}
