import * as React from "react";
import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { ISicavSummaryModel, ISicavModel, IFeatureModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getEntityName } from "tools/lib/modelUtils";

export default function Sicavs() {
    const { all: entityList, dictionaries: { entities }, allLoading } = useReduxSelections("sicav");
    const { navigationNavigate } = useReduxActions("navigation");
    const handleRowClick = (row: (ISicavSummaryModel | ISicavModel)) => {
        // if (row.isUnderManagement) {
        //     navigationNavigate({ screenKey: "MySicavs", sectionKey: "detail", parameters: { id: row.id } });
        // }
        // else {
            navigationNavigate({ sectionKey: "detail", parameters: { id: row.id } });
        // }
    };
    const handleAddNewClick = () => navigationNavigate({ sectionKey: "detail", parameters: { id: 0 } });
    const { columns, state } = React.useMemo(() => {
        const cs = [
            { name: "InternalCode", title: "Internal Code", getCellValue: (row: (ISicavSummaryModel | ISicavModel)) => row.internalCode, filteringEnabled: true },
            { name: "Name", title: "Name", getCellValue: (row: (ISicavSummaryModel | ISicavModel)) => getEntityName(row) ?? "", filteringEnabled: true },
            { name: "UnderManagement", title: "Under Management", getCellValue: (row: (ISicavSummaryModel | ISicavModel)) => row.isUnderManagement, columnType: "boolean" }
        ] as IColumnDefinition[];
        const state = {
            "InternalCode": { width: 150 },
            "Name": { width: 280 },
        } as IGridState;

        cs.push({ name: "Issuer", title: "Issuer", getCellValue: (row: (ISicavSummaryModel | ISicavModel)) => row.issuerId && getEntityName(entities[row.issuerId]) });
        state.Issuer = { width: 280 };
        return {
            columns: cs,
            state
        };
    }, [entities]);

    const getRowKey = (row: ISicavSummaryModel | ISicavModel) => row.id || 0;

    return <SearchPanel title="Sicavs" isQuerying={allLoading}
        addAllowed={IFeatureModel.UniverseWrite}
        onAddClick={handleAddNewClick}>
        <ExtendedGrid
            getRowId={getRowKey}
            columns={columns}
            rows={entityList}
            onRowClick={handleRowClick}
            initialState={state}
            userCanGroup={true}
            defaultExportFileName={`Sicavs.xlsx`} />
    </SearchPanel>
}
