import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import MacroScriptCategories from "./MacroScriptCategories";
import MacroScriptCategory from "./MacroScriptCategory";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class MacroScriptCategoryScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                gridSize={9}
                gridSizeIfNotExact={6}
                exact={false}
                slideDirection={"right"}
                component={MacroScriptCategories}
                path={getSiteScreenUrl("MacroScriptCategories")} />
            <NavigationRoute
                gridSize={6}
                slideDirection={"left"}
                component={MacroScriptCategory}
                path={getSiteDetailScreenUrl("MacroScriptCategories")} />
        </>
    }
}

export default MacroScriptCategoryScreen;
