import produce from "immer";
import {
    EntitySummaryModel, IGetNoteVersionsModel, INoteModel,
    // INoteVersionModel, 
    NoteSummaryVersionModel, NoteVersionModel, PortfolioSummaryModel, RelationshipSummaryModel, SecuritySummaryModel
} from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IGetEntitySummary, IGetPortfolioSummary, IGetRelationshipSummary, IGetSecuritySummary } from "./Reference";

export interface IGetFilePayload {
    dueDiligenceExecutionId: number,
    taskCode: string
}
export interface INoteSavePayload {
    versionsToDelete: number[];
    versionsToSave: NoteVersionModel[];
    note: INoteModel;
}
export interface INoteSavedPayload {
    deletedVersionIds: number[];
    savedVersions: NoteSummaryVersionModel[];
    note: INoteModel;
}
export const ActionFactories = produceActionFactories({
    noteDeleteCurrent: (payload: number) => payload,
    noteDeleted: (payload: number) => payload,
    noteLoad: (payload: number) => payload,
    noteLoaded: (payload: INoteModel) => payload,
    noteVersionsLoad: (payload: number) => payload,
    noteVersionsLoaded: (payload: IGetNoteVersionsModel) => payload,
    // noteVersionsSave: (payload: INoteVersionModel[]) => payload,
    // noteVersionsDelete: (payload: number[]) => payload,
    noteSave: (payload: INoteSavePayload) => payload,
    noteSaved: (payload: INoteSavedPayload) => payload,
    noteLoadAll: () => undefined,
    noteLoadedAll: (payload: INoteModel[]) => payload,
    noteVersionContentLoad: (payload: number) => payload,
    noteVersionContentLoaded: (payload: NoteVersionModel) => payload,


    noteRelationshipSelected: (payload: IGetRelationshipSummary) => payload,
    noteEntitySelected: (payload: IGetEntitySummary) => payload,
    notePortfolioSelected: (payload: IGetPortfolioSummary) => payload,
    noteSecuritySelected: (payload: IGetSecuritySummary) => payload,
});
export interface IState {
    allLoading: boolean;
    loading: boolean;
    loadingVersions: boolean;
    saving: boolean;
    deleting: boolean;
    all: INoteModel[];
    current?: INoteModel;
    dictionaries: {
        entities: Record<string | number, EntitySummaryModel>;
        portfolios: Record<string | number, PortfolioSummaryModel>;
        securities: Record<string | number, SecuritySummaryModel>;
        relationships: Record<string | number, RelationshipSummaryModel>;
    }
    currentVersions: NoteSummaryVersionModel[];
    noteVersionContents: Record<number, NoteVersionModel>;
}
export const reducer = (
    state: IState = {
        allLoading: false,
        loading: false,
        deleting: false,
        saving: false,
        all: [],
        loadingVersions: false,
        dictionaries: {
            entities: {},
            portfolios: {},
            securities: {},
            relationships: {}
        },
        currentVersions: [],
        noteVersionContents: {}
    },
    action: AnyActionOf<typeof ActionFactories>
) => produce(state, draft => {
    switch (action.type) {
        case "noteLoadAll":
            draft.allLoading = true;
            break;
        case "noteLoadedAll":
            draft.allLoading = false;
            draft.all = action.payload;
            break;
        case "noteLoad":
            draft.loading = true;
            draft.current = undefined;
            draft.currentVersions = [];
            draft.noteVersionContents = {}
            draft.dictionaries = {
                entities: {},
                portfolios: {},
                securities: {},
                relationships: {}
            };
            break;
        case "noteLoaded":
            draft.loading = false;
            draft.current = action.payload;
            break;
        case "noteVersionsLoad":
            draft.loadingVersions = true;
            break;
        case "noteVersionContentLoaded":
            draft.noteVersionContents[action.payload.id] = action.payload;
            break;
        case "noteVersionsLoaded":
            draft.loadingVersions = false;
            draft.currentVersions = action.payload.noteVersions;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
            draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
            draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
            break;
        case "noteRelationshipSelected":
            draft.dictionaries.relationships[action.payload.relationship.id] = action.payload.relationship;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
            break;
        case "noteEntitySelected":
            draft.dictionaries.entities[action.payload.entity.id] = action.payload.entity;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            break;
        case "notePortfolioSelected":
            draft.dictionaries.portfolios[action.payload.portfolio.id] = action.payload.portfolio;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            break;
        case "noteSecuritySelected":
            draft.dictionaries.securities[action.payload.security.id] = action.payload.security;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.subFunds };
            break;
        case "noteSave":
            draft.saving = true;
            break;
        case "noteSaved":
            draft.saving = false;
            const { deletedVersionIds, note, savedVersions } = action.payload;
            draft.current = note;
            const existing = draft.all.find(i => i.id === note.id);
            if (existing) {
                Object.assign(existing, note);
            }
            else {
                draft.all.push(note);
            }
            for (const deletedVersionId of deletedVersionIds) {
                const foundIndex = draft.currentVersions.findIndex(i => i.id === deletedVersionId);
                if (foundIndex >= 0) {
                    draft.currentVersions.splice(foundIndex, 1);
                }
            }
            for (const savedVersion of savedVersions) {
                const existingVersion = draft.currentVersions.find(i => i.id === savedVersion.id);
                if (!!existingVersion) {
                    Object.assign(existingVersion, savedVersion);
                }
                else {
                    draft.currentVersions.push(savedVersion);
                }
            }
            draft.noteVersionContents = {};
            break;
        case "noteDeleteCurrent":
            draft.deleting = true;
            break;
        case "noteDeleted":
            draft.deleting = false;
            const deletedId = action.payload;
            if (draft.current && draft.current.id === deletedId) {
                delete draft.current;
            }
            const idx = draft.all.findIndex(i => i.id === deletedId);
            if (idx >= 0) {
                draft.all.splice(idx, 1);
            }
            break;
    }
});
