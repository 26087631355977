import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, share, mergeMap } from "rxjs/operators";
import { ILoanModel, loansApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, changedNavigation } from "lib/rxJsUtility";
import { IGetLoanModel } from "proxy/apiProxy";
import { tryParseNumber } from "tools/lib/utility";
import { filterRoute } from "tools/lib/UrlDictionary";
export const loadLoans: Epic<IAnyAction>
    = (action$) => action$.pipe(
        mapToPayload("loan", "loanLoadAll"),
        mergeMap(() => loansApi.getAllAsync({})),
        map(ActionFactories.loan.loanLoadedAll));
export const onOpenScreenLoan: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("Loans", "detail"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? 0),
        map(loanId => ActionFactories.loan.loanLoad(loanId as number)));
export const onOpenScreenLoans: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("Loans"),
        map(() => ActionFactories.loan.loanLoadAll()));
export const loadLoan: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("loan", "loanLoad"),
            share()
        );
        return merge(
            requestedId$.pipe(map(() => ActionFactories.parameters.parametersLoad())),
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => loansApi.getAsync({ id })),
                map(ActionFactories.loan.loanLoaded)),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({
                    loan: { id: 0, name: "", shortName: "", covenants: [], collaterals: [], internalCode: "", borrowerId: null, lenderId: null, securityExtensionFieldsValues: {} } as unknown as ILoanModel,
                    entities: {},
                    indexes: {},
                    securities: {},
                    subFunds: {}
                } as IGetLoanModel)),
                map(ActionFactories.loan.loanLoaded)));
    };
export const saveLoan: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("loan", "loanSave"),
        mergeMap(model => loansApi.saveAsync({ model })),
        map(ActionFactories.loan.loanSaved));
export const deleteLoan: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("loan", "loanDelete"),
            mergeMap(id => loansApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.loan.loanDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };
