import buildUrl from "./buildUrl";
import { getUserManager } from './userManager';


export type FileResponse = { blob: Blob, fileName: string | undefined };

// function isOkPayloadResponse(response: ApiPayloadResponse<any> | OkFileResponse | OkEmptyResponse): response is OkPayloadResponse<any> {
//     return !!(response as OkPayloadResponse<any>).payload;
// }

export interface IRestApi {
    method: string;
    path: string;
    parametersDirection: {
        [parameterName: string]: "path" | "query" | "body"
    }
}

function isFormData(payload: any): payload is FormData {
    return typeof FormData !== 'undefined' && payload instanceof FormData;
};

const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}([.]\d+)?(Z|([+]\d{2}:\d{2}))?$/;
// const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z?$/;

// tslint:disable-next-line:variable-name
function reviver(_key: any, value: any): any {
    if (typeof value === "string" && dateFormat.test(value)) {
        return new Date(value);
    }
    return value;
}

function getDownloadFileName(contentDisposition: string | undefined | null): string | undefined {
    if (!contentDisposition) {
        return;
    }
    const values = contentDisposition.split(";");
    if (values.length < 2) {
        return;
    }
    const [attachment, fileSpecification] = values;
    if (attachment.trim().toLowerCase() !== "attachment") {
        return;
    }
    const specs = fileSpecification.split("=");
    if (specs.length < 2) {
        return;
    }
    const [fileSpec, fileName] = specs;
    if (fileSpec.trim().toLowerCase() !== "filename") {
        return;
    }
    if (fileName.substr(0, 1) !== "\"") {
        return fileName.trim();
    }
    return fileName.substr(1, fileName.length - 2).trim();
}
export class ApiError extends Error {
    public status: number;
    public statusText: string;
    constructor(response: Response) {
        super(`${response.status}:${response.statusText}`);
        this.status = response.status;
        this.statusText = response.statusText;
    }
}

export async function fetchPayloadApiAsync<TOutput>(apiDescription: IRestApi): Promise<TOutput>;
export async function fetchPayloadApiAsync<TParams extends Record<string, any>, TOutput>(apiDescription: IRestApi, parameters: TParams): Promise<TOutput>;
export async function fetchPayloadApiAsync<TParams extends Record<string, any>, TOutput>(apiDescription: IRestApi, parameters?: TParams): Promise<TOutput> {
    const response = await fetchDataWithUserAsync(apiDescription, parameters ?? {});
    if (!response.ok) {
        throw new ApiError(response);
    }
    return JSON.parse(await response.text(), reviver) as TOutput;
}

export async function fetchBlobApiAsync(apiDescription: IRestApi): Promise<FileResponse>
export async function fetchBlobApiAsync<TParams extends Record<string, any>>(apiDescription: IRestApi, parameters: TParams): Promise<FileResponse>
export async function fetchBlobApiAsync<TParams extends Record<string, any>>(apiDescription: IRestApi, parameters?: TParams): Promise<FileResponse> {
    const response = await fetchDataWithUserAsync(apiDescription, parameters ?? {});
    if (!response.ok) {
        throw new ApiError(response);
    }
    return { blob: await response.blob(), fileName: getDownloadFileName(response.headers.get("content-disposition")) }
}

export async function fetchEmptyApiAsync(apiDescription: IRestApi): Promise<void>
export async function fetchEmptyApiAsync<TParams extends Record<string, any>>(apiDescription: IRestApi, parameters: TParams): Promise<void>
export async function fetchEmptyApiAsync<TParams extends Record<string, any>>(apiDescription: IRestApi, parameters?: TParams): Promise<void> {
    const response = await fetchDataWithUserAsync(apiDescription, parameters ?? {});
    if (!response.ok) {
        throw new ApiError(response);
    }
}
const sessionStorageKey = "TenantId";

export function getTenantId() {
    const tenantIdString = localStorage.getItem(sessionStorageKey);
    if (!tenantIdString) return undefined;
    const tenantId = Number(tenantIdString);
    if (!!tenantId && !isNaN(tenantId)) return tenantId;
    return undefined;
}

export function setTenantId(tenantId: number | undefined) {
    if (!tenantId) {
        localStorage.removeItem(sessionStorageKey);
        return;
    }
    localStorage.setItem(String(sessionStorageKey), String(tenantId))
}

async function fetchDataWithUserAsync<TParams extends Record<string, any>>(apiDescription: IRestApi, parameters: TParams): Promise<Response> {
    const user = await getUserManager().getUser();
    const headersInit: HeadersInit = {
        "Accept": "application/json",
        "Content-Type": "application/json",
    };
    if (!!user) {
        headersInit["Authorization"] = `${user?.token_type} ${user?.access_token}`;
    }
    const tenantId = getTenantId();
    if (!!tenantId) {
        headersInit["TenantId"] = String(tenantId);
    }
    const requestInit: RequestInit = {
        headers: new Headers(headersInit),
        method: apiDescription.method
    };

    const groupedParameters = Object
        .keys(parameters)
        .map(name => ({ name, value: parameters[name], in: apiDescription.parametersDirection[name] }))
        .reduce<{ pathParameters?: Record<string, any>, queryParameters?: Record<string, any>, body?: any }>((a, { name, value, in: direction }) => {
            switch (direction) {
                case "query":
                    if (!a.queryParameters) {
                        a.queryParameters = {};
                    }
                    a.queryParameters[name] = value;
                    break;
                case "path":
                    if (!a.pathParameters) {
                        a.pathParameters = {};
                    }
                    a.pathParameters[name] = value;
                    break;
                case "body":
                    a.body = value;
                    break;
            }
            return a;
        }, {});

    if (groupedParameters.body) {
        if (isFormData(groupedParameters.body)) {
            requestInit.body = groupedParameters.body;
        }
        else {
            requestInit.body = JSON.stringify(groupedParameters.body, replacerJson);
        }
    }

    const url = buildUrl(apiDescription.path, replaceDates(groupedParameters.pathParameters), replaceDates(groupedParameters.queryParameters));

    return await fetch(url, requestInit);
}
function replaceDates(params?: Record<string, any>): Record<string, any> | undefined {
    if (!params) {
        return params;
    }
    const response: Record<string, any> = {};
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const element = params[key];
            response[key] = replacer(key, element);
        }
    }
    return response;
}
function isDate(value: any): value is Date {
    return value instanceof Date && !isNaN(value.valueOf())
}
function replacer(key: string, value: any): any | undefined {
    // Filtering out properties
    if (isDate(value)) {

        const offset = value.getTimezoneOffset() * 60000;
        // @ts-ignore
        const localISOTime = new Date(value - offset).toISOString().slice(0, -1);
        return localISOTime;
    }
    return value;
}
function replacerJson(key: string, value: any): any | undefined {
    // Filtering out properties
    if (typeof value === "string" && dateFormat.test(value)) {
        const v = new Date(value);
        const offset = v.getTimezoneOffset() * 60000;
        // @ts-ignore
        const localISOTime = new Date(v - offset).toISOString().slice(0, -1);
        return localISOTime;
    }
    return value;
}
