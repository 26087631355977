import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import TradeDate from "./TradeDate";
import TradeDates from "./TradeDates";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class TradeDatesScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute gridSize={12} exact={true} slideDirection={"right"} component={TradeDates} path={getSiteScreenUrl("TradeDatesScreen")} />
            <NavigationRoute gridSize={12} slideDirection={"left"} component={TradeDate} path={getSiteDetailScreenUrl("TradeDatesScreen")} />
        </>
    }
}

export default TradeDatesScreen;
