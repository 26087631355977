import { CustomScreenModel, CustomScreenSummaryModel } from "proxy/apiProxy";

export function getCustomScreenTypeLabel(type: CustomScreenModel["type"] | CustomScreenModel["type"]): string;
export function getCustomScreenTypeLabel(type: CustomScreenModel["type"] | CustomScreenSummaryModel["type"] | null): string | null;
export function getCustomScreenTypeLabel(type: CustomScreenModel["type"] | CustomScreenSummaryModel["type"] | undefined): string | undefined;
export function getCustomScreenTypeLabel(type: CustomScreenModel["type"] | CustomScreenSummaryModel["type"] | undefined | null): string | null | undefined;
export function getCustomScreenTypeLabel(type: CustomScreenModel["type"] | CustomScreenSummaryModel["type"] | undefined | null): string | undefined | null {
    if (typeof (type) === "undefined") {
        return undefined;
    }
    if (!type) {
        return null;
    }
    switch (type) {
        case "CounterpartyCustomScreenModel":
        case "CounterpartyCustomScreenSummaryModel": return "Counterparty";
        case "EntityCustomScreenModel":
        case "EntityCustomScreenSummaryModel": return "Entity";
        case "InvestorCustomScreenModel":
        case "InvestorCustomScreenSummaryModel": return "Investor";
        case "PortfolioCustomScreenModel":
        case "PortfolioCustomScreenSummaryModel": return "Portfolio";
        case "RoleCustomScreenModel":
        case "RoleCustomScreenSummaryModel": return "Role Relationship";
        case "SecurityInvestmentCustomScreenModel":
        case "SecurityInvestmentCustomScreenSummaryModel": return "Security";
        case "SubFundCustomScreenModel":
        case "SubFundCustomScreenSummaryModel": return "SubFund";
        case "ManagedShareClassCustomScreenModel":
        case "ManagedShareClassCustomScreenSummaryModel": return "Managed ShareClass";
        case "SicavCustomScreenModel":
        case "SicavCustomScreenSummaryModel": return "Sicav";
        case "ManagedSicavCustomScreenModel":
        case "ManagedSicavCustomScreenSummaryModel": return "Managed Sicav";
    }
}
