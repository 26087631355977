import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import ProcessDefinitions from "./ProcessDefinitions";
import ProcessDefinition from "./ProcessDefinition";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class RoleScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                gridSize={12}
                exact={true}
                slideDirection={"right"}
                component={ProcessDefinitions}
                path={getSiteScreenUrl("ProcessDefinitions")} />

            <NavigationRoute
                gridSize={12}
                slideDirection={"left"}
                component={ProcessDefinition}
                path={getSiteDetailScreenUrl("ProcessDefinitions")} />
        </>
    }
}

export default RoleScreen;
