import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { map, mergeMap, share } from "rxjs/operators";
import { studioPortfolioComplianceApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "reducers";
import { mapToPayload, changedNavigation } from "lib/rxJsUtility";
import { filterRoute } from "tools/lib/UrlDictionary";

export const onOpenScreenConnector: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey),
        filterRoute("GlobalPortfolioCompliancePolicy"),
        map(() => ActionFactories.globalPortfolioCompliance.globalPortfolioComplianceLoad()));
export const loadConnector: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("globalPortfolioCompliance", "globalPortfolioComplianceLoad"),
            share());
        const portfolioComplianceMacro$ = requestedId$.pipe(
            mergeMap(() => studioPortfolioComplianceApi.getGlobalMacroAsync()),
            share());
        return merge(
            portfolioComplianceMacro$.pipe(map(ActionFactories.globalPortfolioCompliance.globalPortfolioComplianceLoaded)),
            portfolioComplianceMacro$.pipe(map(({ script }) => ActionFactories.globalPortfolioCompliance.globalPortfolioComplianceValidateScript(script))),
            requestedId$.pipe(
                mergeMap(() => studioPortfolioComplianceApi.getUniverseStructureAsync()),
                map(ActionFactories.globalPortfolioCompliance.globalPortfolioComplianceMetadataLoaded)));
    };
export const saveConnector: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("globalPortfolioCompliance", "globalPortfolioComplianceSave"),
        mergeMap(model => studioPortfolioComplianceApi.saveGlobalMacroAsync({ complianceDefinition: model })),
        map(ActionFactories.globalPortfolioCompliance.globalPortfolioComplianceSaved));

export const checkMacro: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("globalPortfolioCompliance", "globalPortfolioComplianceValidateScript"),
        mergeMap(script => studioPortfolioComplianceApi.checkScriptAsync({ textModel: { text: script } })),
        map(result => ActionFactories.globalPortfolioCompliance.globalPortfolioComplianceValidatedScript(result)));
