import { Typography, Tooltip, IconButton, Fab, Grid, withStyles, Card, CardContent, CardHeader } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { FieldArray, FieldArrayRenderProps, useField } from "formik";
import { IRelatedFileWithContent } from "reducers/IRelatedFileWithContent";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { base64toBlob, oProps } from "tools/lib/utility";
import FormTextField from "tools/fieldComponents/FormTextField";
import { createStyles, Theme } from '@material-ui/core/styles';
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FormDropZoneField from "tools/fieldComponents/FormDropZoneField";
import saveAs from "file-saver";

export interface IAttachedDocumentsProps {
    formFieldName: string;
    onFileOpen: (fileId: number) => void;
}

export default function AttachedDocuments({ formFieldName, onFileOpen }: IAttachedDocumentsProps) {
    const [{ value: files }] = useField<IRelatedFileWithContent[]>(formFieldName);
    return <FieldArray name={formFieldName} render={renderFiles} validateOnChange={true} />;
    function renderFiles({ push, remove, move, form: { validateForm } }: FieldArrayRenderProps): React.ReactNode {
        const handleRemove = (idx: number) => {
            if (idx < 0) {
                return;
            }
            remove(idx);
            setTimeout(() => validateForm(), 100);
        }
        const handleAdd = () => {
            push({} as IRelatedFileWithContent);
            setTimeout(() => validateForm(), 100);
        }
        return (<ReadOnlyContext.Consumer>{readOnly => <><FilesTable
            onFileOpen={onFileOpen}
            files={files}
            readOnly={readOnly}
            formFieldName={formFieldName}
            onRemove={handleRemove} />
            {!readOnly && <Fab onClick={handleAdd} >
                <AddIcon />
            </Fab>
            }
        </>}</ReadOnlyContext.Consumer>);
    }
}


interface IFilesTableProps {
    files: IRelatedFileWithContent[];
    readOnly: boolean;
    onRemove: (idx: number) => void;
    onFileOpen: (fileId: number) => void;
    formFieldName: string;
}
function FilesTable({ files, readOnly, onRemove, formFieldName, onFileOpen }: IFilesTableProps) {
    if (!files?.length) {
        return <Typography>No file</Typography>
    }
    return <Grid container spacing={2}>{files.map((file, idx) => <Grid key={idx} md={12} item><FileRow
        onFileOpen={onFileOpen}
        file={file}
        key={idx}
        index={idx}
        formFieldName={formFieldName}
        onRemove={onRemove}
        readOnly={readOnly} /></Grid>)}</Grid>
}

const StyledCardHeader = withStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.primary.main,
        }
    })
)(CardHeader);


interface IFileRowProps {
    index: number;
    onRemove: (index: number) => void;
    readOnly: boolean;
    formFieldName: string;
    file: IRelatedFileWithContent;
    onFileOpen: (fileId: number) => void;
}
function FileRow({ index, onRemove, readOnly, formFieldName, file, onFileOpen }: IFileRowProps) {
    const handleRemove = () => onRemove(index);
    const handleViewFile = () => {
        if (file.content && file.fileName) {
            const blob = base64toBlob(file.content.content, file.content.mimeType);
            saveAs(blob, file.fileName);
        }
        else if (file.id) {
            onFileOpen(file.id);
        }
    }

    return <Card>
        <StyledCardHeader
            title={file?.name ?? file?.fileName}
            action={<Tooltip title="Delete">
                <IconButton size="small" style={{ verticalAlign: "middle" }} onClick={handleRemove} >
                    <CloseIcon />
                </IconButton>
            </Tooltip>} />
        <CardContent>
            <Grid container spacing={1}>
                <Grid item md={3}>
                    <FormTextField name={oProps<IRelatedFileWithContent[]>(formFieldName).path(index, "name")} label="Name" />
                </Grid>
                <Grid item md={3}>
                    <FormDatePickerField name={oProps<IRelatedFileWithContent[]>(formFieldName).path(index, "expiration")} label="Expiration" />
                </Grid>
                <Grid item md={6}>
                    <FormTextField name={oProps<IRelatedFileWithContent[]>(formFieldName).path(index, "comment")} label="Comment" />
                </Grid>
                <Grid item md={4}>
                    <FormDropZoneField
                        name={oProps<IRelatedFileWithContent[]>(formFieldName).path(index, "fileName")}
                        dateTime={oProps<IRelatedFileWithContent[]>(formFieldName).path(index, "fileSubmissionDate")}
                        contentName={oProps<IRelatedFileWithContent[]>(formFieldName).path(index, "content")}
                        onClick={handleViewFile}
                        label="Drop the document here"
                        emptyValue={null} />
                </Grid>
            </Grid>
        </CardContent>
    </Card>
}
