import DetailPanel from "tools/components/DetailPanel";
import { IFeatureModel, IPortfolioMacroModel } from "proxy/apiProxy";
import { FormikProps, Formik, FormikHelpers } from 'formik';
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import PortfolioScriptEditor from "features/ManagedPortfolio/PortfolioScriptEditor";
import { oProps } from "tools/lib/utility";
import WriteAccess from "tools/fieldComponents/WriteAccess";

export default function Connectors() {
    const { globalPortfolioDealingSave, globalPortfolioDealingValidateScript } = useReduxActions("globalPortfolioDealing");
    const {
        globalPortfolioDealingMacro,
        globalPortfolioDealingMacroLoading,
        globalPortfolioDealingMacroSaving,
        globalPortfolioDealingMacroValidateScriptMetadata,
        globalPortfolioDealingMacroCheckResult = { errors: [] } } = useReduxSelections("globalPortfolioDealing");

    const handleSubmit = (model: IPortfolioMacroModel, { setSubmitting }: FormikHelpers<IPortfolioMacroModel>) => {
        globalPortfolioDealingSave(model);
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={globalPortfolioDealingMacro} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;

    function renderForm({ dirty, isValid, submitForm }: FormikProps<IPortfolioMacroModel>) {
        const handleSaveClick = () => {
            if (isValid && dirty)
                submitForm();
        }
        return <WriteAccess value={IFeatureModel.TradeBookWrite}><DetailPanel
            isQuerying={globalPortfolioDealingMacroLoading || globalPortfolioDealingMacroSaving}
            title="Global Portfolio Dealing Rule"
            noPadding={true}
            saveAllowed={IFeatureModel.TradeBookWrite}
            canSave={dirty && isValid}
            onSaveClick={handleSaveClick}>
            <PortfolioScriptEditor
                fieldName={oProps<IPortfolioMacroModel>().path("script")}
                metadata={globalPortfolioDealingMacroValidateScriptMetadata}
                onSaveRequest={handleSaveClick}
                onScriptChanged={globalPortfolioDealingValidateScript}
                scriptErrors={globalPortfolioDealingMacroCheckResult.errors} />
        </DetailPanel>
        </WriteAccess>
    }
}
