import { Card, CardActions, CardContent, CardHeader, IconButton } from "@material-ui/core"
import RelationshipSelectField from "components/searchers/RelationshipSelectField"
import { EntitySummaryModel, IProcessExecutionResponsibilityModel, RelationshipSummaryModel } from "proxy/apiProxy"
import { IGetRelationshipSummary } from "reducers/Reference"
import { FieldArray, FieldArrayRenderProps, useField } from "formik"
import RelationshipSummary from "components/summaries/RelationshipSummary"
import FormTextField from "tools/fieldComponents/FormTextField"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import AddIcon from "@material-ui/icons/Add"
import UpIcon from "@material-ui/icons/ArrowUpward"
import DownIcon from "@material-ui/icons/ArrowDownward"
import { useCallback } from "react"
import clsx from "clsx"


const useStyles = makeStyles(theme => createStyles({
    responsibleCard: {
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0),
    },
    responsibleCardContent: {
        padding: 0,
        paddingBottom: theme.spacing(0.5),
    },
    hiddenButton: {
        visibility: "hidden"
    },
}));


export default function ProcessResponsibles(
    {
        field,
        dictionaries,
        onRelationshipLoaded
    }: {
        field: string,
        dictionaries: {
            relationships: Record<number, RelationshipSummaryModel>;
            entities: Record<number, EntitySummaryModel>
        },
        onRelationshipLoaded: (roleSummary: IGetRelationshipSummary) => void
    }) {

    // This isn't an input, so instead of using the values in 'field' directly, we'll use 'meta' and 'helpers'.
    const [, { value },] = useField<IProcessExecutionResponsibilityModel[]>(field)
    return <FieldArray name={field} render={arrayHelper => (
        <Card>
            <CardHeader title="Responsibles" />
            <CardContent>
                {value
                    .map((resp, idx) =>
                        <EditResponsible
                            key={idx}
                            fieldName={`${arrayHelper.name}.${idx}`}
                            idx={idx}
                            arrayLength={value.length}
                            swap={arrayHelper.swap}
                            remove={arrayHelper.remove}
                            resp={resp}
                            entities={dictionaries.entities}
                            relationships={dictionaries.relationships}
                            onRelationshipLoaded={onRelationshipLoaded} />
                    )}
            </CardContent>
            <CardActions disableSpacing>
                <AddResponsible push={arrayHelper.push} title={`Reviewer ${value.length}`} />
            </CardActions>
        </Card>
    )} />
}

function EditResponsible(
    { swap, idx, remove, fieldName, arrayLength, ...props }: {
        fieldName: string
        swap: FieldArrayRenderProps["swap"],
        remove: FieldArrayRenderProps["remove"],
        idx: number,
        arrayLength: number,
        resp: IProcessExecutionResponsibilityModel,
        entities: Record<number, EntitySummaryModel>,
        relationships: Record<number, RelationshipSummaryModel>,
        onRelationshipLoaded: (roleSummary: IGetRelationshipSummary) => void
    }) {
    const classes = useStyles();

    const enableUp = idx > 0
    const enableDown = idx < arrayLength - 1

    const handleUp = useCallback(() => enableUp && swap(idx, idx - 1), [enableUp, idx, swap])
    const handleDown = useCallback(() => enableDown && swap(idx, idx + 1), [enableDown, idx, swap])
    const handleRemove = useCallback(() => remove(idx), [idx, remove])

    return <Card variant="outlined" className={classes.responsibleCard}>
        <CardHeader
            className={classes.responsibleCardContent}
            title={
                <FormTextField
                    //TODO Add required validation that works when removing Responsibility item
                    fullWidth label="Responsibility*"
                    name={`${fieldName}.title`} />
            }
            action={<>
                <IconButton className={clsx(!enableUp && classes.hiddenButton)}
                            onClick={handleUp}><UpIcon /></IconButton>
                <IconButton className={clsx(!enableDown && classes.hiddenButton)}
                            onClick={handleDown}><DownIcon /></IconButton>
                <IconButton onClick={handleRemove}><CloseIcon /></IconButton>
            </>} />
        <CardContent className={classes.responsibleCardContent}>
            {props.resp.responsibleId
                ? <RelationshipSummary
                    name={`${fieldName}.responsibleId`}
                    entities={props.entities}
                    relationships={props.relationships} />
                : <RelationshipSelectField
                    type="RoleRelationshipModel"
                    label="Add Responsible"
                    onSelected={props.onRelationshipLoaded}
                    name={`${fieldName}.responsibleId`}
                />}
        </CardContent>
    </Card>
}

function AddResponsible({ push, title }: { push: FieldArrayRenderProps["push"], title: string }) {
    const handleAddResponsible = useCallback(() => push({
        title: title,
        responsibleId: undefined
    } as IProcessExecutionResponsibilityModel), [push, title])
    return <IconButton aria-label="add responsible" onClick={handleAddResponsible}><AddIcon /></IconButton>
}
