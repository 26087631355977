import * as React from "react";
import SearchPanel from "tools/components/SearchPanel";
import { PortfolioSummaryModel, IPortfolioTypeModel, PortfolioModel, IFeatureModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getEntityName } from "tools/lib/modelUtils";
import { getEnumLabels } from "tools/lib/utility";
import AddIcon from '@material-ui/icons/Add';
import { IActionButton, IMenuActionButton } from "tools/components/FabContainer";
import { getPortfolioTypeLabel } from "./getPortfolioTypeLabel";
import { FormControlLabel, Switch } from "@material-ui/core";
import { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";

const portfolioTypes = getEnumLabels(IPortfolioTypeModel);
export default function Portfolios() {
    const { all: portfolios = [], dictionaries: { entities }, allLoading: portfolioAllLoading, onlyActive, extraData = {} } = useReduxSelections("managedPortfolio");
    const { navigationActiveSiteMapNode } = useReduxSelections("navigation");
    const { referenceCurrencies = {} } = useReduxSelections("reference");
    const { navigationNavigate } = useReduxActions("navigation");
    const { portfolioLoadAll } = useReduxActions("managedPortfolio");

    const {
        classificationTypes,
        classificationTypesFlat
    } = useReduxSelections("securityClassificationType");
    const { classificationTypesColumns, classificationTypesState } = getClassificationTypesColumnDefinitions(classificationTypes, classificationTypesFlat, (portfolio: PortfolioSummaryModel) => portfolio.classifications);

    const columns: IColumnDefinition[] = [
        { name: "Name", title: "Name", getCellValue: (row: PortfolioModel | PortfolioSummaryModel) => row.name || "", filteringEnabled: true, positionToKeep: "left" },
        { name: "InternalCode", title: "Internal Code", getCellValue: (row: PortfolioModel | PortfolioSummaryModel) => row.internalCode || "", filteringEnabled: true },
        { name: "Currency", title: "Currency", getCellValue: (row: PortfolioModel | PortfolioSummaryModel) => row.currencyId ? referenceCurrencies[row.currencyId]?.isoCode : undefined, filteringEnabled: true },
        { name: "Type", title: "Type", getCellValue: (row: PortfolioModel | PortfolioSummaryModel) => getPortfolioTypeLabel(row.type) },
        { name: "Sicav", title: "Sicav", getCellValue: (row: PortfolioModel | PortfolioSummaryModel) => (row.type === "SubFundSummaryModel" || row.type === "ManagedSubFundModel") && row.sicavId && getEntityName(entities[row.sicavId]), filteringEnabled: true },
        { name: "LastCompositionDate", title: "Last Composition Date", getCellValue: ({ id }: PortfolioModel | PortfolioSummaryModel) => extraData[id]?.date, columnType: "date" },
        { name: "LastAum", title: "Last Aum", getCellValue: ({ id }: PortfolioModel | PortfolioSummaryModel) => extraData[id]?.aum, columnType: "preciseDecimal", aggregationType: "sum" },
        { name: "Terminated", title: "Terminated", getCellValue: ({ id }: PortfolioModel | PortfolioSummaryModel) => extraData[id]?.isTerminated, columnType: "boolean" },
        ...classificationTypesColumns
    ];

    const state: IGridState = {
        "Name": { width: 300 },
        "InternalCode": { width: 150 },
        "Currency": { width: 120 },
        "Type": { width: 130 },
        "Sicav": { width: 300 },
        "LastCompositionDate": { width: 180 },
        "LastAum": { width: 150 },
        "Terminated": { width: 120 },
        ...classificationTypesState
    };

    const getRowKey = (row: PortfolioSummaryModel | PortfolioModel) => row.id || 0;
    const handleRowSelect = (row: PortfolioSummaryModel | PortfolioModel) => navigationNavigate({ sectionKey: "detail", parameters: { id: row.id } });

    const extraActionButtons: IActionButton[] = React.useMemo(() => [{
        label: "Add portfolio",
        icon: AddIcon,
        feature: IFeatureModel.ManagedPortfolioWrite,
        actions: Object.values(IPortfolioTypeModel).map(key => ({
            label: portfolioTypes[key],
            onClick: () => navigationNavigate({ sectionKey: "detail", parameters: { id: key } }),
        }))
    } as IMenuActionButton], [navigationNavigate]);

    const handleOnActiveChange = (event: React.ChangeEvent<HTMLInputElement>, checked:boolean) => portfolioLoadAll({ onlyActive: checked });
    const title = navigationActiveSiteMapNode?.screen?.label ?? "";
    return <SearchPanel title={title} isQuerying={portfolioAllLoading} renderSearch={<FormControlLabel
        control={<Switch
            checked={onlyActive}
            onChange={handleOnActiveChange} />
        }
        label="Only active portfolios" />} actions={extraActionButtons}>
        <ExtendedGrid
            rows={portfolios}
            columns={columns}
            getRowId={getRowKey}
            initialState={state}
            onRowClick={handleRowSelect}
            defaultExportFileName="Portfolios.xlsx"
            userCanGroup={true} />
    </SearchPanel>
}
