import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { filter, first, map, mergeMap, share } from "rxjs/operators";
import { ActionFactories, IAnyAction } from "reducers";
import { documentDefinitionsApi, IDocumentDefinitionModel, reportTemplatesApi } from "proxy/apiProxy";
import { changedNavigation, mapToPayload } from "lib/rxJsUtility";
import { tryParseNumber } from "tools/lib/utility";
import { filterRoute } from "tools/lib/UrlDictionary";

export const onOpenScreenDocumentDefinitions: Epic<IAnyAction>
    = action$ => {
        const userLoaded$ = action$.ofType("applicationLoaded").pipe(share(), first()); // Must be loaded from start for templates to be shown in related screens
        const changedScreen$ = action$.pipe(
            changedNavigation(({ screenKey }) => screenKey),
            filterRoute("DocumentDefinitions"));
        return merge(userLoaded$, changedScreen$)
            .pipe(map(() => ActionFactories.documentDefinition.documentDefinitionLoadAll({})));
    }

export const loadDocumentDefinitions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("documentDefinition", "documentDefinitionLoadAll"),
        mergeMap(documentDefinitionsApi.getAllAsync),
        map(ActionFactories.documentDefinition.documentDefinitionLoadedAll));
export const onOpenScreenDocumentDefinition: Epic<IAnyAction>
    = action$ => action$.pipe(
        changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
        filterRoute("DocumentDefinitions"),
        map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? 0),
        map(ActionFactories.documentDefinition.documentDefinitionLoad));

export const generateReport: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("documentDefinition", "documentDefinitionGenerate"),
        mergeMap(({ scopeType, ...pars }) => documentDefinitionsApi.generateDocumentAsync(pars).then(() => scopeType)),
        map(ActionFactories.documentDefinition.documentDefinitionGenerated));


export const loadDocumentDefinition: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("documentDefinition", "documentDefinitionLoad"),
            share());

        const documentDefinition$ = merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => documentDefinitionsApi.getAsync({ id }))),
            requestedId$.pipe(
                filter(id => !id),
                map(createEmptyDocumentDefinition))
        ).pipe(share());
        return merge(
            requestedId$.pipe(
                mergeMap(() => reportTemplatesApi.getAllAsync({})),
                map(ActionFactories.documentDefinition.documentDefinitionReportTemplateLoadedAll)),
            documentDefinition$.pipe(map(ActionFactories.documentDefinition.documentDefinitionLoaded)),
            requestedId$.pipe(map(() => ActionFactories.note.noteLoadAll())));
    };
function createEmptyDocumentDefinition(): IDocumentDefinitionModel {
    return {
        id: 0,
        code: "",
        name: "",
        classifications: {},
        content: [],
        publishedVersion: 0,
        publishOnPortal: false
    };
}
export const saveDocumentDefinition: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("documentDefinition", "documentDefinitionSave"),
        mergeMap(model => documentDefinitionsApi.saveAsync({ model })),
        map(ActionFactories.documentDefinition.documentDefinitionSaved));
export const deleteDocumentDefinition: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("documentDefinition", "documentDefinitionDelete"),
            mergeMap(id => documentDefinitionsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.documentDefinition.documentDefinitionDeleted),
            share()
        );
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }
