import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import Traces from "./Traces";
import { getSiteScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class TraceScreen extends React.PureComponent {
    public render() {
        return <NavigationRoute slideDirection={"right"} component={Traces} path={getSiteScreenUrl("Traces")} />
    }
}

export default TraceScreen;
