import { ICompletionDataModel, IEntityMetadataModel, IMacroModel, IMacroTypeModel, IResultErrorModel, ITextModelWithPosition } from "proxy/apiProxy";
import { toDictionary } from "tools/lib/utility";
import CodeEditor from "./CodeEditor";
import { CircularProgress, Box, createStyles, Fab, Tooltip, withStyles, TextField } from "@material-ui/core";
import ReactJson from 'react-json-view'
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import ErrorIcon from '@material-ui/icons/Error';
import { SummaryField } from "components/global/SummaryField";
import { useCallback, useMemo, useState } from "react";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { IMacroDiagnostic, ITypedMacro } from "../slice";
// import NavigationIcon from '@material-ui/icons/Navigation';
import { INotControllableEditorProps } from "./INotControllableEditorProps";
// https://developers.google.com/fonts/docs/getting_started
const FloatingBox = withStyles((theme) =>
    createStyles({
        root: {
            position: "absolute",
            top: 10,
            left: 10,
            zIndex: 999
        }
    })
)(Box);


const FabContainerBox = withStyles(theme =>
    createStyles({
        root: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            width: 0,
            left: 0,
            right: 0,
            justifyContent: "center",
            overflowY: "visible",
            zIndex: 1200,
            alignItems: "center",
            alignContent: "center",
            gap: theme.spacing(2)
        },
    })
)(Box);

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[15],
        color: theme.palette.text.primary,
        fontSize: theme.typography.pxToRem(12),
        maxWidth: 700,
        wordWrap: "break-word",
    },
}))(Tooltip);
export interface IFormMacroEditorProps extends INotControllableEditorProps<IMacroModel> {
    metadata: Record<string | number, IEntityMetadataModel>;
    error?: IResultErrorModel;
    executing: boolean;
    diagnostics?: IMacroDiagnostic;
    type: IMacroTypeModel;
    onCodeChanged: (macro: ITypedMacro) => void;
    onRequestAutoComplete?: (position: ITextModelWithPosition) => Promise<ICompletionDataModel[]>
};
export default function FormMacroEditor({ type, onCodeChanged, metadata, error, executing, onRequestAutoComplete, diagnostics, refToGetValue }: IFormMacroEditorProps) {
    const [metadataExpanded, setMetadataExpanded] = useState(true);
    const handleSwitchExpand = useCallback(() => {
        setMetadataExpanded(v => !v);
    }, []);
    const handleCodeChanged = useCallback((macroModel: IMacroModel) => onCodeChanged({...macroModel, type}), [onCodeChanged, type]);
    return (<Box display="flex" flexDirection="row" style={{ height: "100%", width: "100%" }}>
        <div style={{ height: "100%", width: metadataExpanded ? "calc(100% - 400px)" : "100%", position: "relative" }}>
            {(!!error) && <FloatingBox><HtmlTooltip placement="right-start" title={<Box><SummaryField label="Node" value={error.nodeName} noUnderline={true} /><SummaryField label="Error" value={error.text} noUnderline={true} /></Box>} ><Fab size="small" style={{ color: "red" }}><ErrorIcon /></Fab></HtmlTooltip></FloatingBox>}
            {(!!executing) && <FloatingBox><Fab size="small"><CircularProgress size={16} /></Fab></FloatingBox>}
            <ReadOnlyContext.Consumer>
                {readOnly => <CodeEditor
                    onCodeChanged={handleCodeChanged}
                    refToGetValue={refToGetValue}
                    readOnly={readOnly}
                    codeErrors={diagnostics?.errors}
                    onRequestAutoComplete={onRequestAutoComplete} />}
            </ReadOnlyContext.Consumer>
        </div>
        <FabContainerBox>
            <Fab color="primary" size="small" onClick={handleSwitchExpand} >
                {metadataExpanded ? <ChevronRightIcon fontSize="small" /> : <ChevronLeftIcon fontSize="small" />}
            </Fab>
        </FabContainerBox>
        {metadataExpanded && <MetadataTree metadata={metadata} />}
    </Box>);
}
interface IMetadataTreeProps {
    metadata: Record<string | number, IEntityMetadataModel>;
}
function MetadataTree({ metadata }: IMetadataTreeProps) {
    const [filter, setFilter] = useState("");
    const handleChangeFilter = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setFilter(event.target.value), []);
    const filteredMetadata = useMemo(() => {
        if (!filter || filter === "") {
            return metadata;
        }
        return toDictionary(Object.values(metadata).filter(i => (i.name ?? "").toLowerCase().includes(filter.toLowerCase())), i => i.name ?? "");
    }, [metadata, filter]);
    return <Box display="flex" flexDirection="column" width={400}>
        <TextField value={filter} onChange={handleChangeFilter} label="Filter" />
        <div style={{ height: "100%", overflowY: "auto", width: 400 }}>
            <ReactJson
                style={{ width: 600 }}
                name="Referential"
                collapsed={1}
                displayDataTypes={false}
                displayObjectSize={false}
                src={filteredMetadata} />
        </div>
    </Box>
}
