import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import Sicavs from "./Sicavs";
import Sicav from "./Sicav";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class SicavScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                gridSize={12}
                exact={true}
                slideDirection={"right"}
                component={Sicavs}
                path={getSiteScreenUrl("MySicavs")} />
            <NavigationRoute
                gridSize={12}
                slideDirection={"left"}
                component={Sicav}
                path={getSiteDetailScreenUrl("MySicavs")} />
        </>
    }
}

export default SicavScreen;
