// import { React } from "mdi-material-ui";
import ClassificationsData from "components/global/ClassificationsData";
import {
    EntitySummaryModel, IClassificationTypeModel, ICurrencyModel, PortfolioSummaryModel,
    SecuritySummaryModel, IBankAccountSummaryModel, IEntityTypeModel, IFeatureModel
} from "proxy/apiProxy";
import FieldBox from "tools/components/FieldBox";
import FormTextField from "tools/fieldComponents/FormTextField";
import { base64toBlob, oProps } from "tools/lib/utility";
import { Box, Grid } from "@material-ui/core";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import EntitySelectField from "components/searchers/EntitySelectField";
import { IGetBankAccountSummary, IGetPortfolioSummary, IGetEntitySummary, IGetSecuritySummary } from "reducers/Reference";
import ManagedPortfolioSelectField from "components/searchers/ManagedPortfolioSelectField";
import SecuritySelectField from "components/searchers/SecuritySelectField";
import EntitySummary from "components/summaries/EntitySummary";
import ManagedPortfolioSummary from "components/summaries/ManagedPortfolioSummary";
import SecuritySummary from "components/summaries/SecuritySummary";
import { BankAccountSelectField } from "components/searchers/BankAccountSelectField";
import BankAccountSummary from "components/summaries/BankAccountSummary";
import { CurrencySelectField } from "components/searchers/CurrencySelectField";
import { useGrants } from "tools/lib/reduxStoreAccess";
import FormMultiCulturedTextField from "tools/fieldComponents/FormMultiCulturedTextField";
import { ICashMovementData } from "reducers/CashMovement";
import FormDropZoneField from "tools/fieldComponents/FormDropZoneField";
import { useField } from "formik";
import saveAs from "file-saver";

export interface ICashMovementDataProps {
    classificationTypes: IClassificationTypeModel[];
    referenceCurrencies?: Record<number | string, ICurrencyModel>;
    onEntitySelected: (found: IGetEntitySummary) => void;
    onPortfolioSelected: (found: IGetPortfolioSummary) => void;
    onSecuritySelected: (found: IGetSecuritySummary) => void;
    onBankAccountSelected: (found: IGetBankAccountSummary) => void;
    onRequestFileLoad: () => void;
    dictionaries: {
        entities: Record<number, EntitySummaryModel>;
        portfolios: Record<number, PortfolioSummaryModel>;
        securities: Record<number, SecuritySummaryModel>;
        bankAccounts: Record<number, IBankAccountSummaryModel>;
    }
}

export default function CashMovementData({ onRequestFileLoad, classificationTypes, referenceCurrencies = {}, dictionaries, onEntitySelected, onPortfolioSelected, onSecuritySelected, onBankAccountSelected }: ICashMovementDataProps) {
    const isGranted = useGrants();
    const [{ value: file }] = useField<ICashMovementData["file"]>(oProps<ICashMovementData>().path("file"))
    const [{ value: fileName }] = useField<ICashMovementData["cashMovement"]["fileName"]>(oProps<ICashMovementData>().path("cashMovement", "fileName"))
    const isPortfolioGranted = ({ id: portfolioId }: PortfolioSummaryModel) => isGranted({ feature: IFeatureModel.ManagedPortfolioWrite, portfolioId })
    const handleViewFile = () => {
        if (file) {
            const blob = base64toBlob(file.content, file.mimeType);
            saveAs(blob, file.fileName);
        }
        else if (fileName) {
            onRequestFileLoad();
        }
    }
    return <FieldBox display="flex" flexDirection="column">
        <ClassificationsData
            panelTitle="Classification"
            fieldName={oProps<ICashMovementData>().path("cashMovement", "classifications")}
            classificationTypes={classificationTypes} />
        <Grid container={true} spacing={1}>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "description")} label="Description" required={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "externalTransactionCode")} label="External Transaction Code" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "transactionCode")} label="Transaction Code" required={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "movementCode")} label="Movement Code" required={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormDatePickerField name={oProps<ICashMovementData>().path("cashMovement", "closingDate")} label="Closing Date" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormDatePickerField name={oProps<ICashMovementData>().path("cashMovement", "tradeDate")} label="Trade Date" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormDatePickerField name={oProps<ICashMovementData>().path("cashMovement", "valueDate")} label="Value Date" required={false} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormCheckBoxField name={oProps<ICashMovementData>().path("cashMovement", "reversal")} label="Reversal" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <CurrencySelectField name={oProps<ICashMovementData>().path("cashMovement", "currencyId")} label="Currency" required={false} />
            </Grid>

            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "statementNumber")} label="Statement Number" isNumber />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormDatePickerField name={oProps<ICashMovementData>().path("cashMovement", "openingBalanceDate")} label="Opening Balance Date" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "openingBalance")} label="Opening Balance" isNumber/>
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormDatePickerField name={oProps<ICashMovementData>().path("cashMovement", "closingBalanceDate")} label="Closing Balance Date" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "closingBalance")} label="Closing Balance" isNumber />
            </Grid>

            <Grid item={true} sm={8}>
                <FormMultiCulturedTextField name={oProps<ICashMovementData>().path("cashMovement", "comment")} label="Comment" multiline={true} />
            </Grid>
        </Grid>
        <Grid container={true} spacing={1}>
            <Grid item={true} sm={4}>
                <FormDropZoneField
                    name={oProps<ICashMovementData>().path("cashMovement", "fileName")}
                    contentName={oProps<ICashMovementData>().path("file")}
                    onClick={handleViewFile}
                    label="Drop the document here"
                    emptyValue={null}
                    minLength={1}
                />
            </Grid>
        </Grid>
        <Grid container={true} spacing={1}>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "brokerageFeesInSecurityCcy")} label="Brokerage Fees In Security Ccy" isNumber={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "feesInSecurityCcy")} label="Fees In Security Ccy" isNumber={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "grossAmountInSecurityCcy")} label="Gross Amount In Security Ccy" isNumber={true} adornment />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "grossAmountInPortfolioCcy")} label="Gross Amount In Portfolio Ccy" isNumber={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "netAmountInSecurityCcy")} label="Net Amount In Security Ccy" isNumber={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "netAmountInPortfolioCcy")} label="Net Amount In Portfolio Ccy" isNumber={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "priceInSecurityCcy")} label="Price In Security Ccy" isNumber={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "taxesInSecurityCcy")} label="Taxes In Security Ccy" isNumber={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "quantity")} label="Quantity" isNumber={true} />
            </Grid>
        </Grid>
        <Grid container={true} spacing={1}>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormDatePickerField name={oProps<ICashMovementData>().path("cashMovement", "voucherDate")} label="Voucher Date" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "voucherNumber")} label="Voucher Number" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementData>().path("cashMovement", "voucherAmount")} label="Voucher Amount" isNumber={true} />
            </Grid>
        </Grid>
        <Grid container={true} spacing={1}>
            <Grid item={true} sm={6} md={6} lg={6}>
                <Box>
                    <EntitySelectField name={oProps<ICashMovementData>().path("cashMovement", "counterpartyId")} label="Counterparty" type={IEntityTypeModel.Company} onSelected={onEntitySelected} />
                    <EntitySummary name={oProps<ICashMovementData>().path("cashMovement", "counterpartyId")} {...dictionaries} />
                </Box>
            </Grid>
            <Grid item={true} sm={6} md={6} lg={6}>
                <Box>
                    <ManagedPortfolioSelectField name={oProps<ICashMovementData>().path("cashMovement", "portfolioId")} filter={isPortfolioGranted} label="Portfolio" onSelected={onPortfolioSelected} required={false} />
                    <ManagedPortfolioSummary name={oProps<ICashMovementData>().path("cashMovement", "portfolioId")} currencies={referenceCurrencies} {...dictionaries} />
                </Box>
            </Grid>
            <Grid item={true} sm={6} md={6} lg={6}>
                <Box>
                    <SecuritySelectField name={oProps<ICashMovementData>().path("cashMovement", "underlyingSecurityId")} label="Underlying Security" onSelected={onSecuritySelected} />
                    <SecuritySummary name={oProps<ICashMovementData>().path("cashMovement", "underlyingSecurityId")} currencies={referenceCurrencies} {...dictionaries} />
                </Box>
            </Grid>
            <Grid item={true} sm={6} md={6} lg={6}>
                <Box>
                    <BankAccountSelectField name={oProps<ICashMovementData>().path("cashMovement", "cashId")} label="Bank Account" onSelected={onBankAccountSelected} />
                    <BankAccountSummary name={oProps<ICashMovementData>().path("cashMovement", "cashId")} currencies={referenceCurrencies} {...dictionaries} />
                </Box>
            </Grid>
        </Grid>
    </FieldBox>;
}