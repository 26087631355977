import { Typography, Accordion, AccordionSummary, AccordionDetails, Divider, List, Avatar, ListItem, ListItemAvatar, ListItemText, Tooltip, Box } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { IComplianceAlertLevelModel, IComplianceIssueModel, IPortfolioComplianceResultModel } from "proxy/apiProxy";
import WarningIcon from '@material-ui/icons/Warning';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { getPortfolioTypeLabel } from "features/ManagedPortfolio/getPortfolioTypeLabel";
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import { IComplianceChecksFailed } from "reducers/TradeDates";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            width: '100%',
        },
        icon: {
            marginRight: theme.spacing(1)
        },
        heading: {
            // fontSize: theme.typography.pxToRem(15),
            // flexBasis: '33.33%',
            // flexShrink: 0,
        },
        secondaryHeading: {
            // fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
            marginLeft: theme.spacing(1)
        },
    }),
);


export interface IPortfoliosComplianceChecksProps {
    portfoliosChecks: IPortfolioComplianceResultModel[] | IComplianceChecksFailed | undefined;
    processing?: boolean;
}



export default function PortfoliosComplianceChecks({ portfoliosChecks, processing }: IPortfoliosComplianceChecksProps) {
    return <>
        {processing && <Box display="flex" alignItems="center"><HourglassEmptyIcon /><Typography>Checking compliance...</Typography></Box>}
        {!!portfoliosChecks && <InnerPortfoliosComplianceChecks portfoliosChecks={portfoliosChecks} />}
    </>
}

interface IInnerPortfoliosComplianceChecksProps {
    portfoliosChecks: IPortfolioComplianceResultModel[] | IComplianceChecksFailed;
}

function InnerPortfoliosComplianceChecks({ portfoliosChecks }: IInnerPortfoliosComplianceChecksProps) {
    if (Array.isArray(portfoliosChecks)) {
        return <>{portfoliosChecks.map(portfolioChecks => <PortfolioComplianceChecks key={portfolioChecks.portfolio.id} portfolioComplianceResult={portfolioChecks} />)}</>
    }
    return <Typography style={{ color: "red" }}>{portfoliosChecks.failureDetail}</Typography>
}

interface IPortfolioComplianceChecksProps {
    portfolioComplianceResult: IPortfolioComplianceResultModel
}
function PortfolioComplianceChecks({ portfolioComplianceResult }: IPortfolioComplianceChecksProps) {
    const classes = useStyles();
    const { portfolio, issues = [] } = portfolioComplianceResult;
    return <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}    >
            {(!!issues.length) ? <ThumbDownIcon style={{ color: "red" }} className={classes.icon} /> : <ThumbUpIcon style={{ color: "green" }} className={classes.icon} />}
            <Typography className={classes.heading}>{`${portfolio.name} - ${portfolio.internalCode}`}</Typography>
            <Typography className={classes.secondaryHeading}> {getPortfolioTypeLabel(portfolio.type)}</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
            <Checks issues={issues} />
        </AccordionDetails>
    </Accordion>
}
interface IChecksProps {
    issues: IComplianceIssueModel[];
}
function Checks({ issues }: IChecksProps) {
    if (!!issues.filter(i => i.level === IComplianceAlertLevelModel.Error).length) {
        return <Box>
            <Typography>Failure occurred when running rules:</Typography>
            <ul>
                {issues.filter(i => i.level === IComplianceAlertLevelModel.Error).map((i, idx) => <li key={idx}><Typography>{i.comment}</Typography></li>)}
            </ul>
            <List>
                {issues.filter(i => i.level !== IComplianceAlertLevelModel.Error).map(({ code, comment, level }) => <ListItem key={code ?? comment ?? ""}>
                    <ListItemAvatar>
                        <Avatar>
                            <LevelAvatar level={level} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={code ?? comment ?? ""} secondary={comment} />
                </ListItem>)}
            </List>
        </Box>
    } else {
        if (!issues.filter(i => i.level === IComplianceAlertLevelModel.Breach).length) {
            return <Typography>No issue, this portfolio is compliant.</Typography>
        }
    }
    return <List>
        {issues.map(({ code, comment, level }) => <ListItem key={code ?? comment ?? ""}>
            <ListItemAvatar>
                <Avatar>
                    <LevelAvatar level={level} />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={code ?? comment ?? ""} secondary={comment} />
        </ListItem>)}
    </List>;
}

interface ILevelAvatarProps {
    level: IComplianceAlertLevelModel;
}
function LevelAvatar({ level }: ILevelAvatarProps) {
    switch (level) {
        case IComplianceAlertLevelModel.Error: return <Tooltip title="Rule execution error"><SmsFailedIcon style={{ color: "red" }} /></Tooltip>
        case IComplianceAlertLevelModel.Breach: return <Tooltip title="Compliance breached"><ErrorIcon style={{ color: "red" }} /></Tooltip>
        case IComplianceAlertLevelModel.Warning: return <Tooltip title="Rule respected but needs to be watched"><WarningIcon style={{ color: "orange" }} /></Tooltip>
        case IComplianceAlertLevelModel.Info: return <Tooltip title="Not a compliance problem but needs to be known"><InfoIcon /></Tooltip>
    }
}