import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import Macros from "./MacroScripts";
import Macro from "./MacroScript";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class MacroScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute
                slideDirection={"right"}
                component={Macros}
                path={getSiteScreenUrl("Macros")}
                exact={true} />
            <NavigationRoute
                slideDirection={"left"}
                component={Macro}
                path={getSiteDetailScreenUrl("Macros")} />
        </>
    }
}

export default MacroScreen;
