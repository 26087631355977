import * as React from "react";
import autobind from "autobind-decorator";
import NavigationRoute from "components/global/NavigationRoute";
import ShareClasses from "./ShareClasses";
import ShareClass from "./ShareClass";
import { getSiteScreenUrl, getSiteDetailScreenUrl } from "tools/lib/UrlDictionary";

@autobind
class SecurityScreen extends React.PureComponent {
    public render() {
        return <>
            <NavigationRoute gridSize={12} exact={true} slideDirection={"right"} component={ShareClasses} path={getSiteScreenUrl("MyShareClasses")} />
            <NavigationRoute gridSize={12} slideDirection={"left"} component={ShareClass} path={getSiteDetailScreenUrl("MyShareClasses")} />
        </>
    }
}

export default SecurityScreen;
