import React, { useCallback, useMemo, useState } from "react";
import { Typography, makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import UserDropdown from 'tools/components/UserDropdown';
// import { getNavigationContext, INodeContext } from 'tools/lib/SiteMap';
import { ISiteMap, ISiteMapScreen } from "tools/lib/SiteMapTypes";
import { IActiveScreen, IUrlKey } from 'tools/lib/UrlDictionary';
import { useReduxSelections, useReduxActions } from 'tools/lib/reduxStoreAccess';
import { ISiteMapSelection } from 'tools/lib/SiteMap';
import SearchBar, { ISearchOption } from './SearchBar';
import siteMap from 'siteMap';
import TenantsDialog from "./TenantsDialog";
import { getConfig, getUserManager } from "lib/userManager";

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
	appBar: {
		transition: theme.transitions.create(['width', 'margin'], {
			duration: theme.transitions.duration.leavingScreen,
			easing: theme.transitions.easing.sharp,
		}),
		zIndex: theme.zIndex.drawer + 1,
	},
	toolBar: {
		"& > *:not(:first-child)": {
			marginLeft: theme.spacing(1)
		}
	},
	appBarShift: {
		marginLeft: drawerWidth,
		transition: theme.transitions.create(['width', 'margin'], {
			duration: theme.transitions.duration.enteringScreen,
			easing: theme.transitions.easing.sharp,
		}),
		width: `calc(100% - ${drawerWidth}px)`,
	},
	hide: {
		display: 'none',
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	insideToolbarFiller: {
		flexGrow: 1,
	},
	tabButton: {
		minHeight: 64,
	}
}));
const { general: { enableStudio } } = getConfig();
const computeSearchOptions = (siteMap: ISiteMap) =>
	siteMap.flatMap(category =>
		category.modules.filter(module => !module.studioRelated || enableStudio).flatMap(mod =>
			mod.screens.filter(screen => !screen.studioRelated || enableStudio).flatMap(screen =>
			({
				label: screen.label,
				moduleCategoryLabel: category.label,
				moduleLabel: mod.label,
				moduleIcon: mod.icon,
				screenKey: screen.screenKey,
				keywords: screen.keywords
			} as ISearchOption))));
export default function ApplicationBar() {
	const { menuDrawerOpened, currentUser/*, pictureUrl*/, accessibleTenants = [], currentTenant, tenantsImageUrls } = useReduxSelections("app");
	const tenants = useMemo(() => accessibleTenants.filter(t => t.id !== currentTenant?.id).sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0), [accessibleTenants, currentTenant?.id])
	const { navigationActiveSiteMapNode } = useReduxSelections("navigation");
	const { openMenuDrawer, switchTenant, tenantsImageLoad } = useReduxActions("app");
	const handleRequestLogout = useCallback(() => {
		const userManager = getUserManager();
		userManager.signoutRedirect()
	}, [])
	const { navigationNavigate } = useReduxActions("navigation");
	const [tenantsOpened, setTenantsOpened] = useState(false);
	// const { push } = useReduxActions("router");
	const classes = useStyles();
	const searchOptions = React.useMemo(() => computeSearchOptions(siteMap), []);
	const handleNavigate = useCallback((screenKey: IUrlKey) => navigationNavigate({ screenKey }), [navigationNavigate]);
	const handleSearchOptionSelected = useCallback(({ screenKey }: ISearchOption) => navigationNavigate({ screenKey }), [navigationNavigate]);
	const handleOpenChangeTenant = useCallback(() => {
		setTenantsOpened(true);
		tenantsImageLoad();
	}, [tenantsImageLoad]);
	const handleCloseChangeTenant = useCallback(() => setTenantsOpened(false), []);
	const handleTenantSelected = (tenantId: number) => {
		setTenantsOpened(false);
		switchTenant(tenantId);
	}
	return <>
		<AppBar position="fixed" className={classNames(classes.appBar, { [classes.appBarShift]: menuDrawerOpened })}>
			<Toolbar className={classes.toolBar} disableGutters={!menuDrawerOpened}>
				<IconButton
					color="inherit"
					aria-label="Open drawer"
					onClick={openMenuDrawer}
					className={classNames(classes.menuButton, {
						[classes.hide]: menuDrawerOpened,
					})}>
					<MenuIcon />
				</IconButton>
				<SearchBar options={searchOptions} onOptionSelected={handleSearchOptionSelected} />
				{(!!navigationActiveSiteMapNode) && <NavigationModuleTitle
					navigationActiveSiteMapNode={navigationActiveSiteMapNode}
					menuDrawerOpened={menuDrawerOpened} />}
				<div className={classes.insideToolbarFiller} />
				{(!!navigationActiveSiteMapNode) && <TopMenuTabs
					navigationActiveSiteMapNode={navigationActiveSiteMapNode}
					onNavigate={handleNavigate} />}
				{!!currentUser && <UserDropdown user={currentUser} onLogout={handleRequestLogout} onChangeTenant={(accessibleTenants.length > 1) ? handleOpenChangeTenant : undefined} />}
			</Toolbar>
		</AppBar>
		<TenantsDialog accessibleTenants={tenants} opened={tenantsOpened} onCancel={handleCloseChangeTenant} onTenantSelected={handleTenantSelected} tenantsImageUrls={tenantsImageUrls} />
	</>
}

interface INavigationModuleTitleProps {
	navigationActiveScreen?: IActiveScreen;
	navigationActiveSiteMapNode?: ISiteMapSelection;
	menuDrawerOpened: boolean;
}

function NavigationModuleTitle({ navigationActiveSiteMapNode, menuDrawerOpened }: INavigationModuleTitleProps) {

	if (!navigationActiveSiteMapNode) {
		return null;
	}
	const { module: { icon, label }, moduleCategory: { label: activeCategoryLabel } } = navigationActiveSiteMapNode;
	if (menuDrawerOpened) {
		return <>
			&nbsp;{icon}&nbsp;
			<Typography variant="h6" color="inherit" noWrap={true}>
				{label}
			</Typography>
		</>
	}
	else {
		return <>
			{/* <ApplicationTitle /> &nbsp; {icon}&nbsp; */}
			{icon}&nbsp;
			<Typography variant="h6" color="inherit" noWrap={true}>
				{activeCategoryLabel}<ChevronRightIcon style={{ verticalAlign: "middle" }} />{label}
			</Typography>
		</>
	}
}

interface ITopMenuTabsProps {
	navigationActiveSiteMapNode?: ISiteMapSelection;
	onNavigate: (screenKey: IUrlKey) => void;
}

function TopMenuTabs({ onNavigate, navigationActiveSiteMapNode }: ITopMenuTabsProps) {
	if (!navigationActiveSiteMapNode) {
		return null;
	}
	const { general: { enableStudio } } = getConfig();
	const { module: { screens }, screen: { screenKey } } = navigationActiveSiteMapNode;
	if ((screens?.length ?? 0) > 1) {
		return <Tabs value={screenKey}>
			{screens
				.filter(mod => !mod.studioRelated || enableStudio)
				.map(screen => (<ScreenTab
					key={screen.screenKey}
					onNavigate={onNavigate}
					value={screen.screenKey}
					screen={screen}
				/>))}
		</Tabs>
	}
	else {
		return null;
	}
}

interface IScreenTabProps {
	screen: ISiteMapScreen;
	onNavigate: (screenKey: IUrlKey) => void;
	value?: any;
}
function ScreenTab({ screen: { screenKey, label }, onNavigate, value }: IScreenTabProps) {
	const classes = useStyles();
	const handleNavigate = () => onNavigate(screenKey);
	return <Tab
		className={classes.tabButton}
		label={label}
		value={value}
		onClick={handleNavigate} />
}
