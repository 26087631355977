import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IClassificationTypeModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { ClassificationTypeDomain, getClassificationTypeDomainLabel } from "./ClassificationTypeDomain";
import { getRelatedGrants } from "./getRelatedGrants";

function useClassificationTypeSelections(domain: ClassificationTypeDomain) {
    const { classificationTypes: securityClassificationTypes, classificationTypesLoading: securityClassificationTypesLoading } = useReduxSelections("securityClassificationType");
    const { classificationTypes: investorClassificationTypes, classificationTypesLoading: investorClassificationTypesLoading } = useReduxSelections("investorClassificationType");
    const { classificationTypes: roleRelationshipClassificationTypes, classificationTypesLoading: roleRelationshipClassificationTypesLoading } = useReduxSelections("roleRelationshipClassificationType");
    const { classificationTypes: movementClassificationTypes, classificationTypesLoading: movementClassificationTypesLoading } = useReduxSelections("movementClassificationType");
    const { classificationTypes: entityClassificationTypes, classificationTypesLoading: entityClassificationTypesLoading } = useReduxSelections("entityClassificationType");
    const { classificationTypes: noteClassificationTypes, classificationTypesLoading: noteClassificationTypesLoading } = useReduxSelections("noteClassificationType");
    const { classificationTypes: processClassificationTypes, classificationTypesLoading: processClassificationTypesLoading } = useReduxSelections("processClassificationType");
    const { classificationTypes: developmentItemClassificationTypes, classificationTypesLoading: developmentItemClassificationTypesLoading } = useReduxSelections("developmentItemClassificationType");
    switch (domain) {
        case ClassificationTypeDomain.Investor: return { classificationTypes: investorClassificationTypes, classificationTypesLoading: investorClassificationTypesLoading };
        case ClassificationTypeDomain.RoleRelationship: return { classificationTypes: roleRelationshipClassificationTypes, classificationTypesLoading: roleRelationshipClassificationTypesLoading };
        case ClassificationTypeDomain.Security: return { classificationTypes: securityClassificationTypes, classificationTypesLoading: securityClassificationTypesLoading };
        case ClassificationTypeDomain.Movement: return { classificationTypes: movementClassificationTypes, classificationTypesLoading: movementClassificationTypesLoading };
        case ClassificationTypeDomain.Entity: return { classificationTypes: entityClassificationTypes, classificationTypesLoading: entityClassificationTypesLoading };
        case ClassificationTypeDomain.Note: return { classificationTypes: noteClassificationTypes, classificationTypesLoading: noteClassificationTypesLoading };
        case ClassificationTypeDomain.Process: return { classificationTypes: processClassificationTypes, classificationTypesLoading: processClassificationTypesLoading };
        case ClassificationTypeDomain.DevelopmentItem: return { classificationTypes: developmentItemClassificationTypes, classificationTypesLoading: developmentItemClassificationTypesLoading };
    }
}
export interface IClassificationTypesProps {
    domain: ClassificationTypeDomain
}
export default function ClassificationTypes({ domain }: IClassificationTypesProps) {
    const {
        classificationTypes, classificationTypesLoading
    } = useClassificationTypeSelections(domain);
    const { navigationNavigate } = useReduxActions("navigation");
    const relatedGrants = getRelatedGrants(domain);
    const columns: IColumnDefinition[] = [
        { name: "Code", title: "Code", getCellValue: ({ code }: IClassificationTypeModel) => code, filteringEnabled: true },
        { name: "Name", title: "Name", getCellValue: ({ name }: IClassificationTypeModel) => name["en"], filteringEnabled: true }
    ];
    const state: IGridState = {
        "Code": { width: 180 },
        "Name": { width: 240 },
    }

    const handleRowEdit = ({ id }: IClassificationTypeModel) => navigationNavigate({ sectionKey: "detail", parameters: { id } });

    const handleAddNew = () => navigationNavigate({ sectionKey: "detail", parameters: { id: 0 } });

    const getRowKey = (row: IClassificationTypeModel) => row.id || 0;
    return <SearchPanel
        title={`${getClassificationTypeDomainLabel(domain)} Classification Types`}
        onAddClick={handleAddNew}
        addAllowed={relatedGrants}
        isQuerying={classificationTypesLoading}>
        <ExtendedGrid
            rows={classificationTypes}
            columns={columns}
            getRowId={getRowKey}
            initialState={state}
            onRowClick={handleRowEdit} />
    </SearchPanel>
}
