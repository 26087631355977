import * as appNew from "features/App/epics";
import * as tenantManager from "features/TenantManager/epics";
import * as tenantsAdministration from "features/TenantsAdministration/epics";
import * as entity from '../features/Entity/epics';
import * as security from '../features/Security/epics';
import * as processDefinitionLookup from '../features/ProcessDefinitionLookup/epics';
import * as managedPortfolio from 'features/ManagedPortfolio/epics';
import * as subFund from '../features/Subfund/epics';
import * as position from './Position';
import * as portfolioHistoricalValue from './PortfolioHistoricalValue';
import * as reference from './Reference';
import * as securityClassificationType from '../features/Classification/securityEpics';
import * as investorClassificationType from '../features/Classification/investorEpics';
import * as roleRelationshipClassificationType from '../features/Classification/roleRelationshipEpics';
import * as movementClassificationType from '../features/Classification/movementEpics';
import * as entityClassificationType from '../features/Classification/entityEpics';
import * as noteClassificationType from '../features/Classification/noteEpics';
import * as processClassificationType from '../features/Classification/processEpics';
import * as developmentItemClassificationType from '../features/Classification/developmentItemEpics';
import * as navigation from './Navigation';
import * as developmentItem from '../features/DevelopmentItem/epics';
import * as reportTemplate from './ReportTemplate';
import * as communityReportTemplate from './CommunityReportTemplate';
import * as communityMacro from './CommunityMacro';
import * as monitoringMacroCall from './MonitoringMacroCall';
import * as dashboard from './Dashboard';
import * as report from './Report';
import * as versioning from './Versioning';
import * as reportTemplateCategory from './ReportTemplateCategory';
import * as macroScriptCategory from './MacroScriptCategory';
import * as macroScript from 'features/MacroScript/epics';
import * as cashMovement from './CashMovement';
import * as accountBalance from './AccountBalance';
import * as bankAccount from './BankAccount';
import * as note from './Note';
import * as portfolioTransaction from './PortfolioTransaction';
import * as registerTransaction from './RegisterTransaction';
import * as connector from './Connector';
import * as batch from './Batch';
import * as stressTest from './StressTest';
import * as batchInstance from './BatchInstance';
import * as relationship from '../features/Relationship/epics';
import * as valueAtRisk from './ValueAtRisk';
import * as parameters from './Parameters';
import * as globalPortfolioCompliance from './GlobalPortfolioCompliance';
import * as globalPortfolioDealing from './GlobalPortfolioDealing';
import * as tradeDate from './TradeDate';
import * as portfoliosCompliance from './PortfoliosCompliance';
import * as indexes from './Indexes';
import * as fxRates from './FxRates';
import * as portfolioTrades from './PortfolioTrade';
import * as statisticDefinitionSet from './StatisticDefinitionSet';
import * as processDefinition from '../features/ProcessDefinition/epics';
import * as processExecution from '../features/ProcessExecution/epics';
import * as managedShareClass from '../features/ManagedShareClass/epics';
import * as managedSicav from '../features/ManagedSicav/epics';
import * as sicav from '../features/Sicav/epics';
import * as trace from './Trace';
import * as externalTemplate from './ExternalTemplate';
import * as customScreen from '../features/CustomScreen/epic';
import * as documentDefinition from './DocumentDefinition';
import * as tenancy from './Tenancy';
import * as loan from './Loan';

const AllEpics = {
    processDefinitionLookup,
    developmentItem,
    tenantsAdministration,
    processExecution,
    tenantManager,
    appNew,
    loan,
    tenancy,
    documentDefinition,
    customScreen,
    externalTemplate,
    registerTransaction,
    sicav,
    trace,
    managedSicav,
    managedShareClass,
    subFund,
    processDefinition,
    statisticDefinitionSet,
    portfolioTrades,
    fxRates,
    indexes,
    portfoliosCompliance,
    tradeBook: tradeDate,
    globalPortfolioCompliance,
    globalPortfolioDealing,
    parameters,
    entity,
    security,
    managedPortfolio,
    position,
    portfolioHistoricalValue,
    reference,
    securityClassificationType,
    investorClassificationType,
    movementClassificationType,
    roleRelationshipClassificationType,
    entityClassificationType,
    noteClassificationType,
    processClassificationType,
    developmentItemClassificationType,
    navigation,
    reportTemplate,
    communityReportTemplate,
    communityMacro,
    monitoringMacroCall,
    dashboard,
    report,
    versioning,
    reportTemplateCategory,
    macroScriptCategory,
    macroScript,
    cashMovement,
    accountBalance,
    bankAccount,
    note,
    portfolioTransaction,
    connector,
    batch,
    stressTest,
    batchInstance,
    relationship,
    valueAtRisk
}

export default AllEpics;